import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import {
    getBank, deleteBank, getBankCount, isTokenValid, insertUpdateBank, getBankById,
    getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";
import { Trans, useTranslation } from "react-i18next";


export default function BankPage() {
    const [listBank, setListBank] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [bankId, setBankId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [bankModal, setBankModal] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_bank.order_name1")}`,
        value: "bank_name"
    });
    const orderByList = [{
        label: `${t("index_bank.order_name1")}`,
        value: "bank_name"
    }, {
        label: `${t("index_bank.order_name2")}`,
        value: "bank_code"
    },];
    const [bankCount, setBankCount] = useState(0);
    const [descending, setDescending] = useState(false);
    const [bank, setBank] = useState({
        id: "0",
        bank_name: "",
        bank_code: "",
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBank();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadBank();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadBank();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadBank();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadBank();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadBank()
    }, [descending]);

    useEffect(() => {
        if (bankId !== "" && bankId !== undefined)
            initBank();
    }, [bankId]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeBank();
    }, [removeId])

    useEffect(() => {
        if (!bankModal)
            setBankId("");
    }, [bankModal])


    const loadBank = async () => {
        try {
            let count = await getBankCount(cookies.token, searchQuery, false);
            setBankCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);


            let response = await getBank(cookies.token, cookies.languageId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListBank(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }

    }

    const clearBankModal = async () => {
        let bankTmp = {};
        bankTmp.id = 0;
        bankTmp.bank_name = "";
        bankTmp.bank_code = "";
        setBank(bankTmp);
    }

    const saveData = async () => {
        try {
            let response = await insertUpdateBank(cookies.token, bank);
            if (response.error_code === 0) {
                alert(`${t("index_bank.alert_success_save_data")}`);
                setBankModal(false);
                clearBankModal();
                loadBank();
                setDisabledButton(false);
            } else {
                alert(`${t("index_bank.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const initBank = async () => {
        try {
            let response = await getBankById(cookies.token, bankId);
            if (response) {
                setBank({
                    ...bank,
                    id: response.id,
                    bank_name: response.bank_name,
                    bank_code: response.bank_code,
                })
            }
            setBankModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeBank = async () => {
        try {
            let response = await deleteBank(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_bank.delete_success_alert")}`);
                loadBank();
            } else {
                alert(`${t("index_bank.delete_failed_alert")}`);
            }

            setRemoveId("");
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_bank.order_name1")}`,
                value: "bank_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_bank.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setBankModal(true);
                                setBank({ ...bank, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_bank.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_bank.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadBank();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadBank();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadBank();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_bank.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_bank.table_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_bank.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_bank.table_name4")}</div>
                                </div>
                                {
                                    listBank.map((banks, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{banks.bank_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{banks.bank_code}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idBank = banks.id;
                                                        setBankId(idBank);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        let idBank = banks.id;
                                                        if (window.confirm(`${t("index_bank.delete_confirmation")}  ${banks.bank_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idBank);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />




                <Modal show={bankModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setBankModal(false);
                        clearBankModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_bank.modal_add_bank")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_bank.field_bank_name")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setBank({ ...bank, bank_name: e.target.value })
                                }} value={bank.bank_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_bank.field_bank_code")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setBank({ ...bank, bank_code: e.target.value })
                                }} value={bank.bank_code} type="text" placeholder="" ></Form.Control>
                            </Form.Group>

                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{width:100}} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_bank.save_button")}</Button>
                                <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                    clearBankModal();
                                    setBankModal(false);
                                }}>{t("index_bank.cancel_button")}</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}