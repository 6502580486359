import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getContractTask, getContractTaskCount, deleteContractTask, getContractById,
    getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { EyeFill, ArrowLeft, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise, Files } from "react-bootstrap-icons";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function ContractDetailContractTaskPage() {
    const { t, i18n } = useTranslation();

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [contractName, setContractName] = useState("");
    const [contractOptions, setContractOptions] = useState([]);
    const [vesselTypeOptions, setVesselTypeOptions] = useState([]);
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [contractTaskId, setContractTaskId] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_contract_task.order_name1")}`,
        value: "contractTask.title"
    });
    const orderByList = [{
        label: `${t("index_contract_task.order_name1")}`,
        value: "contractTask.title"
    },
    // {
    //     label: "Tipe Kapal",
    //     value: "vesselType.vessel_type_name"
    // }, 
    {
        label:  `${t("index_contract_task.order_name2")}`,
        value: "equipment.equipment_name"
    }];

    const [contractTaskCount, setContractTaskCount] = useState(0);
    const [descending, setDescending] = useState(false);
    const [listContractTask, setListContractTask] = useState([]);
    const [contractId, setContractId] = useState(location.state.contractId);
    const [totalQuality, setTotalQuality] = useState(0);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");

            } else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadContractTask();
                loadAccess();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadContractTask();
    }, [page]);

    useEffect(() => {
        loadContractTask();
    }, [itemPerPage]);

    useEffect(() => {
        loadContractTask();
    }, [orderBy]);

    useEffect(() => {
        if (searchQuery === "")
            loadContractTask();
    }, [searchQuery]);

    useEffect(() => {
        loadContractTask()
    }, [descending]);

    useEffect(() => {
        if (listContractTask.length > 0)
            handlesum();
    }, [listContractTask]);

    useEffect(() => {
        setLoading(true);
        if (contractTaskId !== "")
            removeContractTask();
    }, [contractTaskId]);

    const loadContractTask = async () => {
        try {
            let count = await getContractTaskCount(cookies.token, contractId);
            setContractTaskCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);



            let response = await getContractTask(cookies.token, cookies.languageId, page, itemPerPage, orderBy.value, descending, searchQuery, false, contractId);
            setListContractTask(response);

            // let name = await getContractById(cookies.token, contractId)
            // setContractName(name.contract_name);
            setLoading(false);


        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahaan Pada Sistem, Silahkan Hubungi Administrator');
        }
    }

    const handlesum = async () => {
        try {
            let total = 0;
            for (let index = 0; index < listContractTask.length; index++) {
                total += Number(listContractTask[index].quality);
            }
            setTotalQuality(total);
            // if (total > 100) {
            //     // alert('Total Tidak Boleh Lebih Dari 100');
            //     setErrorMessage("*Total Bobot Tidak Boleh Lebih Besar Dari 100");
            //     setDisabledButton(true);
            // } else if (total < 0) {
            //     // alert('Total Tidak Boleh Lebih Kecil Dari 0');
            //     setErrorMessage("*Total Bobot Tidal Boleh Lebih Kecil Dari 0")
            //     setDisabledButton(true);
            // } else {
            //     setErrorMessage("");
            //     setDisabledButton(false);
            // }
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeContractTask = async () => {
        let response = await deleteContractTask(cookies.token, contractTaskId);
        console.log(response);
        if (response) {
            loadContractTask();
            alert(`${t("index_contract_task.delete_success_alert")}`)
        } else {
            alert(`${t("index_contract_task.delete_failed_alert")}`);
            setLoading(false);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_contract_task.order_name1")}`,
                value: "contractTask.title"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_contract_task.header_title")} ${contractName}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Contract"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={!disabledCustomerForm}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/Contract/ContractTask/Detail", { state: { contractTaskId: 0, contractId: contractId, totalQty: totalQuality } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_contract_task.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_contract_task.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadContractTask();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadContractTask();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadContractTask();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_contract_task.table_name1")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_contract_task.table_name2")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_contract_task.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_contract_task.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_contract_task.table_name5")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_contract_task.table_name6")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_contract_task.table_name7")}</div>
                                </div>
                                {
                                    listContractTask.map((contractTask, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{contractTask.title}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p> {contractTask.vessel_type.map((vesselType, index) => {
                                                return (
                                                    vesselType.label + (index === contractTask.vessel_type.length - 1 ? "" : ", ")
                                                )
                                            })}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{contractTask.equipment_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{contractTask.quality}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/Contract/ContractTask/Detail", { state: { contractTaskId: contractTask.id, contractId: contractTask.contract_id, totalQty: Number(totalQuality) - Number(contractTask.quality), type: "detail" } });
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={disabledCustomerForm} variant="primary" onClick={() => {
                                                        navigate("/Contract/ContractTask/Detail", { state: { contractTaskId: contractTask.id, contractId: contractTask.contract_id, totalQty: Number(totalQuality) - Number(contractTask.quality), type: "duplicate" } });
                                                    }}
                                                    ><Files /></Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={disabledCustomerForm === true ? true : !deletedButton} variant="danger"
                                                        onClick={event => {
                                                            if (window.confirm(`${t("index_contract_task.delete_confirmation")} ${contractTask.contract_name}?`)) {
                                                                let contractTaskId = contractTask.id
                                                                setContractTaskId(contractTaskId);
                                                            }
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>

                                        </div>)

                                    }

                                    )


                                }
                            </div>
                            {
                                listContractTask.length > 0 ?
                                    <tr className="table-container">
                                        <td colSpan={6}>{t("index_contract_task.text_total")} : {totalQuality} %</td>
                                    </tr> :
                                    <></>
                            }

                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>
        </>
    )
}