import "./index.css";
import "../../SMSApp.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BitLamp from "../../Components/BitLamp/BitLamp";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { isTokenValid, getPLCCoordinate, getPlcValue } from "../../Helpers/ApplicationHelpers";
import { Col, Row } from "react-bootstrap";
import Loading from "../../Components/Loading";

export default function MSBPage() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listBitlamp, setListBitlamp] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (listBitlamp.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [listBitlamp]);

    useEffect(() => {
        if (listBitlamp.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadBitlampValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [listBitlamp, isDataLoaded]);
    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "msb", cookies.vesselId);
            let newList = response.filter(p => p.is_output === "0")
            setListBitlamp(newList);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }

    const loadBitlampValue = async () => {
        try {
            setIsDataLoaded(true);
            let coordinate = listBitlamp.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = listBitlamp.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setListBitlamp(updatedList);
            setLoading(false);
            setIsDataLoaded(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div>
                <TopNavBar />
            </div>
            <div>
                <TitleAlarmBar text={"MAIN SWITCH BOARD"} />
            </div>
            <div className="contentBilgesTanks">

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    width: "100%",
                    flexWrap: "nowrap",
                    backgroundColor: "rgba(233, 233, 250, 0.1)",
                    minHeight: 600,
                    padding: 20
                }}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        minHeight: 300,
                        // width:100,
                        paddingRight: 10,
                        borderStyle: "solid",
                        backgroundColor: "rgba(233, 233, 250, 0.1)",
                        borderColor: "rgba(233, 233, 250, 0.1)"

                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: "center",
                            paddingBottom: 20

                        }}>
                            <h3>GENERATOR 1</h3>

                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            marginLeft: 20
                        }}>
                            {
                                listBitlamp && listBitlamp.filter(p => p.equipment === "ae1").map((generator1, index) => {
                                    return (
                                        <div key={index}>
                                            <BitLamp text={generator1.coordinate_desc} alarm={generator1.alarm} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={{ paddingRight: 10 }}></div>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        minHeight: 300,
                        borderStyle: "solid",
                        paddingRight: 10,
                        paddingLeft: 10,
                        backgroundColor: "rgba(233, 233, 250, 0.1)",
                        borderColor: "rgba(233, 233, 250, 0.1)"
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: "center",
                            paddingBottom: 20

                        }}>
                            <h3>SYNCHRONIZER</h3>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            marginLeft: 20
                        }}>
                            {
                                listBitlamp && listBitlamp.filter(p => p.equipment === "synchronizer").map((generator1, index) => {
                                    return (
                                        <div key={index}>
                                            <BitLamp text={generator1.coordinate_desc} alarm={generator1.alarm} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={{ paddingRight: 10 }}></div>
                    <div style={{
                        display: "flex",
                        borderStyle: "solid",
                        flexDirection: "column",
                        flex: 1,
                        flexWrap: "nowrap",
                        minHeight: 300,
                        backgroundColor: "rgba(233, 233, 250, 0.1)",
                        borderColor: "rgba(233, 233, 250, 0.1)"

                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: "center",
                            paddingBottom: 20
                        }}>
                            <h3>GENERATOR 2</h3>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            marginLeft: 20
                        }}>
                            {
                                listBitlamp && listBitlamp.filter(p => p.equipment === "ae2").map((generator1, index) => {
                                    return (
                                        <div key={index}>
                                            <BitLamp text={generator1.coordinate_desc} alarm={generator1.alarm} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={{ paddingRight: 10 }}></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        flexWrap: "nowrap",
                        borderStyle: "solid",
                        minHeight: 300,
                        backgroundColor: "rgba(233, 233, 250, 0.1)",
                        borderColor: "rgba(233, 233, 250, 0.1)"

                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: "center",
                            paddingBottom: 20
                        }}>
                            <h3>SHORE CONNECTION</h3>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            marginLeft: 20
                        }}>
                            {
                                listBitlamp && listBitlamp.filter(p => p.equipment === "shore_conn").map((generator1, index) => {
                                    return (
                                        <div key={index}>
                                            <BitLamp text={generator1.coordinate_desc} alarm={generator1.alarm} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* <div className="contentBTContainer" style={{width:"50%"}}>
                    <Col md={12}>
                        <Row>
                            {
                                listBitlamp.map((bitlamps, index) => {
                                    return (

                                        <Col md={6} key={index}>
                                            <div key={index} style={{
                                                display: "flex",
                                                flex: 1,
                                                flexWrap: "nowrap",
                                                flexDirection: "column",

                                            }}>

                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    marginLeft: 200,
                                                    marginTop:20,
                                                    paddingBottom: 20,
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <BitLamp text={bitlamps.coordinate_desc} alarm={bitlamps.alarm} />
                                                </div>

                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </div> */}
            </div>
            <Loading
                loading={loading}
              />
            <div style={{ paddingBottom: 120 }}></div>
            <div>
                <BottomNavBar />
            </div>
        </>
    );
}