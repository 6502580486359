import { Button } from "react-bootstrap";
import "../../App.css";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import "./index.css";
import { useNavigate } from "react-router-dom";

export default function Alarm() {
    const navigate = useNavigate();
    const navigateAlarmLogs = () => { navigate("/Alarm/Logs") }

    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"ALARM"} /></div>
            <div className="contentAlarm">
                <div className="headingContentAlarm">
                    <div className="titleContent">System Event Alarm</div>
                    <div className="topBtnAlarmContainer">
                        <Button
                            className="btnAlarms"
                            variant="outline-primary"
                        >
                            ACKNOWLEDGE ALARM
                        </Button>
                        <Button
                            className="btnAlarms"
                            variant="outline-primary"
                            onClick={navigateAlarmLogs}
                        >
                            ALARM LOGS
                        </Button>
                    </div>
                </div>
                <div className="tableAlarm">
                    <div className="tableAlarmHead">
                        <div className="headingNo"></div>
                        <div className="headingDate">Date</div>
                        <div className="headingTime">Time</div>
                        <div className="headingEvent">Events</div>
                    </div>
                    <div className="tableBodyContainer">
                        <div className="tableAlarmBody">
                            <div className="bodyNo">3</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0 Return to Normal</div>
                        </div>
                        <div className="tableAlarmBody">
                            <div className="bodyNo">2</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0 Acknowledge</div>
                        </div>
                        <div className="tableAlarmBody">
                            <div className="bodyNo">1</div>
                            <div className="bodyDate">04/30/23</div>
                            <div className="bodyTime">03:29:55</div>
                            <div className="bodyEvent">Event 0</div>
                        </div>
                    </div>
                </div>
            </div>
            <div><BottomNavBar /></div>
        </>
    );
}