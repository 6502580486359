import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, insertUpdateGeneralOverhaul, getGeneralOverhaulById, convertBase64,
    getUserAccessById, convertBase64Image, getProject, getEquipment, getVesselEquipmentByEquipmentCategory,
    getGeneralOverhaulReport,getListJobFromDailyReport
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../Components/NavBar";
import ContainerBox from "../../../Components/ContainerBox";
import ButtonForm from "../../../Components/ButtonForm";
import Loading from "../../../Components/Loading";
import TextBox from "../../../Components/Textbox";
import { ArrowLeft, Trash, PrinterFill } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";
import moment from "moment";
import { Editor } from '@tinymce/tinymce-react';
import GOHListJob from "../../../Components/GOHListJob";

export default function GeneralOverhaulDetailPage() {

    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [selectedProject, setSelectedProject] = useState({});
    const [vesselId, setVesselId] = useState("");
    const [generalOverhaulId, setGeneralOverhaulId] = useState("");
    const [equipmentId, setEquipmentId] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledPrint, setDisabledPrint] = useState(false);
    const [listEquipmentCategory, setListEquipmentCategory] = useState([]);
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [selectedequipmentCategory, setSelecteEquipmentCategory] = useState({});
    const [selectedVesselEquipment, setSelectedVesselEquipment] = useState({});
    const [listProject, setListProject] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [docxData, setDocxData] = useState("");
    const [resetComponent, setResetComponent] = useState(false);
    const [generalOverhaul, setGeneralOverhaul] = useState({
        id: 0,
        project_id: null,
        location: "",
        equipment_category_id: null,
        vessel_equipment_id: null,
        language_id: cookies.languageId,
        start_date: null,
        project_overview: ""

    });
    const [listJob, setListJob] = useState([]);
    const [listRecommendation, setListRecommendation] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setGeneralOverhaulId(location.state.generalOverhaulId);
                loadAccess();
                loadProject();
                loadEquipmentCategory();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setLoading(false);
            }
        }
        checkCookies();

    }, []);

    useEffect(()=>{
        if(generalOverhaul.project_id !== null && generalOverhaul.vessel_equipment_id){
            if(location.state.generalOverhaulId === 0){
                loadListJobFromDailyReport();
            }
        }
    },[generalOverhaul.project_id, generalOverhaul.vessel_equipment_id])

    useEffect(() => {
        if(generalOverhaul.project_id){
            let response = listProject.find(p=>p.id === generalOverhaul.project_id);
            if(response){
                setSelectedProject(response);
            }
        }

        if(generalOverhaul.equipment_category_id){
            let response = listEquipmentCategory.find(p=>p.id === generalOverhaul.equipment_category_id);
            if(response){
                setSelecteEquipmentCategory(response);
            }
        }

    }, [generalOverhaul]);

    useEffect(()=>{
        if(listVesselEquipment.length>0){
            if(generalOverhaul.vessel_equipment_id){
                let response = listVesselEquipment.find(p=>p.id === generalOverhaul.vessel_equipment_id);
                if(response){
                    setSelectedVesselEquipment(response);
                }else{
                    setSelectedVesselEquipment({})
                }
            }
        }
    },[listVesselEquipment])
    

    useEffect(() => {
        if (generalOverhaulId !== 0 && generalOverhaulId !== "") {
            initGeneralOverhaul();
        }
    }, [generalOverhaulId])

    useEffect(() => {
        if (equipmentId !== "") {
            loadVesselEquipment()
        }
    }, [equipmentId])

    useEffect(() => {
        if (selectedequipmentCategory.equipment_name) {
            setGeneralOverhaul({ ...generalOverhaul, equipment_category_id: selectedequipmentCategory.id });
            setEquipmentId(selectedequipmentCategory.id);
        } else {
            setGeneralOverhaul({ ...generalOverhaul, equipment_category_id: null });
            setEquipmentId("");
            setListVesselEquipment([]);
        }
    }, [selectedequipmentCategory])

    useEffect(() => {
        if (selectedProject.project_name) {
            setGeneralOverhaul({ ...generalOverhaul, project_id: selectedProject.id, vessel_name: selectedProject.vessel_name })
            setVesselId(selectedProject.vessel_id);
            
        } else {
            setGeneralOverhaul({ ...generalOverhaul, project_id: null, vessel_name: "" });
            setVesselId("")
        }
    }, [selectedProject])

    useEffect(() => {
        if (selectedVesselEquipment.id) {
            setGeneralOverhaul({ ...generalOverhaul, vessel_equipment_id: selectedVesselEquipment.id });
        } else {
            setGeneralOverhaul({ ...generalOverhaul, vessel_equipment_id: null })
        }
    }, [selectedVesselEquipment])
  
    useEffect(() => {
        if (resetComponent)
            setResetComponent(false);
    }, [resetComponent]);

    useEffect(() => {
        setResetComponent(true);
        forceUpdate();
    }, [listJob]);

    useEffect(() => {
        if (docxData.size > 0) {
            // console.log(docxData.size);
            downloadDocxFile();
        }
    }, [docxData]);

    useEffect(()=>{
        if(listProject.length>0){
            if(generalOverhaul.project_id){
                let response = listProject.find(p=>p.id === generalOverhaul.project_id);
                if(response){
                    setSelectedProject(response);
                }else{
                    setSelectedProject({})
                }
            }
    
        }
    },[listProject]);

    useEffect(()=>{
        if(listEquipmentCategory.length>0){
            if(generalOverhaul.equipment_category_id){
                let response = listEquipmentCategory.find(p=>p.id === generalOverhaul.equipment_category_id);
                if(response){
                    setSelecteEquipmentCategory(response);
                }else{
                    setSelecteEquipmentCategory({})
                }
            }
        }
    },[listEquipmentCategory])



    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, "null");
            let newList = response.filter(p => p.project_type_code === "GOH" || p.project_type_code === "TOH");
            setListProject(newList);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadEquipmentCategory = async () => {
        try {
            let response = await getEquipment(cookies.token);
            setListEquipmentCategory(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadVesselEquipment = async () => {
        try {
            let response =  await getVesselEquipmentByEquipmentCategory(cookies.token, equipmentId, vesselId);
            setListVesselEquipment(response)
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            let response = await insertUpdateGeneralOverhaul(cookies.token, generalOverhaul,listRecommendation, listJob);
            if(response.error_code === 0){
                alert('Berhasil Menyimpan Data')
                if (generalOverhaulId === 0) {
                    setGeneralOverhaulId(response.data.id)
                } else {
                    initGeneralOverhaul();
                }
            }else{
                alert('Gagal Menyimpan Data');
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const initGeneralOverhaul = async () => {
        try {
            let response = await getGeneralOverhaulById(cookies.token, generalOverhaulId);
           
            if (response) {
                setGeneralOverhaul({
                    ...generalOverhaul,
                    id: response.id,
                    project_id: response.project_id,
                    location: response.location,
                    equipment_category_id: response.equipment_category_id,
                    vessel_equipment_id: response.vessel_equipment_id,
                    language_id: response.language_id,
                    start_date: response.start_date,
                    project_overview: response.project_overview
                })
                setListJob(response.list_job);
                setListRecommendation(response.list_recommendation)
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
            setUpdatedButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleOnBlur = (listJob2, index) => {
   
        let listJobTmp = listJob;
        listJobTmp[index] = listJob2
        setListJob(listJobTmp.slice(0));

    };

    const handleDelete = (index) => {
        let listJobTmp = listJob;
        let overhaulTmp = generalOverhaul
        if (!overhaulTmp.deleted_detail) {
            let list = [];
            list.push(listJobTmp[index]);
            setGeneralOverhaul({ ...generalOverhaul, deleted_detail: list })
        } else {
            let list = generalOverhaul.deleted_detail;
            list.push(listJobTmp[index])
            setGeneralOverhaul({ ...generalOverhaul, deleted_detail: list })
        }
        listJobTmp.splice(index, 1);
        setListJob(listJobTmp.slice(0));

       
    };

    const renderListJob = () => {
        let renderObj = [];
        // if (listEquipment.length > 0) {
        for (let i = 0; i < listJob.length; i++) {
            const svcEqp = <div key={i}>
                <GOHListJob
                    resetComponent={resetComponent}
                    index={i}
                    handleOnBlur={handleOnBlur}
                    handleDelete={handleDelete}
                    listJob={listJob[i]}
                    generalOverhaulId={generalOverhaulId}
                />
            </div>;

            renderObj.push(svcEqp);
        }
        // }
        return renderObj;
    }

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        element.href = URL.createObjectURL(file);
        element.download = `${generalOverhaul.vessel_name}-GOHReport.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        // console.log(file);
        // console.log(file.length);

        URL.revokeObjectURL(file);
        setLoading(false);

        setDisabledPrint(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getGeneralOverhaulReport(cookies.token, generalOverhaulId, generalOverhaul.project_id)
            if (docx.size) {
                setDocxData(docx);
            } else {
                alert(`${t("detail_daily_report.error_download")}`);
                setLoading(false);
                setDisabledPrint(false);
            }
            // setServiceJobId("")
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadListJobFromDailyReport = async()=>{
        try {
            let response = await getListJobFromDailyReport(cookies.token, generalOverhaul.project_id, generalOverhaul.vessel_equipment_id);
            setListJob(response);
        } catch (exception) {
            console.log(exception)
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`Detail General Overhaul`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/GeneralOverhaul"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={generalOverhaulId !== 0 && generalOverhaulId !== "" ? true : false}
                    actionContainerChild={
                        <div>
                            <Button disabled={disabledPrint} variant="secondary" style={{

                            }} onClick={() => {
                                setDisabledPrint(true)
                                createDocument();
                                setLoading(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><PrinterFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.print_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    saveData();
                                    setDisabledButton(true);
                                    setLoading(true);
                                    setTimeout(function () {
                                        window.scrollTo(0, 0);
                                    }, 2);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Information`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>


                                                    <Form.Group className="mb3">
                                                        <Form.Label>Nama Proyek</Form.Label>
                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.project_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listProject} value={selectedProject} onChange={(e) => {
                                                                if (e !== null) {
                                                                    setSelectedProject(e);
                                                                } else {
                                                                    setSelectedProject({});
                                                                    if(location.state.generalOverhaulId === 0){
                                                                        setListJob([]);
                                                                    }
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Nama Kapal</Form.Label>
                                                        <Form.Control disabled value={generalOverhaul.vessel_name} onChange={(e) => {

                                                        }}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Lokasi</Form.Label>
                                                        <Form.Control onChange={(e) => {
                                                            setGeneralOverhaul({ ...generalOverhaul, location: e.target.value })
                                                        }} value={generalOverhaul.location}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Kategori Peralatan</Form.Label>
                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.equipment_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listEquipmentCategory} value={selectedequipmentCategory} onChange={(e) => {
                                                                if (e !== null) {
                                                                    setSelecteEquipmentCategory(e);
                                                                } else {
                                                                    setSelecteEquipmentCategory({});
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Peralatan Kapal</Form.Label>
                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                if (item.id) {
                                                                    return (item.manufacturer_name ? item.manufacturer_name : " ") + ' - ' + item.model + `(${item.serial_number ? item.serial_number : ""})`
                                                                } else {
                                                                    return ("");
                                                                }
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listVesselEquipment} value={selectedVesselEquipment} onChange={(e) => {
                                                                if (e !== null) {
                                                                    setSelectedVesselEquipment(e);
                                                                } else {
                                                                    setSelectedVesselEquipment({});
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Tanggal Mulai</Form.Label>
                                                        <Form.Control type="date" onChange={(e) => {
                                                            setGeneralOverhaul({ ...generalOverhaul, start_date: e.target.value })
                                                        }} value={generalOverhaul.start_date ? moment(generalOverhaul.start_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Project Overview</Form.Label>
                                                        <Editor
                                                            apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                            // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                            onEditorChange={(evt, editor) => {
                                                               
                                                                setGeneralOverhaul({ ...generalOverhaul, project_overview: evt });

                                                            }}
                                                            value={generalOverhaul.project_overview}
                                                            init={{
                                                                height: 500,
                                                                menubar: false,
                                                                plugins: [
                                                                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                ],
                                                                toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                                image_title: true,
                                                                automatic_uploads: true,
                                                                /*
                                                                  URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                  images_upload_url: 'postAcceptor.php',
                                                                  here we add custom filepicker only to Image dialog
                                                                */
                                                                file_picker_types: 'image',
                                                                /* and here's our custom image picker*/
                                                                file_picker_callback: (cb, value, meta) => {
                                                                    const input = document.createElement('input');
                                                                    input.setAttribute('type', 'file');
                                                                    input.setAttribute('accept', 'image/*');

                                                                    input.addEventListener('change', (e) => {
                                                                        const file = e.target.files[0];
                                                                        const reader = new FileReader();
                                                                        reader.addEventListener('load', () => {
                                                                            /*
                                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                                              registry. In the next release this part hopefully won't be
                                                                              necessary, as we are looking to handle it internally.
                                                                            */

                                                                            // console.log(editorRef.current.getContent())
                                                                            const id = 'blobid' + (new Date()).getTime();
                                                                            const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                            //   editorRef.current.editorUpload.blobCache

                                                                            //Editor.activeEditor.editorUpload.blobCache;

                                                                            const base64 = reader.result.split(',')[1];
                                                                            const blobInfo = blobCache.create(id, file, base64);
                                                                            blobCache.add(blobInfo);

                                                                            /* call the callback and populate the Title field with the file name */
                                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                                        });
                                                                        reader.readAsDataURL(file);
                                                                    });

                                                                    input.click();
                                                                },
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                    </Form.Group>



                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={`Summary Recommendation`}

                                                childContent={
                                                    <div style={{
                                                        padding: 10,
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>


                                                            {
                                                                listRecommendation && listRecommendation.map((recommendation, index) => {
                                                                    return (
                                                                        <div key={index} style={{ padding: 5 }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                padding: 5,
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                                borderStyle: "solid",
                                                                                borderColor: "rgba(3, 30, 103, 1)"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row"
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 9,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <Form.Label>Rekomendasi</Form.Label>
                                                                                        <Form.Control onChange={(e) => {
                                                                                            let listTmp = listRecommendation;
                                                                                            listTmp[index].recommendation = e.target.value
                                                                                            setListRecommendation(listTmp.slice(0));
                                                                                        }} value={recommendation.recommendation} required></Form.Control>


                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        flex: 9,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5,
                                                                                    }}>
                                                                                        <Form.Label>Alasan</Form.Label>
                                                                                        <Form.Control onChange={(e) => {
                                                                                            let listTmp = listRecommendation;
                                                                                            listTmp[index].reason = e.target.value
                                                                                            setListRecommendation(listTmp.slice(0));
                                                                                        }} value={recommendation.reason} ></Form.Control>

                                                                                    </div>


                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "column",
                                                                                        alignItems: "flex-end",
                                                                                        flex: 1,
                                                                                        paddingLeft: 5,
                                                                                        paddingRight: 5
                                                                                    }}>
                                                                                        <div style={{ paddingTop: 35 }}></div>
                                                                                        <Button onClick={() => {
                                                                                            if (window.confirm(`${t("detail_troubleshooting.delete_confirmation")}`)) {
                                                                                                let list = listRecommendation
                                                                                                list.splice(index, 1);
                                                                                                setListRecommendation(list.slice(0))
                                                                                                // if (!dailyReport.deleted_crew) {
                                                                                                //     let listTechnician = [];
                                                                                                //     listTechnician.push(list[index]);

                                                                                                //     list.splice(index, 1);
                                                                                                //     setDailyReport({ ...dailyReport, deleted_crew: listTechnician });
                                                                                                //     setListCrew(list.slice(0))
                                                                                                // } else {
                                                                                                //     let listTechnician = dailyReport.deleted_crew;
                                                                                                //     listTechnician.push(list[index]);
                                                                                                //     list.splice(index, 1);
                                                                                                //     setDailyReport({ ...dailyReport, deleted_crew: listTechnician });
                                                                                                //     setListCrew(list.slice(0))
                                                                                                // }
                                                                                            }

                                                                                        }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                                                    </div>

                                                                                </div>




                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }

                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>
                                                            <Button variant="primary" onClick={() => {
                                                                let newParameter = {
                                                                    recommendation: "",
                                                                    reason: ""
                                                                };
                                                                if (listRecommendation.length === 0) {
                                                                    let list = [];
                                                                    list.push(newParameter);
                                                                    setListRecommendation(list.slice(0))
                                                                } else {
                                                                    let list = listRecommendation;
                                                                    list.push(newParameter);
                                                                    setListRecommendation(list.slice(0))
                                                                }

                                                            }}>Tambah Rekomendasi</Button>
                                                        </div>

                                                    </div>
                                                }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>


                                            <ContainerBox containerPos="inner" titleCaption={`List Pekerjaan`}

                                                childContent={
                                                    <div style={{
                                                        padding: 10,
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>


                                                            {

                                                                renderListJob()

                                                            }

                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            padding: 5
                                                        }}>
                                                            <Button variant="primary" onClick={() => {
                                                                let newParameter = {
                                                                    job_date: null,
                                                                    job_description: ""
                                                                };
                                                                if (listJob.length === 0) {
                                                                    let list = [];
                                                                    list.push(newParameter);
                                                                    setListJob(list.slice(0))
                                                                } else {
                                                                    let list = listJob;
                                                                    list.push(newParameter);
                                                                    setListJob(list.slice(0))
                                                                }

                                                            }}>Tambah Pekerjaan</Button>
                                                        </div>

                                                    </div>
                                                }>
                                            </ContainerBox>


                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5,
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            paddingRight: 5,
                                        }}><Button style={{ width: 100 }} disabled={disabledButton} type="submit" variant="primary" >{t("detail_daily_report.save_button")}</Button></div>


                                        <div style={{
                                            paddingLeft: 5,
                                            width: 100
                                        }}>
                                            <Button variant="danger" onClick={() => {
                                                navigate('/GeneralOverhaul');
                                            }}>{t("detail_daily_report.cancel_button")}</Button>

                                        </div>
                                    </div>

                                </Form>

                            </div>


                        </div>

                    }>

                </ContainerBox >

            </Container >
            <Loading
                loading={loading}
            />
        </>
    )
}