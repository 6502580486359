import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import config from "../../../../Config/Config";
import { useCookies } from "react-cookie";
import {
    getEquipmentFinding, deleteEquipmentFinding, getCountEquipmentFinding, isTokenValid, insertUpdateEquipmentFinding, getEquipmentFindingById,
    getUserAccessById, getVesselByVesselEquipment, findServiceByVessel
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";
import TextBox from "../../../../Components/Textbox";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";
import moment from "moment";
import { Editor } from '@tinymce/tinymce-react';




export default function EquipmentFindingPage() {
    const [listEquipmentFinding, setListEquipmentFinding] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [vesselEquipment, setVesselEquipment] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [listServiceJob, setListServiceJob] = useState([]);
    const [selectedServiceJob, setSelectedServiceJob] = useState({});
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [equipmentFindingId, setEquipmentFindingId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [findingModal, setFindingModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `Nama Proyek`,
        value: "project.project_name"
    });
    const orderByList = [{
        label: `Nama Proyek`,
        value: "project.project_name"
    }, {
        label: `Nama Kapal`,
        value: "vessel.vessel_name"
    }];
    const [descending, setDescending] = useState(false);
    const [equipmentFinding, setEquipmentFinding] = useState({
        id: 0,
        service_id: null,
        finding: "",
        vessel_equipment_id: searchParams.get("id"),
        ext_customer_id: cookies.extCustomerId
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setVesselEquipmentId(searchParams.get("id"));
                loadEquipmentFinding();
                loadAccess();
                loadServiceJob();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadEquipmentFinding();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadEquipmentFinding();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadEquipmentFinding();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadEquipmentFinding();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadEquipmentFinding()
    }, [descending]);

    useEffect(() => {
        if (equipmentFindingId !== "" && equipmentFindingId !== undefined)
            initEquipmentFinding();
    }, [equipmentFindingId]);

    useEffect(() => {
        if (vesselEquipmentId !== "" && vesselEquipmentId !== undefined)
            loadVesselEquipmentData()
    }, [vesselEquipmentId])

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeEquipmentFinding();
    }, [removeId])

    useEffect(() => {
        if (!findingModal)
            setEquipmentFindingId("");
    }, [findingModal])

    useEffect(() => {
        if (selectedServiceJob.project_name) {
            setEquipmentFinding({ ...equipmentFinding, service_id: selectedServiceJob.id })
        } else {
            setEquipmentFinding({ ...equipmentFinding, service_id: null })
        }
    }, [selectedServiceJob])

    useEffect(() => {
        if (listServiceJob.length > 0) {
            if (equipmentFinding.service_id) {
                let newList = listServiceJob.find(p => p.id === equipmentFinding.service_id)
                if (newList) {
                    setSelectedServiceJob(newList)
                }
            }
        }
    }, [listServiceJob]);

    useEffect(() => {
        if (equipmentFinding.service_id) {
            let newList = listServiceJob.find(p => p.id === equipmentFinding.service_id)
            if (newList) {
                setSelectedServiceJob(newList)
            }
        }
    }, [equipmentFinding])

    const loadVesselEquipmentData = async () => {
        try {
            let response = await getVesselByVesselEquipment(cookies.token, vesselEquipmentId);
            if (response) {
                setVesselEquipment(response);
            }
        } catch (exception) {
            console.log(exception)
        }
    }


    const loadEquipmentFinding = async () => {
        try {
            let count = await getCountEquipmentFinding(cookies.token, searchParams.get("id"), searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);


            let response = await getEquipmentFinding(cookies.token, searchParams.get("id"), page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListEquipmentFinding(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }

    }

    const clearFindingModal = async () => {
        let meggerResultTmp = {};
        meggerResultTmp.id = 0;
        meggerResultTmp.service_id = "";
        meggerResultTmp.finding = "";
        meggerResultTmp.vessel_equipment_id = searchParams.get("id");
        setSelectedServiceJob({});
        setEquipmentFinding(meggerResultTmp);
    }

    const saveData = async () => {
        try {
            let equipmentFindingTmp = equipmentFinding;
            equipmentFindingTmp.ext_customer_id = equipmentFindingTmp.ext_customer_id === null ? null : equipmentFindingTmp.ext_customer_id
            let response = await insertUpdateEquipmentFinding(cookies.token, equipmentFindingTmp);
            if (response.error_code === 0) {
                alert(`${t("index_warehouse.alert_success_save_data")}`);
                setFindingModal(false);
                clearFindingModal();
                loadEquipmentFinding();

            } else {
                alert(`${t("index_warehouse.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const initEquipmentFinding = async () => {
        try {
            let response = await getEquipmentFindingById(cookies.token, equipmentFindingId);
            if (response) {
                setEquipmentFinding({
                    ...equipmentFinding,
                    id: response.id,
                    service_id: response.service_id,
                    finding: response.finding,
                })
            }
            setFindingModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeEquipmentFinding = async () => {
        try {
            let response = await deleteEquipmentFinding(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_warehouse.delete_success_alert")}`);
                loadEquipmentFinding();
            } else {
                alert(`${t("index_warehouse.delete_failed_alert")}`);
            }

            setRemoveId("");
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname, cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setUpdatedButton(true);
            setDeletedButton(true);
            // setOrderBy({
            //     label: `${t("index_warehouse.order_name1")}`,
            //     value: "service_id"
            // })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadServiceJob = async () => {
        try {
            let response = await findServiceByVessel(cookies.token, searchParams.get("vesselId"));
            setListServiceJob(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`Equipment Finding ${vesselEquipment.model} ${vesselEquipment.manufacturer_name}  ${vesselEquipment.vessel_name}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setFindingModal(true);
                                setEquipmentFinding({ ...equipmentFinding, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_warehouse.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`Nama Proyek`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadEquipmentFinding();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadEquipmentFinding();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadEquipmentFinding();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Proyek</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Kapal</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listEquipmentFinding.map((findings, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{findings.project_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{findings.vessel_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idFinding = findings.id;
                                                        setEquipmentFindingId(idFinding);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                        let idFinding = findings.id;
                                                        if (window.confirm(`${t("index_warehouse.delete_confirmation")} ${findings.project_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idFinding);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />

                <Modal show={findingModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setFindingModal(false);
                        clearFindingModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Data Equipment Finding </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb3">
                                <Form.Label>Nama Servis</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        if (item.id) {
                                            return item.project_name + '-' + moment(item.service_date).format("DD-MM-yyyy")
                                        }
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listServiceJob.filter((p) => {
                                        return !listEquipmentFinding.find((x) => {
                                            return p.id === x.service_id
                                        })
                                    })} value={selectedServiceJob} onChange={(e) => {
                                        if (e !== null) {
                                            setSelectedServiceJob(e);
                                        } else {
                                            setSelectedServiceJob({});

                                        }
                                    }}
                                    isClearable
                                    required
                                />
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>Finding</Form.Label>
                                <Editor
                                    apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                    // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                    onEditorChange={(evt, editor) => {

                                        setEquipmentFinding({ ...equipmentFinding, finding: evt });

                                    }}
                                    value={equipmentFinding.finding}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                            'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                        ],
                                        toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                        toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                        image_title: true,
                                        automatic_uploads: true,
                                        /*
                                          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                          images_upload_url: 'postAcceptor.php',
                                          here we add custom filepicker only to Image dialog
                                        */
                                        file_picker_types: 'image',
                                        /* and here's our custom image picker*/
                                        file_picker_callback: (cb, value, meta) => {
                                            const input = document.createElement('input');
                                            input.setAttribute('type', 'file');
                                            input.setAttribute('accept', 'image/*');

                                            input.addEventListener('change', (e) => {
                                                const file = e.target.files[0];
                                                const reader = new FileReader();
                                                reader.addEventListener('load', () => {
                                                    /*
                                                      Note: Now we need to register the blob in TinyMCEs image blob
                                                      registry. In the next release this part hopefully won't be
                                                      necessary, as we are looking to handle it internally.
                                                    */

                                                    // console.log(editorRef.current.getContent())
                                                    const id = 'blobid' + (new Date()).getTime();
                                                    const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                    //   editorRef.current.editorUpload.blobCache

                                                    //Editor.activeEditor.editorUpload.blobCache;

                                                    const base64 = reader.result.split(',')[1];
                                                    const blobInfo = blobCache.create(id, file, base64);
                                                    blobCache.add(blobInfo);

                                                    /* call the callback and populate the Title field with the file name */
                                                    cb(blobInfo.blobUri(), { title: file.name });
                                                });
                                                reader.readAsDataURL(file);
                                            });

                                            input.click();
                                        },
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </Form.Group>



                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{ width: 100 }} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_warehouse.save_button")}</Button>
                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                    clearFindingModal();
                                    setFindingModal(false);
                                }}>{t("index_warehouse.cancel_button")}</Button>
                            </div>
                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}