import ReloadPict from "../../Assets/Images/reload-pict.png"
import "./NumStatMainEngine1.css"

export default function NumStat1({ text, text1, temperature }) {
    return (
        <div className="numStat1">
            <div className="textNumStat1">
                <div className="fontSize13" >{text}</div>
                <div className="fontSize13">{text1}</div>
            </div>
            <div className="statCel">{temperature}</div>
            <div><img src={ReloadPict} className="reloadPic" alt={"Reload Pict"} /></div>
        </div>
    )
}

