import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { omit } from 'lodash';
import { useCookies } from "react-cookie";
import {
    getRackCoordinate, getRackCoordinateCount, isTokenValid, insertUpdateRackCoordinate, deleteRackCoordinate,
    getRackCoordinateById, getWarehouse, getRack, getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import FormSelect from "../../../Components/FormSelect"
import Select from 'react-select';
import { Trans, useTranslation } from "react-i18next";

export default function RackCoordinatePage() {
    const [listRackCoordinate, setListRackCoordinate] = useState([]);
    const [listWarehouse, setListWarehouse] = useState([]);
    const [listRack, setListRack] = useState([]);
    const { t, i18n } = useTranslation();
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [errors, setErrors] = useState({});
    const [selectedWarehouse, setSelectedWarehouse] = useState({ id: "" });
    const [selectedRack, setSelectedRack] = useState({ id: "" });
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_rack_coordinate.order_name1")}`,
        value: "warehouse.warehouse_name"
    });
    const orderByList = [{
        label: `${t("index_rack_coordinate.order_name1")}`,
        value: "warehouse.warehouse_name"
    }, {
        label: `${t("index_rack_coordinate.order_name2")}`,
        value: "rack_code"
    }, {
        label: `${t("index_rack_coordinate.order_name3")}`,
        value: "rackCoordinate.coordinate_name"
    }];
    const [rackCoordinateCount, setRackCoordinateCount] = useState(0);
    const [descending, setDescending] = useState(false);
    const [rackCoordinate, setRackCoordinate] = useState({
        id: "",
        warehouse_id: null,
        rack_id: null,
        coordinate_name: "",
        ext_customer_id: cookies.extCustomerId
    });
    const [disabledButton, setDisabledButton] = useState(false);
    const [rackCoordinateId, setRackCoordinateId] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [rackCoordinateModal, setRackCoordinateModal] = useState("")


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadRackCoordinate();
                loadRack();
                loadWarehouse();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadRackCoordinate();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadRackCoordinate();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadRackCoordinate();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadRackCoordinate();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadRackCoordinate()
    }, [descending]);

    useEffect(() => {
        if (selectedWarehouse.warehouse_name) {
            setRackCoordinate({ ...rackCoordinate, warehouse_id: selectedWarehouse.id });
        } else {
            setRackCoordinate({ ...rackCoordinate, warehouse_id: null });
        }
    }, [selectedWarehouse]);

    useEffect(() => {
        if (selectedRack.rack_name) {
            setRackCoordinate({ ...rackCoordinate, rack_id: selectedRack.id });
        } else {
            setRackCoordinate({ ...rackCoordinate, rack_id: null });
        }
    }, [selectedRack]);

    useEffect(() => {
        if (rackCoordinateId !== "" && rackCoordinateId !== undefined) {
            initRackCoordinate();
        }

    }, [rackCoordinateId]);

    useEffect(() => {
        if (!rackCoordinateModal) {
            setRackCoordinateId("")
        }
    }, [rackCoordinateModal])


    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeRackCoordinate();
    }, [removeId]);

    useEffect(() => {
        if (rackCoordinate.warehouse_id) {
            let warehouse = listWarehouse.find(p => p.id === rackCoordinate.warehouse_id);
            if (warehouse) {
                setSelectedWarehouse(warehouse);
            }

        }
        if (rackCoordinate.rack_id) {
            let rack = listRack.find(p => p.id === rackCoordinate.rack_id);
            if (rack) {
                setSelectedRack(rack);
            }

        }

        validationForm();
    }, [rackCoordinate])

    const loadRackCoordinate = async () => {
        try {
            let count = await getRackCoordinateCount(cookies.token, cookies.extCustomerId, searchQuery, false);
            setRackCoordinateCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);


            let response = await getRackCoordinate(cookies.token, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListRackCoordinate(response)
            setLoading(false);
            setResetSearch(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    const clearRackCoordinateModal = async () => {
        let rackCoordianteTmp = {};
        rackCoordianteTmp.id = 0;
        rackCoordianteTmp.warehouse_id = null;
        rackCoordianteTmp.rack_id = null;
        rackCoordianteTmp.coordinate_name = "";
        rackCoordianteTmp.ext_customer_id = cookies.extCustomerId
        setRackCoordinate(rackCoordianteTmp);
        setSelectedRack({});
        setSelectedWarehouse({});
    }

    const saveData = async () => {
        try {
            let rackCoordianteTmp = rackCoordinate;
            rackCoordianteTmp.ext_customer_id = rackCoordianteTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : rackCoordianteTmp.ext_customer_id
            let response = await insertUpdateRackCoordinate(cookies.token, rackCoordianteTmp);
            if (response.error_code === 0) {
                alert(`${t("index_rack_coordinate.alert_success_save_data")}`);
                setRackCoordinateModal(false);
                clearRackCoordinateModal();
                loadRackCoordinate();
                setDisabledButton(false);
            } else {
                alert(`${t("index_rack_coordinate.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const initRackCoordinate = async () => {
        try {
            let response = await getRackCoordinateById(cookies.token, rackCoordinateId);
            if (response) {
                setRackCoordinate({
                    ...rackCoordinate,
                    id: response.id,
                    warehouse_id: response.warehouse_id,
                    rack_id: response.rack_id,
                    coordinate_name: response.coordinate_name
                })
            }
            setRackCoordinateModal(true);

        } catch (exception) {
            console.log(exception)
        }
    }

    const removeRackCoordinate = async () => {
        try {
            let response = await deleteRackCoordinate(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_rack_coordinate.delete_success_alert")}`);
                loadRackCoordinate();
            } else {
                alert(`${t("index_rack_coordinate.delete_failed_alert")}`);
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadWarehouse = async () => {
        try {
            let response = await getWarehouse(cookies.token, cookies.extCustomerId);
            setListWarehouse(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadRack = async () => {
        try {
            let response = await getRack(cookies.token, cookies.extCustomerId);
            setListRack(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = async () => {
        try {
            if (rackCoordinate.warehouse_id === null || rackCoordinate.warehouse_id === undefined) {
                setErrors({
                    ...errors,
                    warehouseError: `${t("index_rack_coordinate.error_empty_field")}`
                },
                    setDisabledButton(true))
            } else if (rackCoordinate.rack_id === null || rackCoordinate.rack_id === undefined) {
                setErrors({
                    ...errors,
                    rackError: `${t("index_rack_coordinate.error_empty_field")}`
                },
                    setDisabledButton(true));
            }
            else {
                let errorMsg = omit(errors, "warehouseError", "rackError");
                setErrors(errorMsg);
                setDisabledButton(false);
            }


        } catch (exception) {
            console.log(exception)
        }
    }

    const styles = {
        valueContainer: (base) => ({
            ...base,
            display: "flex",
            width: 200,
            borderWidth: 1,
            borderStyle: "solid",
        }),
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_rack_coordinate.order_name1")}`,
                value: "warehouse.warehouse_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_rack_coordinate.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setRackCoordinate({ ...rackCoordinate, id: 0 })
                                setRackCoordinateModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_rack_coordinate.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_rack_coordinate.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                setPage(0);
                                                await loadRackCoordinate();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadRackCoordinate();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadRackCoordinate();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_rack_coordinate.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_rack_coordinate.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_rack_coordinate.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_rack_coordinate.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_rack_coordinate.table_name5")}</div>
                                </div>
                                {
                                    listRackCoordinate.map((rackCoordinates, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 0.5, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{rackCoordinates.warehouse_code}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{rackCoordinates.rack_code}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{rackCoordinates.coordinate_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        // navigate("/Country/Detail", { state: { countryId: country.id } });
                                                        let idRackCoordinate = rackCoordinates.id;
                                                        setRackCoordinateId(idRackCoordinate);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                        // navigate("/Country/Detail", { state: { countryId: country.id } });
                                                        let idRackCoordinate = rackCoordinates.id;
                                                        if (window.confirm(`${t("index_rack_coordinate.delete_confirmation")}  ${rackCoordinates.coordinate_name}`))
                                                            setRemoveId(idRackCoordinate);
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />

                {/* <Modal show={rackCoordinateModal} onHide={() => {
                    setRackCoordinateModal(false);
                    clearRackCoordinateModal();
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Data Koordinat Rak`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    setDisabledButton(true);
                                    e.preventDefault();
                                    saveData()
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flexWrap: "nowrap",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Kode Gudang</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                               
                                                <Select styles={styles} placeholder={"Pilih Gudang"}
                                                    getOptionLabel={(item) => {
                                                        return item.warehouse_code;
                                                    }} clearValue={true}
                                                    getOptionValue={(item) => {
                                                        return item.id;
                                                    }}
                                                    options={listWarehouse} value={selectedWarehouse} onChange={(e) => {
                                                        if (e === null) {
                                                            setSelectedWarehouse({})
                                                        } else {
                                                            setSelectedWarehouse(e);
                                                        }
                                                    }}
                                                    isClearable
                                                    required
                                                />

                                            </div>
                                            {
                                                errors.warehouseError && <p style={{ color: "red" }}>{errors.warehouseError}</p>
                                            }
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Kode Rak</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                              
                                                <Select styles={styles} placeholder={"Pilih Rack"}
                                                    getOptionLabel={(item) => {
                                                        return item.rack_code;
                                                    }} clearValue={true}
                                                    getOptionValue={(item) => {
                                                        return item.id;
                                                    }}
                                                    options={listRack} value={selectedRack} onChange={(e) => {
                                                        if (e === null) {
                                                            setSelectedRack({})
                                                        } else {
                                                            setSelectedRack(e);
                                                        }
                                                    }}
                                                    isClearable

                                                />

                                            </div>
                                            {
                                                errors.rackError && <p style={{ color: "red" }}>{errors.rackError}</p>
                                            }
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Koordinat Rak</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setRackCoordinate({ ...rackCoordinate, coordinate_name: e.target.value })
                                                }} value={rackCoordinate.coordinate_name} type="text" placeholder="" required></Form.Control>

                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        paddingTop: 20,
                                    }}>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" type="submit" disabled={disabledButton}>
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            clearRackCoordinateModal();
                                            setRackCoordinateModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal> */}

                <Modal show={rackCoordinateModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setRackCoordinateModal(false);
                        clearRackCoordinateModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_rack_coordinate.add_modal_coordinate_rack")}</h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_rack_coordinate.field_warehouse_code")}</Form.Label>


                                <Select placeholder={"Pilih Gudang"}
                                    getOptionLabel={(item) => {
                                        return item.warehouse_code;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listWarehouse} value={selectedWarehouse} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedWarehouse({})
                                        } else {
                                            setSelectedWarehouse(e);
                                        }
                                    }}
                                    isClearable
                                    required
                                />
                                {
                                    errors.warehouseError && <p style={{ color: "red" }}>{errors.warehouseError}</p>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_rack_coordinate.field_rack_code")}</Form.Label>


                                <Select placeholder={"Pilih Rack"}
                                    getOptionLabel={(item) => {
                                        return item.rack_code;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listRack} value={selectedRack} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedRack({})
                                        } else {
                                            setSelectedRack(e);
                                        }
                                    }}
                                    isClearable

                                />


                                {
                                    errors.rackError && <p style={{ color: "red" }}>{errors.rackError}</p>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_rack_coordinate.field_rack_coordinate")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setRackCoordinate({ ...rackCoordinate, coordinate_name: e.target.value })
                                }} value={rackCoordinate.coordinate_name} type="text" placeholder="" required></Form.Control>

                            </Form.Group>

                            <div style={{ paddingBottom: 10 }}></div>
                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                    <></>
                                    :
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "center"
                                    }}>
                                        <Button style={{ width: 100 }} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_rack_coordinate.save_button")}</Button>
                                        <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                            clearRackCoordinateModal();
                                            setRackCoordinateModal(false);
                                        }}>{t("index_rack_coordinate.cancel_button")}</Button>
                                    </div>
                            }


                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}