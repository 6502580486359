import "./NavButton.css"
import { Button } from "react-bootstrap";

export default function NavButton({ text1, text2, handleOnClick }) {
    return (
        <>
            <Button variant="outline-primary"
                className="navButton"
                onClick={() => { handleOnClick() }}
            >
                <div>{text1}</div>
                <div>{text2}</div>
            </Button>
        </>
    );
}