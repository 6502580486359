import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { Button, Form, Container, Modal, Table, Tabs, Tab } from 'react-bootstrap';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import {
    isTokenValid, getStaff, getVesselByCompany, getCompany, insertUpdateProject,
    getProjectType, getProjectStatus, insertUpdateCompany, getCompanyCategory, insertUpdateVessel,
    getVesselType, getProjectById, getProjectxProjectType, getContract, getVesselProject, getProjectContract,
    insertUpdateContract, getProjectSurvey, getProjectCount, getCustomer, insertUpdateCustomer, getStatusBilling,
    insertUpdateProjectDocument, getProjectDocument, getProjectDocumentById, deleteProjectDocument, convertBase64,
    getDocumentProjectType, getUserAccessById, getProjectAssignment, getProjectAssignmentById, getProjectAssignmentCount,
    insertUpdateProjectAssignment, deleteProjectAssignment, getDocumentProjectAssignment
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import ContainerBox from "../../../../Components/ContainerBox";
import FormSelect from "../../../../Components/FormSelect";
import ButtonForm from "../../../../Components/ButtonForm";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import {
    Search, ArrowLeft, ArrowClockwise, EyeFill, Download, Trash, FilePlusFill, XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, PencilFill, PrinterFill
} from "react-bootstrap-icons";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
import moment from 'moment';
import { Trans, useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function ProjectDetailPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie] = useCookies(["token"]);
    const [companyCategoryOptions, setCompanyCategoryOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [listAssignment, setListAssignment] = useState([]);
    const [assignmentModal, setAssignmentModal] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [disabledPrint, setDisabledPrint] = useState(false);
    const [listCustomer, setListCustomer] = useState([]);
    const { t, i18n } = useTranslation();
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState("");
    const [listDocument, setListDocument] = useState([]);
    const [listDocumentType, setListDocumentType] = useState([]);
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [contractOptions, setContractOptions] = useState([]);
    const [vesselOptions, setVesselOptions] = useState([]);
    const [documentProjectAssignmentId, setDocumentProjectAssignmentId] = useState("");
    const [detailProjectAssignment, setDetailProjectAssignment] = useState({});
    const [vesselTypeOptions, setVesselTypeOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [projectAssignmentId, setProjectAssignmentId] = useState("");
    const [staffOptions, setStaffOptions] = useState([]);
    const [errors, setErrors] = useState([]);
    const [projectTypeOptions, setProjectTypeOptions] = useState([]);
    const [listProjectType, setListProjectType] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [projectId, setProjectId] = useState("");
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [showAddVessel, setShowAddVessel] = useState("");
    const [showAddCustomer, setShowAddCustomer] = useState("");
    const [showaddContract, setShowAddContract] = useState("");
    const [loading, setLoading] = useState(true);
    const [disabledForm, setDisabledForm] = useState(false);
    const [listCompany, setListCompany] = useState([]);
    const [listVessel, setListVessel] = useState([]);
    const [companyId, setCompanyId] = useState(0);
    const [listStaff, setListStaff] = useState([]);
    const [listSurvey, setListSurvey] = useState([]);
    const [projectTypeName, setProjectTypeName] = useState(false);
    const [showSurvey, setShowSurvey] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [listStatusBilling, setListStatusBilling] = useState([]);
    const [customerName, setCustomerName] = useState("");
    const [disabledModalButton, setDisabledModalButton] = useState(false);
    const [disabledCustomerButtonModal, setDisabledCustomerButtonModal] = useState(false);
    const [disabledContractSaveButton, setDisabledContractSaveButton] = useState(false);
    const [listProjectStatus, setListProjectStatus] = useState([]);
    const [projectCount, setProjectCount] = useState(0);
    const [page, setPage] = useState(0);
    const [descending, setDescending] = useState(true);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [isProjectChecked, setIsProjectChecked] = useState(false);
    const [downloadId, setDownloadId] = useState("");
    const [removeDocumentId, setRemoveDocumentId] = useState("");
    const [docxData, setDocxData] = useState("");
    const [projectDocumentId, setProjectDocumentId] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(0.4);
    const [disableDocumentButton, setDisabledDocumentButton] = useState("");
    const [removeProjectAssignmentId, setRemoveProjectAssignmentId] = useState("");
    const [documentFile, setDocumentFile] = useState("");
    const [orderBy, setOrderBy] = useState({
        label: "Nomor Dokumen",
        value: "document_name"
    });

    const orderByList = [
        {
            label: "Nama Dokumen",
            value: "document_name"
        }
    ]

    const checkboxValue = [{
        label: "Proyek",
        value: true
    }, {
        label: "Proyek Marketing",
        value: false,
    }]
    const [isDescActive, setIsDescActive] = useState(true);

    const [projectAssignment, setProjectAssignment] = useState({
        id: 0,
        project_id: location.state.projectId,
        job_name: "",
        language_id: cookies.languageId
    })

    const [listTechnician, setListTechnician] = useState([])

    const [project, setProject] = useState({
        id: 0,
        project_name: "",
        customer_id: null,
        vessel_id: null,
        project_start_date: "",
        project_closing_date: null,
        project_status_id: null,
        project_pic_staff_id: null,
        customer_pic_name: "",
        project_location: "",
        latitude: null,
        longitude: null,
        project_number: "",
        customer_pic_title: "",
        contract_id: null,
        project_type_id: null,
        survey_id: null,
        survey_project_name: "",
        spmk_number: "",
        customer_pic_phone_number: "",
        billing_status_id: null,
        vessel_location: "",
        vessel_arrived_date: null,
        estimated_start_date: null,
        estimated_end_date: null,
        ext_customer_id: cookies.extCustomerId
        // is_project: false

    })

    const [vessel, setVessel] = useState({
        vessel_name: "",
        customer_id: "",
        vessel_type_id: "",
        imo_number: "",
        serial_number: "",
        built_date: null,
        // built_shipyard: "",
        vessel_code: "",
        ext_customer_id: cookies.extCustomerId
    });


    const resetVessel = async () => {
        setVessel({
            ...vessel,
            vessel_name: "",
            customer_id: "",
            vessel_type_id: "",
            imo_number: "",
            serial_number: "",
            built_date: null,
            // built_shipyard: "",
            vessel_code: "",
            ext_customer_id: cookies.extCustomerId

        })
    }

    const resetModalAssignment = async () => {
        let modaltmp = {
            id: 0,
            project_id: location.state.projectId,
            job_name: "",
            language_id: cookies.languageId
        }
        setProjectAssignment(modaltmp);
        setListTechnician([]);
    }

    const [customer, setCustomer] = useState({
        id: 0,
        customer_name: "",
        customer_type: "",
        city: "",
        address_1: "",
        address_2: "",
        company_id: null,
        ext_customer_id: cookies.extCustomerId
    });

    const resetCustomer = async () => {
        setCustomer({
            ...customer,
            id: 0,
            customer_name: "",
            customer_type: "",
            city: "",
            address_1: "",
            address_2: "",
            company_id: null,
            ext_customer_id: cookies.extCustomerId
        })
    }

    const [contract, setContract] = useState({
        id: 0,
        contract_name: "",
        contract_date: null,
        contract_external_number: null,
        customer_id: null,
        description: "",
        ext_customer_id: cookies.extCustomerId
    });

    const resetContract = async () => {
        setContract({
            ...contract,
            contract_name: "",
            contract_date: null,
            contract_external_number: null,
            customer_id: null,
            description: "",
            ext_customer_id: cookies.extCustomerId
        });
    }

    const [newDocument, setNewDocument] = useState({
        id: 0,
        project_id: "",
        document_base64: "",
        document_name: "",
        description: "",
        is_active: true,
        done: false,
        reference_number: "",
        document_type: null,
        document_date: null
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        project_id: "",
        document_base64: "",
        document_name: "",
        description: "",
        reference_number: "",
        is_active: true,
        document_type: null,
        document_date: null
    });

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            project_id: projectId,
            document_base64: "",
            document_name: "",
            description: "",
            reference_number: "",
            document_type: null,
            document_date: null,
            is_active: true,
            done: false
        };
        setNewDocument({ ...newDocument, newDocument });
    }


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadCustomer();
                loadCompany();
                loadStaff();
                loadProjectType();
                loadProjectStatus();
                loadVesselType();
                loadCompanyCategory();
                loadContract();
                loadStatusBilling();
                loadDocumentType();
                loadAccess();
                var today = new Date(),
                    date = today.getFullYear() + '-' + (String(today.getMonth() + 1).padStart(2, '0')) + '-' + String(today.getDate()).padStart(2, '0');
                // console.log(String(today.getMonth()+ 1).padStart(2, '0'));

                let currentDateTime = new Date();
                currentDateTime.setDate(currentDateTime.getDate() + 7)

                let dates = ("0" + currentDateTime.getDate()).slice(-2);
                let month = ("0" + (currentDateTime.getMonth() + 1)).slice(-2);
                let year = currentDateTime.getFullYear();
                let todaysDate = year + '-' + month + '-' + dates
                setProject({ ...project, project_start_date: date, project_closing_date: todaysDate });
                setLoading(false);
                if (searchParams.get("id") !== null) {
                    setProjectId(searchParams.get("id"))
                } else {
                    setProjectId(location.state.projectId);
                }

                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        modalValidation();
    }, [vessel]);

    useEffect(() => {
        if (docxData.size > 0) {
            downloadDocxFile();
        }
    }, [docxData]);

    useEffect(() => {
        if (projectAssignmentId !== "") {
            initProjectAssignment();
        }
    }, [projectAssignmentId])

    useEffect(() => {
        if (!assignmentModal) {
            setProjectAssignmentId("");

        }
    }, [assignmentModal]);

    useEffect(() => {
        if (removeProjectAssignmentId !== "") {
            removeProjectAssignment()
        }
    }, [removeProjectAssignmentId])

    useEffect(() => {
        console.log(projectAssignment);
    }, [projectAssignment])

    useEffect(() => {
        console.log(listTechnician);
    }, [listTechnician])

    useEffect(() => {
        if (projectId !== 0 && projectId !== "") {
            setNewDocument({ ...newDocument, project_id: projectId });
            initProject();
            loadDocumentData();
        }
    }, [projectId])

    useEffect(() => {
        contractValidation();
    }, [contract])

    useEffect(() => {
        if (project.customer_id !== null)
            loadVessel();
    }, [project.customer_id]);

    useEffect(() => {
        validationForm();
    }, [project]);

    useEffect(() => {
        if (projectId === 0 || projectId === "") {
            let status = listProjectStatus.find(p => p.project_status_code === "Open")
            if (status) { setProject({ ...project, project_status_id: status.id }) }
        }
    }, [listProjectStatus])

    useEffect(() => {
        if (projectDocumentId !== "")
            loadDocumentById();
    }, [projectDocumentId]);

    useEffect(() => {
        if (downloadId !== "")
            downloadData();
    }, [downloadId]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }


    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadDocument();
            }
        }
        submitNewDocument();
    }, [newDocument]);

    useEffect(() => {

        if (removeDocumentId !== "") {
            setLoading(true);
            removeDocument();
        }

    }, [removeDocumentId]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (!showDocumentDetailModal) {
            setDocumentToBeViewed({
                id: 0,
                project_id: "",
                document_base64: "",
                document_name: "",
                description: "",
                reference_number: "",
                is_active: true,
                document_type: null,
                document_date: null,
            });
            setProjectDocumentId("")
        }
    }, [showDocumentDetailModal]);

    useEffect(()=>{
        if(documentProjectAssignmentId !== ""){
            createDocument()
        }
    },[documentProjectAssignmentId])



    const loadVessel = async () => {
        try {
            let response = await getVesselProject(cookies.token, cookies.languageId, project.customer_id);
            setListVessel(response);
            let listVesselNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_name,
                    id: response[i].id,
                    customerId: response[i].customer_id

                };
                listVesselNew.push(obj);
            }
            setVesselOptions(listVesselNew);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');

        }
    }

    const loadCustomer = async () => {
        try {
            let response = await getCustomer(cookies.token, cookies.extCustomerId);
            let newList = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].customer_name,
                    id: response[i].id

                };

                newList.push(obj);

            }
            setListCustomer(newList);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadCompany = async () => {
        try {
            let response = await getCompany(cookies.token, cookies.languageId, cookies.extCustomerId);
            let newList = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].company_name,
                    id: response[i].id

                };

                newList.push(obj);

            }
            setListCompany(newList);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadContract = async () => {
        try {
            let response = await getContract(cookies.token, cookies.languageId, cookies.extCustomerId);
            let newList = response.filter(p => p.contract_status_code !== "CLOSED");
            let listContract = [];
            for (let i = 0; i < newList.length; i++) {
                var obj = {
                    value: newList[i].id,
                    label: newList[i].contract_name,
                    id: newList[i].id,
                    customerId: newList[i].customer_id

                };

                listContract.push(obj);

            }
            setContractOptions(listContract);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListStaff(response);
            let listStaffNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id

                };

                listStaffNew.push(obj);

            }
            setStaffOptions(listStaffNew);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadVesselType = async () => {
        try {
            let response = await getVesselType(cookies.token, cookies.languageId);
            let listVesselTypeNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].vessel_type_name,
                    id: response[i].id
                };

                listVesselTypeNew.push(obj);

            }
            setVesselTypeOptions(listVesselTypeNew);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadProjectType = async () => {
        try {
            let response = await getProjectType(cookies.token, cookies.languageId);
            setListProjectType(response);
            let listProjectTypeNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].project_type_name,
                    id: response[i].id

                };

                listProjectTypeNew.push(obj);

            }
            setProjectTypeOptions(listProjectTypeNew);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadCompanyCategory = async () => {
        try {
            let response = await getCompanyCategory(cookies.token, cookies.languageId);
            let listCompanyCategory = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].company_category,
                    id: response[i].id

                };

                listCompanyCategory.push(obj);

            }
            setCompanyCategoryOptions(listCompanyCategory);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadProjectStatus = async () => {
        try {
            let response = await getProjectStatus(cookies.token, cookies.languageId);
            setListProjectStatus(response);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const saveData = async () => {
        try {
            let projectTmp = project;
            projectTmp.ext_customer_id = projectTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : project.ext_customer_id
            let response = await insertUpdateProject(cookies.token, projectTmp, isProjectChecked);
            if (response.error_code === 0) {
                alert(`${t("detail_project.alert_success_save_data")}`);
                navigate('/Project')
            } else {
                alert(`${t("detail_project.alert_failed_save_data")}`);
                setLoading(false);
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
            setDisabledButton(false);
        }
    }

    const initProject = async () => {
        try {
            let response = await getProjectById(cookies.token, projectId);
            if (response) {
                setProject({
                    ...project,
                    id: response.id,
                    project_name: response.project_name,
                    customer_id: response.customer_id,
                    vessel_id: response.vessel_id,
                    project_start_date: response.project_start_date,
                    project_closing_date: response.project_closing_date,
                    project_status_id: response.project_status_id,
                    project_pic_staff_id: response.project_pic_staff_id,
                    project_location: response.project_location,
                    project_number: response.project_number,
                    customer_pic_name: response.customer_pic_name,
                    customer_pic_title: response.customer_pic_title,
                    contract_id: response.contract_id,
                    project_type_id: response.project_type_id,
                    survey_id: response.survey_id,
                    survey_project_name: response.survey_project_name,
                    is_project: response.is_project,
                    estimated_start_date: response.estimated_start_date,
                    estimated_end_date: response.estimated_end_date,
                    spmk_number: response.spmk_number,
                    customer_pic_phone_number: response.customer_pic_phone_number,
                    billing_status_id: response.billing_status_id,
                    vessel_location: response.vessel_location,
                    vessel_arrived_date: response.vessel_arrived_date
                })
                setProjectTypeName(response.project_type_name);
                setIsProjectChecked(response.is_project);
                setVesselName(response.vessel_name);
                setCustomerName(response.customer_name);
                if (response.project_status_code === "CLOSED") {
                    setDisabledForm(true);
                    setDisabledButton(true);
                }

                loadProjectAssignment();

            } else {

            }
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const addVessel = async () => {
        try {
            let vesselTmp = vessel;
            vesselTmp.ext_customer_id = vesselTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : vesselTmp.ext_customer_id
            let response = await insertUpdateVessel(cookies.token, vesselTmp, 0);
            if (response.error_code === 0) {
                alert(`${t("detail_project.alert_success_save_data")}`);
                setShowAddVessel(false);
                loadVessel();
                resetVessel();
            } else {
                alert(`${t("detail_project.alert_failed_save_data")}`);
            }
            setDisabledModalButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const addCustomer = async () => {
        try {
            let customerTmp = customer;
            customerTmp.ext_customer_id = customerTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : customerTmp.ext_customer_id
            let response = await insertUpdateCustomer(cookies.token, customerTmp);
            if (response.error_code === 0) {
                alert(`${t("detail_project.alert_success_save_data")}`);
                setShowAddCustomer(false);
                loadCustomer();
                resetCustomer();
                setDisabledCustomerButtonModal(false);
            } else {
                alert(`${t("detail_project.alert_failed_save_data")}`);
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const addContract = async () => {
        try {
            let contractTmp = contract;
            contractTmp.ext_customer_id = contractTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : contractTmp.ext_customer_id
            let response = await insertUpdateContract(cookies.token, contractTmp)
            if (response.error_code === 0) {
                alert(`${t("detail_project.alert_success_save_data")}`);
                setShowAddContract(false);
                loadContract();
                resetContract();
            }

        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (project.project_name === undefined || project.project_name === "") {
                    setErrors({
                        ...errors,
                        projectName: `${t("detail_project.error_empty_field")}`
                    },
                        setDisabledButton(true));
                } else if (project.project_number === undefined || project.project_number === "") {
                    setErrors({
                        ...errors,
                        projectNumber: `${t("detail_project.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else if (project.customer_id === undefined || project.customer_id === null) {
                    setErrors({
                        ...errors,
                        projectCustomer: `${t("detail_project.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                // else if (project.vessel_id === undefined || project.vessel_id === null) {
                //     setErrors({
                //         ...errors,
                //         projectVessel: `${t("detail_project.error_empty_field")}`
                //     },
                //         setDisabledButton(true));
                // }


                else if (project.project_pic_staff_id === undefined || project.project_pic_staff_id === null) {
                    setErrors({
                        ...errors,
                        projectPIC: `${t("detail_project.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "projectName", "projectNumber", "projectCustomer",  "projectPIC");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }



                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const modalValidation = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (vessel.vessel_name === undefined || vessel.vessel_name === "") {
                    setErrors({
                        ...errors,
                        vesselNameForm: `${t("detail_project.error_empty_field")}`
                    },
                        setDisabledModalButton(true));
                } else if (vessel.customer_id === undefined || vessel.customer_id === "") {
                    setErrors({
                        ...errors,
                        companyNameForm: `${t("detail_project.error_empty_field")}`
                    },
                        setDisabledModalButton(true));
                } else if (vessel.vessel_type_id === undefined || vessel.vessel_type_id === "") {
                    setErrors({
                        ...errors,
                        vesselTypeForm: `${t("detail_project.error_empty_field")}`
                    })
                    setDisabledModalButton(true)
                }
                else {
                    let errorMsg = omit(errors, "vesselNameForm", "companyNameForm", "vesselTypeForm");
                    setErrors(errorMsg);
                    setDisabledModalButton(false);
                }



                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }



    const contractValidation = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (contract.contract_name === undefined || contract.contract_name === "") {
                    setErrors({
                        ...errors,
                        contractNameError: `${t("detail_project.error_empty_field")}`
                    },
                        setDisabledContractSaveButton(true));
                } else if (contract.customer_id === undefined || contract.customer_id === null) {
                    setErrors({
                        ...errors,
                        customerInContractError: `${t("detail_project.error_empty_field")}`
                    },
                        setDisabledContractSaveButton(true));
                } else if (contract.contract_date === undefined || contract.contract_date === null) {
                    setErrors({
                        ...errors,
                        contractDateError: `${t("detail_project.error_empty_field")}`
                    })
                    setDisabledContractSaveButton(true)
                } else if (contract.contract_external_number === undefined || contract.contract_external_number === null) {
                    setErrors({
                        ...errors,
                        contractNumberError: `${t("detail_project.error_empty_field")}`
                    })
                    setDisabledContractSaveButton(true)
                }
                else {
                    let errorMsg = omit(errors, "contractNameError", "customerInContractError", "contractDateError", "contractNumberError");
                    setErrors(errorMsg);
                    setDisabledContractSaveButton(false);
                }



                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadProjectSurvey = async () => {
        try {
            let projectCount = await getProjectCount(cookies.token);
            setProjectCount(projectCount);
            let totalPage = projectCount / itemPerPage;
            setTotalPage(totalPage);
            let descending = isDescActive;
            let response = await getProjectSurvey(cookies.token, cookies.languageId, page, itemPerPage, undefined, descending, searchQuery, true, vesselName, customerName);
            setListSurvey(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStatusBilling = async () => {
        try {
            let response = await getStatusBilling(cookies.token);
            setListStatusBilling(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const uploadDocument = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateProjectDocument(cookies.token, newDocument);

                setShowDocumentUploadModal(false);
                resetUploadForm();
                loadDocumentData();
                setDisabledDocumentButton(false);
                resolve();
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }

    const loadDocumentData = async () => {
        try {
            let count = await getProjectDocument(cookies.token, projectId);
            let totalPage = count.length / itemPerPage;
            setTotalPage(totalPage);

            let response = await getProjectDocument(cookies.token, projectId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListDocument(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeDocument = async () => {
        let response = await deleteProjectDocument(cookies.token, removeDocumentId);
        if (response) {
            loadDocumentData();
            alert(`${t("detail_project.delete_success_alert")}`)
        } else {
            alert(`${t("detail_project.delete_failed_alert")}`);
            setLoading(false);
        }
    }

    const loadDocumentById = async () => {
        try {
            let response = await getProjectDocumentById(cookies.token, projectDocumentId);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadData = async () => {
        try {
            let response = await getProjectDocumentById(cookies.token, downloadId);
            triggerBase64Download(response.document_base64, response.document_name);
            setLoading(false);
            setDownloadId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadDocumentType = async () => {
        try {
            let response = await getDocumentProjectType(cookies.token);
            setListDocumentType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadProjectAssignment = async () => {
        try {
            let response = await getProjectAssignment(cookies.token, location.state.projectId);
            console.log(response);
            setListAssignment(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initProjectAssignment = async () => {
        try {
            let response = await getProjectAssignmentById(cookies.token, projectAssignmentId);
            setProjectAssignment({
                ...projectAssignment,
                id: response.id,
                project_id: response.project_id,
                job_name: response.job_name,

            })
            setListTechnician(response.list_technician);
            setAssignmentModal(true);
        } catch (exception) {
            console.log(exception)
        }
    }

    const saveProjectAssignment = async () => {
        try {
            // console.log(projectAssignment);
            // console.log(listTechnician);
            let response = await insertUpdateProjectAssignment(cookies.token, projectAssignment, listTechnician);
            if (response === 0) {
                alert('Suscces Save Data');
                setAssignmentModal(false);
                resetModalAssignment();
                loadProjectAssignment();
            }
            setLoading(false);
        } catch (exception) {

        }
    }

    const removeProjectAssignment = async () => {
        try {
            let response = await deleteProjectAssignment(cookies.token, removeProjectAssignmentId)
            if (response === 0) {
                alert('Success Delete Data');
            } else {
                alert('failed Delete Data');
            }
            loadProjectAssignment();
            setRemoveProjectAssignmentId("");
        } catch (exception) {

        }
    }

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        element.href = URL.createObjectURL(file);
        element.download = `Surat Tugas- ${detailProjectAssignment.customer_name}-${detailProjectAssignment.vessel_name}-${detailProjectAssignment.project_location}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        URL.revokeObjectURL(file);
        setLoading(false);
        setDetailProjectAssignment({});
        setDisabledPrint(false);
        // setDisabledButton(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getDocumentProjectAssignment(cookies.token, documentProjectAssignmentId)
            if (docx.size) {
                setDocxData(docx);
            } else {
                // alert(`${t("index_daily_report.error_download")}`);
                setLoading(false);
                setDisabledPrint(false);
            }
            setDocumentProjectAssignmentId("")
        } catch (exception) {
            console.log(exception)
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_project.header_title")}`} useActionContainer={true}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Project"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }

                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexWrap: "nowrap",
                                    flexDirection: "column",
                                    borderWidth: 2,
                                    borderColor: "rgba(3, 30, 103, 1)",
                                    borderRadius: 10,
                                    borderStyle: "solid",

                                }}>
                                    <Tabs
                                        defaultActiveKey="project"
                                        // transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3"
                                        style={{
                                            backgroundColor: "rgba(3, 30, 103, 1)",
                                            borderRadius: 5,

                                        }}
                                    >
                                        <Tab eventKey="project" title={`${t("detail_project.tab_1")}`}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }}>

                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>

                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_project.header_project")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                flexDirection: "row"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexWrap: "nowrap",
                                                                    flexDirection: "column",
                                                                    paddingRight: 10
                                                                }}>
                                                                    <TextBox value={project.project_name} placeholder=""
                                                                        caption={`${t("detail_project.field_project_name")}`} setValueFunction={(e) => {
                                                                            setProject({ ...project, project_name: e.target.value });
                                                                        }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />

                                                                    {
                                                                        errors.projectName && <p style={{ color: "red" }}>{errors.projectName}</p>
                                                                    }
                                                                    <FormSelect caption={`${t("detail_project.field_contract_")}`}
                                                                        options={contractOptions} value={contractOptions.filter(function (option) {
                                                                            return option.id === project.contract_id;
                                                                        })} setValueFunction={(e) => {

                                                                            if (e !== null) {
                                                                                setProject({ ...project, contract_id: e.id, customer_id: e.customerId });
                                                                            } else {
                                                                                setProject({ ...project, contract_id: null })
                                                                            }

                                                                        }} addNew={disabledCustomerForm === true ? undefined : `${t("detail_project.add_contract")}`} showModal={() => {
                                                                            setShowAddContract(true);
                                                                        }} disabledForm={projectId !== 0 ? true : false}
                                                                    />
                                                                    <TextBox value={project.project_number} placeholder=""
                                                                        caption={`${t("detail_project.field_project_number")}`} setValueFunction={(e) => {
                                                                            setProject({ ...project, project_number: e.target.value });
                                                                        }} disabledForm={projectId !== 0 ? true : false} />
                                                                    {
                                                                        errors.projectNumber && <p style={{ color: "red" }}>{errors.projectNumber}</p>
                                                                    }

                                                                    <TextBox value={project.spmk_number} placeholder=""
                                                                        caption={`${t("detail_project.field_spmk_number")}`} setValueFunction={(e) => {
                                                                            setProject({ ...project, spmk_number: e.target.value });
                                                                        }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />

                                                                    <FormSelect caption={`${t("detail_project.field_project_type")}`} autoFocusValue={project.project_type_id === undefined} placeholder=""
                                                                        options={projectTypeOptions} value={projectTypeOptions.filter(function (option) {
                                                                            return option.id === project.project_type_id;
                                                                        })} setValueFunction={(e) => {
                                                                            if (e !== null) {
                                                                                setProject({ ...project, project_type_id: e.id });
                                                                                setProjectTypeName(e.label)
                                                                            } else {
                                                                                setProject({ ...project, project_type_id: null })
                                                                            }

                                                                        }} disabledForm={projectId !== 0 ? true : false}
                                                                    />
                                                                    <FormSelect caption={`${t("detail_project.field_customer_name")}`} autoFocusValue={project.customer_id === undefined} placeholder=""
                                                                        options={listCustomer} value={listCustomer.filter(function (option) {
                                                                            return option.id === project.customer_id;
                                                                        })} setValueFunction={(e) => {
                                                                            if (e !== null) {
                                                                                setProject({ ...project, customer_id: e.id });
                                                                                setCustomerName(e.label);
                                                                            } else {
                                                                                setProject({ ...project, customer_id: null, vessel_id: null });
                                                                            }
                                                                        }} addNew={disabledCustomerForm === true ? undefined : `${t("detail_project.add_customer")}`} showModal={() => {
                                                                            setShowAddCustomer(true);
                                                                        }} disabledForm={projectId !== 0 || project.contract_id !== null ? true : false}
                                                                    />
                                                                    {
                                                                        errors.projectCustomer && <p style={{ color: "red" }}>{errors.projectCustomer}</p>
                                                                    }

                                                                </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexWrap: "nowrap",
                                                                    flexDirection: "column",
                                                                    paddingLeft: 10
                                                                }}>



                                                                    <FormSelect caption={`${t("detail_project.field_vessel_name")}`} autoFocusValue={project.vessel_id === undefined} placeholder=""
                                                                        options={vesselOptions} value={vesselOptions.filter(function (option) {
                                                                            return option.id === project.vessel_id;
                                                                        })} setValueFunction={(e) => {
                                                                            if (e !== null) {
                                                                                setProject({ ...project, vessel_id: e.id, customer_id: e.customerId });
                                                                                setVesselName(e.label)
                                                                            } else {
                                                                                setProject({ ...project, vessel_id: null });
                                                                            }
                                                                        }} addNew={disabledCustomerForm === true ? undefined : `${t("detail_project.add_vessel")}`}
                                                                        showModal={() => {
                                                                            setShowAddVessel(true);
                                                                        }} disabledForm={projectId !== 0 ? true : false}
                                                                    />
                                                                    {/* {
                                                                        errors.projectVessel && <p style={{ color: "red" }}>{errors.projectVessel}</p>
                                                                    } */}
                                                                    <TextBox value={project.project_location} placeholder=""
                                                                        caption={`${t("detail_project.field_project_location")}`} setValueFunction={(e) => {
                                                                            setProject({ ...project, project_location: e.target.value });
                                                                        }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />

                                                                    <TextBox value={project.vessel_location} placeholder=""
                                                                        caption={`${t("detail_project.field_vessel_location")}`} setValueFunction={(e) => {
                                                                            setProject({ ...project, vessel_location: e.target.value });
                                                                        }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />

                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>{t("detail_project.field_vessel_arrived_date")}</Form.Label>
                                                                        <Form.Control disabled={disabledCustomerForm} type="datetime-local" value={project.vessel_arrived_date ? moment(project.vessel_arrived_date).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                                            if (e.target.value === "") {
                                                                                setProject({ ...project, vessel_arrived_date: null })
                                                                            } else {
                                                                                setProject({ ...project, vessel_arrived_date: e.target.value })
                                                                            }


                                                                        }}></Form.Control>
                                                                    </Form.Group>

                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>{t("detail_project.checkbox_project")}</Form.Label>

                                                                        <Form.Check name="group1" onClick={(e) => {
                                                                            if (e.target.checked) {
                                                                                setIsProjectChecked(true);
                                                                                setProject({ ...project, is_project: true })
                                                                            } else {
                                                                                setIsProjectChecked(false);
                                                                                setProject({ ...project, is_project: false })
                                                                            }
                                                                        }} label={`${t("detail_project.project_label")}`} defaultChecked={project.is_project} >
                                                                        </Form.Check>



                                                                        {/* <Form.Select onChange={(e)=>{
                                                                setProject({...project, is_project: e.target.value})
                                                            }} value={project.is_project}>
                                                                <option value="">Pilih Jenis Proyek</option>
                                                                <option value={true}>Proyek</option>
                                                                <option value={false}>Proyek Marketing</option>
                                                            </Form.Select> */}

                                                                    </Form.Group>

                                                                    {
                                                                        projectTypeName === "Troubleshooting & Repair" ?
                                                                            <div>
                                                                                <div style={{ fontWeight: "bold", paddingBottom: 5 }}>ID Survey</div>
                                                                                <Form.Control type="text" value={project.survey_project_name} onClick={() => {
                                                                                    setShowSurvey(true);
                                                                                    loadProjectSurvey();
                                                                                }} placeholder="Id Survey" />
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>
                                                            </div>





                                                        </div>
                                                    }>
                                                    </ContainerBox>
                                                </div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>


                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_project.header_project_date")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <TextBox value={project.project_start_date} placeholder=""
                                                                caption={`${t("detail_project.field_project_start_date")}`} controlType="date" setValueFunction={(e) => {
                                                                    let currentDateTime = new Date(e.target.value);
                                                                    currentDateTime.setDate(currentDateTime.getDate() + 7)

                                                                    let date = ("0" + currentDateTime.getDate()).slice(-2);
                                                                    let month = ("0" + (currentDateTime.getMonth() + 1)).slice(-2);
                                                                    let year = currentDateTime.getFullYear();
                                                                    let todaysDate = year + '-' + month + '-' + date

                                                                    setProject({ ...project, project_start_date: e.target.value, project_closing_date: todaysDate });
                                                                }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />
                                                            <TextBox value={project.project_closing_date} placeholder="Tanggal Berakhir Proyek"
                                                                caption={`${t("detail_project.field_project_end_date")}`} controlType="date" setValueFunction={(e) => {
                                                                    setProject({ ...project, project_closing_date: e.target.value });
                                                                }} disabledForm={projectId === 0 || disabledCustomerForm === true ? true : disabledForm} />
                                                            <TextBox req={true} value={project.estimated_start_date ? moment(project.estimated_start_date).format("yyyy-MM-DD") : ""} placeholder=""
                                                                caption={`${t("detail_project.field_estimate_project_start_date")}`} controlType="date" setValueFunction={(e) => {
                                                                    if (e.target.value === "") {
                                                                        setProject({ ...project, estimated_start_date: null });
                                                                    } else {
                                                                        setProject({ ...project, estimated_start_date: e.target.value });
                                                                    }

                                                                }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />
                                                            <TextBox req={true} value={project.estimated_end_date ? moment(project.estimated_end_date).format("yyyy-MM-DD") : ""} placeholder=""
                                                                caption={`${t("detail_project.field_estimate_project_end_date")}`} controlType="date" setValueFunction={(e) => {
                                                                    if (e.target.value === "") {
                                                                        setProject({ ...project, estimated_end_date: null });
                                                                    } else {
                                                                        setProject({ ...project, estimated_end_date: e.target.value });
                                                                    }

                                                                }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />

                                                            <DropDownForm value={project.project_status_id} disabledForm={projectId === 0 || disabledCustomerForm === true ? true : disabledForm} caption={`${t("detail_project.field_project_status")}`} placeholder=""
                                                                setValueFunction={(e) => {
                                                                    setProject({ ...project, project_status_id: e.target.value });
                                                                }} listOption={listProjectStatus} valueKey="id" labelKey="project_status_name" />

                                                            <FormSelect caption={`${t("detail_project.field_billing_status")}`} placeholder=""
                                                                options={listStatusBilling}
                                                                getOptionLabel={(item) => {
                                                                    return item.status_code;
                                                                }} clearValue={true}
                                                                getOptionValue={(item) => {
                                                                    return item.id;
                                                                }}
                                                                value={listStatusBilling.filter(function (option) {
                                                                    return option.id === project.billing_status_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setProject({ ...project, billing_status_id: e.id });
                                                                    } else {
                                                                        setProject({ ...project, billing_status_id: null })
                                                                    }

                                                                }}
                                                                disabledForm={disabledCustomerForm}
                                                            />


                                                        </div>

                                                    }>
                                                    </ContainerBox>


                                                </div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_project.header_project_pic")}`} useActionContainer={true}
                                                        childContent={
                                                            <div style={{
                                                                padding: 10,
                                                            }}>

                                                                <FormSelect caption={`${t("detail_project.field_staff_pic")}`} autoFocusValue={project.project_pic_staff_id === undefined} placeholder=""
                                                                    options={staffOptions} value={staffOptions.filter(function (option) {
                                                                        return option.id === project.project_pic_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setProject({ ...project, project_pic_staff_id: e.id });
                                                                        } else {
                                                                            setProject({ ...project, project_pic_staff_id: null })
                                                                        }

                                                                    }} disabledForm={disabledCustomerForm === true ? true : disabledForm}
                                                                />
                                                                {
                                                                    errors.projectPIC && <p style={{ color: "red" }}>{errors.projectPIC}</p>
                                                                }

                                                                <TextBox value={project.customer_pic_name} placeholder=""
                                                                    caption={`${t("detail_project.field_customer_pic")}`} setValueFunction={(e) => {
                                                                        setProject({ ...project, customer_pic_name: e.target.value });
                                                                    }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />

                                                                <TextBox value={project.customer_pic_title} placeholder=""
                                                                    caption={`${t("detail_project.field_customer_pic_position")}`} setValueFunction={(e) => {
                                                                        setProject({ ...project, customer_pic_title: e.target.value });
                                                                    }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />

                                                                <TextBox value={project.customer_pic_phone_number} placeholder=""
                                                                    caption={`${t("detail_project.field_customer_pic_phone_number")}`} setValueFunction={(e) => {
                                                                        if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                                        } else {
                                                                            setProject({ ...project, customer_pic_phone_number: e.target.value });
                                                                        }

                                                                    }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />
                                                            </div>

                                                        }>
                                                    </ContainerBox>

                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="document" title={`${t("detail_project.tab_2")}`} disabled={projectId === 0 || projectId === "" ? true : false} >
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <ContainerBox
                                                        containerPos="inner"
                                                        titleCaption={`${t("detail_project.header_project_document")}`}
                                                        useActionContainer={!disabledCustomerForm}
                                                        actionContainerChild={
                                                            <div>
                                                                <Button disabled={!updatedButton} variant="secondary" style={{

                                                                }} onClick={() => { setShowDocumentUploadModal(true) }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FilePlusFill size={32} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 10,
                                                                            fontWeight: "bold",
                                                                            fontSize: 18,
                                                                        }}>{t("detail_project.add_button")}</div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        }
                                                        childContent={
                                                            <div className="master-table-inner-container">
                                                                <div className="master-table-searchbar-container">
                                                                    <div className="master-table-searchbar-textbox">
                                                                        <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_project.search_box")}`}
                                                                            onKeyPress={async (e) => {
                                                                                if (e.charCode === 13) {
                                                                                    setLoading(true);
                                                                                    await loadDocumentData();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setSearchQuery(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="master-table-searchbar-button">
                                                                        <div style={{ paddingRight: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                await loadDocumentData();
                                                                            }}><Search /></Button>
                                                                        </div>
                                                                        <div style={{ paddingLeft: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setSearchQuery("");
                                                                                await loadDocumentData();
                                                                            }}><ArrowClockwise /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-container">
                                                                    <div className="table-header">
                                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("detail_project.table_name1")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.table_name2")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.table_name3")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.table_name4")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.table_name5")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.table_name6")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.table_name7")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.table_name8")}</div>
                                                                    </div>
                                                                    {
                                                                        listDocument.map((document, index) => {
                                                                            return (<div key={index} className="table-body">
                                                                                <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{document.reference_number}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{document.document_type}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{document.document_date ? moment(document.document_date).format("DD/MM/yyyy") : ""}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            setLoading(true);
                                                                                            setProjectDocumentId(document.id);
                                                                                        }}><EyeFill />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            setDownloadId(document.id)
                                                                                        }}><Download />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button disabled={disabledCustomerForm === true ? true : !deletedButton} variant="danger" onClick={() => {
                                                                                            if (window.confirm(`${t("detail_project.delete_confirmation")}  ${document.document_name}?`)) {
                                                                                                let documentId = document.id
                                                                                                setRemoveDocumentId(documentId);

                                                                                            }
                                                                                        }}><Trash />
                                                                                        </Button>
                                                                                    </div></div>
                                                                            </div>)
                                                                        }
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    paddingTop: 20,
                                                                }}>

                                                                </div>
                                                                {/* <Paginations
                                                            itemPerPage={itemPerPage}
                                                            totalPage={totalPage}
                                                            page={page}
                                                            setPage={setPage}
                                                            setItemPerPage={setItemPerPage}
                                                            itemPerPageSelection={itemPerPageSelection}
                                                            orderBy={orderBy}
                                                            setOrderBy={setOrderBy}
                                                            orderBySelection={orderByList}
                                                            isDescActive={descending}
                                                            setIsDescActive={setDescending}
                                                        /> */}
                                                                {/* <Loading
                                                            loading={loading}
                                                        /> */}
                                                            </div>
                                                        } />
                                                </div>
                                            </div>

                                        </Tab>
                                        <Tab eventKey="assignment" title={`${t("detail_project.tab_3")}`} disabled={projectId === 0 || projectId === "" ? true : false} >
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <ContainerBox
                                                        containerPos="inner"
                                                        titleCaption={`${t("detail_project.header_project_assignment")}`}
                                                        useActionContainer={!disabledCustomerForm}
                                                        actionContainerChild={
                                                            <div>
                                                                <Button disabled={!updatedButton} variant="secondary" style={{

                                                                }} onClick={() => { setAssignmentModal(true) }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FilePlusFill size={32} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 10,
                                                                            fontWeight: "bold",
                                                                            fontSize: 18,
                                                                        }}>{t("detail_project.add_button")}</div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        }
                                                        childContent={
                                                            <div className="master-table-inner-container">
                                                                <div className="master-table-searchbar-container">
                                                                    <div className="master-table-searchbar-textbox">
                                                                        <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_project.assignment_search_box")}`}
                                                                            onKeyPress={async (e) => {
                                                                                if (e.charCode === 13) {
                                                                                    setLoading(true);
                                                                                    await loadDocumentData();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setSearchQuery(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="master-table-searchbar-button">
                                                                        <div style={{ paddingRight: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                await loadDocumentData();
                                                                            }}><Search /></Button>
                                                                        </div>
                                                                        <div style={{ paddingLeft: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setSearchQuery("");
                                                                                await loadDocumentData();
                                                                            }}><ArrowClockwise /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-container">
                                                                    <div className="table-header">
                                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("detail_project.assignment_table_name1")}</div>
                                                                        <div style={{ flex: 3 }} className="table-header-content">{t("detail_project.assignment_table_name2")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.assignment_table_name3")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.assignment_table_name4")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.assignment_table_name5")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.assignment_table_name6")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.assignment_table_name7")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.assignment_table_name8")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_project.assignment_table_name9")}</div>
                                                                    </div>
                                                                    {
                                                                        listAssignment.map((assignment, index) => {
                                                                            return (<div key={index} className="table-body">
                                                                                <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                <div style={{ flex: 4 }} className="table-body-content"><p>{assignment.project_name}</p></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{assignment.assignment_number}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{assignment.job_name}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{assignment.customer_name}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{assignment.vessel_name}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{assignment.list_technician.map((tech, index) => {
                                                                                    return (
                                                                                        tech.staff_name + (index === assignment.list_technician.length - 1 ? "" : ", ")
                                                                                    )
                                                                                })}</p></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button disabled={disabledPrint} variant="primary" onClick={() => {
                                                                                            setLoading(true);
                                                                                            setDisabledPrint(true);
                                                                                            setDetailProjectAssignment(assignment);
                                                                                            setDocumentProjectAssignmentId(assignment.id)
                                                                                        }}><PrinterFill />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            setProjectAssignmentId(assignment.id)
                                                                                        }}><PencilFill />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button disabled={disabledCustomerForm === true ? true : !deletedButton} variant="danger" onClick={() => {
                                                                                            if (window.confirm(`${t("detail_project.delete_confirmation")}  ${assignment.project_name}?`)) {
                                                                                                setRemoveProjectAssignmentId(assignment.id)
                                                                                            }
                                                                                        }}><Trash />
                                                                                        </Button>
                                                                                    </div></div>
                                                                            </div>)
                                                                        }
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    paddingTop: 20,
                                                                }}>

                                                                </div>
                                                                {/* <Paginations
                                                            itemPerPage={itemPerPage}
                                                            totalPage={totalPage}
                                                            page={page}
                                                            setPage={setPage}
                                                            setItemPerPage={setItemPerPage}
                                                            itemPerPageSelection={itemPerPageSelection}
                                                            orderBy={orderBy}
                                                            setOrderBy={setOrderBy}
                                                            orderBySelection={orderByList}
                                                            isDescActive={descending}
                                                            setIsDescActive={setDescending}
                                                        /> */}
                                                                {/* <Loading
                                                            loading={loading}
                                                        /> */}
                                                            </div>
                                                        } />
                                                </div>
                                            </div>

                                        </Tab>


                                    </Tabs>


                                </div>

                            </div>
                            {
                                disabledCustomerForm === false &&
                                <ButtonForm addData={() => {
                                    setLoading(true);
                                    setDisabledButton(true);
                                    saveData();
                                }}
                                    saveButton={`${t("button_component.save_button")}`}
                                    cancelButton={`${t("button_component.cancel_button")}`}
                                    path={"/Project"} button={disabledForm === false && updatedButton === true ? disabledButton : true} />
                            }


                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />

            <Modal size="xl" show={showAddVessel} onHide={
                () => {
                    setShowAddVessel(false);
                    resetVessel();
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_project.add_vessel")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setShowAddVessel(false);
                                resetVessel();
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    addVessel();
                                    setDisabledModalButton(true);
                                }}>
                                    <TextBox value={vessel.vessel_name} placeholder=""
                                        caption={`${t("detail_vessel.field_vessel_name")}`} setValueFunction={(e) => {
                                            setVessel({ ...vessel, vessel_name: e.target.value });
                                        }} />

                                    {
                                        errors.vesselNameForm && <p style={{ color: "red" }}>{errors.vesselNameForm}</p>
                                    }
                                    <FormSelect caption={`${t("detail_vessel.field_customer_name")}`} autoFocusValue={vessel.customer_id === undefined} placeholder=""
                                        options={listCustomer} value={listCustomer.filter(function (option) {
                                            return option.id === vessel.customer_id;
                                        })} setValueFunction={(e) => {
                                            setVessel({ ...vessel, customer_id: e.id });
                                        }}

                                    />
                                    {
                                        errors.companyNameForm && <p style={{ color: "red" }}>{errors.companyNameForm}</p>
                                    }
                                    <FormSelect caption={`${t("detail_vessel.field_vessel_type")}`} autoFocusValue={vessel.vessel_type_id === undefined} placeholder=""
                                        options={vesselTypeOptions} value={vesselTypeOptions.filter(function (option) {
                                            return option.id === vessel.vessel_type_id;
                                        })} setValueFunction={(e) => {
                                            setVessel({ ...vessel, vessel_type_id: e.id });
                                        }}

                                    />
                                    {
                                        errors.vesselTypeForm && <p style={{ color: "red" }}>{errors.vesselTypeForm}</p>
                                    }
                                    <TextBox value={vessel.imo_number} placeholder=""
                                        caption={`${t("detail_vessel.field_vessel_imo_number")}`} setValueFunction={(e) => {
                                            setVessel({ ...vessel, imo_number: e.target.value });
                                        }} />
                                    <TextBox value={vessel.serial_number} placeholder=""
                                        caption={`${t("detail_vessel.field_yard_number")}`} setValueFunction={(e) => {
                                            setVessel({ ...vessel, serial_number: e.target.value });
                                        }} />
                                    <TextBox value={vessel.built_date} controlType="date" placeholder=""
                                        caption={`${t("detail_vessel.field_built_date")}`} setValueFunction={(e) => {
                                            setVessel({ ...vessel, built_date: e.target.value });
                                        }} />
                                    <TextBox value={vessel.vessel_code} placeholder=""
                                        caption={`${t("detail_vessel.field_vessel_code")}`} setValueFunction={(e) => {
                                            setVessel({ ...vessel, vessel_code: e.target.value });
                                        }} />

                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                        padding: 10,
                                        width: "100%",
                                        justifyContent: "center",
                                        fontWeight: "bold"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            paddingRight: 5,
                                        }}><Button type="submit" style={{ width: 100 }} onClick={() => {

                                        }}

                                            className="save-button" disabled={disabledModalButton === true || disabledCustomerForm === true ? true : !updatedButton} >{t("button_component.save_button")}</Button> </div>
                                        <div style={{
                                            display: "flex",
                                            paddingLeft: 5,
                                            paddingRight: 5
                                        }}>
                                            <Button style={{ width: 100 }} onClick={() => {
                                                setShowAddVessel(false);
                                                resetVessel();
                                            }}
                                                className="cancel-button" variant="danger">{t("button_component.cancel_button")}</Button></div>

                                    </div>
                                </Form>

                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            {/* <Modal dialogClassName="modal-my" show={showAddVessel} onHide={() => {
                setShowAddVessel(false);
                resetVessel();
            }}>

                <Modal.Header closeButton onClick={() => {
                    resetVessel();
                }}>
                    <Modal.Title>{t("detail_project.add_vessel")}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <TextBox value={vessel.vessel_name} placeholder=""
                        caption={`${t("detail_vessel.field_vessel_name")}`} setValueFunction={(e) => {
                            setVessel({ ...vessel, vessel_name: e.target.value });
                        }} />

                    {
                        errors.vesselNameForm && <p style={{ color: "red" }}>{errors.vesselNameForm}</p>
                    }
                    <FormSelect caption={`${t("detail_vessel.field_customer_name")}`} autoFocusValue={vessel.customer_id === undefined} placeholder=""
                        options={listCustomer} value={listCustomer.filter(function (option) {
                            return option.id === vessel.customer_id;
                        })} setValueFunction={(e) => {
                            setVessel({ ...vessel, customer_id: e.id });
                        }}

                    />
                    {
                        errors.companyNameForm && <p style={{ color: "red" }}>{errors.companyNameForm}</p>
                    }
                    <FormSelect caption={`${t("detail_vessel.field_vessel_type")}`} autoFocusValue={vessel.vessel_type_id === undefined} placeholder=""
                        options={vesselTypeOptions} value={vesselTypeOptions.filter(function (option) {
                            return option.id === vessel.vessel_type_id;
                        })} setValueFunction={(e) => {
                            setVessel({ ...vessel, vessel_type_id: e.id });
                        }}

                    />
                    {
                        errors.vesselTypeForm && <p style={{ color: "red" }}>{errors.vesselTypeForm}</p>
                    }
                    <TextBox value={vessel.imo_number} placeholder=""
                        caption={`${t("detail_vessel.field_vessel_imo_number")}`} setValueFunction={(e) => {
                            setVessel({ ...vessel, imo_number: e.target.value });
                        }} />
                    <TextBox value={vessel.serial_number} placeholder=""
                        caption={`${t("detail_vessel.field_yard_number")}`} setValueFunction={(e) => {
                            setVessel({ ...vessel, serial_number: e.target.value });
                        }} />
                    <TextBox value={vessel.built_date} controlType="date" placeholder=""
                        caption={`${t("detail_vessel.field_built_date")}`} setValueFunction={(e) => {
                            setVessel({ ...vessel, built_date: e.target.value });
                        }} />
                    <TextBox value={vessel.vessel_code} placeholder=""
                        caption={`${t("detail_vessel.field_vessel_code")}`} setValueFunction={(e) => {
                            setVessel({ ...vessel, vessel_code: e.target.value });
                        }} />
                </Modal.Body>
                <Modal.Footer>

                    <div style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        padding: 10,
                        width: "100%",
                        justifyContent: "center",
                        fontWeight: "bold"
                    }}>
                        <div style={{
                            display: "flex",
                            paddingRight: 5,
                        }}><Button style={{ width: 100 }} onClick={() => {
                            addVessel();
                            setDisabledModalButton(true);
                        }}

                            className="save-button" disabled={disabledModalButton === true || disabledCustomerForm === true ? true : !updatedButton} >{t("button_component.save_button")}</Button> </div>
                        <div style={{
                            display: "flex",
                            paddingLeft: 5,
                            paddingRight: 5
                        }}>
                            <Button style={{ width: 100 }} onClick={() => {
                                setShowAddVessel(false);
                                resetVessel();
                            }}
                                className="cancel-button" variant="danger">{t("button_component.cancel_button")}</Button></div>

                    </div>

                </Modal.Footer>

            </Modal> */}

            {/* <Modal dialogClassName="modal-my" show={showAddCustomer} onHide={() => {
                setShowAddCustomer(false);
                resetCustomer();
            }}>

                <Modal.Header closeButton onClick={() => {
                    resetCustomer();
                }}>
                    <Modal.Title>{t("detail_project.add_customer")}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        setLoading(true);
                        addCustomer();
                        setDisabledCustomerButtonModal(true);
                    }}>
                        <TextBox req={true} value={customer.customer_name} placeholder=""
                            caption={`${t("detail_customer.field_customer_name")}`} setValueFunction={(e) => {
                                setCustomer({ ...customer, customer_name: e.target.value });
                            }} />

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_customer.field_customer_type")}</Form.Label>
                            <Form.Select required value={customer.customer_type} onChange={(e) => {
                                setCustomer({ ...customer, customer_type: e.target.value })
                            }}>
                                <option value=""></option>
                                <option value="company">Perusahaan</option>
                                <option value="personal">Perorangan</option>
                            </Form.Select>
                        </Form.Group>

                        <TextBox req={true} value={customer.city} placeholder=""
                            caption={`${t("detail_customer.field_customer_city")}`} setValueFunction={(e) => {
                                setCustomer({ ...customer, city: e.target.value });
                            }} />

                        <TextBox req={true} value={customer.address_1} placeholder=""
                            caption={`${t("detail_customer.field_address1")}`} setValueFunction={(e) => {
                                setCustomer({ ...customer, address_1: e.target.value });
                            }} />

                        {
                            customer.customer_type === "company" &&
                            <FormSelect caption={`${t("detail_customer.field_company_name")}`} placeholder=""
                                options={listCompany} value={listCompany.filter(function (option) {
                                    return option.id === customer.company_id;
                                })} setValueFunction={(e) => {
                                    if (e !== null) {
                                        setCustomer({ ...customer, company_id: e.id });
                                    } else {
                                        setCustomer({ ...customer, company_id: null });
                                    }
                                }}
                            />
                        }



                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            padding: 10,
                            width: "100%",
                            justifyContent: "center",
                            fontWeight: "bold"
                        }}>
                            <div style={{
                                display: "flex",
                                paddingRight: 5,
                            }}><Button style={{ width: 100 }} disabled={disabledCustomerButtonModal === true ? disabledCustomerButtonModal : !updatedButton} type="submit"

                                className="save-button" >{t("button_component.save_button")}</Button> </div>
                            <div style={{
                                display: "flex",
                                paddingLeft: 5,
                                paddingRight: 5
                            }}>
                                <Button style={{ width: 100 }} onClick={() => {
                                    setShowAddCustomer(false);
                                    resetCustomer();
                                }}
                                    className="cancel-button" variant="danger">{t("button_component.cancel_button")}</Button></div>

                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>



                </Modal.Footer>

            </Modal> */}

            <Modal size="xl" show={showAddCustomer} onHide={
                () => {
                    setShowAddCustomer(false);
                    resetCustomer();
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_project.add_customer")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setShowAddCustomer(false);
                                resetCustomer();
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    addCustomer();
                                    setDisabledCustomerButtonModal(true);
                                }}>
                                    <TextBox req={true} value={customer.customer_name} placeholder=""
                                        caption={`${t("detail_customer.field_customer_name")}`} setValueFunction={(e) => {
                                            setCustomer({ ...customer, customer_name: e.target.value });
                                        }} />

                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("detail_customer.field_customer_type")}</Form.Label>
                                        <Form.Select required value={customer.customer_type} onChange={(e) => {
                                            setCustomer({ ...customer, customer_type: e.target.value })
                                        }}>
                                            <option value=""></option>
                                            <option value="company">Perusahaan</option>
                                            <option value="personal">Perorangan</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <TextBox req={true} value={customer.city} placeholder=""
                                        caption={`${t("detail_customer.field_customer_city")}`} setValueFunction={(e) => {
                                            setCustomer({ ...customer, city: e.target.value });
                                        }} />

                                    <TextBox req={true} value={customer.address_1} placeholder=""
                                        caption={`${t("detail_customer.field_address1")}`} setValueFunction={(e) => {
                                            setCustomer({ ...customer, address_1: e.target.value });
                                        }} />

                                    {
                                        customer.customer_type === "company" &&
                                        <FormSelect caption={`${t("detail_customer.field_company_name")}`} placeholder=""
                                            options={listCompany} value={listCompany.filter(function (option) {
                                                return option.id === customer.company_id;
                                            })} setValueFunction={(e) => {
                                                if (e !== null) {
                                                    setCustomer({ ...customer, company_id: e.id });
                                                } else {
                                                    setCustomer({ ...customer, company_id: null });
                                                }
                                            }}
                                        />
                                    }



                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                        padding: 10,
                                        width: "100%",
                                        justifyContent: "center",
                                        fontWeight: "bold"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            paddingRight: 5,
                                        }}><Button style={{ width: 100 }} disabled={disabledCustomerButtonModal === true ? disabledCustomerButtonModal : !updatedButton} type="submit"

                                            className="save-button" >{t("button_component.save_button")}</Button> </div>
                                        <div style={{
                                            display: "flex",
                                            paddingLeft: 5,
                                            paddingRight: 5
                                        }}>
                                            <Button style={{ width: 100 }} onClick={() => {
                                                setShowAddCustomer(false);
                                                resetCustomer();
                                            }}
                                                className="cancel-button" variant="danger">{t("button_component.cancel_button")}</Button></div>

                                    </div>
                                </Form>

                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            {/* <Modal dialogClassName="modal-my" show={showaddContract} onHide={() => {
                setShowAddContract(false);
                resetContract();
            }}>

                <Modal.Header closeButton onClick={() => {
                    resetContract();
                }}>
                    <Modal.Title>{t("detail_project.add_contract")}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <TextBox value={contract.contract_name} placeholder=""
                        caption={`${t("detail_contract.field_contract_name")}`} setValueFunction={(e) => {
                            setContract({ ...contract, contract_name: e.target.value });
                        }} />
                    {
                        errors.contractNameError && <p style={{ color: "red" }}>{errors.contractNameError}</p>
                    }

                    <FormSelect caption={`${t("detail_contract.field_customer_name")}`} autoFocusValue={contract.customer_id === undefined} placeholder=""
                        options={listCustomer} value={listCustomer.filter(function (option) {
                            return option.id === contract.customer_id;
                        })} setValueFunction={(e) => {
                            if (e !== null) {
                                setContract({ ...contract, customer_id: e.id });
                            } else {
                                setContract({ ...contract, customer_id: null });
                            }
                        }}
                    />
                    {
                        errors.customerInContractError && <p style={{ color: "red" }}>{errors.customerInContractError}</p>
                    }

                    <TextBox value={contract.contract_date} controlType="date" placeholder="Tanggal Kontrak"
                        caption={`${t("detail_contract.field_contract_date")}`} setValueFunction={(e) => {
                            setContract({ ...contract, contract_date: e.target.value });
                        }} />

                    {
                        errors.contractDateError && <p style={{ color: "red" }}>{errors.contractDateError}</p>
                    }

                    <TextBox value={contract.contract_external_number} placeholder=""
                        caption={`${t("detail_contract.field_contract_number1")}`} setValueFunction={(e) => {
                            setContract({ ...contract, contract_external_number: e.target.value });
                        }} />
                    {
                        errors.contractNumberError && <p style={{ color: "red" }}>{errors.contractNumberError}</p>
                    }
                    <TextBox value={contract.description} placeholder=""
                        caption={`${t("detail_contract.field_description")}`} setValueFunction={(e) => {
                            setContract({ ...contract, description: e.target.value });
                        }} asType="textarea" />





                </Modal.Body>
                <Modal.Footer>

                    <div style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        padding: 10,
                        width: "100%",
                        justifyContent: "center",
                        fontWeight: "bold"
                    }}>
                        <div style={{
                            display: "flex",
                            paddingRight: 5,
                        }}><Button style={{ width: 100 }} onClick={() => {
                            addContract();
                        }}

                            className="save-button" disabled={disabledContractSaveButton === true ? disabledContractSaveButton : !updatedButton} >{t("detail_contract.save_button")}</Button> </div>
                        <div style={{
                            display: "flex",
                            paddingLeft: 5,
                            paddingRight: 5
                        }}>
                            <Button style={{ width: 100 }} onClick={() => {
                                setShowAddContract(false);
                                resetContract();
                            }}
                                className="cancel-button" variant="danger">{t("detail_contract.cancel_button")}</Button></div>

                    </div>

                </Modal.Footer>

            </Modal> */}

            <Modal size="xl" show={showaddContract} onHide={
                () => {
                    setShowAddContract(false);
                    resetContract();
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_project.add_contract")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setShowAddContract(false);
                                resetContract();
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    addContract();
                                }}>
                                    <TextBox value={contract.contract_name} placeholder=""
                                        caption={`${t("detail_contract.field_contract_name")}`} setValueFunction={(e) => {
                                            setContract({ ...contract, contract_name: e.target.value });
                                        }} />
                                    {
                                        errors.contractNameError && <p style={{ color: "red" }}>{errors.contractNameError}</p>
                                    }

                                    <FormSelect caption={`${t("detail_contract.field_customer_name")}`} autoFocusValue={contract.customer_id === undefined} placeholder=""
                                        options={listCustomer} value={listCustomer.filter(function (option) {
                                            return option.id === contract.customer_id;
                                        })} setValueFunction={(e) => {
                                            if (e !== null) {
                                                setContract({ ...contract, customer_id: e.id });
                                            } else {
                                                setContract({ ...contract, customer_id: null });
                                            }
                                        }}
                                    />
                                    {
                                        errors.customerInContractError && <p style={{ color: "red" }}>{errors.customerInContractError}</p>
                                    }

                                    <TextBox value={contract.contract_date} controlType="date" placeholder="Tanggal Kontrak"
                                        caption={`${t("detail_contract.field_contract_date")}`} setValueFunction={(e) => {
                                            setContract({ ...contract, contract_date: e.target.value });
                                        }} />

                                    {
                                        errors.contractDateError && <p style={{ color: "red" }}>{errors.contractDateError}</p>
                                    }

                                    <TextBox value={contract.contract_external_number} placeholder=""
                                        caption={`${t("detail_contract.field_contract_number1")}`} setValueFunction={(e) => {
                                            setContract({ ...contract, contract_external_number: e.target.value });
                                        }} />
                                    {
                                        errors.contractNumberError && <p style={{ color: "red" }}>{errors.contractNumberError}</p>
                                    }
                                    <TextBox value={contract.description} placeholder=""
                                        caption={`${t("detail_contract.field_description")}`} setValueFunction={(e) => {
                                            setContract({ ...contract, description: e.target.value });
                                        }} asType="textarea" />



                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                        padding: 10,
                                        width: "100%",
                                        justifyContent: "center",
                                        fontWeight: "bold"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            paddingRight: 5,
                                        }}><Button type="submit" style={{ width: 100 }} onClick={() => {

                                        }}

                                            className="save-button" disabled={disabledContractSaveButton === true ? disabledContractSaveButton : !updatedButton} >{t("detail_contract.save_button")}</Button> </div>
                                        <div style={{
                                            display: "flex",
                                            paddingLeft: 5,
                                            paddingRight: 5
                                        }}>
                                            <Button style={{ width: 100 }} onClick={() => {
                                                setShowAddContract(false);
                                                resetContract();
                                            }}
                                                className="cancel-button" variant="danger">{t("button_component.cancel_button")}</Button></div>

                                    </div>
                                </Form>

                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>


            <Modal dialogClassName="modal-my" show={showSurvey} onHide={() => {
                setShowSurvey(false);
            }}>

                <Modal.Header closeButton >

                </Modal.Header>
                <Modal.Body >
                    <Container fluid style={{
                        display: "flex",
                        flex: 1,
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center"
                    }}>
                        <ContainerBox
                            titleCaption="Daftar Survey"
                            useActionContainer={true}
                            childContent={
                                <div className="master-table-inner-container">
                                    {/* <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Nama Proyek)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                // await loadProject();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            // await loadProject();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadProject();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div> */}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr className="table-header-caption">
                                                <th>#</th>
                                                <th>Nama Proyek</th>
                                                <th>Tipe Proyek</th>
                                                <th>Perusahaan</th>
                                                <th>Nama Kapal</th>
                                                <th>Select</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                listSurvey.length === 0 ?
                                                    <tr>
                                                        <td colSpan={6} style={{ textAlign: "center" }}>Tidak Ada Hasil Pencarian Untuk {searchQuery}</td>
                                                    </tr> :
                                                    <></>
                                            }
                                            {
                                                listSurvey.map((projectSurvey, index) => {
                                                    return (
                                                        <tr key={index} className="table-body-caption">
                                                            <td>{index + 1}</td>
                                                            <td>{projectSurvey.project_name}</td>
                                                            <td>{projectSurvey.project_type_name}</td>
                                                            <td>{projectSurvey.customer_name}</td>
                                                            <td>{projectSurvey.vessel_name}</td>
                                                            <td><Button onClick={() => {
                                                                setProject({ ...project, survey_id: projectSurvey.project_type_id, survey_project_name: projectSurvey.project_name });
                                                            }}>Select</Button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    {/* <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            /> */}
                                    <Loading
                                        loading={loading}
                                    />
                                </div>
                            } />

                    </Container>
                </Modal.Body>


            </Modal>


            <Modal show={showDocumentUploadModal}
                // dialogClassName="modal-size"
                size={"lg"}
                onHide={() => {
                    resetUploadForm();
                    setShowDocumentUploadModal(false);
                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>{t("detail_project.modal_add_document")} </h3>
                    </div>

                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        setLoading(true);
                        setDisabledDocumentButton(true);
                        setNewDocument({ ...newDocument, done: true });
                    }} style={{ padding: 10 }}>



                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_project.field_document_file")}</Form.Label>
                            <Form.Control onChange={async (e) => {
                                if (e.target.files[0].type === "application/pdf") {
                                    setDocumentFile(e.target.value)
                                    let base64Doc = await convertBase64(e.target.files[0]);
                                    setNewDocument({ ...newDocument, document_base64: base64Doc.toString() });
                                    setUploadFileImageError("");
                                    setDisabledDocumentButton(false)

                                } else {
                                    setDocumentFile("")
                                    setNewDocument({ ...newDocument, document_base64: "" });
                                    setUploadFileImageError(`${t("detail_project.error_pdf_file")}`)
                                    setDisabledDocumentButton(true)
                                }

                            }} type="file" required></Form.Control>
                            {
                                uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_project.field_document_name")}</Form.Label>

                            <Form.Control onChange={(e) => {
                                setNewDocument({ ...newDocument, document_name: e.target.value })
                            }} value={newDocument.document_name} type="text" placeholder="" required></Form.Control>

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_project.field_document_reference")}</Form.Label>

                            <Form.Control onChange={(e) => {
                                setNewDocument({ ...newDocument, reference_number: e.target.value })
                            }} value={newDocument.reference_number} type="text" placeholder="" ></Form.Control>

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_project.field_document_date")}</Form.Label>

                            <Form.Control onChange={(e) => {
                                if (e.target.value === "") {
                                    setNewDocument({ ...newDocument, document_date: null })
                                } else {
                                    setNewDocument({ ...newDocument, document_date: e.target.value })
                                }

                            }} value={newDocument.document_date ? moment(newDocument.document_date).format("yyyy-MM-DD") : ""} type="date" placeholder=""></Form.Control>

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_project.field_document_type")}</Form.Label>

                            {/* <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, document_type: e.target.value })
                                }} value={newDocument.document_type} type="text" placeholder="" ></Form.Control> */}
                            <Select placeholder={""}
                                getOptionLabel={(item) => {
                                    return item.type_name;
                                }} clearValue={true}
                                getOptionValue={(item) => {
                                    return item.id;
                                }}
                                options={listDocumentType} value={listDocumentType.filter(function (option) {
                                    return option.id === newDocument.document_type;
                                })} onChange={(e) => {
                                    if (e === null) {
                                        setNewDocument({ ...newDocument, document_type: null })
                                    } else {
                                        setNewDocument({ ...newDocument, document_type: e.id })
                                    }
                                }}
                                isClearable

                            />

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_project.field_description")}</Form.Label>

                            <Form.Control onChange={(e) => {
                                setNewDocument({ ...newDocument, description: e.target.value })
                            }} value={newDocument.description} as="textarea" rows={3} placeholder=""></Form.Control>

                        </Form.Group>

                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            flex: 1,
                        }}>
                            <Button style={{ width: 100 }} variant="primary" type="submit" disabled={disableDocumentButton}>
                                {t("detail_project.save_button")}
                            </Button>
                            <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                resetUploadForm();
                                loadDocumentData();
                                setShowDocumentUploadModal(false);
                            }}>
                                {t("detail_project.cancel_button")}
                            </Button>
                        </div>
                    </Form>



                </div>

            </Modal>

            <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                setShowDocumentDetailModal(false);
            }}>

                <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setShowDocumentDetailModal(false);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_project.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            <div>
                                <Document
                                    file={documentToBeViewed.document_base64}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={() => {

                                    }}
                                ><div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            overflow: "scroll",
                                        }}>
                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                        </div>
                                    </div>
                                </Document>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <p>
                                        {t("detail_project.page_text")} {pageNumber} {t("detail_project.from_text")} {numPages}
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor <= 0.2}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 0.8);
                                            }}
                                        >

                                            <ZoomOut size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={false}
                                            onClick={() => {
                                                setZoomFactor(0.4);
                                            }}
                                        >
                                            <AspectRatioFill size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                        flexWrap: "wrap",
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor >= 10}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 2);
                                            }}
                                        >
                                            <ZoomIn size={28} />
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >

                                            <ChevronDoubleLeft size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            <ChevronDoubleRight size={28} />
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    } />

            </Modal>

            <Modal show={assignmentModal}
                // dialogClassName="modal-size"
                size={"xl"}
                onHide={() => {
                    resetModalAssignment();
                    setAssignmentModal(false);
                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>{t("detail_project.header_modal_assignment")}</h3>
                    </div>

                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        saveProjectAssignment();
                    }} style={{ padding: 10 }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_project.field_assignment_number")}</Form.Label>
                            <Form.Control type="text" onChange={(e) => {

                            }} value={projectAssignment.assignment_number} disabled></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("detail_project.field_job_name")}</Form.Label>
                            <Form.Control type="text" onChange={(e) => {
                                setProjectAssignment({ ...projectAssignment, job_name: e.target.value })
                            }} value={projectAssignment.job_name} required></Form.Control>
                        </Form.Group>


                        {
                            listTechnician && listTechnician.map((technician, index) => {
                                return (
                                    <div key={index} style={{ padding: 5 }}>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5,
                                            borderWidth: 2,
                                            borderRadius: 5,
                                            borderStyle: "solid",
                                            borderColor: "rgba(3, 30, 103, 1)"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    flex: 9,
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Form.Label>{t("detail_project.field_technician_name")}</Form.Label>
                                                    <FormSelect placeholder=""
                                                        options={listStaff.filter((p) => {
                                                            return !listTechnician.find((x) => {
                                                                return p.id === x.staff_id
                                                            }) && p.resign_date === null && p.is_field_staff
                                                        })} getOptionLabel={(item) => {
                                                            return item.staff_name;
                                                        }} clearValue={true}
                                                        getOptionValue={(item) => {
                                                            return item.staff_id;
                                                        }} setValueFunction={(e) => {
                                                            let newList = listTechnician;
                                                            newList[index].staff_id = e.id;
                                                            newList[index].position_name = e.position_name;
                                                            newList[index].employee_number = e.employee_number;
                                                            setListTechnician(newList.slice(0));
                                                        }} value={listStaff.filter(function (option) {
                                                            return option.staff_id === technician.staff_id;
                                                        })}
                                                    />

                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    flex: 9,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                }}>
                                                    <Form.Label>{t("detail_annual_report.field_technician_position")}</Form.Label>
                                                    <Form.Control type="text" value={technician.position_name} disabled></Form.Control>

                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    flex: 9,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                }}>

                                                    <Form.Label>{t("detail_project.field_employee_number")}</Form.Label>
                                                    <Form.Control type="text" value={technician.employee_number} disabled></Form.Control>


                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    flex: 1,
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <div style={{ paddingTop: 35 }}></div>
                                                    <Button onClick={() => {
                                                        if (window.confirm(`${t("detail_project.delete_confirmation")}`)) {
                                                            // let list = listServiceCrew;
                                                            // if (!serviceJob.deleted_crew) {
                                                            //     let listCrew = [];
                                                            //     listCrew.push(list[index]);

                                                            //     list.splice(index, 1);
                                                            //     setServiceJob({ ...serviceJob, deleted_crew: listCrew });
                                                            //     setListServiceCrew(list)
                                                            // } else {
                                                            //     let listCrew = serviceJob.deleted_crew;
                                                            //     listCrew.push(list[index]);
                                                            //     list.splice(index, 1);
                                                            //     setServiceJob({ ...serviceJob, deleted_crew: listCrew });
                                                            //     setListServiceCrew(list)
                                                            // }
                                                            let list = listTechnician;
                                                            list.splice(index, 1)
                                                            setListTechnician(list.slice(0));

                                                        }

                                                    }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                </div>

                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    flex: 1,
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Form.Label>{t("detail_project.field_start_date")}</Form.Label>
                                                    <Form.Control required type="date" onChange={(e) => {
                                                        let newList = listTechnician;
                                                        newList[index].start_date = e.target.value;
                                                        setListTechnician(newList.slice(0));

                                                    }} value={technician.start_date ? moment(technician.start_date).format("yyyy-MM-DD") : ""} placeholder="" ></Form.Control>

                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    flex: 1,
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Form.Label>{t("detail_project.field_end_date")}</Form.Label>
                                                    <Form.Control type="date" onChange={(e) => {
                                                        let newList = listTechnician;
                                                        newList[index].end_date = e.target.value;
                                                        setListTechnician(newList.slice(0));

                                                    }} value={technician.end_date ? moment(technician.end_date).format("yyyy-MM-DD") : ""} placeholder=""></Form.Control>

                                                </div>



                                            </div>





                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: 10
                        }}>
                            <Button onClick={() => {
                                let newParameter = {
                                    staff_id: null,
                                    start_date: null,
                                    end_date: null
                                }
                                if (listTechnician.length === 0) {
                                    let newList = [];
                                    newList.push(newParameter);
                                    setListTechnician(newList);
                                } else {
                                    let newList = [...listTechnician];
                                    newList.push(newParameter);
                                    setListTechnician(newList)
                                }
                            }}>{t("detail_project.button_add_technician")}</Button>
                        </div>
                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            flex: 1,
                        }}>
                            <Button style={{ width: 100 }} variant="primary" type="submit" disabled={disableDocumentButton}>
                                {t("detail_project.save_button")}
                            </Button>
                            <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                resetModalAssignment();
                                // loadDocumentData();
                                setAssignmentModal(false);
                            }}>
                                {t("detail_project.cancel_button")}
                            </Button>
                        </div>
                    </Form>



                </div>

            </Modal>


        </>
    )
}