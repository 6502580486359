import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container, Modal, Table } from 'react-bootstrap';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import moment, * as moments from 'moment';
import { isTokenValid, getDivision, getStaff, getUserAccessById, getProject } from "../../../../Helpers/ApplicationHelpers";
import {
    getInventoryCondition, getListHandoverDetail, getHandoverById, insertUpdateHandover, getHandoverDetail,
    getHandoverDetailCount, deleteHandoverInventory, insertUpdateHandoverInventory, getHandoverDetailById,
    getInventoryHandoverList, listHandoverInventoryFromListStockCategory
} from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import { ArrowLeft, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise, Printer } from "react-bootstrap-icons";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Paginations from "../../../../Components/Pagination";
import Loading from "../../../../Components/Loading";
import Select from 'react-select';
import ButtonForm from "../../../../Components/ButtonForm";
import { omit } from 'lodash';
import { Trans, useTranslation } from "react-i18next";
// import MyImage from '../../../../Assets/Images/';


export default function HandoverDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState({});
    const [listProject, setListProject] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [listDivision, setListDivision] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [disabledDivision, setDisabledDivision] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [disabledForm, setDisabledForm] = useState(false);
    const [listStaff, setListStaff] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    //Handover inventory
    const [listHandoverDetail, setListHandoverDetail] = useState([]);
    const [HandoverInventoryCount, setHandoverDetailCount] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [listReceiveStaff, setListReceiveStaff] = useState([]);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 100, 150, 200, 250]);
    const [descending, setDescending] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [listInventoryStock, setListInventoryStock] = useState([]);
    const [inventoryOptions, setInventoryOptions] = useState([]);
    const [handoverId, setHandoverId] = useState(0);
    const [selectedFromDivision, setSelectedFromDivision] = useState({ id: "" });
    const [selectedToDivision, setSelectedToDivision] = useState({ id: "" });
    const [selectedFromStaff, setSelectedFromStaff] = useState({ staff_id: "" });
    const [selectedKnownStaff, setSelectedKnownStaff] = useState({});
    const [selectedToStaff, setSelectedToStaff] = useState({ id: "" });
    const [listHandoverItem, setListHandoverItem] = useState([]);
    const [listSearchedHandoverItem, setListSearchedHandoverItem] = useState([]);
    const [selectedHandoverDetail, setSelectedHandoverDetail] = useState({
        id: 0,
        handover_id: "",
        handover_inventory_description: "",
        inventory_stock_id: "",
        received_by_staff_id: "",
        estimation_return_date: null,
    });


    //modal
    const [showInventoryModal, setShowInventoryModal] = useState(false);
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);



    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_handover_inventory.order_name1")}`,
        value: "inventory_code"
    });

    const orderByList = [
        {
            label: `${t("detail_handover_inventory.order_name1")}`,
            value: "inventory_code"
        },
        {
            label: `${t("detail_handover_inventory.order_name2")}`,
            value: "inventory_condition_name"
        }

    ]



    const [handover, setHandover] = useState({
        id: 0,
        form_number: "",
        from_division_id: "",
        to_division_id: "",
        handover_date: "",
        given_by_staff_id: "",
        known_by_staff_id: "",
        received_by_staff_id: "",
        document_base64: "",
        handover_description: "",
        division_from_name: "",
        division_to_name: "",
        document_number: "",
        staff_givenby: "",
        staff_receivedby: "",
        detail: [],
        ext_customer_id: cookies.extCustomerId,
        project_id: null
    })

    const [handoverDetail, setHandoverDetail] = useState({
        id: 0,
        handover_id: "",
        inventory_stock_id: "",
        inventory_condition_id: "",
        handover_inventory_description: "",
        received_by_staff_id: handover.received_by_staff_id
    });


    const barcodeDiv = useRef(null);
    const [barcodeRef, setBarcodeRef] = useState(barcodeDiv);
    const [barcodeState, setBarcodeState] = useState("");


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");
            } else {
                loadProject()
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }

                if (location.state.handoverId === 0) {
                    setLoading(false);
                }

            }

        }
        checkCookies();

        loadInventoryStock();
        if (searchParams.get("id") !== null) {
            setHandoverId(searchParams.get("id"))
        } else {
            setHandoverId(location.state.handoverId);
        }

    }, []);

    useEffect(() => {
        validationForm();
    }, [handover])

    useEffect(() => {
        setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)));
    }, [page]);

    useEffect(() => {
        setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)));
    }, [itemPerPage]);

    useEffect(() => {
        if (orderBy.value === 'inventory_code') {
            setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_code > b.inventory_code ? 1 : -1)));
        } else {
            setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_condition_name > b.inventory_condition_name ? 1 : -1)));
        }
    }, [orderBy]);

    useEffect(() => {
        if (descending === true) {
            if (orderBy.value === 'inventory_code') {
                setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_code > b.inventory_code ? 1 : -1)));
            } else {
                setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_condition_name > b.inventory_condition_name ? 1 : -1)));
            }
        } else {
            if (orderBy.value === 'inventory_code') {
                setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_code > b.inventory_code ? -1 : 1)));
            } else {
                setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_condition_name > b.inventory_condition_name ? -1 : 1)));
            }
        }

    }, [descending]);

    useEffect(() => {
        if (searchQuery !== "") {
            let listSearchedHandoverItem = listHandoverItem.filter(p => (p.inventory_code.includes(searchQuery) || p.inventory_master_name.toLowerCase().includes(searchQuery.toLowerCase())));
            if (listSearchedHandoverItem)
                setListSearchedHandoverItem(listSearchedHandoverItem);
            else
                setListSearchedHandoverItem([]);
        } else {
            setListSearchedHandoverItem(listHandoverItem);
        }
    }, [searchQuery])

    useEffect(() => {
        setListSearchedHandoverItem(listHandoverItem.slice(page * itemPerPage, itemPerPage * (page + 1)));
        if (listHandoverItem.length > 0) {
            setDisabledDivision(true);
            setHandoverDetailCount(listHandoverItem.length);
            let totalPage = listHandoverItem.length / itemPerPage;
            setTotalPage(totalPage);
        } else {
            setDisabledDivision(false);
        }

    }, [listHandoverItem])

    useEffect(() => {
        if (barcodeState !== "" && listInventoryStock) {
            let selectedInventoryStock = listInventoryStock.find(p => p.barcode_number === barcodeState && p.division_id === selectedFromDivision.id);
            if (handover.from_division_id !== "") {
                let selectedOption = listInventoryStock.find(p => p.barcode_number === barcodeState && p.division_id === selectedFromDivision.id);
                if (selectedOption) {
                    let handoverInv = selectedInventoryStock;
                    handoverInv.inventory_stock_id = selectedOption.id;
                    if (listSearchedHandoverItem.length > 0) {
                        let newList = listSearchedHandoverItem.filter(p => p.inventory_stock_id === handoverInv.inventory_stock_id)
                        if (newList.length === 0 && showInventoryModal === false) {
                            setListHandoverItem([...listHandoverItem, handoverInv]);

                        }
                        // for (let index = 0; index < listSearchedHandoverItem.length; index++) {
                        //     const newList = listHandoverItem[index];
                        //     if (newList.inventory_stock_id !== handoverInv.inventory_stock_id && showInventoryModal === false) {
                        //         setListHandoverItem([...listHandoverItem, handoverInv]);

                        //     }

                        // }
                    } else {
                        if (showInventoryModal === false)
                            setListHandoverItem([...listHandoverItem, handoverInv]);

                    }
                    let newList = listSearchedHandoverItem.filter(p => p.inventory_stock_id === selectedOption.id)
                    if (showInventoryModal === true && newList.length === 0) {
                        if (selectedInventoryStock)
                            setSelectedHandoverDetail(selectedInventoryStock);
                    }

                }


            }

            setBarcodeState("")

        }
    }, [barcodeState]);


    useEffect(() => {
        if (barcodeState !== "" && listInventoryStock) {
            let selectedInventoryStock = listInventoryStock.find(p => p.barcode_number === barcodeState);
            setHandoverDetail(selectedInventoryStock);
        }
    }, [barcodeRef]);

    useEffect(() => {
        loadDivision();
    }, [handoverId]);

    useEffect(() => {
        if (listDivision.length > 0)
            loadStaff();
    }, [listDivision]);

    useEffect(() => {
        if (listStaff.length > 0)
            loadInventoryCondition();
    }, [listStaff]);

    useEffect(()=>{
        if(listProject.length>0){
            if (handover.project_id) {
                let projects = listProject.find(p => p.id === handover.project_id);
                if (projects) {
                    setSelectedProject(projects);
                }

            }
        }
    },[listProject])

    useEffect(() => {
        if (listInventoryCondition.length > 0) {
            if (handoverId !== 0) {
                initHandover();
            }
        }

    }, [listInventoryCondition]);

    useEffect(() => {
        if (handover.id !== 0) {
            if (handover.from_division_id) {
                let selectedDivision = listDivision.find(p => p.id === handover.from_division_id);
                setSelectedFromDivision(selectedDivision);
            }

            if (handover.to_division_id) {
                let selectedDivision = listDivision.find(p => p.id === handover.to_division_id);
                setSelectedToDivision(selectedDivision);
            }

            if (handover.given_by_staff_id) {
                let selectedStaff = listStaff.find(p => p.id === handover.given_by_staff_id);
                setSelectedFromStaff(selectedStaff);
            }

            if (handover.received_by_staff_id) {
                let selectedStaff = listStaff.find(p => p.id === handover.received_by_staff_id);
                setSelectedToStaff(selectedStaff);
            }
            if (handover.known_by_staff_id) {
                let selectedStaff = listStaff.find(p => p.id === handover.known_by_staff_id);
                setSelectedKnownStaff(selectedStaff);
            }

            if (handover.project_id) {
                let projects = listProject.find(p => p.id === handover.project_id);
                if (projects) {
                    setSelectedProject(projects);
                }

            }

            setListHandoverDetail(handover.detail);
        }
    }, [handover]);

    // useEffect(() => {
    //     if (selectedFromStaff.staff_id !== "")
    //         loadStaff();
    // }, [selectedFromStaff.staff_id])

    // useEffect(()=>{
    //     if(selectedToStaff.id !== "")
    //     loadStaff();
    // },[selectedToStaff.id])

    useEffect(() => {
        setHandover({ ...handover, from_division_id: selectedFromDivision.id, division_from_name: selectedFromDivision.division_name });
    }, [selectedFromDivision]);

    useEffect(() => {
        setHandover({ ...handover, to_division_id: selectedToDivision.id, division_to_name: selectedToDivision.division_name });
    }, [selectedToDivision]);

    useEffect(() => {
        setHandover({ ...handover, given_by_staff_id: selectedFromStaff.staff_id, staff_givenby: selectedFromStaff.staff_name });
    }, [selectedFromStaff]);

    useEffect(() => {
        setHandover({ ...handover, received_by_staff_id: selectedToStaff.id, staff_receivedby: selectedToStaff.staff_name });
    }, [selectedToStaff]);

    useEffect(() => {
            setHandover({ ...handover, known_by_staff_id: selectedKnownStaff.id, staff_knownby: selectedKnownStaff.staff_name })
    }, [selectedKnownStaff])

    useEffect(() => {
        if (searchQuery === "" && handover.id !== 0)
            loadHandoverDetail();
    }, [searchQuery]);

    useEffect(() => {
        if (selectedProject.project_name) {
            setHandover({ ...handover, project_id: selectedProject.id });
        } else {
            setHandover({ ...handover, project_id: null });
        }
    }, [selectedProject]);




    const printPageArea = () => {
        //console.log('print');  
        // console.log('print');
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; text-align:left}"
        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("detail_handover_inventory.print_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }


    const initHandover = async () => {
        try {

            let response = await getHandoverById(cookies.token, handoverId, cookies.languageId);
            console.log(response);
            if (response.data[0].inventory_requisition_id !== null) {
                setDisabledForm(true);
            } else {
                setDisabledForm(false);
            }
            if (response.data.length > 0) {
                setHandover({
                    ...handover,
                    id: response.data[0].id,
                    form_number: response.data[0].document_number,
                    from_division_id: response.data[0].from_division_id,
                    to_division_id: response.data[0].to_division_id,
                    handover_date: response.data[0].handover_date,
                    given_by_staff_id: response.data[0].given_by_staff_id,
                    received_by_staff_id: response.data[0].received_by_staff_id,
                    known_by_staff_id: response.data[0].known_by_staff_id,
                    document_base64: response.data[0].document_base64,
                    handover_description: response.data[0].handover_description,
                    division_from_name: response.data[0].division_from_name,
                    division_to_name: response.data[0].division_to_name,
                    document_number: response.data[0].document_number,
                    staff_givenby: response.data[0].staff_givenby,
                    staff_receivedby: response.data[0].staff_receivedby,
                    staff_knownby: response.data[0].staff_knownby,
                    // detail: response.data[0].detail,
                    numberForm: response.data[0].number_form,
                    inventory_requisition_id: response.data[0].inventory_requisition_id,
                    project_id: response.data[0].project_id,
                    project_name: response.data[0].project_name
                });
                //setHandoverDetail({ ...handoverDetail, received_by_staff_id: response.received_by_staff_id });
                loadHandoverDetail(response.data[0].inventory_requisition_id)
            }

        } catch (exception) {
            console.log(exception);
        }
    }


    const saveHandoverForm = async () => {
     
        let handoverTmp = handover;
        handoverTmp.ext_customer_id = handoverTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : handover.ext_customer_id;
        let response = await insertUpdateHandover(cookies.token, handover, listHandoverItem);
        if (response) {
            if (response.error_code === 0) {
                alert(`${t("detail_handover_inventory.alert_success_save_data")}`);
                if (response.data.id !== undefined || response.data.id !== 0) {
                    setHandoverId(response.data.id);
                    setHandover({ ...handover, id: response.data.id });
                }
            } else {
                alert(`${t("detail_handover_inventory.alert_failed_save_data")}`);
            }
        }
        setDisabledButton(false);
        setLoading(false);
        loadHandoverDetail();
        setPage(0);
    }


    const clearHandoverInventoryModal = async () => {
        let handoverDetailTmp = {};
        handoverDetailTmp.id = 0;
        handoverDetailTmp.handover_id = "";
        handoverDetailTmp.inventory_stock_id = "";
        handoverDetailTmp.inventory_condition_id = "";
        handoverDetailTmp.handover_inventory_description = "";
        handoverDetailTmp.given_by_staff_id = handover.given_by_staff_id;
        setHandoverDetail(handoverDetailTmp);
        setBarcodeState("");
    }



    const loadDivision = async () => {
        try {
            let listDivision = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId,);

            setListDivision(listDivision);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, undefined, false, undefined, undefined, 2);
            setListStaff(response);

        } catch (exception) {
            console.log(exception);
        }


    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadHandoverDetail = async (requisitionId) => {
        try {
            // if(handover.inventory_requisition_id === null){
            let HandoverInventoryCount = await getHandoverDetailCount(cookies.token, handoverId);
            setHandoverDetailCount(HandoverInventoryCount);
            let totalPage = HandoverInventoryCount / itemPerPage;
            setTotalPage(totalPage);

            let listHandoverDetail = await getHandoverDetail(cookies.token, undefined, undefined, orderBy.value, undefined, undefined, false, handoverId);
            if (listHandoverDetail.length < 1 && handover.inventory_requisition_id !== null) {
                let response = await getInventoryHandoverList(cookies.token, requisitionId);
                setListHandoverItem(response);
                setListHandoverDetail(response);
            } else {
                setListHandoverItem(listHandoverDetail);
                setListHandoverDetail(listHandoverDetail);
            }


            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }


    }




    const loadInventoryStock = async (staffId) => {
        let listInventoryStock = await getListHandoverDetail(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, "inventory_code", true, undefined, undefined, handoverDetail.id === 0 ? false : undefined);
        // let listInventoryStock = await listHandoverInventoryFromListStockCategory(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, "inventory_code", true, undefined, undefined, handoverDetail.id === 0 ? false : undefined, handover.from_division_id);
        setListInventoryStock(listInventoryStock);
        let listNewInventory = [];
        var opt = {
            value: "",
            label: "Pilih Barang...",
            id: ""

        };
        listNewInventory.push(opt);
        for (let i = 0; i < listInventoryStock.length; i++) {
            var obj = {
                value: listInventoryStock[i].id,
                label: listInventoryStock[i].inventory_master_name + " (" + listInventoryStock[i].inventory_code + ")",
                id: listInventoryStock[i].id,
                barcode_number: listInventoryStock[i].barcode_number
            };

            listNewInventory.push(obj);

        }
        setInventoryOptions(listNewInventory);

    }

    const handleremove = index => {
        const list = [...listHandoverItem];
        list.splice(index, 1);
        setListHandoverItem(list);
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (handover.handover_date === undefined || handover.handover_date === "" || handover.handover_date === null) {
                    setErrors({
                        ...errors,
                        handoverDateMessageError: `${t("detail_handover_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (handover.from_division_id === undefined || handover.from_division_id === "") {
                    setErrors({
                        ...errors,
                        fromDivisiMessageError: `${t("detail_handover_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (handover.to_division_id === undefined || handover.to_division_id === "") {
                    setErrors({
                        ...errors,
                        toDivisiMessageError: `${t("detail_handover_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (handover.given_by_staff_id === undefined || handover.given_by_staff_id === "" || handover.given_by_staff_id === null) {
                    setErrors({
                        ...errors,
                        givenByStaffMessageError: `${t("detail_handover_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (handover.received_by_staff_id === undefined || handover.received_by_staff_id === "" || handover.received_by_staff_id === null) {
                    setErrors({
                        ...errors,
                        receivedByStaffMessageError: `${t("detail_handover_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (handover.known_by_staff_id === undefined || handover.known_by_staff_id === "" || handover.known_by_staff_id === null) {
                    setErrors({
                        ...errors,
                        knownByStaffMessageError: `${t("detail_handover_inventory.error_empty_field")}`
                    })
                }
                else {
                    let errorMsg = omit(errors, "handoverDateMessageError", "fromDivisiMessageError", "toDivisiMessageError", "givenByStaffMessageError", "receivedByStaffMessageError", "knownByStaffMessageError");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("detail_handover_inventory.order_name1")}`,
                value: "inventory_code"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, "null");
            setListProject(response);
        } catch (exception) {

        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_handover_inventory.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Handover"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_handover_inventory.header_handover")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>

                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_form_number")}</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setHandover({ ...handover, form_number: e.target.value })
                                                                }} type="text" value={handover.form_number} required placeholder="" disabled={true}></Form.Control>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_project")}</Form.Label>


                                                                <Select placeholder={""}
                                                                    getOptionLabel={(item) => {
                                                                        return item.project_name;
                                                                    }} clearValue={true}
                                                                    getOptionValue={(item) => {
                                                                        return item.id;
                                                                    }}
                                                                    options={listProject} value={selectedProject} onChange={(e) => {
                                                                        if (e === null) {
                                                                            setSelectedProject({})
                                                                        } else {
                                                                            setSelectedProject(e);
                                                                        }
                                                                    }}
                                                                    isClearable
                                                                    required
                                                                />
                                                              
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_handover_date")}</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setHandover({ ...handover, handover_date: e.target.value })
                                                                }} type="date" value={handover.handover_date !== undefined ? moment(handover.handover_date).format("yyyy-MM-DD") : ""} required placeholder="" ></Form.Control>
                                                            </Form.Group>
                                                            {
                                                                errors.handoverDateMessageError && <p style={{ color: "red" }}>{errors.handoverDateMessageError}</p>
                                                            }
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_from_division")}</Form.Label>
                                                                <Form.Select required disabled={disabledDivision} onChange={(e) => {
                                                                    if (e.target.value === "") {
                                                                        setSelectedFromDivision({ ...selectedFromDivision, id: null });
                                                                    } else {
                                                                        let selectedDivision = listDivision.find(p => p.id === e.target.value);
                                                                        setSelectedFromDivision(selectedDivision);
                                                                    }

                                                                }} value={selectedFromDivision.id}  >
                                                                    <option selected value="">

                                                                    </option>
                                                                    {listDivision.map((division, index) => (
                                                                        <option key={index} value={division.id}>{division.division_name} ({division.division_code})</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                            {
                                                                errors.fromDivisiMessageError && <p style={{ color: "red" }}>{errors.fromDivisiMessageError}</p>
                                                            }
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_to_division")}</Form.Label>
                                                                <Form.Select required onChange={(e) => {
                                                                    if (e.target.value === "") {
                                                                        setSelectedToDivision({ ...selectedToDivision, id: null });
                                                                    } else {
                                                                        let selectedDivision = listDivision.find(p => p.id === e.target.value);
                                                                        setSelectedToDivision(selectedDivision);
                                                                    }
                                                                }} value={selectedToDivision.id} disabled={disabledDivision}>
                                                                    <option selected value="">

                                                                    </option>
                                                                    {listDivision.map((division, index) => (
                                                                        <option key={index} value={division.id}>{division.division_name} ({division.division_code})</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                            {
                                                                errors.toDivisiMessageError && <p style={{ color: "red" }}>{errors.toDivisiMessageError}</p>
                                                            }

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>

                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_given_by")}</Form.Label>
                                                                <Select placeholder=""
                                                                    options={listStaff.filter(p => p.id !== handover.received_by_staff_id && p.id !== handover.known_by_staff_id && p.resign_date === null)} value={selectedFromStaff} onChange={(e) => {
                                                                        if (e === null) {
                                                                            setSelectedFromStaff({ ...selectedFromStaff, staff_id: null })
                                                                        } else {
                                                                            setSelectedFromStaff(e);
                                                                        }

                                                                    }}
                                                                    getOptionLabel={
                                                                        (item) => {
                                                                            if (item.id) {
                                                                                return item.staff_name + (item.employee_number ? ` (${item.employee_number})` : "");
                                                                            } else {
                                                                                return ("");
                                                                            }

                                                                        }
                                                                    } getOptionValue={
                                                                        (item) => {
                                                                            return item.id;
                                                                        }
                                                                    }
                                                                // isDisabled={disabledForm}
                                                                />
                                                            </Form.Group>
                                                            {
                                                                errors.givenByStaffMessageError && <p style={{ color: "red" }}>{errors.givenByStaffMessageError}</p>
                                                            }
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_received_by")}</Form.Label>
                                                                <Select placeholder=""
                                                                    options={listStaff.filter(p => p.id !== handover.given_by_staff_id && p.id !== handover.known_by_staff_id && p.resign_date === null)} value={selectedToStaff} onChange={(e) => {
                                                                        if (e === null) {
                                                                            setSelectedToStaff({ ...selectedToStaff, id: null });
                                                                        } else {
                                                                            setSelectedToStaff(e);
                                                                        }

                                                                    }}
                                                                    getOptionLabel={
                                                                        (item) => {
                                                                            if (item.id) {
                                                                                return item.staff_name + (item.employee_number ? ` (${item.employee_number})` : "");
                                                                            } else {
                                                                                return ("");
                                                                            }
                                                                        }
                                                                    } getOptionValue={
                                                                        (item) => {
                                                                            return item.id;
                                                                        }
                                                                    }
                                                                    isDisabled={disabledForm}
                                                                />
                                                            </Form.Group>
                                                            {
                                                                errors.receivedByStaffMessageError && <p style={{ color: "red" }}>{errors.receivedByStaffMessageError}</p>
                                                            }
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_known_by")}</Form.Label>
                                                                <Select placeholder=""
                                                                    options={listStaff.filter(p => p.id !== handover.given_by_staff_id && p.id !== handover.received_by_staff_id && p.resign_date === null)} value={selectedKnownStaff} onChange={(e) => {
                                                                        if (e === null) {
                                                                            setSelectedKnownStaff({});
                                                                        } else {
                                                                            setSelectedKnownStaff(e);
                                                                        }

                                                                    }}
                                                                    getOptionLabel={
                                                                        (item) => {
                                                                            if (item.id) {
                                                                                return item.staff_name + (item.employee_number ? ` (${item.employee_number})` : "");
                                                                            } else {
                                                                                return ("");
                                                                            }
                                                                        }
                                                                    } getOptionValue={
                                                                        (item) => {
                                                                            return item.id;
                                                                        }
                                                                    }
                                                                    // isDisabled={disabledForm}
                                                                />
                                                            </Form.Group>
                                                            {
                                                                errors.knownByStaffMessageError && <p style={{ color: "red" }}>{errors.knownByStaffMessageError}</p>
                                                            }
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_handover_inventory.field_description")}</Form.Label>
                                                                <textarea
                                                                    className="form-control"
                                                                    value={handover.handover_description}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        setHandover({ ...handover, handover_description: e.target.value })
                                                                    }}
                                                                    rows="4"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>


                                        }>


                                        </ContainerBox>
                                    </div>

                                </div>


                            </div>
                            {
                                handoverId !== 0 ?
                                    <Button className="cancel" onClick={printPageArea}  >{t("detail_handover_inventory.button_print")} <Printer /></Button>
                                    :
                                    <></>
                            }



                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>

                                <ContainerBox
                                    titleCaption={`${t("detail_handover_inventory.header_list_inventory")}`}
                                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                    containerPos="inner"
                                    actionContainerChild={
                                        <div>
                                            <Form.Control hidden className="barcode-input" id="barcode-input" onChangeCapture={(e) => {
                                                // console.log("FROM React", e);
                                            }} onChange={(e) => {
                                                setBarcodeState(e.target.value);
                                                // console.log("FROM React", e.target.value);

                                            }} value={barcodeState} type="text" disabled placeholder="Barcode" />
                                            <Button variant="secondary" style={{

                                            }} onClick={() => {
                                                clearHandoverInventoryModal();
                                                setShowInventoryModal(true);
                                                setSelectedHandoverDetail({ ...selectedHandoverDetail, id: 0, handover_id: handover.id, received_by_staff_id: handover.received_by_staff_id, inventory_stock_id: "", bulk_item: false });
                                                handoverDetail.id = 0;
                                                loadInventoryStock(handover.given_by_staff_id);

                                            }} disabled={searchParams.get("id") !== null || disabledForm === true ? true : !updatedButton}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                    alignSelf: "center",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        flex: 1,
                                                    }}><FilePlusFill size={32} /></div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 8,
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        paddingLeft: 10,
                                                        fontWeight: "bold",
                                                        fontSize: 18,
                                                    }}>{t("detail_handover_inventory.add_button")}</div>
                                                </div>
                                            </Button>
                                        </div>
                                    }
                                    childContent={
                                        <div className="master-table-inner-container">
                                            <div className="master-table-searchbar-container">
                                                <div className="master-table-searchbar-textbox">
                                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_handover_inventory.search_box")}`}
                                                        onKeyPress={async (e) => {
                                                            if (e.charCode === 13) {
                                                                /* setLoading(true);
                                                                await loadHandoverDetail(); */

                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setSearchQuery(e.target.value);
                                                        }} />
                                                </div>
                                                <div className="master-table-searchbar-button">
                                                    <div style={{ paddingRight: 5 }}>
                                                        <Button onClick={async () => {
                                                            setLoading(true);
                                                            await loadHandoverDetail();
                                                        }}><Search /></Button>
                                                    </div>
                                                    <div style={{ paddingLeft: 5 }}>
                                                        <Button onClick={async () => {
                                                            setLoading(true);
                                                            setSearchQuery("");
                                                            await loadHandoverDetail();
                                                        }}><ArrowClockwise /></Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{

                                                maxHeight: 400,
                                                overflowY: "auto",
                                                border: "1px solid #ccc"
                                            }}>

                                                <div className="table-container">
                                                    <div className="table-header">
                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                        <div style={{ flex: 3 }} className="table-header-content">{t("detail_handover_inventory.table_name1")}</div>
                                                        <div style={{ flex: 3 }} className="table-header-content">{t("detail_handover_inventory.table_name2")}</div>
                                                        <div style={{ flex: 3 }} className="table-header-content">{t("detail_handover_inventory.table_name7")}</div>
                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_handover_inventory.table_name3")}</div>
                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_handover_inventory.table_name4")}</div>
                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_handover_inventory.table_name5")}</div>
                                                        <div style={{ flex: 1 }} className="table-header-content">Total Item</div>
                                                        <div style={{ flex: 1 }} className="table-header-content">{t("detail_handover_inventory.unit_name")}</div>
                                                        <div style={{ flex: 1 }} className="table-header-content">{t("detail_handover_inventory.table_name6")}</div>
                                                    </div>

                                                    {
                                                        listSearchedHandoverItem.map((handoverInv, index) => {
                                                            return (<div key={index} className="table-body" >
                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{handoverInv.inventory_master_name}</p></div>
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{handoverInv.inventory_code}</p></div>
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{handoverInv.inventory_type_name}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>
                                                                    {/* {handoverInv.inventory_condition_name} */}
                                                                    <Form.Select required onChange={(e) => {
                                                                        let newList = listSearchedHandoverItem
                                                                        if (e.target.value === "") {
                                                                            newList[index].inventory_condition_id = null
                                                                        } else {
                                                                            newList[index].inventory_condition_id = e.target.value
                                                                        }

                                                                        setListHandoverItem(newList)
                                                                    }} value={handoverInv.inventory_condition_id} >
                                                                        <option selected value="">

                                                                        </option>
                                                                        {listInventoryCondition.map((condition, index) => (
                                                                            <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                                                        ))}
                                                                    </Form.Select></p></div>
                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content"><p><Form.Control onChange={(e) => {
                                                                    let newList = listSearchedHandoverItem
                                                                    if (e.target.value === "") {
                                                                        newList[index].estimation_return_date = null
                                                                    } else {
                                                                        newList[index].estimation_return_date = e.target.value
                                                                    }

                                                                    setListHandoverItem(newList)
                                                                }} type="date" value={handoverInv.estimation_return_date !== undefined ? moment(handoverInv.estimation_return_date).format("yyyy-MM-DD") : ""} required placeholder="Masukan Tanggal " ></Form.Control></p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>
                                                                    {/* {handoverInv.handover_inventory_description} */}
                                                                    <Form.Control onChange={(e) => {
                                                                        let newList = listSearchedHandoverItem
                                                                        newList[index].handover_inventory_description = e.target.value

                                                                        setListHandoverItem(newList)
                                                                    }} value={handoverInv.handover_inventory_description}></Form.Control>
                                                                </p></div>
                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>
                                                                    {/* {handoverInv.bulk_item ? handoverInv.request_quantity : 1} */}
                                                                    <Form.Control disabled={handoverId !== 0 ? true : !handoverInv.bulk_item} onChange={(e) => {
                                                                        let newList = listSearchedHandoverItem
                                                                        if (e.target.value > handoverInv.stock_quantity) {
                                                                            alert(`${t("detail_handover_inventory.stock_available")} ${selectedHandoverDetail.stock_quantity}`)
                                                                        } else {
                                                                            newList[index].last_quantity = handoverInv.request_quantity
                                                                            newList[index].request_quantity = e.target.value

                                                                        }
                                                                        setListHandoverItem(newList)


                                                                    }} value={handoverInv.bulk_item ? handoverInv.request_quantity : 1}></Form.Control>
                                                                </p></div>
                                                                <div style={{ flex: 1 }} className="table-body-content"><p>{handoverInv.unit_name}</p></div>
                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                    <div style={{ padding: 5 }}>
                                                                        <Button disabled={disabledForm === true ? disabledForm : !deletedButton} variant="danger" onClick={() => {
                                                                            if (window.confirm(`${t("detail_handover_inventory.delete_confirmation")}`) == true) {
                                                                                handleremove(index)
                                                                            }
                                                                        }}><Trash />
                                                                        </Button>
                                                                    </div></div>
                                                            </div>)
                                                        }
                                                        )
                                                    }





                                                </div>
                                            </div>





                                            <div style={{
                                                paddingTop: 20,
                                            }}>

                                            </div>
                                            {listHandoverItem.length == 0 ?
                                                <center>{t("detail_handover_inventory.text_no_data")} </center> : <div></div>

                                            }

                                            <Paginations
                                                itemPerPage={itemPerPage}
                                                totalPage={totalPage}
                                                page={page}
                                                setPage={setPage}
                                                setItemPerPage={setItemPerPage}
                                                itemPerPageSelection={itemPerPageSelection}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                                orderBySelection={orderByList}
                                                isDescActive={descending}
                                                setIsDescActive={setDescending}
                                            />
                                            <Loading
                                                loading={loading}
                                            />

                                        </div>

                                    } />
                            </div>
                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                    <></>
                                    :
                                    <ButtonForm addData={() => {
                                        if (listHandoverItem.length < 1) {
                                            alert(`${t("detail_handover_inventory.error_list_sparepart")}`)
                                        } else {
                                            saveHandoverForm();
                                            setDisabledButton(true);
                                        }

                                    }}
                                        saveButton={`${t("button_component.save_button")}`}
                                        cancelButton={`${t("button_component.cancel_button")}`}
                                        button={disabledButton} />
                            }

                        </div>

                    }>


                </ContainerBox>
                <div id="printableArea" style={{ display: 'none' }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ float: 'left', width: '70%' }}>
                        <img src={require("../../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '60px', marginBottom: '80px' }}>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">PT.Radian Peninsula Indonesia</p>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">{t("detail_handover_inventory.text_document_number")} : {handover.numberForm}</p>
                    </div>
                    <div style={{ marginLeft: '200px', width: '100%' }}>
                        <h3 >{t("detail_handover_inventory.print_title")} </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%',  }}>
                        <table className="tableClass" style={{ width: "70%" }}>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_handover_inventory.field_to_division")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{handover.division_to_name}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_handover_inventory.field_from_division")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{handover.division_from_name}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_handover_inventory.field_project")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{handover.project_name}</td>
                            </tr>
                        </table>
                        {/* <p style={{ margin: '0' }} className="text-danger">{t("detail_handover_inventory.field_to_division")} :  {handover.division_to_name}</p>
                        <p style={{ margin: '0' }} className="text-danger">{t("detail_handover_inventory.field_from_division")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:  {handover.division_from_name} </p> */}
                    </div>
                    <div style={{ float: 'right', width: '35%', marginBottom: '20px' }}>
                        <table className="tableClass" >
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_handover_inventory.field_handover_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{handover.handover_date}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_handover_inventory.field_form_number")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{handover.form_number}</td>
                            </tr>
                        </table>
                        {/* <p style={{ margin: '0' }} className="text-danger">{t("detail_handover_inventory.field_handover_date")} : {handover.handover_date} </p>
                        <p style={{ margin: '0' }} className="text-danger">{t("detail_handover_inventory.field_form_number")}&nbsp;&nbsp;&nbsp;:  {handover.form_number}</p> */}
                    </div>





                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr>
                            <td>No </td>
                            <td>{t("detail_handover_inventory.table_name1")} </td>
                            <td>{t("detail_handover_inventory.table_name2")} </td>
                            <td>{t("detail_handover_inventory.table_name7")} </td>
                            <td>{t("detail_handover_inventory.table_name3")} </td>
                            <td>Total Items </td>
                            <td>{t("detail_handover_inventory.unit_name")} </td>
                        </tr>
                        {
                            listHandoverDetail.map((handoverInv, index) => {
                                return (
                                    <tr key={index} className="table-caption-body">
                                        <td>{index + 1}</td>
                                        <td>{handoverInv.inventory_master_name}</td>
                                        <td>{handoverInv.inventory_code}</td>
                                        <td>{handoverInv.inventory_type_name}</td>
                                        <td>{handoverInv.inventory_condition_name}</td>
                                        <td>{handoverInv.bulk_item ? handoverInv.request_quantity : 1}</td>
                                        <td>{handoverInv.unit_name}</td>
                                    </tr>
                                )
                            })
                        }
                    </table>

                    <div style={{ float: 'left', width: '65%', paddingTop: "10px" }}>
                        <tr>
                            <td> <p style={{ margin: '0' }} className="text-danger">{t("detail_handover_inventory.table_name5")} :  </p></td>
                            <td> <p style={{ margin: '0' }} className="text-danger">{handover.handover_description}</p></td>
                        </tr>
                    </div>
                    <div style={{ paddingBottom: 100 }}></div>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        flexWrap: "nowrap",
                        padding: 10,

                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                        }}>
                            <span >{t("detail_handover_inventory.field_given_by")} </span>
                            <p style={{ marginTop: '100px' }}>{handover.staff_givenby ? handover.staff_givenby : " "}</p>
                            <hr width="150px"
                                size="1"

                            // heigh="10"
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                        }}>
                            <span >{t("detail_handover_inventory.field_known_by")} </span>
                            <p style={{ marginTop: '100px' }}>{handover.staff_knownby ? handover.staff_knownby : " "}</p>
                            <hr width="150px"
                                size="1"

                            // heigh="10"
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                        }}>
                            <span >{t("detail_handover_inventory.field_received_by")} </span>
                            <p style={{ marginTop: '100px' }}>{handover.staff_receivedby?handover.staff_receivedby: " " }</p>
                            <hr width="150px"
                                size="1"

                            // heigh="10"
                            />
                        </div>
                    </div>

                    {/* <div style={{ float: 'left', width: '70%', marginTop: '30px' }}>
                        <span >{t("detail_handover_inventory.field_given_by")} </span>
                        <p style={{ marginTop: '100px' }}>{handover.staff_givenby}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '30px' }}>
                        <span >{t("detail_handover_inventory.field_received_by")} </span>
                        <p style={{ marginTop: '100px' }}>{handover.staff_receivedby}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div> */}

                </div>



                {/*============================== MODAL ADD INVENTORY STOCK ============================================ */}

                <Modal show={showInventoryModal}
                    size="lg"
                    onHide={() => {
                        setShowInventoryModal(false);

                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">

                            {/* {handover.id == 0 ? */}
                            <h3>{t("detail_handover_inventory.modal_add_inventory")}</h3>
                            {/* : */}
                            {/* <h3>Informasi Barang</h3> */}
                            {/* } */}
                        </div>

                        <Form onSubmit={(e) => {

                            e.preventDefault();
                            setListHandoverItem([...listHandoverItem, selectedHandoverDetail]);
                            setShowInventoryModal(false);
                            setErrorMessage("");
                        }} style={{ padding: 10 }}>




                            <Form.Group className="mb-3">
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "flex-start"
                                }}>
                                    <Form.Control className="barcode-input" id="barcode-input" onChangeCapture={(e) => {
                                        // console.log("FROM React", e);
                                    }} onChange={(e) => {
                                        setBarcodeState(e.target.value);
                                        // console.log("FROM React", e.target.value);

                                    }} value={barcodeState} type="text" disabled placeholder="Barcode" />
                                </div>
                                <Form.Label>{t("detail_handover_inventory.field_inventory")}</Form.Label>
                                {/* <Select autoFocus={handoverDetail.inventory_stock_id == undefined} placeholder={handoverDetail.inventory_stock_id == "" ? "Pilih Barang..." : <span className="text-danger">Pilih Barang!</span>} required
                                    options={inventoryOptions} value={inventoryOptions.filter(function (option) {
                                        return option.id === handoverDetail.inventory_stock_id;
                                    })} onChange={(e) => {

                                        setHandoverDetail({ ...handoverDetail, handover_id: handover.id, inventory_stock_id: e.id, received_by_staff_id: selectedToStaff.id });
                                        console.log(handoverDetail);
                                    }}
                                /> */}
                                {/* <Form.Select required onChange={(e) => {

                                    let selectedInv = listInventoryStock.find(p => p.id === e.target.value);
                                    let selectedItem = {
                                        handover_id: "",
                                        handover_inventory_description: selectedInv.handover_inventory_description,
                                        id: "",
                                        inventory_code: selectedInv.inventory_code,
                                        inventory_condition_id: selectedInv.inventory_condition_id,
                                        inventory_condition_name: selectedInv.inventory_condition_name,
                                        inventory_master_id: selectedInv.inventory_master_id,
                                        inventory_master_name: selectedInv.inventory_master_name,
                                        inventory_stock_id: selectedInv.id
                                    };
                                    if (selectedInv !== undefined) {
                                        setSelectedHandoverDetail(selectedItem);
                                    } else {
                                        setSelectedHandoverDetail("");
                                    }

                                }} value={selectedHandoverDetail.inventory_stock_id} >
                                    <option selected value="">
                                        Pilih Barang...
                                    </option>
                                    {listInventoryStock.filter((p) => {
                                        return !listHandoverItem.find((x) => {
                                            return p.id === x.inventory_stock_id
                                        }) && p.division_id === handover.from_division_id
                                    }).map((stock, index) => (
                                        // <div>
                                        <option key={index} value={stock.id}>{stock.inventory_master_name} ({stock.inventory_code})</option>
                                     
                                        // </div>
                                    ))}
                                </Form.Select> */}

                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        // return item.inventory_master_name + (item.inventory_code);
                                        if (item.id) {
                                            return item.inventory_type_name+ ' ' + item.inventory_master_name + (` (${item.inventory_code})`);
                                        } else {
                                            return ("");
                                        }
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    // options={listInventoryStock} 
                                    options={listInventoryStock.filter((p) => {
                                        return !listHandoverItem.find((x) => {
                                            return p.id === x.inventory_stock_id
                                        }) && p.division_id === handover.from_division_id
                                    })} 
                                    // value={listInventoryStock} 
                                    value={listInventoryStock.filter(function (option) {
                                        return option.id === selectedHandoverDetail.inventory_stock_id;
                                    })} 
                                    onChange={(e) => {
                                        // console.log(handover.from_division_id);
                                        console.log('list inventory stock',listInventoryStock);

                                        let selectedInv = listInventoryStock.find(p => p.id === e.id);
                                        let selectedItem = {
                                            handover_id: "",
                                            handover_inventory_description: selectedInv.handover_inventory_description,
                                            id: "",
                                            inventory_code: selectedInv.inventory_code,
                                            inventory_condition_id: selectedInv.inventory_condition_id,
                                            inventory_condition_name: selectedInv.inventory_condition_name,
                                            inventory_master_id: selectedInv.inventory_master_id,
                                            inventory_master_name: selectedInv.inventory_master_name,
                                            inventory_stock_id: selectedInv.id,
                                            bulk_item: selectedInv.bulk_item,
                                            stock_quantity: selectedInv.quantity,
                                            inventory_type_name: selectedInv.inventory_type_name,
                                            unit_name: selectedInv.unit_name
                                        };
                                        if (selectedInv !== undefined) {
                                            setSelectedHandoverDetail(selectedItem);
                                        } else {
                                            setSelectedHandoverDetail("");
                                        }

                                    }}
                                    isClearable

                                />
                            </Form.Group>
                            {
                                selectedHandoverDetail.bulk_item &&
                                <div>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Total Item</Form.Label>
                                        <Form.Control type="number" onChange={(e) => {
                                            if (e.target.value >= 0) {
                                                if (e.target.value > selectedHandoverDetail.stock_quantity) {
                                                    setErrorMessage(`${t("detail_handover_inventory.stock_available")} ${selectedHandoverDetail.stock_quantity}`)
                                                } else {
                                                    setErrorMessage("");
                                                    setSelectedHandoverDetail({ ...selectedHandoverDetail, request_quantity: e.target.value })
                                                }

                                            }
                                        }} value={selectedHandoverDetail.request_quantity}></Form.Control>
                                    </Form.Group> <Form.Group className="mb-3">
                                        <Form.Label>{t("detail_handover_inventory.unit_name")}</Form.Label>
                                        <Form.Control disabled type="text" value={selectedHandoverDetail.unit_name}></Form.Control>
                                    </Form.Group>
                                </div>

                            }
                            {
                                errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                            }

                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_handover_inventory.field_return_date")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    if (e.target.value === "") {
                                        setSelectedHandoverDetail({ ...selectedHandoverDetail, estimation_return_date: null })
                                    } else {
                                        setSelectedHandoverDetail({ ...selectedHandoverDetail, estimation_return_date: e.target.value })
                                    }

                                }} type="date" value={selectedHandoverDetail.estimation_return_date !== undefined ? moment(selectedHandoverDetail.estimation_return_date).format("yyyy-MM-DD") : ""} placeholder="Masukan Tanggal " ></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_handover_inventory.field_inventory_condition")}</Form.Label>
                                <Form.Select required onChange={(e) => {
                                    setSelectedHandoverDetail({ ...selectedHandoverDetail, inventory_condition_id: e.target.value });
                                }} value={selectedHandoverDetail.inventory_condition_id} >
                                    <option selected value="">

                                    </option>
                                    {listInventoryCondition.map((condition, index) => (
                                        <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_handover_inventory.field_inventory_description")}</Form.Label>
                                <textarea
                                    className="form-control"
                                    value={selectedHandoverDetail.handover_inventory_description}
                                    placeholder=""
                                    onChange={(e) => {
                                        setSelectedHandoverDetail({ ...selectedHandoverDetail, handover_inventory_description: e.target.value });
                                    }}
                                    rows="4"
                                />
                            </Form.Group>

                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                    <></>
                                    :
                                    <div>
                                        <Button style={{ width: 100 }} disabled={!updatedButton} type="submit">{t("detail_handover_inventory.save_button")}</Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            setBarcodeState("");
                                            setShowInventoryModal(false);
                                        }}>{t("detail_handover_inventory.cancel_button")}</Button>
                                    </div>
                            }

                        </Form>



                    </div>

                </Modal>

            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}