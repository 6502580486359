import "../../../SMSApp.css";
import "./index.css";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import WidthButton from "../../../Components/WidthButton/WidthButton";
import BitLamp2 from "../../../Components/BitLamp2/BitLamp2";
import { useNavigate } from "react-router-dom";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { Col, Row } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import { getPLCCoordinate, isTokenValid, getPlcValue } from "../../../Helpers/ApplicationHelpers";
import { useState, useEffect } from "react";
import Loading from "../../../Components/Loading";


export default function MainDeck() {
    const navigate = useNavigate();
    const navigateWheelHouse = () => { navigate("/FireAlarm/WheelHouse") };
    const navigateBelowMainDeck = () => { navigate("/FireAlarm/BelowMainDeck") };
    const [cookies, setCookie] = useCookies(["token"]);
    const [mainDeckBitlamp, setMainDeckBitlamp] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // const [mainDeckBitlamp, setMainDeckBitlamp] = useState([
    //     {text: "Deck Store", alarm: false, coordinate:"M50"},
    //     {text: "Saloon", alarm: true, coordinate:"M42"},
    //     {text: "Laundry", alarm: false, coordinate:"M48"},
    //     {text: "C/E", alarm: false, coordinate:"M38"},
    //     {text: "Captain", alarm: true, coordinate:"M36"},
    //     {text: "Manual Call Point Dining Room", alarm: true, coordinate:"M54"},
    //     {text: "Prev Store", alarm: true, coordinate:"M44"},
    //     {text: "Galley Heat", alarm: false, coordinate:"M46"},
    //     {text: "Manual Call Point Galley", alarm: true, coordinate:"M56"},
    // ])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (mainDeckBitlamp.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [mainDeckBitlamp]);
    useEffect(() => {
        if (mainDeckBitlamp.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadBitlampValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [mainDeckBitlamp, isDataLoaded]);

    const loadBitlampValue = async () => {
        try {
            setIsDataLoaded(true);
            let coordinate = mainDeckBitlamp.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = mainDeckBitlamp.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setMainDeckBitlamp(updatedList);
            setLoading(false);
            setIsDataLoaded(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "fire_alarm", cookies.vesselId);
            let newLisst = response.filter(p => p.sub_coordinate_group === "main_deck")
            setMainDeckBitlamp(newLisst);
           
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }
    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"FIRE ALARM > MAIN DECK"} /></div>
            <div className="contentMainDeck">
                <div className="topBtn">
                    <WidthButton textBtn={"GO TO WHEEL HOUSE"} handleOnClick={navigateWheelHouse} />
                    <WidthButton textBtn={"GO TO BELOW MAIN DECK"} handleOnClick={navigateBelowMainDeck} />
                </div>
                <div className="containerImgMD">
                    <div className="imgMainDeck">
                        {/* <div className="lampInImg1">
                            <BitLamp2 text={"DINING ROOM"} alarm={false} gapText={10} />
                        </div>
                        <div className="lampInImg2">
                            <BitLamp2 text={"SALOON"} alarm={true} gapText={10} />
                        </div>
                        <div className="lampInImg3">
                            <BitLamp2 text={"CAPTAIN"} alarm={true} gapText={0} />
                        </div>
                        <div className="lampInImg4">
                            <BitLamp2 text={"GALLEY"} alarm={true} gapText={0} />
                        </div>
                        <div className="lampInImg5">
                            <BitLamp2 text={"C/ENG"} alarm={true} gapText={0} />
                        </div> */}
                    </div>

                </div>
                <div className="containerBitLampsMD" >
                    <div className="boxBitLampMD" >
                        <Col md={12}>
                            <Row>
                                {
                                    mainDeckBitlamp.map((bitlamps, index) => {
                                        return (

                                            <Col md={4} key={index}>
                                                <div key={index} style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "column",

                                                }}>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        marginLeft: 200,
                                                        paddingBottom: 20,
                                                        flexWrap: "nowrap",
                                                    }}>
                                                        <BitLamp text={bitlamps.coordinate_desc} alarm={bitlamps.alarm} />
                                                    </div>

                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                        {/* <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            gap: 120,
                            justifyContent: "center"
                        }}>
                            {
                                mainDeckBitlamp.length > 0 &&
                                <>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 30
                                    }}>
                                        <BitLamp text={mainDeckBitlamp[0].coordinate_desc} alarm={mainDeckBitlamp[0].alarm} />
                                        <BitLamp text={mainDeckBitlamp[1].coordinate_desc} alarm={mainDeckBitlamp[1].alarm} />
                                        <BitLamp text={mainDeckBitlamp[2].coordinate_desc} alarm={mainDeckBitlamp[2].alarm} />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 30
                                    }}>
                                        <BitLamp text={mainDeckBitlamp[3].coordinate_desc} alarm={mainDeckBitlamp[3].alarm} />
                                        <BitLamp text={mainDeckBitlamp[4].coordinate_desc} alarm={mainDeckBitlamp[4].alarm} />
                                        <BitLamp text={mainDeckBitlamp[5].coordinate_desc} alarm={mainDeckBitlamp[5].alarm} />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 30
                                    }}>
                                        <BitLamp text={mainDeckBitlamp[6].coordinate_desc} alarm={mainDeckBitlamp[6].alarm} />
                                        <BitLamp text={mainDeckBitlamp[7].coordinate_desc} alarm={mainDeckBitlamp[7].alarm} />
                                        <BitLamp text={mainDeckBitlamp[8].coordinate_desc} alarm={mainDeckBitlamp[8].alarm} />
                                    </div>

                                </>


                            }

                        </div> */}

                        {/* <div className="flexColumn">
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={false} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                        </div>
                        <div className="flexColumn">
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={false} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                        </div>
                        <div className="flexColumn">
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={true} />
                            <BitLamp text={"SMOKE ALARM (ROOM)"} alarm={false} />
                        </div> */}
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
              />
            <div style={{ paddingBottom: 120 }}></div>
            <div><BottomNavBar /></div>

        </>
    );
}