import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import 'moment/locale/id';
import Clock from 'react-live-clock';
import { useNavigate, Link } from "react-router-dom";
// import axios from "axios";
// import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getEquipmentCountByType, getAllVesselByCompany, getAllStaffDocument,
    getDashboarAccessByUserId, getNotificationVesselInventoryStock, getCountNotificationVesselInventoryStock,
    getAllCrewDocument, getDocumentAlert, getServiceByProject, getNotificationVesselInventoryRequisition,
    getCountNotificationVesselInventoryRequisition
} from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import Navbar from "../../Components/NavBar";
import { getScheduleForDashboard, getScheduleCrweForDashboard } from "../../Helpers/SurveyScheduleHelpers";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Paginations from "../../Components/Pagination";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Moment from 'moment';
import TextBox from "../../Components/Textbox";
import DatePicker from "../../Components/DatePicker";
import "../../react-big-calendar.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
import { getDueDateInventory } from "../../Helpers/InventoryHelpers";
import { EyeFill, XSquare, XSquareFill, Search, ArrowClockwise } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";


ChartJS.register(ArcElement, Tooltip, Legend);

export default function DasboardPage() {
    const { t, i18n } = useTranslation();
    const [listCountry, setListCountry] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [listCrewSchedule, setListCrewSchedule] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [calenderAccess, setCalenderAcess] = useState(false);
    const [alertAccess, setAlerAcess] = useState(false);
    const [vesselAccess, setVesselAcess] = useState(false);
    const [equipmentAccess, setEquipmentAcess] = useState(false);
    const [inventoryDueDateAccess, setInventoryDueDateAccess] = useState(false);
    const [projectId, setProjectId] = useState("");
    const [listDueDateInventory, setListDueDateInventory] = useState([]);
    const [dueDateAcess, setDueDateAcces] = useState(false);
    const [documentAcces, setDocumentAcces] = useState(false);
    const [servicePlanAccess, setServicePlanAcces] = useState(false);
    const [document, setDocument] = useState("");
    const [listDocument, setListDocument] = useState([]);
    const [listCrewDocument, setListCrewDocument] = useState([]);
    const [listDocumentAlert, setListDocumentAlert] = useState([]);
    const [vesselCompanyChartDataLoaded, setVesselCompanyChartDataLoaded] = useState(false);
    const [vesselChartData, setVesselChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [equipmentChartData, setEquipmentChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [equipmentByTypeChartDataLoaded, setEquipmentByTypeChartDataLoaded] = useState(false);
    const [listEquipmentByType, setListEquipmentByType] = useState([]);
    const [listSurveySchedule, setListSurveySchedule] = useState([]);
    const [showScheduleDetailModal, setShowScheduleDetailModal] = useState(false);
    const [showCrewScheduleDetailModal, setShowCrewScheduleDetailModal] = useState(false);
    const [scheduleToBeViewed, setScheduleToBeViewed] = useState({
        id: "",
        vessel_id: "",
        survey_type_id: "",
        company_name: "",
        // last_survey_date: "",
        // range_start_date: "",
        // range_end_date: "",
        // due_date: "",
        // is_postponed: false,
        // survey_schedule_remark: "",
        // scheduled_survey_date: "",
        // is_active: true,
        survey_schedule_remark: "",
        project_name: "",
        project_number: "",
        contract_name: "",
        project_start_date: null,
        project_closing_date: null,
        project_status_id: null,
        project_location: "",
        vessel_location: "",
        estimated_start_date: null,
        estimated_end_date: null,
        vessel_arrived_date: null,
    });

    const [crewScheduleToBeViewed, setCrewScheduleToBeViewed] = useState({
        vessel_name: "",
        start_date: "",
        end_date: "",
        list_crew: []
    });
    const [dateRange, setDateRange] = useState({
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
    });
    const [dateRangeCrew, setDateRangeCrew] = useState({
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
    });
    const [listVesselByCompany, setListVesselByCompany] = useState([]);
    const [calendarEvent, setCalendarEvent] = useState([]);
    const [calendarCrewEvent, setCalendarCrewEvent] = useState([]);
    const [listService, setListService] = useState([]);
    const [crewCalendarEver, setCrewCalendarEvent] = useState([])
    const [listVesselInventory, setListVesselInventory] = useState([]);
    const [listVesselInventoryRequisition, setListVesselInventoryRequisition] = useState([]);
    const localizer = momentLocalizer(Moment);
    const [isSearchedVesselInventoryStock, setIsSearchedVesselInventoryStock] = useState(0);
    const [resetSearchVesselInventoryStock, setResetSearchVesselInventoryStock] = useState(0);
    const [pageVesselInventoryStock, setPageVesselInventoryStock] = useState(0);
    const [itemPerPageVesselInventoryStock, setItemPerPageVesselInventoryStock] = useState(50);
    const [totalPageVesselInventoryStock, setTotalPageVesselInventoryStock] = useState(0);
    const [searchQueryVesselInventoryStock, setSearchQueryVesselInventoryStock] = useState("");
    const [descendingVesselInventoryStock, setDescendingVesselInventoryStock] = useState(false);
    const [itemPerPageSelectionVesselInventoryStock, setItemPerPageSelectionVesselInventoryStock] = useState([5, 10, 15, 20, 50]);
    const [orderByVesselInventoryStock, setOrderByVesselInventoryStock] = useState({
        label: `${t("schedule.stock_order_name1")}`,
        value: "vessel_inventory_name"
    });
    const orderByListVesselInventoryStock = [{
        label: `${t("schedule.stock_order_name1")}`,
        value: "vessel_inventory_name"
    }];


    const [isSearchedVesselInventoryRequisition, setIsSearchedVesselInventoryRequisition] = useState(0);
    const [resetSearchVesselInventoryRequisition, setResetSearchVesselInventoryRequisition] = useState(0);
    const [pageVesselInventoryRequisition, setPageVesselInventoryRequisition] = useState(0);
    const [itemPerPageVesselInventoryRequisition, setItemPerPageVesselInventoryRequisition] = useState(50);
    const [totalPageVesselInventoryRequisition, setTotalPageVesselInventoryRequisition] = useState(0);
    const [searchQueryVesselInventoryRequisition, setSearchQueryVesselInventoryRequisition] = useState("");
    const [descendingVesselInventoryRequisition, setDescendingVesselInventoryRequisition] = useState(false);
    const [itemPerPageSelectionVesselInventoryRequisition, setItemPerPageSelectionVesselInventoryRequisition] = useState([5, 10, 15, 20, 50]);
    const [orderByVesselInventoryRequisition, setOrderByVesselInventoryRequisition] = useState({
        label: `${t("schedule.vessel_inventory_order_name1")}`,
        value: "vessel.vessel_name"
    });
    const orderByListVesselInventoryRequisition = [{
        label: `${t("schedule.vessel_inventory_order_name1")}`,
        value: "vessel.vessel_name"
    },
    {
        label: `${t("schedule.vessel_inventory_order_name2")}`,
        value: "staff.staff_name"
    }];


    useEffect(() => {

        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) {
                navigate("/");
            }
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                //moment.locale('id');
                initDashboard();
                initDashboardCrew();
                loadStaffDocument();
                loadCrewDocument();
                loadDueDateInventory();
                loadVesselInventoryStock();
                loadVesselInventoryRequisition();
                loadDocumentAlert();
                loadDashboarAcces();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadVesselInventoryStock();
    }, [pageVesselInventoryStock]);

    useEffect(() => {
        if (isSearchedVesselInventoryStock !== 0)
            loadVesselInventoryStock();
    }, [itemPerPageVesselInventoryStock]);

    useEffect(() => {
        if (isSearchedVesselInventoryStock !== 0)
            loadVesselInventoryStock();
    }, [orderByVesselInventoryStock]);

    useEffect(() => {
        if (resetSearchVesselInventoryStock !== 0) {
            loadVesselInventoryStock()
        }
    }, [resetSearchVesselInventoryStock])

    useEffect(() => {
        if (isSearchedVesselInventoryStock !== 0)
            loadVesselInventoryStock()
    }, [descendingVesselInventoryStock]);

    useEffect(() => {
        loadVesselInventoryRequisition();
    }, [pageVesselInventoryRequisition]);

    useEffect(() => {
        if (isSearchedVesselInventoryStock !== 0)
            loadVesselInventoryRequisition();
    }, [itemPerPageSelectionVesselInventoryRequisition]);

    useEffect(() => {
        if (isSearchedVesselInventoryStock !== 0)
            loadVesselInventoryRequisition();
    }, [orderByListVesselInventoryRequisition]);

    useEffect(() => {
        if (resetSearchVesselInventoryStock !== 0) {
            loadVesselInventoryRequisition()
        }
    }, [resetSearchVesselInventoryRequisition])

    useEffect(() => {
        if (isSearchedVesselInventoryStock !== 0)
            loadVesselInventoryRequisition()
    }, [descendingVesselInventoryRequisition]);


    useEffect(() => {
        setLoading(true);
        initDashboard();

    }, [dateRange]);

    useEffect(() => {
        setLoading(true);
        initDashboardCrew();
    }, [dateRangeCrew]);

    useEffect(() => {
        // console.log(vesselChartData);
        if (vesselChartData.labels.length > 0) {

            setVesselCompanyChartDataLoaded(true);
        }
    }, [vesselChartData]);

    useEffect(() => {
        // console.log(equipmentChartData);
        if (equipmentChartData.labels.length > 0) {

            setEquipmentByTypeChartDataLoaded(true);
        }
    }, [equipmentChartData]);

    useEffect(() => {
        if (projectId !== "")
            loadServiceByProject();
    }, [projectId])

    const loadDashboarAcces = async () => {
        try {
            let response = await getDashboarAccessByUserId(cookies.token, cookies.userId);
            // console.log(response);
            if (response) {
                setCalenderAcess(response.calender);
                setAlerAcess(response.alert);
                setVesselAcess(response.vessel);
                setEquipmentAcess(response.equipment);
                setDueDateAcces(response.due_date_alert);
                setDocumentAcces(response.document_alert);
                setServicePlanAcces(response.service_plan_alert);
                setInventoryDueDateAccess(response.inventory_due_date);
            } else {
                setCalenderAcess(false);
                setAlerAcess(false);
                setVesselAcess(false);
                setEquipmentAcess(false);
                setDueDateAcces(false);
                setDocumentAcces(false);
                setServicePlanAcces(false);
                setInventoryDueDateAccess(false);

            }

            setOrderByVesselInventoryStock({
                label: `${t("schedule.stock_order_name1")}`,
                value: "vessel_inventory_name"
            })

            setOrderByVesselInventoryRequisition({
                label: `${t("schedule.vessel_inventory_order_name1")}`,
                value: "vessel.vessel_name"
            })
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadVesselInventoryStock = async () => {
        try {
            let countryCount = await getCountNotificationVesselInventoryStock(cookies.token, searchQueryVesselInventoryStock, false);
            let totalPageVesselInventoryStock = countryCount / itemPerPageVesselInventoryStock;
            setTotalPageVesselInventoryStock(totalPageVesselInventoryStock);

            let listStock = await getNotificationVesselInventoryStock(cookies.token, pageVesselInventoryStock, itemPerPageVesselInventoryStock, orderByVesselInventoryStock.value, descendingVesselInventoryStock, searchQueryVesselInventoryStock, false);
            setListVesselInventory(listStock)
            setLoading(false);
            setResetSearchVesselInventoryStock(0);
            setIsSearchedVesselInventoryStock(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadVesselInventoryRequisition = async () => {
        try {
            let counts = await getCountNotificationVesselInventoryRequisition(cookies.token, searchQueryVesselInventoryRequisition, false);
            console.log(counts);
            let totalPageVesselInventoryRequisition = counts / itemPerPageVesselInventoryRequisition;
            setTotalPageVesselInventoryRequisition(totalPageVesselInventoryRequisition);

            let listRequesition = await getNotificationVesselInventoryRequisition(cookies.token, pageVesselInventoryRequisition, itemPerPageVesselInventoryRequisition, orderByListVesselInventoryRequisition.value, descendingVesselInventoryRequisition, searchQueryVesselInventoryRequisition, false);
            setListVesselInventoryRequisition(listRequesition)
            setLoading(false);
            setResetSearchVesselInventoryRequisition(0);
            setIsSearchedVesselInventoryRequisition(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initDashboard = async () => {
        try {
            // let listVesselByCompany = await getAllVesselByCompany(cookies.token, cookies.languageId);
            let listSurveySchedule = await getScheduleForDashboard(cookies.token, cookies.languageId, dateRange.start || new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                dateRange.end || new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
            // let listEquipmentByType = await getEquipmentCountByType(cookies.token, cookies.languageId);
            // console.log(listEquipmentByType);
            // console.log(listSurveySchedule);
            // setListVesselByCompany(listVesselByCompany);
            // setListEquipmentByType(listEquipmentByType);
            setListSurveySchedule(listSurveySchedule);

            // let vesselChartData = {
            //     labels: [],
            //     datasets: [],
            // };

            // let vesselChartLabel = [];
            // let vesselDatasets = [];
            // vesselDatasets.push({
            //     labels: 'Jumlah Kapal Berdasarkan Perusahaan',
            //     data: [],
            //     backgroundColor: [],
            //     borderColor: [],
            //     borderWidth: 1,
            // });
            // for (let i = 0; i < listVesselByCompany.length; i++) {
            //     vesselChartLabel.push(listVesselByCompany[i].company_name);
            //     vesselDatasets[0].data.push(listVesselByCompany[i].vessel.length);
            //     let r, g, b;
            //     r = Math.floor(Math.random() * 255) + 1;
            //     g = Math.floor(Math.random() * 255) + 1;
            //     b = Math.floor(Math.random() * 255) + 1;
            //     vesselDatasets[0].backgroundColor.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
            //     vesselDatasets[0].borderColor.push(`rgba(${r}, ${g}, ${b}, 1)`);
            // }
            // vesselChartData.labels = vesselChartLabel;
            // vesselChartData.datasets = vesselDatasets;
            // // console.log(vesselChartData);

            // setVesselChartData(vesselChartData);

            // let equipmentChartData = {
            //     labels: [],
            //     datasets: [],
            // };

            // let equipmentChartLabel = [];
            // let equipmentDatasets = [];
            // equipmentDatasets.push({
            //     labels: 'Jumlah Peralatan Berdasarkan Jenis',
            //     data: [],
            //     backgroundColor: [],
            //     borderColor: [],
            //     borderWidth: 1,
            // });
            // for (let i = 0; i < listEquipmentByType.length; i++) {
            //     equipmentChartLabel.push(listEquipmentByType[i].equipment_name);
            //     equipmentDatasets[0].data.push(listEquipmentByType[i].count);
            //     let r, g, b;
            //     r = Math.floor(Math.random() * 255) + 1;
            //     g = Math.floor(Math.random() * 255) + 1;
            //     b = Math.floor(Math.random() * 255) + 1;
            //     equipmentDatasets[0].backgroundColor.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
            //     equipmentDatasets[0].borderColor.push(`rgba(${r}, ${g}, ${b}, 1)`);
            // }
            // equipmentChartData.labels = equipmentChartLabel;
            // equipmentChartData.datasets = equipmentDatasets;
            // console.log(equipmentChartData);

            // setEquipmentChartData(equipmentChartData);
            let listEvent = [];
            for (let i = 0; i < listSurveySchedule.length; i++) {
                if (listSurveySchedule[i].event_type === "survey_range_date") {
                    if (new Date(listSurveySchedule[i].project_start_date).getMonth() === new Date().getMonth() && new Date(listSurveySchedule[i].project_start_date).getFullYear() === new Date().getFullYear()) {
                        let evt = {
                            id: listSurveySchedule[i].id,
                            title: `${listSurveySchedule[i].vessel_name} -- ${listSurveySchedule[i].project_name}`,
                            allDay: true,
                            start: new Date(listSurveySchedule[i].project_start_date),
                            end: new Date(listSurveySchedule[i].project_closing_date),
                            event_type: listSurveySchedule[i].event_type,
                            detail: listSurveySchedule[i],
                        };
                        listEvent.push(evt);
                    } else {
                        let evt = {
                            id: listSurveySchedule[i].id,
                            title: `${listSurveySchedule[i].vessel_name} -- ${listSurveySchedule[i].project_name}`,
                            allDay: true,
                            start: new Date(listSurveySchedule[i].project_start_date),
                            end: new Date(listSurveySchedule[i].project_closing_date),
                            event_type: listSurveySchedule[i].event_type,
                            detail: listSurveySchedule[i],
                        };
                        listEvent.push(evt);
                    }

                }

                if (listSurveySchedule[i].event_type === "survey_due_date") {
                    let evt = {
                        id: listSurveySchedule[i].id,
                        title: `${listSurveySchedule[i].vessel_name} -- ${listSurveySchedule[i].project_name}`,
                        allDay: true,
                        start: new Date(listSurveySchedule[i].project_closing_date),
                        end: new Date(listSurveySchedule[i].project_closing_date),
                        event_type: listSurveySchedule[i].event_type,
                        detail: listSurveySchedule[i],
                    };
                    listEvent.push(evt);
                }

                if (listSurveySchedule[i].event_type === "scheduled_survey_date") {
                    let evt = {
                        id: listSurveySchedule[i].id,
                        title: `${listSurveySchedule[i].vessel_name} -- ${listSurveySchedule[i].project_name}`,
                        allDay: true,
                        start: new Date(listSurveySchedule[i].project_start_date),
                        end: new Date(listSurveySchedule[i].project_start_date),
                        event_type: listSurveySchedule[i].event_type,
                        detail: listSurveySchedule[i],
                    };
                    listEvent.push(evt);
                }
            }
            setCalendarEvent(listEvent);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initDashboardCrew = async () => {
        try {
            let listSchedule = await getScheduleCrweForDashboard(cookies.token, cookies.languageId, dateRangeCrew.start || new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                dateRangeCrew.end || new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), cookies.extCustomerId);

            setListCrewSchedule(listSchedule);


            let listEvent = [];
            for (let i = 0; i < listSchedule.length; i++) {
                if (listSchedule[i].event_type === "schedule_range_date") {
                    if (new Date(listSchedule[i].start_date).getMonth() === new Date().getMonth() && new Date(listSchedule[i].start_date).getFullYear() === new Date().getFullYear()) {
                        let evt = {
                            id: listSchedule[i].id,
                            title: `${listSchedule[i].vessel_name} `,
                            allDay: true,
                            start: new Date(listSchedule[i].start_date),
                            end: new Date(listSchedule[i].end_date),
                            event_type: listSchedule[i].event_type,
                            detail: listSchedule[i],
                            list_crew: listSchedule[i].list_crew
                        };
                        listEvent.push(evt);
                    } else {
                        let evt = {
                            id: listSchedule[i].id,
                            title: `${listSchedule[i].vessel_name} `,
                            allDay: true,
                            start: new Date(listSchedule[i].start_date),
                            end: new Date(listSchedule[i].end_date),
                            event_type: listSchedule[i].event_type,
                            detail: listSchedule[i],
                            list_crew: listSchedule[i].list_crew
                        };
                        listEvent.push(evt);
                    }

                }

                if (listSchedule[i].event_type === "schedule_due_date") {
                    let evt = {
                        id: listSchedule[i].id,
                        title: `${listSchedule[i].vessel_name} `,
                        allDay: true,
                        start: new Date(listSchedule[i].end_date),
                        end: new Date(listSchedule[i].end_date),
                        event_type: listSchedule[i].event_type,
                        detail: listSchedule[i],
                        list_crew: listSchedule[i].list_crew
                    };
                    listEvent.push(evt);
                }

                if (listSchedule[i].event_type === "scheduled_date") {
                    let evt = {
                        id: listSchedule[i].id,
                        title: `${listSchedule[i].vessel_name} `,
                        allDay: true,
                        start: new Date(listSchedule[i].start_date),
                        end: new Date(listSchedule[i].start_date),
                        event_type: listSchedule[i].event_type,
                        detail: listSchedule[i],
                        list_crew: listSchedule[i].list_crew
                    };
                    listEvent.push(evt);
                }
            }
            // console.log(listEvent)
            setCalendarCrewEvent(listEvent);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const getDayProps = (e) => {
        let dayProps = {
            style: {}
        };
        let evtIdx = listSurveySchedule.findIndex(p => new Date(p.due_date).getDate() === new Date(e).getDate() &&
            new Date(p.due_date).getMonth() === new Date(e).getMonth() && new Date(p.due_date).getFullYear() === new Date(e).getFullYear());
        if (evtIdx) {
            dayProps.style = {
                backgroundColor: "#fff"
            }
        } else {
            dayProps.style = {
                backgroundColor: "#74ad31"
            }
        }
        return (dayProps);
    }

    const getDayPropsCrew = (e) => {
        let dayProps = {
            style: {}
        };
        let evtIdx = listCrewSchedule.findIndex(p => new Date(p.start_date).getDate() === new Date(e).getDate() &&
            new Date(p.start_date).getMonth() === new Date(e).getMonth() && new Date(p.start_date).getFullYear() === new Date(e).getFullYear());
        if (evtIdx) {
            dayProps.style = {
                backgroundColor: "#fff"
            }
        } else {
            dayProps.style = {
                backgroundColor: "#74ad31"
            }
        }
        return (dayProps);
    }

    const getEventProps = (e) => {
        let eventProps = {
            style: {}
        };
        if (e.event_type === "survey_range_date") {
            eventProps.style = {
                backgroundColor: "#b1eb34",
                color: "black"
            }
        } else if (e.event_type === "survey_due_date") {
            eventProps.style = {
                backgroundColor: "#ff0000"
            }
        } else if (e.event_type === "scheduled_survey_date") {
            eventProps.style = {
                backgroundColor: "#00bfff"
            }
        }

        return (eventProps);
    }

    const getEventPropsCrew = (e) => {
        let eventProps = {
            style: {}
        };
        if (e.event_type === "schedule_range_date") {
            eventProps.style = {
                backgroundColor: "#b1eb34",
                color: "black"
            }
        } else if (e.event_type === "schedule_due_date") {
            eventProps.style = {
                backgroundColor: "#ff0000"
            }
        } else if (e.event_type === "scheduled_date") {
            eventProps.style = {
                backgroundColor: "#00bfff"
            }
        }

        return (eventProps);
    }

    const loadStaffDocument = async () => {
        try {
            let response = await getAllStaffDocument(cookies.token);
            // console.log(response);
            setListDocument(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCrewDocument = async () => {
        try {
            let response = await getAllCrewDocument(cookies.token);
            setListCrewDocument(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDueDateInventory = async () => {
        try {
            let response = await getDueDateInventory(cookies.token);
            // console.log(response);
            setListDueDateInventory(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDocumentAlert = async () => {
        try {
            let response = await getDocumentAlert(cookies.token);
            setListDocumentAlert(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadServiceByProject = async () => {
        try {

            let response = await getServiceByProject(cookies.token, projectId)
            setListService(response);
            setProjectId("")
        } catch (exception) {
            console.log(exception)
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                width: "90%"
            }}>

                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                }}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-start"
                    }}>
                        <h2 className="dashboard-main-header">{`${t("schedule.welcome_text")}, ${cookies.userName} !`}</h2>
                    </div>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end"
                    }}>
                        <Clock
                            className="dashboard-main-header"
                            format={'dddd, DD MMMM YYYY, hh:mm:ss'}
                            ticking={true}
                            timezone={'Asia/Jakarta'} />
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#ffffffee"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 10
                    }}>

                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-start",
                            padding: 10,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "#282c34",
                            borderRadius: 5,
                            marginRight: 5,
                            flexDirection: "column"
                        }}>
                            <div style={{
                                display: "flex",
                                padding: 5,
                                borderRadius: 5,
                                fontWeight: "bold",
                                fontSize: 25
                            }}>
                                {t("schedule.text_notification")}
                            </div>
                            {
                                documentAcces &&
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 30,
                                    flexWrap: "nowrap",
                                    width: "100%",
                                    paddingBottom: 10
                                }}>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                    flex: 1,
                                                    width: "100%",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 6,
                                                        flexWrap: "nowrap",
                                                        flexDirection: "column",
                                                        color: "black",
                                                        fontSize: 18
                                                    }}>
                                                        <p>{t("schedule.staff_document")}</p>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 5,
                                                        flexWrap: "nowrap",
                                                        flexDirection: "row",
                                                    }}>


                                                    </div>

                                                </div>



                                            </Accordion.Header>
                                            <Accordion.Body >

                                                {
                                                    listDocument.length > 0 &&
                                                    <div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row"
                                                        }}>

                                                            <div style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center",
                                                                border: "#282c34",
                                                                borderWidth: 0.5,
                                                                borderStyle: "solid"
                                                            }} >{t("schedule.staff_document_table_name1")}</div>
                                                            <div style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center",
                                                                border: "#282c34",
                                                                borderWidth: 0.5,
                                                                borderStyle: "solid"
                                                            }} >{t("schedule.staff_document_table_name2")}</div>
                                                            <div style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center",
                                                                border: "#282c34",
                                                                borderWidth: 0.5,
                                                                borderStyle: "solid"
                                                            }} >{t("schedule.staff_document_table_name3")}</div>
                                                        </div>
                                                        {
                                                            listDocument.map((document, index) => {
                                                                return (<div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row"
                                                                }}>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{document.staff_name}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{document.document_name}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{Moment(document.expired_date).format("DD MMMM YYYY")}</p></div>

                                                                </div>)
                                                            }
                                                            )
                                                        }
                                                    </div>

                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            }
                            {
                                inventoryDueDateAccess &&
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 30,
                                    flexWrap: "nowrap",
                                    width: "100%",
                                    paddingBottom: 10
                                }}>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                    flex: 1,
                                                    width: "100%",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 6,
                                                        flexWrap: "nowrap",
                                                        flexDirection: "column",
                                                        color: "black",
                                                        fontSize: 18
                                                    }}>
                                                        <p>{t("schedule.return_inventory")}</p>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 5,
                                                        flexWrap: "nowrap",
                                                        flexDirection: "row",
                                                    }}>


                                                    </div>

                                                </div>



                                            </Accordion.Header>
                                            <Accordion.Body >

                                                {
                                                    listDueDateInventory.length > 0 &&
                                                    <div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row"
                                                        }}>

                                                            <div style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center",
                                                                border: "#282c34",
                                                                borderWidth: 0.5,
                                                                borderStyle: "solid"
                                                            }} >{t("schedule.inventory_table_name1")}</div>
                                                            <div style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center",
                                                                border: "#282c34",
                                                                borderWidth: 0.5,
                                                                borderStyle: "solid"
                                                            }} >{t("schedule.inventory_table_name2")}</div>
                                                            <div style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center",
                                                                border: "#282c34",
                                                                borderWidth: 0.5,
                                                                borderStyle: "solid"
                                                            }} >{t("schedule.inventory_table_naem3")}</div>
                                                            <div style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                padding: 10,
                                                                justifyContent: "center",
                                                                border: "#282c34",
                                                                borderWidth: 0.5,
                                                                borderStyle: "solid"
                                                            }} >{t("schedule.inventory_table_name4")}</div>
                                                        </div>
                                                        {
                                                            listDueDateInventory.map((inv, index) => {
                                                                return (<div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row"
                                                                }}>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{inv.staff_name}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{inv.inventory_master_name}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{inv.inventory_code}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{Moment(inv.estimation_return_date).format("DD MMMM YYYY")}</p></div>

                                                                </div>)
                                                            }
                                                            )
                                                        }
                                                    </div>

                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            }

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 30,
                                flexWrap: "nowrap",
                                width: "100%",
                                paddingBottom: 10
                            }}>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                flex: 1,
                                                width: "100%",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 6,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "column",
                                                    color: "black",
                                                    fontSize: 18
                                                }}>
                                                    <p>{t("schedule.stock")}</p>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 5,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "row",
                                                }}>


                                                </div>

                                            </div>



                                        </Accordion.Header>
                                        <Accordion.Body >

                                            <div className="master-table-inner-container">
                                                <div className="master-table-searchbar-container">
                                                    <div className="master-table-searchbar-textbox">
                                                        <Form.Control value={searchQueryVesselInventoryStock} type="text" placeholder={`${t("schedule.stock_search_box")}`}
                                                            onKeyPress={async (e) => {
                                                                if (e.charCode === 13) {
                                                                    setPageVesselInventoryStock(0);
                                                                    setLoading(true);
                                                                    await loadVesselInventoryStock();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setSearchQueryVesselInventoryStock(e.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="master-table-searchbar-button">
                                                        <div style={{ paddingRight: 5 }}>
                                                            <Button onClick={async () => {
                                                                setLoading(true);
                                                                setPageVesselInventoryStock(0);
                                                                await loadVesselInventoryStock();
                                                            }}><Search /></Button>
                                                        </div>
                                                        <div style={{ paddingLeft: 5 }}>
                                                            <Button onClick={async () => {
                                                                setLoading(true);
                                                                setSearchQueryVesselInventoryStock("");
                                                                setResetSearchVesselInventoryStock(1);
                                                                // await loadCountry();
                                                            }}><ArrowClockwise /></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-container">
                                                    <div className="table-header">
                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                        <div style={{ flex: 4 }} className="table-header-content">{t("schedule.stock_table_name1")}</div>
                                                        <div style={{ flex: 4 }} className="table-header-content">{t("schedule.stock_table_name2")}</div>

                                                    </div>
                                                    {
                                                        listVesselInventory.map((inventory, index) => {
                                                            return (<div className="table-body">
                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(pageVesselInventoryStock * (itemPerPageVesselInventoryStock)) + (index + 1)}</p></div>
                                                                <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.vessel_inventory_name}</p></div>
                                                                <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.vessel_inventory_brand}</p></div>

                                                            </div>)
                                                        }
                                                        )
                                                    }
                                                </div>
                                                <div style={{
                                                    paddingTop: 20,
                                                }}>

                                                </div>
                                                <Paginations
                                                    itemPerPage={itemPerPageVesselInventoryStock}
                                                    totalPage={totalPageVesselInventoryStock}
                                                    page={pageVesselInventoryStock}
                                                    setPage={setPageVesselInventoryStock}
                                                    setItemPerPage={setItemPerPageVesselInventoryStock}
                                                    itemPerPageSelection={itemPerPageSelectionVesselInventoryStock}
                                                    orderBy={orderByVesselInventoryStock}
                                                    setOrderBy={setOrderByVesselInventoryStock}
                                                    orderBySelection={orderByListVesselInventoryStock}
                                                    isDescActive={descendingVesselInventoryStock}
                                                    setIsDescActive={setDescendingVesselInventoryStock}
                                                    setIsSearched={setIsSearchedVesselInventoryStock}
                                                />
                                                <Loading
                                                    loading={loading}
                                                    loadingText={`${t("loading_component.text")}`}
                                                />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 30,
                                flexWrap: "nowrap",
                                width: "100%",
                                paddingBottom: 10
                            }}>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                flex: 1,
                                                width: "100%",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 6,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "column",
                                                    color: "black",
                                                    fontSize: 18
                                                }}>
                                                    <p>{t("schedule.vessel_inventory_requisition")}</p>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 5,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "row",
                                                }}>


                                                </div>

                                            </div>



                                        </Accordion.Header>
                                        <Accordion.Body >

                                            <div className="master-table-inner-container">
                                                <div className="master-table-searchbar-container">
                                                    <div className="master-table-searchbar-textbox">
                                                        <Form.Control value={searchQueryVesselInventoryStock} type="text" placeholder={`${t("schedule.vessel_inventory_search_box")}`}
                                                            onKeyPress={async (e) => {
                                                                if (e.charCode === 13) {
                                                                    setPageVesselInventoryRequisition(0);
                                                                    setLoading(true);
                                                                    await loadVesselInventoryRequisition();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setSearchQueryVesselInventoryRequisition(e.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="master-table-searchbar-button">
                                                        <div style={{ paddingRight: 5 }}>
                                                            <Button onClick={async () => {
                                                                setLoading(true);
                                                                setPageVesselInventoryRequisition(0);
                                                                await loadVesselInventoryRequisition();
                                                            }}><Search /></Button>
                                                        </div>
                                                        <div style={{ paddingLeft: 5 }}>
                                                            <Button onClick={async () => {
                                                                setLoading(true);
                                                                setSearchQueryVesselInventoryRequisition("");
                                                                setResetSearchVesselInventoryRequisition(1);
                                                                // await loadCountry();
                                                            }}><ArrowClockwise /></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-container">
                                                    <div className="table-header">
                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                        <div style={{ flex: 4 }} className="table-header-content">{t("schedule.vessel_inventory_table_name1")}</div>
                                                        <div style={{ flex: 4 }} className="table-header-content">{t("schedule.vessel_inventory_table_name2")}</div>
                                                        <div style={{ flex: 4 }} className="table-header-content">{t("schedule.vessel_inventory_table_name3")}</div>
                                                        <div style={{ flex: 2 }} className="table-header-content">{t("schedule.vessel_inventory_table_name4")}</div>

                                                    </div>
                                                    {
                                                        listVesselInventoryRequisition.map((requesition, index) => {
                                                            return (<div className="table-body">
                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(pageVesselInventoryRequisition * (itemPerPageVesselInventoryRequisition)) + (index + 1)}</p></div>
                                                                <div style={{ flex: 4, justifyContent: "center" }} className="table-body-content"><p>{requesition.vessel_name}</p></div>
                                                                <div style={{ flex: 4, justifyContent: "center" }} className="table-body-content"><p>{requesition.staff_name}</p></div>
                                                                <div style={{ flex: 4, justifyContent: "center" }} className="table-body-content"><p>{Moment(requesition.request_date).format("DD-MM-YYYY")}</p></div>
                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                    <div style={{ padding: 5 }}>
                                                                        <Link to={`/VesselInventoryRequisition/Detail?id=${requesition.id}`} target="_blank">
                                                                            <Button variant="primary" onClick={() => {

                                                                            }}><EyeFill />
                                                                            </Button>
                                                                        </Link>
                                                                    </div></div>
                                                            </div>)
                                                        }
                                                        )
                                                    }
                                                </div>
                                                <div style={{
                                                    paddingTop: 20,
                                                }}>

                                                </div>
                                                <Paginations
                                                    itemPerPage={itemPerPageVesselInventoryRequisition}
                                                    totalPage={totalPageVesselInventoryRequisition}
                                                    page={pageVesselInventoryRequisition}
                                                    setPage={setPageVesselInventoryRequisition}
                                                    setItemPerPage={setItemPerPageVesselInventoryRequisition}
                                                    itemPerPageSelection={itemPerPageSelectionVesselInventoryRequisition}
                                                    orderBy={orderByVesselInventoryRequisition}
                                                    setOrderBy={setOrderByVesselInventoryRequisition}
                                                    orderBySelection={orderByListVesselInventoryRequisition}
                                                    isDescActive={descendingVesselInventoryRequisition}
                                                    setIsDescActive={setDescendingVesselInventoryRequisition}
                                                    setIsSearched={setIsSearchedVesselInventoryRequisition}
                                                />
                                                {/* <Loading
                                                    loading={loading}

                                                /> */}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                        </div>





                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10
                    }}>
                        {
                            calenderAccess &&
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "flex-start",
                                padding: 10,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#282c34",
                                borderRadius: 5,
                                marginRight: 5,
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: 10,
                                    borderRadius: 5,
                                    fontWeight: "bold",
                                    fontSize: 20
                                }}>
                                    {t("schedule.activity_calendar")}
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: 800,
                                }}>
                                    <Calendar
                                        localizer={localizer}
                                        events={calendarEvent}
                                        defaultDate={new Date()}
                                        selectable
                                        popup
                                        startAccessor="start"
                                        endAccessor="end"
                                        messages={{
                                            agenda: `${t("schedule.agenda")}`,
                                            allDay: `${t("schedule.all_day")}`,
                                            month: `${t("schedule.month")}`,
                                            day: `${t("schedule.day")}`,
                                            today: `${t("schedule.today")}`,
                                            previous: `${t("schedule.previous")}`,
                                            next: `${t("schedule.next")}`,
                                            date: `${t("schedule.date")}`,
                                            noEventsInRange: `${t("schedule.text_no_event")}`,
                                            time: `${t("schedule.time")}`,
                                            tomorrow: `${t("schedule.tomorrow")}`,
                                            week: `${t("schedule.week")}`,
                                            work_week: `${t("schedule.work_week")}`,
                                            yesterday: `${t("schedule.yesterday")}`
                                        }}
                                        onSelectEvent={(e) => {
                                            setProjectId(e.id)
                                            setScheduleToBeViewed(e.detail);
                                            setShowScheduleDetailModal(true);
                                        }}
                                        onRangeChange={(e) => {
                                            // console.log(e);
                                            setDateRange(e);
                                        }}
                                        dayPropGetter={getDayProps}
                                        eventPropGetter={getEventProps}
                                    />
                                </div>
                            </div>
                        }

                        {
                            alertAccess &&
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                padding: 10,
                                borderRadius: 5,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#282c34",
                                borderRadius: 5,
                                marginLeft: 5,
                            }}>

                                {
                                    dueDateAcess &&
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: 10,
                                            borderRadius: 5,
                                            fontWeight: "bold",
                                            fontSize: 20
                                        }}>
                                            {t("schedule.survey_due_date")}
                                        </div>
                                        {listSurveySchedule.sort(function compare(a, b) {
                                            var dateA = new Date(a.project_start_date);
                                            var dateB = new Date(b.project_closing_date);
                                            return dateA - dateB;
                                        }).map((schedule, index) => {
                                            if (schedule.event_type === "survey_due_date") {
                                                return (
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                    }} key={index}>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            flexDirection: "row",
                                                            flex: 6,
                                                            color: Moment(schedule.project_start_date).isBefore(Moment(new Date())) ? "red" : "black"
                                                        }}>
                                                            {`${schedule.vessel_name} - ${schedule.project_name}`}
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            flexDirection: "row",
                                                            flex: 2,
                                                            color: Moment(schedule.project_start_date).isBefore(Moment(new Date())) ? "red" : "black"
                                                        }}>
                                                            {Moment(schedule.project_start_date).format("DD MMMM YYYY")}
                                                        </div>
                                                    </div>)
                                            }

                                        })}
                                    </div>
                                }

                                {
                                    servicePlanAccess &&
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: 10,
                                            borderRadius: 5,
                                            fontWeight: "bold",
                                            fontSize: 20
                                        }}>
                                            {t("schedule.survey_plan")}
                                        </div>
                                        {listSurveySchedule.sort(function compare(a, b) {
                                            var dateA = new Date(a.project_start_date);
                                            var dateB = new Date(b.project_start_date);
                                            return dateA - dateB;
                                        }).map((schedule, index) => {
                                            if (schedule.event_type === "scheduled_survey_date") {
                                                return (
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                    }} key={index}>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            flexDirection: "row",
                                                            flex: 6,
                                                            color: Moment(schedule.project_start_date).isBefore(Moment(new Date())) ? "red" : "black"
                                                        }}>
                                                            {`${schedule.vessel_name} - ${schedule.project_name}`}
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            flexDirection: "row",
                                                            flex: 2,
                                                            color: Moment(schedule.project_start_date).isBefore(Moment(new Date())) ? "red" : "black"
                                                        }}>
                                                            {Moment(schedule.project_start_date).format("DD MMMM YYYY")}
                                                        </div>
                                                    </div>)
                                            }

                                        })}

                                    </div>
                                }

                            </div>
                        }


                    </div>
                    {/* <div style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10,
                    }}>

                        {
                            vesselAccess &&
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                padding: 10,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#282c34",
                                borderRadius: 5,
                                marginRight: 5,
                                flexWrap: "wrap"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "center",
                                    fontWeight: "bold",
                                    fontSize: 20
                                }}>
                                    JUMLAH KAPAL BERDASARKAN PERUSAHAAN
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 16,
                                    justifyContent: "flex-start",
                                    padding: 20,
                                    backgroundColor: "white"
                                }}>
                                    {vesselCompanyChartDataLoaded && <Doughnut style={{
                                        backgroundColor: "transparent"
                                    }} data={vesselChartData} />}
                                </div>
                            </div>
                        }

                        {
                            equipmentAccess &&
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                padding: 10,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#282c34",
                                borderRadius: 5,
                                marginLeft: 5,
                                flexWrap: "wrap"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "center",
                                    fontWeight: "bold",
                                    fontSize: 20
                                }}>
                                    JUMLAH PERALATAN BERDASARKAN JENIS
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 16,
                                    justifyContent: "flex-start",
                                    padding: 20,
                                    backgroundColor: "white"
                                }}>
                                    {equipmentByTypeChartDataLoaded && <Doughnut style={{
                                        backgroundColor: "transparent"
                                    }} data={equipmentChartData} />}
                                </div>
                            </div>
                        }


                    </div> */}

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10
                    }}>

                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-start",
                            padding: 10,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "#282c34",
                            borderRadius: 5,
                            marginRight: 5,
                            flexDirection: "column"
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: 10,
                                borderRadius: 5,
                                fontWeight: "bold",
                                fontSize: 20
                            }}>
                                {t("schedule.crew_calendar")}
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                height: 800,
                            }}>
                                <Calendar
                                    localizer={localizer}
                                    events={calendarCrewEvent}
                                    defaultDate={new Date()}
                                    selectable
                                    popup
                                    startAccessor="start"
                                    endAccessor="end"
                                    messages={{
                                        agenda: `${t("schedule.agenda")}`,
                                        allDay: `${t("schedule.all_day")}`,
                                        month: `${t("schedule.month")}`,
                                        day: `${t("schedule.day")}`,
                                        today: `${t("schedule.today")}`,
                                        previous: `${t("schedule.previous")}`,
                                        next: `${t("schedule.next")}`,
                                        date: `${t("schedule.date")}`,
                                        noEventsInRange: `${t("schedule.text_no_event")}`,
                                        time: `${t("schedule.time")}`,
                                        tomorrow: `${t("schedule.tomorrow")}`,
                                        week: `${t("schedule.week")}`,
                                        work_week: `${t("schedule.work_week")}`,
                                        yesterday: `${t("schedule.yesterday")}`
                                    }}
                                    onSelectEvent={(e) => {
                                        setCrewScheduleToBeViewed(e.detail);
                                        setShowCrewScheduleDetailModal(true);
                                    }}
                                    onRangeChange={(e) => {
                                        // console.log(e);
                                        setDateRangeCrew(e);
                                    }}
                                    dayPropGetter={getDayPropsCrew}
                                    eventPropGetter={getEventPropsCrew}
                                />
                            </div>
                        </div>


                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            padding: 10,
                            borderRadius: 5,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "#282c34",
                            borderRadius: 5,
                            marginLeft: 5,
                        }}>


                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: 10,
                                borderRadius: 5,
                                fontWeight: "bold",
                                fontSize: 20
                            }}>
                                {t("schedule.vessel_schedule_plan")}
                            </div>
                            {listCrewSchedule.sort(function compare(a, b) {
                                var dateA = new Date(a.start_date);
                                var dateB = new Date(b.start_date);
                                return dateA - dateB;
                            }).map((schedule, index) => {
                                if (schedule.event_type === "scheduled_date") {
                                    return (
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            flexDirection: "row",
                                            width: "100%",
                                        }} key={index}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                flexDirection: "row",
                                                flex: 6,
                                                color: Moment(schedule.start_date).isBefore(Moment(new Date())) ? "red" : "black"
                                            }}>
                                                {`${schedule.vessel_name} `}
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                flexDirection: "row",
                                                flex: 2,
                                                color: Moment(schedule.start_date).isBefore(Moment(new Date())) ? "red" : "black"
                                            }}>
                                                {Moment(schedule.start_date).format("DD MMMM YYYY")}
                                            </div>
                                        </div>)
                                }

                            })}


                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: 10,
                                borderRadius: 5,
                                fontWeight: "bold",
                                fontSize: 20
                            }}>
                                {t("schedule.vessel_schedule_end")}
                            </div>
                            {listCrewSchedule.sort(function compare(a, b) {
                                var dateA = new Date(a.end_date);
                                var dateB = new Date(b.end_date);
                                return dateA - dateB;
                            }).map((schedule, index) => {
                                if (schedule.event_type === "schedule_due_date") {
                                    return (
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            flexDirection: "row",
                                            width: "100%",
                                        }} key={index}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                flexDirection: "row",
                                                flex: 6,
                                                color: Moment(schedule.end_date).isBefore(Moment(new Date())) ? "red" : "black"
                                            }}>
                                                {`${schedule.vessel_name}`}
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                flexDirection: "row",
                                                flex: 2,
                                                color: Moment(schedule.end_date).isBefore(Moment(new Date())) ? "red" : "black"
                                            }}>
                                                {Moment(schedule.end_date).format("DD MMMM YYYY")}
                                            </div>
                                        </div>)
                                }

                            })}


                            <div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: 10,
                                    borderRadius: 5,
                                    fontWeight: "bold",
                                    fontSize: 20
                                }}>
                                    {t("schedule.crew_document")}
                                </div>


                                {
                                    listCrewDocument.length > 0 &&
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row"
                                        }}>

                                            <div style={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: 10,
                                                justifyContent: "center",
                                                border: "#282c34",
                                                borderWidth: 0.5,
                                                borderStyle: "solid"
                                            }} >{t("schedule.crew_document_table_name1")}</div>
                                            <div style={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: 10,
                                                justifyContent: "center",
                                                border: "#282c34",
                                                borderWidth: 0.5,
                                                borderStyle: "solid"
                                            }} >{t("schedule.crew_document_table_name2")}</div>
                                            <div style={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: 10,
                                                justifyContent: "center",
                                                border: "#282c34",
                                                borderWidth: 0.5,
                                                borderStyle: "solid"
                                            }} >{t("schedule.crew_document_table_name3")}</div>
                                        </div>
                                        {
                                            listCrewDocument.map((document, index) => {
                                                return (<div style={{
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}>
                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{document.staff_name}</p></div>
                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{document.document_name}</p></div>
                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{Moment(document.expired_date).format("DD MMMM YYYY")}</p></div>

                                                </div>)
                                            }
                                            )
                                        }
                                    </div>

                                }

                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: 10,
                                borderRadius: 5,
                                fontWeight: "bold",
                                fontSize: 20
                            }}>
                                {t("schedule.crew_note")}
                            </div>


                            {
                                listDocumentAlert.length > 0 &&
                                <div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // borderStyle:"solid"
                                    }}>
                                        {
                                            listDocumentAlert.map((document, index) => {
                                                return (<div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "100%"
                                                }}>
                                                    <div style={{ flex: 1 }}>{index + 1}.</div>
                                                    <div style={{ flex: 25 }}>{document.staff_name} {document.note}</div>

                                                </div>)
                                            }
                                            )
                                        }
                                    </div>
                                </div>

                            }





                        </div>



                    </div>
                </div>

                <Modal size={"xl"} show={showScheduleDetailModal} onHide={() => {
                    setShowScheduleDetailModal(false);
                }}>
                    <ContainerBox
                        containerPos="inner"
                        useActionContainer={true}
                        actionContainerChild={
                            <div id="button-color">
                                <Button variant="primary" onClick={() => {
                                    setShowScheduleDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquare size={32} /></div>

                                    </div>
                                </Button>

                            </div>
                        }
                        titleCaption={`${t("schedule.schedule_modal")}`}
                        childContent={
                            <div style={{
                                display: "flex",
                                minWidth: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form style={{
                                    width: "100%"
                                }} onSubmit={(e) => {
                                    e.preventDefault();
                                    setShowScheduleDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                        padding: 5,
                                        paddingBottom: 20
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            paddingRight: 5
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                justifyContent: "flex-start",
                                                flex: 1,
                                            }}>
                                                <Form.Group style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    flexDirection: "column",
                                                }} className="mb-3">
                                                    <TextBox value={scheduleToBeViewed.vessel_name} placeholder=""
                                                        caption={`${t("schedule.field_vessel_name")}`} setValueFunction={(e) => {

                                                        }} />
                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                justifyContent: "flex-start",
                                                flex: 1,
                                            }}>
                                                <Form.Group style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    flexDirection: "column",
                                                }} className="mb-3">
                                                    <TextBox value={scheduleToBeViewed.company_name} placeholder=""
                                                        caption={`${t("schedule.field_company_name")}`} setValueFunction={(e) => {

                                                        }} />
                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                justifyContent: "flex-start",
                                                flex: 1,
                                            }}>
                                                <Form.Group style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    flexDirection: "column",
                                                }} className="mb-3">
                                                    <TextBox value={scheduleToBeViewed.project_name} placeholder=""
                                                        caption={`${t("schedule.field_project_name")}`} setValueFunction={(e) => {

                                                        }} />
                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                flex: 1,
                                                alignItems: "center",
                                                alignContent: "center",
                                            }}>
                                                <DatePicker disabled={true} caption={`${t("schedule.field_project_start_date")}`} placeholder="" onDateChange={(date) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, project_start_date: new Date(date) });
                                                }} value={Moment(scheduleToBeViewed.project_start_date).isValid() ? Moment(scheduleToBeViewed.project_start_date).toDate() : ""} />
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            paddingLeft: 5
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                flex: 1,
                                                alignItems: "center",
                                                alignContent: "center",
                                            }}>
                                                <DatePicker disabled={true} caption={`${t("schedule.field_project_end_date")}`} placeholder="" onDateChange={(date) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, project_closing_date: new Date(date) });
                                                }} value={Moment(scheduleToBeViewed.project_closing_date).isValid() ? Moment(scheduleToBeViewed.project_closing_date).toDate() : ""} />
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                flex: 1,
                                                alignItems: "center",
                                                alignContent: "center",
                                            }}>
                                                <DatePicker disabled={true} caption={`${t("schedule.field_estimate_project_start_date")}`} placeholder="Pilih Tanggal" onDateChange={(date) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, estimated_start_date: new Date(date) });
                                                }} value={Moment(scheduleToBeViewed.estimated_start_date).isValid() ? Moment(scheduleToBeViewed.estimated_start_date).toDate() : ""} />
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                flex: 1,
                                                alignItems: "center",
                                                alignContent: "center",
                                            }}>
                                                <DatePicker disabled={true} caption={`${t("schedule.field_estimate_project_end_date")}`} placeholder="Pilih Tanggal" onDateChange={(date) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, estimated_end_date: new Date(date) });
                                                }} value={Moment(scheduleToBeViewed.estimated_end_date).isValid() ? Moment(scheduleToBeViewed.estimated_end_date).toDate() : ""} />
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "flex-start",
                                                flex: 1,
                                            }}>
                                                <Form.Group style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    flexDirection: "column",
                                                }} className="mb-3">
                                                    <Form.Label>{t("schedule.field_end_date")}</Form.Label>
                                                    <div style={{
                                                        display: "flex",
                                                        minWidth: "100%",
                                                    }}>
                                                        <Form.Control disabled onChange={(e) => {
                                                            setScheduleToBeViewed({ ...scheduleToBeViewed, survey_schedule_remark: e.target.value })
                                                        }} value={scheduleToBeViewed.survey_schedule_remark} as="textarea" rows={3} placeholder=""></Form.Control>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="table-container">
                                        <div className="table-header" >
                                            <div style={{ flex: 1 }} className="table-header-content">#</div>
                                            <div style={{ flex: 3 }} className="table-header-content">{t("schedule.table_name1")}</div>
                                            <div style={{ flex: 3 }} className="table-header-content">{t("schedule.table_name2")}</div>
                                            <div style={{ flex: 3 }} className="table-header-content">{t("schedule.table_name3")}</div>
                                            <div style={{ flex: 3 }} className="table-header-content">{t("schedule.table_name4")}</div>
                                            <div style={{ flex: 3 }} className="table-header-content">{t("schedule.table_name5")}</div>
                                            <div style={{ flex: 2 }} className="table-header-content">{t("schedule.table_name6")}</div>

                                        </div>
                                        {
                                            listService.map((list, index) => {
                                                return (
                                                    <div className="table-body" key={index}>
                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{index + 1}</p></div>
                                                        <div style={{ flex: 3, justifyContent: "center" }} className="table-body-content"><p>{Moment(list.service_date).format("DD-MM-yyyy")}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{list.group_name}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{list.staff_name}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{list.status_name}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{list.service_reason}</p></div>

                                                        <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                            <div style={{ padding: 5 }}>
                                                                <Link to={`/ServiceJob/Detail?id=${list.id}`} target="_blank">
                                                                    <Button variant="primary" onClick={() => {

                                                                    }}><EyeFill />
                                                                    </Button>
                                                                </Link>
                                                            </div></div>


                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        paddingTop: 15
                                    }}>
                                        <Link to={`/Project/Detail?id=${scheduleToBeViewed.id}`} target="_blank">
                                            <Button className="cancel" variant="primary" onClick={() => {

                                            }}>
                                                {t("schedule.button_detail_proyek")}
                                            </Button>
                                        </Link>
                                        {/* </div> */}
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal>

                <Modal size={"xl"} show={showCrewScheduleDetailModal} onHide={() => {
                    setShowCrewScheduleDetailModal(false);
                }}>

                    <ContainerBox
                        containerPos="inner"
                        useActionContainer={true}
                        actionContainerChild={
                            <div id="button-color">
                                <Button variant="primary" onClick={() => {
                                    setShowCrewScheduleDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquare size={32} /></div>

                                    </div>
                                </Button>
                            </div>


                        }
                        titleCaption={`${t("schedule.modal_vessel_schedule")}`}
                        childContent={
                            <div style={{
                                display: "flex",
                                minWidth: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form style={{
                                    width: "100%"
                                }} onSubmit={(e) => {

                                }}>
                                    <div style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <TextBox disabledForm={true} value={crewScheduleToBeViewed.vessel_name} placeholder=""
                                                caption={`${t("schedule.field_vessel_name")}`} setValueFunction={(e) => {

                                                }} />
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <TextBox disabledForm={true} value={Moment(crewScheduleToBeViewed.start_date).format("DD/MM/yyyy")} placeholder=""
                                                caption={`${t("schedule.field_start_date")}`} setValueFunction={(e) => {

                                                }} />
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <TextBox disabledForm={true} value={Moment(crewScheduleToBeViewed.end_date).format("DD/MM/yyyy")} placeholder=""
                                                caption={`${t("schedule.field_end_date")}`} setValueFunction={(e) => {

                                                }} />
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("schedule.field_from")}</Form.Label>
                                            <Form.Control disabled={true} value={crewScheduleToBeViewed.departing_from}></Form.Control>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("schedule.field_to")}</Form.Label>
                                            <Form.Control disabled={true} value={crewScheduleToBeViewed.departure_destination}></Form.Control>
                                        </Form.Group>
                                    </div>
                                    <div className="table-container">
                                        <div className="table-header">
                                            <div style={{ flex: 1 }} className="table-header-content">#</div>
                                            <div style={{ flex: 3 }} className="table-header-content">{t("schedule.vessel_table_name1")}</div>
                                            <div style={{ flex: 3 }} className="table-header-content">{t("schedule.vessel_table_name2")}</div>
                                            <div style={{ flex: 3 }} className="table-header-content">{t("schedule.vessel_table_name3")}</div>
                                            <div style={{ flex: 2 }} className="table-header-content">{t("schedule.vessel_table_name4")}</div>
                                            <div style={{ flex: 2 }} className="table-header-content">{t("schedule.vessel_table_name5")}</div>
                                        </div>
                                        {
                                            crewScheduleToBeViewed.list_crew.map((crew, index) => {
                                                return (
                                                    <div className="table-body">
                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{index + 1}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{crew.staff_name}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{crew.position_name}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p></p></div>
                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{crew.start_date ? Moment(crew.start_date).format("DD-MM-yyyy") : ""}</p></div>
                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{crew.end_date ? Moment(crew.end_date).format("DD-MM-yyyy") : ""}</p></div>


                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{ paddingBottom: 20 }}></div>
                                    {/* <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Jabatan</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control disabled onChange={(e) => {
                                                }} value={crewScheduleToBeViewed.position_name} type="text" ></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div> */}
                                    {/* <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}>
                                        <Button className="cancel" variant="danger" onClick={() => {


                                            setShowCrewScheduleDetailModal(false);
                                        }}>
                                            Tutup
                                        </Button>
                                    </div> */}
                                </Form>
                            </div>
                        }
                    />

                </Modal>
                {/* <Loading
                    loading={loading}
                /> */}
            </Container>
        </>
    );

}