import "./BitLamp.css"

export default function BitLamp({ text, alarm = false, fontSize = 16, bitlampColor = "" }) {
    return (
        <div className="bitLamp">
            {
                bitlampColor === "" ?
                    <div className={alarm === false ? "bitLampOff" : "bitLampOn"}></div>
                    : <div className={alarm === false ? "bitlampBlack" : bitlampColor}></div>
            }

            <div className="textBitLamp" style={{ fontSize }}>{text}</div>
        </div>
    )
}