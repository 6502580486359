import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getSupplierById, insertUpdateSupplier, getSupplierClass, getSupplierType, getBank,
    getSupplierContact, getSupplierContactById, getSupplierContactCount, insertUpdateSupplierContact,
    deleteSupplierContact, getCompany, getTermoFPayment, getTitle, getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../Components/NavBar";
import ContainerBox from "../../../Components/ContainerBox";
import Loading from "../../../Components/Loading";
import TextBox from "../../../Components/Textbox";
import Select from "react-select"
import { ArrowLeft, Trash, PencilFill, ArrowClockwise, Search, FilePlusFill } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function SupplierDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [listCompany, setListCompany] = useState([]);
    const [listSupplierClass, setListSupplierClass] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [listSupplierType, setListSupplierType] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [listBank, setListBank] = useState([]);
    const [listSupplierContact, setListSupplierContact] = useState([]);
    const [supplierContactId, setSupplierContacId] = useState("");
    const [supplierContactModal, setSupplierContactModal] = useState("");
    const [selectedSupplierClass, setSelectedSupplierClass] = useState({ id: "" });
    const [selectedSupplierType, setSelectedSupplierType] = useState({ id: "" });
    const [selectedCompany, setSelectedCompany] = useState({ id: "" });
    const [selectedBank, setSelectedBank] = useState({ id: "" });
    const location = useLocation();
    const [listTermOfPayment, setListTermOfPayment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [supplierId, setSupplierId] = useState("");
    const [errors, setErrors] = useState({});
    const [page, setPage] = useState(0);
    const [removeId, setRemoveId] = useState("");
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [listTitle, setListTitle] = useState([]);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_supplier.order_name1")}`,
        value: "contact.contact_person"
    });
    const orderByList = [{
        label: `${t("detail_supplier.order_name1")}`,
        value: "contact.contact_person"
    }, {
        label: `${t("detail_supplier.order_name2")}`,
        value: "supplier.supplier_name"
    }];
    const [supplierContactCount, setSupplierContactCount] = useState(0);
    const [descending, setDescending] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [supplier, setSupplier] = useState({
        id: 0,
        supplier_code: "",
        supplier_name: "",
        supplier_type_id: null,
        supplier_class_id: null,
        city: "",
        bank_id: null,
        address_1: "",
        address_2: "",
        zip_code: "",
        bank_account_number: "",
        npwp: "",
        fg_ppn: "",
        nppkp: "",
        company_id: null,
        term_of_payment: null,
        ext_customer_id: cookies.extCustomerId
    });

    const [supplierContact, setSupplierContact] = useState({
        id: "",
        supplier_id: null,
        phone: "",
        fax: "",
        email: "",
        contact_person: "",
        contact_title: null,
        contact_mobile_phone: "",
        note: ""
    })
    useEffect(() => {
        if (supplierId !== 0 && supplierId !== "")
            loadSupplierContact();
    }, [page]);

    useEffect(() => {
        if (supplierId !== 0 && supplierId !== "" && isSearched !== 0)
            loadSupplierContact();
    }, [itemPerPage]);

    useEffect(() => {
        if (supplierId !== 0 && supplierId !== "" && isSearched !== 0)
            loadSupplierContact();
    }, [orderBy]);

    useEffect(() => {
        if (supplierId !== 0 && supplierId !== "") {
            // if (searchQuery === "")
            loadSupplierContact();
        }

    }, [searchQuery]);

    useEffect(() => {
        if (supplierId !== 0 && supplierId !== "" && isSearched !== 0)
            loadSupplierContact()
    }, [descending]);

    useEffect(() => {
        if (selectedCompany.company_name) {

            setSupplier({ ...supplier, company_id: selectedCompany.id });
            setCompanyAddress(selectedCompany.address)
        } else {
            setSupplier({ ...supplier, company_id: null });
            setCompanyAddress("")
        }
    }, [selectedCompany]);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadSupplierClass();
                loadSupplierType();
                loadBank();
                setSupplierId(location.state.supplierId);
                setLoading(false);
                loadCompany();
                loadTermOfPayment();
                loadTitle();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (supplierId !== 0 && supplierId !== "") {
            initSupplier();
            loadSupplierContact();
        }
    }, [supplierId])

    useEffect(() => {
        if (selectedSupplierClass.supplier_class_name) {
            setSupplier({ ...supplier, supplier_class_id: selectedSupplierClass.id });
        } else {
            setSupplier({ ...supplier, supplier_class_id: null });
        }
    }, [selectedSupplierClass]);

    useEffect(() => {
        if (selectedSupplierType.supplier_type_name) {
            setSupplier({ ...supplier, supplier_type_id: selectedSupplierType.id });
        } else {
            setSupplier({ ...supplier, supplier_type_id: null });
        }
    }, [selectedSupplierType]);

    useEffect(() => {
        if (selectedBank.bank_name) {
            setSupplier({ ...supplier, bank_id: selectedBank.id });
        } else {
            setSupplier({ ...supplier, bank_id: null });
        }
    }, [selectedBank]);

    useEffect(() => {
        if (location.state.supplierId !== 0) {
            if (supplier.supplier_class_id) {
                let classSupplier = listSupplierClass.find(p => p.id === supplier.supplier_class_id);
                if (classSupplier) {
                    setSelectedSupplierClass(classSupplier);
                }

            }
            if (supplier.supplier_type_id) {
                let typeSupplier = listSupplierType.find(p => p.id === supplier.supplier_type_id);
                if (typeSupplier) {
                    setSelectedSupplierType(typeSupplier);
                }

            }
            if (supplier.bank_id) {
                let bank = listBank.find(p => p.id === supplier.bank_id);
                if (bank) {
                    setSelectedBank(bank);
                }

            }

            if (supplier.company_id) {
                let response = listCompany.find(p => p.id === supplier.company_id);
                if (response) {
                    setSelectedCompany(response);
                }

            }
        }

    }, [supplier]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeSupplierContact();
    }, [removeId]);

    useEffect(() => {
        if (supplierContactId !== "" && supplierContactId !== undefined) {
            initSupplierContact();
        }

    }, [supplierContactId]);

    useEffect(() => {
        if (!supplierContactModal) {
            setSupplierContacId("")
        }
    }, [supplierContactModal]);




    const clearSupplierContactModal = async () => {
        let supplierContactTmp = {};
        supplierContactTmp.id = 0;
        supplierContactTmp.supplier_id = "";
        supplierContactTmp.phone = "";
        supplierContactTmp.fax = "";
        supplierContactTmp.email = "";
        supplierContactTmp.contact_person = "";
        supplierContactTmp.contact_title = null;
        supplierContactTmp.contact_mobile_phone = "";
        supplierContactTmp.note = "";
        setSupplierContact(supplierContactTmp);

    }



    const saveSupplier = async () => {
        try {
            let supplierTmp = supplier;
            supplierTmp.ext_customer_id = supplierTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : supplierTmp.ext_customer_id
            let response = await insertUpdateSupplier(cookies.token, supplierTmp);
            if (response.error_code === 0) {
                alert(`${t("detail_supplier.alert_success_save_data")}`);
                navigate("/Supplier")
            } else {
                alert(`${t("detail_supplier.alert_failed_save_data")}`)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadTitle = async () => {
        try {
            let response = await getTitle(cookies.token);
            setListTitle(response)
        } catch (exception) {
            console.log(exception)
        }
    }

    const initSupplier = async () => {
        try {
            let response = await getSupplierById(cookies.token, location.state.supplierId);
            if (response) {
                setSupplier({
                    ...supplier,
                    id: response.id,
                    supplier_code: response.supplier_code,
                    supplier_name: response.supplier_name,
                    supplier_type_id: response.supplier_type_id,
                    supplier_class_id: response.supplier_class_id,
                    city: response.city,
                    bank_id: response.bank_id,
                    address_1: response.address_1,
                    address_2: response.address_2,
                    zip_code: response.zip_code,
                    bank_account_number: response.bank_account_number,
                    npwp: response.npwp,
                    fg_ppn: response.fg_ppn,
                    nppkp: response.nppkp,
                    company_id: response.company_id,
                    term_of_payment: response.term_of_payment
                })
                setLoading(false);
                setOrderBy({
                    label: `${t("detail_supplier.order_name1")}`,
                    value: "contact.contact_person"
                })
            } else {
                alert(`${t("detail_supplier.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const loadSupplierClass = async () => {
        try {
            let response = await getSupplierClass(cookies.token);
            setListSupplierClass(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadSupplierType = async () => {
        try {
            let response = await getSupplierType(cookies.token, cookies.extCustomerId);
            setListSupplierType(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBank = async () => {
        try {
            let response = await getBank(cookies.token, cookies.languageId);
            setListBank(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadSupplierContact = async () => {
        let count = await getSupplierContactCount(cookies.token, supplierId, searchQuery, false);
        setSupplierContactCount(count);
        let totalPage = count / itemPerPage;
        setTotalPage(totalPage);


        let response = await getSupplierContact(cookies.token, supplierId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
        setListSupplierContact(response)
        setLoading(false);
        setIsSearched(0);
    }

    const initSupplierContact = async () => {
        try {
            let response = await getSupplierContactById(cookies.token, supplierContactId)
            if (response) {
                setSupplierContact({
                    ...supplierContact,
                    id: response.id,
                    supplier_id: response.supplier_id,
                    phone: response.phone,
                    fax: response.fax,
                    email: response.email,
                    contact_person: response.contact_person,
                    contact_title: response.contact_title,
                    contact_mobile_phone: response.contact_mobile_phone,
                    note: response.note
                });

                setSupplierContactModal(true);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeSupplierContact = async () => {
        try {
            let response = await deleteSupplierContact(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("detail_supplier.delete_success_alert")}`);
                loadSupplierContact();
            } else {
                alert(`${t("detail_supplier.delete_failed_alert")}`);
            }
            setRemoveId("");
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveSupplierContact = async () => {
        try {
            let response = await insertUpdateSupplierContact(cookies.token, supplierContact);
            if (response.error_code === 0) {
                alert(`${t("detail_supplier.alert_success_save_data")}`);
                setSupplierContactModal(false);
                clearSupplierContactModal();
                loadSupplierContact();
                setDisabledButton(false);
            } else {
                alert(`${t("detail_supplier.alert_failed_save_data")}`)
            }
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadCompany = async () => {
        try {
            let response = await getCompany(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListCompany(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadTermOfPayment = async () => {
        try {
            let response = await getTermoFPayment(cookies.token);
            setListTermOfPayment(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_supplier.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Supplier"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    borderWidth: 2,
                                    borderColor: "rgba(3, 30, 103, 1)",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                }}>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        setDisabledButton(true);
                                        setLoading(true);
                                        saveSupplier();

                                    }}>
                                        <Tabs
                                            defaultActiveKey="information"
                                            // transition={false}
                                            id="noanim-tab-example"
                                            className="mb-3"
                                            style={{
                                                backgroundColor: "rgba(3, 30, 103, 1)",
                                                borderRadius: 5,

                                            }}
                                        >
                                            <Tab eventKey="information" title={`${t("detail_supplier.tab_1")}`}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    padding: "0px 10px 10px 10px"
                                                }}>
                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_supplier.information_supplier_header")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                width: "100%"
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "row",
                                                                    flexWrap: "nowrap"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        flexDirection: "column",
                                                                        flexWrap: "nowrap",
                                                                        width: "100%",
                                                                        paddingRight: 10
                                                                    }}>
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_supplier_code")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setSupplier({ ...supplier, supplier_code: e.target.value })
                                                                            }} value={supplier.supplier_code} placeholder=""></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_supplier_name")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setSupplier({ ...supplier, supplier_name: e.target.value })
                                                                            }} value={supplier.supplier_name} placeholder="" required></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_class_supplier")}</Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.supplier_class_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listSupplierClass} value={selectedSupplierClass} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedSupplierClass({})
                                                                                    } else {
                                                                                        setSelectedSupplierClass(e);
                                                                                    }
                                                                                }}
                                                                                isClearable

                                                                            />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_supplier_type")}</Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.supplier_type_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listSupplierType} value={selectedSupplierType} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedSupplierType({})
                                                                                    } else {
                                                                                        setSelectedSupplierType(e);
                                                                                    }
                                                                                }}
                                                                                isClearable

                                                                            />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_supplier_city")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setSupplier({ ...supplier, city: e.target.value })
                                                                            }} value={supplier.city} placeholder="" required></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_bank_name")}</Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.bank_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listBank} value={selectedBank} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedBank({})
                                                                                    } else {
                                                                                        setSelectedBank(e);
                                                                                    }
                                                                                }}
                                                                                isClearable

                                                                            />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_bank_account_number")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                                                } else {
                                                                                    setSupplier({ ...supplier, bank_account_number: e.target.value })
                                                                                }


                                                                            }} value={supplier.bank_account_number} placeholder="" ></Form.Control>
                                                                        </Form.Group>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        flexDirection: "column",
                                                                        flexWrap: "nowrap",
                                                                        width: "100%",
                                                                        paddingLeft: 10
                                                                    }}>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_term_of_payment")}</Form.Label>
                                                                            <Form.Select onChange={(e) => {
                                                                                if (e.target.value === "") {
                                                                                    setSupplier({ ...supplier, term_of_payment: null })
                                                                                } else {
                                                                                    setSupplier({ ...supplier, term_of_payment: e.target.value })
                                                                                }
                                                                            }} value={supplier.term_of_payment}>
                                                                                <option value=""></option>
                                                                                {
                                                                                    listTermOfPayment.map((list, index) => {
                                                                                        return (
                                                                                            <option key={index} value={list.id}>{list.number_of_days}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Form.Select>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_postal_code")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                if (!new RegExp(/^[0-9]{0,6}$/).test(e.target.value)) {

                                                                                } else {
                                                                                    setSupplier({ ...supplier, zip_code: e.target.value })
                                                                                }

                                                                            }} value={supplier.zip_code} placeholder="" ></Form.Control>
                                                                        </Form.Group>


                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_address1")}</Form.Label>
                                                                            <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                                setSupplier({ ...supplier, address_1: e.target.value })
                                                                            }} value={supplier.address_1} placeholder="" ></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_address2")}</Form.Label>
                                                                            <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                                setSupplier({ ...supplier, address_2: e.target.value })
                                                                            }} value={supplier.address_2} placeholder=""></Form.Control>
                                                                        </Form.Group>






                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    }>
                                                    </ContainerBox>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="contact" title={`${t("detail_supplier.tab_2")}`} disabled={location.state.supplierId === 0 ? true : false}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    padding: "0px 10px 10px 10px"
                                                }}>
                                                    <ContainerBox containerPos="inner" titleCaption={`${t("detail_supplier.contact_supplier_header")}`}
                                                        useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                        actionContainerChild={
                                                            <div>
                                                                <Button disabled={!updatedButton} variant="secondary" style={{

                                                                }} onClick={() => {
                                                                    // navigate("/Country/Detail", { state: { countryId: 0 } }) 
                                                                    setSupplierContact({ ...supplierContact, id: 0, supplier_id: supplierId })
                                                                    setSupplierContactModal(true);
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FilePlusFill size={32} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 10,
                                                                            fontWeight: "bold",
                                                                            fontSize: 18,
                                                                        }}>{t("detail_supplier.add_button")}</div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        }
                                                        childContent={
                                                            <div style={{
                                                                padding: 10,
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    width: "100%"
                                                                }}>
                                                                    <div className="master-table-inner-container">
                                                                        <div className="master-table-searchbar-container">
                                                                            <div className="master-table-searchbar-textbox">
                                                                                <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_supplier.search_box")}`}
                                                                                    onKeyPress={async (e) => {
                                                                                        if (e.charCode === 13) {
                                                                                            setPage(0)
                                                                                            setLoading(true);
                                                                                            await loadSupplierContact();
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setPage(0)
                                                                                        setSearchQuery(e.target.value);
                                                                                    }} />
                                                                            </div>
                                                                            <div className="master-table-searchbar-button">
                                                                                <div style={{ paddingRight: 5 }}>
                                                                                    <Button onClick={async () => {
                                                                                        setPage(0)
                                                                                        setLoading(true);
                                                                                        await loadSupplierContact();
                                                                                    }}><Search /></Button>
                                                                                </div>
                                                                                <div style={{ paddingLeft: 5 }}>
                                                                                    <Button onClick={async () => {
                                                                                        setLoading(true);
                                                                                        setSearchQuery("");
                                                                                        await loadSupplierContact();
                                                                                    }}><ArrowClockwise /></Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_supplier.table_name1")}</div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_supplier.table_name2")}</div>
                                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_supplier.table_name3")}</div>
                                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_supplier.table_name4")}</div>
                                                                            </div>
                                                                            {
                                                                                listSupplierContact.map((contacts, index) => {
                                                                                    return (<div key={index} className="table-body">
                                                                                        <div style={{ flex: 0.5, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{contacts.supplier_name}</p></div>
                                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{contacts.contact_person}</p></div>
                                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                            <div style={{ padding: 5 }}>
                                                                                                <Button variant="primary" onClick={() => {
                                                                                                    let idContact = contacts.id;
                                                                                                    setSupplierContacId(idContact);
                                                                                                }}><PencilFill />
                                                                                                </Button>
                                                                                            </div></div>
                                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                            <div style={{ padding: 5 }}>
                                                                                                <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                                                                    // navigate("/Country/Detail", { state: { countryId: country.id } });
                                                                                                    let idContact = contacts.id;
                                                                                                    if (window.confirm(`${t("detail_supplier.delete_confirmation")} ${contacts.contact_person}`))
                                                                                                        setRemoveId(idContact);
                                                                                                }}><Trash />
                                                                                                </Button>
                                                                                            </div></div>


                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div style={{
                                                                            paddingTop: 20,
                                                                        }}>

                                                                        </div>
                                                                        <Paginations
                                                                            itemPerPage={itemPerPage}
                                                                            totalPage={totalPage}
                                                                            page={page}
                                                                            setPage={setPage}
                                                                            setItemPerPage={setItemPerPage}
                                                                            itemPerPageSelection={itemPerPageSelection}
                                                                            orderBy={orderBy}
                                                                            setOrderBy={setOrderBy}
                                                                            orderBySelection={orderByList}
                                                                            isDescActive={descending}
                                                                            setIsDescActive={setDescending}
                                                                            setIsSearched={setIsSearched}
                                                                        />
                                                                        <Loading
                                                                            loading={loading}
                                                                        />
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        }>
                                                    </ContainerBox>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="company" title={`${t("detail_supplier.tab_3")}`} disabled={location.state.supplierId === 0 ? true : false}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    padding: "0px 10px 10px 10px"
                                                }}>
                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_supplier.header_company_information")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                width: "100%"
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "row",
                                                                    flexWrap: "nowrap"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        flexDirection: "column",
                                                                        flexWrap: "nowrap",
                                                                        width: "100%",
                                                                        paddingRight: 10
                                                                    }}>


                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_company_name")}</Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.company_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listCompany} value={selectedCompany} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedCompany({})
                                                                                    } else {
                                                                                        setSelectedCompany(e);
                                                                                    }
                                                                                }}
                                                                                isClearable
                                                                                required
                                                                            />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_company_address")}</Form.Label>
                                                                            <Form.Control as="textarea" rows={3} onChange={(e) => {

                                                                            }} value={companyAddress} disabled={true} placeholder=""></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_npwp")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                                                } else {
                                                                                    setSupplier({ ...supplier, npwp: e.target.value })
                                                                                }

                                                                            }} value={supplier.npwp} placeholder="" ></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_fg_ppn")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setSupplier({ ...supplier, fg_ppn: e.target.value })
                                                                            }} value={supplier.fg_ppn} placeholder="" ></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_supplier.field_nppkp")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setSupplier({ ...supplier, nppkp: e.target.value })
                                                                            }} value={supplier.nppkp} placeholder="" ></Form.Control>
                                                                        </Form.Group>


                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    }>
                                                    </ContainerBox>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                        {
                                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                <></>
                                                :
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    padding: 5
                                                }}>

                                                    <Button style={{width:100}} disabled={!updatedButton} type="submit" variant="primary" >{t("detail_supplier.save_button")}</Button>

                                                    <Button style={{width:100}} className="cancel" type="reset" variant="danger" >{t("detail_supplier.cancel_button")}</Button>

                                                </div>

                                        }



                                    </Form>

                                </div>




                            </div>


                        </div >

                    }>

                </ContainerBox >

                <Modal size="xl" show={supplierContactModal} onHide={
                    () => {
                        setSupplierContactModal(false);
                        clearSupplierContactModal();
                    }
                }>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("detail_supplier.modal_add_supplier_header")}`}
                        childContent={
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                padding: 10,
                                flex: 1,
                                flexWrap: "nowrap"
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveSupplierContact();

                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingRight: 5,
                                                flex: 3,
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_supplier.field_title")}</Form.Label>
                                                <Form.Select onChange={(e) => {
                                                    if (e.target.value === "") {
                                                        setSupplierContact({ ...supplierContact, contact_title: null })
                                                    } else {
                                                        setSupplierContact({ ...supplierContact, contact_title: e.target.value });
                                                    }
                                                }} value={supplierContact.contact_title} required>
                                                    <option value=""></option>
                                                    {
                                                        listTitle.map((list, index) => {
                                                            return (
                                                                <option key={index} value={list.id}>{list.title_name}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                                {/* <Form.Control type="text" onChange={(e) => {
                                                    setSupplierContact({ ...supplierContact, contact_title: e.target.value });
                                                }} value={supplierContact.contact_title} placeholder="" required></Form.Control> */}
                                            </Form.Group>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5,
                                                flex: 10
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_supplier.field_contact_name")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    setSupplierContact({ ...supplierContact, contact_person: e.target.value });
                                                }} value={supplierContact.contact_person} placeholder="" required></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingRight: 5
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_supplier.field_phone_number1")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                    } else {
                                                        setSupplierContact({ ...supplierContact, phone: e.target.value });
                                                    }

                                                }} value={supplierContact.phone} placeholder=""></Form.Control>
                                            </Form.Group>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5,
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_supplier.field_phone_number2")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                    } else {
                                                        setSupplierContact({ ...supplierContact, contact_mobile_phone: e.target.value });
                                                    }
                                                }} value={supplierContact.contact_mobile_phone} placeholder="" ></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingRight: 5,
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_supplier.field_fax")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                    } else {
                                                        setSupplierContact({ ...supplierContact, fax: e.target.value });
                                                    }

                                                }} value={supplierContact.fax} placeholder=""></Form.Control>
                                            </Form.Group>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_supplier.field_email")}</Form.Label>
                                                <Form.Control type="email" onChange={(e) => {
                                                    setSupplierContact({ ...supplierContact, email: e.target.value });
                                                }} value={supplierContact.email} placeholder=""></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_supplier.field_note")}</Form.Label>
                                                <Form.Control as={"textarea"} rows={3} onChange={(e) => {
                                                    setSupplierContact({ ...supplierContact, note: e.target.value });
                                                }} value={supplierContact.note} placeholder=""></Form.Control>
                                            </Form.Group>
                                        </div>

                                        {
                                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                <></>
                                                :
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5,
                                                    justifyContent: "center"
                                                }}>
                                                    <Button style={{ width: 100 }} disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit" variant="primary">{t("detail_supplier.save_button")}</Button>
                                                    <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                                        clearSupplierContactModal();
                                                        setSupplierContactModal(false);
                                                    }}>{t("detail_supplier.cancel_button")}</Button>
                                                </div>
                                        }


                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal>

            </Container >
            <Loading
                loading={loading}
            />
        </>
    )
}