import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import { getGeneralOverhaul, isTokenValid, getGeneralOverhaulCount, deleteGeneralOverhaul, getUserAccessById, getGeneralOverhaulReport } from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import Navbar from "../../Components/NavBar";
import Loading from "../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash, TabletFill, PrinterFill } from "react-bootstrap-icons";
import Paginations from "../../Components/Pagination";
import ContainerBox from "../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

export default function GeneralOverhaulIndexPage() {
  const [listGeneralOverhaul, setListGeneralOverhaul] = useState([]);
  const [cookies, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [updatedButton, setUpdatedButton] = useState("");
  const [deletedButton, setDeletedButton] = useState("");
  const [documentGeneralOverhaulId, setDocumentGeneralOvehaulId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [isSearched, setIsSearched] = useState(0);
  const [resetSearch, setResetSearch] = useState(0);
  const [disabledPrint, setDisabledPrint] = useState(false);
  const [page, setPage] = useState(0);
  const [gohDetail, setGohDetail] = useState({});
  const [docxData, setDocxData] = useState("");
  const [itemPerPage, setItemPerPage] = useState(50);
  const [removeId, setRemoveId] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
  const { t, i18n } = useTranslation();
  const [orderBy, setOrderBy] = useState({
    label: `${t("index_general_overhaul.order_name1")}`,
    value: "project.project_name"
  });
  const orderByList = [{
    label: `${t("index_general_overhaul.order_name1")}`,
    value: "project.project_name"
  }];
  const [countryCount, setCountryCount] = useState(0);
  const [descending, setDescending] = useState(false);




  useEffect(() => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token);
      if (!isAuthenticated)
        navigate("/");
      else {
        loadGeneralOverhaul();
        loadAccess();
        if (cookies.language_code === "id-id") {
          i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
          i18n.changeLanguage("en");
        }

      }
    }
    checkCookies();
  }, []);

  useEffect(() => {
    // if (isSearched !== 0)
    loadGeneralOverhaul();
  }, [page]);

  useEffect(() => {
    if (isSearched !== 0)
      loadGeneralOverhaul();
  }, [itemPerPage]);

  useEffect(() => {
    if (isSearched !== 0)
      loadGeneralOverhaul();
  }, [orderBy]);


  useEffect(() => {
    if (docxData.size > 0) {
      // console.log(docxData.size);
      downloadDocxFile();
    }
  }, [docxData]);


  // useEffect(() => {
  //   if (searchQuery === "")
  //     loadGeneralOverhaul();
  // }, [searchQuery]);

  useEffect(() => {
    if (resetSearch !== 0) {
      loadGeneralOverhaul()
    }
  }, [resetSearch])

  useEffect(() => {
    if (isSearched !== 0)
      loadGeneralOverhaul()
  }, [descending]);

  useEffect(() => {
    if (removeId !== "")
      removeGeneralOverhaul();
  }, [removeId])

  useEffect(()=>{
    if(documentGeneralOverhaulId !== ""){
      createDocument();
    }
  },[documentGeneralOverhaulId])


  const loadGeneralOverhaul = async () => {
    try {
      let countryCount = await getGeneralOverhaulCount(cookies.token, searchQuery, false);
      let totalPage = countryCount / itemPerPage;
      setTotalPage(totalPage);


      let listGeneralOverhaul = await getGeneralOverhaul(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
      console.log(listGeneralOverhaul);
      setListGeneralOverhaul(listGeneralOverhaul)
      setLoading(false);
      setResetSearch(0);
      setIsSearched(0);
    } catch (exception) {
      console.log(exception)
    }
  }

  const removeGeneralOverhaul = async () => {
    try {
      let response = await deleteGeneralOverhaul(cookies.token, removeId);
      if (response === 0) {
        alert(`${t("index_general_overhaul.delete_success_alert")}`);
        loadGeneralOverhaul();
      }
      setRemoveId("");
    } catch (exception) {
      console.log(exception)
    }
  }

  const loadAccess = async () => {
    try {
      // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
      let response = await getUserAccessById(cookies.token, window.location.pathname, cookies.languageId);
      if (response) {
        setDeletedButton(response.deleted);
        setUpdatedButton(response.updated)
      }
      setUpdatedButton(true);
      setDeletedButton(true);
      setOrderBy({
        label: `${t("index_general_overhaul.order_name1")}`,
        value: "project.project_name"
      })
    } catch (exception) {
      console.log(exception);
    }
  }

  const createDocument = async () => {
    try {
      let docx = await getGeneralOverhaulReport(cookies.token, documentGeneralOverhaulId, projectId)
      // console.log(docx);
      if (docx.size) {
        setDocxData(docx);
      } else {
        alert(`${t("detail_daily_report.error_download")}`);
        setLoading(false);
        setDisabledPrint(false);
      }
      // setServiceJobId("")
    } catch (exception) {
      console.log(exception)
    }
  }

  const downloadDocxFile = async () => {
    console.log(gohDetail);
    const element = document.createElement("a");
    const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    element.href = URL.createObjectURL(file);
    element.download = `${gohDetail.overhauls.vessel_name}-GOHReport.docx`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    // console.log(file);
    // console.log(file.length);

    URL.revokeObjectURL(file);
    setLoading(false);

    setDisabledPrint(false);
    setGohDetail({})
    setDocumentGeneralOvehaulId("");
    setProjectId("");
  }



  return (
    <>
      <Navbar />
      <Container fluid style={{
        display: "flex",
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
      }}>
        <ContainerBox
          titleCaption={`${t("index_general_overhaul.header_title")}`}
          useActionContainer={true}
          actionContainerChild={
            <div>
              <Button disabled={!updatedButton} variant="secondary" style={{

              }} onClick={() => { navigate("/GeneralOverhaul/Detail", { state: { generalOverhaulId: 0 } }) }}>
                <div style={{
                  display: "flex",
                  flex: 1,
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignSelf: "center",
                }}>
                  <div style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    flex: 1,
                  }}><FilePlusFill size={32} /></div>
                  <div style={{
                    display: "flex",
                    flex: 8,
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    paddingLeft: 10,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}>{t("index_general_overhaul.add_button")}</div>
                </div>
              </Button>
            </div>
          }
          childContent={

            <div className="master-table-inner-container">

              <div className="master-table-searchbar-container">
                <div className="master-table-searchbar-textbox">
                  <Form.Control value={searchQuery} type="text" placeholder={`${t("index_general_overhaul.search_box")}`}
                    onKeyPress={async (e) => {
                      if (e.charCode === 13) {
                        setPage(0);
                        setLoading(true);
                        await loadGeneralOverhaul();
                      }
                    }}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }} />
                </div>
                <div className="master-table-searchbar-button">
                  <div style={{ paddingRight: 5 }}>
                    <Button onClick={async () => {
                      setLoading(true);
                      setPage(0);
                      await loadGeneralOverhaul();
                    }}><Search /></Button>
                  </div>
                  <div style={{ paddingLeft: 5 }}>
                    <Button onClick={async () => {
                      setLoading(true);
                      setSearchQuery("");
                      setResetSearch(1);
                      // await loadGeneralOverhaul();
                    }}><ArrowClockwise /></Button>
                  </div>
                </div>
              </div>
              {/* <button onClick={() => changeLanguage("en")}>EN</button>
              <button onClick={() => changeLanguage("id")}>ID</button>
              <Trans i18nKey="overhauls.part1">
                Daftar Negara
              </Trans> */}
              <div className="table-container">
                <div className="table-header">
                  <div style={{ flex: 1 }} className="table-header-content">#</div>
                  <div style={{ flex: 4 }} className="table-header-content">{t("index_general_overhaul.table_name1")}</div>
                  <div style={{ flex: 4 }} className="table-header-content">{t("index_general_overhaul.table_name2")}</div>
                  <div style={{ flex: 4 }} className="table-header-content">{t("index_general_overhaul.table_name3")}</div>
                  <div style={{ flex: 4 }} className="table-header-content">{t("index_general_overhaul.table_name4")}</div>
                  <div style={{ flex: 1 }} className="table-header-content">{t("index_general_overhaul.table_name5")}</div>
                  <div style={{ flex: 1 , textAlign:"center"  }} className="table-header-content">{t("index_general_overhaul.table_name7")}</div>
                  <div style={{ flex: 1}} className="table-header-content">{t("index_general_overhaul.table_name8")}</div>
                  <div style={{ flex: 1 }} className="table-header-content">{t("index_general_overhaul.table_name6")}</div>
                </div>
                {
                  listGeneralOverhaul.map((overhauls, index) => {
                    return (<div className="table-body" key={index}>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                      <div style={{ flex: 4 }} className="table-body-content"><p>{overhauls.project_name}</p></div>
                      <div style={{ flex: 4 }} className="table-body-content"><p>{overhauls.customer_name}</p></div>
                      <div style={{ flex: 4 }} className="table-body-content"><p>{overhauls.vessel_name}</p></div>
                      <div style={{ flex: 4 }} className="table-body-content"><p>{overhauls.start_date ? moment(overhauls.start_date).format("DD-MMMM-yyyy") : ""}</p></div>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                        <div style={{ padding: 5 }}>
                          <Button variant="primary" onClick={() => {
                            navigate("/GeneralOverhaul/Detail", { state: { generalOverhaulId: overhauls.id } });
                          }}><PencilFill />
                          </Button>
                        </div></div>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                        <div style={{ padding: 5 }}>
                          <Button disabled={overhauls.project_type_code !== "GOH"} variant="primary" onClick={() => {
                            navigate("/GeneralOverhaul/TableData", { state: { generalOverhaulId: overhauls.id, numberOfCylinder: overhauls.number_of_cylinder } });
                          }}><EyeFill />
                          </Button>
                        </div></div>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                        <div style={{ padding: 5 }}>
                          <Button variant="primary" onClick={() => {
                            setGohDetail({overhauls});
                            setProjectId(overhauls.project_id);
                            setDocumentGeneralOvehaulId(overhauls.id);
                            setLoading(true);
                          }}><PrinterFill />
                          </Button>
                        </div></div>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                        <div style={{ padding: 5 }}>
                          <Button disabled={!deletedButton} variant="danger" onClick={() => {
                            if (window.confirm(`${t("index_general_overhaul.delete_confirmation")} ${overhauls.project_name}`))
                              setRemoveId(overhauls.id)
                          }}><Trash />
                          </Button>
                        </div></div>


                    </div>)
                  }
                  )
                }
              </div>
              <div style={{
                paddingTop: 20,
              }}>

              </div>
              <Paginations
                itemPerPage={itemPerPage}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
                setItemPerPage={setItemPerPage}
                itemPerPageSelection={itemPerPageSelection}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                orderBySelection={orderByList}
                isDescActive={descending}
                setIsDescActive={setDescending}
                setIsSearched={setIsSearched}
              />
              <Loading
                loading={loading}
              />
            </div>
          } />
      </Container>
    </>
  );

}