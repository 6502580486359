import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../Config/Config";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import {
    getVesselClassificationCharacter,
    getVesselClassificationCharacterCount, deleteCharacter
} from "../../../../Helpers/VesselClassification.helpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import { Trash, PencilFill, Search, FilePlusFill, ArrowClockwise } from "react-bootstrap-icons";
import Loading from "../../../../Components/Loading";
import ContainerBox from "../../../../Components/ContainerBox";
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function VesselTypePage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [listCharacter, setListCharacter] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [itemPerPage, setItemPerPage] = useState(50);
    const [isSearched, setIsSearched] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_class_character.order_name1")}`,
        value: "created_date"
    });
    const orderByList = [{
        label: `${t("index_class_character.order_name1")}`,
        value: "created_date"
    },
    {
        label: `${t("index_class_character.order_name2")}`,
        value: "society.society_name"
    },
    {
        label: `${t("index_class_character.order_name3")}`,
        value: "application.application_name"
    }];
    const [descending, setDescending] = useState(true);
    const [characterCount, setCharacterCount] = useState(0);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadCharacter();
                setLoading(false)
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadCharacter();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCharacter();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCharacter();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadCharacter();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCharacter()
    }, [descending]);

    const loadCharacter = async () => {
        let characterCount = await getVesselClassificationCharacterCount(cookies.token, cookies.languageId, searchQuery, false);
        setCharacterCount(characterCount);
        let totalPage = characterCount / itemPerPage;
        setTotalPage(totalPage);

        let listCharacter = await getVesselClassificationCharacter(cookies.token, cookies.languageId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
        setListCharacter(listCharacter);
        setLoading(false);
        setResetSearch(0);
        setIsSearched(0);
    }

    const removeCharacter = async (characterId) => {
        try {
            // let text;
            if (window.confirm(`${t("index_class_character.delete_confirmation")}`) == true) {
                let response = await deleteCharacter(cookies.token, characterId);
                if (response.success) {
                    if (response.error_code === 0) {
                        alert(`${t("index_class_character.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("index_class_character.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("index_class_character.delete_failed_alert")}`);
                }
                loadCharacter();
            }

        } catch (exception) {
            // alert("Terjadi kesalahan sistem, mohon hubungi Administrator.");
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_class_character.order_name1")}`,
                value: "created_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_class_character.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/VesselClassification/Character/Detail", { state: { characterId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_class_character.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_class_character.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadCharacter();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadCharacter();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadCharacter();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            {/* <Table striped bordered hover>
                                <thead>
                                    <tr className="table-header-caption">
                                        <th>#</th>
                                        <th>Nama Kelas</th>
                                        <th>Aplikasi</th>
                                        <th>Simbol Karakter</th>
                                        <th>Deskripsi</th>
                                        <th>Edit</th>
                                        <th>Hapus</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        listCharacter.length === 0 ?
                                            <tr>
                                                <td colSpan={7} style={{ textAlign: "center" }}>Tidak Ada Hasil Pencarian Untuk {searchQuery}</td>
                                            </tr> :
                                            <></>
                                    }
                                    {
                                        listCharacter.map((character, index) => {
                                            return (
                                                <tr key={index} className="table-body-caption">
                                                    <td>{index + 1}</td>
                                                    <td>{character.society_name}</td>
                                                    <td>{character.application_name}</td>
                                                    <td><img className="character-symbol-thumb-img" src={character.character_symbol_image} /></td>
                                                    <td>{character.character_desc}</td>
                                                    <td>
                                                        <div className="table-action-button">
                                                            <div style={{ padding: 5 }}>
                                                                <Button variant="primary" onClick={() => {
                                                                    navigate("/VesselClassification/Character/Detail", { state: { characterId: character.id } });
                                                                }}><PencilFill />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-action-button">
                                                            <div style={{ padding: 5, flexDirection: "column" }}>
                                                                <Button variant="danger"
                                                                    onClick={event => {
                                                                        removeCharacter(character.id);
                                                                    }}
                                                                ><Trash /></Button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table> */}
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_class_character.table_name1")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_class_character.table_name2")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_class_character.table_name3")}</div>
                                    <div style={{ flex: 6 }} className="table-header-content">{t("index_class_character.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_class_character.table_name5")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_class_character.table_name6")}</div>
                                </div>
                                {
                                    listCharacter.map((character, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{character.society_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{character.application_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p><img className="character-symbol-thumb-img" src={character.character_symbol_image} /></p></div>
                                            <div style={{ flex: 6 }} className="table-body-content"><p>{character.character_desc}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/VesselClassification/Character/Detail", { state: { characterId: character.id } });
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={event => {
                                                        removeCharacter(character.id);
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>
        </>
    )

}