import "./index.css";
import "../../App.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import { useCookies } from "react-cookie";
import BitLamp from "../../Components/BitLamp/BitLamp";
import BitLamp3 from "../../Components/BitLamp3/BitLamp3";
import { Button } from "react-bootstrap";
import BoxSwitchToggle from "../../Components/BoxSwitchToggle/BoxSwitchToggle";
import React, { useEffect, useState } from "react"
import { getPLCCoordinate, isTokenValid, getPlcValue } from "../../Helpers/ApplicationHelpers";
import Loading from "../../Components/Loading";

export default function NavSignalLightPanel() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [bitlampList, setBitlampList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDataLoaded,setIsDataLoaded] = useState(false);
    // const [bitlampList, setBitlampList] = useState([
    //     {
    //         text: "ANCHOR",
    //         alarm: true,
    //         coordinate: "M118"
    //     }, {
    //         text: "NUC 1",
    //         alarm: false,
    //         coordinate: "M106"
    //     }, {
    //         text: "NUC 2",
    //         alarm: true,
    //         coordinate: "M108"
    //     }, {
    //         text: "NUC 3",
    //         alarm: true,
    //         coordinate: "M110"
    //     }, {
    //         text: "CUSTOM 1",
    //         alarm: true,
    //         coordinate: "M112"
    //     }, {
    //         text: "CUSTOM 2",
    //         alarm: true,
    //         coordinate: "M114"
    //     },
    //     {
    //         text: "PORT",
    //         alarm: true,
    //         coordinate: "M102"
    //     },
    //     {
    //         text: "MAST 1",
    //         alarm: true,
    //         coordinate: "M96"
    //     },
    //     {
    //         text: "MAST 2",
    //         alarm: true,
    //         coordinate: "M98"
    //     },
    //     {
    //         text: "MAST 3",
    //         alarm: true,
    //         coordinate: "M100"
    //     },
    //     {
    //         text: "STBD",
    //         alarm: true,
    //         coordinate: "M104"
    //     },
    //     {
    //         text: "TOWING",
    //         alarm: true,
    //         coordinate: "M120"
    //     },
    //     {
    //         text: "STERN",
    //         alarm: true,
    //         coordinate: "M116"
    //     }])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (bitlampList.length > 0) {
    //         const interval = setInterval(async () => {
    //             // console.log('tes')
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval);
    //     }


    // }, [bitlampList]);
    useEffect(() => {
        if (bitlampList.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadBitlampValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [bitlampList, isDataLoaded]);

    const loadBitlampValue = async () => {
        try {
            setIsDataLoaded(true);
            let coordinate = bitlampList.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = bitlampList.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setBitlampList(updatedList);
            setLoading(false);
            setIsDataLoaded(false);
            // for (let index = 0; index < bitlampList.length; index++) {
            //     const list = bitlampList[index];
            //     let alarmValue = response.find(p => list.plc_coordinate === p.plc_coordinate)
            //     if(alarmValue){
            //         if(alarmValue.value === 1){
            //             list.alarm = true
            //         }else{
            //             list.alarm = false
            //         }
            //     }else{
            //         list.alarm = !list.alarm
            //     }
            // }
            // const correspondingItem = array2.find(item2 => item1.id === item2.id);
            // if (correspondingItem) {
            //     // Update value in array1 from corresponding element in array2
            //     item1.value = correspondingItem.value;
            // }
            // let valueBitlamp = bitlampList;
            // valueBitlamp[0].alarm = !bitlampList[0].alarm;
            // valueBitlamp[1].alarm = !bitlampList[1].alarm;
            // valueBitlamp[2].alarm = !bitlampList[2].alarm;
            // valueBitlamp[3].alarm = !bitlampList[3].alarm;
            // valueBitlamp[4].alarm = !bitlampList[4].alarm;
            // valueBitlamp[5].alarm = !bitlampList[5].alarm;
            // valueBitlamp[6].alarm = !bitlampList[6].alarm;
            // valueBitlamp[7].alarm = !bitlampList[7].alarm;
            // valueBitlamp[8].alarm = !bitlampList[8].alarm;
            // valueBitlamp[9].alarm = !bitlampList[9].alarm;
            // valueBitlamp[10].alarm = !bitlampList[10].alarm;
            // valueBitlamp[11].alarm = !bitlampList[11].alarm;
            // valueBitlamp[12].alarm = !bitlampList[12].alarm;
            // setBitlampList(valueBitlamp.slice(0))
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "navigation_light", cookies.vesselId);
            setBitlampList(response);
            if (response.length < 1) {
                setLoading(false);
            }
        } catch (exception) {

        }
    }


    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"NAV & SIGNAL LIGHT PANEL"} /></div>
            <div className="contentNavSignal" >
                <div style={{
                    display: "flex",
                    flex: "1",
                    width: "100%",
                    // flexWrap:"nowrap",
                }}>

                    <div style={{
                        display: "flex",
                        flex: 1,
                        // padding: ,
                        justifyContent: "center"
                    }}>
                        <div className="imgShipShape">
                            {
                                bitlampList.length > 0 &&
                                <>
                                    <div className="bitlampAnchor">
                                        <div style={{ fontSize: 14, marginLeft: -15 }}>{bitlampList[0].coordinate_desc}</div>
                                        <BitLamp style={{ backgroundColor: "blue !important" }} text={""} alarm={bitlampList[0].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                    <div className="bitlampNuc1">
                                        <div style={{ fontSize: 14, marginLeft: -5 }}>{bitlampList[8].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[8].alarm} fontSize={14} bitlampColor="bitlampRed" />
                                    </div>
                                    <div className="bitlampCustom1">
                                        <div style={{ fontSize: 14, marginLeft: -15 }}>{bitlampList[11].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[11].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                    <div className="bitlampCustom2">
                                        <div style={{ fontSize: 14, marginLeft: -15 }}>{bitlampList[12].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[12].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                    <div className="bitlampNuc2">
                                        <div style={{ fontSize: 14, marginLeft: -5 }}>{bitlampList[9].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[9].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                    <div className="bitlampNuc3">
                                        <div style={{ fontSize: 14, marginLeft: -5 }}>{bitlampList[10].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[10].alarm} fontSize={14} bitlampColor="bitlampRed" />
                                    </div>
                                    <div className="bitlampPort">
                                        <div style={{ fontSize: 14, marginLeft: -5 }}>{bitlampList[1].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[1].alarm} fontSize={14} bitlampColor="bitlampRed" />
                                    </div>
                                    <div className="bitlampMast1">
                                        <div style={{ fontSize: 14, marginLeft: -10 }}>{bitlampList[5].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[5].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                    <div className="bitlampStbd">
                                        <div style={{ fontSize: 14, marginLeft: -1 }}>{bitlampList[2].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[2].alarm} fontSize={14} bitlampColor="bitlampGreen" />
                                    </div>
                                    <div className="bitlampMast2">
                                        <div style={{ fontSize: 14, marginLeft: -10 }}>{bitlampList[6].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[6].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                    <div className="bitlampMast3">
                                        <div style={{ fontSize: 14, marginLeft: -10 }}>{bitlampList[7].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[7].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                    <div className="bitlampTowing">
                                        <div style={{ fontSize: 14, marginLeft: -15 }}>{bitlampList[4].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[4].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                    <div className="bitlampStern">
                                        <div style={{ fontSize: 14, marginLeft: -10 }}>{bitlampList[3].coordinate_desc}</div>
                                        <BitLamp text={""} alarm={bitlampList[3].alarm} fontSize={14} bitlampColor="bitlampYellow" />
                                    </div>
                                </>
                            }
                        </div>
                        {/* <p>Tes</p> */}
                    </div>



                </div>
            </div>
            <Loading
                loading={loading}
            />
            <div style={{ paddingBottom: 120 }}></div>
            <div><BottomNavBar /></div>
        </>
    );
}