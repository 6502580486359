import "./index.css";
import "../../../SMSApp.css";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import WidthButton from "../../../Components/WidthButton/WidthButton";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import BitLamp2 from "../../../Components/BitLamp2/BitLamp2";
import { useCookies } from "react-cookie";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useState, useEffect } from "react";
import { getPLCCoordinate, isTokenValid, getPlcValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";

export default function BelowMainDeck() {
    const navigate = useNavigate();
    const navigateWheelHouse = () => { navigate("/FireAlarm/WheelHouse") };
    const navigateMainDeck = () => { navigate("/FireAlarm/MainDeck") };
    const [belowMainDeckBitlamp, setBelowMainDeckBitlamp] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [isDataLoaded,setIsDataLoaded] = useState(false);
    // const [belowMainDeckBitlamp, setBelowMainDeckBitlamp] = useState([
    //     {text: "Manual Call Point Engine Room", alarm: false, coordinate:"M294"},
    //     {text: "Engine Room 4 Heat S", alarm: true, coordinate:"M292"},
    //     {text: "Engine Room 2 Heat P", alarm: false, coordinate:"M288"},
    //     {text: "Crew Room", alarm: false, coordinate:"M40"},
    //     {text: "CO2 Room", alarm: true, coordinate:"M270"},
    //     {text: "Crew Room 1", alarm: true, coordinate:"M282"},
    //     {text: "Crew Room 2", alarm: true, coordinate:"M284"},
    //     {text: "Engine Room 1 Smoke P", alarm: false, coordinate:"M286"},
    //     {text: "Engine Room 3 Smoke S", alarm: true, coordinate:"M290"},
    // ])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (belowMainDeckBitlamp.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [belowMainDeckBitlamp]);

    useEffect(() => {
        if (belowMainDeckBitlamp.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadBitlampValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [belowMainDeckBitlamp, isDataLoaded]);

    const loadBitlampValue = async () => {
        try {
            setIsDataLoaded(true);
            let coordinate = belowMainDeckBitlamp.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = belowMainDeckBitlamp.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setBelowMainDeckBitlamp(updatedList);

            setLoading(false);
            setIsDataLoaded(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "fire_alarm", cookies.vesselId);
            let newLisst = response.filter(p => p.sub_coordinate_group === "below_main_deck")
            setBelowMainDeckBitlamp(newLisst);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }

    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"FIRE ALARM > BELOW MAIN DECK"} /></div>
            <div className="contentBelowMainDeck">
                <div className="topBtn">
                    <WidthButton textBtn={"GO TO WHEEL HOUSE"} handleOnClick={navigateWheelHouse} />
                    <WidthButton textBtn={"GO TO MAIN DECK"} handleOnClick={navigateMainDeck} />
                </div>
                <div className="containerImgBMD">
                    <div className="imgBelowMainDeck">
                        {/* <div className="txtEngRoom">Engine Room</div> */}
                        {/* <div className="lampInImg1">
                            <BitLamp2 text={""} alarm={false} gapText={0} />
                        </div>
                        <div className="lampInImg2">
                            <BitLamp2 text={""} alarm={true} gapText={0} />
                        </div>
                        <div className="lampInImg3">
                            <BitLamp2 text={""} alarm={true} gapText={0} />
                        </div>
                        <div className="lampInImg4">
                            <BitLamp2 text={""} alarm={false} gapText={0} />
                        </div>
                        <div className="lampInImg5">
                            <BitLamp2 text={"CREW ROOM"} alarm={false} gapText={5} />
                        </div> */}
                    </div>
                </div>
                <div className="containerBitLampsMD">
                    <div className="boxBitLampBMD">
                        {/* <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            gap: 120,
                            justifyContent: "center"
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 30
                            }}>
                                <BitLamp text={belowMainDeckBitlamp[0].text} alarm={belowMainDeckBitlamp[0].alarm} />
                                <BitLamp text={belowMainDeckBitlamp[1].text} alarm={belowMainDeckBitlamp[1].alarm} />
                                <BitLamp text={belowMainDeckBitlamp[2].text} alarm={belowMainDeckBitlamp[2].alarm} />
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 30
                            }}>
                                <BitLamp text={belowMainDeckBitlamp[3].text} alarm={belowMainDeckBitlamp[3].alarm} />
                                <BitLamp text={belowMainDeckBitlamp[4].text} alarm={belowMainDeckBitlamp[4].alarm} />
                                <BitLamp text={belowMainDeckBitlamp[5].text} alarm={belowMainDeckBitlamp[5].alarm} />
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 30
                            }}>
                                <BitLamp text={belowMainDeckBitlamp[6].text} alarm={belowMainDeckBitlamp[6].alarm} />
                                <BitLamp text={belowMainDeckBitlamp[7].text} alarm={belowMainDeckBitlamp[7].alarm} />
                                <BitLamp text={belowMainDeckBitlamp[8].text} alarm={belowMainDeckBitlamp[8].alarm} />
                            </div>
                        </div> */}
                        <Col md={12}>
                            <Row>
                                {
                                    belowMainDeckBitlamp.map((bitlamps, index) => {
                                        return (

                                            <Col md={4} key={index}>
                                                <div key={index} style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "column",

                                                }}>
                                                   
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        marginLeft:200,
                                                        paddingBottom:20,
                                                        flexWrap: "nowrap",
                                                    }}>
                                                          <BitLamp text={bitlamps.coordinate_desc} alarm={bitlamps.alarm} />
                                                    </div>

                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
              />
            <div style={{ paddingBottom: 120 }}></div>
            <div><BottomNavBar /></div>
        </>
    );
}