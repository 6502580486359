import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, useLocation, useAsyncError } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, findAllEngineHealthInspection, getTroubleshootingCount, deleteEngineHealthInspectionById, getTroubleshootingReport, getUserAccessById} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { EyeFill, PencilFill, Trash, Search, ArrowClockwise, FilePlusFill, PrinterFill } from "react-bootstrap-icons";
import Moment from "moment";
import Paginations from "../../../Components/Pagination";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import ContainerBox from "../../../Components/ContainerBox";
import moment from "moment";


export default function ListEngineHealthInspectionPage() {

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();

    const [listEngineHealthInspection, setListEngineHealthInspection] = useState({
        data: [],
        isFinishedLoading: false,
    });
    const [shouldUpdateFromAPI, setShouldUpdateFromAPI] = useState(true);
   
    const [userAccess, setUserAccess] = useState({
        allowCreate: false,
        allowDelete: false,
        isLoadAccessFinished: false,
    });
    const [listTroubleshooting, setListTroubleshooting] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [descending, setDescending] = useState(true);
    const [searchQuery, setSearchQuery] = useState("")
    const [itemPerPage, setItemPerPage] = useState(50);
    const [projectId, setProjectId] = useState("");
    const [troubleshootingId, setTroubleshootingId] = useState("");
    const [projectName, setProjectName] = useState("");
    const [serviceDate, setServiceDate] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 20, 50, 100]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Proyek",
        value: "project.project_name"
    },
    {
        label: "Nama Kapal",
        value: "vessel.vessel_name"
    },);
    const [docxData, setDocxData] = useState("");

    const orderByList = [
        {
            label: "Nama Proyek",
            value: "project.project_name"
        },
        {
            label: "Nama Kapal",
            value: "vessel.vessel_name"
        },
    ]


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
               loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if(userAccess.isLoadAccessFinished){
            setShouldUpdateFromAPI(true);
            
        }
    }, [userAccess]);

    useEffect(() => {
        loadEngineHealthInspection();
    }, [shouldUpdateFromAPI]);

    useEffect(() => {
        if(listEngineHealthInspection.isFinishedLoading){
            setLoading(false);
            console.log(listEngineHealthInspection);
        }
    }, [listEngineHealthInspection]);

    // useEffect(() => {
    //     loadTroubleshooting();
    // }, [page]);

    // useEffect(() => {
    //   loadTroubleshooting();
    // }, [itemPerPage]);

    // useEffect(() => {
    //     loadTroubleshooting();
    // }, [orderBy]);

    // useEffect(()=>{
    //     loadTroubleshooting();
    // },[descending])

  

    useEffect(() => {
        if (removeId !== "")
            removeData();
    }, [removeId]);

    useEffect(() => {
        if (troubleshootingId !== "")
            createDocument();
    }, [troubleshootingId]);

    useEffect(() => {
        if (docxData.size > 0) {
            downloadDocxFile();
        }
    }, [docxData]);


    const loadEngineHealthInspection = async () => {
        try{
            let resp = await findAllEngineHealthInspection(cookies.token, cookies.languageId, cookies.extCustomerId, searchQuery);
            console.log(resp);
            setListEngineHealthInspection({ data: resp, isFinishedLoading: true });
        }
        catch(exception){
            console.log(exception);
        }
    }

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        
        element.href = URL.createObjectURL(file);
        element.download = `${projectName} - ${Moment(serviceDate).format("DD-MM-yyyy")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        
        URL.revokeObjectURL(file);
        setLoading(false);
        setDisabledButton(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getTroubleshootingReport(cookies.token, troubleshootingId, projectId)
            if (docx.size) {
                setDocxData(docx);
            }else{
                alert('Gagal Mengunduh Dokumen');
                setLoading(false);
                setDisabledButton(false);
            }
            setTroubleshootingId("")
        } catch (exception) {
            console.log(exception)
        }
    }

    const removeData = async()=>{
        try {
            let response = await deleteEngineHealthInspectionById(cookies.token, removeId);
            if(response.error_code === 0){
                alert('Data Berhasil Dihapus');
                loadEngineHealthInspection();
            }else{
                alert('Data Gagal Dihapus')
            }
            setRemoveId("");
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if(response){
                setUserAccess({ allowCreate: response.updated, allowDelete: response.deleted, isLoadAccessFinished: true });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Daftar Inspeksi Mesin"
                    useActionContainer={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!userAccess.allowCreate} variant="secondary" style={{

                            }} onClick={() => { navigate("/EngineHealthInspection/Detail", { state: { engineHealthInspectionId: "" } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Inspeksi Mesin</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Nama Proyek, Nama Kapal)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadEngineHealthInspection();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadEngineHealthInspection();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadEngineHealthInspection()
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 5 }} className="table-header-content">Nama Proyek</div>
                                    <div style={{ flex: 5 }} className="table-header-content">Tanggal Inspeksi</div>
                                    <div style={{ flex: 7 }} className="table-header-content">Kapal</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Posisi Mesin</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Model Mesin</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Nomor Serial Mesin</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Hapus</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Cetak</div>

                                </div>
                                {
                                    listEngineHealthInspection && listEngineHealthInspection.data.length === 0 &&
                                    <div>
                                        <div colSpan={7} style={{ textAlign: "center" }}>Tidak Ada Data</div>
                                    </div>
                                }
                                {
                                    listEngineHealthInspection && listEngineHealthInspection.data.length > 0 && listEngineHealthInspection.data.map(
                                        (insp, index) => {
                                            return (<div key={index} className="table-body">
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                <div style={{ flex: 5 }} className="table-body-content"><p>{insp.project_name}</p></div>
                                                <div style={{ flex: 5 }} className="table-body-content"><p>{insp.start_date? moment(insp.start_date).format("DD/MM/yyyy"):""}</p></div>
                                                <div style={{ flex: 7 }} className="table-body-content"><p>{insp.vessel_name}</p></div>
                                                <div style={{ flex: 4 }} className="table-body-content"><p>{insp.position}</p></div>
                                                <div style={{ flex: 4 }} className="table-body-content"><p>{insp.title}</p></div>
                                                <div style={{ flex: 4 }} className="table-body-content"><p>{insp.serial_number}</p></div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button variant="primary" onClick={() => {
                                                            navigate("/EngineHealthInspection/Detail", { state: { engineHealthInspectionId: insp.engine_health_inspection_id } });
                                                        }}><PencilFill />
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"? true : !userAccess.allowDelete} variant="danger"
                                                            onClick={(event) => {
                                                                if (window.confirm(`Apakah Anda Yakin Menghapus Data ini?`)) {
                                                                    let idRemove = insp.engine_health_inspection_id
                                                                    setRemoveId(idRemove)
                                                                }
                                                            }}
                                                        ><Trash /></Button>
                                                    </div>
                                                </div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        { <Button disabled={true} variant="primary" onClick={() => {
                                                           
                                                        }}><PrinterFill />
                                                        </Button> }
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        })
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}   