import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container, Modal, Table } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getDivision, getStaff, getReturnInventory,
    getReturnInventoryCount, getReturnInventoryById, deleteReturnInventory,
    insertUpdateReturnInventory, getListReturnInventory, getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import {
    getReturnById, insertUpdateReturn,
} from "../../../../Helpers/InventoryHelpers";
import { getInventoryCondition } from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import { EyeFill, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise, Printer, ArrowLeft } from "react-bootstrap-icons";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Paginations from "../../../../Components/Pagination";
import Loading from "../../../../Components/Loading";
import Select from 'react-select';
import ButtonForm from "../../../../Components/ButtonForm";
import moment from "moment";
import TextBox from "../../../../Components/Textbox";
import FormSelect from "../../../../Components/FormSelect";
import { omit } from 'lodash';
import { Trans, useTranslation } from "react-i18next";


// import MyImage from '../../../../Assets/Images/';


export default function ReturnDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [listDivision, setListDivision] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [listStaff, setListStaff] = useState([]);
    const [listReceiveStaff, setListReceiveStaff] = useState([]);
    const [options, setOptions] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [listReturnInventory, setlistReturnInventory] = useState([]);
    const [ReturnInventoryCount, setReturnInventoryCount] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([50, 100, 150, 200, 250]);
    const [descending, setDescending] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [listInventoryStock, setListInventoryStock] = useState([]);
    const [inventoryOptions, setInventoryOptions] = useState([]);
    const [returnId, setReturnId] = useState(0);
    const [listReturnItem, setListReturnItem] = useState([]);
    const [listSearchedReturnItem, setListSearchedReturnItem] = useState([]);
    const [disabledGivenStaff, setDisabledGivenStaff] = useState(false);
    const [disabledForm, setDisabledForm] = useState(false);
    const [selectedStock, setSelectedStock] = useState({});
    const [selectedReturnDetail, setSelectedReturnDetail] = useState({
        id: 0,
        return_id: "",
        return_inventory_description: "",
        inventory_stock_id: "",
        inventory_condition_id: "",
    });
    const barcodeDiv = useRef(null);
    const [barcodeRef, setBarcodeRef] = useState(barcodeDiv);
    const [barcodeState, setBarcodeState] = useState("");


    //modal
    const [showInventoryModal, setShowInventoryModal] = useState(false);
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);



    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_return_inventory.order_name1")}`,
        value: "inventory_code"
    });

    const orderByList = [
        {
            label: `${t("detail_return_inventory.order_name1")}`,
            value: "inventory_code"
        },
        {
            label: `${t("detail_return_inventory.order_name2")}`,
            value: "inventory_condition_name"
        }

    ]

    const [returns, setReturns] = useState({
        id: 0,
        form_number: "",
        division_id: "",
        return_date: "",
        given_by_staff_id: "",
        known_by_staff_id: "",
        received_by_staff_id: "",
        document_base64: "",
        return_note: "",
        division_name: "",
        document_number: "",
        staff_givenbyname: "",
        staff_knownbyname: "",
        staff_receivedbyname: "",
        detail: [],
        ext_customer_id: cookies.extCustomerId
    })


    const [ReturnInventory, setReturnInventory] = useState({
        id: 0,
        return_id: "",
        inventory_stock_id: "",
        inventory_condition_id: "",
        return_inventory_description: ""
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
        }


        checkCookies();
        loadDivision();
        loadStaff();
        loadAccess();
        loadInventoryCondition();
        setReturnId(location.state.ReturnId);


        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
        }
        // if (location.state.ReturnId === 0) {

        // } else {
        //     initReturn();
        //     loadReturnInventory();

        // }
    }, []);

    useEffect(() => {
        validationForm();
        if (returns.given_by_staff_id !== "")
            loadInventoryStock()
    }, [returns]);

    useEffect(() => {
        if (barcodeState !== "" && listInventoryStock) {
            let selectedInventoryStock = listInventoryStock.find(p => p.barcode_number === barcodeState);
            let selectedOption = listInventoryStock.find(p => p.barcode_number === barcodeState);
            if (selectedOption) {
                let returnInv = selectedInventoryStock;
                returnInv.inventory_stock_id = selectedOption.id;
                returnInv.inventory_condition_name = selectedOption.inventory_condition_name
                selectedInventoryStock.inventory_condition_name = selectedOption.inventory_condition_name
                if (listSearchedReturnItem.length > 0) {
                    let newList = listSearchedReturnItem.filter(p => p.inventory_stock_id === returnInv.inventory_stock_id)
                    if (newList.length === 0 && showInventoryModal === false) {
                        setListReturnItem([...listReturnItem, returnInv]);

                    }

                } else {
                    if (showInventoryModal === false)
                        setListReturnItem([...listReturnItem, returnInv]);

                }
                let newList = listSearchedReturnItem.filter(p => p.inventory_stock_id === selectedOption.id)
                if (showInventoryModal === true && newList.length === 0)
                    setSelectedStock(selectedOption);
                setSelectedReturnDetail(selectedOption)

            }


            setBarcodeState("")

        }
    }, [barcodeState]);

    useEffect(() => {
        if (barcodeState !== "" && listInventoryStock) {
            let selectedInventoryStock = listInventoryStock.find(p => p.barcode_number === barcodeState);
            setReturnInventory(selectedInventoryStock);
        }
    }, [barcodeRef]);

    useEffect(() => {
        // loadReturn();
        setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)));
    }, [page]);

    useEffect(() => {
        // loadReturn();
        setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)));
    }, [itemPerPage]);

    useEffect(() => {
        if (orderBy.value === 'inventory_code') {
            setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_code > b.inventory_code ? 1 : -1)));
        } else {
            setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_condition_name > b.inventory_condition_name ? 1 : -1)));
        }
    }, [orderBy]);

    useEffect(() => {
        if (descending === true) {
            if (orderBy.value === 'inventory_code') {
                setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_code > b.inventory_code ? 1 : -1)));
            } else {
                setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_condition_name > b.inventory_condition_name ? 1 : -1)));
            }
        } else {
            if (orderBy.value === 'inventory_code') {
                setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_code > b.inventory_code ? -1 : 1)));
            } else {
                setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)).sort((a, b) => (a.inventory_condition_name > b.inventory_condition_name ? -1 : 1)));
            }
        }

    }, [descending]);

    useEffect(() => {
        setListSearchedReturnItem(listReturnItem.slice(page * itemPerPage, itemPerPage * (page + 1)));
        // console.log(listReturnItem.sort());
        // console.log(page, itemPerPage)
        // console.log()
        if (listReturnItem.length > 0) {
            setReturnInventoryCount(listReturnItem.length);
            let totalPage = listReturnItem.length / itemPerPage;
            setTotalPage(totalPage);
            setDisabledGivenStaff(true);
        } else {
            setDisabledGivenStaff(false)
        }
    }, [listReturnItem]);

    useEffect(() => {
        if (searchQuery !== "") {
            let listSearchedReturnItem = listReturnItem.filter(p => (p.inventory_code.includes(searchQuery) || p.inventory_master_name.toLowerCase().includes(searchQuery.toLowerCase()) || p.inventory_condition_name.toLowerCase().includes(searchQuery.toLowerCase())));
            if (listSearchedReturnItem)
                setListSearchedReturnItem(listSearchedReturnItem);
            else
                setListSearchedReturnItem([]);
        } else {
            setListSearchedReturnItem(listReturnItem);
        }
    }, [searchQuery])





    useEffect(() => {
        if (returnId !== 0) {
            initReturn();
            loadReturnInventory();
            setDisabledForm(true);
        }
    }, [returnId]);




    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = "Form Pengembalian Barang";
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }



    const formatDate = (timestamps) => {
        var dateFormat = new Date(timestamps.toString());
        var tanggal = String(dateFormat.getDate()).padStart(2, '0');
        var bulan = String(dateFormat.getMonth()).padStart(2, '0');
        var tahun = dateFormat.getFullYear();
        var fixDate = tahun + "-" + bulan + "-" + tanggal;
        return fixDate
    }

    const handleremove = index => {
        const list = [...listReturnItem];
        list.splice(index, 1);
        setListReturnItem(list);
    }




    const initReturn = async () => {
        try {
            let response = await getReturnById(cookies.token, returnId);
            if (response) {
                setReturns({
                    id: response.id,
                    form_number: response.document_number,
                    division_id: response.division_id,
                    return_date: response.return_date,
                    given_by_staff_id: response.given_by_staff_id,
                    known_by_staff_id: response.known_by_staff_id,
                    received_by_staff_id: response.received_by_staff_id,
                    document_base64: response.document_base64,
                    return_note: response.return_note,
                    division_name: response.division_name,
                    document_number: response.document_number,
                    staff_givenbyname: response.staff_givenbyname,
                    staff_knownbyname: response.staff_knownbyname,
                    staff_receivedbyname: response.staff_receivedbyname,
                    detail: response.detail,
                    numberForm: response.number_form

                });
            }
            loadReturnInventory();

        } catch (exception) {
            console.log(exception);
        }
    }



    const initReturnInventory = async (returnInventoryId) => {
        try {
            let response = await getReturnInventoryById(cookies.token, returnInventoryId);
            if (response) {
                setReturnInventory({
                    id: response.id,
                    return_id: response.return_id,
                    inventory_stock_id: response.inventory_stock_id,
                    inventory_condition_id: response.inventory_condition_id,
                    return_inventory_description: response.return_inventory_description

                });
            }

        } catch (exception) {
            console.log(exception);
        }
    }




    const saveReturnForm = async () => {
        try {
            let returnsTmp = returns;
            returnsTmp.ext_customer_id = returnsTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : returns.ext_customer_id;
            console.log(listReturnItem)
            let response = await insertUpdateReturn(cookies.token, returns, listReturnItem);
            if (response) {
                if (response.error_code === 0) {
                    alert(`${t("detail_return_inventory.alert_success_save_data")}`);
                    if (response.data.id !== undefined || response.data.id !== 0) {
                        setReturnId(response.data.id);
                        setReturns({ ...returns, id: response.data.id });
                    }
                } else {
                    alert(`${t("detail_return_inventory.alert_failed_save_data")}`);
                }
            }
            loadReturnInventory();
            setDisabledButton(false);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }





    const clearReturnInventoryModal = async () => {

        setSelectedStock({});

    }



    const addReturnInventory = async () => {

        try {
            let response = await insertUpdateReturnInventory(cookies.token, ReturnInventory, returns.id, returns.division_id);
            if (response.error_code === 0) {
                alert(`${t("detail_return_inventory.alert_success_save_data")}`);
                setShowInventoryModal(false);
                loadReturnInventory();
                if (listReturnInventory.length === 0) {
                    initReturn()
                }
            }
        } catch (exception) {
            console.log(exception);
        }
    }




    const loadDivision = async () => {
        try {
            let listDivision = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId,);
            setListDivision(listDivision);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadStaff = async () => {
        try {
            let listStaff = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, undefined, true, undefined, undefined, 2);
            // setLoading(true);
            setListStaff(listStaff);
            let listNewStaff = [];

            for (let i = 0; i < listStaff.length; i++) {
                var obj = {
                    value: listStaff[i].employee_number,
                    label: listStaff[i].staff_name + " (" + listStaff[i].employee_number + ") ",
                    id: listStaff[i].staff_id,
                    resign_date: listStaff[i].resign_date

                };

                listNewStaff.push(obj);

            }
            setOptions(listNewStaff);


        } catch (exception) {
            console.log(exception);
        }


    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }




    const loadReturnInventory = async () => {
        let ReturnInventoryCount = await getReturnInventoryCount(cookies.token, returnId);
        setReturnInventoryCount(ReturnInventoryCount);
        let totalPage = ReturnInventoryCount / itemPerPage;
        setTotalPage(totalPage);

        let listReturnInventory = await getReturnInventory(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false, returnId);
        setlistReturnInventory(listReturnInventory);
        setListReturnItem(listReturnInventory);
        setLoading(false);

    }



    const deletethisReturnInventory = async (id, inventory_stock_id) => {
        try {
            // let text;
            if (window.confirm(`${t("detail_return_inventory.delete_confirmation")}`) == true) {
                let deleteSelectedReturnInventory = await deleteReturnInventory(cookies.token, id, inventory_stock_id, returns.given_by_staff_id);
                if (deleteSelectedReturnInventory.success) {
                    if (deleteSelectedReturnInventory.error_code === 0) {
                        alert(`${t("detail_return_inventory.delete_success_alert")}`);
                    }
                    else {
                        alert(deleteSelectedReturnInventory.error_message);
                    }
                }
                else {
                    alert(deleteSelectedReturnInventory.error_message);
                }
                loadReturnInventory();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }


    const loadInventoryStock = async (staffId) => {
        let listInventoryStock = await getListReturnInventory(cookies.token, undefined, undefined, "inventory_code", true, undefined, returns.given_by_staff_id, ReturnInventory.id === 0 ? true : undefined);
        setListInventoryStock(listInventoryStock);
        let listNewInventory = [];
        var opt = {
            value: "",
            label: "",
            id: ""

        };
        listNewInventory.push(opt);
        for (let i = 0; i < listInventoryStock.length; i++) {
            var obj = {
                value: listInventoryStock[i].id,
                label: listInventoryStock[i].inventory_master_name + " (" + listInventoryStock[i].inventory_code + ")",
                id: listInventoryStock[i].id,
                conditionId: listInventoryStock[i].inventory_condition_id

            };

            listNewInventory.push(obj);

        }

        setInventoryOptions(listNewInventory);

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (returns.return_date === undefined || returns.return_date === "") {
                    setErrors({
                        ...errors,
                        returnsDateMessageError: `${t("detail_return_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (returns.division_id === undefined || returns.division_id === "") {
                    setErrors({
                        ...errors,
                        divisionMessageError: `${t("detail_return_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (returns.given_by_staff_id === undefined || returns.given_by_staff_id === "") {
                    setErrors({
                        ...errors,
                        givenByStaffMessageError: `${t("detail_return_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (returns.received_by_staff_id === undefined || returns.received_by_staff_id === "") {
                    setErrors({
                        ...errors,
                        receivedByStaffMessageError: `${t("detail_return_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else if (returns.known_by_staff_id === undefined || returns.known_by_staff_id === "") {
                    setErrors({
                        ...errors,
                        knownByStaffMessageError: `${t("detail_return_inventory.error_empty_field")}`
                    },
                        setDisabledButton(true))
                }
                else {
                    let errorMsg = omit(errors, "returnsDateMessageError", "divisionMessageError", "givenByStaffMessageError", "receivedByStaffMessageError", "knownByStaffMessageError");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")), cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("detail_return_inventory.order_name1")}`,
                value: "inventory_code"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_return_inventory.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Return"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_return_inventory.header_return_inventory")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>

                                                            <TextBox value={returns.form_number} placeholder=""
                                                                caption={`${t("detail_return_inventory.field_form_number")}`} setValueFunction={(e) => {
                                                                    setReturns({ ...returns, form_number: e.target.value });
                                                                }} disabledForm="true" />
                                                            <TextBox value={returns.return_date !== undefined ? moment(returns.return_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Pengembalian"
                                                                caption={`${t("detail_return_inventory.field_return_date")}`} controlType="date" setValueFunction={(e) => {
                                                                    setReturns({ ...returns, return_date: e.target.value });
                                                                }} disabledForm={disabledForm} />
                                                            {
                                                                errors.returnsDateMessageError && <p style={{ color: "red" }}>{errors.returnsDateMessageError}</p>
                                                            }
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_return_inventory.field_division")}</Form.Label>
                                                                <Form.Select required onChange={(e) => {
                                                                    setReturns({ ...returns, division_id: e.target.value })
                                                                }} value={returns.division_id} disabled={disabledForm} >
                                                                    <option selected value="">

                                                                    </option>
                                                                    {listDivision.map((division, index) => (
                                                                        <option key={index} value={division.id}>{division.division_name} ({division.division_code})</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                            {
                                                                errors.divisionMessageError && <p style={{ color: "red" }}>{errors.divisionMessageError}</p>
                                                            }

                                                            {/* <FormSelect caption="Peminjam" autoFocusValue={returns.given_by_staff_id == undefined} placeholder={returns.given_by_staff_id == "" ? "Pilih Staff..." : <span className="text-danger">Pilih Staff!</span>} required
                                                                options={options.filter(p => p.id !== returns.received_by_staff_id && p.id !== returns.known_by_staff_id)} value={options.filter(function (option) {
                                                                    return option.id === returns.given_by_staff_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setReturns({ ...returns, given_by_staff_id: e.id });
                                                                    } else {
                                                                        setReturns({ ...returns, given_by_staff_id: null });
                                                                    }


                                                                }}
                                                            /> */}

                                                            <FormSelect caption={`${t("detail_return_inventory.field_borrower")}`} autoFocusValue={returns.given_by_staff_id == undefined} placeholder=""
                                                                options={options.filter(p => p.id !== returns.received_by_staff_id && p.id !== returns.known_by_staff_id)} value={options.filter(function (option) {
                                                                    return option.id === returns.given_by_staff_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setReturns({ ...returns, given_by_staff_id: e.id });
                                                                    } else {
                                                                        setReturns({ ...returns, given_by_staff_id: null });
                                                                    }
                                                                }} disabledForm={disabledGivenStaff}
                                                            />
                                                            {
                                                                errors.givenByStaffMessageError && <p style={{ color: "red" }}>{errors.givenByStaffMessageError}</p>
                                                            }


                                                        </div><div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>


                                                            {/* <FormSelect caption="Diterima Oleh" autoFocusValue={returns.received_by_staff_id == undefined} placeholder={returns.received_by_staff_id == "" ? "Pilih Staff..." : <span className="text-danger">Pilih Staff!</span>} required
                                                                options={options.filter(p => p.id !== returns.given_by_staff_id && p.id !== returns.known_by_staff_id)} value={options.filter(function (option) {
                                                                    return option.id === returns.received_by_staff_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setReturns({ ...returns, received_by_staff_id: e.id })
                                                                    } else {
                                                                        setReturns({ ...returns, received_by_staff_id: null });
                                                                    }

                                                                }}
                                                            /> */}

                                                            <FormSelect caption={`${t("detail_return_inventory.field_accepted_by")}`} autoFocusValue={returns.received_by_staff_id == undefined} placeholder=""
                                                                options={options.filter(p => p.id !== returns.given_by_staff_id && p.id !== returns.known_by_staff_id && p.resign_date === null)} value={options.filter(function (option) {
                                                                    return option.id === returns.received_by_staff_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setReturns({ ...returns, received_by_staff_id: e.id })
                                                                    } else {
                                                                        setReturns({ ...returns, received_by_staff_id: null });
                                                                    }
                                                                }} disabledForm={disabledForm}
                                                            />
                                                            {
                                                                errors.receivedByStaffMessageError && <p style={{ color: "red" }}>{errors.receivedByStaffMessageError}</p>
                                                            }
                                                            <FormSelect caption={`${t("detail_return_inventory.field_known_by")}`} autoFocusValue={returns.known_by_staff_id == undefined} placeholder=""
                                                                options={options.filter(p => p.id !== returns.given_by_staff_id && p.id !== returns.received_by_staff_id && p.resign_date === null)} value={options.filter(function (option) {
                                                                    return option.id === returns.known_by_staff_id;
                                                                })} setValueFunction={(e) => {
                                                                    if (e !== null) {
                                                                        setReturns({ ...returns, known_by_staff_id: e.id })
                                                                    } else {
                                                                        setReturns({ ...returns, known_by_staff_id: null });
                                                                    }
                                                                }} disabledForm={disabledForm}
                                                            />
                                                            {
                                                                errors.knownByStaffMessageError && <p style={{ color: "red" }}>{errors.knownByStaffMessageError}</p>
                                                            }

                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_return_inventory.field_note")}</Form.Label>
                                                                <textarea
                                                                    className="form-control"
                                                                    value={returns.return_note}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        setReturns({ ...returns, return_note: e.target.value })
                                                                    }}
                                                                    rows="4"
                                                                />
                                                            </Form.Group>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>
                            {
                                returnId !== 0 ?
                                    <Button className="cancel" onClick={printPageArea}  >{t("detail_return_inventory.button_print")} <Printer /></Button>
                                    :
                                    <></>
                            }


                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>

                                <ContainerBox
                                    titleCaption={`${t("detail_return_inventory.header_list_return_inventory")}`}
                                    containerPos="inner"
                                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                    actionContainerChild={
                                        <div>
                                            <Form.Control hidden className="barcode-input" id="barcode-input" onChangeCapture={(e) => {
                                                // console.log("FROM React", e);
                                            }} onChange={(e) => {
                                                setBarcodeState(e.target.value);
                                                // console.log("FROM React", e.target.value);

                                            }} value={barcodeState} type="text" disabled placeholder="Barcode" />
                                            <Button disabled={!updatedButton} variant="secondary" style={{

                                            }} onClick={() => {
                                                clearReturnInventoryModal();
                                                setShowInventoryModal(true);
                                                setReturnInventory({ ...ReturnInventory, id: 0 });
                                                ReturnInventory.id = 0;
                                                loadInventoryStock(returns.received_by_staff_id);
                                                setSelectedReturnDetail({ ...selectedReturnDetail, id: 0, return_id: ReturnInventory.id, inventory_condition_id: "", bulk_item: false });
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                    alignSelf: "center",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        flex: 1,
                                                    }}><FilePlusFill size={32} /></div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 8,
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        paddingLeft: 10,
                                                        fontWeight: "bold",
                                                        fontSize: 18,
                                                    }}>{t("detail_return_inventory.add_button")}</div>
                                                </div>
                                            </Button>
                                        </div>
                                    }
                                    childContent={
                                        <div className="master-table-inner-container">
                                            <div className="master-table-searchbar-container">
                                                <div className="master-table-searchbar-textbox">
                                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_return_inventory.search_box")}`}
                                                        onKeyPress={async (e) => {
                                                            if (e.charCode === 13) {
                                                                setLoading(true);
                                                                await loadReturnInventory();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setSearchQuery(e.target.value);
                                                        }} />
                                                </div>
                                                <div className="master-table-searchbar-button">
                                                    <div style={{ paddingRight: 5 }}>
                                                        <Button onClick={async () => {
                                                            setLoading(true);
                                                            await loadReturnInventory();
                                                        }}><Search /></Button>
                                                    </div>
                                                    <div style={{ paddingLeft: 5 }}>
                                                        <Button onClick={async () => {
                                                            setLoading(true);
                                                            setSearchQuery("");
                                                            await loadReturnInventory();
                                                        }}><ArrowClockwise /></Button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{

                                                maxHeight: 400,
                                                overflowY: "auto",
                                                border: "1px solid #ccc"
                                            }}>
                                                <div className="table-container">
                                                    <div className="table-header">
                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                        <div style={{ flex: 3 }} className="table-header-content">{t("detail_return_inventory.table_name1")}</div>
                                                        <div style={{ flex: 3 }} className="table-header-content">{t("detail_return_inventory.table_name2")}</div>
                                                        <div style={{ flex: 3 }} className="table-header-content">{t("detail_return_inventory.table_name6")}</div>
                                                        <div style={{ flex: 1 }} className="table-header-content">{t("detail_return_inventory.table_name3")}</div>
                                                        <div style={{ flex: 1 }} className="table-header-content">Total Items</div>
                                                        <div style={{ flex: 1 }} className="table-header-content">{t("detail_return_inventory.unit_name")}</div>
                                                        <div style={{ flex: 2 }} className="table-header-content">{t("detail_return_inventory.table_name4")}</div>
                                                        {/* <div style={{ flex: 1 }} className="table-header-content">Edit</div> */}
                                                        <div style={{ flex: 1 }} className="table-header-content">{t("detail_return_inventory.table_name5")}</div>
                                                    </div>
                                                    {
                                                        listSearchedReturnItem.map((returnInv, index) => {
                                                            return (
                                                                <div className="table-body" >
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                    <div style={{ flex: 3 }} className="table-body-content"><p>{returnInv.inventory_master_name}</p></div>
                                                                    <div style={{ flex: 3 }} className="table-body-content"><p>{returnInv.inventory_code}</p></div>
                                                                    <div style={{ flex: 3 }} className="table-body-content"><p>{returnInv.inventory_type_name}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{returnInv.inventory_condition_name}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{returnInv.bulk_item ? returnInv.return_quantity : 1}</p></div>
                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{returnInv.unit_name}</p></div>
                                                                    <div style={{ flex: 2 }} className="table-body-content"><p>{returnInv.return_inventory_description}</p></div>

                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                        <div style={{ padding: 5 }}>
                                                                            <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                                                if (window.confirm(`${t("detail_return_inventory.delete_confirmation")}`) == true) {
                                                                                    handleremove()
                                                                                }
                                                                            }}><Trash />
                                                                            </Button>
                                                                        </div></div>
                                                                </div>)
                                                        }
                                                        )
                                                    }
                                                </div>
                                            </div>


                                            <div style={{
                                                paddingTop: 20,
                                            }}>

                                            </div>
                                            {listReturnItem.length == 0 ?
                                                <center>{t("detail_return_inventory.text_no_data")} </center> : <div></div>

                                            }

                                            <Paginations
                                                itemPerPage={itemPerPage}
                                                totalPage={totalPage}
                                                page={page}
                                                setPage={setPage}
                                                setItemPerPage={setItemPerPage}
                                                itemPerPageSelection={itemPerPageSelection}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                                orderBySelection={orderByList}
                                                isDescActive={descending}
                                                setIsDescActive={setDescending}
                                            />
                                            <Loading
                                                loading={loading}
                                                loadingText={`${t("loading_component.text")}`}
                                            />
                                        </div>
                                    } />
                            </div>
                            : <></>
                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                    <></>
                                    :
                                    <ButtonForm addData={() => {
                                        if (listReturnItem < 1) {
                                            alert(`${t("detail_return_inventory.error_list_item")}`);
                                        }
                                        else {
                                            saveReturnForm();
                                            setLoading(true);
                                            setDisabledButton(true);
                                        }

                                    }}
                                        saveButton={`${t("button_component.save_button")}`}
                                        cancelButton={`${t("button_component.cancel_button")}`}

                                        button={disabledButton === true ? disabledButton : !updatedButton} />
                            }

                        </div>

                    }>


                </ContainerBox>
                <div id="printableArea" style={{ display: 'none' }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ float: 'left', width: '70%' }}>
                        <img src={require("../../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '60px', marginBottom: '80px' }}>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">PT.Radian Peninsula Indonesia</p>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">{t("detail_return_inventory.text_document_number")}{returns.numberForm}</p>
                    </div>
                    <div style={{ marginLeft: '300px', width: '100%' }}>
                        <h3 >FORM PENGEMBALIAN BARANG </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%' }}>
                        <table className="tableClass" style={{ width: "50%" }}>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_return_inventory.field_borrower")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{returns.staff_givenbyname}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_return_inventory.field_division")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{returns.division_name}</td>
                            </tr>
                        </table>

                        {/* <p style={{ margin: '0' }} className="text-danger">{t("detail_return_inventory.field_borrower")} :  {returns.staff_givenbyname}</p>
                        <p style={{ margin: '0' }} className="text-danger">{t("detail_return_inventory.field_division")} :  {returns.division_name}</p> */}
                    </div>
                    <div style={{ float: 'right', width: '35%', marginBottom: '20px' }}>
                        <table className="tableClass" >
                            <tr className="tableClass">
                                <td className="tableClass" >{t("detail_return_inventory.field_return_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass" >{moment(returns.return_date).format("DD-MM-yyyy")}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass" >{t("detail_return_inventory.field_form_number")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{returns.form_number}</td>
                            </tr>
                        </table>
                        {/* <p style={{ margin: '0' }} className="text-danger">{t("detail_return_inventory.field_return_date")} :  {moment(returns.return_date).format("DD-MM-yyyy")}</p>
                        <p style={{ margin: '0' }} className="text-danger">{t("detail_return_inventory.field_form_number")}&nbsp;&nbsp;&nbsp;:  {returns.form_number}</p> */}
                    </div>





                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr>
                            <td>No </td>
                            <td>{t("detail_return_inventory.table_name1")} </td>
                            <td>{t("detail_return_inventory.table_name2")} </td>
                            <td>{t("detail_return_inventory.table_name6")} </td>
                            <td>{t("detail_return_inventory.table_name3")} </td>
                            <td>Total Item </td>
                            <td>{t("detail_return_inventory.unit_name")}  </td>
                            {/* <td>{t("detail_return_inventory.table_name4")} </td> */}
                        </tr>
                        {listReturnInventory.length != 0 ?

                            listReturnInventory.map((returnInv, index) => {
                                return (
                                    <tr key={index} className="table-caption-body">
                                        <td>{index + 1}</td>
                                        <td>{returnInv.inventory_master_name}</td>
                                        <td>{returnInv.inventory_code}</td>
                                        <td>{returnInv.inventory_type_name}</td>
                                        <td>{returnInv.inventory_condition_name}</td>
                                        <td>{returnInv.bulk_item ? returnInv.return_quantity : 1}</td>
                                        <td>{returnInv.unit_name}</td>
                                        {/* <td>{returnInv.return_inventory_description}</td> */}
                                    </tr>
                                )
                            })
                            :
                            <tr >
                                <td style={{ padding: '100px' }}></td>
                                <td style={{ padding: '100px' }}></td>
                                <td style={{ padding: '100px' }}></td>
                                <td style={{ padding: '100px' }}></td>
                                <td style={{ padding: '100px' }}></td>
                            </tr>




                        }


                    </table>
                    <p>{t("detail_return_inventory.table_name4")} :&nbsp;&nbsp;&nbsp;{returns.return_note}</p>
                    <div style={{ marginLeft: 100 }}>
                        <div style={{ float: 'left', width: '30%', marginTop: '30px', marginLeft: '30px' }}>
                            <span >&nbsp;&nbsp;&nbsp;{t("detail_return_inventory.field_borrower")}</span>
                            <p style={{ marginTop: '100px' }} >{returns.staff_givenbyname}</p>
                            <hr width="150px"
                                size="1"
                                align="left"
                            // heigh="10"
                            />
                        </div>
                        <div style={{ float: 'left', width: '30%', marginTop: '30px' }}>
                            <span >&nbsp;&nbsp;&nbsp;{t("detail_return_inventory.field_known_by")}, </span>
                            <p style={{ marginTop: '100px' }} >{returns.staff_knownbyname}</p>
                            <hr width="150px"
                                size="1"
                                align="left"
                            // heigh="10"
                            />
                        </div>
                        <div style={{ float: 'left', width: '30%', marginTop: '30px' }}>
                            <span >&nbsp;&nbsp;&nbsp;{t("detail_return_inventory.field_accepted_by")},</span>
                            <p style={{ marginTop: '100px' }} >{returns.staff_receivedbyname}</p>
                            <hr width="150px"
                                size="1"
                                align="left"
                            // heigh="10"
                            />
                        </div>
                    </div>


                </div>



                {/*============================== MODAL ADD INVENTORY STOCK ============================================ */}

                <Modal show={showInventoryModal}
                    size="lg"
                    onHide={() => {
                        setShowInventoryModal(false);

                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">


                            <h3>{t("detail_return_inventory.modal_add_inventory")} </h3>

                        </div>

                        <Form onSubmit={(e) => {

                            e.preventDefault();
                            // addReturnInventory();
                            setListReturnItem([...listReturnItem, selectedReturnDetail]);
                            setShowInventoryModal(false);


                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "flex-start"
                                }}>
                                    <Form.Control className="barcode-input" id="barcode-input" onChangeCapture={(e) => {
                                        // console.log("FROM React", e);
                                    }} onChange={(e) => {
                                        setBarcodeState(e.target.value);
                                        // console.log("FROM React", e.target.value);

                                    }} value={barcodeState} type="text" disabled placeholder="Barcode" />
                                </div>
                                <Form.Label>{t("detail_return_inventory.field_inventory")}</Form.Label>
                                <Select placeholder={""} required
                                    options={listInventoryStock.filter((p) => {
                                        return !listReturnItem.find((x) => {
                                            return p.id === x.inventory_stock_id
                                        })
                                    })} getOptionLabel={
                                        (item) => {
                                            if (item.id) {
                                                return item.inventory_master_name + (` (${item.inventory_code})`);
                                            } else {
                                                return ("");
                                            }

                                        }
                                    } getOptionValue={
                                        (item) => {
                                            return item.id;
                                        }
                                    } value={selectedStock} onChange={(e) => {
                                        console.log(listInventoryStock)
                                        let selectedInv = listInventoryStock.find(p => p.id === e.id);
                                        setSelectedStock(selectedInv);
                                        let selectedItem = {
                                            return_id: "",
                                            return_inventory_description: selectedInv.return_inventory_description,
                                            id: "",
                                            inventory_code: selectedInv.inventory_code,
                                            inventory_condition_id: selectedInv.inventory_condition_id,
                                            inventory_condition_name: selectedInv.inventory_condition_name,
                                            inventory_master_id: selectedInv.inventory_master_id,
                                            inventory_master_name: selectedInv.inventory_master_name,
                                            inventory_stock_id: selectedInv.id,
                                            handover_quantity: selectedInv.handover_quantity,
                                            bulk_item: selectedInv.bulk_item,
                                            handover_id: selectedInv.handover_id,
                                            last_quantity: 0,
                                            inventory_type_name: selectedInv.inventory_type_name,
                                            unit_name: selectedInv.unit_name
                                        };
                                        setSelectedReturnDetail(selectedItem);

                                    }}
                                />
                            </Form.Group>
                            {
                                selectedReturnDetail.bulk_item &&
                                <div>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Total Item</Form.Label>
                                        <Form.Control type="number" onChange={(e) => {
                                            if (e.target.value >= 0) {
                                                if (e.target.value > selectedReturnDetail.handover_quantity) {
                                                    setErrorMessage(`${t("detail_return_inventory.total_item")} ${selectedReturnDetail.handover_quantity}`)
                                                } else {
                                                    setErrorMessage("")
                                                    setSelectedReturnDetail({ ...selectedReturnDetail, return_quantity: e.target.value })
                                                }

                                            }
                                        }} value={selectedReturnDetail.return_quantity}></Form.Control>

                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("detail_return_inventory.unit_name")}</Form.Label>
                                        <Form.Control disabled type="text" value={selectedReturnDetail.unit_name}></Form.Control>
                                    </Form.Group>
                                </div>


                            }
                            {
                                errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                            }
                            <Form.Group className="mb-3">
                                <Form.Label>Kondisi Barang</Form.Label>
                                <Select placeholder={""} required
                                    options={listInventoryCondition}
                                     getOptionLabel={
                                        (item) => {
                                            return item.inventory_condition_name
                                        }
                                    } getOptionValue={
                                        (item) => {
                                            return item.id;
                                        }
                                    } value={listInventoryCondition.filter(function (option) {
                                        return option.id === selectedReturnDetail.inventory_condition_id;
                                    })} onChange={(e) => {
                                        setSelectedReturnDetail({ ...selectedReturnDetail, inventory_condition_id: e.id, inventory_condition_name: e.inventory_condition_name })
                                    }}
                                />
                                {/* <Form.Select required onChange={(e) => {
                                    setSelectedReturnDetail({ ...selectedReturnDetail, inventory_condition_id: e.target.value })
                                }} value={selectedReturnDetail.inventory_condition_id} >
                                    <option selected value="">
                                        Pilih Kondisi Barang...
                                    </option>
                                    {listInventoryCondition.map((condition, index) => (
                                        <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                    ))}
                                </Form.Select> */}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_return_inventory.field_inventory_description")}</Form.Label>
                                <textarea
                                    className="form-control"
                                    value={selectedReturnDetail.return_inventory_description}
                                    placeholder=""
                                    onChange={(e) => {
                                        setSelectedReturnDetail({ ...selectedReturnDetail, return_inventory_description: e.target.value })
                                    }}
                                    rows="4"
                                />
                            </Form.Group>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: 5,
                                justifyContent: "center"
                            }}>
                                <Button style={{ width: 100 }} type="submit">{t("detail_return_inventory.save_button")}</Button>
                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                    setShowInventoryModal(false);
                                }}>{t("detail_return_inventory.cancel_button")}</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}