import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getProject, getSparepartCatalog, getStaff, insertUpdateSparepartHandover, getSparepartHandoverById,
    getRequisitionStatus, getSparepartCatalogStock, getSparepartHandoverList, getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function SparepartHandoverDetailPage() {
    const { t, i18n } = useTranslation();

    const [projectOptions, setProjectOptions] = useState([]);
    const [sparepartCatalogOptions, setSparepartCatalogOptions] = useState([]);
    const [listSparepartStock, setListSparepartStock] = useState([]);
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [disabledForm, setDisabledForm] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [updatedButton,setUpdatedButton] = useState("");
    const [deletedButton,setDeletedButton] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateErrors, setDateErrors] = useState("");
    const [errors, setErrors] = useState([]);
    const [listHandoverIndex, setListHandoverIndex] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [disabledButtonAdd, setDisableButtonAdd] = useState(false);
    const [sparepartHandover, setSparepartHandover] = useState({
        id: 0,
        project_id: null,
        requesting_staff_id: null,
        request_date: null,
        approved_date: null,
        arrived_date: null,
        approved_by: null,
        sparepart_requisition_id: null,
        giving_staff_id: null,
        list_handover: []
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadProject();
                loadStaff();
                loadStatus();
                loadAccess();
                // loadSparepartCatalog();
            }
        }
        checkCookies();
        if (location.state.sparepartHandoverId === 0) {
            setLoading(false);
        } else {
            initSparepartHandover();
        }
    }, []);

    useEffect(() => {
        if (sparepartHandover.sparepart_requisition_id) {
            loadSparepartCatalog();
        }
        validationForm()
    }, [sparepartHandover])

    useEffect(() => {
        if (listHandoverIndex === 1) {
            if (sparepartHandover.list_handover.length < 1) {
                let list = [];
                for (let index = 0; index < listSparepartStock.length; index++) {
                    let listRequest = listSparepartStock[index];
                    var obj = {
                        sparepart_catalog_id: listRequest.id,
                        price: listRequest.purchase_price,
                        title: listRequest.manufacturer_name,
                        sparepart_catalog_code: listRequest.sparepart_catalog_code,
                        note: listRequest.note
                    };
                   list.push(obj)
                }
                setSparepartHandover({...sparepartHandover, list_handover: list})

            }
        }

    }, [listHandoverIndex])


    // useEffect(() => {
    //     if (listSparepartStock.length > 0) {
    //         if (sparepartHandover.list_handover.length > 0) {
    //             for (let index = 0; index < sparepartHandover.list_handover.length; index++) {
    //                 let list = sparepartHandover.list_handover;

    //                 let stock = listSparepartStock.find(p => p.id === sparepartHandover.list_handover[index].sparepart_catalog_id);
    //                 list[index].title = stock.title + `(${stock.sparepart_catalog_code})`
    //                 // setSparepartHandover({ ...sparepartHandover, list_handover: list });
    //                 // let listHandover = sparepartHandover.list_handover;
    //                 // listHandover[index].sparepart_catalog_id = e.id;
    //                 // listHandover[index].price = e.price;
    //                 // setSparepartHandover({ ...sparepartHandover, list_handover: listHandover });
    //             }
    //         }
    //     }
    // }, [listSparepartStock])

    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listProject = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].project_name,
                    id: response[i].id

                };

                listProject.push(obj);

            }
            setProjectOptions(listProject);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadSparepartCatalog = async () => {
        try {
            let responses = await getSparepartHandoverList(cookies.token, sparepartHandover.sparepart_requisition_id);
            setListSparepartStock(responses);

            let response = await getSparepartCatalogStock(cookies.token,cookies.languageId);
            let listSparepartCatalog = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: (response[i].manufacturer_name ? response[i].manufacturer_name : "") + ' ' + `(${response[i].sparepart_catalog_code})`,
                    id: response[i].id,
                    price: response[i].purchase_price,
                    out_stock: response[i].out_stock
                };

                listSparepartCatalog.push(obj);

            }
            setSparepartCatalogOptions(listSparepartCatalog);
            setListHandoverIndex(1);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id

                };

                listStaff.push(obj);

            }
            setStaffOptions(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            let response = await insertUpdateSparepartHandover(cookies.token, sparepartHandover);
            if (response.error_code === 0) {
                alert(`${t("detail_sparepart_handover.alert_success_save_data")}`);
                navigate('/SparepartHandover');

            } else {
                alert(`${t("detail_sparepart_handover.alert_success_failed_data")}`);
            }

            setDisabledButton(false);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initSparepartHandover = async () => {
        try {
            let response = await getSparepartHandoverById(cookies.token, location.state.sparepartHandoverId);
            if (response) {
                setSparepartHandover({
                    ...sparepartHandover,
                    id: response.id,
                    project_id: response.project_id,
                    requesting_staff_id: response.requesting_staff_id,
                    request_date: response.request_date,
                    approved_date: response.approved_date,
                    arrived_date: response.arrived_date,
                    approved_by: response.approved_by,
                    sparepart_requisition_id: response.sparepart_requisition_id,
                    giving_staff_id: response.giving_staff_id,
                    list_handover: response.list_handover,
                    project_name: response.project_name,
                    staff_name: response.staff_name,
                    approved_name: response.approved_name,
                    giving_name: response.giving_name

                })
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
            setListStatusRequisition(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (sparepartHandover.list_handover.length > 0) {
                    for (let index = 0; index < sparepartHandover.list_handover.length; index++) {
                        const list = sparepartHandover.list_handover[index];
                        if (list.sparepart_catalog_id === "") {
                            // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                            setErrorMessage("Nama Suku Cadang Harus Diisi");
                            setDisabledButton(true);
                            setDisableButtonAdd(true);
                        }
                        else {
                            setErrorMessage("");
                            setDisableButtonAdd(false);
                            setDisabledButton(false);
                        }
                    }
                } else {
                    setErrorMessage("");
                    setDisableButtonAdd(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("detail_sparepart_handover.print_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_sparepart_handover.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/SparepartHandover"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_sparepart_handover.header_sparepart_handover")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>
                                                                <FormSelect caption={`${t("detail_sparepart_handover.field_project_name")}`} autoFocusValue={sparepartHandover.project_id === undefined} placeholder=""
                                                                    options={projectOptions} value={projectOptions.filter(function (option) {
                                                                        return option.id === sparepartHandover.project_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandover({ ...sparepartHandover, project_id: e.id });
                                                                        } else {
                                                                            setSparepartHandover({ ...sparepartHandover, project_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />
                                                                {/* {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                } */}


                                                                <FormSelect caption={`${t("detail_sparepart_handover.field_request_staff")}`} autoFocusValue={sparepartHandover.requesting_staff_id === undefined} placeholder=""
                                                                    options={staffOptions.filter(p => p.id !== sparepartHandover.approved_by)} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartHandover.requesting_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandover({ ...sparepartHandover, requesting_staff_id: e.id });
                                                                        } else {
                                                                            setSparepartHandover({ ...sparepartHandover, requesting_staff_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />
                                                                {/* {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                } */}

                                                                <FormSelect caption={`${t("detail_sparepart_handover.field_approved_by")}`} autoFocusValue={sparepartHandover.approved_by === undefined} placeholder=""
                                                                    options={staffOptions.filter(p => p.id !== sparepartHandover.requesting_staff_id)} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartHandover.approved_by;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandover({ ...sparepartHandover, approved_by: e.id });
                                                                        } else {
                                                                            setSparepartHandover({ ...sparepartHandover, approved_by: null });
                                                                        }

                                                                    }} disabledForm={true}

                                                                />

                                                                <TextBox value={sparepartHandover.request_date ? moment(sparepartHandover.request_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Request" controlType="date"
                                                                    caption={`${t("detail_sparepart_handover.field_request_date")}`} setValueFunction={(e) => {
                                                                        setSparepartHandover({ ...sparepartHandover, request_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>





                                                                <TextBox value={sparepartHandover.approved_date ? moment(sparepartHandover.approved_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Disetujui" controlType="date"
                                                                    caption={`${t("detail_sparepart_handover.field_approved_date")}`} setValueFunction={(e) => {
                                                                        setSparepartHandover({ ...sparepartHandover, approved_date: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <TextBox value={sparepartHandover.arrived_date ? moment(sparepartHandover.arrived_date).format("yyyy-MM-DD") : ""} placeholder="Tanggal Barang Sampai" controlType="date"
                                                                    caption={`${t("detail_sparepart_handover.field_arrived_date")}`} setValueFunction={(e) => {
                                                                        setSparepartHandover({ ...sparepartHandover, arrived_date: e.target.value });
                                                                    }} disabledForm={true} />

                                                                <FormSelect caption={`${t("detail_sparepart_handover.field_given_by")}`} autoFocusValue={sparepartHandover.giving_staff_id === undefined} placeholder=""
                                                                    options={staffOptions.filter(p => p.id !== sparepartHandover.requesting_staff_id)} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartHandover.giving_staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandover({ ...sparepartHandover, giving_staff_id: e.id });
                                                                        } else {
                                                                            setSparepartHandover({ ...sparepartHandover, giving_staff_id: null });
                                                                        }

                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>

                                    {
                                        location.state.sparepartHandoverId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >{t("detail_sparepart_handover.button_print")} <Printer /></Button>
                                            :
                                            <></>
                                    }
                                    <div style={{ paddingBottom: 10 }}></div>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_sparepart_handover.header_sparepart_list")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "row",
                                                            padding: 5
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 3
                                                            }}>{t("detail_sparepart_handover.table_name1")}</div>
                                                            {/* <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            flex: 2
                                                        }}>Jumlah</div> */}
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 2
                                                            }}>{t("detail_sparepart_handover.table_name2")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 2
                                                            }}>{t("detail_sparepart_handover.table_name3")}</div>
                                                            {/* <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 1
                                                            }}>Hapus</div> */}
                                                        </div>
                                                        {
                                                            sparepartHandover.list_handover && sparepartHandover.list_handover.map((list, index) => {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5,
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 3,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>

                                                                            <Select placeholder={""}
                                                                                options={sparepartCatalogOptions.filter((p) => {
                                                                                    return !sparepartHandover.list_handover.find((x) => {
                                                                                        return p.id === x.sparepart_catalog_id
                                                                                    }) && p.out_stock === false
                                                                                })} value={sparepartCatalogOptions.filter(function (option) {
                                                                                    return option.id === list.sparepart_catalog_id;
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    let listHandover = sparepartHandover.list_handover;
                                                                                    listHandover[index].sparepart_catalog_id = e.id;
                                                                                    listHandover[index].price = e.price;
                                                                                    setSparepartHandover({ ...sparepartHandover, list_handover: listHandover });
                                                                                }}
                                                                                isDisabled={true}
                                                                            />
                                                                            {/* <FormSelect placeholder=""
                                                                            options={sparepartCatalogOptions} value={sparepartCatalogOptions.filter(function (option) {
                                                                                return option.id === list.sparepart_catalog_id;
                                                                            })} setValueFunction={(e) => {
                                                                                let listRequisition = sparepartRequisition.list_requisition;
                                                                                listRequisition[index].sparepart_catalog_id = e.id;
                                                                                setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                            }}
                                                                        /> */}

                                                                        </div>
                                                                        {/* <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 2,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <Form.Control onChange={(e) => {
                                                                            let listRequisition = sparepartRequisition.list_requisition;
                                                                            listRequisition[index].quantity = formatCurrency(e.target.value);
                                                                            setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                        }} type="text" value={list.quantity} required placeholder=""></Form.Control>



                                                                    </div> */}
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 2,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Form.Control onChange={(e) => {
                                                                                let listHandover = sparepartHandover.list_handover;
                                                                                listHandover[index].price = formatCurrency(e.target.value);
                                                                                setSparepartHandover({ ...sparepartHandover, list_handover: listHandover });
                                                                            }} type="text" value={list.price} required placeholder="" disabled={true}></Form.Control>


                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 2,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Form.Control onChange={(e) => {
                                                                                let listHandover = sparepartHandover.list_handover;
                                                                                listHandover[index].note = e.target.value;
                                                                                setSparepartHandover({ ...sparepartHandover, list_handover: listHandover });
                                                                            }} type="text" value={list.note} placeholder="" ></Form.Control>


                                                                        </div>
                                                                        {/* <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 1,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Button disabled={!deletedButton} onClick={() => {
                                                                                if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                                    let list = [...sparepartHandover.list_handover];
                                                                                    list.splice(index, 1);
                                                                                    setSparepartHandover({ ...sparepartHandover, list_handover: list });
                                                                                }

                                                                            }} style={{ maxWidth: 50 }} variant="danger" ><Trash /></Button>
                                                                        </div> */}
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }

                                                    {/* <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Button disabled={disabledButtonAdd} variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                id: "",
                                                                sparepart_catalog_id: "",
                                                                price: "",
                                                                note: ""
                                                            };
                                                            if (!sparepartHandover.list_handover) {
                                                                let listHandover = [];
                                                                listHandover.push(newParameter);
                                                                setSparepartHandover({ ...sparepartHandover, list_handover: listHandover });
                                                            } else {
                                                                let listHandover = sparepartHandover.list_handover;
                                                                listHandover.push(newParameter);
                                                                setSparepartHandover({ ...sparepartHandover, list_handover: listHandover });
                                                            }

                                                        }} >Tambah Barang</Button>
                                                    </div> */}
                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>
                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? 
                                        <></>
                                        :
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                            padding: 10,
                                            width: "100%",
                                            justifyContent: "center",
                                            fontWeight: "bold"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                paddingRight: 5,
                                            }}>
                                                <Button style={{width:100}} className="save-button" disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit">{t("detail_sparepart_handover.save_button")}</Button>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                paddingLeft: 5,
                                                paddingRight: 5
                                            }}>
                                                <Button style={{width:100}} className="cancel-button" onClick={()=>{
                                                    navigate("/SparepartHandover")
                                                }} variant="danger">{t("detail_sparepart_handover.cancel_button")}</Button>
                                            </div>
                                        </div>
                                    }

                               

                                </Form>



                            </div>

                          
                        </div>

                    }>
                </ContainerBox>
                <div id="printableArea" style={{ display: 'none' }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ float: 'left', width: '70%' }}>
                        <img src={require("../../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '60px', marginBottom: '80px' }}>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">PT.Radian Peninsula Indoneia</p>
                        {/* <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">No.Dok : {returns.numberForm}</p> */}
                    </div>
                    {/* <div style={{
                        marginLeft: 300,
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >Form Permintaan Suku Cadang </h3>

                    </div> */}

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%" }}>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_handover.field_project_name")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartHandover.project_name}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_handover.field_request_staff")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartHandover.staff_name}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_handover.field_given_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartHandover.giving_name}</td>
                            </tr>
                        </Table>
                        {/* <p style={{ margin: '0' }} className="text-danger">Nama Proyek :  {sparepartRequisition.project_name}</p>
                        <p style={{ margin: '0' }} className="text-danger">Nama Staff   :  {sparepartRequisition.staff_name}</p> */}
                    </div>
                    <div style={{ float: 'right', width: '35%', marginBottom: '20px' }}>
                        <Table className="tableClass" >
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_handover.field_request_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartHandover.request_date ? moment(sparepartHandover.request_date).format("DD-MMM-yyyy") : ""}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("detail_sparepart_handover.field_approved_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{sparepartHandover.approved_date ? moment(sparepartHandover.approved_date).format("DD-MMM-yyyy") : ""}</td>
                            </tr>

                        </Table>
                        {/* <p style={{ margin: '0' }} className="text-danger">Tanggal Pengajuan :  {sparepartRequisition.request_date ? moment(sparepartRequisition.request_date).format("DD-MMM-yyyy") : ""}</p> */}
                        {/* <p style={{ margin: '0' }} className="text-danger">NO&nbsp;&nbsp;&nbsp;:  {returns.form_number}</p> */}
                    </div>
                    <div style={{ paddingBottom: 20 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr>
                            <td>No </td>
                            <td>{t("detail_sparepart_handover.table_name1")}</td>
                            <td>{t("detail_sparepart_handover.table_name2")} </td>
                            <td>{t("detail_sparepart_handover.table_name3")} </td>
                        </tr>
                        {sparepartHandover.list_handover && sparepartHandover.list_handover.map((listHandover, index) => {
                            return (
                                <tr key={index} className="table-caption-body">
                                    <td>{index + 1}</td>
                                    <td>{listHandover.title + `(${listHandover.sparepart_catalog_code})`}</td>
                                    <td>Rp.{listHandover.price}</td>
                                    <td>{listHandover.note}</td>

                                </tr>
                            )
                        })
                        }


                    </table>

                    <div style={{ float: 'left', width: '70%', marginTop: '30px' }}>
                        <span >{t("detail_sparepart_handover.field_request_staff")} </span>
                        <p style={{ marginTop: '100px' }}>{sparepartHandover.staff_name}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '30px' }}>
                        <span >{t("detail_sparepart_handover.field_given_by")} </span>
                        <p style={{ marginTop: '100px' }}>{sparepartHandover.giving_name}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>

                    {/* <div style={{ float: 'left', width: '30%', marginTop: '30px', marginLeft: '30px' }}>
                        <span >&nbsp;&nbsp;&nbsp;Peminjam</span>
                        <p style={{ marginTop: '100px' }} >{returns.staff_givenbyname}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'left', width: '30%', marginTop: '30px' }}>
                        <span >&nbsp;&nbsp;&nbsp;Diketahui oleh, </span>
                        <p style={{ marginTop: '100px' }} >{returns.staff_knownbyname}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'left', width: '30%', marginTop: '30px' }}>
                        <span >&nbsp;&nbsp;&nbsp;Diterima oleh,</span>
                        <p style={{ marginTop: '100px' }} >{returns.staff_receivedbyname}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div> */}

                </div>
            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    );

}