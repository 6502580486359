import "./index.css"
import TopNavBar from "../../../Components/TopNavBar/TopNavBar";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import NumStat from "../../../Components/NumStatMainEngine/NumStatMainEngine";
import NumStat1 from "../../../Components/NumStatMainEngine1/NumStatMainEngine1";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Speedometer, { Arc, Needle, Progress, Marks, Indicator, DangerPath } from 'react-speedometer';
import { isTokenValid, getPlcValue, getPLCCoordinate, getEquipmentDetail, getECUValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import { Row, Form, Modal, Button } from "react-bootstrap";
import TankLevel from "../../../Components/TankLevel/TankLevel";
export default function MainEnginePage() {
    //==============Main Engine PORT==============
    // left column bitlamp
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [listCoordinate, setListCoordinate] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    const [equipmentModal, setEquipmentModal] = useState("");
    const [detailEquipment, setDetailEquipment] = useState({});
    const [ecuValue, setEcuValue] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    

    // useEffect(() => {
    //     if (listCoordinate.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [listCoordinate]);

    useEffect(() => {
        if (listCoordinate.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadBitlampValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [listCoordinate, isDataLoaded]);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail();
    }, [vesselEquipmentId]);

    const loadEquipmentDetail = async () => {
        try {
            let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
            console.log(response);
            setDetailEquipment(response);
            setEquipmentModal(true);
        } catch (exception) {

        }
    }


    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "main_engine", cookies.vesselId);
            setListCoordinate(response);
            if(response.length < 1){
                setLoading(false);
            }

        } catch (exception) {

        }
    }

    const loadBitlampValue = async () => {
        try {
            setIsDataLoaded(true);
            let coordinate = listCoordinate.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = listCoordinate.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, value: alarmValue.value };
                } else {
                    return { ...item, value: Number(item.value) };
                }
            });
            setListCoordinate(updatedList);

            let responseECU = await getECUValue(cookies.token, cookies.serialNumber);
            setEcuValue(responseECU);
            setLoading(false);
            setIsDataLoaded(false);
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <div>
            {/* Navbar */}
            <div><TopNavBar /></div>
            {/* End Navbar */}

            {/* Content */}
            <div><TitleAlarmBar text={"MAIN ENGINE"} /></div>
            <div className="contentPageMainEngine">
                {
                    listCoordinate.length > 0 && listCoordinate.map((mainEngine, index) => {
                        return (
                            <div className={index === 0 ? "leftContent" : "rightContent"}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    flexWrap: "nowrap"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1
                                    }}>
                                         <div className="titleContent">{mainEngine.coordinate_desc}</div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                        flex: 1,
                                        paddingRight: 10,
                                        paddingTop:5
                                    }}>
                                        <a type="button" onClick={() => {
                                            // alert(pump.equipment_id);
                                            setVesselEquipmentId(mainEngine.equipment_id)
                                        }}> <InfoCircle size={25} /></a>
                                    </div>
                                </div>
                                {/* <div className="titleContent">{mainEngine.coordinate_desc}</div> */}
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "nowrap",
                                    width: "100%"
                                }}>
                                    <div className="gauge">
                                        <Speedometer
                                            width={400}
                                            height={400}
                                            rotation={-150}
                                            min={0}
                                            max={2500}
                                            value={index === 0 ? ecuValue.engine_speed : 0}
                                            angle={250}
                                            fontFamily='squada-one'>
                                            {/* <Background
                                            angle={250}
                                        /> */}
                                            <Arc color="#D9D9D9" />
                                            <Needle color="#D9D9D9" />
                                            <DangerPath angle={62} />
                                            <Progress color="#D9D9D9" />
                                            <Marks step={500} />
                                            <Indicator>

                                            </Indicator>

                                        </Speedometer>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        fontSize: 25,
                                        marginLeft:50
                                    }}>
                                        <div>THROTTLE POSITION({ecuValue.throttle_position}%)</div>
                                      
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        alignContent:"center",
                                        alignItems:"center",
                                        paddingBottom:10,
                                        fontSize: 25,
                                        marginLeft:90
                                    }}>
                                        
                                        <TankLevel valueBG={index === 0 ? ecuValue.throttle_position : 0}/>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        fontSize: 50,
                                        marginLeft:50
                                    }}>
                                        <div>{ecuValue && index === 0 ? "" :"ECU NOT CONNECTED"}</div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontSize: 18,
                                        marginLeft: 125,
                                        // textAlign:"center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                            width: "70%",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            // textAlign:"center"
                                            // alignContent

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 6,
                                            }}>
                                                <div>BATTERY VOLTAGE</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>{index === 0 ? ecuValue.battery_voltage : 0.0}</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>Volt</div>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                            width: "70%",

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 6
                                            }}>
                                                <div>BOOST PRESSURE</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>{index === 0 ? ecuValue.boost_pressure : 0.0}</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>Kpa</div>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                            width: "70%",

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 6
                                            }}>
                                                <div>BOOST AIR TEMP</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>{index === 0 ? ecuValue.boost_air_temp : 0.0}</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>&deg;C</div>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                            width: "70%",

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 6
                                            }}>
                                                <div>RAIL PRESSURE</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>{index === 0 ? ecuValue.rail_pressure : 0.0}</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>Mpa</div>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                            width: "70%",

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 6
                                            }}>
                                                <div>COOLANT TEMP</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>{index === 0 ? ecuValue.coolant_temp : 0.0}</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>&deg;C</div>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                            width: "70%",

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 6
                                            }}>
                                                <div>FUEL TEMP</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>{index === 0 ? ecuValue.fuel_temp : 0.0}</div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 2
                                            }}>
                                                <div>&deg;C</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )
                    })
                }

            </div>
            <Loading
                loading={loading}
            />
            {/* End Content */}

            {/* bottom NavBar */}
            <div style={{ paddingBottom: 150 }}></div>

            <Modal show={equipmentModal}
                // dialogClassName="modal-size"
                size={"xl"}
                onHide={() => {
                    setEquipmentModal(false);
                    setDetailEquipment({});
                    setVesselEquipmentId("")
                    // clearInventoryTypeModal()
                }}>

                {/*  */}
                <ContainerBox
                    containerPos="inner"
                    titleCaption={`Equipment Detail`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setEquipmentModal(false);
                                setDetailEquipment({});
                                setVesselEquipmentId("")
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Close</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <Form style={{ padding: 10 }}>


                            <Form.Group className="mb-3">
                                <Form.Label>Equipment Category</Form.Label>
                                <Form.Control disabled value={detailEquipment.equipment_name}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Manufacture Name</Form.Label>
                                <Form.Control disabled value={detailEquipment.manufacturer_name}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control disabled value={detailEquipment.model}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Serial Number</Form.Label>
                                <Form.Control disabled value={detailEquipment.serial_number}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control disabled value={detailEquipment.part_number}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Position</Form.Label>
                                <Form.Control disabled value={detailEquipment.position}></Form.Control>
                            </Form.Group>


                        </Form>
                    }
                />



            </Modal>
            <div>
                <BottomNavBar />
            </div>
            {/* End Bottom NavBar */}
        </div>
    );
}