import "./LampNavButton.css"
import { Button } from "react-bootstrap";

export default function LampNavButton({ text1, text2, handleOnClick, lamp = false }) {
    return (
        <>
            <Button
                variant="outline-primary"
                className="lampNavButton"
                onClick={() => handleOnClick()}
            >
                <div className={lamp === false ? "menuBitLampBlack" : "menuBitLampRed"}></div>
                <div>{text1}</div>
                <div>{text2}</div>

            </Button>
        </>
    );
}