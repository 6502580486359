import "../App.css";
import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { Form, option, Button, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";
import Select from 'react-select';
import { Trash, FilePlusFill, PencilFill, XSquareFill, CameraFill, Image } from "react-bootstrap-icons";
import { isTokenValid, getVesselEquipmentByEquipmentCategory, convertBase64,getServiceJobDucmentById } from "../Helpers/ApplicationHelpers";
import { useCookies } from "react-cookie";
import { convert } from 'html-to-text'
import { Editor } from '@tinymce/tinymce-react';
import ContainerBox from "./ContainerBox";
import FormSelect from "./FormSelect";
import { Trans, useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../Helpers/Base64Downloader";
import { EyeFill, Download } from "react-bootstrap-icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function ServiceJobDocument({
    listDocument,
    handleOnBlurServiceJobDocument,
    handleOnDeleteDocument
    // steSelectedGroup,

}) {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [resetComponent, setResetComponent] = useState(false);
    const [disabledButton, setDisabledButton] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [downloadDocumentId, setDownloadDocumentId] = useState("");
    // const [serviceJobDocument, setServiceJobDocument] = useState({
    //     base64_image: "",
    //     label: ""
    // })

    const [newDocument, setNewDocument] = useState({
        id: 0,
        base64_image: "",
        label: "",
        done: false,
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        base64_image: "",
        label: "",
        done: false,

        is_active: true,
    });

    const [listDocumentNew, setListDocumentNew] = useState([]);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState("");
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState("");
    // useEffect(() => {
    //     resetComponentProps && setResetComponent(false);
    // }, [resetComponent]);


    useEffect(() => {
        if (listDocument) {
            setListDocumentNew(listDocument);
        }
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
        }
    }, []);

    useEffect(() => {
        if (listDocument) {
            setListDocumentNew(listDocument)
        }
    }, [listDocument]);

    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadDocument();
            }
        }
        submitNewDocument();
    }, [newDocument]);

    useEffect(() => {
        if (documentToBeViewed.base64_image !== "")
            setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (documentId !== "")
            loadDocumentById()
    }, [documentId])

    useEffect(() => {
        if (!showDocumentDetailModal) {
            setDocumentToBeViewed({
                id: 0,
                base64_image: "",
                label: "",
                is_active: true,
            });
          setDocumentId("")
        }
    }, [showDocumentDetailModal]);
    useEffect(() => {
        if (downloadDocumentId !== "")
            downloadData();
    }, [downloadDocumentId])


    const resetUploadForm = () => {
        let newDocument = {
            id: 0,
            base64_image: "",
            file:"",
            label: "",
            is_active: true,
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    const uploadDocument = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                listDocumentNew.push(newDocument);
                handleOnBlurServiceJobDocument(listDocumentNew);
                resetUploadForm();
                setDisabledButton(false);
                setShowDocumentUploadModal(false);
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }

    const loadDocumentById = async()=>{
        try {
            
                let response = await getServiceJobDucmentById(cookies.token, documentId);
                setDocumentToBeViewed(response);
            
            
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadData = async () => {
        try {
            let response = await getServiceJobDucmentById(cookies.token, downloadDocumentId);
            triggerBase64Download(response.base64_image, response.label);
            // setLoading(false);
            setDownloadDocumentId("");
        } catch (exception) {
            console.log(exception);
        }
    }



    // useEffect(()=>{
    //     if(listProject.length>0){
    //         setListProjectNew(listProject);
    //     }
    // },[listProject])




    return (
        <div style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
            flexWrap: "nowrap",
            paddingRight: 5,
        }}>
            <div style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                paddingBottom: 5,
                width: "100%",
                flexWrap: "nowrap",
            }}>
                <ContainerBox 
                containerPos="inner"
                    titleCaption={`Daftar Dokumen`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>

                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setShowDocumentUploadModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Data</div>
                                </div>
                            </Button>
                        </div>


                    }
                    childContent={
                        <div className="master-table-inner-container">

                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Nama Dokumen</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Lihat Dokument</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Download</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listDocumentNew.map((document, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.label}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        // setLoading(true);
                                                        // setVesselDocumentId(document.id)
                                                        if(document.id === 0){
                                                            setDocumentToBeViewed(document);
                                                        }else{
                                                            setDocumentId(document.id)
                                                        }

                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        //    setDownloadDocumentId(document.id);
                                                        if(document.id === 0){
                                                            triggerBase64Download(document.base64_image, document.label);
                                                        }else{
                                                            setDownloadDocumentId(document.id)
                                                        }

                                                    }}><Download />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("vessel_document.delete_confirmation")}  ${document.label}?`)) {
                                                            // let documentId = document.id
                                                            // setDocumentId(documentId);
                                                            // let newList = listDocumentNew
                                                            // newList.splice(index,1)
                                                            // setListDocumentNew(newList);
                                                            // handleOnBlurServiceJobDocument(index)
                                                            handleOnDeleteDocument(index)
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>


                        </div>
                    } />
            </div>


            <Modal show={showDocumentUploadModal}
                // dialogClassName="modal-size"
                size={"lg"}
                onHide={() => {
                    resetUploadForm();
                    setShowDocumentUploadModal(false);
                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>{t("vessel_document.modal_add_document")} </h3>
                    </div>

                    <Form onSubmit={(e) => {
                        e.preventDefault();
                       
                        // setLoading(true);
                    }} style={{ padding: 10 }}>



                        <Form.Group className="mb-3">
                            <Form.Label>Gambar Dokumen</Form.Label>
                            <Form.Control onChange={async (e) => {
                                let imageType = e.target.files[0].type
                                if (imageType.substring(0, 5) === "image") {
                                    let base64Doc = await convertBase64(e.target.files[0]);
                                    setNewDocument({ ...newDocument, base64_image: base64Doc.toString(), file: e.target.value });
                                    setErrorMessage("");
                                    setDisabledButton(false)

                                } else {
                                    setNewDocument({ ...newDocument, base64_image: "", file: "" });
                                    setErrorMessage(`Hanya Bisa File Gambar JPG/PNG`)
                                    setDisabledButton(true)
                                }

                            }} type="file" required></Form.Control>
                            {
                                errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Label</Form.Label>

                            <Form.Control onChange={(e) => {
                                setNewDocument({ ...newDocument, label: e.target.value })
                            }} value={newDocument.label} type="text" placeholder="" required></Form.Control>

                        </Form.Group>



                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            flex: 1,
                        }}>
                            <Button style={{ width: 100 }} variant="primary" onClick={()=>{
                                 setNewDocument({ ...newDocument, done: true });
                                 setDisabledButton(true);
                            }}  disabled={disabledButton}>
                                {t("vessel_document.save_button")}
                            </Button>
                            <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                resetUploadForm();
                                // loadDocumentData();
                                setShowDocumentUploadModal(false);
                            }}>
                                {t("vessel_document.cancel_button")}
                            </Button>
                        </div>
                    </Form>



                </div>

            </Modal>

            <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                setShowDocumentDetailModal(false);
            }}>
                <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setShowDocumentDetailModal(false);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_document.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            <center> <img src={documentToBeViewed.base64_image} style={{ width: "50%" }}></img></center>
                        </div>
                    } />

            </Modal>
        </div>
    )
}



export default ServiceJobDocument;
