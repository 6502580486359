export default function FlexPage() {
    return (
        <>

          
            <div style={{
                backgroundColor: 'white',
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: 'column',
                    width: "100%",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: 'column',
                        width: "100%",
                        flex: 1
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            width: "100%",

                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: 'column',
                                width: "100%",
                                flex: 5,
                                borderStyle: "solid",
                                minHeight: 100,
                                minWidth: 100,
                                borderWidth:10,
                            }}>

                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: 'column',
                                width: "100%",
                                flex: 1,
                                borderStyle: "solid",
                                minHeight: 100,
                                minWidth: 100
                            }}>

                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: 'column',
                                width: "100%",
                                flex: 4,
                                borderStyle: "solid",
                                minHeight: 100,
                                minWidth: 100
                            }}>

                            </div>


                        </div>

                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: 'column',
                        width: "100%",
                        flex: 1
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            width: "100%",
                            flex: 1
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: 'column',
                                width: "100%",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    width: "100%",
                                    flex: 1
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        width: "100%",
                                        flex: 1,
                                        borderStyle: "solid",
                                        minHeight: 100,
                                        minWidth: 100
                                    }}>

                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: 'row',
                                    width: "100%",
                                    flex: 1
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        width: "100%",
                                        flex: 1,
                                        borderStyle: "solid",
                                        minHeight: 100,
                                        minWidth: 100
                                    }}>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        width: "100%",
                                        flex: 1,
                                        borderStyle: "solid",
                                        minHeight: 100,
                                        minWidth: 100
                                    }}>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        width: "100%",
                                        flex: 1,
                                        borderStyle: "solid",
                                        minHeight: 100,
                                        minWidth: 100
                                    }}>

                                    </div>
                                </div>

                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: 'column',
                                width: "100%",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    width: "100%",
                                    flex: 1,
                                    borderStyle: "solid",
                                    minHeight: 100,
                                    minWidth: 100
                                }}>

                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    width: "100%",
                                    flex: 1,
                                    borderStyle: "solid",
                                    minHeight: 100,
                                    minWidth: 100
                                }}>

                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: 'row',
                                    width: "100%",
                                    flex: 1,
                                    borderStyle: "solid",
                                    minHeight: 100,
                                    minWidth: 100
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flex: 1, flexDirection: 'column',

                                        borderStyle: "solid",
                                        minHeight: 100,
                                        minWidth: 100
                                    }}>

                                    </div> <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        width: "100%",
                                        flex: 1,
                                        borderStyle: "solid",
                                        minHeight: 100,
                                        minWidth: 100
                                    }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                     

                    </div>
                </div>
            </div>
        </>
    )
}