import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import {
    getCrewPositions, deleteCrewPositions, getCountCrewPositions, isTokenValid, insertUpdateCrewPosition, getCrewPositionsById,
    getUserAccessById, getCrewType, getCrewDepartment
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";
import FormSelect from "../../../Components/FormSelect";
import { Trans, useTranslation } from "react-i18next";


export default function CrewPositionsPage() {
    const { t, i18n } = useTranslation();
    const [listCrewPositions, setListCrewPositions] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listDepartment, setListDepartment] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [listType, setListType] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [crewPositionsId, setCrewPositionsId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [crewPositionsModal, setCrewPositionsModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_cru_position.order_name1")}`,
        value: "position_name"
    });
    const orderByList = [{
        label: `${t("index_cru_position.order_name1")}`,
        value: "position_name"
    },];
    const [descending, setDescending] = useState(false);
    const [crewPositions, setCrewPositions] = useState({
        id: "0",
        position_name: "",
        department_id: null,
        position_desc: "",
        ext_customer_id: cookies.extCustomerId,
        crew_type_id: ""
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadCrewPositions();
                loadAccess();
                loadDepartment();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadCrewPositions();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewPositions();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewPositions();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadCrewPositions();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCrewPositions()
    }, [descending]);

    useEffect(() => {
        if (selectedDepartment) {
            if (selectedDepartment.department_name) {
                setCrewPositions({ ...crewPositions, department_id: selectedDepartment.id });
            } else {
                setCrewPositions({ ...crewPositions, department_id: null });
            }
        } else {
            setCrewPositions({ ...crewPositions, department_id: null });
        }


    }, [selectedDepartment]);

    useEffect(() => {
        if (crewPositions.department_id) {
            let department = listDepartment.find(p => p.id === crewPositions.department_id);
            if (department) {
                setSelectedDepartment(department);
            }

        }
    }, [crewPositions])

    useEffect(() => {
        if (crewPositionsId !== "" && crewPositionsId !== undefined)
            initCrewPositions();
    }, [crewPositionsId]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeCrewPositions();
    }, [removeId])

    useEffect(() => {
        if (!crewPositionsModal)
            setCrewPositionsId("");
    }, [crewPositionsModal])


    const loadCrewPositions = async () => {
        try {
            let count = await getCountCrewPositions(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);


            let response = await getCrewPositions(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListCrewPositions(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }

    }

    const loadDepartment = async () => {
        try {
            let response = await getCrewDepartment(cookies.token)
            setListDepartment(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const clearCrewPositionsModal = async () => {
        let crewPositionsTmp = {};
        crewPositionsTmp.id = 0;
        crewPositionsTmp.position_name = "";
        crewPositionsTmp.position_desc = "";
        crewPositionsTmp.department_id = null
        setCrewPositions(crewPositionsTmp);
        setSelectedDepartment({})
    }

    const saveData = async () => {
        try {
            // let crewPositionsTmp = crewPositions;
            // crewPositionsTmp.ext_customer_id = crewPositionsTmp.ext_customer_id === null ? null : crewPositionsTmp.ext_customer_id
            let response = await insertUpdateCrewPosition(cookies.token, crewPositions);
            if (response === 0) {
                alert(`${t("index_cru_position.alert_success_save_data")}`);
                setCrewPositionsModal(false);
                clearCrewPositionsModal();
                loadCrewPositions();

            } else {
                alert(`${t("index_cru_position.alert_failed_save_data")}`)
            }
            setDisabledButton(false);

        } catch (exception) {
            console.log(exception)
        }
    }

    const initCrewPositions = async () => {
        try {
            let response = await getCrewPositionsById(cookies.token, crewPositionsId);
            if (response) {
                setCrewPositions({
                    ...crewPositions,
                    id: response.id,
                    position_name: response.position_name,
                    position_desc: response.position_desc,
                    department_id: response.department_id

                })
            }
            setCrewPositionsModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeCrewPositions = async () => {
        try {
            let response = await deleteCrewPositions(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_cru_position.delete_success_alert")}`);
                loadCrewPositions();
            } else {
                alert(`${t("index_cru_position.delete_faield_alert")}`);
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_cru_position.order_name1")}`,
                value: "position_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_cru_position.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setCrewPositionsModal(true);
                                setCrewPositions({ ...crewPositions, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_cru_position.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_cru_position.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadCrewPositions();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadCrewPositions();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadCrewPositions();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_cru_position.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_cru_position.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_cru_position.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_cru_position.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_cru_position.table_name5")}</div>
                                </div>
                                {
                                    listCrewPositions.map((crews, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crews.position_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crews.department_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{crews.position_desc}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idCrews = crews.id;
                                                        setCrewPositionsId(idCrews);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        let idCrews = crews.id;
                                                        if (window.confirm(`${t("index_cru_position.delete_confirmation")} ${crews.position_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idCrews);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />

                <Modal show={crewPositionsModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setCrewPositionsModal(false);
                        clearCrewPositionsModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_cru_position.modal_crew_position")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_cru_position.field_position_name")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setCrewPositions({ ...crewPositions, position_name: e.target.value })
                                }} value={crewPositions.position_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <FormSelect caption={`${t("index_cru_position.field_department")}`} placeholder=""
                                options={listDepartment} value={selectedDepartment} setValueFunction={(e) => {
                                    if (e === null) {
                                        setSelectedDepartment({})
                                    } else {
                                        setSelectedDepartment(e);
                                    }
                                }}
                                getOptionLabel={
                                    (item) => {
                                        return item.department_name;
                                    }
                                } getOptionValue={
                                    (item) => {
                                        return item.id;
                                    }
                                } disabledForm={(cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                            />


                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_cru_position.field_description")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setCrewPositions({ ...crewPositions, position_desc: e.target.value })
                                }} value={crewPositions.position_desc} as="textarea" rows={3} placeholder="" ></Form.Control>
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{width:100}} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_cru_position.save_button")}</Button>
                                <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                    clearCrewPositionsModal();
                                    setCrewPositionsModal(false);
                                }}>{t("index_cru_position.cancel_button")}</Button>
                            </div>

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}