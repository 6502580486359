import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getProject, getSparepartRequisition, getStaff, insertUpdateSparepartReturnRequisition, getSparepartReturnRequisitionById,
    getRequisitionStatus, getSparepartCatalogStock, getSparepartRequisitionDetail, getSparepartRequisitionById, getSparepartHandover, getSparepartHandoverById,
    getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import { BorderWidth, Camera, Trash } from "react-bootstrap-icons";
import Modals from "../../../../Components/modal";
import { CameraFill, FiletypeDocx, FilePlusFill, FiletypePdf, FiletypeXlsx, ArrowLeft, Printer } from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import omit from "lodash"
import FormSelect from "../../../../Components/FormSelect";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function SparepartReturnRequisitionDetailPage() {
    const [projectOptions, setProjectOptions] = useState([]);
    const { t, i18n } = useTranslation();
    const [listSparepartStock, setListSparepartStock] = useState([]);
    const [sparepartCatalogOptions, setSparepartCatalogOptions] = useState([]);
    const [listStatusRequisition, setListStatusRequisition] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [sparepartHandoverId, setSparepartHandoverId] = useState("");
    const [staffOptions, setStaffOptions] = useState([]);
    const [listSparepartHandover, setListSparepartHandover] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const [disabledButtonAdd, setDisableButtonAdd] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [disabledForm, setDisabledForm] = useState(false);
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [selectedProjectType, setSelectedProjectType] = useState("");
    const [sparepartReturnRequisition, setSparepartReturnRequisition] = useState({
        id: 0,
        project_id: null,
        staff_id: null,
        request_date: null,
        status_id: null,
        approved_date: null,
        arrived_date: null,
        is_project: null,
        list_return: [],
        sparepart_handover_id: null,
        ext_customer_id: cookies.extCustomerId
    })
    const barcodeDiv = useRef(null);
    const [barcodeRef, setBarcodeRef] = useState(barcodeDiv);
    const [barcodeState, setBarcodeState] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadProject();
                loadStaff();
                loadAccess();
                loadStatus();
                loadSparepartHandover();
            }
        }
        checkCookies();
        if (location.state.sparepartReturnRequisitionId === 0) {
            setLoading(false);
        } else {
            initSparepartReturnRequisition();
            setDisabledForm(true);
        }
    }, []);

    useEffect(() => {
        if (barcodeState !== "" && sparepartCatalogOptions) {
            let selectedInventoryStock = sparepartCatalogOptions.find(p => p.barcode_number === barcodeState);
            if (selectedInventoryStock) {
                let returns = sparepartReturnRequisition.list_return
                let listReturnTmp = {};
                listReturnTmp.sparepart_catalog_id = selectedInventoryStock.id;
                listReturnTmp.price = selectedInventoryStock.price;

                let newList = sparepartReturnRequisition.list_return.filter(p => p.sparepart_catalog_id === selectedInventoryStock.id)
                if (newList.length === 0) {
                    returns.push(listReturnTmp)
                    setSparepartReturnRequisition({ ...sparepartReturnRequisition, list_return: returns });
                }

            }

            // if (handover.from_division_id !== "") {
            //     let selectedOption = listInventoryStock.find(p => p.barcode_number === barcodeState && p.division_id === selectedFromDivision.id);
            //     if (selectedOption) {
            //         let handoverInv = selectedInventoryStock;
            //         handoverInv.inventory_stock_id = selectedOption.id;
            //         if (listSearchedHandoverItem.length > 0) {
            //             let newList = listSearchedHandoverItem.filter(p=>p.inventory_stock_id === handoverInv.inventory_stock_id)
            //             console.log(newList)
            //             if(newList.length === 0 && showInventoryModal === false){
            //                 setListHandoverItem([...listHandoverItem, handoverInv]);

            //             }
            //             // for (let index = 0; index < listSearchedHandoverItem.length; index++) {
            //             //     const newList = listHandoverItem[index];
            //             //     if (newList.inventory_stock_id !== handoverInv.inventory_stock_id && showInventoryModal === false) {
            //             //         setListHandoverItem([...listHandoverItem, handoverInv]);

            //             //     }

            //             // }
            //         } else {
            //             if(showInventoryModal === false)
            //             setListHandoverItem([...listHandoverItem, handoverInv]);

            //         }


            //     }
            //     let newList = listSearchedHandoverItem.filter(p=>p.inventory_stock_id === selectedOption.id)
            //     if (showInventoryModal === true && newList.length === 0)
            //         setSelectedHandoverDetail(selectedInventoryStock);
            // }

            setBarcodeState("")

        }
    }, [barcodeState]);

    // useEffect(()=>{
    //     if(listHandoverDetail.length === 1)
    //         initHandover();
    // },[listHandoverDetail])

    useEffect(() => {
        if (barcodeState !== "" && sparepartCatalogOptions) {
            let selectedStock = sparepartCatalogOptions.find(p => p.barcode_number === barcodeState);
            setSparepartReturnRequisition({ ...sparepartReturnRequisition, list_return: selectedStock })
        }
    }, [barcodeRef]);

    useEffect(() => {
        // validationForm()
        if (sparepartReturnRequisition.list_return.length === 0) {
            setErrors({
                ...errors,
                listReturnError: `${t("detail_sparepart_return.error_list_sparepart")}`
            },
                setDisabledButton(true))
        }
        else {
            let errorMsg = omit(errors, "listReturnError");
            setErrors(errorMsg);
            // setDisabledButton(false);
        }
        validationForm()

    }, [sparepartReturnRequisition]);

    // useEffect(()=>{
    //     if(listSparepartStock.length > 0){
    //         if (sparepartReturnRequisition.list_return.length > 0) {
    //             for (let index = 0; index < sparepartReturnRequisition.list_return.length; index++) {
    //                 let list = sparepartReturnRequisition.list_return[index];

    //                 let stock = listSparepartStock.find(p => p.id === sparepartReturnRequisition.list_return[index].sparepart_catalog_id);
    //                 console.log(listSparepartStock)
    //                 list.equipment_name = stock.equipment_name
    //                 list.sparepart_catalog_code = stock.sparepart_catalog_code

    //             }
    //         }

    //     }
    // },[listSparepartStock])

    useEffect(() => {
        if (sparepartHandoverId) {
            initSparepartHandover();
            loadSparepartCatalog();
        }
    }, [sparepartHandoverId])


    const loadSparepartHandover = async () => {
        try {
            let response = await getSparepartHandover(cookies.token, cookies.extCustomerId);
            let listNew = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].project_name + ` (${response[i].staff_name})`,
                    id: response[i].id

                };

                listNew.push(obj);

            }
            setListSparepartHandover(listNew)
        } catch (exception) {
            console.log(exception);
        }
    }



    const loadProject = async () => {
        try {
            let response = await getProject(cookies.token, cookies.languageId, cookies.extCustomerId);
            let listProject = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].project_name,
                    id: response[i].id

                };

                listProject.push(obj);

            }
            setProjectOptions(listProject);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadSparepartCatalog = async () => {
        try {
            let response = await getSparepartRequisitionDetail(cookies.token, sparepartHandoverId);
            setListSparepartStock(response);
            let listSparepartCatalog = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].sparepart_catalog_id,
                    label: response[i].equipment_name + ` (${response[i].sparepart_catalog_code})`,
                    id: response[i].sparepart_catalog_id,
                    price: response[i].price,
                    status: response[i].out_stock,
                    barcode_number: response[i].barcode_number

                };

                listSparepartCatalog.push(obj);

            }
            setSparepartCatalogOptions(listSparepartCatalog);
        } catch (exception) {
            console.log(exception);

        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, undefined, undefined, undefined, true, undefined, undefined, 2);
            let listStaff = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].staff_name,
                    id: response[i].id,
                    resign_date: response[i].resign_date

                };

                listStaff.push(obj);

            }
            setStaffOptions(listStaff);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            let sparepartReturnRequisitionTmp = sparepartReturnRequisition;
            sparepartReturnRequisitionTmp.ext_customer_id = sparepartReturnRequisitionTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : sparepartReturnRequisition.ext_customer_id
            let response = await insertUpdateSparepartReturnRequisition(cookies.token, sparepartReturnRequisition);
            if (response.error_code === 0) {
                alert(`${t("detail_sparepart_return.alert_success_save_data")}`);
                navigate('/SparepartReturnRequisition');

            } else {
                alert(`${t("detail_sparepart_return.alert_failed_save_data")}`);
            }

            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initSparepartHandover = async () => {
        try {
            let response = await getSparepartHandoverById(cookies.token, sparepartReturnRequisition.sparepart_handover_id);
            if (response) {
                setSparepartReturnRequisition({
                    ...sparepartReturnRequisition,
                    project_id: response.project_id,
                    staff_id: response.requesting_staff_id,
                    request_date: response.request_date,
                    // status_id: response.status_id,
                    approved_date: response.approved_date,
                    arrived_date: response.arrived_date,
                })
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const initSparepartReturnRequisition = async () => {
        try {
            let response = await getSparepartReturnRequisitionById(cookies.token, location.state.sparepartReturnRequisitionId);
            if (response) {
                setSparepartReturnRequisition({
                    ...sparepartReturnRequisition,
                    id: response[0].id,
                    project_id: response[0].project_id,
                    staff_id: response[0].staff_id,
                    request_date: response[0].request_date,
                    // status_id: response[0].status_id,
                    approved_date: response[0].approved_date,
                    arrived_date: response[0].arrived_date,
                    sparepart_handover_id: response[0].sparepart_handover_id,
                    list_return: response[0].list_return,
                    project_name: response[0].project_name,
                    staff_name: response[0].staff_name,
                    returned_date: response[0].created_date
                })
                setSparepartHandoverId(response[0].sparepart_handover_id)
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getRequisitionStatus(cookies.token);
            setListStatusRequisition(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        console.log(angka);
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const printPageArea = () => {
        //console.log('print');  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("detail_sparepart_return.header_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }


    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (sparepartReturnRequisition.list_return.length > 0) {
                    for (let index = 0; index < sparepartReturnRequisition.list_return.length; index++) {
                        const list = sparepartReturnRequisition.list_return[index];
                        if (list.sparepart_catalog_id === "") {
                            // alert('Total Tidak Boleh Lebih Kecil Dari 0');
                            setErrorMessage(`${t("detail_sparepart_return.error_sprepart_field")}`);
                            setDisabledButton(true);
                            setDisableButtonAdd(true);
                        }
                        else {
                            setErrorMessage("");
                            setDisableButtonAdd(false);
                            setDisabledButton(false);
                        }
                    }
                } else {
                    setErrorMessage("");
                    setDisableButtonAdd(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }


    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_sparepart_return.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/SparepartReturnRequisition"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveData();
                                }}>


                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>
                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_sparepart_return.header_sparepart_return")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            flexWrap: "nowrap",
                                                            paddingRight: 10
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: 1,
                                                                flexWrap: "nowrap",
                                                                paddingRight: 10
                                                            }}>
                                                                <FormSelect caption={`${t("detail_sparepart_return.field_sparepart_handover_form")}`} placeholder=""
                                                                    options={listSparepartHandover} value={listSparepartHandover.filter(function (option) {
                                                                        return option.id === sparepartReturnRequisition.sparepart_handover_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartHandoverId(e.id)
                                                                            setSparepartReturnRequisition({ ...sparepartReturnRequisition, sparepart_handover_id: e.id, list_return: [] });
                                                                        } else {
                                                                            setSparepartHandoverId("")
                                                                            setSparepartReturnRequisition({
                                                                                ...sparepartReturnRequisition,
                                                                                project_id: null,
                                                                                staff_id: null,
                                                                                request_date: null,
                                                                                // status_id: "",
                                                                                approved_date: null,
                                                                                arrived_date: null,
                                                                                is_project: null,
                                                                                list_return: [],
                                                                                sparepart_handover_id: null,
                                                                            });
                                                                        }

                                                                    }} disabledForm={disabledForm}
                                                                />

                                                                <FormSelect caption={`${t("detail_sparepart_return.field_project_name")}`} placeholder=""
                                                                    options={projectOptions} value={projectOptions.filter(function (option) {
                                                                        return option.id === sparepartReturnRequisition.project_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartReturnRequisition({ ...sparepartReturnRequisition, project_id: e.id });
                                                                        } else {
                                                                            setSparepartReturnRequisition({ ...sparepartReturnRequisition, project_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }


                                                                <FormSelect caption={`${t("detail_sparepart_return.field_staff_name")}`} placeholder=""
                                                                    options={staffOptions.filter(p => p.resign_date === null)} value={staffOptions.filter(function (option) {
                                                                        return option.id === sparepartReturnRequisition.staff_id;
                                                                    })} setValueFunction={(e) => {
                                                                        if (e !== null) {
                                                                            setSparepartReturnRequisition({ ...sparepartReturnRequisition, staff_id: e.id });
                                                                        } else {
                                                                            setSparepartReturnRequisition({ ...sparepartReturnRequisition, staff_id: null });
                                                                        }

                                                                    }} disabledForm={true}
                                                                />
                                                                {
                                                                    errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                                                }

                                                                {/* <DropDownForm value={sparepartReturnRequisition.status_id} caption="Status Permintaan" placeholder="Status Permintaan"
                                                                    setValueFunction={(e) => {
                                                                        setSparepartReturnRequisition({ ...sparepartReturnRequisition, status_id: e.target.value });
                                                                    }} listOption={listStatusRequisition} valueKey="id" labelKey="status_name" disabledForm={true} /> */}

                                                                {/* {
                                                                errors.equipmentName && <p style={{ color: "red" }}>{errors.equipmentName}</p>
                                                            } */}




                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                paddingLeft: 10
                                                            }}>
                                                                <TextBox value={sparepartReturnRequisition.request_date ? moment(sparepartReturnRequisition.request_date).format("yyyy-MM-DD") : null} placeholder="Tanggal Request" controlType="date"
                                                                    caption={`${t("detail_sparepart_return.field_request_date")}`} setValueFunction={(e) => {
                                                                        setSparepartReturnRequisition({ ...sparepartReturnRequisition, request_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                                <TextBox value={sparepartReturnRequisition.approved_date ? moment(sparepartReturnRequisition.approved_date).format("yyyy-MM-DD") : null} placeholder="Tanggal Disetujui" controlType="date"
                                                                    caption={`${t("detail_sparepart_return.field_approved_date")}`} setValueFunction={(e) => {
                                                                        setSparepartReturnRequisition({ ...sparepartReturnRequisition, approved_date: e.target.value });
                                                                    }} disabledForm={true} />
                                                                <TextBox value={sparepartReturnRequisition.arrived_date ? moment(sparepartReturnRequisition.arrived_date).format("yyyy-MM-DD") : null} placeholder="Tanggal Barang Sampai" controlType="date"
                                                                    caption={`${t("detail_sparepart_return.field_arrived_date")}`} setValueFunction={(e) => {
                                                                        setSparepartReturnRequisition({ ...sparepartReturnRequisition, arrived_date: e.target.value });
                                                                    }} disabledForm={true} />


                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            }>
                                            </ContainerBox>
                                        </div>
                                    </div>
                                    {
                                        location.state.sparepartReturnRequisitionId !== 0 ?
                                            <Button className="cancel" onClick={printPageArea}  >{t("detail_sparepart_return.button_print")} <Printer /></Button>
                                            :
                                            <></>
                                    }
                                    <div style={{ paddingBottom: 10 }}></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        paddingRight: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            paddingBottom: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                        }}>

                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_sparepart_return.header_sparepart_list")}`} childContent={
                                                <div style={{
                                                    padding: 10,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "row",
                                                            padding: 5
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 3
                                                            }}>{t("detail_sparepart_return.table_name1")}</div>
                                                            {/* <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            flex: 2
                                                        }}>Jumlah</div> */}
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 2
                                                            }}>{t("detail_sparepart_return.table_name2")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 2
                                                            }}>{t("detail_sparepart_return.table_name3")}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                flex: 1
                                                            }}>{t("detail_sparepart_return.table_name4")}</div>
                                                        </div>
                                                        {
                                                            sparepartReturnRequisition.list_return && sparepartReturnRequisition.list_return.map((list, index) => {
                                                                return (
                                                                    <div key={index} style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5,
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 3,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Select placeholder={""}
                                                                                options={sparepartCatalogOptions.filter((p) => {
                                                                                    return !sparepartReturnRequisition.list_return.find((x) => {
                                                                                        return p.id === x.sparepart_catalog_id
                                                                                    }) && p.status == true
                                                                                })} value={sparepartCatalogOptions.filter(function (option) {
                                                                                    return option.id === list.sparepart_catalog_id;
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    let listReturn = sparepartReturnRequisition.list_return;
                                                                                    listReturn[index].sparepart_catalog_id = e.id;
                                                                                    listReturn[index].price = e.price
                                                                                    setSparepartReturnRequisition({ ...sparepartReturnRequisition, list_return: listReturn });
                                                                                }}


                                                                            />
                                                                            {/* <FormSelect placeholder=""
                                                                            options={sparepartCatalogOptions} value={sparepartCatalogOptions.filter(function (option) {
                                                                                return option.id === list.sparepart_catalog_id;
                                                                            })} setValueFunction={(e) => {
                                                                                let listRequisition = sparepartRequisition.list_requisition;
                                                                                listRequisition[index].sparepart_catalog_id = e.id;
                                                                                setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                            }}
                                                                        /> */}

                                                                        </div>
                                                                        {/* <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 2,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <Form.Control onChange={(e) => {
                                                                            let listRequisition = sparepartRequisition.list_requisition;
                                                                            listRequisition[index].quantity = formatCurrency(e.target.value);
                                                                            setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                        }} type="text" value={list.quantity} required placeholder=""></Form.Control>



                                                                    </div> */}
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 2,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Form.Control onChange={(e) => {
                                                                                // let listRequisition = sparepartRequisition.list_requisition;
                                                                                // listRequisition[index].price = formatCurrency(e.target.value);
                                                                                // setSparepartRequisition({ ...sparepartRequisition, list_requisition: listRequisition });
                                                                            }} type="text" value={list.price} required placeholder="" disabled={true}></Form.Control>


                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 2,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Form.Control onChange={(e) => {
                                                                                let listReturn = sparepartReturnRequisition.list_return;
                                                                                listReturn[index].note = e.target.value;
                                                                                setSparepartReturnRequisition({ ...sparepartReturnRequisition, list_return: listReturn });
                                                                            }} type="text" value={list.note} placeholder=""></Form.Control>


                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            flex: 1,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5
                                                                        }}>
                                                                            <Button disabled={!deletedButton} onClick={() => {
                                                                                if (window.confirm(`${t("detail_sparepart_return.delete_confirmation")}`)) {
                                                                                    let list = [...sparepartReturnRequisition.list_return];
                                                                                    list.splice(index, 1);
                                                                                    setSparepartReturnRequisition({ ...sparepartReturnRequisition, list_return: list });
                                                                                }

                                                                            }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        errors.listReturnError && <p style={{ color: "red" }}>{errors.listReturnError}</p>
                                                    }
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }


                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        padding: 5
                                                    }}>
                                                        <Form.Control hidden className="barcode-input" id="barcode-input" onChangeCapture={(e) => {
                                                            // console.log("FROM React", e);
                                                        }} onChange={(e) => {
                                                            setBarcodeState(e.target.value);
                                                            // console.log("FROM React", e.target.value);

                                                        }} value={barcodeState} type="text" disabled placeholder="Barcode" />
                                                        <Button disabled={disabledButtonAdd === true ? disabledButtonAdd : !updatedButton} variant="primary" onClick={() => {
                                                            let newParameter = {
                                                                id: "",
                                                                sparepart_catalog_id: "",
                                                                quantity: null,
                                                                price: "",
                                                                note: ""
                                                            };
                                                            if (!sparepartReturnRequisition.list_return) {
                                                                let listReturn = [];
                                                                listReturn.push(newParameter);
                                                                setSparepartReturnRequisition({ ...sparepartReturnRequisition, list_return: listReturn });
                                                            } else {
                                                                let listReturn = sparepartReturnRequisition.list_return;
                                                                listReturn.push(newParameter);
                                                                setSparepartReturnRequisition({ ...sparepartReturnRequisition, list_return: listReturn });
                                                            }

                                                        }}>{t("detail_sparepart_return.button_add_sparepart")}</Button>
                                                    </div>
                                                </div>

                                            }>
                                            </ContainerBox>


                                        </div>
                                    </div>

                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                padding: 10,
                                                width: "100%",
                                                justifyContent: "center",
                                                fontWeight: "bold"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    paddingRight: 5,
                                                }}>
                                                    <Button style={{width:100}} className="save-button" disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit">{t("detail_sparepart_return.save_button")}</Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }}>
                                                    <Button style={{width:100}} className="cancel-button" onClick={() => {
                                                        navigate("/SparepartReturnRequisition")
                                                    }} variant="danger">{t("detail_sparepart_return.cancel_button")}</Button>
                                                </div>
                                            </div>
                                    }



                                </Form>



                            </div>

                           
                        </div>

                    }>
                </ContainerBox>

                <div id="printableArea" style={{ display: 'none' }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ float: 'left', width: '70%' }}>
                        <img src={require("../../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>
                    <div style={{ float: 'right', width: '30%', marginTop: '60px', marginBottom: '80px' }}>
                        <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">PT.Radian Peninsula Indoneia</p>
                        {/* <p style={{ margin: '0', fontSize: '10px' }} className="text-danger">No.Dok : {returns.numberForm}</p> */}
                    </div>
                    <div style={{
                        marginLeft: 300,
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >{t("detail_sparepart_return.print_title")} </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%' }}>
                        <p style={{ margin: '0' }} className="text-danger">{t("detail_sparepart_return.field_project_name")} :  {sparepartReturnRequisition.project_name}</p>
                        <p style={{ margin: '0' }} className="text-danger">{t("detail_sparepart_return.field_staff_name")}  :  {sparepartReturnRequisition.staff_name}</p>
                    </div>
                    <div style={{ float: 'right', width: '35%', marginBottom: '20px' }}>
                        <p style={{ margin: '0' }} className="text-danger">{t("detail_sparepart_return.text_return_date")} :  {moment(sparepartReturnRequisition.returned_date).format("DD-MMM-yyyy")}</p>
                        {/* <p style={{ margin: '0' }} className="text-danger">NO&nbsp;&nbsp;&nbsp;:  {returns.form_number}</p> */}
                    </div>


                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr>
                            <td>No </td>
                            <td>{t("detail_sparepart_return.table_name1")} </td>
                            <td>{t("detail_sparepart_return.field_sparepart_catalogue_code")} </td>
                            <td>{t("detail_sparepart_return.table_name2")}  </td>
                            <td>{t("detail_sparepart_return.table_name3")} </td>
                        </tr>
                        {sparepartReturnRequisition.list_return && sparepartReturnRequisition.list_return.map((listReturn, index) => {
                            return (
                                <tr key={index} className="table-caption-body">
                                    <td>{index + 1}</td>
                                    <td>{listReturn.title}</td>
                                    <td>{listReturn.sparepart_catalog_code}</td>
                                    <td>{listReturn.price}</td>
                                    <td>{listReturn.note}</td>
                                    {/* <td>{listReturn.return_inventory_description}</td> */}
                                </tr>
                            )
                        })
                        }


                    </table>
                    {/* <div style={{ float: 'left', width: '30%', marginTop: '30px', marginLeft: '30px' }}>
                        <span >&nbsp;&nbsp;&nbsp;Peminjam</span>
                        <p style={{ marginTop: '100px' }} >{returns.staff_givenbyname}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'left', width: '30%', marginTop: '30px' }}>
                        <span >&nbsp;&nbsp;&nbsp;Diketahui oleh, </span>
                        <p style={{ marginTop: '100px' }} >{returns.staff_knownbyname}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div>
                    <div style={{ float: 'left', width: '30%', marginTop: '30px' }}>
                        <span >&nbsp;&nbsp;&nbsp;Diterima oleh,</span>
                        <p style={{ marginTop: '100px' }} >{returns.staff_receivedbyname}</p>
                        <hr width="150px"
                            size="1"
                            align="left"
                        // heigh="10"
                        />
                    </div> */}

                </div>
            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    );

}