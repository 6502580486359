import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, insertUpdateLeaveAllowance, getLeaveAllowanceById, getStaff, getUserAccessById, convertBase64Image } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import Select from "react-select";

export default function LeaveAllowanceDetailPage() {
    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState({});
    const [listStaff, setListStaff] = useState([]);
    const [allowanceDetail, setAllowanceDetail] = useState({
        id: 0,
        staff_id: null,
        annual_leave_allowance: 0,
        sick_leave_allowance: 0,
        maternity_leave_allowance: 0
    })
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAccess();
                loadStaff();
            }
        }
        checkCookies();
        if (location.state.allowanceId === 0) {
            setLoading(false);
        } else {
            initLeaveAllowance();
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [allowanceDetail]);

    useEffect(() => {
        if (selectedStaff.staff_name) {
            setAllowanceDetail({ ...allowanceDetail, staff_id: selectedStaff.id });
        } else {
            setAllowanceDetail({ ...allowanceDetail, staff_id: null });
        }
    }, [selectedStaff]);

    useEffect(() => {
        if (allowanceDetail.staff_id) {
            let response = listStaff.find(p => p.id === allowanceDetail.staff_id);
            if (response) {
                setSelectedStaff(response);
            }

        }
    }, [allowanceDetail])

    useEffect(()=>{
        if(listStaff.length>0){
            if (allowanceDetail.staff_id) {
                let response = listStaff.find(p => p.id === allowanceDetail.staff_id);
                if (response) {
                    setSelectedStaff(response);
                }
    
            }
        }
    },[listStaff])


    const saveData = async () => {
        try {
            let response = await insertUpdateLeaveAllowance(cookies.token, allowanceDetail);
            if (response === 0) {
                alert("Berhasil Menyimpan Data");
                navigate("/LeaveAllowance")
            } else {
                alert("Gagal Menyimpan Data")
            }
            setDisabledButton(false);
        } catch (exception) {
            setErrorMessage("Data Gagal Disimpan");
        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListStaff(response);
        } catch (exception) {

        }
    }

    const initLeaveAllowance = async () => {
        try {
            let response = await getLeaveAllowanceById(cookies.token, location.state.allowanceId);
            console.log(response);
            if (response) {
                setAllowanceDetail({
                    ...allowanceDetail,
                    staff_id: response.staff_id,
                    annual_leave_allowance: response.annual_leave_allowance,
                    sick_leave_allowance: response.sick_leave_allowance,
                    maternity_leave_allowance: response.maternity_leave_allowance
                })
                setLoading(false);
            } else {
                alert('Terjadi Kesalahan Pada Sistem');
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (allowanceDetail.staff_id === undefined || allowanceDetail.staff_id === null) {
                   setErrorMessage("*Harus Diisi");
                   setDisabledButton(true)
                }
                else {
                    setErrorMessage("");
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                // console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={"Detail Jatah Cuti Staf"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/LeaveAllowance"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Jatah Cuti"} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    saveData();
                                                    setDisabledButton(true);
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Nama Staff</Form.Label>


                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.staff_name;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listStaff} value={selectedStaff} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedStaff({})
                                                                } else {
                                                                    setSelectedStaff(e);
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                        {
                                                            errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                        }
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Jatah Cuti Tahunan</Form.Label>
                                                        <Form.Control type="number" onChange={(e) => {
                                                            if(e.target.value >= 0){
                                                                setAllowanceDetail({ ...allowanceDetail, annual_leave_allowance: e.target.value })
                                                            }
                                                           
                                                        }} value={allowanceDetail.annual_leave_allowance}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Jatah Cuti Sakit</Form.Label>
                                                        <Form.Control type="number" onChange={(e) => {
                                                            if(e.target.value >= 0){
                                                                setAllowanceDetail({ ...allowanceDetail, sick_leave_allowance: e.target.value })
                                                            }
                                                            
                                                        }} value={allowanceDetail.sick_leave_allowance}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Jatah Cuti Melahirkan</Form.Label>
                                                        <Form.Control type="number" onChange={(e) => {
                                                            if(e.target.value >= 0){
                                                                setAllowanceDetail({ ...allowanceDetail, maternity_leave_allowance: e.target.value })
                                                            }
                                                           
                                                        }} value={allowanceDetail.maternity_leave_allowance}></Form.Control>
                                                    </Form.Group>
                                                    <div style={{ paddingBottom:20}}></div>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "row",
                                                        padding: 5,
                                                        justifyContent: "center"
                                                    }}>
                                                        <div style={{
                                                            paddingRight: 5,
                                                        }}><Button disabled={disabledButton} type="submit" variant="primary" >Simpan</Button></div>


                                                        <div style={{
                                                            paddingLeft: 5,
                                                        }}>
                                                            <Button variant="danger" onClick={() => {
                                                                navigate('/LeaveAllowance');
                                                            }}>Batal</Button>

                                                        </div>
                                                    </div>
                                                </Form>

                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>


                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}