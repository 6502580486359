import React, { useState, useEffect, Fragment, useRef } from "react";
import { Button, Form, Container, Col, Row, Table, Modal, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import Select from 'react-select';
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import {
    isTokenValid, createStaff, getStaffByid, getGender, getStaffPosition, convertBase64,
    getSpecializaton, getStaffxSpecializationById, getUser, insertUpdateUser, getUserRole,
    getUserById, deleteUserAccount, checkUsername, getBank, getMaritalStatus, getBloodType,
    getEmployeeStatus, getReligion, getPosition, getDivision, getDivisionById, getStaffDocument,
    insertUpdateStaffDocument, deleteStaffDocument, getStaffInventory, getStaffDocumentById, getDocumentStaffById,
    getUserAccessById, getCustomer, getCrewPositions, getCrewScheduleById, getCrewSchedule, getCountCrewSchedule,
    insertUpdateCrewSchedule, getVessel, deleteCrewSchedule, getCrewRank, getCrewType, getCrewRankByType,
    getCrewDocumentCategory, getCrewDepartment, getCrewPositionByDepartment, getVesselScheduleForCrew,
    getDocumentCategoryTechnician, getContractStaffHistory, getBusinessUnit
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import AvatarIcon from "../../../../Assets/Images/default.jpg"
import {
    EyeFill, PencilFill, Trash, ArrowLeft, Download, FilePlusFill, FileCheckFill, XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise, XSquare,
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import TextBox from "../../../../Components/Textbox";
import Loading from "../../../../Components/Loading";
import DropDownForm from "../../../../Components/DropDownForm";
import FormSelect from "../../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
import moment from 'moment';
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";


export default function StaffDetailPage() {
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [listCategoryDocument, setListCategoryDocument] = useState([])
    const [listType, setListType] = useState([]);
    const [roleName, setRoleName] = useState("");
    const [typeId, setTypeId] = useState("");
    const [listCrewSchedule, setListCrewSchedule] = useState([]);
    const [listDocumentCategoryTechnician, setListDocumentCategoryTechnician] = useState([]);
    const location = useLocation();
    const [listGender, setListGender] = useState([]);
    const [listStaffPosition, setListStaffPosition] = useState([]);
    const [staffPositionOptions, setStaffPositionOptions] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [listBusinessUnit, setListBusinessUnit] = useState([]);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState({});
    const [crewScheduleId, setCrewScheduleId] = useState("");
    const [selectedBpjs, setSelectedBpjs] = useState("");
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [errorVessel, setErrorVessel] = useState("");
    const [errorCrewPosition, setErrorCrewPosition] = useState("");
    const [removeScheduleId, setRemoveScheduleId] = useState("");
    const [saveButtonModalDisabled, setSaveButtonModalDisabled] = useState(false);
    const [listSpecialization, setListSpecialization] = useState([]);
    const [downloadDocumentId, setDownloadDocumentId] = useState("");
    const [updateDocumentId, setUpdateDocumentId] = useState("");
    const [crewScheduleModal, setCrewScheduleModal] = useState("");
    const [errorStartDate, setErrorStartDate] = useState("");
    const [errorEndDate, setErrorEndDate] = useState("");
    const [listUserRole, setListUserRole] = useState([]);
    const [formHidden, setFormHidden] = useState(true);
    const [formFieldStaff, setFormFieldStaff] = useState(true);
    const [staffxSpecialization, setStaffxSpecialization] = useState([]);
    const [listAccount, setListAccount] = useState([]);
    const [errors, setErrors] = useState({});
    const [userId, setUserId] = useState("");
    const [userIdRemove, setUserIdRemove] = useState("");
    const [isAllowLoginChecked, setIsAllowLoginChecked] = useState(false);
    const [isFieldStaffChecked, setIsFieldStaffChecked] = useState(false);
    const [listCustomer, setListCustomer] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [listStaffInventory, setListStaffInventory] = useState([]);
    const [listBank, setListBank] = useState([]);
    const [listBloodType, setListBloodType] = useState([]);
    const [listPosition, setListPosition] = useState([]);
    const [listEmployeeStatus, setListEmployeeStatus] = useState([]);
    const [listMaritalStatus, setListMaritalStatus] = useState([]);
    const [listReligion, setListReligion] = useState([]);
    const [disabledPosition, setDisablePosition] = useState(false);
    const [selectedReligion, setSelectedReligion] = useState({ id: "" });
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedBank, setSelectedBank] = useState({ id: "" });
    const [selectedPosition, setSelectedPosition] = useState({ id: "" });
    const [selectedCrewPosition, setSelectedCrewPosition] = useState({});
    const [selectedVessel, setSelectedVessel] = useState({});
    const [listVessel, setListVessel] = useState([]);
    const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState({ id: "" });
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState({ id: "" });
    const [selectedBloodType, setSelectedBloodType] = useState({ id: "" });
    const [staffId, setStaffId] = useState("");
    const [listDivision, setListDivision] = useState([]);
    const [listDepartment, setListDepartment] = useState([]);
    const [listCrewDepartment, setListCrewDepartment] = useState([]);
    const [staffNameError, setStaffNameError] = useState("");
    const [staffGenderError, setStaffGenderError] = useState("");
    const [staffBirthDateError, setStaffBirthDateError] = useState("");
    const [selectedDivision, setSelectedDivision] = useState({ id: "" });
    const [searchQuery, setSearchQuery] = useState("");
    const [searchInventory, setSearchInventory] = useState("");
    const [listDocument, setListDocument] = useState([]);
    const [staffDocumentId, setStaffDocumentId] = useState("");
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [disabledButtonCrew, setDisableButtonCrew] = useState(false);
    const [disabledFormCustomer, setDisabledFormCustomer] = useState(false);
    const [hiddenRate, setHiddenRate] = useState(true);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [searchSchedule, setSearchSchedule] = useState("");
    const [resetSearchSchedule, setResetSearchSchedule] = useState(0);
    const [zoomFactor, setZoomFactor] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [divisionRequired, setDivisionRequired] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [descending, setDescending] = useState(true);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Dokumen",
        value: "document.document_name"
    });
    const orderByList = [{
        label: "Nama Dokumen",
        value: "document.document_name"
    }];

    const [pageCrewSchedule, setPageCrewSchedule] = useState(0);
    const [itemPerPageCrewSchedule, setItemPerPageCrewSchedule] = useState(50);
    const [totalPageCrewSchedule, setTotalPageCrewSchedule] = useState(0);
    const [descendingCrewSchedule, setDescendingCrewSchedule] = useState(true);
    const [itemPerPageSelectionCrewSchedule, setItemPerPageSelectionCrewSchedule] = useState([5, 10, 15, 20, 50]);
    const [orderByCrewSchedule, setOrderByCrewSchedule] = useState({
        label: "Nama Kapal",
        value: "vessel.vessel_name"
    });
    const orderByListCrewSchedule = [{
        label: "Nama Kapal",
        value: "vessel.vessel_name"
    }];
    const [selectedDepartment, setSelectedDepartment] = useState({});

    const [pageInventory, setPageInventory] = useState(0);
    const [listCrewPositions, setListCrewPositions] = useState([]);
    const [itemPerPageInventory, setItemPerPageInventory] = useState(50);
    const [totalPageInventory, setTotalPageInventory] = useState(0);
    const [descendingInventory, setDescendingInventory] = useState(true);
    const [itemPerPageSelectionInventory, setItemPerPageSelectionInventory] = useState([5, 10, 15, 20, 50]);
    const [listContractHistory, setListContractHistory] = useState([]);
    const [contractHistoryModal, setContractHistoryModal] = useState("");
    const [orderByInventory, setOrderByInventory] = useState({
        label: "Nama Barang",
        value: "inventoryMaster.inventory_master_name"
    });
    const orderByListInventory = [{
        label: "Nama Barang",
        value: "inventoryMaster.inventory_master_name"
    }];


    const [newDocument, setNewDocument] = useState({
        id: 0,
        staff_id: "",
        document_base64: "",
        document_name: "",
        description: "",
        document_category_id: null,
        effective_date: null,
        expired_date: null,
        is_active: true,
        done: false,
        reference_number: "",
        is_vaccine_covid: false,
        vaccine_covid_description: null,
        note: "",
        technician_document_category_id: null
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        staff_id: "",
        document_base64: "",
        document_name: "",
        description: "",
        document_category_id: null,
        reference_number: "",
        is_active: true,
    });

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            staff_id: location.state.staffId,
            document_base64: "",
            document_name: "",
            document_category_id: null,
            description: "",
            reference_number: "",
            is_active: true,
            done: false,
            effective_date: null,
            expired_date: null,
            is_vaccine_covid: false,
            vaccine_covid_description: null,
            note: "",
            technician_document_category_id: null
        };
        setNewDocument({ ...newDocument, newDocument });
        setSelectedCategory({})
    }
    const [documentFile, setDocumentFile] = useState("");


    const [staff, setStaff] = useState({
        staff_id: 0,
        staff_name: "",
        employee_number: "",
        date_of_birth: "",
        crew_type_id: null,
        place_of_birth: "",
        address: "",
        phone_number_1: "",
        phone_number_2: "",
        email: "",
        gender: "",
        position_id: null,
        file: "",
        photo_base64: "",
        special: [],
        division_id: null,
        department_id: null,
        staff_position_id: null,
        employee_status: null,
        join_date: null,
        resign_date: null,
        resign_reason: "",
        npwp_number: "",
        npwp_address: "",
        kpp: "",
        grade: "",
        poin_of_hire: "",
        workarea: "",
        worklegth: "",
        bpjs_tk_number: "",
        bpjs_ks_number: "",
        bpjs_pensiun: "",
        ptkp: "",
        ptkp_next_year: "",
        tax: "",
        marital_status_id: null,
        kk_number: "",
        ktp_number: "",
        ktp_address: "",
        mothers_name: "",
        overtime: "",
        bank_branch: "",
        bank_city: "",
        bank_account_name: "",
        bank_account_number: "",
        note_1: "",
        note_2: "",
        clothes_size: "",
        religion_id: null,
        bank_id: null,
        blood_type_id: null,
        rate: "",
        ext_customer_id: cookies.extCustomerId,
        is_crew: window.location.pathname === "/Crew/Detail" ? true : false,
        sailor_registration_number: "",
        language_id: cookies.languageId,
        number_of_children: null
    });

    const [crewSchedule, setCrewSchedule] = useState({
        id: 0,
        vessel_id: null,
        crew_id: staffId,
        department_id: null,
        start_date: null,
        end_date: null,
        crew_positions_id: null,
        ext_customer_id: staff.ext_customer_id
    })

    const resetCrewSchedule = async () => {
        let crewScheduleTmp = {};
        crewScheduleTmp.id = 0;
        crewScheduleTmp.vessel_id = null;
        crewScheduleTmp.department_id = null;
        crewScheduleTmp.crew_id = staffId;
        crewScheduleTmp.start_date = null;
        crewScheduleTmp.end_date = null;
        crewScheduleTmp.crew_positions_id = null;
        crewScheduleTmp.ext_customer_id = staff.ext_customer_id

        setCrewSchedule(crewScheduleTmp);
        // setSelectedCrewPosition({});
        // setSelectedVessel({});
        // setSelectedDepartment({})
    }

    useEffect(() => {
        if (listCrewPositions.length > 0) {
            if (crewSchedule.crew_positions_id !== null && crewSchedule.crew_positions_id !== "") {
                let crewPositionTmp = listCrewPositions.find(p => p.id === crewSchedule.crew_positions_id);
                if (crewPositionTmp) {
                    setSelectedCrewPosition(crewPositionTmp)
                }
            } else {

                setSelectedCrewPosition({})
            }
        }
    }, [listCrewPositions, crewSchedule.crew_positions_id])

    useEffect(() => {
        if (crewSchedule.id !== 0) {
            if (crewSchedule.department_id) {
                let department = listCrewDepartment.find(p => p.id === crewSchedule.department_id);
                if (department) {
                    setSelectedDepartment(department);
                }

            }
            if (crewSchedule.crew_positions_id) {
                let crewPositionTmp = listCrewPositions.find(p => p.id === crewSchedule.crew_positions_id);
                if (crewPositionTmp) {
                    setSelectedCrewPosition(crewPositionTmp)
                }
            }
            if (crewSchedule.vessel_id) {
                let vesselTmp = listVessel.find(p => p.id === crewSchedule.vessel_id);
                if (vesselTmp) {
                    setSelectedVessel(vesselTmp)
                }
            }
        }


        if (crewSchedule.vessel_id === null || crewSchedule === undefined) {
            setErrorVessel(`${t("staff_detail_page.error_empty_field")}`);
            setDisableButtonCrew(true)
        } else {
            setErrorVessel("")
        }

        // if (crewSchedule.crew_positions_id === null || crewSchedule === undefined) {
        //     setErrorCrewPosition("*Harus Diisi");
        //     setDisableButtonCrew(true)
        // } else {
        //     setErrorCrewPosition("")
        // }

        if (crewSchedule.vessel_id !== null && crewSchedule.vessel_id !== undefined) {
            setDisableButtonCrew(false);
        }


    }, [crewSchedule])

    useEffect(() => {
        console.log(staff);
    }, [staff])

    useEffect(() => {
        if (window.location.pathname === "/Crew/Detail") {
            if (typeId !== "" && typeId !== null) {
                loadPositionData();
            } else {
                setListPosition([])
            }
        }


    }, [typeId])

    // useEffect(() => {
    //     if (listContractHistory.length > 0) {
    //         // let listContract = []
    //         // for (let index = 0; index < listContractHistory.length; index++) {
    //         //     const newList = listContractHistory[index];
    //         //     let totalDate = null;
    //         //     if (newList.start_date !== null && newList.end_date !== null) {
    //         //         let startDate = new Date(newList.start_date);
    //         //         let endDate = new Date(newList.end_date);
    //         //         let diffYear = endDate.getFullYear() - startDate.getFullYear();
    //         //         let diffMonth = endDate.getMonth() - startDate.getMonth();
    //         //         totalDate = diffYear * 12 + diffMonth;
    //         //     }
    //         //     newList.contract_duration = totalDate;
    //         //     listContract.push(newList)
    //         // }
    //         // setListContractHistory(listContract.slice(0));

    //     }
    // }, [listContractHistory])

    useEffect(() => {
        if (listContractHistory.length > 0) {
            const updatedListContract = listContractHistory.map(contract => {
                if (contract.start_date !== null && contract.end_date !== null) {
                    const startDate = new Date(contract.start_date);
                    const endDate = new Date(contract.end_date);
                    const diffYear = endDate.getFullYear() - startDate.getFullYear();
                    const diffMonth = endDate.getMonth() - startDate.getMonth();
                    const totalDate = diffYear * 12 + diffMonth;
                    return {...contract, contract_duration: totalDate};
                } else {
                    return contract;
                }
            });
            if (JSON.stringify(updatedListContract) !== JSON.stringify(listContractHistory)) {
                setListContractHistory(updatedListContract);
            }
        }
    }, [listContractHistory]); 
    

    const [user, setUser] = useState({
        id: 0,
        login_username: "",
        login_password: "",
        login_password_confirmation: "",
        email: "",
        role_id: "",
        language_id: "",
        staff_id: "",
    });

    const [fileImg, setFileImg] = useState("");

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    useEffect(() => {
        if (updateDocumentId !== "") {
            initDocument();
        }
    }, [updateDocumentId])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadGender();
                loadStaffPosition();
                loadCustomer();
                loadDocumentCategoryTechnician();
                loadBusinessUnit();
                loadUserRole();
                loadStaffSpecialization();
                loadStaffxSpecialization();
                loadReligionData();
                loadBankData();
                loadEployeeStatusData();
                loadCrewType();
                loadMaritalStatusData();
                loadDocumentCategory();
                loadCrewDepartment();
                loadPositionData();
                loadBloodTypeData();
                loadDivision();
                setStaffId(location.state.staffId);
                setLoading(false);
                loadAccess();
                if (location.state.pathname === "/Mechanic") {
                    setIsFieldStaffChecked(true);
                    setStaff({ ...staff, is_field_staff: "true" });
                    setFormFieldStaff(false);
                }
                if (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") {
                    setDisabledFormCustomer(true);
                }

                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
        // if (location.state.staffId === 0) {
        //     setLoading(false);
        // } else {

        // }
    }, []);

    useEffect(() => {
        if (staffId !== 0 && staffId !== "")
            loadDocumentData();
    }, [page]);

    useEffect(() => {
        if (staffId !== 0 && staffId !== "")
            loadDocumentData();
    }, [itemPerPage]);

    useEffect(() => {
        if (staffId !== 0 && staffId !== "")
            loadDocumentData();
    }, [orderBy]);

    useEffect(() => {
        if (staffId !== 0 && staffId !== "") {
            setLoading(false);
            loadDocumentData()
        }

    }, [descending]);

    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadDocument();
            }
        }
        submitNewDocument();

        if (newDocument.document_category_id) {
            let category = listCategoryDocument.find(p => p.id === newDocument.document_category_id);

            if (category) {
                setSelectedCategory(category);
            }

        }
    }, [newDocument]);

    useEffect(() => {
        if (selectedCustomer) {
            setStaff({ ...staff, ext_customer_id: selectedCustomer.id })
        } else {
            setStaff({ ...staff, ext_customer_id: null })
        }
    }, [selectedCustomer])

    useEffect(() => {
        if (staffId !== 0 && staffId !== "") {
            setNewDocument({ ...newDocument, staff_id: staffId });
            initStaff();
            loadStaffAccount();
            loadStaffInventory();
            if (window.location.pathname === "/Crew/Detail") {
                loadCrewSchedule();
                loadVessel();
            }

        }
    }, [staffId])

    useEffect(() => {
        if (userId !== "")
            loadUserByid();
    }, [userId]);

    useEffect(() => {
        if (userIdRemove !== "")
            removeUser();
    }, [userIdRemove]);

    useEffect(() => {
        setLoading(true);
        if (documentId !== "")
            removeDocument();
    }, [documentId]);

    useEffect(() => {
        if (staffDocumentId !== "")
            loadDocumentById();
    }, [staffDocumentId])

    useEffect(() => {
        if (user.login_username !== "")
            checkUsernames();
    }, [user.login_username]);

    useEffect(() => {
        if (staffId !== 0 && staffId !== "")
            loadCrewSchedule();
    }, [pageCrewSchedule]);

    useEffect(() => {
        if (staffId !== 0 && staffId !== "" && isSearched !== 0)
            loadCrewSchedule();
    }, [itemPerPageCrewSchedule]);

    useEffect(() => {
        if (staffId !== 0 && staffId !== "" && isSearched !== 0)
            loadCrewSchedule();
    }, [orderByCrewSchedule]);

    useEffect(() => {
        if (staffId !== 0 && staffId !== "" && isSearched !== 0) {
            setLoading(false);
            loadCrewSchedule();
        }

    }, [descendingCrewSchedule]);

    useEffect(() => {
        if (resetSearchSchedule !== 0)
            loadCrewSchedule();
    }, [resetSearchSchedule]);

    useEffect(() => {
        if (removeScheduleId !== "")
            removeSchedule();
    }, [removeScheduleId])

    useEffect(() => {
        if (location.state.staffId !== 0) {
            if (staff.religion_id) {
                let religion = listReligion.find(p => p.id === staff.religion_id);
                if (religion) {
                    setSelectedReligion(religion);
                }

            }
            if (staff.blood_type_id && listBloodType.length > 0) {
                let bloodType = listBloodType.find(p => p.id === staff.blood_type_id);
                if (bloodType) {
                    setSelectedBloodType(bloodType);
                }

            }
            if (staff.employee_status) {
                let employeeStatus = listEmployeeStatus.find(p => p.id === staff.employee_status);
                if (employeeStatus) {
                    setSelectedEmployeeStatus(employeeStatus);
                }

                let status = listEmployeeStatus.find(p => p.id === staff.employee_status);
                if (status) {
                    if (status.employee_status_name === "Temporary") {
                        setHiddenRate(false);
                    } else {
                        setHiddenRate(true);
                    }
                }

            }
            if (staff.marital_status_id) {
                let maritalStatus = listMaritalStatus.find(p => p.id === staff.marital_status_id);
                if (maritalStatus) {
                    setSelectedMaritalStatus(maritalStatus);
                }

            }
            if (staff.staff_position_id) {
                let position = listPosition.find(p => p.id === staff.staff_position_id);
                if (position) {
                    setSelectedPosition(position);
                }

            }
            if (staff.bank_id) {
                let bank = listBank.find(p => p.id === staff.bank_id);
                if (bank) {
                    setSelectedBank(bank);
                }

            }
            if (staff.business_unit_id) {
                let unitBusiness = listBusinessUnit.find(p => p.id === staff.business_unit_id);
                if (unitBusiness) {
                    setSelectedBusinessUnit(unitBusiness);
                }

            }
            if (staff.division_id) {
                let division = listDivision.find(p => p.id === staff.division_id);
                if (division) {
                    setSelectedDivision(division);
                }

            }
            if (staff.ext_customer_id) {
                let customer = listCustomer.find(p => p.id === staff.ext_customer_id);
                if (customer) {
                    setSelectedCustomer(customer);
                }

            }

        }

        if (staff.staff_name === undefined || staff.staff_name === "") {
            setDisabledButton(true);
            setStaffNameError(`${t("staff_detail_page.error_empty_field")}`);
            // setDisabledButton(true);

        } else {
            setStaffNameError("");

        }

        if (staff.gender === undefined || staff.gender === "") {
            setStaffGenderError(`${t("staff_detail_page.error_empty_field")}`)
            setDisabledButton(true)
        } else {
            setStaffGenderError("");
        }

        if (staff.date_of_birth === undefined || staff.date_of_birth === "") {
            setStaffBirthDateError(`${t("staff_detail_page.error_empty_field")}`);
            setDisabledButton(true)
        } else {
            setStaffBirthDateError("");
            // setDisabledButton(false);
        }

        if ((staff.division_id === undefined || staff.division_id === null) && window.location.pathname !== "/Crew/Detail") {
            setDisabledButton(true);
            setDivisionRequired(`${t("staff_detail_page.error_empty_field")}`);
            // setDisabledButton(true);

        } else {
            setDivisionRequired("");

        }



        validationForm();
    }, [staff])

    useEffect(() => {
        if (selectedReligion) {
            if (selectedReligion.religion_name) {
                setStaff({ ...staff, religion_id: selectedReligion.id });
            } else {
                setStaff({ ...staff, religion_id: null });
            }
        } else {
            setStaff({ ...staff, religion_id: null });
        }


    }, [selectedReligion]);


    useEffect(() => {
        // if (selectedDepartment) {
        if (selectedDepartment.department_name) {
            setCrewSchedule({ ...crewSchedule, department_id: selectedDepartment.id });
            loadCrewPositions();

        } else {
            setCrewSchedule({ ...crewSchedule, department_id: null, crew_positions_id: null });
        }
        // } 
        // else {
        //     setCrewSchedule({ ...crewSchedule, department_id: "null" });
        // }


    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedCategory) {
            if (selectedCategory.category_name) {
                // setStaff({ ...staff, religion_id: selectedCategory.id });
                setNewDocument({ ...newDocument, document_category_id: selectedCategory.id })
            } else {
                setNewDocument({ ...newDocument, document_category_id: null })
            }
        } else {
            setNewDocument({ ...newDocument, document_category_id: null.id })
        }


    }, [selectedCategory]);



    useEffect(() => {
        if (selectedMaritalStatus) {
            if (selectedMaritalStatus.marital_status_name) {
                setStaff({ ...staff, marital_status_id: selectedMaritalStatus.id });
            } else {
                setStaff({ ...staff, marital_status_id: null });
            }
        } else {
            setStaff({ ...staff, marital_status_id: null });
        }


    }, [selectedMaritalStatus]);

    useEffect(() => {
        if (selectedBloodType) {
            if (selectedBloodType.blood_type_name) {
                setStaff({ ...staff, blood_type_id: selectedBloodType.id });
            } else {
                setStaff({ ...staff, blood_type_id: null });
            }
        } else {
            setStaff({ ...staff, blood_type_id: null });
        }

    }, [selectedBloodType]);

    useEffect(() => {
        if (selectedEmployeeStatus) {
            if (selectedEmployeeStatus.employee_status_name) {
                setStaff({ ...staff, employee_status: selectedEmployeeStatus.id });
            } else {
                setStaff({ ...staff, employee_status: null })
            }
        } else {
            setStaff({ ...staff, employee_status: null })
        }


    }, [selectedEmployeeStatus]);


    useEffect(() => {
        if (selectedDivision) {
            if (selectedDivision.division_name) {
                setStaff({ ...staff, division_id: selectedDivision.id });
            } else {
                setStaff({ ...staff, division_id: null })
            }
        } else {
            setStaff({ ...staff, division_id: null })
        }

    }, [selectedDivision]);

    useEffect(() => {
        if (selectedPosition) {
            if (window.location.pathname === "/Crew/Detail") {
                if (selectedPosition.rank_name) {
                    setStaff({ ...staff, staff_position_id: selectedPosition.id });
                } else {
                    setStaff({ ...staff, staff_position_id: null });
                }
            } else {
                if (selectedPosition.position_name) {
                    setStaff({ ...staff, staff_position_id: selectedPosition.id });
                } else {
                    setStaff({ ...staff, staff_position_id: null });
                }
            }

        } else {
            setStaff({ ...staff, staff_position_id: null });
        }

    }, [selectedPosition]);

    useEffect(() => {
        if (selectedCrewPosition) {
            if (selectedCrewPosition.position_name) {
                setCrewSchedule({ ...crewSchedule, crew_positions_id: selectedCrewPosition.id });
            } else {
                setCrewSchedule({ ...crewSchedule, crew_positions_id: null });
            }
        } else {
            setCrewSchedule({ ...crewSchedule, crew_positions_id: null });
        }

    }, [selectedCrewPosition]);

    useEffect(() => {
        if (selectedVessel) {
            if (selectedVessel.vessel_name) {
                setCrewSchedule({ ...crewSchedule, vessel_id: selectedVessel.id });
            } else {
                setCrewSchedule({ ...crewSchedule, vessel_id: null });
            }
        } else {
            setCrewSchedule({ ...crewSchedule, vessel_id: null });
        }

    }, [selectedVessel]);

    useEffect(() => {
        if (listPosition.length > 0 && location.state.staffId !== 0) {
            if (staff.staff_position_id) {
                let position = listPosition.find(p => p.id === staff.staff_position_id);
                if (position) {
                    setSelectedPosition(position);
                }

            }
        }
    }, [listPosition])

    useEffect(() => {
        if (selectedBank) {
            if (selectedBank.bank_name) {
                setStaff({ ...staff, bank_id: selectedBank.id });
            } else {
                setStaff({ ...staff, bank_id: null });
            }

        } else {
            setStaff({ ...staff, bank_id: null });
        }

    }, [selectedBank])

    useEffect(() => {
        if (selectedBusinessUnit) {
            if (selectedBusinessUnit.unit_name) {
                setStaff({ ...staff, business_unit_id: selectedBusinessUnit.id });
            } else {
                setStaff({ ...staff, business_unit_id: null });
            }

        } else {
            setStaff({ ...staff, business_unit_id: null });
        }

    }, [selectedBusinessUnit])

    useEffect(() => {
        if (staff.division_id !== null && staff.division_id !== undefined) {
            loadDepartment();
        }
    }, [staff.division_id]);

    useEffect(() => {
        if (documentToBeViewed.document_base64)
            setShowDocumentDetailModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (!showDocumentDetailModal) {
            setDocumentToBeViewed({
                id: 0,
                document_base64: "",
                document_name: "",
                description: "",
                reference_number: "",
                is_active: true,
            });
            setStaffDocumentId("");
        }
    }, [showDocumentDetailModal]);

    useEffect(() => {
        if (!showDocumentUploadModal) {
            // setDocumentToBeViewed({
            //     id: 0,
            //     document_base64: "",
            //     document_name: "",
            //     description: "",
            //     reference_number: "",
            //     is_active: true,
            // });
            setUpdateDocumentId("")
        } else {
            setLoading(false)
        }
    }, [showDocumentUploadModal]);

    useEffect(() => {
        if (!crewScheduleModal) {
            setCrewScheduleId("");
        }
    }, [crewScheduleModal]);

    useEffect(() => {
        if (crewScheduleId !== "")
            initCrewSchedule();
    }, [crewScheduleId]);

    useEffect(() => {

        if (crewSchedule.start_date !== null && crewSchedule.end_date !== null) {
            for (let index = 0; index < listCrewSchedule.length; index++) {
                const listScheduleTmp = listCrewSchedule[index];
                if (moment(listScheduleTmp.start_date).format("yyyy-MM-DD") > moment(crewSchedule.start_date).format("yyyy-MM-DD") && moment(listScheduleTmp.start_date).format("yyyy-MM-DD") < moment(crewSchedule.end_date).format("yyyy-MM-DD") || moment(crewSchedule.start_date).format("yyyy-MM-DD") > moment(listScheduleTmp.start_date).format("yyyy-MM-DD") && moment(crewSchedule.start_date).format("yyyy-MM-DD") < moment(listScheduleTmp.end_date).format("yyyy-MM-DD")) {
                    setErrorEndDate(`${t("staff_detail_page.error_crew_schedule")}`);

                    setCrewSchedule({ ...crewSchedule, end_date: null })
                }
            }
        }

    }, [crewSchedule.start_date, crewSchedule.end_date])

    useEffect(() => {
        if (listType.length > 0) {
            if (staff.crew_type_id !== null && staff.crew_type_id !== "") {
                let name = listType.find(p => p.id === staff.crew_type_id)
                if (name.crew_type_name === "Non Perwira") {
                    setDisablePosition(true)
                }
            }
        }
    }, [listType, staff.crew_type_id])




    useEffect(() => {
        if (searchInventory === "" && staffId !== 0 && staffId !== "")
            loadStaffInventory();
    }, [searchInventory]);

    useEffect(() => {
        if (downloadDocumentId !== "")
            downloadData();
    }, [downloadDocumentId])

    const loadGender = async () => {
        try {
            let listGender = await getGender(cookies.token, cookies.languageId);
            setListGender(listGender);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const checkUsernames = async () => {
        let response = await checkUsername(cookies.token, user.login_username);
        if (response) {
            if (response.login_username === user.login_username) {
                setErrors({
                    ...errors,
                    loginUsername: `${t("staff_detail_page.error_username")}`
                })
                setSaveButtonModalDisabled(true);
            }

        } else {
            let check = omit(errors, "loginUsername");
            setErrors(check);
            setSaveButtonModalDisabled(false);
        }

    }

    const loadCrewDepartment = async () => {
        try {
            let response = await getCrewDepartment(cookies.token);
            setListCrewDepartment(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadCrewType = async () => {
        try {
            let response = await getCrewType(cookies.token);
            setListType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadStaffPosition = async () => {
        try {
            let listStaffPosition = await getStaffPosition(cookies.token, cookies.languageId);
            setListStaffPosition(listStaffPosition);
            let listStaffPositionNew = [];
            for (let i = 0; i < listStaffPosition.length; i++) {
                var obj = {
                    value: listStaffPosition[i].id,
                    label: listStaffPosition[i].position_name,
                    id: listStaffPosition[i].id

                };

                listStaffPositionNew.push(obj);

            }
            setStaffPositionOptions(listStaffPositionNew);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadStaffAccount = async () => {
        try {
            let listAccount = await getUser(cookies.token, location.state.staffId, cookies.extCustomerId);
            setListAccount(listAccount);

        } catch (exception) {
            console.log(exception);
        }
    }

    const initStaff = async () => {
        try {
            let response = await getStaffByid(cookies.token, cookies.languageId, location.state.staffId);
            if (response) {
                setTypeId(response.crew_type_id);
                setStaff({
                    ...staff,
                    staff_id: response.staff_id,
                    staff_name: response.staff_name,
                    employee_number: response.employee_number,
                    date_of_birth: response.date_of_birth,
                    place_of_birth: response.place_of_birth,
                    address: response.address,
                    phone_number_1: response.phone_number_1,
                    phone_number_2: response.phone_number_2,
                    email: response.email,
                    gender: response.gender_id,
                    position_id: response.position_id,
                    photo_base64: response.photo_base64,
                    is_field_staff: response.is_field_staff,
                    allow_login: response.allow_login,
                    division_id: response.division_id,
                    department_id: response.department_id,
                    staff_position_id: response.staff_position_id,
                    employee_status: response.employee_status,
                    join_date: response.join_date,
                    resign_date: response.resign_date,
                    resign_reason: response.resign_reason,
                    npwp_number: response.npwp_number,
                    npwp_address: response.npwp_address,
                    kpp: response.kpp,
                    grade: response.grade,
                    poin_of_hire: response.poin_of_hire,
                    workarea: response.workarea,
                    worklegth: response.worklegth,
                    bpjs_tk_number: response.bpjs_tk_number,
                    bpjs_ks_number: response.bpjs_ks_number,
                    bpjs_pensiun: response.bpjs_pensiun,
                    ptkp: response.ptkp,
                    ptkp_next_year: response.ptkp_next_year,
                    tax: response.tax,
                    marital_status_id: response.marital_status_id,
                    kk_number: response.kk_number,
                    ktp_number: response.ktp_number,
                    ktp_address: response.ktp_address,
                    mothers_name: response.mothers_name,
                    overtime: response.overtime,
                    bank_branch: response.bank_branch,
                    bank_city: response.bank_city,
                    bank_account_name: response.bank_account_name,
                    bank_account_number: response.bank_account_number,
                    note_1: response.note_1,
                    note_2: response.note_2,
                    clothes_size: response.clothes_size,
                    religion_id: response.religion_id,
                    bank_id: response.bank_id,
                    blood_type_id: response.blood_type_id,
                    rate: response.rate,
                    ext_customer_id: response.ext_customer_id,
                    crew_type_id: response.crew_type_id,
                    sailor_registration_number: response.sailor_registration_number,
                    number_of_children: response.number_of_children,
                    pay_privately: response.pay_privately,
                    business_unit_id: response.business_unit_id

                });
                if (response.pay_privately === true) {
                    setSelectedBpjs("private");
                } else if (response.bpjs_government === true) {
                    setSelectedBpjs("government")
                } else {
                    setSelectedBpjs("");
                }
                loadDocumentData();

                loadContractStaffHistory();
                setIsFieldStaffChecked(response.is_field_staff);
                setIsAllowLoginChecked(response.allow_login);
                if (response.is_field_staff === true) {
                    setFormFieldStaff(false);
                }
                if (response.allow_login === true) {
                    setFormHidden(false);
                }
                setLoading(false);
            } else {
            }

        }
        catch (exception) {
            console.log(exception);
        }

    }

    const loadDocumentCategory = async () => {
        try {
            let response = await getCrewDocumentCategory(cookies.token);
            setListCategoryDocument(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const addStaff = async () => {
        try {
            let staffTmp = staff;
            staffTmp.ext_customer_id = staff.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : staff.ext_customer_id;
            if (selectedBpjs === "private") {
                staffTmp.pay_privately = true;
                staffTmp.bpjs_government = false
            } else if (selectedBpjs === "government") {
                staffTmp.bpjs_government = true;
                staffTmp.pay_privately = false
            } else {
                staffTmp.pay_privately = false
                staffTmp.bpjs_government = false
            }
            let response = await createStaff(cookies.token, staffTmp, location.state.staffId, listSpecialization, isFieldStaffChecked, isAllowLoginChecked, listContractHistory);
            if (response.error_code === 0) {
                alert(`${t("staff_detail_page.alert_success_save_data")}`);

                navigate(`${location.state.pathname}`);

            } else {
                alert(`${t("staff_detail_page.alert_failed_save_data")}`);
                setLoading(false);
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception)
            setDisabledButton(false);
        }

    }

    const loadStaffxSpecialization = async () => {
        try {
            let staffxSpecialization = await getStaffxSpecializationById(cookies.token, location.state.staffId);
            setStaffxSpecialization(staffxSpecialization);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaffSpecialization = async () => {
        try {
            let listSpecialization = await getSpecializaton(cookies.token, cookies.languageId);
            // console.log(listSpecialization)
            if (listSpecialization.length > 0) {
                for (let i = 0; i < listSpecialization.length; i++) {
                    let list = listSpecialization[i]
                    for (let index = 0; index < list.sub_specialization.length; index++) {
                        const listSub = list.sub_specialization[index];
                        let response = await getStaffxSpecializationById(cookies.token, location.state.staffId, listSub.id);
                        if (response.length > 0) {
                            listSub.checked = true
                        } else {
                            listSub.checked = false
                        }
                    }

                }
            }
            setListSpecialization(listSpecialization);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initDocument = async () => {
        try {
            let response = await getDocumentStaffById(cookies.token, updateDocumentId);
            if (response) {
                setNewDocument({
                    ...newDocument,
                    id: response.id,
                    document_name: response.document_name,
                    description: response.description,
                    document_category_id: response.document_category_id,
                    effective_date: response.effective_date,
                    expired_date: response.expired_date,
                    reference_number: response.reference_number,
                    is_vaccine_covid: response.is_vaccine_covid,
                    vaccine_covid_description: response.vaccine_covid_description,
                    note: response.note,
                    technician_document_category_id: response.technician_document_category_id
                })
                setShowDocumentUploadModal(true);
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const addUser = async () => {
        let response = await insertUpdateUser(cookies.token, user);
        if (response === 0) {
            loadStaffAccount();
            handleClose();
        } else {
        }
    }

    const loadUserRole = async () => {
        try {
            let listUserRole = await getUserRole(cookies.token);
            setListUserRole(listUserRole);
            if (listUserRole) {
                let roleNameTmp = listUserRole.find(p => p.id === cookies.roleId)
                if (roleNameTmp) {
                    setRoleName(roleNameTmp.role_name)
                }
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const handleResets = async () => {

        setUser({
            name: "",
            pass: "",
            confirPass: "",
            email: "",
            roleId: "",
            languageId: cookies.languageId,
            staffId: location.state.staffId,
            staffName: location.state.staffName
        });

    }

    const loadUserByid = async () => {
        try {
            let response = await getUserById(cookies.token, userId);
            if (response) {
                setUser({
                    ...user,
                    name: response.login_username,
                    pass: response.password,
                    confirPass: response.password,
                    email: response.email,
                    roleId: response.role_id
                })
            }
        } catch (exception) {

        }
    }

    const removeUser = async () => {
        let response = await deleteUserAccount(cookies.token, userIdRemove);
        if (response.error_code === 0) {
            handleClose();
            loadStaffAccount();
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (window.location.pathname !== "/Crew/Detail") {
                    if (staff.staff_name !== "" && staff.gender !== "" && staff.date_of_birth !== "" && errors.staffEmail === undefined && staff.division_id !== null) {
                        setDisabledButton(false);
                    }
                } else {
                    if (staff.staff_name !== "" && staff.gender !== "" && staff.date_of_birth !== "" && errors.staffEmail === undefined) {
                        setDisabledButton(false);
                    }
                }


                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadBankData = async () => {
        try {
            const response = await getBank(cookies.token, cookies.languageId);
            setListBank(response);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEployeeStatusData = async () => {
        try {
            const response = await getEmployeeStatus(cookies.token, cookies.languageId);
            setListEmployeeStatus(response);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBloodTypeData = async () => {
        try {
            const response = await getBloodType(cookies.token, cookies.languageId);
            setListBloodType(response);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadMaritalStatusData = async () => {
        try {
            const response = await getMaritalStatus(cookies.token, cookies.languageId);
            setListMaritalStatus(response);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadReligionData = async () => {
        try {
            const response = await getReligion(cookies.token, cookies.languageId);
            setListReligion(response);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadPositionData = async () => {
        try {
            if (window.location.pathname === "/Crew/Detail") {
                const responseRank = await getCrewRankByType(cookies.token, typeId);
                setListPosition(responseRank);
            } else {
                const response = await getPosition(cookies.token, cookies.languageId);
                setListPosition(response);
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDivision = async () => {
        try {
            let listDivision = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListDivision(listDivision);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadDepartment = async () => {
        try {
            let response = await getDivisionById(cookies.token, staff.division_id, cookies.languageId);

            let listNewDepartment = [];

            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].department_id,
                    label: response[i].department_code,
                    id: response[i].department_id

                };

                listNewDepartment.push(obj);

            }
            setListDepartment(listNewDepartment);
        } catch (exception) {
            console.log(exception)
        }
    }


    const uploadDocument = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateStaffDocument(cookies.token, newDocument);

                setShowDocumentUploadModal(false);
                resetUploadForm();
                loadDocumentData();
                setDisabledButton(false);
                resolve();
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }

    const removeDocument = async () => {
        let response = await deleteStaffDocument(cookies.token, documentId);
        if (response) {
            loadDocumentData();
        } else {
            alert(`${t("staff_detail_page.alert_failed_remove_document")}`);
            setLoading(false);
        }
    }

    const loadDocumentData = async () => {
        try {
            let count = await getStaffDocument(cookies.token, location.state.staffId);
            let totalPage = count.length / itemPerPage;
            setTotalPage(totalPage);

            const docs = await getStaffDocument(cookies.token, location.state.staffId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListDocument(docs);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaffInventory = async () => {
        try {
            let count = await getStaffInventory(cookies.token, location.state.staffId);
            let totalPageInventory = count.length / itemPerPageInventory;
            setTotalPageInventory(totalPageInventory);

            const response = await getStaffInventory(cookies.token, location.state.staffId, pageInventory, itemPerPageInventory, orderByInventory.value, descendingInventory, searchInventory, false);
            setListStaffInventory(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDocumentById = async () => {
        try {
            let response = await getStaffDocumentById(cookies.token, staffDocumentId);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const downloadData = async () => {
        try {
            let response = await getStaffDocumentById(cookies.token, downloadDocumentId);
            triggerBase64Download(response.document_base64, response.document_name);
            setLoading(false);
            setDownloadDocumentId("");
        } catch (exception) {
            console.log(exception);
        }
    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCustomer = async () => {
        try {
            let response = await getCustomer(cookies.token, cookies.extCustomerId);
            setListCustomer(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadCrewPositions = async () => {
        try {
            let response = await getCrewPositionByDepartment(cookies.token, selectedDepartment.id);
            setListCrewPositions(response.slice(0));
        } catch (exception) {

        }
    }

    const initCrewSchedule = async () => {
        try {
            let response = await getCrewScheduleById(cookies.token, crewScheduleId);
            if (response) {
                setCrewSchedule({
                    ...crewSchedule,
                    id: response.id,
                    vessel_id: response.vessel_id,
                    crew_id: response.crew_id,
                    start_date: response.start_date,
                    end_date: response.end_date,
                    crew_positions_id: response.crew_positions_id,
                    department_id: response.department_id
                })
            }
            setCrewScheduleModal(true)
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadCrewSchedule = async () => {
        try {
            let count = await getCountCrewSchedule(cookies.token, staffId, searchSchedule, false);
            let totalPages = count / itemPerPageCrewSchedule
            setTotalPageCrewSchedule(totalPages)

            let response = await getCrewSchedule(cookies.token, staffId, pageCrewSchedule, itemPerPageCrewSchedule, orderByCrewSchedule.value, descendingCrewSchedule, searchSchedule, false);
            setListCrewSchedule(response);
            setResetSearchSchedule(0);
            setIsSearched(0);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const saveCrewSchedule = async () => {
        try {
            let response = await insertUpdateCrewSchedule(cookies.token, crewSchedule);
            if (response === 0) {
                alert(`${t("staff_detail_page.alert_success_save_data")}`);
                resetCrewSchedule();
                setCrewScheduleModal(false);
                loadCrewSchedule();
            } else {
                alert(`${t("staff_detail_page.alert_failed_save_data")}`);
            }
            setDisableButtonCrew(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadVessel = async () => {
        try {
            let response = await getVesselScheduleForCrew(cookies.token);
            setListVessel(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const removeSchedule = async () => {
        try {
            let response = await deleteCrewSchedule(cookies.token, removeScheduleId);
            if (response === 0) {
                alert(`${t("staff_detail_page.alert_success_delete_crew_schedule")}`);
                loadCrewSchedule();
            } else {
                alert(`${t("staff_detail_page.alert_failed_delete_crew_schedule")}`)
            }
            setRemoveScheduleId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadDocumentCategoryTechnician = async () => {
        try {
            let response = await getDocumentCategoryTechnician(cookies.token);
            setListDocumentCategoryTechnician(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadContractStaffHistory = async () => {
        try {

            let response = await getContractStaffHistory(cookies.token, location.state.staffId);
            console.log(response);
            setListContractHistory(response);


        } catch (exception) {
            console.log(exception)
        }
    }

    const loadBusinessUnit = async () => {
        try {
            let response = await getBusinessUnit(cookies.token);
            setListBusinessUnit(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    // const updateContractDuration = async () => {
    //     try {
    //         let updatedList = listContractHistory.map(item => {
    //                 let totalDate = null;
    //                 if (item.start_date !== null && item.end_date !== null) {
    //                     let startDate = new Date(item.start_date);
    //                     let endDate = new Date(item.end_date);
    //                     let diffYear = endDate.getFullYear() - startDate.getFullYear();
    //                     let diffMonth = endDate.getMonth() - startDate.getMonth();
    //                     totalDate = diffYear * 12 + diffMonth;
    //                 }
    //                 return{...item,contract_duration:totalDate}
    //         })
    //         setListContractHistory(updatedList.slice(0))
    //     } catch (exception) {

    //     }
    // }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={location.state.pathname === "/Staff" ? `${t("staff_detail_page.header_title_staff")}` : window.location.pathname === "/Crew/Detail" ? `${t("staff_detail_page.header_title_crew")}` : `${t("staff_detail_page.header_title_technician")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>


                            <Button variant="secondary"
                                onClick={() => { navigate(`${location.state.pathname}`); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    } childContent={
                        <div>

                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexWrap: "nowrap",
                                    flexDirection: "column",
                                    borderWidth: 2,
                                    borderColor: "rgba(3, 30, 103, 1)",
                                    borderRadius: 10,
                                    borderStyle: "solid",

                                }}>
                                    <Tabs
                                        defaultActiveKey="biodata"
                                        // transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3"
                                        style={{
                                            backgroundColor: "rgba(3, 30, 103, 1)",
                                            borderRadius: 5,

                                        }}
                                    >

                                        <Tab eventKey="biodata" title={`${t("staff_detail_page.tab_1")}`}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("staff_detail_page.header_general_data")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                padding: 10
                                                            }}>

                                                                {

                                                                    staff.photo_base64 === "" || staff.photo_base64 === undefined || staff.photo_base64 === null ?
                                                                        <img className="student-portrait-img" src={AvatarIcon} />
                                                                        :
                                                                        <div>

                                                                            <img className="student-portrait-img" src={staff.photo_base64} />
                                                                        </div>

                                                                }
                                                            </div>
                                                            <div style={{
                                                                padding: 2,
                                                                borderStyle: "solid",
                                                                borderRadius: 5,
                                                                borderWidth: 1,
                                                                borderColor: "#bf0000",
                                                                marginBottom: 10,
                                                                width: "50%",
                                                                marginLeft: "auto",
                                                                marginRight: "auto"
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 2,
                                                                        flexDirection: "row",
                                                                    }}>
                                                                        <Button disabled={location.state.pathname === "/Mechanic" ? true : false} onClick={() => {
                                                                            inputFile.current.click();
                                                                        }}>{t("staff_detail_page.photo_button")}</Button>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 5,
                                                                        width: "100%",
                                                                        flexDirection: "column"
                                                                    }}>
                                                                        <Form.Control onClick={() => {
                                                                            inputFile.current.click();
                                                                        }} placeholder={`${t("staff_detail_page.field_choose_photo")}`} type="text" readOnly={true} />
                                                                        <input onChange={async (e) => {
                                                                            if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                                                                let base64Img = await convertBase64(e.target.files[0]);
                                                                                const base64ImgString = base64Img.toString();
                                                                                setStaff({ ...staff, photo_base64: base64ImgString, file: e.target.value });
                                                                                setUploadFileImageError("");

                                                                            } else {
                                                                                setStaff({ ...staff, photo_base64: "", file: "" });
                                                                                setUploadFileImageError(`${t("staff_detail_page.error_image_validation")}`)
                                                                                setDisabledButton(true)
                                                                            }


                                                                        }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} disabled={location.state.pathname === "/Mechanic" ? true : false} />
                                                                        {
                                                                            uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "row",
                                                                flexWrap: "nowrap",
                                                                width: "100%"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    width: "100%",
                                                                    paddingRight: 10
                                                                }}>
                                                                    <TextBox value={staff.staff_name} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_name")}`} setValueFunction={(e) => {

                                                                            setStaff({ ...staff, staff_name: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    {
                                                                        staffNameError && <p style={{ color: "red" }}>{staffNameError}</p>
                                                                    }
                                                                    <DropDownForm value={staff.gender} caption={`${t("staff_detail_page.field_gender")}`} placeholder=""
                                                                        setValueFunction={(e) => {
                                                                            setStaff({ ...staff, gender: e.target.value });
                                                                        }} listOption={listGender} valueKey="id" labelKey="gender" disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    {
                                                                        staffGenderError && <p style={{ color: "red" }}>{staffGenderError}</p>
                                                                    }
                                                                    <TextBox value={staff.employee_number} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_employee_number")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, employee_number: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    {
                                                                        window.location.pathname === "/Crew/Detail" &&
                                                                        <TextBox value={staff.sailor_registration_number} placeholder=""
                                                                            caption={`${t("staff_detail_page.field_sailor_registration_number")}`} setValueFunction={(e) => {
                                                                                setStaff({ ...staff, sailor_registration_number: e.target.value });
                                                                            }} />
                                                                    }
                                                                    <TextBox value={staff.date_of_birth} controlType="date" placeholder=""
                                                                        caption={`${t("staff_detail_page.field_birth_date")}`} setValueFunction={(e) => {
                                                                            let today = new Date();
                                                                            if (moment(e.target.value).format("yyyy-MM-DD") > moment(today).format("yyyy-MM-DD")) {
                                                                                setStaffBirthDateError(`${t("staff_detail_page.error_birth_date")}`)
                                                                                setDisabledButton(true);

                                                                            } else {
                                                                                setStaff({ ...staff, date_of_birth: e.target.value });
                                                                                setStaffBirthDateError("")
                                                                            }

                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    {
                                                                        staffBirthDateError && <p style={{ color: "red" }}>{staffBirthDateError}</p>
                                                                    }
                                                                    <TextBox value={staff.place_of_birth} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_birth_place")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, place_of_birth: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    <FormSelect caption={`${t("staff_detail_page.field_blood_group")}`} placeholder=""
                                                                        options={listBloodType} value={selectedBloodType} setValueFunction={(e) => {
                                                                            if (e === null) {
                                                                                setSelectedBloodType({})
                                                                            } else {
                                                                                setSelectedBloodType(e);
                                                                            }
                                                                        }}
                                                                        getOptionLabel={
                                                                            (item) => {
                                                                                return item.blood_type_name;
                                                                            }
                                                                        } getOptionValue={
                                                                            (item) => {
                                                                                return item.id;
                                                                            }
                                                                        }
                                                                        disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                    />
                                                                    <FormSelect caption={`${t("staff_detail_page.field_religion")}`} placeholder=""
                                                                        options={listReligion} value={selectedReligion} setValueFunction={(e) => {
                                                                            if (e === null) {
                                                                                setSelectedReligion({})
                                                                            } else {
                                                                                setSelectedReligion(e);
                                                                            }
                                                                        }}
                                                                        getOptionLabel={
                                                                            (item) => {
                                                                                return item.religion_name;
                                                                            }
                                                                        } getOptionValue={
                                                                            (item) => {
                                                                                return item.id;
                                                                            }
                                                                        } disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                    />
                                                                    <FormSelect caption={`${t("staff_detail_page.field_marital_status")}`} placeholder=""
                                                                        options={listMaritalStatus} value={selectedMaritalStatus} setValueFunction={(e) => {
                                                                            if (e === null) {
                                                                                setSelectedMaritalStatus({})
                                                                            } else {
                                                                                setSelectedMaritalStatus(e);
                                                                            }
                                                                        }}
                                                                        getOptionLabel={
                                                                            (item) => {
                                                                                return item.marital_status_name;
                                                                            }
                                                                        } getOptionValue={
                                                                            (item) => {
                                                                                return item.id;
                                                                            }
                                                                        } disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                    />

                                                                    <TextBox value={staff.number_of_children ? staff.number_of_children : ""} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_number_of_children")}`} setValueFunction={(e) => {
                                                                            const value = e.target.value;
                                                                            if (/^\d*$/.test(value) || value === null) {
                                                                                setStaff({ ...staff, number_of_children: value });
                                                                            } else {
                                                                                setStaff({ ...staff, number_of_children: null });
                                                                            }
                                                                        }} />


                                                                    <Form.Group className="mb-3">
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexDirection: "row"
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                flex: 1
                                                                            }}>
                                                                                <Form.Label>{t("staff_detail_page.field_employee_status")}</Form.Label>
                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                justifyContent: 'flex-end'
                                                                            }}>
                                                                                <a role="button" onClick={() => {
                                                                                    setContractHistoryModal(true)
                                                                                }}> Detail Kontrak<ZoomIn /></a>
                                                                            </div>

                                                                        </div>

                                                                        <Select
                                                                            placeholder=""
                                                                            options={listEmployeeStatus}
                                                                            value={selectedEmployeeStatus}
                                                                            getOptionLabel={(e) => {
                                                                                return e.employee_status_name;
                                                                            }}
                                                                            getOptionValue={(e) => {
                                                                                return e.id
                                                                            }}
                                                                            onChange={(e) => {
                                                                                if (e === null) {
                                                                                    setSelectedEmployeeStatus({});
                                                                                    setHiddenRate(true);
                                                                                } else {
                                                                                    let status = listEmployeeStatus.find(p => p.id === e.id);
                                                                                    if (status.employee_status_name === "Temporary") {
                                                                                        setHiddenRate(false);
                                                                                    } else {
                                                                                        setHiddenRate(true);
                                                                                    }
                                                                                    setSelectedEmployeeStatus(e);
                                                                                }
                                                                            }}
                                                                            isClearable
                                                                            isDisabled={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                        />

                                                                    </Form.Group>
                                                                    {/* <FormSelect caption={`${t("staff_detail_page.field_employee_status")}`} isDetail={true} placeholder=""
                                                                        options={listEmployeeStatus} value={selectedEmployeeStatus} setValueFunction={(e) => {
                                                                            if (e === null) {
                                                                                setSelectedEmployeeStatus({});
                                                                                setHiddenRate(true);
                                                                            } else {
                                                                                let status = listEmployeeStatus.find(p => p.id === e.id);
                                                                                if (status.employee_status_name === "Temporary") {
                                                                                    setHiddenRate(false);
                                                                                } else {
                                                                                    setHiddenRate(true);
                                                                                }
                                                                                setSelectedEmployeeStatus(e);
                                                                            }
                                                                        }}
                                                                        getOptionLabel={
                                                                            (item) => {
                                                                                return item.employee_status_name;
                                                                            }
                                                                        } getOptionValue={
                                                                            (item) => {
                                                                                return item.id;
                                                                            }
                                                                        } disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                    /> */}

                                                                    {
                                                                        hiddenRate === false &&
                                                                        <TextBox value={staff.rate} placeholder=""
                                                                            caption={`${t("staff_detail_page.field_daily_rate")}`} setValueFunction={(e) => {
                                                                                setStaff({ ...staff, rate: formatCurrency(e.target.value) });
                                                                            }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    }

                                                                    {
                                                                        window.location.pathname === "/Crew/Detail" &&
                                                                        <Form.Group>
                                                                            <Form.Label>{t("staff_detail_page.field_crew_type")}</Form.Label>
                                                                            <Form.Select required onChange={(e) => {
                                                                                if (e.target.value == "") {
                                                                                    setSelectedPosition({})
                                                                                    setStaff({ ...staff, crew_type_id: null, staff_position_id: null });
                                                                                    setTypeId(e.target.value)
                                                                                } else {
                                                                                    setSelectedPosition({})
                                                                                    setStaff({ ...staff, crew_type_id: e.target.value, staff_position_id: null });
                                                                                    setTypeId(e.target.value)
                                                                                }

                                                                            }} value={staff.crew_type_id} >
                                                                                <option selected value="">

                                                                                </option>
                                                                                {listType.map((type, index) => (
                                                                                    <option key={index} value={type.id}>{type.crew_type_name}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Form.Group>

                                                                    }

                                                                    <FormSelect caption={window.location.pathname === "/Crew/Detail" ? `${t("staff_detail_page.field_rank")}` : `${t("staff_detail_page.field_structural_position")}`} placeholder=""
                                                                        options={listPosition} value={selectedPosition} setValueFunction={(e) => {
                                                                            if (e === null) {
                                                                                setSelectedPosition({})
                                                                            } else {
                                                                                setSelectedPosition(e);
                                                                            }
                                                                        }}
                                                                        getOptionLabel={
                                                                            (item) => {
                                                                                if (window.location.pathname === "/Crew/Detail") {
                                                                                    return item.rank_name
                                                                                } else {
                                                                                    return item.position_name;
                                                                                }

                                                                            }
                                                                        } getOptionValue={
                                                                            (item) => {
                                                                                return item.id;
                                                                            }
                                                                        } disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                    />


                                                                </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    width: "100%",
                                                                    paddingLeft: 10
                                                                }}>
                                                                    {
                                                                        window.location.pathname !== "/Crew/Detail" &&
                                                                        <div>
                                                                            <FormSelect caption={`${t("staff_detail_page.field_division")}`} placeholder=""
                                                                                options={listDivision} value={selectedDivision} setValueFunction={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedDivision({})
                                                                                    } else {
                                                                                        setSelectedDivision(e);
                                                                                    }
                                                                                }}
                                                                                getOptionLabel={
                                                                                    (item) => {
                                                                                        return item.division_name;
                                                                                    }
                                                                                } getOptionValue={
                                                                                    (item) => {
                                                                                        return item.id;
                                                                                    }
                                                                                } disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                            />
                                                                            {
                                                                                divisionRequired && <p style={{ color: "red" }}>{divisionRequired}</p>
                                                                            }
                                                                            <FormSelect caption={`${t("staff_detail_page.field_department")}`} placeholder=""
                                                                                options={listDepartment} value={listDepartment.filter(function (option) {
                                                                                    return option.id === staff.department_id;
                                                                                })} setValueFunction={(e) => {
                                                                                    if (e !== null) {
                                                                                        setStaff({ ...staff, department_id: e.id });
                                                                                    } else {
                                                                                        setStaff({ ...staff, department_id: null });
                                                                                    }
                                                                                }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                            />
                                                                        </div>

                                                                    }
                                                                    <FormSelect caption={`Unit Bisnis`} placeholder=""
                                                                        options={listBusinessUnit} value={selectedBusinessUnit} setValueFunction={(e) => {
                                                                            if (e === null) {
                                                                                setSelectedBusinessUnit({})
                                                                            } else {
                                                                                setSelectedBusinessUnit(e);
                                                                            }
                                                                        }}
                                                                        getOptionLabel={
                                                                            (item) => {
                                                                                return item.unit_name;
                                                                            }
                                                                        } getOptionValue={
                                                                            (item) => {
                                                                                return item.id;
                                                                            }
                                                                        }
                                                                    />



                                                                    {
                                                                        roleName === "Super Administrator" || roleName === "Administrator" ?
                                                                            <FormSelect caption={`${t("staff_detail_page.field_company")}`} placeholder=""
                                                                                getOptionLabel={(item) => {
                                                                                    return item.customer_name
                                                                                }}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id
                                                                                }}
                                                                                options={listCustomer} value={selectedCustomer} setValueFunction={(e) => {
                                                                                    if (e !== null) {
                                                                                        setSelectedCustomer(e)
                                                                                    } else {
                                                                                        setSelectedCustomer(null)
                                                                                    }
                                                                                }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                                                            /> : <div></div>

                                                                    }


                                                                    <TextBox value={staff.join_date ? moment(staff.join_date).format("yyyy-MM-DD") : ""} placeholder="" controlType="date"
                                                                        caption={`${t("staff_detail_page.field_join_date")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, join_date: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                    <TextBox value={staff.resign_date ? moment(staff.resign_date).format("yyyy-MM-DD") : ""} placeholder="" controlType="date"
                                                                        caption={`${t("staff_detail_page.field_leave_date")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, resign_date: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                    {/* <TextBox value={staff.mothers_name} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_mother_name")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, mothers_name: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} /> */}

                                                                    <TextBox value={staff.phone_number_1} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_phone_number1")}`} setValueFunction={(e) => {

                                                                            if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                                            } else {
                                                                                setStaff({ ...staff, phone_number_1: e.target.value });
                                                                            }

                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    <TextBox value={staff.phone_number_2} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_phone_number2")}`} setValueFunction={(e) => {
                                                                            if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                                            } else {
                                                                                setStaff({ ...staff, phone_number_2: e.target.value });
                                                                            }

                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    <TextBox value={staff.email} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_email")}`} setValueFunction={(e) => {
                                                                            if (e.target.value !== "") {
                                                                                if (!new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(e.target.value)) {
                                                                                    setErrors({
                                                                                        ...errors,
                                                                                        staffEmail: 'Masukkan Format Email Dengan Benar'
                                                                                    },
                                                                                        setDisabledButton(true))
                                                                                } else {

                                                                                    let email = omit(errors, "staffEmail");
                                                                                    setErrors(email);
                                                                                    setDisabledButton(false)
                                                                                }
                                                                            } else {
                                                                                let email = omit(errors, "staffEmail");
                                                                                setErrors(email);
                                                                                setDisabledButton(false)
                                                                            }

                                                                            setStaff({ ...staff, email: e.target.value });

                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    {
                                                                        errors.staffEmail && <p style={{ color: "red" }}>{errors.staffEmail}</p>
                                                                    }


                                                                    <TextBox value={staff.address} asType="textarea" rows={3} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_address")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, address: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />




                                                                </div>

                                                            </div>


                                                            {/* {
                                                errors.company_category_id && <p style={{ color: "red" }}>{errors.company_category_id}</p>
                                            } */}

                                                        </div>
                                                    }>
                                                    </ContainerBox>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }} >
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }} >
                                                    {
                                                        window.location.pathname !== "/Crew/Detail" &&
                                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("staff_detail_page.field_specialization_header")}`} childContent={
                                                            <div style={{
                                                                padding: 10,
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    paddingRight: 10
                                                                }}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>{t("staff_detail_page.field_technician")}</Form.Label>
                                                                        <Form.Check onClick={(e) => {
                                                                            if (e.target.checked) {
                                                                                setIsFieldStaffChecked(true);
                                                                                setFormFieldStaff(false);
                                                                                setStaff({ ...staff, is_field_staff: "true" })
                                                                            }
                                                                            else {
                                                                                setIsFieldStaffChecked(false);
                                                                                setFormFieldStaff(true);
                                                                                setStaff({ ...staff, is_field_staff: false })
                                                                            }


                                                                        }} label={`${t("staff_detail_page.checkbox_technician")}`} defaultChecked={staff.is_field_staff} disabled={location.state.pathname === "/Staff" ? false : true} type={"switch"}></Form.Check>

                                                                    </Form.Group>
                                                                    {
                                                                        formFieldStaff === false &&
                                                                        <div>
                                                                            <FormSelect caption={`${t("staff_detail_page.field_technician_position")}`} autoFocusValue={staff.position_id === undefined} placeholder=""
                                                                                options={staffPositionOptions} value={staffPositionOptions.filter(function (option) {
                                                                                    return option.id === staff.position_id;
                                                                                })} setValueFunction={(e) => {
                                                                                    setStaff({ ...staff, position_id: e.id });
                                                                                }}
                                                                                disabledForm={location.state.pathname === "/Staff" ? false : true}
                                                                            />
                                                                            {
                                                                                listSpecialization &&
                                                                                <Form.Group >
                                                                                    <Form.Label>{`${t("staff_detail_page.field_specialization_technician")}`}</Form.Label>
                                                                                    {
                                                                                        listSpecialization.map((specialization, index) => {
                                                                                            return (
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    width: "100%",
                                                                                                    flexWrap: "nowrap",
                                                                                                    flex: 1
                                                                                                }} key={specialization.id}>
                                                                                                    <div style={{
                                                                                                        display: "flex",
                                                                                                        flexDirection: "column",
                                                                                                        flex: 1,
                                                                                                        flexWrap: "nowrap",
                                                                                                        paddingLeft: 40

                                                                                                    }}>
                                                                                                        <p>{(index + 1) + '. ' + specialization.specialization_name}</p>

                                                                                                    </div>
                                                                                                    <div style={{
                                                                                                        display: "flex",
                                                                                                        flexDirection: "column",
                                                                                                        flex: 1,
                                                                                                        flexWrap: "nowrap",
                                                                                                        paddingLeft: 70
                                                                                                    }}>
                                                                                                        {
                                                                                                            specialization.sub_specialization.map((subSpecialization, idx) => {
                                                                                                                return (
                                                                                                                    <Form.Check key={subSpecialization.id} onChange={(e) => {
                                                                                                                        let tmpListSpec = listSpecialization[index].sub_specialization;
                                                                                                                        let selectedSpecIdx = tmpListSpec.findIndex(p => p.id === subSpecialization.id);
                                                                                                                        if (e.target.checked) {
                                                                                                                            let listSub = listSpecialization
                                                                                                                            listSub[index].sub_specialization[selectedSpecIdx].checked = true;
                                                                                                                            listSub[index].sub_specialization[selectedSpecIdx].staffId = location.state.staffId;
                                                                                                                            setListSpecialization(listSub);
                                                                                                                        } else {
                                                                                                                            let listSub = listSpecialization
                                                                                                                            listSub[index].sub_specialization[selectedSpecIdx].checked = false;
                                                                                                                            setListSpecialization(listSub);
                                                                                                                        }
                                                                                                                    }} type={"checkbox"} label={subSpecialization.sub_specialization_name} defaultChecked={subSpecialization.checked} disabled={location.state.pathname === "/Staff" ? false : true} >
                                                                                                                    </Form.Check>
                                                                                                                )
                                                                                                            })
                                                                                                        }

                                                                                                    </div>



                                                                                                </div>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </Form.Group>
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>



                                                            </div>
                                                        }>
                                                        </ContainerBox>
                                                    }

                                                </div>

                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                // paddingBottom: 5,
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }} >
                                                    {
                                                        location.state.staffId === undefined || location.state.staffId === 0 ?
                                                            <></>
                                                            :
                                                            <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("staff_detail_page.container_account_header")}`} childContent={
                                                                <div style={{
                                                                    padding: 10,
                                                                }}>
                                                                    {
                                                                        location.state.staffId !== undefined || location.state.staffId !== 0 ?
                                                                            <Form.Group className="mb-3">
                                                                                {/* <Form.Label>Allow login</Form.Label> */}
                                                                                <Form.Check onClick={async (e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFormHidden(false);
                                                                                        setIsAllowLoginChecked(true);
                                                                                        setStaff({ ...staff, allow_login: "true" });
                                                                                        // await createStaff(cookies.token, staff, location.state.staffId);

                                                                                    } else {
                                                                                        setFormHidden(true);
                                                                                        setIsAllowLoginChecked(false);
                                                                                        setStaff({ ...staff, allow_login: false });
                                                                                        // await createStaff(cookies.token, staff, location.state.staffId);
                                                                                    }
                                                                                }} label={`${t("staff_detail_page.checkbox_allow_login")}`} defaultChecked={staff.allow_login} type="switch"></Form.Check>
                                                                            </Form.Group> : <></>
                                                                    }


                                                                    <Table hidden={formHidden}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{t("staff_detail_page.account_table1")}</th>
                                                                                <th>{t("staff_detail_page.account_table2")}</th>
                                                                                {/* <th>last Login</th> */}
                                                                                <th>{t("staff_detail_page.account_table3")}</th>
                                                                                <th>{t("staff_detail_page.account_table4")}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                listAccount.map((userAccount, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{userAccount.login_username}</td>
                                                                                            <td>{userAccount.email}</td>
                                                                                            {/* <td>Tes</td> */}
                                                                                            <td><Button variant="primary" onClick={() => {
                                                                                                setUser(userAccount);
                                                                                                //setUserId(userAccount.id);
                                                                                                handleShow();

                                                                                            }}><PencilFill /></Button></td>
                                                                                            <td>  <Button className="cancel" variant="danger" onClick={() => {
                                                                                                if (window.confirm(`${t("staff_detail_page.confirmation_delete_account")}`)) {
                                                                                                    setUserIdRemove(userAccount.id);
                                                                                                    // removeStaff();
                                                                                                }
                                                                                            }}><Trash /></Button></td>
                                                                                        </tr>
                                                                                    )
                                                                                })

                                                                            }

                                                                        </tbody>
                                                                    </Table>
                                                                    <Button onClick={() => {
                                                                        setUser({
                                                                            id: 0,
                                                                            login_username: "",
                                                                            login_password: "",
                                                                            login_password_confirmation: "",
                                                                            email: "",
                                                                            role_id: "",
                                                                            language_id: cookies.languageId,
                                                                            staff_id: location.state.staffId,
                                                                        });
                                                                        handleShow();
                                                                    }} hidden={formHidden}>{t("staff_detail_page.add_account_button")}</Button>

                                                                </div>
                                                            }>
                                                            </ContainerBox>
                                                    }

                                                </div>

                                            </div>
                                        </Tab>
                                        {
                                            location.state.pathname === "/Staff" &&
                                            <Tab eventKey="bankData" title={`${t("staff_detail_page.tab_2")}`}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    padding: 5
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        paddingBottom: 5,
                                                        width: "100%",
                                                        flexWrap: "nowrap",
                                                    }}>

                                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("staff_detail_page.bank_container_header")}`} childContent={
                                                            <div style={{
                                                                padding: 10,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap"
                                                                }}>

                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        flexDirection: "column",
                                                                        flexWrap: "nowrap",
                                                                        paddingRight: 10
                                                                    }}>
                                                                        <FormSelect caption={`${t("staff_detail_page.field_bank_name")}`} placeholder=""
                                                                            options={listBank} value={selectedBank} setValueFunction={(e) => {
                                                                                if (e === null) {
                                                                                    setSelectedBank({})
                                                                                } else {
                                                                                    setSelectedBank(e);
                                                                                }
                                                                            }}
                                                                            getOptionLabel={
                                                                                (item) => {
                                                                                    return item.bank_code;
                                                                                }
                                                                            } getOptionValue={
                                                                                (item) => {
                                                                                    return item.id;
                                                                                }
                                                                            }
                                                                        />

                                                                        <TextBox value={staff.bank_branch} placeholder=""
                                                                            caption={`${t("staff_detail_page.field_bank_branch")}`} setValueFunction={(e) => {
                                                                                setStaff({ ...staff, bank_branch: e.target.value });
                                                                            }} />

                                                                        <TextBox value={staff.bank_city} placeholder=""
                                                                            caption={`${t("staff_detail_page.field_bank_city")}`} setValueFunction={(e) => {
                                                                                setStaff({ ...staff, bank_city: e.target.value });
                                                                            }} />
                                                                        <TextBox value={staff.bank_account_number} placeholder=""
                                                                            caption={`${t("staff_detail_page.field_bank_account_number")}`} setValueFunction={(e) => {
                                                                                setStaff({ ...staff, bank_account_number: e.target.value });
                                                                            }} />
                                                                        <TextBox value={staff.bank_account_name} placeholder=""
                                                                            caption={`${t("staff_detail_page.field_bank_account_name")}`} setValueFunction={(e) => {
                                                                                setStaff({ ...staff, bank_account_name: e.target.value });
                                                                            }} />
                                                                    </div>


                                                                </div>


                                                            </div>
                                                        }>
                                                        </ContainerBox>

                                                    </div>
                                                </div>
                                            </Tab>
                                        }

                                        <Tab eventKey="legalDocument" title={`${t("staff_detail_page.tab_3")}`} >
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: 5
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 10,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>

                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("staff_detail_page.legal_document_container_header")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap"
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    paddingRight: 10
                                                                }}>
                                                                    <TextBox value={staff.kk_number} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_family_card_number")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, kk_number: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                    <TextBox value={staff.ktp_number} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_identity_card_number")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, ktp_number: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                    <TextBox value={staff.ktp_address} asType="textarea" row={3} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_identity_card_address")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, ktp_address: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                </div>


                                                            </div>


                                                        </div>
                                                    }>
                                                    </ContainerBox>

                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 10,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>

                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("staff_detail_page.bpjs_container_header")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap"
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    paddingRight: 10
                                                                }}>
                                                                    <TextBox value={staff.bpjs_tk_number} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_bpjs_tk_number")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, bpjs_tk_number: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            width: "100%",
                                                                            flex: 13
                                                                        }}>
                                                                            <TextBox value={staff.bpjs_ks_number} placeholder=""
                                                                                caption={`${t("staff_detail_page.field_bpjs_ks_number")}`} setValueFunction={(e) => {
                                                                                    setStaff({ ...staff, bpjs_ks_number: e.target.value });
                                                                                }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            width: "100%",
                                                                            flex: 1,
                                                                            paddingTop: 30,
                                                                            paddingLeft: 10
                                                                        }}>
                                                                            <Form.Group>
                                                                                <Form.Check
                                                                                    type="radio"
                                                                                    size="lg"
                                                                                    label={`${t("staff_detail_page.field_bpjs_pay")}`}
                                                                                    value={"private"}
                                                                                    checked={selectedBpjs === "private"}
                                                                                    onClick={(e) => {
                                                                                        if(selectedBpjs === "private"){
                                                                                            setSelectedBpjs("")
                                                                                        }else{
                                                                                            setSelectedBpjs(e.target.value);
                                                                                        }
                                                                                     
                                                                                    }} />

                                                                                <Form.Check
                                                                                    type="radio"
                                                                                    size="lg"
                                                                                    label={`${t("staff_detail_page.field_government")}`}
                                                                                    value={"government"}
                                                                                    checked={selectedBpjs === "government"}
                                                                                    onClick={(e) => {
                                                                                        if(selectedBpjs === "government"){
                                                                                            setSelectedBpjs("")
                                                                                        }else{
                                                                                            setSelectedBpjs(e.target.value);
                                                                                        }
                                                                                       
                                                                                    }} />
                                                                            </Form.Group>

                                                                        </div>

                                                                    </div>

                                                                    <TextBox value={staff.bpjs_pensiun} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_bpjs_jht")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, bpjs_pensiun: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                </div>


                                                            </div>


                                                        </div>
                                                    }>
                                                    </ContainerBox>

                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 10,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>

                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("staff_detail_page.field_tax")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap"
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    paddingRight: 10
                                                                }}>
                                                                    <TextBox value={staff.npwp_number} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_npwp_number")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, npwp_number: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    <TextBox value={staff.npwp_address} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_npwp_address")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, npwp_address: e.target.value });
                                                                        }} asType="textarea" rows={3} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                    <TextBox value={staff.tax} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_tax")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, tax: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                    <TextBox value={staff.ptkp} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_ptkp")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, ptkp: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />

                                                                    <TextBox value={staff.ptkp_next_year} placeholder=""
                                                                        caption={`${t("staff_detail_page.field_ptkp_next_year")}`} setValueFunction={(e) => {
                                                                            setStaff({ ...staff, ptkp_next_year: e.target.value });
                                                                        }} disabledForm={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} />
                                                                </div>


                                                            </div>


                                                        </div>
                                                    }>
                                                    </ContainerBox>

                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="document" title={`${t("staff_detail_page.tab_4")}`} disabled={location.state.staffId === 0 ? true : false} >
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <ContainerBox
                                                        containerPos="inner"
                                                        titleCaption={`${t("staff_detail_page.container_document")}`}
                                                        useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                        actionContainerChild={
                                                            <div>
                                                                <Button disabled={!updatedButton} variant="secondary" style={{

                                                                }} onClick={() => { setShowDocumentUploadModal(true) }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FilePlusFill size={32} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 10,
                                                                            fontWeight: "bold",
                                                                            fontSize: 18,
                                                                        }}>{`${t("staff_detail_page.add_button_document")}`}</div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        }
                                                        childContent={
                                                            <div className="master-table-inner-container">
                                                                <div className="master-table-searchbar-container">
                                                                    <div className="master-table-searchbar-textbox">
                                                                        <Form.Control value={searchQuery} type="text" placeholder={`${t("staff_detail_page.document_search_box")}`}
                                                                            onKeyPress={async (e) => {
                                                                                if (e.charCode === 13) {
                                                                                    setLoading(true);
                                                                                    await loadDocumentData();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setSearchQuery(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="master-table-searchbar-button">
                                                                        <div style={{ paddingRight: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                await loadDocumentData();
                                                                            }}><Search /></Button>
                                                                        </div>
                                                                        <div style={{ paddingLeft: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setSearchQuery("");
                                                                                await loadDocumentData();
                                                                            }}><ArrowClockwise /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-container">
                                                                    <div className="table-header">
                                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("staff_detail_page.document_table_name1")}</div>
                                                                        {window.location.pathname === "/Crew/Detail" && <div style={{ flex: 4 }} className="table-header-content">{t("staff_detail_page.document_table_crew")}</div>}
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.document_table_name2")}</div>

                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.document_table_name3")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.document_table_name4")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.document_table_name5")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.document_table_name6")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.document_table_name7")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.document_table_name8")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.document_table_name9")}</div>
                                                                    </div>
                                                                    {
                                                                        listDocument.map((document, index) => {
                                                                            return (<div key={index} className="table-body">
                                                                                <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                                                                {window.location.pathname === "/Crew/Detail" && <div style={{ flex: 4 }} className="table-body-content"><p>{document.category_name}</p></div>}
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{document.reference_number}</p></div>

                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{document.effective_date ? moment(document.effective_date).format("DD/MM/yyyy") : ""}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{document.expired_date ? moment(document.expired_date).format("DD/MM/yyyy") : ""}</p></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : false} variant="primary" onClick={() => {
                                                                                            setLoading(true);
                                                                                            setUpdateDocumentId(document.id);
                                                                                        }}><PencilFill />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            setLoading(true);
                                                                                            setStaffDocumentId(document.id);
                                                                                        }}><EyeFill />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            setDownloadDocumentId(document.id);
                                                                                        }}><Download />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button disabled={location.state.pathname === "/Mechanic" || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false} variant="danger" onClick={() => {
                                                                                            if (window.confirm(`${t("staff_detail_page.confirmation_delete_document")}  ${document.document_name}?`)) {
                                                                                                let documentId = document.id
                                                                                                setDocumentId(documentId);

                                                                                            }
                                                                                        }}><Trash />
                                                                                        </Button>
                                                                                    </div></div>
                                                                            </div>)
                                                                        }
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    paddingTop: 20,
                                                                }}>

                                                                </div>

                                                            </div>
                                                        } />
                                                </div>
                                            </div>

                                        </Tab>

                                        <Tab eventKey="inventaris" title={`${t("staff_detail_page.tab_5")}`} disabled={location.state.staffId === 0 ? true : false} >
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <ContainerBox
                                                        containerPos="inner"
                                                        titleCaption={`${t("staff_detail_page.inventory_header_container")}`}

                                                        childContent={
                                                            <div className="master-table-inner-container">
                                                                <div className="master-table-searchbar-container">
                                                                    <div className="master-table-searchbar-textbox">
                                                                        <Form.Control value={searchInventory} type="text" placeholder={`${t("staff_detail_page.search_box_inventory")}`}
                                                                            onKeyPress={async (e) => {
                                                                                if (e.charCode === 13) {
                                                                                    setLoading(true);
                                                                                    await loadStaffInventory();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setSearchInventory(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="master-table-searchbar-button">
                                                                        <div style={{ paddingRight: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                await loadStaffInventory();
                                                                            }}><Search /></Button>
                                                                        </div>
                                                                        <div style={{ paddingLeft: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setSearchInventory("");
                                                                                await loadStaffInventory();
                                                                            }}><ArrowClockwise /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-container">
                                                                    <div className="table-header">
                                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("staff_detail_page.inventory_table_name1")}</div>
                                                                        <div style={{ flex: 3 }} className="table-header-content">{t("staff_detail_page.inventory_table_name2")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.inventory_table_name3")}</div>
                                                                        <div style={{ flex: 1 }} className="table-header-content">{t("staff_detail_page.inventory_table_name4")}</div>

                                                                    </div>
                                                                    {
                                                                        listStaffInventory.map((staffInventory, index) => {
                                                                            return (
                                                                                <div key={index} className="table-body">
                                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                    <div style={{ flex: 4 }} className="table-body-content"><p>{staffInventory.inventory_master_name}</p></div>
                                                                                    <div style={{ flex: 3 }} className="table-body-content"><p>{staffInventory.inventory_code}</p></div>
                                                                                    <div style={{ flex: 2 }} className="table-body-content"><p>{moment(staffInventory.handover_date).format("DD/MM/yyyy")}</p></div>

                                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                        <div style={{ padding: 5 }}>
                                                                                            <Link to={`/Handover/Detail?id=${staffInventory.handover_id}`} target="_blank">
                                                                                                <Button variant="secondary" onClick={() => {

                                                                                                }} >
                                                                                                    <EyeFill size={20} />


                                                                                                </Button>
                                                                                            </Link>
                                                                                        </div></div>

                                                                                </div>
                                                                            )
                                                                        }
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    paddingTop: 20,
                                                                }}>

                                                                </div>

                                                            </div>
                                                        } />
                                                </div>
                                            </div>

                                        </Tab>

                                        {
                                            window.location.pathname === "/Crew/Detail" &&
                                            <Tab eventKey="crew_schedule" title={`${t("staff_detail_page.tab_6")}`} disabled={location.state.staffId === 0 ? true : false} >
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    padding: "0px 10px 10px 10px"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        // paddingBottom: 5,
                                                        width: "100%",
                                                        flexWrap: "nowrap",
                                                    }}>
                                                        <ContainerBox
                                                            containerPos="inner"
                                                            titleCaption={`${t("staff_detail_page.container_crew_schedule_title")}`}
                                                            useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                            actionContainerChild={
                                                                <div>
                                                                    <Button disabled={!updatedButton} variant="secondary" style={{

                                                                    }} onClick={() => {
                                                                        setCrewScheduleModal(true);
                                                                        resetCrewSchedule();
                                                                        setSelectedCrewPosition({});
                                                                        setSelectedVessel({});
                                                                        setSelectedDepartment({})
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            alignSelf: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flex: 1,
                                                                            }}><FilePlusFill size={32} /></div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 8,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                paddingLeft: 10,
                                                                                fontWeight: "bold",
                                                                                fontSize: 18,
                                                                            }}>{t("staff_detail_page.add_button_crew")}</div>
                                                                        </div>
                                                                    </Button>
                                                                </div>
                                                            }

                                                            childContent={
                                                                <div className="master-table-inner-container">
                                                                    <div className="master-table-searchbar-container">
                                                                        <div className="master-table-searchbar-textbox">
                                                                            <Form.Control value={searchSchedule} type="text" placeholder={`${t("staff_detail_page.search_box_crew_schedule")}`}
                                                                                onKeyPress={async (e) => {
                                                                                    if (e.charCode === 13) {
                                                                                        setPageCrewSchedule(0)
                                                                                        setLoading(true);
                                                                                        await loadCrewSchedule();
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    setSearchSchedule(e.target.value);
                                                                                }} />
                                                                        </div>
                                                                        <div className="master-table-searchbar-button">
                                                                            <div style={{ paddingRight: 5 }}>
                                                                                <Button onClick={async () => {
                                                                                    setLoading(true);
                                                                                    setPageCrewSchedule(0)
                                                                                    await loadCrewSchedule();
                                                                                }}><Search /></Button>
                                                                            </div>
                                                                            <div style={{ paddingLeft: 5 }}>
                                                                                <Button onClick={async () => {
                                                                                    setLoading(true);
                                                                                    setSearchSchedule("");
                                                                                    setResetSearchSchedule(1);
                                                                                }}><ArrowClockwise /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="table-container">
                                                                        <div className="table-header">
                                                                            <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                            <div style={{ flex: 4 }} className="table-header-content">{t("staff_detail_page.crew_table_name1")}</div>
                                                                            <div style={{ flex: 3 }} className="table-header-content">{t("staff_detail_page.crew_table_name2")}</div>
                                                                            <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.crew_table_name3")}</div>
                                                                            <div style={{ flex: 2 }} className="table-header-content">{t("staff_detail_page.crew_table_name4")}</div>
                                                                            <div style={{ flex: 1 }} className="table-header-content">{t("staff_detail_page.crew_table_name5")}</div>
                                                                            <div style={{ flex: 1 }} className="table-header-content">{t("staff_detail_page.crew_table_name6")}</div>

                                                                        </div>
                                                                        {
                                                                            listCrewSchedule.map((crews, index) => {
                                                                                return (
                                                                                    <div key={index} className="table-body">
                                                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                        <div style={{ flex: 4 }} className="table-body-content"><p>{crews.vessel_name}</p></div>
                                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{crews.position_name}</p></div>
                                                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{crews.start_date ? moment(crews.start_date).format("DD/MM/yyyy") : ""}</p></div>
                                                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{crews.end_date ? moment(crews.end_date).format("DD/MM/yyyy") : ""}</p></div>

                                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                            <div style={{ padding: 5 }}>

                                                                                                <Button variant="primary" onClick={() => {
                                                                                                    setCrewScheduleId(crews.id)
                                                                                                }} >
                                                                                                    <PencilFill size={20} />


                                                                                                </Button>

                                                                                            </div></div>


                                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                            <div style={{ padding: 5 }}>
                                                                                                <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : false} variant="danger" onClick={() => {
                                                                                                    if (window.confirm(`${t("staff_detail_page.confirmation_delete_vessel_crew")}  ${crews.vessel_name}?`)) {
                                                                                                        setRemoveScheduleId(crews.id)

                                                                                                    }
                                                                                                }}><Trash />
                                                                                                </Button>
                                                                                            </div></div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div style={{
                                                                        paddingTop: 20,
                                                                    }}>

                                                                    </div>
                                                                    <Paginations
                                                                        itemPerPage={itemPerPageCrewSchedule}
                                                                        totalPage={totalPageCrewSchedule}
                                                                        page={pageCrewSchedule}
                                                                        setPage={setPageCrewSchedule}
                                                                        setItemPerPage={setItemPerPageCrewSchedule}
                                                                        itemPerPageSelection={itemPerPageSelectionCrewSchedule}
                                                                        orderBy={orderByCrewSchedule}
                                                                        setOrderBy={setOrderByCrewSchedule}
                                                                        orderBySelection={orderByListCrewSchedule}
                                                                        isDescActive={descendingCrewSchedule}
                                                                        setIsDescActive={setDescendingCrewSchedule}
                                                                        setIsSearched={setIsSearched}
                                                                    />

                                                                </div>
                                                            } />
                                                    </div>
                                                </div>

                                            </Tab>
                                        }


                                    </Tabs>

                                </div>


                            </div>


                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                padding: 10,
                                width: "100%",
                                justifyContent: "center",
                                fontWeight: "bold"
                            }}>
                                <div style={{
                                    display: "flex",
                                    paddingRight: 5,
                                }}>
                                    <Button style={{
                                        width: 100
                                    }} onClick={() => {
                                        addStaff();
                                        setDisabledButton(true);
                                        setLoading(true);
                                    }} className="save-button" disabled={disabledButton === true || (cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : !updatedButton} type="submit">{t("staff_detail_page.save_button")}</Button>
                                </div>
                                <div style={{
                                    display: "flex",
                                    paddingLeft: 5,
                                    paddingRight: 5
                                }}>
                                    <Button style={{
                                        width: 100
                                    }} onClick={() => {
                                        navigate('/Staff')
                                    }} className="cancel-button" variant="danger">{t("staff_detail_page.cancel_button")}</Button>
                                </div>
                            </div>
                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />


            <Modal size="lg" show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton onClick={() => {
                    handleResets();
                }}>
                    <Modal.Title>{t("staff_detail_page.modal_add_account_header")}</Modal.Title>
                </Modal.Header> */}
                {/* <Modal.Body> */}
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>{t("staff_detail_page.modal_add_account_header")}</h3>
                    </div>
                    <div style={{
                        padding: 10
                    }}>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            // checkUsername();
                            addUser();
                            // addStaff();
                        }}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("staff_detail_page.account_username")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setUser({ ...user, login_username: e.target.value })
                                }} type="text" value={user.login_username} required></Form.Control>
                                {
                                    errors.loginUsername && <p style={{ color: "red" }}>{errors.loginUsername}</p>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("staff_detail_page.account_email")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setUser({ ...user, email: e.target.value })
                                    if (!new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(e.target.value)) {
                                        setErrors({
                                            ...errors,
                                            userEmail: `${t("staff_detail_page.error_email")}`
                                        },
                                            setSaveButtonModalDisabled(true))
                                    } else {

                                        let emails = omit(errors, "userEmail");
                                        setErrors(emails);
                                        setSaveButtonModalDisabled(false)
                                    }
                                }} type="text" value={user.email} required ></Form.Control>
                                {
                                    errors.userEmail && <p style={{ color: "red" }}>{errors.userEmail}</p>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("staff_detail_page.account_role_status")}</Form.Label>
                                <Form.Select required onChange={(e) => {
                                    setUser({ ...user, role_id: e.target.value });
                                }} value={user.role_id} >
                                    <option value="" selected disabled>
                                        {t("staff_detail_page.account_choose_status")}
                                    </option>
                                    {
                                        listUserRole.map((userRole, index) => (
                                            <option key={index} value={userRole.id}>{userRole.role_name}</option>
                                        )
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>


                            <div>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("staff_detail_page.account_password")}</Form.Label>
                                    <input required type="password" onChange={(e) => {
                                        setUser({ ...user, login_password: e.target.value })
                                    }} class="form-control" value={user.login_password} ></input>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label  >{t("staff_detail_page.account_confirmation_password")}</Form.Label>
                                    <input required type="password" onChange={(e) => {
                                        if (user.login_password !== e.target.value) {
                                            setErrors({
                                                ...errors,
                                                login_password_confirmation: `${t("staff_detail_page.error_confirmation_password")}`
                                            })
                                            setSaveButtonModalDisabled(true);
                                        } else {

                                            let pass = omit(errors, "login_password_confirmation");
                                            setErrors(pass);
                                            setSaveButtonModalDisabled(false)
                                        }
                                        setUser({ ...user, login_password_confirmation: e.target.value })
                                    }} class="form-control" value={user.login_password_confirmation} />
                                    {
                                        errors.login_password_confirmation && <p style={{ color: "red" }}>{errors.login_password_confirmation}</p>
                                    }
                                </Form.Group></div>


                            <Button style={{ width: 100 }} type="submit" disabled={saveButtonModalDisabled}>{t("staff_detail_page.save_button")}</Button>
                            <Button style={{ width: 100 }} variant="danger" className="cancel" onClick={() => {
                                handleClose();
                                handleResets();
                            }}>{t("staff_detail_page.cancel_button")}</Button>
                        </Form>
                    </div>

                </div>
                {/* </Modal.Body> */}
            </Modal>

            <Modal size="xl" show={showDocumentUploadModal} onHide={() => {
                resetUploadForm();
                setShowDocumentUploadModal(false);
            }} >
                <ContainerBox
                    containerPos="inner"
                    titleCaption={`${t("staff_detail_page.container_upload_document_title")}`}
                    childContent={
                        <div style={{

                            padding: 10,
                        }}>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setDisabledButton(true);
                                setLoading(true);
                                setNewDocument({ ...newDocument, done: true });

                            }}>
                                {
                                    newDocument.id === 0 &&

                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("staff_detail_page.field_document_file")}</Form.Label>
                                        <Form.Control onChange={async (e) => {
                                            setDocumentFile(e.target.value)
                                            let base64Doc = await convertBase64(e.target.files[0]);
                                            setNewDocument({ ...newDocument, document_base64: base64Doc.toString() });
                                        }} type="file" required></Form.Control>
                                        <small id="fileHelp" class="form-text text-muted">{t("staff_detail_page.hint_upload_document")}</small>
                                    </Form.Group>

                                }

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>{t("staff_detail_page.field_document_name")}</Form.Label>

                                        <Form.Control onChange={(e) => {
                                            setNewDocument({ ...newDocument, document_name: e.target.value })
                                        }} value={newDocument.document_name} type="text" placeholder="" required></Form.Control>

                                    </Form.Group>
                                </div>

                                {
                                    window.location.pathname === "/Crew/Detail" &&
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        // justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <FormSelect caption={`${t("staff_detail_page.field_category_document")}`} placeholder=""
                                            options={listCategoryDocument} value={selectedCategory} setValueFunction={(e) => {
                                                if (e === null) {
                                                    setSelectedCategory({})
                                                } else {
                                                    setSelectedCategory(e);
                                                }
                                            }}
                                            getOptionLabel={
                                                (item) => {
                                                    return item.category_name;
                                                }
                                            } getOptionValue={
                                                (item) => {
                                                    return item.id;
                                                }
                                            } disabledForm={(cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                                        />
                                        <Form.Label>{t("staff_detail_page.field_vaccine_certificate")}</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                            width: "100%"
                                        }}>
                                            <div style={{ display: "flex", flexDirection: "column", paddingRight: 15 }}>
                                                <div style={{ paddingTop: 5 }}></div>
                                                <Form.Check label={`${t("staff_detail_page.yes_label")}`} onClick={(e) => {
                                                    if (e.target.checked === true) {
                                                        setNewDocument({ ...newDocument, is_vaccine_covid: true })
                                                    } else {
                                                        setNewDocument({ ...newDocument, is_vaccine_covid: false, vaccine_covid_description: null })
                                                    }
                                                }} defaultChecked={newDocument.is_vaccine_covid}></Form.Check>
                                            </div>


                                            <Form.Control onChange={(e) => {
                                                if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                } else {
                                                    setNewDocument({ ...newDocument, vaccine_covid_description: e.target.value })
                                                }

                                            }} type="number" value={newDocument.vaccine_covid_description} disabled={newDocument.is_vaccine_covid === false} placeholder={`${t("staff_detail_page.vaccine_explanation")}`} required></Form.Control>


                                        </div>



                                    </div>
                                }




                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>{t("staff_detail_page.field_reference_number")}</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                        }}>
                                            <Form.Control style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                borderWidth: 1,
                                                borderStyle: "solid",
                                            }} onChange={(e) => {
                                                setNewDocument({ ...newDocument, reference_number: e.target.value })
                                            }} value={newDocument.reference_number} type="text" placeholder="" ></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                }}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("index_document_technician.field_document_category")}</Form.Label>

                                        <Select
                                            placeholder=""
                                            options={listDocumentCategoryTechnician}
                                            value={listDocumentCategoryTechnician.filter(function (option) {
                                                return option.id === newDocument.technician_document_category_id;
                                            })}
                                            isClearable
                                            getOptionLabel={(e) => {
                                                return e.category_name
                                            }}
                                            getOptionValue={(e) => {
                                                return e.id
                                            }}
                                            onChange={(e) => {
                                                if (e === null) {
                                                    setNewDocument({ ...newDocument, technician_document_category_id: null })
                                                } else {
                                                    setNewDocument({ ...newDocument, technician_document_category_id: e.id })
                                                }
                                            }}

                                        />
                                    </Form.Group>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>{t("staff_detail_page.field_start_active_date")}</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                        }}>
                                            <Form.Control style={{
                                                // display: "flex",
                                                // minWidth: "100%",
                                                // borderWidth: 1,
                                                // borderStyle: "solid",
                                            }} onChange={(e) => {
                                                if (e.target.value === "") {
                                                    setNewDocument({ ...newDocument, effective_date: null })
                                                } else {
                                                    setNewDocument({ ...newDocument, effective_date: e.target.value })
                                                }

                                            }} value={newDocument.effective_date ? moment(newDocument.effective_date).format("yyyy-MM-DD") : ""} type="date" placeholder="" ></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>{t("staff_detail_page.field_end_active_date")}</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                        }}>
                                            <Form.Control style={{
                                                // display: "flex",
                                                // minWidth: "100%",
                                                // borderWidth: 1,
                                                // borderStyle: "solid",
                                            }} onChange={(e) => {
                                                if (e.target.value === "") {
                                                    setNewDocument({ ...newDocument, expired_date: null })
                                                } else {
                                                    setNewDocument({ ...newDocument, expired_date: e.target.value })
                                                }

                                            }} value={newDocument.expired_date ? moment(newDocument.expired_date).format("yyyy-MM-DD") : ""} type="date" placeholder="" ></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>


                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>{t("staff_detail_page.field_document_description")}</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                        }}>
                                            <Form.Control onChange={(e) => {
                                                setNewDocument({ ...newDocument, description: e.target.value })
                                            }} value={newDocument.description} as="textarea" rows={3} placeholder=""></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    flex: 1,
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                        <Form.Label>{t("staff_detail_page.field_document_note")}</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                        }}>
                                            <Form.Control onChange={(e) => {
                                                setNewDocument({ ...newDocument, note: e.target.value })
                                            }} value={newDocument.note} as="textarea" rows={3} placeholder=""></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    flex: 1,
                                }}>
                                    <Button style={{ width: 100 }} variant="primary" type="submit" disabled={disabledButton}>
                                        {t("staff_detail_page.save_button")}
                                    </Button>
                                    <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                        resetUploadForm();
                                        loadDocumentData();
                                        setShowDocumentUploadModal(false);
                                    }}>
                                        {t("staff_detail_page.cancel_button")}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    }
                />


            </Modal>

            <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                setShowDocumentDetailModal(false);
            }}>
                <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name}
                    useActionContainer={true}
                    actionContainerChild={
                        <div id="button-color">
                            <Button variant="primary" style={{

                            }} onClick={() => {
                                setShowDocumentDetailModal(false);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquare size={32} /></div>

                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            {
                                documentToBeViewed.document_base64.substring(0, 10) === "data:image"
                                    ?
                                    <center> <img src={documentToBeViewed.document_base64} style={{ width: "50%" }}></img></center>
                                    :
                                    <div>
                                        <div>

                                            <Document
                                                file={documentToBeViewed.document_base64}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                                loading={() => {

                                                }}
                                            ><div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        overflow: "scroll",
                                                    }}>
                                                        <Page scale={zoomFactor} pageNumber={pageNumber} />
                                                    </div>
                                                </div>
                                            </Document>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignContent: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}>
                                                <p>
                                                    Halaman {pageNumber} dari {numPages}
                                                </p>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                flexWrap: "wrap",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    padding: 5,
                                                }}>
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        disabled={zoomFactor <= 0.2}
                                                        onClick={() => {
                                                            setZoomFactor(zoomFactor * 0.8);
                                                        }}
                                                    >

                                                        <ZoomOut size={28} />
                                                    </Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    padding: 5
                                                }}>
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        disabled={false}
                                                        onClick={() => {
                                                            setZoomFactor(1);
                                                        }}
                                                    >
                                                        <AspectRatioFill size={28} />
                                                    </Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    padding: 5,
                                                    flexWrap: "wrap",
                                                }}>
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        disabled={zoomFactor >= 10}
                                                        onClick={() => {
                                                            setZoomFactor(zoomFactor * 2);
                                                        }}
                                                    >
                                                        <ZoomIn size={28} />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                padding: 5
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    padding: 5
                                                }}>
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        disabled={pageNumber <= 1}
                                                        onClick={previousPage}
                                                    >

                                                        <ChevronDoubleLeft size={28} />
                                                    </Button>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    padding: 5
                                                }}>
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        disabled={pageNumber >= numPages}
                                                        onClick={nextPage}
                                                    >
                                                        <ChevronDoubleRight size={28} />
                                                    </Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                            }

                        </div>
                    } />



            </Modal>

            <Modal show={crewScheduleModal}
                size={"lg"}
                onHide={() => {
                    setCrewScheduleModal(false);
                    resetCrewSchedule();
                    setErrorStartDate("");
                    setErrorEndDate("");
                    setSelectedCrewPosition({});
                    setSelectedVessel({});
                    setSelectedDepartment({})
                    setListCrewPositions([])
                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>Data Jadwal Kru </h3>
                    </div>

                    <Form onSubmit={(e) => {
                        setDisableButtonCrew(true)
                        e.preventDefault();
                        saveCrewSchedule()
                    }} style={{ padding: 10 }}>

                        <FormSelect caption="Jadwal Kapal" placeholder=""
                            options={listVessel} value={selectedVessel} setValueFunction={(e) => {
                                if (e === null) {
                                    setSelectedVessel({})
                                } else {
                                    setSelectedVessel(e);
                                }
                            }}
                            getOptionLabel={
                                (item) => {
                                    if (item.id) {
                                        return item.vessel_name + " (" + moment(item.start_date).format("DD-MM-yyyy") + "-" + moment(item.end_date).format("DD-MM-yyyy") + " )";
                                    } else {
                                        return ("");
                                    }

                                }
                            } getOptionValue={
                                (item) => {
                                    return item.id;
                                }
                            }
                        />
                        {
                            errorVessel && <p style={{ color: "red" }}>{errorVessel}</p>
                        }
                        <FormSelect caption="Departemen" placeholder="Pilih Departemen..."
                            options={listCrewDepartment} value={selectedDepartment} setValueFunction={(e) => {
                                // setSelectedCrewPosition({});
                                // setCrewSchedule({...crewSchedule,crew_positions_id:null})
                                if (e === null) {
                                    setSelectedDepartment({})
                                } else {
                                    setSelectedDepartment(e);
                                }
                            }}
                            getOptionLabel={
                                (item) => {
                                    return item.department_name;
                                }
                            } getOptionValue={
                                (item) => {
                                    return item.id;
                                }
                            } disabledForm={(cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") ? true : false}
                        />

                        <FormSelect caption="Jabatan" placeholder=""
                            options={listCrewPositions} value={selectedCrewPosition} setValueFunction={(e) => {
                                if (e === null) {
                                    setSelectedCrewPosition({})
                                } else {
                                    setSelectedCrewPosition(e);
                                }
                            }}
                            getOptionLabel={
                                (item) => {
                                    return item.position_name;
                                }
                            } getOptionValue={
                                (item) => {
                                    return item.id;
                                }
                            }
                            disabledForm={disabledPosition}
                        />
                        {
                            errorCrewPosition && <p style={{ color: "red" }}>{errorCrewPosition}</p>
                        }

                        <TextBox req={true} value={crewSchedule.start_date ? moment(crewSchedule.start_date).format("yyyy-MM-DD") : ""} controlType="date" placeholder=""
                            caption="Tanggal Mulai" setValueFunction={(e) => {
                                if (e.target.value === "") {
                                    setCrewSchedule({ ...crewSchedule, start_date: null })
                                } else {
                                    if (moment(e.target.value).format("yyyy-MM-DD") >= moment(selectedVessel.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(selectedVessel.end_date).format("yyyy-MM-DD")) {
                                        if (moment(e.target.value).format("yyyy-MM-DD") > moment(crewSchedule.end_date).format("yyyy-MM-DD")) {
                                            setErrorStartDate("Tanggal Mulai Tidak Bisa Lebih Dari Tanggal Berakhir");
                                            setCrewSchedule({ ...crewSchedule, start_date: null })
                                        } else {
                                            setErrorStartDate("");
                                            let errorsDate = 0
                                            for (let index = 0; index < listCrewSchedule.length; index++) {
                                                const scheduleTmp = listCrewSchedule[index];
                                                if ((moment(e.target.value).format("yyyy-MM-DD") >= moment(scheduleTmp.start_date).format("yyyy-MM-DD")) && (moment(e.target.value).format("yyyy-MM-DD") <= moment(scheduleTmp.end_date).format("yyyy-MM-DD"))) {
                                                    setErrorStartDate('Jadwal Sudah Terisi');
                                                    errorsDate = 1;
                                                    setCrewSchedule({ ...crewSchedule, start_date: null });
                                                }

                                            }
                                            if (errorsDate === 0) {
                                                setCrewSchedule({ ...crewSchedule, start_date: e.target.value });
                                                setErrorStartDate("")
                                            }

                                        }
                                    } else {
                                        setCrewSchedule({ ...crewSchedule, start_date: null })
                                        setErrorStartDate(`Tanggal Mulai Tidak Boleh Kurang dari ${moment(selectedVessel.start_date).format("DD-MM-yyyy")} atau lebih dari ${moment(selectedVessel.end_date).format("DD-MM-yyyy")}`)
                                    }


                                }

                            }} />

                        {
                            errorStartDate && <p style={{ color: "red" }}>{errorStartDate}</p>
                        }

                        <TextBox req={true} value={crewSchedule.end_date ? moment(crewSchedule.end_date).format("yyyy-MM-DD") : ""} controlType="date" placeholder=""
                            caption="Tanggal Berakhir" setValueFunction={(e) => {
                                let today = new Date();
                                if (e.target.value === "") {
                                    setCrewSchedule({ ...crewSchedule, end_date: null })
                                } else {
                                    if (moment(e.target.value).format("yyyy-MM-DD") >= moment(selectedVessel.start_date).format("yyyy-MM-DD") && moment(e.target.value).format("yyyy-MM-DD") <= moment(selectedVessel.end_date).format("yyyy-MM-DD")) {
                                        if (moment(e.target.value).format("yyyy-MM-DD") < moment(crewSchedule.start_date).format("yyyy-MM-DD")) {
                                            setErrorEndDate('Tanggal Berakhir Tidak Boleh Kurang Dari Tanggal Mulai');
                                            setCrewSchedule({ ...crewSchedule, end_date: null });
                                        } else {
                                            setErrorEndDate("")
                                            let errorsDate = 0
                                            for (let index = 0; index < listCrewSchedule.length; index++) {
                                                const scheduleTmp = listCrewSchedule[index];
                                                if ((moment(e.target.value).format("yyyy-MM-DD") >= moment(scheduleTmp.start_date).format("yyyy-MM-DD")) && (moment(e.target.value).format("yyyy-MM-DD") <= moment(scheduleTmp.end_date).format("yyyy-MM-DD"))) {
                                                    setErrorEndDate('Jadwal Sudah Terisi');
                                                    errorsDate = 1;
                                                    setCrewSchedule({ ...crewSchedule, end_date: null });
                                                }

                                            }
                                            if (errorsDate === 0) {
                                                setCrewSchedule({ ...crewSchedule, end_date: e.target.value });
                                                setErrorEndDate("")
                                            }

                                        }

                                    } else {
                                        setCrewSchedule({ ...crewSchedule, end_date: null })
                                        setErrorEndDate(`Tanggal Berakhir Tidak Boleh Kurang dari ${moment(selectedVessel.start_date).format("DD-MM-yyyy")} atau lebih dari ${moment(selectedVessel.end_date).format("DD-MM-yyyy")}`)
                                    }

                                }

                            }} />

                        {
                            errorEndDate && <p style={{ color: "red" }}>{errorEndDate}</p>
                        }



                        <div style={{ paddingBottom: 10 }}></div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                            <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : disabledButtonCrew} type="submit" >Simpan</Button>
                            <Button className="cancel" variant="danger" onClick={() => {
                                resetCrewSchedule();
                                setCrewScheduleModal(false);
                                setErrorStartDate("");
                                setErrorEndDate("");
                            }}>Batal</Button>
                        </div>

                    </Form>



                </div>

            </Modal>

            <Modal size="xl" show={contractHistoryModal} onHide={() => {
                setContractHistoryModal(false);
            }} >
                <ContainerBox
                    containerPos="inner"
                    titleCaption={`Detail Kontrak`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div id="button-color">
                            <Button variant="primary" style={{

                            }} onClick={() => {
                                setContractHistoryModal(false);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquare size={32} /></div>

                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{

                            padding: 10,
                        }}>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setContractHistoryModal(false);

                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            flex: 1
                                        }}>#</div>

                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            flex: 2
                                        }}>Kontrak Ke</div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            flex: 2
                                        }}>Tanggal Mulai</div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            flex: 2
                                        }}>Tanggal Berakhir</div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            flex: 2
                                        }}>Durasi Kontrak (Bulan)</div>
                                    </div>
                                    {
                                        listContractHistory && listContractHistory.map((list, index) => {
                                            return (
                                                <div key={index} style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5,
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flex: 1
                                                    }}>{index + 1}</div>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flex: 2,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        <Form.Control value={list.contract_status} onChange={(e) => {
                                                            let newList = listContractHistory
                                                            newList[index].contract_status = e.target.value;
                                                            setListContractHistory(newList.slice(0));
                                                        }} required></Form.Control>


                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "row",
                                                        flex: 2,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        <Form.Control value={list.start_date ? moment(list.start_date).format("yyyy-MM-DD") : ""} type="date" onChange={(e) => {
                                                            let newList = listContractHistory
                                                            newList[index].start_date = e.target.value;
                                                            setListContractHistory(newList.slice(0));
                                                        }} required></Form.Control>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flex: 2,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        <Form.Control value={list.end_date ? moment(list.end_date).format("yyyy-MM-DD") : ""} type="date" onChange={(e) => {
                                                            let newList = listContractHistory
                                                            newList[index].end_date = e.target.value;
                                                            setListContractHistory(newList.slice(0));
                                                        }} required></Form.Control>

                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flex: 2,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        <Form.Control disabled value={list.contract_duration} type="text" onChange={(e) => {
                                                            let newList = listContractHistory
                                                            newList[index].contract_duration = e.target.value;
                                                            setListContractHistory(newList.slice(0));
                                                        }} required></Form.Control>

                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        <Button onClick={() => {
                                                            if (window.confirm(`${t("detail_inventory_requisition.delete_confirmation")}`)) {
                                                                let list = [...listContractHistory];
                                                                list.splice(index, 1);
                                                                setListContractHistory(list);
                                                            }

                                                        }} style={{ maxWidth: 50 }} variant="danger" ><Trash /></Button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>


                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    padding: 5,
                                    flexDirection: "column"

                                }}>
                                    <Button variant="primary" onClick={() => {
                                        let newParameter = {
                                            contract_status: "",
                                            contract_duration: "",
                                            start_date: listContractHistory.length === 0 ? staff.join_date : null,
                                            end_date: null,
                                            staff_id: location.state.staff_id
                                        };
                                        if (listContractHistory.length === 0) {
                                            let newList = [];
                                            newList.push(newParameter);
                                            setListContractHistory(newList)
                                        } else {
                                            let newList = listContractHistory;
                                            newList.push(newParameter);
                                            setListContractHistory(newList.slice(0))
                                        }

                                    }} ><FilePlusFill size={40} /></Button>
                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    padding: 5,
                                    flexDirection: "column"

                                }}>
                                    <Button variant="primary" type="submit" >Simpan</Button>
                                </div>
                            </Form>
                        </div>
                    }
                />


            </Modal>
        </>
    )

}
