import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import { getDailyReport, isTokenValid, getCountDailyReport, deleteDailyReport, getUserAccessById, getReportDailyReportV2 } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash, PrinterFill } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import moment from "moment";



export default function DailyReportV2Page() {
    const [listDailyReport, setListDailyReport] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [removeId, setRemoveId] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [dailyReportId, setDailyReportId] = useState("");
    const [reportDate, setReportDate] = useState("");
    const [docxData, setDocxData] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Tanggal Laporan",
        value: "dailyReport.report_date"
    });
    const orderByList = [{
        label: "Tanggal Laporan",
        value: "dailyReport.report_date"
    },
    {
        label: "Nama Teknisi",
        value: "staff.staff_name"
    }
];
    const [descending, setDescending] = useState(false);

    


   

  

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadDailyReport();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadDailyReport();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadDailyReport();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadDailyReport();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0) {
            loadDailyReport()
        }
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0)
            loadDailyReport()
    }, [descending]);

    useEffect(() => {
        if (removeId !== "")
            removeList();
    }, [removeId])

    
    useEffect(() => {
        if (dailyReportId !== "")
            createDocument();
    }, [dailyReportId]);

    useEffect(() => {
        if (docxData.size > 0) {
            downloadDocxFile();
        }
    }, [docxData]);


    const loadDailyReport = async () => {
        try {
            let count = await getCountDailyReport(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let listDailyReport = await getDailyReport(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListDailyReport(listDailyReport)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception)
        }
    }

    const removeList = async () => {
        try {
            let response = await deleteDailyReport(cookies.token, removeId);
            if (response === 0) {
                alert('Data Telah Dihapus');
                loadDailyReport();
            }
            setRemoveId("")
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setDeletedButton(true);
            setUpdatedButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        
        element.href = URL.createObjectURL(file);
        element.download = `dailyReport- ${moment(reportDate).format("DD-MM-yyyy")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        
        URL.revokeObjectURL(file);
        setLoading(false);
        setDisabledButton(false);
    }

    const createDocument = async () => {
        try {
            let docx = await getReportDailyReportV2(cookies.token, dailyReportId)
            if (docx.size) {
                setDocxData(docx);
            }else{
                alert('Gagal Mengunduh Dokumen');
                setLoading(false);
                setDisabledButton(false);
            }
            setDailyReportId("")
        } catch (exception) {
            console.log(exception)
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Laporan Harian "
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/DailyReportV2/Detail", { state: { reportId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Data</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} dailyrpt="text" placeholder="Kata Kunci (lokasi, nama teknisi)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadDailyReport();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadDailyReport();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setResetSearch(1);
                                            // await loadDailyReport();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Nama Teknisi</div>
                                    <div style={{ flex: 4 }} className="table-header-content">Tanggal Laporan</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Lokasi</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Keterangan</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Cetak</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listDailyReport.map((dailyrpt, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 4, justifyContent: "center" }} className="table-body-content"><p>{dailyrpt.staff_name}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{moment(dailyrpt.report_date).format("DD-MM-yyyy")}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content"><p>{dailyrpt.location}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content"><p>{dailyrpt.description}</p></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/DailyReportV2/Detail", { state: { reportId: dailyrpt.id } });
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={disabledButton} variant="primary" onClick={() => {
                                                        setDisabledButton(true);
                                                        setReportDate(dailyrpt.report_date);
                                                        setDailyReportId(dailyrpt.id);
                                                        setLoading(true);
                                                    }}><PrinterFill />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`Apakah Anda Yakin Menghapus Data ${dailyrpt.staff_name}`))
                                                            setRemoveId(dailyrpt.id)
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>
        </>
    );

}