import "../App.css";
import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { Form, option, Button, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";
import Select from 'react-select';
import { Trash, FilePlusFill, PencilFill, XSquareFill, CameraFill, Image, PeopleFill, PersonPlusFill, PersonLinesFill, EyeFill } from "react-bootstrap-icons";
import {
    isTokenValid, getVesselEquipmentByEquipmentCategory, convertBase64, convertBase64Image, getManufacturer, getEquipmentPosition,
    insertUpdateVesselxEquipment, getPhotoCrew
} from "../Helpers/ApplicationHelpers";
import { useCookies } from "react-cookie";
import { convert } from 'html-to-text'
import { Editor } from '@tinymce/tinymce-react';
import ContainerBox from "./ContainerBox";
import { Trans, useTranslation } from "react-i18next";

function DailyReportListJob({
    index,
    listJob,
    listTroubleshootingJob,
    listEquipment,
    vesselId,
    dailyReportId,
    dailyReport,
    handleOnBlur,
    handleDelete,
    handleDeleteDailyReportJob,
    resetComponentProps,
    listStaff,
    timePeriode,
    // setDisableAddDetail,
    disabledAddDetail,
    handleDeleteDailyReportCrew,
    listProject,
    
}) {
    const { t, i18n } = useTranslation();

    const [cookies, setCookie] = useCookies(["token"]);
    const [indexStaffPhoto, setIndexStaffPhoto] = useState("");
    const [indexEquipment, setIndexEquipment] = useState("");
    const [staffPhoto, setStaffPhoto] = useState("");
    const [listEquipmentNew, setListEquipmentNew] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [errorStaffName, setErrorStaffName] = useState("");
    const [listVesselEquipmentNew, setListVesselEquipmentNew] = useState([]);
    const [addListCrew, setAddListCrew] = useState(false);
    const [indexJobDescription, setIndexJobDescription] = useState("");
    const [indexJobProcess, setIndexJobProcess] = useState("");
    const [indexJobConclusion, setIndexJobConclusion] = useState("");
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [indexJobRecomendation, setIndexJobRecomendation] = useState("");
    const [indexCrewModal, setIndexCrewModal] = useState("");
    const [crewModal, setCrewModal] = useState("");
    const [descriptionModal, setDescriptionModal] = useState("");
    const [processModal, setProcessModal] = useState("");
    const [conclusionModal, setConclusionModal] = useState("");
    const [jobNameError, setJobNameError] = useState("");
    const [recomendationModal, setRecomendationModal] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [fotoModal, setFotoModal] = useState("");
    const [timePeriodeNew, setTimePeriodeNew] = useState({});
    const [listProjectNew, setListProjectNew] = useState([]);
    const [crewId, setCrewId] = useState("");
    const [listJobNew, setListJobNew] = useState([]);
    const [dailyReportNew, setDailyReportNew] = useState({});
    const [addJobButton, setAddJobButton] = useState(false);
    const [resetComponent, setResetComponent] = useState(false);
    const [indexFotoModal, setIndexFotoModal] = useState("");
    const options = {
        wordwrap: 130
    }

    useEffect(() => {
        resetComponentProps && setResetComponent(false);
    }, [resetComponent]);

    useEffect(() => {
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
        }
        if (timePeriode) {
            setTimePeriodeNew(timePeriode);
        }
    }, []);

    useEffect(() => {
        if (timePeriode) {
            setTimePeriodeNew(timePeriode)
        }

        if (listJob) {
            setListJobNew(listJob);
        }
        if (listEquipment) {
            setListEquipmentNew(listEquipment.slice(0));
        }
        if (dailyReport) {
            setDailyReportNew(dailyReport);
        }

        if (listProject) {
            setListProjectNew(listProject);
        }



    }, [timePeriode])

    useEffect(() => {
        if (timePeriodeNew.start_date !== null) {
            setStartDate(timePeriodeNew.start_date)
        }
        if (timePeriodeNew.end_date !== null) {
            setEndDate(timePeriodeNew.end_date);
        }
    }, [timePeriodeNew])

    useEffect(() => {
        if (startDate) {
            let newDate = new Date(startDate);
            let newStartDate = newDate.toISOString();
            let timePeriodeTmp = timePeriodeNew;
            timePeriodeTmp.start_date = newStartDate;
            setTimePeriodeNew(timePeriodeTmp);
            handleOnBlur(timePeriodeTmp, listJobNew, index);
        }
    }, [startDate])

    useEffect(() => {
        if (endDate) {
            let newDate = new Date(endDate);
            let newEndDate = newDate.toISOString();
            let timePeriodeTmp = timePeriodeNew;
            timePeriodeTmp.end_date = newEndDate;
            setTimePeriodeNew(timePeriodeTmp);
            handleOnBlur(timePeriodeTmp, listJobNew, index);
        }
    }, [endDate])

    useEffect(() => {
        if (indexJobDescription !== "") {
            setDescriptionModal(true);
        }
    }, [indexJobDescription]);

    useEffect(() => {
        if (indexJobProcess !== "") {
            setProcessModal(true);
        }
    }, [indexJobProcess]);

    useEffect(() => {
        if (indexJobConclusion !== "") {
            setConclusionModal(true);
        }
    }, [indexJobConclusion]);

    useEffect(() => {
        if (indexJobRecomendation !== "") {
            setRecomendationModal(true);
        }
    }, [indexJobRecomendation]);

    useEffect(() => {
        if (indexFotoModal !== "") {
            setFotoModal(true);
        }
    }, [indexFotoModal]);

    useEffect(() => {
        if (crewId !== "") {
            getPhoto();
        }
    }, [crewId])

    useEffect(()=>{
        if(indexEquipment !== ""){
            loadVesselEquipment();
        }
    },[indexEquipment])

    useEffect(() => {
        if (startDate === null || startDate === undefined) {
            setAddJobButton(true);
            disabledAddDetail(true);
            setStartDateError(`${t("detail_daily_report.error_empty_field")}`);
        } else {
            setStartDateError("");
        }

        if (endDate === null || endDate === undefined) {
            disabledAddDetail(true)
            setEndDateError(`${t("detail_daily_report.error_empty_field")}`);
        } else {
            setAddJobButton(false);
            disabledAddDetail(false)
            setEndDateError("")
        }
        // if(startDate !== null && endDate !== null){
        //     disabledAddDetail(false)
        // }
    }, [startDate, endDate])

    useEffect(() => {
        // console.log(listJobNew);
      
        if (listJobNew.length > 0) {
            if(listJobNew[0].equipment_id){
                setIndexEquipment(listJobNew[0].equipment_id);
            }
            for (let index = 0; index < listJobNew.length; index++) {
                const listJobTmp = listJobNew[index];
                if (listJobTmp.job_name === "") {
                    setJobNameError(`${t("detail_daily_report.error_list_job")}`)
                    disabledAddDetail(true);
                    setAddJobButton(true);
                } else {
                    setJobNameError("");
                    disabledAddDetail(false);
                    setAddJobButton(false);
                }
            }
        } else {
            setJobNameError("");
            disabledAddDetail(false);
            setAddJobButton(false);
        }

    }, [listJobNew])

    useEffect(() => {
        if (indexCrewModal !== "") {
            setCrewModal(true);
        }
    }, [indexCrewModal]);

  

    const loadVesselEquipment = async () => {
        try {
            let listVesselEquipmentTmp = await getVesselEquipmentByEquipmentCategory(cookies.token, indexEquipment, vesselId);
            setListVesselEquipmentNew(listVesselEquipmentTmp);
            setIndexEquipment("");

        } catch (exception) {
            console.log(exception)
        }
    }

    const getPhoto = async () => {
        try {
            let response = await getPhotoCrew(cookies.token, crewId);
            // console.log(response);
            setStaffPhoto(response.image_base64);
            setCrewId("");
        } catch (exception) {

        }
    }



    return (
        <div >
            <div style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                flexWrap: "nowrap",
                width: "100%"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 30,
                    flexWrap: "nowrap",
                    width: "100%"
                }}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header style={{
                                // borderStyle: "solid",
                                // borderWidth: 8,
                                borderRadius: 5,
                            }}>
                                <div style={{
                                    display:"flex",
                                    flexDirection:"column"
                                }}>
                                    <h5>{startDate && endDate ? moment(startDate).format("DD-MMMM-yyyy HH:mm") + ' - ' + moment(endDate).format("DD-MMMM-yyyy HH:mm"): "" }</h5>
                                </div>


                            </Accordion.Header>
                            <Accordion.Body style={{
                                // backgroundColor: `${troubleshootingEquipmentNew.background_color}`
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    width: "100%"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            paddingRight: 5
                                        }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{t("detail_daily_report.field_start_date")}</Form.Label>
                                                <Form.Control required type="datetime-local" value={startDate ? moment(startDate).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                   if (e.target.value === "") {
                                                        setStartDate(null);
                                                        setEndDate(null);
                                                    } else {
                                                        setStartDate(e.target.value);
                                                    }

                                                }}></Form.Control>
                                            </Form.Group>
                                            {
                                                startDateError && <p style={{ color: "red" }}>{startDateError}</p>
                                            }
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            paddingLeft: 5
                                        }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{t("detail_daily_report.field_end_date")}</Form.Label>
                                                <Form.Control disabled={!startDate} required type="datetime-local" value={endDate ? moment(endDate).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                    if (e.target.value === "") {
                                                        setEndDate(null)
                                                    } else {
                                                        if (moment(e.target.value).format("yyyy-MM-DD HH:mm") < moment(startDate).format("yyyy-MM-DD HH:mm")) {
                                                            setEndDateError(`${t("detail_daily_report.error_end_date")}`);
                                                            setEndDate(null);
                                                        } else {
                                                            setEndDateError("")
                                                            setEndDate(e.target.value);
                                                        }

                                                    }

                                                }}></Form.Control>
                                            </Form.Group>
                                            {
                                                endDateError && <p style={{ color: "red" }}>{endDateError}</p>
                                            }
                                        </div>
                                    </div>

                                    {listJobNew &&
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }}>

                                            {
                                                listJobNew.map((jobs, idx) => {
                                                    return (

                                                        <div key={idx} style={{ padding: 5 }}>
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                                padding: 5,
                                                                borderWidth: 2,
                                                                borderRadius: 5,
                                                                borderStyle: "solid",
                                                                borderColor: "rgba(3, 30, 103, 1)"
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row"
                                                                }}>
                                                                
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        flex: 25,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_job_name")}</Form.Label>
                                                                        <Form.Control required onBlur={e => handleOnBlur(timePeriodeNew, listJobNew, index)} value={jobs.job_name} onChange={(e) => {

                                                                            let detailJobs = listJobNew;
                                                                            let orderNumber = ""
                                                                            if (idx < 1) {
                                                                                orderNumber = idx + 1
                                                                            } else {
                                                                                orderNumber = Number(detailJobs[idx - 1].order_number) + 1
                                                                            }

                                                                            detailJobs[idx].job_name = e.target.value
                                                                            detailJobs[idx].order_number = orderNumber
                                                                            setListJobNew(detailJobs.slice(0));


                                                                        }} placeholder=""></Form.Control>

                                                                    </div>
                                                                    {/* <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Button onClick={() => {
                                                                            setIndexCrewModal(idx)
                                                                            let newParameter = {

                                                                            };

                                                                            if (!jobs.list_crew) {
                                                                                let detailJobs = listJobNew;
                                                                                detailJobs[idx].list_crew = []


                                                                                setListJobNew(detailJobs.slice(0))
                                                                            } else {

                                                                            }

                                                                        }} style={{ maxWidth: 50 }} variant="primary"><PersonPlusFill /></Button>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Link to={`/DailyReport/Technician?id=${jobs.id}&dailyReportId=${jobs.daily_report_id}`} style={{
                                                                            pointerEvents: dailyReportId === 0 || jobs.id === undefined ? "none" : ""
                                                                        }} target="_blank">
                                                                            <Button disabled={dailyReportId === 0 || jobs.id === undefined} onClick={() => {

                                                                            }} style={{ maxWidth: 50 }} variant="primary"><PersonLinesFill /></Button>
                                                                        </Link>
                                                                    </div> */}
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Button onClick={() => {
                                                                            setIndexFotoModal(idx)
                                                                            let newParameter = {

                                                                            };

                                                                            if (!jobs.list_photo) {
                                                                                let detailJobs = listJobNew;
                                                                                detailJobs[idx].list_photo = []


                                                                                setListJobNew(detailJobs.slice(0))
                                                                            } else {

                                                                            }

                                                                        }} style={{ maxWidth: 50 }} variant="primary"><CameraFill /></Button>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Link to={`/DailyReport/Photo?id=${jobs.id}&reportId=${jobs.daily_report_id}`} style={{
                                                                            pointerEvents: dailyReportId === 0 || jobs.id === undefined ? "none" : ""
                                                                        }} target="_blank">
                                                                            <Button disabled={dailyReportId === 0 || jobs.id === undefined} onClick={() => {

                                                                            }} style={{ maxWidth: 50 }} variant="primary"><Image /></Button>
                                                                        </Link>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Link to={`/DailyReport/TableData?dailyReportJobId=${jobs.id}&dailyReportId=${jobs.daily_report_id}&numberCylinder=${jobs.number_of_cylinder}`} style={{
                                                                            pointerEvents: jobs.daily_report_id === 0 || jobs.id === undefined || jobs.vessel_equipment_id === null ? "none" : ""
                                                                        }} target="_blank">
                                                                            <Button disabled={dailyReportId === 0 || jobs.id === undefined || jobs.vessel_equipment_id === null} onClick={() => {

                                                                            }} style={{ maxWidth: 50 }} variant="primary"><EyeFill /></Button>
                                                                        </Link>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-end",
                                                                        flex: 1,
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5
                                                                    }}>
                                                                        <div style={{ paddingTop: 30 }}></div>
                                                                        <Button onClick={() => {
                                                                            if (window.confirm(`${t("detail_daily_report.delete_confirmation")}`)) {
                                                                                let newList = timePeriodeNew
                                                                                if (!dailyReportNew.deleted_job) {
                                                                                    let list = [];
                                                                                    let newList = listJobNew
                                                                                    list.push(newList[idx])
                                                                                    newList.splice(idx, 1);


                                                                                    let reportTmp = dailyReportNew;
                                                                                    reportTmp.deleted_job = list;

                                                                                    setDailyReportNew(reportTmp);
                                                                                    setListJobNew(newList.slice(0));
                                                                                    handleDeleteDailyReportJob(reportTmp, index);
                                                                                } else {

                                                                                    let newList = listJobNew;
                                                                                    let listDeleted = dailyReportNew
                                                                                    listDeleted.deleted_job.push(newList[idx]);
                                                                                    newList.splice(idx, 1)
                                                                                    setDailyReportNew(listDeleted);
                                                                                    handleDeleteDailyReportJob(listDeleted, index)
                                                                                    // setServiceJob({ ...troubleshooting, deleted_job: listDeleted });
                                                                                    setListJobNew(newList.slice(0));
                                                                                }

                                                                                handleOnBlur(timePeriodeNew, listJobNew, index);

                                                                            }

                                                                        }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>

                                                                    </div>

                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                }}>

                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_project_name")}</Form.Label>
                                                                        <Form.Group className="mb-3" style={{
                                                                            // width: "80%"
                                                                        }}>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.project_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listProjectNew} value={listProjectNew.filter(function (option) {
                                                                                    return option.id === jobs.project_id;
                                                                                })} onChange={(e) => {
                                                                                    let detailJobs = listJobNew;
                                                                                    if (e === null) {
                                                                                        detailJobs[idx].project_id = null
                                                                                        detailJobs[idx].vessel_id = null
                                                                                        setListJobNew(detailJobs.slice(0));
                                                                                    } else {
                                                                                        detailJobs[idx].project_id = e.id;
                                                                                        detailJobs[idx].vessel_id = e.vessel_id
                                                                                        setListJobNew(detailJobs.slice(0));
                                                                                    }

                                                                                    handleOnBlur(timePeriodeNew, listJobNew, index)
                                                                                }}
                                                                                isClearable
                                                                                required
                                                                            />

                                                                        </Form.Group>


                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_equipment_category")}</Form.Label>
                                                                        <Select placeholder={""}
                                                                            getOptionLabel={(item) => {
                                                                                return item.equipment_name;
                                                                            }} clearValue={true}
                                                                            getOptionValue={(item) => {
                                                                                return item.id;
                                                                            }}
                                                                            options={listEquipmentNew} value={listEquipmentNew.filter(function (option) {
                                                                                return option.id === jobs.equipment_id;
                                                                            })} onChange={(e) => {
                                                                                let detailJobs = listJobNew;
                                                                                if (e === null) {
                                                                                    detailJobs[idx].equipment_id = null
                                                                                    // detailJobs[idx].vessel_equipment_id = null

                                                                                    setIndexEquipment("");
                                                                                    setListVesselEquipmentNew([]);
                                                                                    // loadVesselEquipment();
                                                                                    setListJobNew(detailJobs.slice(0));
                                                                                } else {
                                                                                    setIndexEquipment(e.id);
                                                                                    // loadVesselEquipment();
                                                                                    detailJobs[idx].equipment_id = e.id;

                                                                                    setListJobNew(detailJobs.slice(0));
                                                                                }

                                                                                handleOnBlur(timePeriodeNew, listJobNew, index)
                                                                            }}
                                                                            isClearable
                                                                            required
                                                                        />


                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_vessel_equipment")}</Form.Label>
                                                                        <Select placeholder={""}
                                                                             getOptionLabel={(item) => {
                                                                                if (item.id) {
                                                                                    return (item.manufacturer_name ? item.manufacturer_name : " ") + ' - ' + item.model + `(${item.serial_number ? item.serial_number : ""})` + ' : ' + (item.position? item.position : "")
                                                                                } else {
                                                                                    return ("");
                                                                                }
                                                                            }}clearValue={true}
                                                                            getOptionValue={(item) => {
                                                                                return item.id;
                                                                            }}
                                                                            options={listVesselEquipmentNew} value={listVesselEquipmentNew.filter(function (option) {
                                                                                return option.id === jobs.vessel_equipment_id;
                                                                            })} onChange={(e) => {
                                                                                console.log(e);
                                                                                let detailJobs = listJobNew;
                                                                                if (e === null) {
                                                                                    detailJobs[idx].vessel_equipment_id = null
                                                                                
                                                                                    setListJobNew(detailJobs.slice(0));
                                                                                } else {
                                                                                   
                                                                                    detailJobs[idx].vessel_equipment_id = e.id;
                                                                                    detailJobs[idx].number_of_cylinder = e.number_of_cylinder
                                                                                    setListJobNew(detailJobs.slice(0));
                                                                                }

                                                                                handleOnBlur(timePeriodeNew, listJobNew, index)
                                                                            }}
                                                                            isClearable
                                                                            required
                                                                        />

                                                                    </div>

                                                                </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                }}>

                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_description")}</Form.Label>
                                                                        <InputGroup className="mb-2">


                                                                            <Form.Control className="descriptionelips" disabled type="text" value={jobs.description ? convert(jobs.description, options) : ""} placeholder=""></Form.Control>
                                                                            <InputGroup.Text style={{
                                                                                backgroundColor: "rgba(3, 30, 103, 1)"
                                                                            }} onClick={() => {
                                                                                setIndexJobDescription(idx);
                                                                            }}><PencilFill color="white" /></InputGroup.Text>
                                                                        </InputGroup>

                                                                    </div>
                                                                    {/* <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_process_job")}</Form.Label>
                                                                        <InputGroup className="mb-2">


                                                                            <Form.Control className="descriptionelips" disabled type="text" value={jobs.job_process ? convert(jobs.job_process, options) : ""} placeholder=""></Form.Control>
                                                                            <InputGroup.Text style={{
                                                                                backgroundColor: "rgba(3, 30, 103, 1)"
                                                                            }} onClick={() => {
                                                                                setIndexJobProcess(idx);
                                                                            }}><PencilFill color="white" /></InputGroup.Text>
                                                                        </InputGroup>

                                                                    </div> */}
                                                                    {/* <div style={{
                                                                        display: "flex",
                                                                        // width: "100%",

                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 1
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_start_date")}</Form.Label>
                                                                        <Form.Control style={{
                                                                            width: 200
                                                                        }} type="datetime-local" value={jobs.job_start_date ? moment(jobs.job_start_date).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                                            let detailJobs = listJobNew;
                                                                            if (e.target.value === "") {
                                                                                detailJobs[idx].job_start_date = null;
                                                                            } else {
                                                                                detailJobs[idx].job_start_date = e.target.value;
                                                                            }

                                                                            setListJobNew(detailJobs.slice(0))
                                                                        }}></Form.Control>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        // width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 1
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_end_date")}</Form.Label>
                                                                        <Form.Control style={{
                                                                            width: 200
                                                                        }} type="datetime-local" value={jobs.job_end_date ? moment(jobs.job_end_date).format("yyyy-MM-DD HH:mm") : ""} onChange={(e) => {
                                                                            let detailJobs = listJobNew;
                                                                            if (e.target.value === "") {
                                                                                detailJobs[idx].job_end_date = null;
                                                                            } else {
                                                                                detailJobs[idx].job_end_date = e.target.value;
                                                                            }

                                                                            setListJobNew(detailJobs.slice(0))
                                                                        }}></Form.Control>

                                                                    </div> */}
                                                                </div>

                                                                {/* <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_conclusion")}</Form.Label>
                                                                        <InputGroup className="mb-2">


                                                                            <Form.Control className="descriptionelips" disabled type="text" value={jobs.job_conclusion ? convert(jobs.job_conclusion, options) : ""} placeholder=""></Form.Control>
                                                                            <InputGroup.Text style={{
                                                                                backgroundColor: "rgba(3, 30, 103, 1)"
                                                                            }} onClick={() => {
                                                                                setIndexJobConclusion(idx);
                                                                            }}><PencilFill color="white" /></InputGroup.Text>
                                                                        </InputGroup>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10
                                                                    }}>
                                                                        <Form.Label>{t("detail_daily_report.field_recommendation")}</Form.Label>
                                                                        <InputGroup className="mb-2">


                                                                            <Form.Control className="descriptionelips" disabled type="text" value={jobs.job_recommendation ? convert(jobs.job_recommendation, options) : ""} placeholder=""></Form.Control>
                                                                            <InputGroup.Text style={{
                                                                                backgroundColor: "rgba(3, 30, 103, 1)"
                                                                            }} onClick={() => {
                                                                                setIndexJobRecomendation(idx);
                                                                            }}><PencilFill color="white" /></InputGroup.Text>
                                                                        </InputGroup>

                                                                    </div>
                                                                </div> */}

                                                                {/* <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                    paddingLeft: 10,
                                                                    paddingRight: 10
                                                                }}>

                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 5,
                                                                        paddingRight: 5,
                                                                        flex: 10,
                                                                        borderStyle: "groove",
                                                                        borderRadius: 10
                                                                    }}>
                                                                        <div>{t("detail_daily_report.list_technician")}</div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            width: "100%",
                                                                            flexDirection: "row",
                                                                            flexWrap: "nowrap",
                                                                            padding: 10
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 4
                                                                            }}>{t("detail_daily_report.table_name1")}</div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 4
                                                                            }}>{t("detail_daily_report.table_name2")}</div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 4
                                                                            }}>{t("detail_daily_report.table_name3")}</div>

                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1
                                                                            }}>{t("detail_daily_report.table_name4")}</div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1
                                                                            }}>{t("detail_daily_report.table_name5")}</div>
                                                                        </div>
                                                                        {
                                                                            jobs.list_crew && jobs.list_crew.map((crew, idxCrew) => {
                                                                                return (
                                                                                    <div key={idxCrew}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flex: 1,
                                                                                            width: "100%",
                                                                                            flexDirection: "row",
                                                                                            flexWrap: "nowrap",
                                                                                            padding: 10
                                                                                        }}>

                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flex: 4,
                                                                                                flexDirection: "column",
                                                                                            }}>
                                                                                                <Form.Group className="mb-3" style={{
                                                                                                    width: "80%"
                                                                                                }}>
                                                                                                    <Select placeholder={""}
                                                                                                        getOptionLabel={(item) => {
                                                                                                            return item.staff_name;
                                                                                                        }} clearValue={true}
                                                                                                        getOptionValue={(item) => {
                                                                                                            return item.id;
                                                                                                        }}
                                                                                                        options={listStaff.filter((p) => {
                                                                                                            return !listJobNew[idx].list_crew.find((x) => {
                                                                                                                return p.staff_id === x.staff_id
                                                                                                            })
                                                                                                        })} value={listStaff.filter(function (option) {
                                                                                                            return option.staff_id === crew.staff_id;
                                                                                                        })} onChange={(e) => {
                                                                                                            let detailJobs = listJobNew;
                                                                                                            if (e === null) {

                                                                                                                detailJobs[idx].list_crew[idxCrew].staff_id = null;
                                                                                                                setAddListCrew(true);
                                                                                                                setErrorStaffName(`${t("detail_daily_report.error_list_technician")}`)


                                                                                                            } else {
                                                                                                                detailJobs[idx].list_crew[idxCrew].staff_id = e.staff_id;
                                                                                                                detailJobs[idx].list_crew[idxCrew].position_name = e.position_name;
                                                                                                                setAddListCrew(false);
                                                                                                                setErrorStaffName("")
                                                                                                            }
                                                                                                            setListJobNew(detailJobs.slice(0));
                                                                                                            handleOnBlur(timePeriodeNew, listJobNew, index)
                                                                                                        }}
                                                                                                        // isClearable
                                                                                                        required
                                                                                                    />

                                                                                                </Form.Group></div>
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flex: 4,
                                                                                                flexDirection: "column"
                                                                                            }}>{crew.position_name}</div>
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flex: 4
                                                                                            }}></div>

                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flex: 1,
                                                                                                flexDirection: "column",
                                                                                                justifyContent: "center"
                                                                                            }}>

                                                                                                <Button onClick={() => {
                                                                                                    setIndexCrewModal(idx);
                                                                                                    setIndexStaffPhoto(idxCrew);
                                                                                                    if (crew.id) {
                                                                                                        setCrewId(crew.id);
                                                                                                        setIndexCrewModal(idx);
                                                                                                        setIndexStaffPhoto(idxCrew);
                                                                                                    } else {
                                                                                                        if (crew.image_base64 !== "") {
                                                                                                            setStaffPhoto(crew.image_base64)
                                                                                                        }
                                                                                                    }
                                                                                                }} style={{ width: 50 }}><CameraFill size={20} /></Button>

                                                                                            </div>
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flex: 1,
                                                                                                flexDirection: "column",
                                                                                                justifyContent: "center"
                                                                                            }}>
                                                                                                <Button style={{ width: 50 }} variant="danger" onClick={() => {
                                                                                                    if (window.confirm(`${t("detail_daily_report.delete_confirmation")}`)) {
                                                                                                        if (!dailyReportNew.deleted_crew) {
                                                                                                            let list = [];
                                                                                                            let newList = listJobNew
                                                                                                            list.push(newList[idx].list_crew[idxCrew])
                                                                                                            newList[idx].list_crew.splice(idxCrew, 1);


                                                                                                            let reportTmp = dailyReportNew;
                                                                                                            reportTmp.deleted_crew = list;

                                                                                                            setDailyReportNew(reportTmp);
                                                                                                            setListJobNew(newList.slice(0));
                                                                                                            handleDeleteDailyReportCrew(reportTmp, index);
                                                                                                        } else {

                                                                                                            let newList = listJobNew;
                                                                                                            let listDeleted = dailyReportNew
                                                                                                            listDeleted.deleted_crew.push(newList[idx].list_crew[idxCrew]);
                                                                                                            newList[idx].list_crew.splice(idxCrew, 1)
                                                                                                            setDailyReportNew(listDeleted);
                                                                                                            handleDeleteDailyReportCrew(listDeleted, index)
                                                                                                            setListJobNew(newList.slice(0));
                                                                                                        }

                                                                                                        handleOnBlur(timePeriodeNew, listJobNew, index);
                                                                                                        setAddListCrew(false);
                                                                                                        setErrorStaffName("");

                                                                                                        //     // let detailJobs = listJobNew;
                                                                                                        //     // detailJobs[idx].list_crew.splice(idxCrew, 1);
                                                                                                        //     // setListJobNew(detailJobs.slice(0))
                                                                                                    }
                                                                                                }}>
                                                                                                    <Trash size={20} />
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ paddingBottom: 10 }}></div>
                                                                                    </div>



                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            errorStaffName && <p style={{ color: "red" }}>{errorStaffName}</p>
                                                                        }
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "column",
                                                                            padding: 5,
                                                                        }}>
                                                                            <Button disabled={addListCrew} variant="primary" onClick={() => {
                                                                                let newParameter = {
                                                                                    image_base64: "",
                                                                                    staff_id: null,
                                                                                };
                                                                                if (!listJobNew[idx].list_crew) {
                                                                                    let newList = listJobNew;
                                                                                    newList[idx].list_crew = []
                                                                                    newList[idx].list_crew.push(newParameter);
                                                                                    setListJobNew(newList.slice(0))
                                                                                } else {
                                                                                    let newList = listJobNew
                                                                                    newList[idx].list_crew.push(newParameter);
                                                                                    setListJobNew(newList.slice(0))
                                                                                }
                                                                                setErrorStaffName(`${t("detail_daily_report.error_list_technician")}`);
                                                                                setAddListCrew(true);
                                                                                disabledAddDetail(true);
                                                                                setAddJobButton(true);



                                                                            }}>{t("detail_daily_report.button_add_technician")}</Button>
                                                                        </div>

                                                                    </div>


                                                                </div> */}

                                                            </div>

                                                        </div>



                                                    )
                                                })
                                            }
                                            {
                                                jobNameError && <p style={{ color: "red" }}>{jobNameError}</p>
                                            }
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",

                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    padding: 5,

                                                }}>
                                                    <Button hidden={listJobNew.length>0} disabled={listJobNew.length === 1} variant="primary" onClick={() => {
                                                        let newParameter = {
                                                            job_name: "",
                                                            project_id: null,
                                                            equipment_id: null,
                                                            // vessel_equipment_id: null,
                                                            job_start_date: null,
                                                            job_end_date: null,
                                                        };
                                                        if (!listJobNew) {
                                                            // let newJobs = []
                                                            let newList = []
                                                            // newList = newJobs;
                                                            newList.push(newParameter);
                                                            setListJobNew(newList.slice(0))
                                                        } else {
                                                            let newList = listJobNew
                                                            newList.push(newParameter);
                                                            // newList.push(newParameter);
                                                            setListJobNew(newList.slice(0))
                                                        }
                                                        // handleOnBlur(troubleshootingEquipmentNew, listTroubleshootingJobNew, index);
                                                        // setServiceEquipmentIndexComponent(index)


                                                    }}><FilePlusFill size={30} /></Button>
                                                </div>

                                            </div>


                                        </div>}
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5,
                                    }}>
                                        <Button variant="danger" onClick={() => {
                                            if (window.confirm(`${t("detail_daily_report.delete_confirmation")}`)) {
                                                handleDelete(index)
                                            }


                                        }}><Trash size={30} /></Button>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>


            <div style={{ paddingBottom: 10 }}></div>
            <Modal size="xl" show={descriptionModal} onHide={
                () => {
                    setDescriptionModal(false);
                    setIndexJobDescription("");
                    handleOnBlur(timePeriodeNew, listJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_daily_report.modal_description")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setDescriptionModal(false);
                                setIndexJobDescription("");
                                handleOnBlur(timePeriodeNew, listJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>


                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexJobDescription !== "" &&
                                            <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listJobNew;
                                                    detailJobs[indexJobDescription].description = evt;
                                                    setListJobNew(detailJobs.slice(0))


                                                }}
                                                value={listJobNew[indexJobDescription].description}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={processModal} onHide={
                () => {
                    setProcessModal(false);
                    setIndexJobProcess("");
                    handleOnBlur(timePeriodeNew, listJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_daily_report.modal_process")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setProcessModal(false);
                                setIndexJobProcess("");
                                handleOnBlur(timePeriodeNew, listJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>



                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexJobProcess !== "" &&
                                            <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listJobNew;
                                                    detailJobs[indexJobProcess].job_process = evt;
                                                    setListJobNew(detailJobs.slice(0))


                                                }}
                                                value={listJobNew[indexJobProcess].job_process}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={conclusionModal} onHide={
                () => {
                    setConclusionModal(false);
                    setIndexJobConclusion("");
                    handleOnBlur(timePeriodeNew, listJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_daily_report.field_conclusion")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setConclusionModal(false);
                                setIndexJobConclusion("");
                                handleOnBlur(timePeriodeNew, listJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>



                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexJobConclusion !== "" &&
                                            <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listJobNew;
                                                    detailJobs[indexJobConclusion].job_conclusion = evt;
                                                    setListJobNew(detailJobs.slice(0))


                                                }}
                                                value={listJobNew[indexJobConclusion].job_conclusion}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={recomendationModal} onHide={
                () => {
                    setRecomendationModal(false);
                    setIndexJobRecomendation("");
                    handleOnBlur(timePeriodeNew, listJobNew, index);
                }
            }>
                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_daily_report.modal_recommendation")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setRecomendationModal(false);
                                setIndexJobRecomendation("");
                                handleOnBlur(timePeriodeNew, listJobNew, index);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5
                                }}>



                                </div>

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                }}>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        flex: 3,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>


                                        {
                                            indexJobRecomendation !== "" &&
                                            <Editor
                                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                                onEditorChange={(evt, editor) => {
                                                    let detailJobs = listJobNew;
                                                    detailJobs[indexJobRecomendation].job_recommendation = evt;
                                                    setListJobNew(detailJobs.slice(0))


                                                }}
                                                value={listJobNew[indexJobRecomendation].job_recommendation}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        }



                                    </div>

                                </div>


                            </div>



                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={fotoModal} onHide={
                () => {
                    setFotoModal(false);
                    setIndexFotoModal("");
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={`${t("detail_daily_report.modal_job_photo")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setFotoModal(false);
                                setIndexFotoModal("");

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("detail_daily_report.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>


                                <Form >
                                    {
                                        indexFotoModal !== "" && listJobNew[indexFotoModal].list_photo.map((foto, index) => {
                                            return (
                                                <div>
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        borderWidth: 2,
                                                        borderRadius: 5,
                                                        borderStyle: "solid",
                                                        borderColor: "rgba(3, 30, 103, 1)",
                                                        padding: 10
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            width: "100%",

                                                        }}>
                                                            <img src={foto.base64_image}
                                                                style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_daily_report.field_photo")}</Form.Label>
                                                                <Form.Control required type="file" onChange={async (e) => {

                                                                    let base64Img = await convertBase64Image(e.target.files[0]);
                                                                    const base64ImgString = base64Img.toString();
                                                                    let detailJobs = listJobNew;
                                                                    detailJobs[indexFotoModal].list_photo[index].base64_image = base64ImgString;
                                                                    setListJobNew(detailJobs.slice(0));

                                                                }}></Form.Control>
                                                            </Form.Group>

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            flex: 1

                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                paddingRight: 5
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("detail_daily_report.field_label")}</Form.Label>
                                                                    <Form.Control required type="text" onChange={(e) => {
                                                                        let detailJobs = listJobNew;
                                                                        detailJobs[indexFotoModal].list_photo[index].label = e.target.value;
                                                                        setListJobNew(detailJobs.slice(0))
                                                                    }} value={foto.label}></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                paddingLeft: 5

                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>{t("detail_daily_report.field_taken_date")}</Form.Label>
                                                                    <Form.Control type="date" onChange={(e) => {
                                                                        let detailJobs = listJobNew;
                                                                        detailJobs[indexFotoModal].list_photo[index].taken_date = e.target.value;
                                                                        setListJobNew(detailJobs.slice(0))
                                                                        // handleOnBlur(troubleshootingEquipmentNew, listTroubleshootingJobNew, index);

                                                                    }} value={foto.taken_date ? moment(foto.taken_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                                </Form.Group>
                                                            </div>


                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: "100%",

                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("detail_daily_report.field_explanation")}</Form.Label>
                                                                <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                    let detailJobs = listJobNew;
                                                                    detailJobs[indexFotoModal].list_photo[index].description = e.target.value;
                                                                    setListJobNew(detailJobs.slice(0))
                                                                }} value={foto.description}></Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            flex: 1,
                                                            paddingLeft: 5,
                                                            paddingRight: 5
                                                        }}>
                                                            <div style={{ paddingTop: 35 }}></div>
                                                            <Button onClick={() => {
                                                                if (window.confirm(`${t("detail_daily_report.delete_confirmation")}`)) {
                                                                    let detailJobs = listJobNew;
                                                                    detailJobs[indexFotoModal].list_photo.splice(index, 1);
                                                                    setListJobNew(detailJobs.slice(0))
                                                                }

                                                            }} variant="danger"><Trash size={20} /></Button>

                                                        </div>
                                                        <div style={{ paddingBottom: 10 }}></div>
                                                    </div>
                                                    <div style={{ paddingBottom: 10 }}></div>
                                                </div>



                                            )
                                        })
                                    }
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5,
                                    }}>
                                        <Button variant="primary" onClick={() => {
                                            let newParameter = {
                                                base64_image: "",
                                                label: "",
                                                taken_date: null,
                                                description: ""
                                            };
                                            if (!listJobNew[indexFotoModal].list_photo) {
                                                let newList = listJobNew
                                                newList[indexFotoModal].list_photo.push(newParameter);
                                                setListJobNew(newList.slice(0))
                                            } else {
                                                let newList = listJobNew
                                                newList[indexFotoModal].list_photo.push(newParameter);
                                                setListJobNew(newList.slice(0))
                                            }

                                        }}>{t("detail_daily_report.button_add_photo")}</Button>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5,
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            paddingRight: 5,
                                        }}><Button style={{ width: 100 }} onClick={() => {
                                            setFotoModal(false);
                                            setIndexFotoModal("")
                                            handleOnBlur(timePeriodeNew, listJobNew, index);
                                        }} variant="primary" >{t("detail_daily_report.save_button")}</Button></div>


                                        <div style={{
                                            paddingLeft: 5,
                                        }}>
                                            <Button style={{ width: 100 }} variant="danger" onClick={() => {
                                                setFotoModal(false);
                                                setIndexFotoModal("")

                                            }}>{t("detail_daily_report.cancel_button")}</Button>

                                        </div>
                                    </div>
                                </Form>




                            </div>






                        </div>

                    }>
                </ContainerBox>
            </Modal>

            <Modal size="xl" show={crewModal} onHide={
                () => {
                    setCrewModal(false);
                    setIndexCrewModal("");
                    setStaffPhoto("");
                    setIndexStaffPhoto("");
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={"List Tekniksi"}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setCrewModal(false);
                                setIndexCrewModal("");
                                setStaffPhoto("");
                                setIndexStaffPhoto("");

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tutup</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>



                                <div style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    width: "100%",

                                }}>
                                    <img src={staffPhoto}
                                        style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                    <Form.Group className="mb-3">
                                        <Form.Label>Foto</Form.Label>
                                        <Form.Control required type="file" onChange={async (e) => {

                                            let base64Img = await convertBase64Image(e.target.files[0]);
                                            const base64ImgString = base64Img.toString();
                                            setStaffPhoto(base64ImgString);

                                        }}></Form.Control>
                                    </Form.Group>

                                </div>


                                <div style={{ paddingBottom: 10 }}></div>






                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    padding: 5,
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        paddingRight: 5,
                                    }}><Button onClick={() => {
                                        let photoTmp = listJobNew;
                                        photoTmp[indexCrewModal].list_crew[indexStaffPhoto].image_base64 = staffPhoto;
                                        setListJobNew(photoTmp.slice(0));
                                        setCrewModal(false);
                                        setIndexCrewModal("");
                                        setIndexStaffPhoto("");
                                        setStaffPhoto("");
                                        handleOnBlur(timePeriodeNew, listJobNew, index);
                                    }} variant="primary" >Simpan</Button></div>

                                    <div style={{
                                        paddingLeft: 5,
                                    }}>
                                        <Button variant="danger" onClick={() => {
                                            setCrewModal(false);
                                            setIndexCrewModal("");
                                            setIndexStaffPhoto("");
                                            setStaffPhoto("");
                                        }}>Batal</Button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    }>
                </ContainerBox>
            </Modal>



        </div>
    )
}



export default DailyReportListJob;
