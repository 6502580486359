import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import config from "../../../../Config/Config";
import { useCookies } from "react-cookie";
import {
    getMeggerResult, deleteMeggerResult, getCountMeggerResult, isTokenValid, insertUpdateMeggerResult, getMeggerResultById,
    getUserAccessById, getVesselByVesselEquipment, findServiceByVessel
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";
import TextBox from "../../../../Components/Textbox";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";
import moment from "moment";



export default function MeggerResultPage() {
    const [listMeggerResult, setListMeggerResult] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [vesselEquipment, setVesselEquipment] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [listServiceJob, setListServiceJob] = useState([]);
    const [selectedServiceJob, setSelectedServiceJob] = useState({});
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [meggerResultId, setMeggerResultId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [meggerResultModal, setMeggerResultModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `Nama Proyek`,
        value: "project.project_name"
    });
    const orderByList = [{
        label: `Nama Proyek`,
        value: "project.project_name"
    }, {
        label: `Nama Kapal`,
        value: "vessel.vessel_name"
    }];
    const [descending, setDescending] = useState(false);
    const [meggerResult, setMeggerResult] = useState({
        id: 0,
        service_id: null,
        u1_n: "",
        v1_n: "",
        w1_n: "",
        description: "",
        vessel_equipment_id: searchParams.get("id"),
        ext_customer_id: cookies.extCustomerId
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setVesselEquipmentId(searchParams.get("id"));
                loadMeggerResult();
                loadAccess();
                loadServiceJob();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadMeggerResult();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadMeggerResult();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadMeggerResult();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadMeggerResult();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadMeggerResult()
    }, [descending]);

    useEffect(() => {
        if (meggerResultId !== "" && meggerResultId !== undefined)
            initMeggerResult();
    }, [meggerResultId]);

    useEffect(() => {
        if (vesselEquipmentId !== "" && vesselEquipmentId !== undefined)
            loadVesselEquipmentData()
    }, [vesselEquipmentId])

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeMeggerResult();
    }, [removeId])

    useEffect(() => {
        if (!meggerResultModal)
            setMeggerResultId("");
    }, [meggerResultModal])

    useEffect(() => {
        if (selectedServiceJob.project_name) {
            setMeggerResult({ ...meggerResult, service_id: selectedServiceJob.id })
        } else {
            setMeggerResult({ ...meggerResult, service_id: null })
        }
    }, [selectedServiceJob])

    useEffect(() => {
        if (listServiceJob.length > 0) {
            if (meggerResult.service_id) {
                let newList = listServiceJob.find(p => p.id === meggerResult.service_id)
                if (newList) {
                    setSelectedServiceJob(newList)
                }
            }
        }
    }, [listServiceJob]);

    useEffect(() => {
        if (meggerResult.service_id) {
            let newList = listServiceJob.find(p => p.id === meggerResult.service_id)
            if (newList) {
                setSelectedServiceJob(newList)
            }
        }
    }, [meggerResult])

    const loadVesselEquipmentData = async () => {
        try {
            let response = await getVesselByVesselEquipment(cookies.token, vesselEquipmentId);
            if (response) {
                setVesselEquipment(response);
            }
        } catch (exception) {
            console.log(exception)
        }
    }


    const loadMeggerResult = async () => {
        try {
            let count = await getCountMeggerResult(cookies.token, searchParams.get("id"), searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);


            let response = await getMeggerResult(cookies.token, searchParams.get("id"), page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListMeggerResult(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }

    }

    const clearMeggerResultModal = async () => {
        let meggerResultTmp = {};
        meggerResultTmp.id = 0;
        meggerResultTmp.service_id = "";
        meggerResultTmp.u1_n = "";
        meggerResultTmp.v1_n = "";
        meggerResultTmp.w1_n = "";
        meggerResultTmp.vessel_equipment_id = searchParams.get("id");
        setSelectedServiceJob({});
        setMeggerResult(meggerResultTmp);
    }

    const saveData = async () => {
        try {
            let meggerResultTmp = meggerResult;
            meggerResultTmp.ext_customer_id = meggerResultTmp.ext_customer_id === null ? null : meggerResultTmp.ext_customer_id
            let response = await insertUpdateMeggerResult(cookies.token, meggerResultTmp);
            if (response.error_code === 0) {
                alert(`${t("index_warehouse.alert_success_save_data")}`);
                setMeggerResultModal(false);
                clearMeggerResultModal();
                loadMeggerResult();
              
            } else {
                alert(`${t("index_warehouse.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const initMeggerResult = async () => {
        try {
            let response = await getMeggerResultById(cookies.token, meggerResultId);
            if (response) {
                setMeggerResult({
                    ...meggerResult,
                    id: response.id,
                    service_id: response.service_id,
                    u1_n: response.u1_n,
                    v1_n: response.v1_n,
                    w1_n: response.w1_n,
                    description: response.description
                })
            }
            setMeggerResultModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeMeggerResult = async () => {
        try {
            let response = await deleteMeggerResult(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_warehouse.delete_success_alert")}`);
                loadMeggerResult();
            } else {
                alert(`${t("index_warehouse.delete_failed_alert")}`);
            }

            setRemoveId("");
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname, cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setUpdatedButton(true);
            setDeletedButton(true);
            // setOrderBy({
            //     label: `${t("index_warehouse.order_name1")}`,
            //     value: "service_id"
            // })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadServiceJob = async () => {
        try {
            let response = await findServiceByVessel(cookies.token,searchParams.get("vesselId"));
            setListServiceJob(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`Megger Result ${vesselEquipment.model} ${vesselEquipment.manufacturer_name}  ${vesselEquipment.vessel_name}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setMeggerResultModal(true);
                                setMeggerResult({ ...meggerResult, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_warehouse.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`Nama Proyek`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadMeggerResult();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadMeggerResult();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadMeggerResult();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Proyek</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Kapal</div>
                                    <div style={{ flex: 2 }} className="table-header-content">U1 N</div>
                                    <div style={{ flex: 2 }} className="table-header-content">V1 N</div>
                                    <div style={{ flex: 2 }} className="table-header-content">W1 N</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Deskripsi</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listMeggerResult.map((megger, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{megger.project_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{megger.vessel_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{megger.u1_n}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{megger.v1_n}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{megger.w1_n}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{megger.description}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idMeggerResult = megger.id;
                                                        setMeggerResultId(idMeggerResult);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                        let idMeggerResult = megger.id;
                                                        if (window.confirm(`${t("index_warehouse.delete_confirmation")} ${megger.project_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idMeggerResult);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />

                <Modal show={meggerResultModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setMeggerResultModal(false);
                        clearMeggerResultModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Data Hasil Megger </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb3">
                                <Form.Label>Nama Servis</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        if (item.id) {
                                            return item.project_name + '-' + moment(item.service_date).format("DD-MM-yyyy")
                                        }
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listServiceJob.filter((p) => {
                                        return !listMeggerResult.find((x) => {
                                            return p.id === x.service_id
                                        })
                                    })} value={selectedServiceJob} onChange={(e) => {
                                        if (e !== null) {
                                            setSelectedServiceJob(e);
                                        } else {
                                            setSelectedServiceJob({});

                                        }
                                    }}
                                    isClearable
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>W1 N</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setMeggerResult({ ...meggerResult, w1_n: e.target.value })
                                }} value={meggerResult.w1_n} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>U1 N</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setMeggerResult({ ...meggerResult, u1_n: e.target.value })
                                }} value={meggerResult.u1_n} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>V1 N</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setMeggerResult({ ...meggerResult, v1_n: e.target.value })
                                }} value={meggerResult.v1_n} as="textarea" row={3} placeholder="" required></Form.Control>
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>Keterangan</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setMeggerResult({ ...meggerResult, description: e.target.value })
                                }} value={meggerResult.description} as="textarea" rows={3} placeholder="" required></Form.Control>
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button style={{ width: 100 }} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_warehouse.save_button")}</Button>
                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                    clearMeggerResultModal();
                                    setMeggerResultModal(false);
                                }}>{t("index_warehouse.cancel_button")}</Button>
                            </div>
                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}