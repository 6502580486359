import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, convertBase64, getVesselPhotoById, insertUpdateVesselPhoto, deleteVesselPhoto, getVesselPhotoByCount, checkPhotoIsCover,
        getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import { omit } from 'lodash';
import Modals from "../../../../Components/modal";
import { Trash, FilePlusFill, InfoCircleFill, FileCheckFill, GearWideConnected } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import Loading from "../../../../Components/Loading";
import ContainerBox from "../../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function VesselPhotoPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [listVessel, setListVessel] = useState([]);
    const [show, setShow] = useState(false);
    const [updatedButton,setUpdatedButton] = useState("");
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [deletedButton, setDeletedButton] = useState("");
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const handleShow = () => setShow(true);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(4);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([4, 8, 12, 20, 40]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("vessel_photo.order_name1")}`,
        value: "taken_date"
    });
    // const [orderBySelection, setOrderBySelection] = useState(["Title", "Taken Date"]);
    const orderBySelection = [
        {
            label: `${t("vessel_photo.order_name1")}`,
            value: "taken_date"
        }
    ]
    const [vesselPhotosId, setVesselPhotosId] = useState("");
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [description, setDescription] = useState(null);
    const [vesselPhotoCount, setvesselPhotoCount] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [vesselPhoto, setVesselPhoto] = useState({
        vesselId: "",
        base64Img: "",
        description: "",
        takenDate: null,
        name: ""
    })
    const [fileImg, setFileImg] = useState("");
    const [coverId, setCoverId] = useState("");


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadVessel();
                setLoading(false);
                loadAccess();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (vesselPhotosId !== "") {
            removeVesselPhoto();
        }

    }, [vesselPhotosId]);

    useEffect(() => {
        loadVessel();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadVessel();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadVessel();
    }, [orderBy]);

    useEffect(() => {
        if (coverId !== "") {
            setCoverPhoto();
            loadVessel();
        }

    }, [coverId]);



    const loadVessel = async () => {
        try {
            let vesselPhotoCount = await getVesselPhotoByCount(cookies.token, location.state.vesselId);
            setvesselPhotoCount(vesselPhotoCount);
            let totalPage = vesselPhotoCount / itemPerPage;
            setTotalPage(totalPage);

            let listVessel = await getVesselPhotoById(cookies.token, location.state.vesselId, page, itemPerPage, orderBy.value);
            setListVessel(listVessel);
            if (listVessel) {
                setVesselPhoto({
                    ...vesselPhoto,
                    vesselId: listVessel[0].vessel_name,
                    image: listVessel.base64_image,
                    takenDate: listVessel.taken_date,
                    name: listVessel.name,
                    isCover: listVessel.is_cover
                })

            } else {

            }
            setIsSearched(0)
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const addPhoto = async () => {
        try {
            setDisabledButton(true);
            let response = await insertUpdateVesselPhoto(cookies.token, vesselPhoto, location.state.vesselId);
            if (response === 0) {
                alert(`${t("vessel_photo.alert_success_save_data")}`);
                // window.location.reload();
                handleClose();
                loadVessel();
                // loadVessel();
                // resetInputdata();

            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.base64_image);
        setDescription(item.description)
    };

    const handelRotationRight = () => {
        const totalLength = listVessel.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = listVessel[0].base64_image;
            const descUrl = listVessel[0].description;
            setClickedImg(newUrl);
            setDescription(descUrl)
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = listVessel.filter((item) => {
            return listVessel.indexOf(item) === newIndex;
        });
        const descUrl = listVessel.filter((item) => {
            return listVessel.indexOf(item) === newIndex;
        })
        const newItem = newUrl[0].base64_image;
        const descNew = descUrl[0].description
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const handelRotationLeft = () => {
        const totalLength = listVessel.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = listVessel[totalLength - 1].base64_image;
            const descUrl = listVessel[totalLength - 1].description;

            setClickedImg(newUrl);
            setDescription(descUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = listVessel.filter((item) => {
            return listVessel.indexOf(item) === newIndex;
        });
        const descUrl = listVessel.filter((item) => {
            return listVessel.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_image;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const removeVesselPhoto = async () => {
        let response = await deleteVesselPhoto(cookies.token, vesselPhotosId);
        if (response === 0) {
            // window.location.reload();
            loadVessel();
        }
    }

    const handleResets = async () => {

        setVesselPhoto({
            vesselId: "",
            base64Img: "",
            description: "",
            takenDate: "",
            name: ""
        });

        setFileImg("");

    }

    const setCoverPhoto = async () => {
        try {
            let response = await checkPhotoIsCover(cookies.token, location.state.vesselId, coverId);
            if (response === 0) {
                alert(`${t("vessel_photo.alert_set_cover")}`);
                window.location.reload();
                loadVessel();
            }
        } catch (exception) {
            console.loge(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("vessel_photo.order_name1")}`,
                value: "taken_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container>
                <ContainerBox titleCaption={location.state.vesselName}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/Detail", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><InfoCircleFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_photo.button_vessel_info")}</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/Document", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FileCheckFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_photo.button_document")}</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/EquipmentStructView", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><GearWideConnected size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_photo.button_equipment")}</div>
                                </div>
                            </Button>
                            <Button disabled={disabledCustomerForm === true ? true :!updatedButton} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                setVesselPhoto({
                                    vesselId: "",
                                    base64Img: "",
                                    description: "",
                                    takenDate: "",
                                    name: ""
                                });
                                setFileImg("");
                                handleShow();
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_photo.button_add_photo")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <Col md={12}>
                                <Row>
                                    {
                                        listVessel.map((vessel, index) => {
                                            return (

                                                <Col md={3} key={index}>
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        flexWrap: "nowrap",
                                                        flexDirection: "column",
                                                        padding: 10,
                                                        borderWidth: 1,
                                                        margin: 5,
                                                        borderStyle: "solid",
                                                        borderColor: "#2f71bd",
                                                        borderRadius: 5,
                                                        height: 400,

                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 5,
                                                            alignContent: "flex-start",
                                                            alignItems: "flex-start",
                                                            justifyContent: "center",
                                                            alignSelf: "center",
                                                            flexWrap: "wrap",

                                                        }}>
                                                            <img src={vessel.base64_image} onClick={() => {
                                                                handleClick(vessel, index)
                                                            }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                        </div>
                                                        <div style={{
                                                            flex: 1,
                                                            alignContent: "flex-end",
                                                            alignItems: "flex-end",
                                                            justifyContent: "flex-end",
                                                            alignSelf: "flex-end",
                                                            flexWrap: "nowrap",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                        }} >
                                                            <div style={{
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                alignContent: "flex-end",
                                                                alignItems: "flex-end",
                                                                justifyContent: "flex-end",
                                                                alignSelf: "flex-end",
                                                                textAlign: "center"
                                                            }}><p>{!vessel.name ? "" : vessel.name}</p></div>
                                                            <div style={{
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                alignContent: "flex-end",
                                                                alignItems: "flex-end",
                                                                justifyContent: "flex-end",
                                                                alignSelf: "flex-end",
                                                                textAlign: "center"
                                                            }}><p>{!vessel.taken_date ? "" : vessel.taken_date}</p>
                                                                <div style={{
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    alignContent: "flex-end",
                                                                    alignItems: "flex-end",
                                                                    justifyContent: "flex-end",
                                                                    alignSelf: "flex-end",
                                                                }}>
                                                                    <Button disabled={disabledCustomerForm === true ? true : !deletedButton} onClick={() => {
                                                                        if (window.confirm(`${t("vessel_photo.delete_confirmation")}`)) {
                                                                            let vesselPhotosId = vessel.id
                                                                            setVesselPhotosId(vesselPhotosId);
                                                                            // removeStaff();
                                                                        }
                                                                    }} variant="danger"><Trash /></Button>
                                                                </div>
                                                            </div>
                                                            {
                                                                vessel.is_cover === true ?
                                                                    <Form.Check onClick={(e) => {
                                                                        setCoverId(vessel.id);
                                                                    }} type="checkbox" defaultChecked={vessel.is_cover} label={`${t("vessel_photo.cover_label")}`} />
                                                                    :
                                                                    <>
                                                                        <input onClick={() => {
                                                                            setCoverId(vessel.id);
                                                                        }} type="checkbox" />
                                                                        <label>&nbsp;{t("vessel_photo.cover_label")}</label>
                                                                    </>

                                                            }
                                                            {/* <Form.Check onClick={(e) => {
                                                    setCoverId(vessel.id);
                                                }} type="checkbox" defaultChecked={vessel.is_cover} label="Is Cover" /> */}
                                                            {/* <p>{`'${vessel.is_cover}'`}</p> */}

                                                        </div>

                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Col>

                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderBySelection}
                                setIsSearched={setIsSearched}
                            />

                            <Loading
                                loading={loading}
                            />
                        </div>} />
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton onClick={() => {
                    handleResets();
                }}>
                    <Modal.Title>{t("vessel_photo.modal_add_photo")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        addPhoto();
                    }}>

                        <Form.Group className="mb-3">
                            <Form.Label>{t("vessel_photo.field_vessel_photo")}</Form.Label>
                            <Form.Control onChange={async (e) => {
                                if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                    setFileImg(e.target.value)
                                    let base64Img = await convertBase64(e.target.files[0]);
                                    const base64ImgString = base64Img.toString();
                                    setVesselPhoto({ ...vesselPhoto, base64Img: base64ImgString, file: e.target.value });
                                    setUploadFileImageError("");
                                    setDisabledButton(false)

                                } else {
                                    setFileImg("")
                                    setVesselPhoto({ ...vesselPhoto, base64Img: "", file: "" });
                                    setUploadFileImageError(`${t("vessel_photo.error_image_validation")}`);
                                    setDisabledButton(true)
                                }



                            }} type="file" value={fileImg} required></Form.Control>
                            {
                                uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                            }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("vessel_photo.field_photo_label")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setVesselPhoto({ ...vesselPhoto, name: e.target.value })

                            }} value={vesselPhoto.name} type="text" placeholder="" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("vessel_photo.field_taken_date")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setVesselPhoto({ ...vesselPhoto, takenDate: e.target.value })
                            }} value={vesselPhoto.takenDate} type="date" placeholder="" required></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("vessel_photo.field_description")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setVesselPhoto({ ...vesselPhoto, description: e.target.value })
                            }} value={vesselPhoto.description} as="textarea" rows={3} placeholder=""></Form.Control>
                        </Form.Group>
                        <Button style={{width:100}} variant="primary" disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit">
                        {t("vessel_photo.save_button")}
                        </Button>
                        <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                            handleResets();
                            handleClose();
                        }}>
                            {t("vessel_photo.cancel_button")}
                        </Button>
                    </Form>

                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            {clickedImg && (
                <Modals
                    clickedImg={clickedImg}
                    handelRotationRight={handelRotationRight}
                    setClickedImg={setClickedImg}
                    handelRotationLeft={handelRotationLeft}
                    image={listVessel}
                    description={description}
                    setDescription={setDescription}
                />
            )}

        </>
    )


}