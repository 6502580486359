import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Container, Form, ListGroup, Modal } from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getEquipmentCategoryStruct, getVesselById, getVesselEquipmentByCategory,
    getEquipmentPosition, getSparepartByCategory, getEquipmentById, getVesselxEquipmentById,
    getAllVesselEquipment, insertUpdateVesselxEquipment, getEquipment, deleteVesselxEquipment,
    insertUpdateManufacturer, getManufacturer, getUserAccessById
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { EyeFill, CameraFill, Trash, PencilFill, FilePlusFill, Search, ArrowClockwise, InfoCircleFill, FileCheckFill, PlusSquareFill, TrashFill, FileTextFill } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";
import DeniReactTreeView from "deni-react-treeview"
import FormSelect from "../../../../Components/FormSelect";
import Select from 'react-select';
import { Trans, useTranslation } from "react-i18next";

export default function VesselDetailEquipmentPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [vesselId, setVesselId] = useState("");
    const [listEquipment, setListEquipment] = useState([]);
    const [disabledCatalog, setDisabledCatalog] = useState(false);
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [manufaturerErrorMessage, setManufacturerErrorMessage] = useState("")
    const [vessel, setVessel] = useState({});
    const [listManufacturer, setListManufacturer] = useState([]);
    const [manufacturerModal, setManufacturerModal] = useState("");
    const [selectedManufacturer, setSelectedManufacturer] = useState({ id: "" });
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedCatalog, setSelectedCatalog] = useState({});
    const [listEquipmentCategory, setListEquipmentCategory] = useState([]);
    const [listEquipmentPosition, setListEquipmentPosition] = useState([]);
    const [selectedEquipmentPosition, setSelectedEquipmentPosition] = useState({});
    const [selectedLevel, setSelectedLevel] = useState(-1);
    const [selectedVesselEquipment, setSelectedVesselEquipment] = useState({});
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    const [vesselEquipmentToBeSaved, setVesselEquipmentToBeSaved] = useState({
        id: "",
        equipment_id: "",
        vessel_id: "",
        sparepart_catalog_id: null,
        equipment_position_id: null,
        title: "",
        description: "",
        model: "",
        manufacture_name: null,
        serial_number: "",
        part_number: "",
        parent_id: "",
        parameters: [],
        number_of_cylinder: null
    });
    const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState([]);
    const [listSparepart, setListSparepart] = useState([]);
    const [selectedSparepart, setSelectedSparepart] = useState({});
    const [listVesselEquipment, setListVesselEquipment] = useState([]);
    const [showEquipmentDetailModal, setShowEquipmentDetailModal] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [equipmentCategory, setEquipmentCategory] = useState({
        id: 0,
        equipment_name: "",
        equipment_description: "",
        parent_id: null
    })

    const [manufacturer, setManufacturer] = useState({
        id: "0",
        manufacturer_name: "",
        description: "",
    })

    let listEquipmentCategoryTmp = [];
    let selectedEquipmentCategoryTmp = [];

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setVesselId(location.state.vesselId);
                // setLoading(false);
                loadManufacturer();
                loadAccess();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);


    useEffect(() => {
        async function assignVessel() {
            await loadEquipmentPosition();
            await loadVesselDetail();
        }
        assignVessel();
    }, [vesselId]);




    useEffect(() => {
        if (vesselEquipmentToBeSaved.manufacture_name) {
            let response = listManufacturer.find(p => p.id === vesselEquipmentToBeSaved.manufacture_name);
            if (response) {
                setSelectedManufacturer(response);
            }
            else {
                setSelectedManufacturer({})
            }
        }
        async function loadEquipment() {
            await loadDropdownList();
        }

        loadEquipment();
        if (vesselEquipmentToBeSaved.equipment_id)
            loadSparepartByCategory();

        if (vesselEquipmentToBeSaved.equipment_id === null || vesselEquipmentToBeSaved.equipment_id === "" || vesselEquipmentToBeSaved.equipment_id === undefined) {
            setErrorMessage(`${t("index_vessel_equipment.error_empty_field")}`);
            setDisabledButton(true);
        } else if (vesselEquipmentToBeSaved.manufacture_name === null || vesselEquipmentToBeSaved.manufacture_name === "" || vesselEquipmentToBeSaved.manufacture_name === undefined) {
            setManufacturerErrorMessage(`${t("index_vessel_equipment.error_empty_field")}`);
            setDisabledButton(true);
        } else {
            setErrorMessage("");
            setDisabledButton(false);
            setManufacturerErrorMessage("")
        }


    }, [vesselEquipmentToBeSaved])


    useEffect(() => {
        async function loadEqpCategory() {
            await loadEquipmentCategory();
        }
        loadEqpCategory();
    }, [vessel]);

    // x


    useEffect(() => {
        // setVesselEquipmentId("");
        setSelectedVesselEquipment({})
        // console.log(selectedEquipmentCategory[0])

        if (selectedEquipmentCategory[0]) {

            async function loadEqpCategoryById() {
                await loadEquipmentCategoryById();
            }

            loadEqpCategoryById();

            async function loadVesEqp() {
                setLoading(true);
                await loadVesselEquipment();
            }

            loadVesEqp();

            async function loadEquipment() {
                await loadDropdownList();
            }

            loadEquipment();
        }




        // console.log(selectedEquipmentCategory);
    }, [selectedEquipmentCategory]);

    useEffect(() => {
        forceUpdate();
    }, [listVesselEquipment])


    useEffect(() => {
        // console.log(selectedManufacturer)
        if (selectedManufacturer.manufacturer_name) {
            setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, manufacture_name: selectedManufacturer.id });
        } else {
            setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, manufacture_name: null });
        }
    }, [selectedManufacturer]);

    useEffect(() => {
        if (selectedVesselEquipment.id) {
            async function loadVesselEqp() {
                await initVesselEquipment();
            }
            loadVesselEqp();

            loadSparepartByCategory();
        }

        forceUpdate();
    }, [selectedVesselEquipment])

    const loadVesselDetail = async () => {
        try {
            let response = await getVesselById(cookies.token, location.state.vesselId, cookies.languageId);

            if (response) {
                setVessel(response);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const buildEquipmentCategoryArray = (eqp, level) => {
        try {
            level++;
            let childArray = [];
            for (let i = 0; i < eqp.length; i++) {
                childArray.push(eqp[i]);
                if (eqp[i].children.length > 0) {
                    buildEquipmentCategoryArray(eqp[i].children, level);
                }
            }
            selectedEquipmentCategoryTmp.push({});
            if (!listEquipmentCategoryTmp[level]) {
                listEquipmentCategoryTmp[level] = childArray;
            }
            else {
                let tmpChild = listEquipmentCategoryTmp[level].concat(childArray);
                listEquipmentCategoryTmp[level] = tmpChild;
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadSparepartByCategory = async () => {
        try {

            let response = await getSparepartByCategory(cookies.token, selectedVesselEquipment.id ? vesselEquipmentToBeSaved.equipment_id : selectedEquipmentCategory[selectedLevel].id, cookies.extCustomerId);
            // console.log(response);
            setListSparepart(response);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipmentPosition = async () => {
        try {
            let response = await getEquipmentPosition(cookies.token);
            if (response) {
                setListEquipmentPosition(response);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEquipmentCategory = async () => {
        try {
            let listEquipmentCategory = await getEquipmentCategoryStruct(cookies.token, cookies.languageId);
            // console.log(listEquipmentCategory);
            let level = -1;
            listEquipmentCategoryTmp = [];
            selectedEquipmentCategoryTmp = [];
            buildEquipmentCategoryArray(listEquipmentCategory, level);
            // console.log(listEquipmentCategoryTmp);
            // console.log(selectedEquipmentCategoryTmp);
            setListEquipmentCategory(listEquipmentCategoryTmp);
            setSelectedEquipmentCategory(selectedEquipmentCategoryTmp);
        } catch (exception) {
            console.log(exception);
        }

    }

    const loadEquipmentCategoryById = async () => {
        try {
            let response = await getEquipmentById(cookies.token, selectedEquipmentCategory[selectedLevel].id);
            // console.log(response)
            setEquipmentCategory({
                ...equipmentCategory,
                id: response[0].id,
                equipment_name: response[0].equipment_name,
                equipment_description: response[0].equipment_description,
                parent_id: response[0].parent_id
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const onRenderItem = (item, treeview) => {
        return (
            <div className="treeview-item-example">
                <li style={{ listStyle: "none" }} onClick={() => {
                    let vesselEqpId = item.id;
                    setVesselEquipmentId(vesselEqpId)
                }}>{item.text} </li>
            </div>
        )
    }

    const initVesselEquipment = async () => {
        try {
            let response = await getVesselxEquipmentById(cookies.token, selectedVesselEquipment.id);
            let tmpEqp = response;
            let param = response.parameters.slice();
            tmpEqp.parameters = [];
            tmpEqp.parameters = param.slice();
            setSelectedManufacturer({})
            //setVesselEquipmentToBeSaved(tmpEqp);
            setVesselEquipmentToBeSaved({
                id: response.id,
                vessel_id: response.vessel_id,
                equipment_id: response.equipment_id,
                title: response.title,
                company: response.company_id,
                description: response.description,
                base64_img: response.base64_img,
                vesselName: response.vessel_name,
                equipmentName: response.equipment_name,
                model: response.model,
                manufacture_name: response.manufacture_name,
                sparepart_catalog_id: response.sparepart_catalog_id,
                serial_number: response.serial_number,
                equipment_position_id: response.equipment_position_id,
                part_number: response.part_number,
                number_of_cylinder: response.number_of_cylinder,
                parameters: param,
                type: "mainitem",
                is_megger: response.is_megger
            });
            let listEqp = await getEquipment(cookies.token);
            let selectedEqp = listEqp.find(p => p.id === response.equipment_id);
            if (selectedEqp) {
                setSelectedCategory(selectedEqp);
            } else {
                setSelectedCategory({})
            }



            let list = await getSparepartByCategory(cookies.token, selectedVesselEquipment.equipment_id, cookies.extCustomerId);
            let sparepart = list.find(p => p.id === selectedVesselEquipment.sparepart_catalog_id);
            if (sparepart) {
                setSelectedCatalog(sparepart);
            } else {
                setSelectedCatalog({});
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadVesselEquipment = async () => {
        try {
            let response = await getAllVesselEquipment(cookies.token, cookies.languageId, selectedEquipmentCategory[selectedLevel].id, vesselId);
            setListVesselEquipment(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const saveData = async () => {
        try {
            let vesselEqp = {};
            vesselEqp.id = vesselEquipmentToBeSaved.id;
            vesselEqp.equipment_id = vesselEquipmentToBeSaved.equipment_id;
            vesselEqp.title = vesselEquipmentToBeSaved.title;
            vesselEqp.description = vesselEquipmentToBeSaved.description;
            vesselEqp.model = vesselEquipmentToBeSaved.model;
            vesselEqp.manufacture_name = vesselEquipmentToBeSaved.manufacture_name;
            vesselEqp.equipment_position_id = vesselEquipmentToBeSaved.equipment_position_id;
            vesselEqp.parent_id = vesselEquipmentToBeSaved.parent_id === 0 ? null : vesselEquipmentToBeSaved.parent_id;
            vesselEqp.parameters = vesselEquipmentToBeSaved.parameters;
            vesselEqp.serial_number = vesselEquipmentToBeSaved.serial_number;
            vesselEqp.number_of_cylinder = vesselEquipmentToBeSaved.number_of_cylinder;
            vesselEqp.part_number = vesselEquipmentToBeSaved.part_number;
            vesselEqp.sparepart_catalog_id = vesselEquipmentToBeSaved.sparepart_catalog_id;
            vesselEqp.vessel_id = vesselEquipmentToBeSaved.vessel_id;
            vesselEqp.is_megger = vesselEquipmentToBeSaved.is_megger;

            // console.log(vesselEqp);

            let response = await insertUpdateVesselxEquipment(cookies.token, vesselEqp, undefined, undefined, undefined, cookies.languageId);
            if (response.error_code === 0) {
                alert(`${t("index_vessel_equipment.alert_success_save_data")}`);
                loadVesselEquipment();
                // setVesselEquipmentId("");
                setSelectedVesselEquipment({})
            }
            else {
                alert(`${t("index_vessel_equipment.alert_failed_save_data")}`)
                // setSelectedVesselEquipment({})
            }
            setDisabledButton(false);
            setSelectedManufacturer({});

        } catch (exception) {
            console.log(exception)
            // setDisabledButton(false);
        }
    }

    const loadDropdownList = async () => {
        try {
            // console.log(selectedVesselEquipment.equipment_id)
            let eqp = await getEquipment(cookies.token);
            if (vesselEquipmentToBeSaved.equipment_id === "") {
                let response = eqp.filter(p => p.parent_id === selectedVesselEquipment.equipment_id)
                setListEquipment(response);
            }
            // else {
            //     setListEquipment(eqp);
            // }

            // setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeVesselEquipment = async () => {
        try {
            let response = await deleteVesselxEquipment(cookies.token, selectedVesselEquipment.id);
            if (response === 0) {
                alert(`${t("index_vessel_equipment.delete_success_alert")}`)
                loadVesselEquipment();
                // setVesselEquipmentId("");
                setSelectedVesselEquipment({});
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const clearManufacturerModal = async () => {
        let manufacturerTmp = {};
        manufacturerTmp.id = 0;
        manufacturerTmp.manufacturer_name = "";
        manufacturerTmp.description = "";
        setManufacturer(manufacturerTmp);
    }

    const saveDataManufacturer = async () => {
        try {
            let response = await insertUpdateManufacturer(cookies.token, manufacturer);
            if (response.error_code === 0) {
                alert(`${t("index_vessel_equipment.alert_success_save_data")}`);
                setManufacturerModal(false);
                clearManufacturerModal();
                loadManufacturer();
                setDisabledButton(false);
            } else {
                alert(`${t("index_vessel_equipment.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const loadManufacturer = async () => {
        try {
            let response = await getManufacturer(cookies.token);
            setListManufacturer(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_vessel_equipment.header_title")} ${vessel.vessel_name}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/Detail", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><InfoCircleFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_vessel_equipment.button_vessel_info")}</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/Document", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FileCheckFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_vessel_equipment.button_document")}</div>
                                </div>
                            </Button>
                            <Button variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {

                                navigate("/Vessel/VesselPhoto", { state: { vesselId: location.state.vesselId, vesselName: location.state.vesselName } })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><CameraFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_vessel_equipment.button_photo")}</div>
                                </div>
                            </Button>
                            {/* <Button variant="secondary" style={{

                            }} onClick={() => {

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Data</div>
                                </div>
                            </Button> */}
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    padding: 10,
                                    marginBottom: 10,
                                    borderWidth: 2,
                                    borderColor: "#282c34",
                                    borderStyle: "solid",
                                    borderRadius: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        paddingLeft: 10,
                                    }}>
                                        {t("index_vessel_equipment.text_choose_equipment")}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        overflowX: "auto",
                                        maxWidth: "100%",
                                    }}>

                                        {
                                            listEquipmentCategory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        overflowY: "auto",
                                                        flex: 1,
                                                        maxHeight: 480,
                                                        paddingLeft: 5,
                                                        paddingRight: 5
                                                    }}>
                                                        <ListGroup style={{
                                                            width: "100%"
                                                        }}>

                                                            {
                                                                index === 0 && listEquipmentCategory[index].map((item2, index2) => {
                                                                    return (
                                                                        <ListGroup.Item style={{
                                                                            width: "100%"
                                                                        }} key={index2} action onClick={() => {
                                                                            setSelectedLevel(index);
                                                                            let selectedEquipmentCategory2 = selectedEquipmentCategory.slice();
                                                                            // console.log(selectedEquipmentCategory2);
                                                                            selectedEquipmentCategory2[index] = item2;
                                                                            for (let i = 0; i < selectedEquipmentCategory2.length; i++) {
                                                                                if (i > index) {
                                                                                    selectedEquipmentCategory2[i] = {};
                                                                                }
                                                                            }
                                                                            setSelectedEquipmentCategory(selectedEquipmentCategory2);
                                                                        }} variant={selectedEquipmentCategory[index].id === item2.id ? "primary" : "info"}>{item2.equipment_name}</ListGroup.Item>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                index > 0 && (index - 1) <= selectedLevel && listEquipmentCategory[index].filter(p => p.parent_id === selectedEquipmentCategory[index - 1].id).map((item2, index2) => {
                                                                    return (
                                                                        <ListGroup.Item style={{
                                                                            width: "100%"
                                                                        }} key={index2} action onClick={() => {
                                                                            setSelectedLevel(index);
                                                                            let selectedEquipmentCategory2 = selectedEquipmentCategory.slice();
                                                                            // console.log(selectedEquipmentCategory2);
                                                                            selectedEquipmentCategory2[index] = item2;
                                                                            for (let i = 0; i < selectedEquipmentCategory2.length; i++) {
                                                                                if (i > index) {
                                                                                    selectedEquipmentCategory2[i] = {};
                                                                                }
                                                                            }
                                                                            setSelectedEquipmentCategory(selectedEquipmentCategory2);
                                                                        }} variant={selectedEquipmentCategory[index].id === item2.id ? "primary" : "info"}>{item2.equipment_name}</ListGroup.Item>
                                                                    )
                                                                })
                                                            }

                                                        </ListGroup>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    padding: 10,
                                    borderWidth: 2,
                                    borderColor: "#282c34",
                                    borderStyle: "solid",
                                    borderRadius: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        paddingRight: 10,
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flex: 5,
                                            paddingLeft: 10,
                                        }}>
                                            {
                                                equipmentCategory.id !== 0 &&
                                                <div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            fontWeight: "bold",
                                                            fontSize: 20,
                                                            paddingRight: 10,
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            alignItems: "center"
                                                        }}>
                                                            {t("index_vessel_equipment.text_list_equipment")}
                                                        </div>
                                                        {
                                                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                                <></>
                                                                :
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignContent: "center",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <Button disabled={equipmentCategory.id ? !updatedButton : true} style={{
                                                                        marginLeft: 5, marginRight: 5
                                                                    }} onClick={() => {
                                                                        // console.log(equipmentCategory.id);
                                                                        let SparepartId = equipmentCategory.id;
                                                                        // setVesselEquipmentId(0);


                                                                        let vesselEquipment = {
                                                                            id: 0,
                                                                            equipment_id: equipmentCategory.id,
                                                                            vessel_id: vesselId,
                                                                            sparepart_catalog_id: null,
                                                                            equipment_position_id: null,
                                                                            title: "",
                                                                            description: "",
                                                                            model: "",
                                                                            manufacture_name: null,
                                                                            serial_number: "",
                                                                            number_of_cylinder: null,
                                                                            part_number: "",
                                                                            parent_id: null,
                                                                            parameters: [],
                                                                            type: "mainitem",
                                                                            is_megger: false
                                                                        }
                                                                        setSelectedCategory(equipmentCategory);
                                                                        setSelectedCatalog({});
                                                                        setSelectedManufacturer({})
                                                                        setSelectedVesselEquipment(vesselEquipment)
                                                                        setVesselEquipmentToBeSaved(vesselEquipment)
                                                                        loadSparepartByCategory();
                                                                        loadVesselEquipment();
                                                                        setDisabledCatalog(false);
                                                                        // setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, equipment_id: equipmentCategory.id })
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            alignSelf: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flex: 1,
                                                                            }}><PlusSquareFill size={16} /></div>
                                                                        </div>
                                                                    </Button>
                                                                </div>
                                                        }
                                                        {
                                                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                                <></>
                                                                :
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignContent: "center",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <Button variant="danger" disabled={selectedVesselEquipment.id && disabledCustomerForm === false ? false : true} style={{
                                                                        marginLeft: 5, marginRight: 5
                                                                    }} onClick={() => {
                                                                        if (window.confirm(`${t("index_vessel_equipment.delete_confirmation")}?`)) {
                                                                            setLoading(true);
                                                                            removeVesselEquipment();
                                                                            // handleremove(i)
                                                                            // let equipmentId = equipmentCategory.id
                                                                            // setEquipmentId(equipmentId);
                                                                        }
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            alignSelf: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flex: 1,
                                                                            }}><Trash size={16} /></div>
                                                                        </div>
                                                                    </Button>
                                                                </div>

                                                        }


                                                    </div>
                                                    <div style={{ paddingBottom: 10 }}></div>
                                                    <DeniReactTreeView
                                                        style={{ marginRight: 10, marginBottom: 10, maxWidth: 300, }}
                                                        showCheckbox={false}
                                                        showIcon={false}
                                                        theme="classic"
                                                        items={listVesselEquipment}
                                                        onSelectItem={(e) => {
                                                            setSelectedVesselEquipment(e);
                                                            // setVesselEquipmentId(e.id)
                                                        }}

                                                    // onRenderItem={onRenderItem}
                                                    />

                                                </div>

                                            }
                                            <div style={{ paddingRight: 10 }}></div>
                                            {
                                                selectedVesselEquipment.equipment_id &&
                                                <ContainerBox containerPos="inner" titleCaption={`${t("index_vessel_equipment.modal_add_data")}`}
                                                    useActionContainer={true}
                                                    actionContainerChild={
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row"
                                                        }}>
                                                            {
                                                                vesselEquipmentToBeSaved.id !== 0 &&
                                                                <div style={{ display: "flex" }}>
                                                                    <Link to={`/VesselEquipment/EquipmentFinding?id=${selectedVesselEquipment.id}&vesselId=${location.state.vesselId}`} target="_blank">
                                                                        <Button variant="secondary" onClick={() => {
                                                                            // setShowDocumentUploadModal(true);
                                                                            // navigate("/SparepartCatalog/Document" , {state:{sparepartCatalogId: vesselEquipmentId}});
                                                                        }} style={{

                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                flexDirection: "row",
                                                                                alignSelf: "center",
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    flex: 1,
                                                                                }}><Search size={20} /></div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flex: 8,
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    paddingLeft: 5,
                                                                                    fontWeight: "bold",
                                                                                    fontSize: 15,
                                                                                }}> {t("index_vessel_equipment.button_equipment_finding")}</div>
                                                                            </div>
                                                                        </Button>
                                                                    </Link>
                                                                </div>


                                                            }
                                                             <div style={{ paddingRight: 10 }}></div>
                                                             {
                                                                vesselEquipmentToBeSaved.id !== 0 && vesselEquipmentToBeSaved.is_megger &&
                                                                <div style={{ display: "flex" }}>
                                                                    <Link to={`/VesselEquipment/MeggerResult?id=${selectedVesselEquipment.id}&vesselId=${location.state.vesselId}`} target="_blank">
                                                                        <Button variant="secondary" onClick={() => {
                                                                            // setShowDocumentUploadModal(true);
                                                                            // navigate("/SparepartCatalog/Document" , {state:{sparepartCatalogId: vesselEquipmentId}});
                                                                        }} style={{

                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                flexDirection: "row",
                                                                                alignSelf: "center",
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    flex: 1,
                                                                                }}><FileTextFill size={20} /></div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flex: 8,
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    paddingLeft: 5,
                                                                                    fontWeight: "bold",
                                                                                    fontSize: 15,
                                                                                }}> {t("index_vessel_equipment.button_megger_result")}</div>
                                                                            </div>
                                                                        </Button>
                                                                    </Link>
                                                                </div>


                                                            }
                                                            <div style={{ paddingRight: 10 }}></div>
                                                            {
                                                                selectedVesselEquipment.id && vesselEquipmentToBeSaved.id !== 0 && (cookies.logingAs === "User" || cookies.extCustomerId === "null") &&
                                                                <div style={{ display: "flex" }}>
                                                                    <Button disabled={disabledCustomerForm === true ? true : !updatedButton} variant="secondary" onClick={() => {
                                                                        let vesselEquipment = {
                                                                            id: 0,
                                                                            equipment_id: "",
                                                                            vessel_id: vesselId,
                                                                            sparepart_catalog_id: null,
                                                                            equipment_position_id: null,
                                                                            title: "",
                                                                            description: "",
                                                                            model: "",
                                                                            manufacture_name: null,
                                                                            serial_number: "",
                                                                            number_of_cylinder: null,
                                                                            part_number: "",
                                                                            parent_id: selectedVesselEquipment.id,
                                                                            parameters: [],
                                                                            type: "subitem",
                                                                            is_megger: false
                                                                        }
                                                                        setVesselEquipmentToBeSaved(vesselEquipment);
                                                                        setSelectedCategory({});
                                                                        setSelectedCatalog({});
                                                                        setSelectedManufacturer({})
                                                                        setDisabledCatalog(false)
                                                                    }} style={{

                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            alignSelf: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flex: 1,
                                                                            }}><FilePlusFill size={20} /></div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 8,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                paddingLeft: 5,
                                                                                fontWeight: "bold",
                                                                                fontSize: 15,
                                                                            }}>{t("index_vessel_equipment.button_sub_item")}</div>
                                                                        </div>
                                                                    </Button>
                                                                </div>

                                                            }
                                                            <div style={{ paddingRight: 10 }}></div>
                                                            {
                                                                vesselEquipmentToBeSaved.id !== 0 &&
                                                                <div style={{ display: "flex" }}>
                                                                    <Link to={`/VesselEquipment/Photo?id=${selectedVesselEquipment.id}&vesselName=${vessel.vessel_name}&name=${selectedEquipmentCategory[selectedLevel].equipment_name}`} target="_blank">
                                                                        <Button variant="secondary" onClick={() => {
                                                                            // setShowDocumentUploadModal(true);
                                                                            // navigate("/SparepartCatalog/Document" , {state:{sparepartCatalogId: vesselEquipmentId}});
                                                                        }} style={{

                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                flexDirection: "row",
                                                                                alignSelf: "center",
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    flex: 1,
                                                                                }}><CameraFill size={20} /></div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flex: 8,
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    paddingLeft: 5,
                                                                                    fontWeight: "bold",
                                                                                    fontSize: 15,
                                                                                }}> {t("index_vessel_equipment.button_photo")}</div>
                                                                            </div>
                                                                        </Button>
                                                                    </Link>
                                                                </div>


                                                            }
                                                            <div style={{ paddingRight: 10 }}></div>
                                                            {
                                                                vesselEquipmentToBeSaved.id !== 0 &&
                                                                <div style={{ display: "flex" }}>

                                                                    <Link to={`/VesselEquipment/Document?id=${selectedVesselEquipment.id}&sparepartId=${vesselEquipmentToBeSaved.sparepart_catalog_id}`} target="_blank">
                                                                        <Button variant="secondary" onClick={() => {
                                                                            // setShowDocumentUploadModal(true);
                                                                            // navigate("/SparepartCatalog/Document" , {state:{sparepartCatalogId: vesselEquipmentId}});
                                                                        }} style={{

                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                flexDirection: "row",
                                                                                alignSelf: "center",
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    flex: 1,
                                                                                }}><FileCheckFill size={20} /></div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flex: 8,
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    paddingLeft: 5,
                                                                                    fontWeight: "bold",
                                                                                    fontSize: 15,
                                                                                }}> {t("index_vessel_equipment.button_document")}</div>
                                                                            </div>
                                                                        </Button>
                                                                    </Link>
                                                                </div>


                                                            }
                                                        </div>
                                                    }
                                                    childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>
                                                            <Form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                setLoading(true);
                                                                setDisabledButton(true);
                                                                saveData()
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5
                                                                    }}>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingRight: 5,
                                                                        }} className="mb-3">
                                                                            <FormSelect caption={`${t("index_vessel_equipment.field_equipment_category")}`} placeholder=""
                                                                                options={listEquipment} getOptionLabel={
                                                                                    (item) => {
                                                                                        return item.equipment_name;
                                                                                    }
                                                                                } getOptionValue={
                                                                                    (item) => {
                                                                                        return item.id;
                                                                                    }
                                                                                } value={selectedCategory} setValueFunction={(e) => {
                                                                                    let selectedEqp = listEquipment.find(p => p.id === e.id);
                                                                                    setSelectedCategory(selectedEqp);
                                                                                    let idEquipment = e.id
                                                                                    setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, equipment_id: selectedEqp.id })

                                                                                }}
                                                                                disabledForm={vesselEquipmentToBeSaved.type === "subitem" ? false : true}
                                                                            />
                                                                            {
                                                                                errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                                            }
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5
                                                                    }}>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingRight: 5,
                                                                        }} className="mb-3">
                                                                            <FormSelect caption={`${t("index_vessel_equipment.field_sparepart_catalogue")}`} placeholder=""
                                                                                options={listSparepart} getOptionLabel={
                                                                                    (item) => {
                                                                                        if (item.id) {
                                                                                            return (`${item.manufacturer_name ? item.manufacturer_name : ""} --
                                                                                                 ${item.model ? item.model : ""}`);
                                                                                        } else {
                                                                                            return ("");
                                                                                        }
                                                                                    }
                                                                                } getOptionValue={
                                                                                    (item) => {
                                                                                        return item.id;
                                                                                    }
                                                                                } value={selectedCatalog ? selectedCatalog : ""} setValueFunction={(e) => {

                                                                                    if (e === null) {
                                                                                        setSelectedCatalog({});
                                                                                        let vesselEqp = {};
                                                                                        vesselEqp.id = 0
                                                                                        vesselEqp.equipment_id = equipmentCategory.id;
                                                                                        vesselEqp.title = "";
                                                                                        vesselEqp.description = "";
                                                                                        vesselEqp.model = "";
                                                                                        vesselEqp.manufacture_name = "";
                                                                                        vesselEqp.equipment_position_id = null;
                                                                                        vesselEqp.parameters = [];
                                                                                        vesselEqp.serial_number = "";
                                                                                        vesselEqp.part_number = "";
                                                                                        vesselEqp.sparepart_catalog_id = null;
                                                                                        vesselEqp.vessel_id = vesselId;
                                                                                        vesselEqp.parent_id = selectedVesselEquipment.id;
                                                                                        setVesselEquipmentToBeSaved(vesselEqp);
                                                                                        setDisabledCatalog(false);
                                                                                    } else {
                                                                                        let selectedSparepart = listSparepart.find(p => p.id === e.id);
                                                                                        setSelectedCatalog(selectedSparepart)
                                                                                        let vesselEqp = {};
                                                                                        vesselEqp.id = 0
                                                                                        vesselEqp.equipment_id = selectedSparepart.equipment_id;
                                                                                        vesselEqp.title = selectedSparepart.title;
                                                                                        vesselEqp.description = selectedSparepart.description;
                                                                                        vesselEqp.model = selectedSparepart.model;
                                                                                        vesselEqp.manufacture_name = selectedSparepart.manufacture_name;
                                                                                        vesselEqp.equipment_position_id = selectedSparepart.equipment_position_id;
                                                                                        vesselEqp.parameters = selectedSparepart.parameters;
                                                                                        vesselEqp.serial_number = selectedSparepart.serial_number;
                                                                                        vesselEqp.part_number = selectedSparepart.part_number;
                                                                                        vesselEqp.sparepart_catalog_id = selectedSparepart.id;
                                                                                        vesselEqp.vessel_id = vesselId;
                                                                                        vesselEqp.parent_id = selectedVesselEquipment.id

                                                                                        setVesselEquipmentToBeSaved(vesselEqp);
                                                                                        setDisabledCatalog(true);

                                                                                    }

                                                                                }}
                                                                                disabledForm={vesselEquipmentToBeSaved.id === 0 && vesselEquipmentToBeSaved.equipment_id !== "" ? false : true}
                                                                            />

                                                                        </Form.Group>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingLeft: 5
                                                                        }} className="mb-3">
                                                                            <Form.Label>{t("index_vessel_equipment.field_serial_number")}</Form.Label>
                                                                            <Form.Control onChange={(e) => {
                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, serial_number: e.target.value })
                                                                            }} type="text" value={vesselEquipmentToBeSaved.serial_number ? vesselEquipmentToBeSaved.serial_number : ""} placeholder=""></Form.Control>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5
                                                                    }}>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingRight: 5,
                                                                        }} className="mb-3">
                                                                            <Form.Label>{t("index_vessel_equipment.field_part_number")}</Form.Label>
                                                                            <Form.Control disabled={disabledCatalog} onChange={(e) => {
                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, part_number: e.target.value })
                                                                            }} type="text" value={vesselEquipmentToBeSaved.part_number ? vesselEquipmentToBeSaved.part_number : ""} placeholder=""></Form.Control>
                                                                        </Form.Group>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingLeft: 5
                                                                        }} className="mb-3">
                                                                            <Form.Label>{t("index_vessel_equipment.field_manufacturer_name")} {disabledCustomerForm !== true && <a onClick={() => {
                                                                                setManufacturerModal(true);
                                                                                setManufacturer({ ...manufacturer, id: 0 })
                                                                            }} role="button" style={{ color: "blue", textDecoration: "underline" }}> <i className='fa fa-plus text-primary'></i> {t("index_vessel_equipment.add_button")} </a>} </Form.Label>
                                                                            <Select isDisabled={disabledCatalog} placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.manufacturer_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listManufacturer} value={vesselEquipmentToBeSaved.manufacture_name ? selectedManufacturer : null} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedManufacturer({})
                                                                                    } else {
                                                                                        setSelectedManufacturer(e);
                                                                                    }
                                                                                }}
                                                                                isClearable
                                                                                required
                                                                            />
                                                                            {
                                                                                manufaturerErrorMessage && <p style={{ color: "red" }}>{manufaturerErrorMessage}</p>
                                                                            }
                                                                        </Form.Group>
                                                                        {/* <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingLeft: 5
                                                                        }} className="mb-3">
                                                                            <Form.Label>Nama Pabrikan</Form.Label>
                                                                            <Form.Control disabled={true} onChange={(e) => {
                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, manufacture_name: e.target.value })
                                                                            }} type="text" value={vesselEquipmentToBeSaved.manufacture_name} required placeholder="Masukkan Nama Pabrikan"></Form.Control>
                                                                        </Form.Group> */}
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5
                                                                    }}>
                                                                        {/* <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingRight: 5,
                                                                        }} className="mb-3">
                                                                            <Form.Label>Nama Peralatan</Form.Label>
                                                                            <Form.Control disabled={disabledCatalog} onChange={(e) => {
                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, title: e.target.value })
                                                                            }} type="text" value={vesselEquipmentToBeSaved.title? vesselEquipmentToBeSaved.title : ""} required placeholder="Masukkan Nama Peralatan"></Form.Control>
                                                                        </Form.Group> */}
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingRight: 5
                                                                        }} className="mb-3">
                                                                            <Form.Label>{t("index_vessel_equipment.field_model")}</Form.Label>
                                                                            <Form.Control disabled={disabledCatalog} onChange={(e) => {
                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, model: e.target.value })
                                                                            }} type="text" value={vesselEquipmentToBeSaved.model ? vesselEquipmentToBeSaved.model : ""} required placeholder=""></Form.Control>
                                                                        </Form.Group>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingLeft: 5,
                                                                        }} className="mb-3">
                                                                            <Form.Label>{t("index_vessel_equipment.field_position")}</Form.Label>
                                                                            <Form.Select disabled={selectedSparepart.id} onChange={(e) => {
                                                                                if (e.target.value !== "") {
                                                                                    setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, equipment_position_id: e.target.value })
                                                                                } else {
                                                                                    setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, equipment_position_id: null })
                                                                                }
                                                                            }} value={vesselEquipmentToBeSaved.equipment_position_id === null ? "" : vesselEquipmentToBeSaved.equipment_position_id} >
                                                                                <option selected value="">

                                                                                </option>

                                                                                {listEquipmentPosition.map((position, index) => (
                                                                                    <option key={index} value={position.id}>{position.position}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5
                                                                    }}>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        padding: 5
                                                                    }}>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingLeft: 5
                                                                        }} className="mb-3">
                                                                            <Form.Label>{t("index_vessel_equipment.number_of_cylinder")}</Form.Label>
                                                                            <Form.Control onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                if (/^\d*$/.test(value) || value === "") {
                                                                                    setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, number_of_cylinder: e.target.value })
                                                                                } else {
                                        
                                                                                    setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, number_of_cylinder: null })
                                                                                }
                                                                               
                                                                            }} type="text" value={vesselEquipmentToBeSaved.number_of_cylinder ? vesselEquipmentToBeSaved.number_of_cylinder : ""} placeholder=""></Form.Control>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        padding: 5
                                                                    }}>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingLeft: 5
                                                                        }} className="mb-3">
                                                                            <Form.Label></Form.Label>
                                                                            <Form.Check checked={vesselEquipmentToBeSaved.is_megger} onClick={(e)=>{
                                                                                if(e.target.checked){
                                                                                    setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, is_megger: true })
                                                                                }else{
                                                                                    setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, is_megger: false })
                                                                                }
                                                                            }} label={'Megger?'}>

                                                                            </Form.Check>
                                                                        </Form.Group>
                                                                    </div>

                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "row",
                                                                        padding: 5
                                                                    }}>
                                                                        <Form.Group style={{
                                                                            width: "100%",
                                                                            paddingRight: 5,
                                                                        }} className="mb-3">
                                                                            <Form.Label>{t("index_vessel_equipment.field_description")}</Form.Label>
                                                                            <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, description: e.target.value })
                                                                            }} type="text" value={vesselEquipmentToBeSaved.description} placeholder=""></Form.Control>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        padding: 5
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            flexDirection: "row",
                                                                            padding: 5
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                flex: 3
                                                                            }}>{t("index_vessel_equipment.table_name1")}</div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                flex: 2
                                                                            }}>{t("index_vessel_equipment.table_name2")}</div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                flex: 2
                                                                            }}>{t("index_vessel_equipment.table_name3")}</div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                width: "100%",
                                                                                flexDirection: "column",
                                                                                flex: 1
                                                                            }}>{t("index_vessel_equipment.table_name4")}</div>
                                                                        </div>
                                                                        {
                                                                            vesselEquipmentToBeSaved.parameters && vesselEquipmentToBeSaved.parameters.map((param, index) => {
                                                                                return (
                                                                                    <div key={index} style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        flexDirection: "row",
                                                                                        padding: 5,
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            flex: 3,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <Form.Control onChange={(e) => {
                                                                                                let parameters = vesselEquipmentToBeSaved.parameters;
                                                                                                parameters[index].parameter_name = e.target.value;
                                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, parameters: parameters });
                                                                                            }} type="text" value={param.parameter_name ? param.parameter_name : ""} required placeholder=""></Form.Control>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            flex: 2,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <Form.Control onChange={(e) => {
                                                                                                let parameters = vesselEquipmentToBeSaved.parameters;
                                                                                                parameters[index].parameter_value = e.target.value;
                                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, parameters: parameters });
                                                                                            }} type="text" value={param.parameter_value ? param.parameter_value : ""} placeholder=""></Form.Control>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            flex: 2,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <Form.Control onChange={(e) => {
                                                                                                let parameters = vesselEquipmentToBeSaved.parameters;
                                                                                                parameters[index].parameter_description = e.target.value;
                                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, parameters: parameters });
                                                                                            }} type="text" value={param.parameter_description} placeholder=""></Form.Control>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexDirection: "column",
                                                                                            flex: 1,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <Button disabled={disabledCustomerForm} onClick={() => {
                                                                                                if (window.confirm(`${t("index_vessel_equipment.delete_confirmation")}?`)) {
                                                                                                    let list = [...vesselEquipmentToBeSaved.parameters];
                                                                                                    list.splice(index, 1);
                                                                                                    setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, parameters: list });
                                                                                                }

                                                                                            }} style={{ maxWidth: 50 }} variant="danger"><Trash /></Button>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        padding: 5
                                                                    }}>
                                                                        <Button variant="primary" onClick={() => {
                                                                            let newParameter = {
                                                                                id: "",
                                                                                parameter_name: "",
                                                                                parameter_value: "",
                                                                                parameter_description: "",
                                                                            };
                                                                            if (!vesselEquipmentToBeSaved.parameters) {
                                                                                let parameters = [];
                                                                                parameters.push(newParameter);
                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, parameters: parameters });
                                                                            } else {
                                                                                let parameters = vesselEquipmentToBeSaved.parameters;
                                                                                parameters.push(newParameter);
                                                                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, parameters: parameters });
                                                                            }
                                                                            // console.log(vesselEquipmentToBeSaved);
                                                                        }}>{t("index_vessel_equipment.button_add_parameter")}</Button>
                                                                    </div>
                                                                </div>
                                                                <div style={{ paddingTop: 10 }}></div>
                                                                {
                                                                    cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                                        <></>
                                                                        :
                                                                        <div style={{
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}>

                                                                            <Button style={{ width: 100 }} type="submit" disabled={disabledButton === true || disabledCustomerForm === true ? true : !updatedButton}>{t("index_vessel_equipment.save_button")}</Button>
                                                                            <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                                                                // setVesselEquipmentId("")
                                                                                setSelectedVesselEquipment({});
                                                                                loadVesselEquipment();
                                                                            }}>{t("index_vessel_equipment.cancel_button")}</Button>
                                                                        </div>
                                                                }



                                                            </Form>
                                                        </div>

                                                    }>
                                                </ContainerBox>
                                            }

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />

                <Modal size="lg" show={manufacturerModal} onHide={() => {
                    setManufacturerModal(false);
                    clearManufacturerModal();
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("index_vessel_equipment.modal_add_manufacturer")}`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flexDirection: "column",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    setDisabledButton(true);
                                    e.preventDefault();
                                    saveDataManufacturer()
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        flexWrap: "nowrap",
                                        flex: 1,
                                    }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("index_vessel_equipment.field_modal_manufacturer_name")}</Form.Label>
                                            {/* <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}> */}
                                            <Form.Control onChange={(e) => {
                                                setManufacturer({ ...manufacturer, manufacturer_name: e.target.value })
                                            }} value={manufacturer.manufacturer_name} type="text" placeholder="" required></Form.Control>
                                            {/* </div> */}
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        flexDirection: "column",
                                        flex: 1,
                                    }}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("index_vessel_equipment.field_modal_description")}</Form.Label>

                                            <Form.Control onChange={(e) => {
                                                setManufacturer({ ...manufacturer, description: e.target.value })
                                            }} value={manufacturer.description} as="textarea" rows={3} placeholder="" ></Form.Control>

                                        </Form.Group>
                                    </div>


                                    <div style={{
                                        paddingTop: 20,
                                    }}>

                                    </div>
                                    {
                                        cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                            <></>
                                            :
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                flex: 1,
                                            }}>
                                                <Button style={{ width: 100 }} variant="primary" type="submit" disabled={disabledButton === true || disabledCustomerForm === true ? true : !updatedButton}>
                                                    {t("index_vessel_equipment.save_button")}
                                                </Button>
                                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                                    clearManufacturerModal();
                                                    setManufacturerModal(false);
                                                }}>
                                                    {t("index_vessel_equipment.cancel_button")}
                                                </Button>
                                            </div>

                                    }

                                </Form>
                            </div>
                        }
                    />
                </Modal>
            </Container >
        </>
    )


}