import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, } from "../../../Helpers/ApplicationHelpers";
import { getSystemMenu, getSystemMenuCount } from "../../../Helpers/SystemHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import DeniReactTreeView from "deni-react-treeview"
const themes = ['classic', 'metro', 'moonlight', 'purple', 'green', 'orange', 'red', 'silver']

export default function MenuPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listSystemMenu, setListSystemMenu] = useState([]);
    const [systemMenuCount, setSystemMenuCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Menu",
        value: "menu_name"
    });
    const orderByList = [{
        label: "Nama Menu",
        value: "menu_name"
    }
    ]

    const [descending, setDescending] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadSystemMenu();
                setLoading(false);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadSystemMenu();
    }, [page]);

    useEffect(() => {
        loadSystemMenu();
    }, [itemPerPage]);

    useEffect(() => {
        loadSystemMenu();
    }, [orderBy]);


    useEffect(() => {
        if (searchQuery === "")
            loadSystemMenu();
    }, [searchQuery]);

    useEffect(() => {
        loadSystemMenu();
    }, [descending]);



    const loadSystemMenu = async () => {
        try {
            let count = await getSystemMenuCount(cookies.token, cookies.languageId);
            setSystemMenuCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
            let tmpSearchQuery = undefined;
            if (searchQuery) {
                tmpSearchQuery = searchQuery;
            }
            let response = await getSystemMenu(cookies.token, cookies.languageId);
            setListSystemMenu(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Daftar Menu"
                    useActionContainer={true}
                    childContent={
                        <div className="master-table-inner-container">
                            {/* <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Cari (Nama Menu,"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                await loadSystemMenu();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            await loadSystemMenu();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadSystemMenu();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                           */}
                            <DeniReactTreeView
                                    style={{ marginRight: '10px', marginBottom: '10px' }}
                                    // showCheckbox={true}
                                    theme="classic"
                                    items={listSystemMenu}
                                />
                                <Loading
                                    loading={loading}
                                />
                        </div>
                    } />
            </Container>


        </>
    );

}