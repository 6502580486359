import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form, Modal, Row, Col, Tab, Tabs, } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import Select from 'react-select';
import {
    isTokenValid, getStaff, getCurrency, getRackCoordinate, getWarehouse, getUserAccessById,
    getInventoryImage, getInventoryImageById, getCountInventoryImage, deleteInventoryImage,
    insertUpdateInventoryImage, convertBase64, getInventoryStockImage, getInventoryStockImageById,
    getCountInventoryStockImage, deleteInventoryStockImage, getInventoryBatchNumber,
    getListBatchBarcode, getCategory, getDivision
} from "../../../Helpers/ApplicationHelpers";
import {
    getInventoryPeriod, getInventoryCondition, getInventoryStatus, getInventoryMasterById,
    insertUpdateInventoryStock, checkBarcodeNumber, getInventoryStockCount,
    deleteInventoryStock, getInventoryStock, getInventoryStockById, getDepreciationGroup,
    getInventoryUnitType, getInventoryTypeSubItem, getInventoryMasterForCategory
} from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import { ArrowLeft, FilePlusFill, PencilFill, Trash, CardChecklist, Search, ArrowClockwise, EyeFill, Download, XSquare, XSquareFill, Printer, PrinterFill, CameraFill, Image } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import Navbar from "../../../Components/NavBar";
import { Alert } from "bootstrap";
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";
import Loading from "../../../Components/Loading";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { triggerBase64Download } from "../../../Helpers/Base64Downloader";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function InventoryStockPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [imageRemoveId, setImageRemoveId] = useState("");
    const [stockImageRemoveId, setStockImageRemoveId] = useState("");
    const [batchPrintId, setBatchPrintId] = useState("");
    const [batchPrintNumber, setBatchPrintNumber] = useState("")
    const [listDepreciation, setListDepreciation] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [listInventoryImage, setListInventoryImage] = useState([]);
    const [listDivision, setListDivision] = useState([]);
    const [staffName, setStaffName] = useState("");
    const [dateNow, setDateNow] = useState("");
    const [listPrint, setListPrint] = useState("");
    const [totalStock, setTotalStock] = useState("");
    const [listInventoryStockImage, setListInventoryStockImage] = useState([]);
    const [listInventoryStockTmp, setListInventoryStockTmp] = useState([]);
    const [deletedButton, setDeletedButton] = useState("");
    const [listWarehouse, setListWarehouse] = useState([]);
    const [listInventoryBatch, setListInventoryBatch] = useState([]);
    const [listUnitType, setListUnitType] = useState([]);
    const [listRackCoordinate, setListRackCoordinate] = useState([]);
    const [stockImageDownloadId, setStockImageDownloadId] = useState("");
    const [printModal, setPrintModal] = useState("");
    const [selectedDepreciation, setSelectedDepreciation] = useState({ id: "" });
    const location = useLocation();
    const [listInventoryStock, setListInventoryStock] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showStockImageModal, setShowStockImageModal] = useState("");
    const [searchQueryInventory, setSearchQueryInventory] = useState("");
    const [selectedInventoryMaster, setSelectedInventoryMaster] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [inventoryStockCount, setinventoryStockCount] = useState(0);
    const [buttonSearch, setButtonSearch] = useState(true)
    const [imageIsSearched, setImageIsSearched] = useState(0);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [disabledImageButton, setDisabledImageButton] = useState(false);
    const [imagePage, setImagePage] = useState(0);
    const [imageItemPerpage, setImageItemPerPage] = useState(10);
    const [imagetotalPage, setImageTotalPage] = useState(0);
    const [imageModal, setImageModal] = useState("");
    const [imageItemPerPageSelection, setImageItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [imageResetSearch, setImageResetSearch] = useState(0);
    const [imageSearchQuery, setImageSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    // const [image]
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [resetSearch, setResetSearch] = useState(0);
    const [descending, setDescending] = useState(true);
    const [showStockModal, setShowStockModal] = useState(false);
    const [findInventoryModal, setFindInventoryModal] = useState(false);
    const [listCurrency, setListCurrency] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [listInventoryStatus, setlistInventoryStatus] = useState([]);
    const [inventoryStockId, setInventoryStockId] = useState("");
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);
    const [listInventoryPeriod, setlistInventoryPeriod] = useState([]);
    const [listStaff, setListStaff] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedRackCoordinate, setSelectedRackCoordinate] = useState({ id: "" });
    const [selectedWarehouse, setSelectedWarehouse] = useState({ id: "" });
    const [downloadId, setDownloadId] = useState("");
    const [imageId, setImageId] = useState("");
    const [imageStockId, setImageStockId] = useState("");
    const [listSubType, setListSubType] = useState([]);
    const [listInventoryFiltered, setListInventoryFiltered] = useState([]);
    const [selectedSubType, setSelectedSubType] = useState("");
    const [inventoryMasterIdFromFind, setInventoryMasterIdFromFind] = useState("");
    const [categoryTitle, setCategoryTitle ] = useState();
    const [orderBy, setOrderBy] = useState({
        label: `${t("inventory_stock.order_name1")}`,
        value: "inventory_code"
    });

    const [newDocument, setNewDocument] = useState({
        id: 0,
        inventory_id: location.state.inventoryMasterId,
        base64_image: "",
        label: "",
        taken_date: null,
        done: false,
    });

    const [stockImage, setStockImage] = useState({
        id: 0,
        inventory_stock_id: null,
        base64_image: "",
        label: "",
        taken_date: null,
        done: false,
    });

    const [stockImageToBeViewed, setStockImageToBeViewed] = useState({
        id: 0,
        inventory_stock_id: null,
        base64_image: "",
        label: "",
        taken_date: null
    })

    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        inventory_id: location.state.inventoryMasterId,
        base64_image: "",
        label: "",
        taken_date: null
    });

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            inventory_id: location.state.inventoryMasterId,
            base64_image: "",
            label: "",
            done: false
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    const resetStockImage = async () => {
        let newDocument = {
            id: 0,
            inventory_stock_id: null,
            base64_image: "",
            label: "",
            done: false
        };
        setStockImage({ ...stockImage, newDocument });
    }

    const orderByList = [
        {
            label: `${t("inventory_stock.order_name1")}`,
            value: "inventory_code"
        },
        // {
        //     label: `${t("inventory_stock.order_name2")}`,
        //     value: "purchase_date"
        // }
        // {
        //     label: "Tanggal Serah Terima",
        //     value: "received_date"
        // },
        // {
        //     label: "Status Barang",
        //     value: "inventory_status_name"
        // },
        // {
        //     label: "Kondisi Barang",
        //     value: "inventory_condition_name"
        // },
        // {
        //     label: "Penanggung Jawab",
        //     value: "staff_name"
        // },


    ]

    const [inventoryMaster, setInventoryMaster] = useState({
        id: 0,
        inventory_master_name: "",
        division_id: "",
        unit: "",
        brand: "",
        model: "",
        quantity: "",
        inventory_master_description: "",
        inventory_master_note: "",
        inventory_type_id: "",
        type_name: "",
        division_name: "",
        division_code: "",
        created_by: "",
        lead_time: "",
        buffer_stock: "",
        inventory_category:"",
        category_code:"",
        type_code:"",
        weight_gram: "",
        is_consumables: false,
        inventory_type_name: ""
    });


    const [inventoryStock, setInventoryStock] = useState({
        id: 0,
        inventory_master_id: "",
        inventory_code: "",
        purchase_date: "",
        received_date: "",
        inventory_period_id: "",
        period_number: "",
        purchase_price: "",
        currency_id: "",
        currency_code: "",
        isIDR: false,
        currency_value_idr: "",
        staff_id: "",
        inventory_status_id: "",
        removed_date: "",
        inventory_condition_id: "",
        depreciation_rate: null,
        inventory_detail_description: "",
        inventory_detail_note: "",
        last_checked_date: null,
        barcode_number: "",
        rack_coordinate_id: null,
        depreciation_group_id: null,
        warehouse_id: null,
        calibration_date: null,
        batch_amount: "",
        inventory_category_id: "",
        category_name: "",
        division_id: "",
        division_code: "",
        division_name: "",
        // estimation_return_date: null
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadInventoryImage();
                loadStaff();
                loadDivision();
                loadCurrency();
                loadCategory();
                loadInventoryPeriod();
                loadInventoryStatus();
                loadInventoryCondition();
                loadDepreciationGroup();
                loadAccess();
                loadWarehouse();
                loadSubType();
                findInventoryMasterById();
                loadInventoryStock();
                setCategoryTitle(location.state.categoryTitle);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        validationForm();
    }, [selectedSubType, searchQueryInventory]);

    useEffect(() => {
        loadInventoryImage();
    }, [imagePage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryImage();
    }, [imageItemPerpage]);

    useEffect(() => {
        if (imageId !== "")
            loadImageById()
    }, [imageId]);

    useEffect(() => {
        if (imageStockId !== "")
            loadImageStockById();
    }, [imageStockId])

    useEffect(() => {
        if (downloadId !== "")
            downloadData();
    }, [downloadId])

    useEffect(() => {
        if (stockImageDownloadId !== "")
            downloadStockData();
    }, [stockImageDownloadId])

    useEffect(() => {
        if (imageSearchQuery === "")
            loadInventoryImage();
    }, [imageSearchQuery]);

    useEffect(() => {
        if (imageResetSearch !== 0) {
            loadInventoryImage()
        }
    }, [imageResetSearch]);

    useEffect(() => {
        if (!showImageModal) {
            setDocumentToBeViewed({
                id: 0,
                inventory_id: location.state.inventoryMasterId,
                base64_image: "",
                label: "",

            });
            setImageId("")
        }
    }, [showImageModal]);

    useEffect(() => {
        if (!showStockImageModal) {
            setStockImageToBeViewed({
                id: 0,
                inventory_stock_id: null,
                base64_image: "",
                label: "",
                taken_date: null

            });
            setImageStockId("")
        }
    }, [showStockImageModal]);

    useEffect(() => {
        if (documentToBeViewed.id !== 0)
            setShowImageModal(true);
    }, [documentToBeViewed]);

    useEffect(() => {
        if (stockImageToBeViewed.id !== 0)
            setShowStockImageModal(true);
    }, [stockImageToBeViewed]);

    useEffect(() => {
        if (imageRemoveId !== "") {
            removeInventoryImage();
        }
    }, [imageRemoveId])

    useEffect(() => {
        if (stockImageRemoveId !== "") {
            removeInventoryStockImage();
        }
    }, [stockImageRemoveId])

    useEffect(() => {
        async function submitNewDocument() {
            if (newDocument.done) {
                await uploadImage();
            }
        }
        submitNewDocument();
    }, [newDocument]);

    useEffect(() => {
        loadInventoryStock();
    }, [inventoryMasterIdFromFind])

    useEffect(() => {
        if (selectedRackCoordinate.warehouse_id) {
            setInventoryStock({ ...inventoryStock, rack_coordinate_id: selectedRackCoordinate.id });
        } else {
            setInventoryStock({ ...inventoryStock, rack_coordinate_id: null });
        }
    }, [selectedRackCoordinate]);

    useEffect(() => {
        if (selectedWarehouse.warehouse_name) {
            setInventoryStock({ ...inventoryStock, warehouse_id: selectedWarehouse.id });
            loadRackCoordinate();
        } else {
            setInventoryStock({ ...inventoryStock, warehouse_id: null, rack_coordinate_id: null });
            loadRackCoordinate();
            setSelectedRackCoordinate({});
        }
    }, [selectedWarehouse]);

    useEffect(() => {
        if (selectedDepreciation.group_name) {

            setInventoryStock({ ...inventoryStock, depreciation_group_id: selectedDepreciation.id, depreciation_rate: (Number(100) / Number(selectedDepreciation.number_of_mounth)).toFixed(2) });
        } else {
            setInventoryStock({ ...inventoryStock, depreciation_group_id: null, depreciation_rate: null });
        }
    }, [selectedDepreciation]);

    useEffect(() => {
        loadInventoryStock();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryStock();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryStock();
    }, [orderBy]);

    useEffect(() => {
        if (isSearched !== 0)
            loadInventoryStock();
    }, [descending]);

    useEffect(() => {
        if (resetSearch !== 0) {
            loadInventoryStock()
        }
    }, [resetSearch])

    useEffect(() => {
        if (inventoryStock.rack_coordinate_id) {
            let rackCoordinate = listRackCoordinate.find(p => p.id === inventoryStock.rack_coordinate_id);
            if (rackCoordinate) {
                setSelectedRackCoordinate(rackCoordinate);
            }

        }
        if (inventoryStock.warehouse_id) {
            let warehouse = listWarehouse.find(p => p.id === inventoryStock.warehouse_id);
            if (warehouse) {
                setSelectedWarehouse(warehouse);
            }

        }
        if (inventoryStock.depreciation_group_id) {
            let response = listDepreciation.find(p => p.id === inventoryStock.depreciation_group_id);
            if (response) {
                setSelectedDepreciation(response);
            }

        }

        console.log(inventoryStock);
    }, [inventoryStock])

    const initInventoryStock = async (inventoryStockId) => {
        try {
            let response = await getInventoryStockById(cookies.token, inventoryStockId, cookies.languageId);
            console.log(response);
            if (response) {
                setInventoryStock({
                    id: response.id,
                    inventory_master_id: response.inventory_master_id,
                    inventory_code: response.inventory_code,
                    purchase_date: response.purchase_date,
                    received_date: response.received_date,
                    inventory_period_id: response.inventory_period_id,
                    period_number: response.period_number,
                    purchase_price: response.purchase_price,
                    currency_id: response.currency_id,
                    currency_value_idr: response.currency_value_idr,
                    staff_id: response.staff_id,
                    inventory_status_id: response.inventory_status_id,
                    removed_date: response.removed_date,
                    inventory_condition_id: response.inventory_condition_id,
                    depreciation_rate: response.depreciation_rate,
                    inventory_detail_description: response.inventory_detail_description,
                    inventory_detail_note: response.inventory_detail_note,
                    isIDR: response.currency_value_idr === 1 ? true : false,
                    last_checked_date: response.last_checked_date,
                    barcode_number: response.barcode_number,
                    rack_coordinate_id: response.rack_coordinate_id,
                    depreciation_group_id: response.depreciation_group_id,
                    warehouse_id: response.warehouse_id,
                    calibration_date: response.calibration_date,
                    is_batch: response.is_batch,
                    batch_amount: response.batch_amount,
                    order_number: response.order_number,
                    inventory_category_id: response.inventory_category_id,
                    category_name: response.category_name,
                    division_id: response.division_id,
                    division_code: response.division_code,
                    division_name: response.division_name,
                    // estimation_return_date: response.estimation_return_date

                });
                // if(response.currency_value_idr==1) setInventoryStock({ ...inventoryStock, isIDR: true })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const generateCode = async () => {
        try {
            let randomNumber = Math.floor(Math.random() * 100000000);
            let checkCode = await checkBarcodeNumber(cookies.token, randomNumber);
            if (checkCode != 0) {
                return (generateCode());
            } else {
                return (randomNumber);
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const clearInventoryStockModal = async () => {
        inventoryStock.id = 0;
        inventoryStock.inventory_code = "";
        inventoryStock.purchase_date = "";
        inventoryStock.received_date = "";
        inventoryStock.inventory_period_id = "";
        inventoryStock.period_number = "";
        inventoryStock.purchase_price = "";
        inventoryStock.currency_id = "";
        inventoryStock.currency_value_idr = "";
        inventoryStock.staff_id = "";
        inventoryStock.inventory_status_id = "";
        inventoryStock.removed_date = "";
        inventoryStock.inventory_condition_id = "";
        inventoryStock.depreciation_rate = null;
        inventoryStock.inventory_detail_description = "";
        inventoryStock.inventory_detail_note = "";
        inventoryStock.isIDR = false;
        inventoryStock.depreciation_group_id = null;
        inventoryStock.calibration_date = null;
        inventoryStock.is_batch = false;
        inventoryStock.batch_amount = "";
        inventoryStock.inventory_category_id = "";
        inventoryStock.category_name = "";
        inventoryStock.division_id = "";
        // inventoryStock.estimation_return_date = null
        setSelectedDepreciation({});
        setSelectedRackCoordinate({});
        setSelectedWarehouse({})


    }

    const saveInventoryStock = async () => {
        try {
            setLoading(true);
            //setDisabledButton(true);
            let invTmp = inventoryStock;
            // if (inventoryStock.id === 0) {
            //     let barcodeNumber = await generateCode();
            //     let today = new Date();
            //     let yyyy = today.getFullYear();
            //     let invCode = "RPI/" + location.state.divisionCode + "/EQP/" + yyyy + "/" + barcodeNumber;
            //     invTmp.inventory_code = invCode;
            //     invTmp.barcode_number = barcodeNumber;
            // }

            let staff_id, currency_value_idr, removed_date, received_date, purchase_price, period_number;
            if (inventoryStock.staff_id == "") staff_id = undefined;
            if (inventoryStock.removed_date == "") removed_date = null;
            if (inventoryStock.received_date == "") received_date = null;
            if (inventoryStock.currency_value_idr !== null){
                if (inventoryStock.currency_value_idr.toString().match(/\d{1,3}/gi)) currency_value_idr = inventoryStock.currency_value_idr.toString().replace(/[^,\d]/g, '');
            }
            if (inventoryStock.purchase_price.toString().match(/\d{1,3}/gi)) purchase_price = inventoryStock.purchase_price.toString().replace(/[^,\d]/g, '');
            if (inventoryStock.isIDR == true) currency_value_idr = 1;
            if (inventoryStock.period_number.toString().match(/\d{1,3}/gi)) period_number = inventoryStock.period_number.toString().replace(/[^,\d]/g, '');

            invTmp.inventory_master_id = inventoryMaster.id;
            invTmp.purchase_price = purchase_price;
            invTmp.staff_id = staff_id;
            invTmp.removed_date = removed_date;
            invTmp.received_date = received_date;
            invTmp.currency_value_idr = currency_value_idr;
            invTmp.period_number = period_number;
            invTmp.inventory_master_id = inventoryMaster.id;
            if (invTmp.batch_amount === "") {
                invTmp.batch_amount = 0
            }

            console.log(invTmp);

            let response = await insertUpdateInventoryStock(cookies.token, invTmp);
            console.log(response);
            if (response.error_code === 0) {
                
                setLoading(false);
                alert(`${t("inventory_stock.alert_success_save_data")}`);
                setShowStockModal(false);
                // findInventoryMasterById(response.id);
                // navigate("/InventoryStock", {state: {inventoryMasterId: response.id}});
                findInventoryMasterById();
                loadInventoryStock();
            }
            setDisabledButton(false)

        } catch (exception) {
            console.log(exception);
            // setDisabledButton(true);

        }

    }

    const loadCategory = async () => {
        try {
            let listCategory = await getCategory(cookies.token, cookies.languageId);
            setListCategory(listCategory);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadDivision = async () => {
        try {
            let listDivision = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListDivision(listDivision);
        }
        catch (exception) {
            console.log(exception)
        }
    }
    
    const loadCurrency = async () => {
        try {
            let listCurrency = await getCurrency(cookies.token, cookies.languageId);
            setListCurrency(listCurrency);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadSubType = async () => {
        try {
            let response = await getInventoryTypeSubItem(cookies.token, cookies.languageId, cookies.extCustomerId, 0, 100000, "inventory_type_name", false, undefined, false);
            // console.log(response);
            setListSubType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryPeriod = async () => {
        try {
            let listInventoryPeriod = await getInventoryPeriod(cookies.token, cookies.languageId);
            setlistInventoryPeriod(listInventoryPeriod);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const loadInventoryStatus = async () => {
        try {
            let listInventoryStatus = await getInventoryStatus(cookies.token, cookies.languageId);
            setlistInventoryStatus(listInventoryStatus);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }


    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }


    const loadStaff = async () => {
        try {
            let listStaff = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, 0, 100, "employee_number", true);

            // setLoading(true);
            setListStaff(listStaff);
            let listNewStaff = [];
            var opt = {
                value: "",
                label: "Pilih Penanggung Jawab...",
                id: ""

            };
            listNewStaff.push(opt);
            for (let i = 0; i < listStaff.length; i++) {
                var obj = {
                    value: listStaff[i].employee_number,
                    label: listStaff[i].staff_name + " (" + listStaff[i].employee_number + ") ",
                    id: listStaff[i].staff_id

                };

                listNewStaff.push(obj);

            }
            setOptions(listNewStaff);


        } catch (exception) {
            console.log(exception);
        }


    }




    const loadInventoryStock = async () => {
        try {
            let InventoryStockCount = await getInventoryStockCount(cookies.token, location.state.inventoryMasterId ? location.state.inventoryMasterId : inventoryMasterIdFromFind, cookies.languageId, searchQuery, false);
            setinventoryStockCount(inventoryStockCount);
            let totalPage = InventoryStockCount / itemPerPage;
            setTotalPage(totalPage);
            console.log(location.state.inventoryCategoryId);
            let listInventoryStock = await getInventoryStock(cookies.token, cookies.languageId, page, itemPerPage, orderBy.value, descending, searchQuery, false, location.state.inventoryMasterId ? location.state.inventoryMasterId : inventoryMasterIdFromFind, location.state.inventoryCategoryId);
            console.log(listInventoryStock);
            setListInventoryStock(listInventoryStock);
            setIsSearched(0)
            setLoading(false);
        } catch (exception) {

        }
    }

    const deleteInventory = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("inventory_stock.delete_confirmation")}`) == true) {
                let result = await deleteInventoryStock(cookies.token, id);
                if (result.success) {
                    if (result.error_code === 0) {
                        alert(`${t("inventory_stock.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("inventory_stock.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("inventory_stock.delete_failed_alert")}`);
                }
                loadInventoryStock();
                findInventoryMasterById();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }

    const findInventoryMasterById = async (id) => {
        try {
            let response = await getInventoryMasterById(cookies.token, location.state.inventoryMasterId ? location.state.inventoryMasterId : id , cookies.languageId);
            console.log(response);
            if (response) {
                setInventoryMaster({
                    id: response.id,
                    inventory_master_name: response.inventory_master_name,
                    division_id: response.division_id,
                    unit: response.unit_name,
                    brand: response.brand,
                    model: response.model,
                    quantity: response.quantity,
                    inventory_master_description: response.inventory_master_description,
                    inventory_master_note: response.inventory_master_note,
                    inventory_type_id: response.inventory_type_id,
                    type_name: response.inventory_type_name,
                    division_name: response.division_name,
                    division_code: response.division_code,
                    created_by: response.created_by,
                    bulk_item: response.bulk_item,
                    lead_time: response.lead_time,
                    buffer_stock: response.buffer_stock,
                    type_code: response.inventory_type_sub_item_code,
                    inventory_category: response.inventory_category,
                    category_code: response.inventory_type_code,
                    weight_gram: response.weight_gram,
                    is_consumables: response.is_consumables,
                    inventory_type_name: response.inventory_type_name

                });
            }
            // loadInventoryStock(response.id);

        } catch (exception) {
            console.log(exception);
        }
    }

    const printPageArea = () => {
        //console.log('print');  

        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        // logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.print();
        // WinPrint.close();
        // logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }

    const loadRackCoordinate = async () => {
        try {
            let response = await getRackCoordinate(cookies.token, cookies.extCustomerId);
            let listCoordinate = response.filter(p => p.warehouse_id === selectedWarehouse.id)
            setListRackCoordinate(listCoordinate);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadDepreciationGroup = async () => {
        try {
            let response = await getDepreciationGroup(cookies.token);
            setListDepreciation(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadWarehouse = async () => {
        try {
            let response = await getWarehouse(cookies.token, cookies.extCustomerId);
            setListWarehouse(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const printBarcode1 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 1,
                    inv_number: inventoryStock.inventory_code,
                    inv_name: inventoryMaster.inventory_master_name,
                    inv_type: inventoryMaster.type_name,
                    purchase_date: inventoryStock.purchase_date,
                    cal_date: inventoryStock.calibration_date,
                    barcode: inventoryStock.barcode_number
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    const printBarcode2 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 2,
                    barcode: inventoryStock.barcode_number
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    const printBatchBarcode = async () => {
        try {
            let listBarcode = await getListBatchBarcode(cookies.token, batchPrintNumber, location.state.inventoryMasterId);
            if (listBarcode.length > 0) {
                let response = await axios.get(`http://localhost:5000/print`, {
                    params: {
                        mokona: 4,
                        barcode: listBarcode
                    }
                });
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const printBarcode3 = async () => {
        try {
            let response = await axios.get(`http://localhost:5000/print`, {
                params: {
                    mokona: 3,
                }
            });
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "/InventoryMaster", cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("inventory_stock.order_name1")}`,
                value: "inventory_code"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadInventoryImage = async () => {
        try {
            let count = await getCountInventoryImage(cookies.token, location.state.inventoryMasterId, searchQuery, false);
            let totalPage = count / itemPerPage;
            setImageTotalPage(totalPage);

            let response = await getInventoryImage(cookies.token, location.state.inventoryMasterId, imagePage, imageItemPerpage, undefined, undefined, searchQuery, false);

            setListInventoryImage(response);
            setLoading(false);
            setImageIsSearched(0);
            setImageResetSearch(0)
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadInvetoryStockImage = async () => {
        try {
            let count = await getCountInventoryStockImage(cookies.token, inventoryStockId, searchQuery, false);
            let totalPage = count / 50;
            setImageTotalPage(totalPage);

            let response = await getInventoryStockImage(cookies.token, inventoryStockId, 0, 50, undefined, undefined, searchQuery, false);

            setListInventoryStockImage(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }


    const downloadData = async () => {
        try {
            let response = await getInventoryImageById(cookies.token, downloadId);
            triggerBase64Download(response.base64_image, response.label);
            setLoading(false);
            setDownloadId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadStockData = async () => {
        try {
            let response = await getInventoryStockImageById(cookies.token, stockImageDownloadId);
            triggerBase64Download(response.base64_image, response.label);
            setLoading(false);
            setStockImageDownloadId("");
        } catch (exception) {
            console.log(exception)
        }
    }


    const loadImageById = async () => {
        try {
            let response = await getInventoryImageById(cookies.token, imageId);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadImageStockById = async () => {
        try {
            let response = await getInventoryStockImageById(cookies.token, imageStockId);
            setStockImageToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const uploadImage = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await insertUpdateInventoryImage(cookies.token, newDocument);

                setImageModal(false);
                resetUploadForm();
                loadInventoryImage();
                setDisabledImageButton(false);
                resolve();
            }
            catch (exception) {
                console.log(exception);
                reject();
            }
        });
    }


    const removeInventoryImage = async () => {
        try {
            console.log(imageRemoveId);
            let response = await deleteInventoryImage(cookies.token, imageRemoveId);
            console.log(response);
            if (response === 0) {
                alert(`${t("inventory_stock.delete_success_alert")}`);
            } else {
                alert(`${t("inventory_stock.delete_failed_alert")}`);
            }

            loadInventoryImage();
            setImageRemoveId("");
        } catch (exception) {

        }
    }

    const removeInventoryStockImage = async () => {
        try {
            console.log(imageRemoveId);
            let response = await deleteInventoryStockImage(cookies.token, stockImageRemoveId);
            console.log(response);
            if (response === 0) {
                alert(`${t("inventory_stock.delete_success_alert")}`);
            } else {
                alert(`${t("inventory_stock.delete_failed_alert")}`);
            }

            loadInvetoryStockImage();
            setStockImageRemoveId("");
        } catch (exception) {

        }
    }

    const loadInventoryBatch = async () => {
        try {
            let response = await getInventoryBatchNumber(cookies.token, location.state.inventoryMasterId);
            setListInventoryBatch(response)
        } catch (exception) {

        }
    }

    const findInventory = async () => {
        try{
            let response = await getInventoryMasterForCategory(cookies.token, cookies.languageId, searchQueryInventory, selectedSubType.id );
            console.log(response);
            setListInventoryFiltered(response);

        } catch(exception){
            console.log(exception);
        }
    }

    const handleResets = async () => {
        setSelectedSubType({id: ""});
        setSearchQueryInventory("");
        setListInventoryFiltered([]);
    }

    const validationForm = async () => {

        // if (!searchQueryInventory) {
        //     setButtonSearch(true)
        // } 

        // if (selectedSubType.id === "" || !selectedSubType) {
            
        // } 
        
        if(searchQueryInventory || selectedSubType.id){
            setButtonSearch(false)
        } else {
            setButtonSearch(true)
        }
        
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("inventory_stock.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate(location.state.backButton); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }

                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                                paddingTop: 10

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexWrap: "nowrap",
                                    flexDirection: "column",
                                    borderWidth: 2,
                                    borderColor: "rgba(3, 30, 103, 1)",
                                    borderRadius: 10,
                                    borderStyle: "solid",

                                }}>
                                    <Tabs
                                        defaultActiveKey="detailStock"
                                        // transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3"
                                        style={{
                                            backgroundColor: "rgba(3, 30, 103, 1)",
                                            borderRadius: 5,

                                        }}
                                    >
                                        <Tab eventKey="detailStock" title={`${t("inventory_stock.tab_1")}`}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: 10
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 10,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <ContainerBox containerPos="inner" useBackButton={true}
                                                        backButtonChild={
                                                            <div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    flexDirection: "row",
                                                                    alignSelf: "center",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        flex: 1,
                                                                    }}><CardChecklist color="white" size={32} /></div>
                                                                </div></div>
                                                        } titleCaption={`${t("inventory_stock.header_inventory_information")}`} childContent={
                                                            <div style={{
                                                                padding: 10,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    // width: "100%"
                                                                }}>
                                                                    <div style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    padding: '10px'}}>
                                                                        <Button 
                                                                            disabled={location.state.inventoryMasterId !== "" ? true : false} 
                                                                            // disabled={!updatedButton} 
                                                                            variant="primary" style={{
                                                                                flex: 1,
                                                                            }} onClick={() => {
                                                                                setFindInventoryModal(true);
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flex: 1,
                                                                                    justifyContent: "center",
                                                                                }}>
                                                                                    <div style={{
                                                                                        padding: '5px',
                                                                                    }}><Search size={18} /></div>
                                                                                    <div style={{
                                                                                        padding: '3px',
                                                                                        fontWeight: "bold",
                                                                                        fontSize: 20,
                                                                                    }}>{`${t("inventory_stock.button_find_inventory")}`}</div>
                                                                                </div>
                                                                            </Button>
                                                                    </div>

                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        flex: 1,
                                                                        flexWrap: "nowrap",
                                                                        paddingRight: 10
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            flex: 1,
                                                                            flexWrap: "nowrap",
                                                                            paddingRight: 10
                                                                        }}>

                                                                            <TextBox value={inventoryMaster.inventory_master_name} placeholder=""
                                                                                caption={`${t("inventory_stock.field_inventory_name")}`} disabledForm="true" />
                                                                            <TextBox value={
                                                                                            inventoryMaster.type_code !== undefined ? inventoryMaster.type_name + (inventoryMaster.type_code?`(${inventoryMaster.type_code})`:" ") :
                                                                                            ""
                                                                                            }
                                                                                placeholder=""
                                                                                caption={`${t("inventory_stock.field_inventory_type")}`} disabledForm="true" />
                                                                            <TextBox value={
                                                                                            inventoryMaster.category_code !== undefined ? inventoryMaster.inventory_category + (inventoryMaster.category_code? `(${inventoryMaster.category_code})`:" ") :
                                                                                            ""
                                                                                            }
                                                                                placeholder=""
                                                                                caption={`${t("inventory_stock.field_category")}`} disabledForm="true" />
                                                                            {/* <TextBox value={
                                                                                            inventoryMaster.division_code !== undefined ? inventoryMaster.division_name + `(${inventoryMaster.division_code})` :
                                                                                            ""
                                                                                            }
                                                                                placeholder=""
                                                                                caption={`${t("inventory_stock.field_division")}`} disabledForm="true" /> */}
                                                                            <TextBox value={inventoryMaster.weight_gram} placeholder=""
                                                                                caption={`${t("inventory_stock.field_weight_gram")}`} disabledForm="true" />
                                                                            <TextBox value={inventoryMaster.unit} placeholder=""
                                                                                caption={`${t("inventory_stock.field_unit_type")}`} disabledForm="true" />
                                                                            <TextBox value={inventoryMaster.brand} placeholder=""
                                                                                caption={`${t("inventory_stock.field_merk")}`} disabledForm="true" />
                                                                            <TextBox value={inventoryMaster.lead_time} placeholder=""
                                                                                caption={`${t("inventory_stock.field_lead_time")}`} disabledForm="true" />
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            flex: 1,
                                                                            flexWrap: "nowrap",
                                                                            paddingLeft: 10
                                                                        }}>
                                                                            {
                                                                                inventoryMaster.is_consumables === true ? <TextBox value={inventoryMaster.buffer_stock} placeholder=""
                                                                                caption={`${t("inventory_stock.field_buffer_stock")}`} disabledForm="true" /> : <></>
                                                                            }
                                                                            
                                                                            <TextBox value={inventoryMaster.model} placeholder=""
                                                                                caption={`${t("inventory_stock.field_model")}`} disabledForm="true" />
                                                                            {/* <TextBox value={inventoryMaster.reference_price} placeholder="Harga Referensi"
                                                                    caption="Harga Referensi" disabledForm="true" /> */}
                                                                            <TextBox value={inventoryMaster.quantity} placeholder=""
                                                                                caption={`${t("inventory_stock.field_stock")}`} disabledForm="true" />
                                                                            <TextBox value={inventoryMaster.inventory_master_description} placeholder=""
                                                                                caption={`${t("inventory_stock.field_description")}`} asType={"textarea"} disabledForm="true" />
                                                                            <TextBox value={inventoryMaster.inventory_master_note} placeholder=""
                                                                                caption={`${t("inventory_stock.field_note")}`} asType={"textarea"} disabledForm="true" />

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>


                                                        }>


                                                    </ContainerBox>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                }}>

                                                    <ContainerBox
                                                        titleCaption={categoryTitle === 'Master' ? `${t("inventory_stock.header_title_master")}` : 
                                                                        categoryTitle === 'Workshop' ? `${t("inventory_stock.header_title_workshop")}` :
                                                                        categoryTitle === 'Special Tool' ? `${t("inventory_stock.header_title_special_tool")}`:
                                                                        categoryTitle === 'Group' ? `${t("inventory_stock.header_title_group")}` : `${t("inventory_stock.header_title")}`
                                                                    }
                                                        useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                        containerPos="inner"
                                                        actionContainerChild={
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",

                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Button disabled={inventoryMaster.bulk_item ? true : !updatedButton} variant="secondary" style={{
                                                                        paddingLeft: 10
                                                                    }} onClick={() => {
                                                                        loadInventoryBatch();
                                                                        setPrintModal(true);
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            alignSelf: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flex: 1,
                                                                            }}><PrinterFill size={32} /></div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 8,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                paddingLeft: 10,
                                                                                fontWeight: "bold",
                                                                                fontSize: 18,
                                                                            }}>Print Batch</div>
                                                                        </div>
                                                                    </Button>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    paddingLeft: 5
                                                                }}>
                                                                    <Button disabled={inventoryMaster.bulk_item ? true : !updatedButton} variant="secondary" style={{

                                                                    }} onClick={() => {
                                                                        clearInventoryStockModal();
                                                                        setShowStockModal(true);
                                                                        setInventoryStock({ ...inventoryStock, id: 0 });
                                                                        inventoryStock.id = 0;
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            alignSelf: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flex: 1,
                                                                            }}><FilePlusFill size={32} /></div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 8,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                paddingLeft: 10,
                                                                                fontWeight: "bold",
                                                                                fontSize: 18,
                                                                            }}>{t("inventory_stock.add_button")}</div>
                                                                        </div>
                                                                    </Button>
                                                                </div>




                                                            </div>
                                                        }
                                                        childContent={
                                                            <div className="master-table-inner-container">
                                                                <div className="master-table-searchbar-container">
                                                                    <div className="master-table-searchbar-textbox">
                                                                        <Form.Control value={searchQuery} type="text" placeholder={`${t("inventory_stock.search_box")}`}
                                                                            onKeyPress={async (e) => {
                                                                                if (e.charCode === 13) {
                                                                                    setPage(0);
                                                                                    setLoading(true);
                                                                                    await loadInventoryStock();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setSearchQuery(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="master-table-searchbar-button">
                                                                        <div style={{ paddingRight: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setPage(0);
                                                                                await loadInventoryStock();
                                                                            }}><Search /></Button>
                                                                        </div>
                                                                        <div style={{ paddingLeft: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setSearchQuery("");
                                                                                setResetSearch(1);
                                                                                // await loadCountry();
                                                                            }}><ArrowClockwise /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-container">
                                                                    <div className="table-header">
                                                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("index_inventory_group.table_name11")}</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("index_inventory_group.table_name1")}</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("index_inventory_group.table_name2")}</div>
                                                                        <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name24")}</div>
                                                                        {/* <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name25")}</div> */}
                                                                        {/* <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name26")}</div> */}
                                                                        <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name27")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("index_inventory_group.table_name28")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("index_inventory_group.table_name29")}</div>
                                                                        <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name8")}</div>
                                                                        <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name10")}</div>
                                                                    </div>
                                                                    {
                                                                        listInventoryStock.map((inventory, index) => {
                                                                            return (
                                                                                <div className="table-body" key={index}>
                                                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                                        <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.inventory_code}</p></div>
                                                                                        <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.inventory_master_name}</p></div>
                                                                                        <div style={{ flex: 4 }} className="table-body-content"><p>{inventoryMaster.inventory_type_name}</p></div>
                                                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.division_code}</p></div>
                                                                                        {/* <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.inventory_status_name}</p></div> */}
                                                                                        {/* <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.inventory_condition_name}</p></div> */}
                                                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.weight_gram ? inventory.weight_gram + 'g' : inventory.weight_gram}</p></div>
                                                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{moment(inventory.purchase_date).format("DD-MM-yyyy")}</p></div>
                                                                                        <div style={{ flex: 2 }} className="table-body-content"><p>{inventory.category_name}</p></div>
                                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                        <div style={{ padding: 5 }}>
                                                                                            <Button variant="primary" onClick={async () => {

                                                                                                // navigate("/InventoryStock/Detail", { state: { inventoryStockId:  inventory.id,inventoryMasterId: location.state.inventoryMasterId,departmentCode:location.state.departmentCode} });
                                                                                                clearInventoryStockModal();
                                                                                                setInventoryStockId(inventory.id);
                                                                                                setStockImage({ ...stockImage, inventory_stock_id: inventory.id })
                                                                                                await initInventoryStock(inventory.id);
                                                                                                //setInventoryStock({ ...inventoryStock, id: inventory.id });
                                                                                                setShowStockModal(true);


                                                                                            }}><PencilFill /></Button>
                                                                                        </div></div>

                                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                        <div style={{ padding: 5 }}>
                                                                                            <Button disabled={(cookies.logingAs === "Customer" && cookies.extCustomerId !== "null") || inventoryMaster.bulk_item ? true : !deletedButton} variant="danger"
                                                                                                onClick={event => {
                                                                                                    deleteInventory(inventory.id);
                                                                                                }}
                                                                                            ><Trash /></Button>
                                                                                        </div></div>
                                                                                </div>)
                                                                        }
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    paddingTop: 20,
                                                                }}>

                                                                </div>

                                                                {/* {listInventoryStock.length == 0 ?
                                                        <center>Belum Ada Data </center> : <div></div>

                                                    } */}

                                                                <Paginations
                                                                    itemPerPage={itemPerPage}
                                                                    totalPage={totalPage}
                                                                    page={page}
                                                                    setPage={setPage}
                                                                    setItemPerPage={setItemPerPage}
                                                                    itemPerPageSelection={itemPerPageSelection}
                                                                    orderBy={orderBy}
                                                                    setOrderBy={setOrderBy}
                                                                    orderBySelection={orderByList}
                                                                    isDescActive={descending}
                                                                    setIsDescActive={setDescending}
                                                                    setIsSearched={setIsSearched}
                                                                />
                                                                <Loading
                                                                    loading={loading}
                                                                    loadingText={`${t("loading_component.text")}`}
                                                                />
                                                            </div>
                                                        } />
                                                </div>

                                            </div>

                                        </Tab>
                                        <Tab eventKey="inventoryImage" title={`${t("inventory_stock.tab_2")}`}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                padding: "0px 10px 10px 10px",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    // paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <ContainerBox
                                                        containerPos="inner"
                                                        titleCaption={`${t("inventory_stock.container_image")}`}
                                                        useActionContainer={true}
                                                        actionContainerChild={
                                                            <div>
                                                                <Button disabled={!updatedButton} variant="secondary" style={{

                                                                }} onClick={() => {
                                                                    setImageModal(true)
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FilePlusFill size={32} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 10,
                                                                            fontWeight: "bold",
                                                                            fontSize: 18,
                                                                        }}>{`${t("inventory_stock.button_add_image")}`}</div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        }
                                                        childContent={
                                                            <div className="master-table-inner-container">
                                                                <div className="master-table-searchbar-container">
                                                                    <div className="master-table-searchbar-textbox">
                                                                        <Form.Control value={searchQuery} type="text" placeholder={`${t("inventory_stock.image_search_box")}`}
                                                                            onKeyPress={async (e) => {
                                                                                if (e.charCode === 13) {
                                                                                    setLoading(true);
                                                                                    await loadInventoryImage();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setSearchQuery(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="master-table-searchbar-button">
                                                                        <div style={{ paddingRight: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                await loadInventoryImage();
                                                                            }}><Search /></Button>
                                                                        </div>
                                                                        <div style={{ paddingLeft: 5 }}>
                                                                            <Button onClick={async () => {
                                                                                setLoading(true);
                                                                                setSearchQuery("");
                                                                                await loadInventoryImage();
                                                                            }}><ArrowClockwise /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-container">
                                                                    <div className="table-header">
                                                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                                        <div style={{ flex: 4 }} className="table-header-content">{t("inventory_stock.inventory_image_table1")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("inventory_stock.inventory_image_table2")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("inventory_stock.inventory_image_table3")}</div>
                                                                        <div style={{ flex: 2 }} className="table-header-content">{t("inventory_stock.inventory_image_table4")}</div>
                                                                    </div>
                                                                    {
                                                                        listInventoryImage.map((images, index) => {
                                                                            return (<div key={index} className="table-body">
                                                                                <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                <div style={{ flex: 4 }} className="table-body-content"><p>{images.label}</p></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            setLoading(true);
                                                                                            setImageId(images.id);
                                                                                        }}><EyeFill />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button variant="primary" onClick={() => {
                                                                                            setDownloadId(images.id);
                                                                                        }}><Download />
                                                                                        </Button>
                                                                                    </div></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                    <div style={{ padding: 5 }}>
                                                                                        <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                                                            if (window.confirm(`${t("inventory_stock.delete_confirmation")}  ${images.label}?`)) {

                                                                                                setImageRemoveId(images.id);

                                                                                            }
                                                                                        }}><Trash />
                                                                                        </Button>
                                                                                    </div></div>
                                                                            </div>)
                                                                        }
                                                                        )
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    paddingTop: 20,
                                                                }}>

                                                                </div>

                                                            </div>
                                                        } />
                                                </div>
                                            </div>

                                        </Tab>
                                    </Tabs>
                                </div>



                            </div>
                        </div>

                    }>


                </ContainerBox>

                <Modal show={showStockModal}
                    size="xxl"
                    onHide={() => {
                        setShowStockModal(false);

                    }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption="Detail Stock"
                        useActionContainer={true}
                        actionContainerChild={
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                flexWrap: "nowrap",

                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column", paddingRight: 5

                                }}>
                                    <Link to={`/InventoryStock/Photo?id=${inventoryStock.id}`} style={{
                                        pointerEvents: inventoryStock.id === 0 ? "none" : ""
                                    }} target="_blank">
                                        <Button hidden={inventoryStock.id === 0} variant="secondary" style={{

                                        }} onClick={() => {
                                            // setShowStockModal(false);

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                alignSelf: "center",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    flex: 1,
                                                }}><Image size={32} /></div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 8,
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    paddingLeft: 10,
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                }}>{t("inventory_stock.tab_2")}</div>
                                            </div>
                                        </Button>
                                    </Link>

                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: 5
                                }}>
                                    <Button variant="secondary" style={{

                                    }} onClick={() => {
                                        setShowStockModal(false);

                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><XSquareFill size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>{t("detail_daily_report.close_button")}</div>
                                        </div>
                                    </Button>
                                </div>

                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                                paddingTop: 10

                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 10
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 10,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        {inventoryStock.id !== 0 &&
                                            <div style={{
                                                flex: 1,
                                                alignItems: "center"
                                            }}>
                                                <div style={{
                                                    flex: 1,
                                                    padding: 20,
                                                }}>
                                                    <div style={{
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        fontWeight: "bold",
                                                        fontSize: 18
                                                    }}>
                                                        {`${t("inventory_stock.order_name1")}: ${inventoryStock.inventory_code}`}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    padding: 20,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flex: 1,
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    alignItems: "flex-start",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        paddingLeft: 20,
                                                        paddingRight: 20,
                                                    }}>
                                                        <h6>Barcode</h6>
                                                        <Barcode
                                                            height={160}
                                                            value={inventoryStock.id == undefined || inventoryStock.id == "" ? 0 : inventoryStock.barcode_number}
                                                            displayValue={true}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        paddingLeft: 20,
                                                        paddingRight: 20,

                                                    }}>
                                                        <h6>QR Code</h6>
                                                        <QRCode
                                                            size={180}
                                                            style={{ padding: 10 }}
                                                            value={inventoryStock.id == undefined || inventoryStock.id == "" ? 0 : inventoryStock.id}
                                                            viewBox={`0 0 256 256`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        }

                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            saveInventoryStock();
                                            setDisabledButton(true);


                                        }} style={{ padding: 10 }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        paddingRight: 10,
                                                    }}>

                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_batch_item")}</Form.Label>
                                                                <Form.Check disabled={inventoryStock.id !== 0} defaultChecked={inventoryStock.is_batch} label={'Batch Item'} onClick={(e) => {
                                                                    if (e.target.checked) {
                                                                        setInventoryStock({ ...inventoryStock, is_batch: true })
                                                                    } else {
                                                                        setInventoryStock({ ...inventoryStock, is_batch: false })
                                                                    }
                                                                }}>
                                                                </Form.Check>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            padding: 5,
                                                        }}>
                                                            <div style={{ display: "flex", flexDirection: "column", flex: 1, paddingRight: 5 }}>
                                                                <Form.Group hidden={!inventoryStock.is_batch} className="mb-3">
                                                                    <Form.Label>{t("inventory_stock.field_batch_item_amount")}</Form.Label>
                                                                    <Form.Control disabled={inventoryStock.id !== 0} value={inventoryStock.batch_amount} onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        if (/^\d*$/.test(value) || value === "") {
                                                                            setInventoryStock({ ...inventoryStock, batch_amount: e.target.value })
                                                                        } else {

                                                                            setInventoryStock({ ...inventoryStock, batch_amount: "" })
                                                                        }

                                                                    }}></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div hidden={!inventoryStock.is_batch || inventoryStock.id === 0} style={{ display: "flex", flexDirection: "column", flex: 1, paddingLeft: 5 }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Nomor Batch</Form.Label>
                                                                    <Form.Control disabled value={'batch ke ' + inventoryStock.order_number} onChange={(e) => {

                                                                    }}></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_purchase_date")}</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    setInventoryStock({ ...inventoryStock, purchase_date: e.target.value })
                                                                }} type="date" value={inventoryStock.purchase_date} required placeholder="Masukan Tanggal Pembelian"></Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_calibration_date")}</Form.Label>
                                                                <Form.Control onChange={(e) => {
                                                                    if (e.target.value === "") {
                                                                        setInventoryStock({ ...inventoryStock, calibration_date: null })
                                                                    } else {
                                                                        setInventoryStock({ ...inventoryStock, calibration_date: e.target.value })
                                                                    }

                                                                }} type="date" value={inventoryStock.calibration_date ? moment(inventoryStock.calibration_date).format("yyyy-MM-DD") : ""} placeholder=""></Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_item_category")}</Form.Label>
                                                                <Form.Select onChange={(e) => {
                                                                    console.log(e.target.value);
                                                                    setInventoryStock({ ...inventoryStock, inventory_category_id: e.target.value })
                                                                }} value={inventoryStock.inventory_category_id} >
                                                                    <option selected value="">

                                                                    </option>
                                                                    {listCategory.map((category, index) => (
                                                                        <option key={index} value={category.id}>{category.category_name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_item_division")}</Form.Label>
                                                                <Form.Select required onChange={(e) => {
                                                                    setInventoryStock({ ...inventoryStock, division_id: e.target.value })
                                                                }} value={inventoryStock.division_id} >
                                                                    <option selected value="">

                                                                    </option>
                                                                    {listDivision.map((category, index) => (
                                                                        <option key={index} value={category.id}>{category.division_name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_checking_item")}</Form.Label>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <Form.Control
                                                                                onKeyUp={(e) => {

                                                                                    setInventoryStock({ ...inventoryStock, period_number: formatCurrency(e.target.value) })
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    setInventoryStock({ ...inventoryStock, period_number: e.target.value })
                                                                                }} type="text" value={inventoryStock.period_number} required placeholder=""></Form.Control>
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Form.Select required onChange={(e) => {
                                                                                setInventoryStock({ ...inventoryStock, inventory_period_id: e.target.value })
                                                                            }} value={inventoryStock.inventory_period_id} >
                                                                                <option selected value="">

                                                                                </option>
                                                                                {listInventoryPeriod.map((period, index) => (
                                                                                    <option key={index} value={period.id}>{period.inventory_period_name}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_purchase_price")}</Form.Label>
                                                                <Form.Control
                                                                    onKeyUp={(e) => {

                                                                        setInventoryStock({ ...inventoryStock, purchase_price: formatCurrency(e.target.value) })
                                                                    }}

                                                                    onChange={(e) => {
                                                                        setInventoryStock({ ...inventoryStock, purchase_price: e.target.value })
                                                                    }} type="text" value={inventoryStock.purchase_price} required placeholder=""></Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_currency")}</Form.Label>
                                                                <Form.Select required onChange={(e) => {
                                                                    setInventoryStock({ ...inventoryStock, currency_id: e.target.value });
                                                                    inventoryStock.currency_id = e.target.value;
                                                                    listCurrency.map(function (cur) {
                                                                        if (cur.id === inventoryStock.currency_id) {
                                                                            if (cur.currency_code == "IDR") setInventoryStock({ ...inventoryStock, isIDR: true })
                                                                            else setInventoryStock({ ...inventoryStock, isIDR: false })

                                                                        }
                                                                    })
                                                                }} value={inventoryStock.currency_id} >

                                                                    <option selected value="">

                                                                    </option>
                                                                    {listCurrency.map((currency, index) => (
                                                                        <option key={index} value={currency.id}>{currency.currency_name} (
                                                                            {currency.currency_code}
                                                                            )</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_exchange_rate")}</Form.Label>
                                                                <Form.Control disabled={inventoryStock.isIDR}
                                                                    onKeyUp={(e) => {

                                                                        setInventoryStock({ ...inventoryStock, currency_value_idr: formatCurrency(e.target.value, 'Rp. ') })
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setInventoryStock({ ...inventoryStock, currency_value_idr: e.target.value })
                                                                    }} type="text" value={inventoryStock.isIDR ? 1 : inventoryStock.currency_value_idr} required placeholder=""></Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_depreciation_group")}</Form.Label>
                                                                <Select placeholder={""}
                                                                    getOptionLabel={(item) => {
                                                                        return item.group_name;
                                                                    }} clearValue={true}
                                                                    getOptionValue={(item) => {
                                                                        return item.id;
                                                                    }}
                                                                    options={listDepreciation} value={inventoryStock.depreciation_group_id ? selectedDepreciation : null} onChange={(e) => {
                                                                        if (e === null) {
                                                                            setSelectedDepreciation({})
                                                                        } else {
                                                                            setSelectedDepreciation(e);
                                                                        }
                                                                    }}
                                                                    isClearable
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>


                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_depreciation_presentation")}</Form.Label>
                                                                <Form.Control
                                                                    onKeyUp={(e) => {

                                                                        setInventoryStock({ ...inventoryStock, depreciation_rate: formatCurrency(e.target.value) })
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setInventoryStock({ ...inventoryStock, depreciation_rate: e.target.value })
                                                                    }} type="text" value={inventoryStock.depreciation_rate ? inventoryStock.depreciation_rate : ""} placeholder="" disabled="true"></Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        paddingLeft: 10,
                                                    }}>
                                                        {/* <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    padding: 5,
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Tanggal Pengembalian Barang</Form.Label>
                                                        <Form.Control onChange={(e) => {
                                                            if (e.target.value === "") {
                                                                setInventoryStock({ ...inventoryStock, estimation_return_date: null })
                                                            } else {
                                                                setInventoryStock({ ...inventoryStock, estimation_return_date: e.target.value })
                                                            }

                                                        }} type="date" value={inventoryStock.estimation_return_date ? moment(inventoryStock.estimation_return_date).format("yyyy-MM-DD") : ""} placeholder=""></Form.Control>
                                                    </Form.Group>
                                                </div> */}
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label> {t("inventory_stock.field_item_status")}</Form.Label>
                                                                <Form.Select required onChange={(e) => {
                                                                    setInventoryStock({ ...inventoryStock, inventory_status_id: e.target.value })
                                                                }} value={inventoryStock.inventory_status_id} >
                                                                    <option selected value="">

                                                                    </option>
                                                                    {listInventoryStatus.map((status, index) => (
                                                                        <option key={index} value={status.id}>{status.inventory_status_name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_item_condition")}</Form.Label>
                                                                <Form.Select required onChange={(e) => {
                                                                    setInventoryStock({ ...inventoryStock, inventory_condition_id: e.target.value })
                                                                }} value={inventoryStock.inventory_condition_id} >
                                                                    <option selected value="">

                                                                    </option>
                                                                    {listInventoryCondition.map((condition, index) => (
                                                                        <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Label>{t("inventory_stock.field_warehouse")}</Form.Label>
                                                            <Select
                                                                getOptionLabel={(item) => {
                                                                    return item.warehouse_name;
                                                                }} clearValue={true}
                                                                getOptionValue={(item) => {
                                                                    return item.id;
                                                                }}
                                                                options={listWarehouse} value={selectedWarehouse} onChange={(e) => {
                                                                    if (e === null) {
                                                                        setSelectedWarehouse({})
                                                                    } else {
                                                                        setSelectedWarehouse(e);
                                                                    }
                                                                }}
                                                                isClearable

                                                            />
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Label>{t("inventory_stock.field_item_position")}</Form.Label>
                                                            <Select
                                                                getOptionLabel={(item) => {
                                                                    if (item.id) {
                                                                        return item.warehouse_code + '/' + (item.rack_code) + '/' + (item.coordinate_name);
                                                                    } else {
                                                                        return ("");
                                                                    }
                                                                }} clearValue={true}
                                                                getOptionValue={(item) => {
                                                                    return item.id;
                                                                }}
                                                                options={listRackCoordinate} value={selectedRackCoordinate} onChange={(e) => {
                                                                    if (e === null) {
                                                                        setSelectedRackCoordinate({})
                                                                    } else {
                                                                        setSelectedRackCoordinate(e);
                                                                    }
                                                                }}
                                                                isClearable

                                                            />
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_description")}</Form.Label>
                                                                <textarea
                                                                    className="form-control"
                                                                    value={inventoryStock.inventory_detail_description}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        setInventoryStock({ ...inventoryStock, inventory_detail_description: e.target.value })
                                                                    }}
                                                                    rows="4"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "column",
                                                            padding: 5,
                                                        }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>{t("inventory_stock.field_note")}</Form.Label>
                                                                <textarea
                                                                    className="form-control"
                                                                    value={inventoryStock.inventory_detail_note}
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        setInventoryStock({ ...inventoryStock, inventory_detail_note: e.target.value })
                                                                    }}
                                                                    rows="4"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                        <></>
                                                        :
                                                        <div style={{
                                                            display: "flex",
                                                            minWidth: "100%",
                                                            flex: 1,
                                                            padding: 13,
                                                        }}>
                                                            <Button style={{
                                                                width: "100%"
                                                            }} type="submit" 
                                                            disabled={disabledButton === true ? disabledButton : !updatedButton}>
                                                                {
                                                                    // inventoryStock.category_name === "Workshop" ? t("inventory_stock.save_button_workshop") :
                                                                    // inventoryStock.category_name === "Special Tool" ? t("inventory_stock.save_button_special_tool") :
                                                                    // inventoryStock.category_name === "Group" ? t("inventory_stock.save_button_group") :
                                                                    t("inventory_stock.save_button")

                                                                }</Button>
                                                        </div>
                                                }

                                                {
                                                    inventoryStock.id == undefined || inventoryStock.id == "" ?

                                                        <></> :
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            padding: 10,
                                                            flexDirection: "row"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                padding: '3px'
                                                            }}><Button style={{width: "100%"}} onClick={() => {
                                                                printBarcode1()
                                                            }} >{t("inventory_stock.button_print_big_barcode")}</Button></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                padding: '3px'
                                                            }}><Button style={{width: "100%"}} onClick={() => {
                                                                printBarcode2()
                                                            }} >{t("inventory_stock.button_print_small_barcode")}</Button></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                padding: '3px'
                                                            }}><Button style={{width: "100%"}} onClick={() => {
                                                                printBarcode3()
                                                            }} >{t("inventory_stock.button_reset_printer")}</Button></div>


                                                        </div>

                                                }
                                                <div style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    flex: 1,
                                                    padding: 13,
                                                }}>
                                                    <Button style={{
                                                        width: "100%"
                                                    }} variant="danger" onClick={() => {
                                                        setShowStockModal(false);
                                                        // navigate("/InventoryStock", { state: { inventoryMasterId: location.state.inventoryMasterId,departmentCode: location.state.departmentCode} });
                                                    }}>{t("inventory_stock.cancel_button")}</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>

                            </div>

                        } />
                </Modal>

                <Modal show={imageModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        resetUploadForm();
                        setImageModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("sparepart_catalog_document.modal_add_document")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            setDisabledImageButton(true);
                            setNewDocument({ ...newDocument, done: true });
                        }} style={{ padding: 10 }}>
                            <img src={newDocument.base64_image} width={"20%"} style={{ alignContent: "center" }}></img>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("inventory_stock.field_image")}</Form.Label>
                                <Form.Control onChange={async (e) => {
                                    if (e.target.files[0].type.substring(0, e.target.files[0].type.lastIndexOf("/")) === "image") {
                                        let base64Doc = await convertBase64(e.target.files[0]);
                                        setNewDocument({ ...newDocument, base64_image: base64Doc.toString() });
                                        setUploadFileImageError("");
                                        setDisabledImageButton(false)

                                    } else {
                                        setNewDocument({ ...newDocument, base64_image: "" });
                                        setUploadFileImageError(`${t("inventory_stock.error_image_validation")}`)
                                        setDisabledImageButton(true)
                                    }

                                }} type="file" required></Form.Control>
                                {
                                    uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                }
                            </Form.Group>



                            <Form.Group className="mb-3">
                                <Form.Label>{t("inventory_stock.field_label")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setNewDocument({ ...newDocument, label: e.target.value })
                                }} value={newDocument.label} type="text" placeholder="" required></Form.Control>

                            </Form.Group>



                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                            }}>
                                <Button style={{ width: 100 }} variant="primary" type="submit" disabled={disabledImageButton}>
                                    {t("inventory_stock.save_button")}
                                </Button>
                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                    resetUploadForm();
                                    loadInventoryImage();
                                    setImageModal(false);
                                }}>
                                    {t("inventory_stock.cancel_button")}
                                </Button>
                            </div>
                        </Form>



                    </div>

                </Modal>

                <Modal className="modal-xxl" show={showImageModal} onHide={() => {
                    setShowImageModal(false);
                }}>
                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.label}
                        useActionContainer={true}
                        actionContainerChild={
                            <div id="button-color">
                                <Button variant="primary" style={{

                                }} onClick={() => {
                                    setShowImageModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquare size={32} /></div>

                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column"
                            }}>
                                <center> <img src={documentToBeViewed.base64_image} style={{ width: "60%" }}></img></center>

                            </div>
                        } />



                </Modal>

                <Modal size="lg" className="lg" show={printModal} onHide={() => {
                    setPrintModal(false);
                    setBatchPrintId("");
                    setBatchPrintNumber("")
                }}>
                    <ContainerBox containerPos="inner" titleCaption={"Print Batch"}
                        useActionContainer={true}
                        actionContainerChild={
                            <div id="button-color">
                                <Button variant="primary" style={{

                                }} onClick={() => {
                                    setPrintModal(false);
                                    setBatchPrintId("");
                                    setBatchPrintNumber("")
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquare size={32} /></div>

                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 20
                            }}>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        if (item.id) {
                                            return 'Batch ke-' + item.order_number
                                        } else {
                                            return ("");
                                        }
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryBatch} value={listInventoryBatch.filter(function (option) {
                                        return option.id === batchPrintId;
                                    })} onChange={(e) => {
                                        console.log(e);
                                        if (e === null) {
                                            setBatchPrintId("");
                                            setBatchPrintNumber("");
                                        } else {
                                            setBatchPrintId(e.id);
                                            setBatchPrintNumber(e.order_number)
                                        }
                                    }}
                                    isClearable
                                />
                                <div style={{ paddingBottom: 20 }}></div>
                                <Button onClick={() => {
                                    printBatchBarcode()
                                }}>Print Barcode</Button>

                            </div>
                        } />



                </Modal>



                <div id="printStock" style={{ display: 'none', padding: 10 }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ width: '70%' }}>
                        <img src={require("../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>

                    <div style={{
                        // marginLeft: 300,
                        textAlign: "center",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >{t("index_inventory_master.header_title")} </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%", fontWeight: "bold" }}>

                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{staffName}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{dateNow}</td>
                            </tr>
                        </Table>

                    </div>
                    <div style={{ paddingBottom: 5 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr style={{ fontWeight: "bold" }}>
                            <td style={{ width: '5%' }}>No </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_group.table_print1")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_group.table_print2")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print3")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print4")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print5")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print6")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print7")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_group.table_print8")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_group.table_print9")} </td>

                        </tr>

                        {listPrint && listPrint.map((print, index) => {
                            return (
                                <div>
                                    <tr key={index} className="table-caption-body">
                                        <td>{index + 1}</td>
                                        <td>{print.inventory_code}</td>
                                        <td>{print.inventory_master_name}</td>
                                        <td>{print.category_name}</td>
                                        <td>{print.inventory_status_name}</td>
                                        <td>{print.inventory_condition_name}</td>
                                        <td>{print.division_code !== "NONE" ? print.division_code : "-"}</td>
                                        <td>{print.weight_gram}</td>
                                        <td>{print.purchase_date}</td>
                                        <td>{"Rp" + formatRupiah(print.purchase_price)}</td>


                                    </tr>
                                </div>
                            )
                        })
                        }
                        <tr style={{ fontWeight: "bold" }} >
                            <td style={{ textAlign: "right" }} colSpan={9}>{t("index_inventory_master.text_total_stock")} :</td>
                            <td colSpan={7}>{totalStock}</td>
                        </tr>

                    </table>

                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        borderStyle: "solid",
                        borderWidth: 1
                    }}>


                    </div>


                </div>
            </Container>
            <Modal show={findInventoryModal}

                    size={"xl"}
                    onHide={() => {
                        // handleResets();
                        setFindInventoryModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{`${t("inventory_stock.title_find_inventory")}`} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            console.log("Find Inventory")
                            findInventory();


                        }} style={{ padding: 10 }}>
                            <Form.Group className="mb-3">
                                <Form.Label>{`${t("inventory_stock.field_inventory_type")}`}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_type_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listSubType} value={selectedSubType} onChange={(e) => {
                                        console.log(e);
                                        if (e === null) {
                                            setSelectedSubType({id: ''});
                                        } else {

                                            setSelectedSubType(e);
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label>{t("Division")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.division_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listDivision} value={selectedDivision} onChange={(e) => {
                                        // console.log(e);
                                        if (e === null) {
                                            setSelectedDivision({id: ''});
                                        } else {
                                            setSelectedDivision(e);
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group> */}
                            <Form.Group className="mb-3">
                                <Form.Label>{`${t("inventory_stock.field_inventory_name")}`}</Form.Label>
                                <Form.Control style={{height: '45px'}} value={searchQueryInventory} type="text"
                                onChange={(e) => {
                                    setSearchQueryInventory(e.target.value);
                                }} />
                            </Form.Group>
                            
                            <div className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <Button style={{ width: 100 }} className="save-button" type="submit" disabled={buttonSearch}>{`${t("inventory_stock.button_find_inventory")}`}</Button>
                                <Button style={{ width: 100 }} className="cancel cancel-button" variant="danger" onClick={() => {
                                    handleResets();
                                    setFindInventoryModal(false);
                                }}>{t("sparepart_catalogue.cancel_button")}</Button>
                            </div>
                        </Form>
                        
                        <div className="master-table-inner-container">
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 3 }} className="table-header-content">{t("inventory_stock.table_name9")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("inventory_stock.table_name10")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("inventory_stock.table_name11")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("inventory_stock.table_name12")}</div>
                                </div>
                                {
                                    listInventoryFiltered.map((item, index) => {
                                        return(
                                    <div key={index} className="table-body">
                                        <div style={{ flex: 3 }} className="table-body-content"><p>{item.inventory_master_name}</p></div>
                                        <div style={{ flex: 1 }} className="table-body-content"><p>{item.model}</p></div>
                                        <div style={{ flex: 1 }} className="table-body-content"><p>{item.brand}</p></div>
                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"  onClick={() => {
                                                        setFindInventoryModal(false);
                                                        handleResets();
                                                        /* setSelectedInventoryItem({
                                                            ...selectedInventoryItem,
                                                            id: data.id,
                                                            inventory_master_name: data.inventory_master_name
                                                        }) */
                                                        console.log("SELECT INVENTORY", item);
                                                        setInventoryMasterIdFromFind(item.id)
                                                        // loadInventoryStock();
                                                        findInventoryMasterById(item.id);
                                                        // setSelectedInventoryMaster(item);
                                                    }}><FilePlusFill />
                                                    </Button>
                                                </div>
                                            </div>
                                    </div>
                                    )
                                    })
                                }
                            </div>
                        </div>
                        
                    </div>

                </Modal>
        </>
    )
}   