import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import config from "../../../../Config/Config";
import { useCookies } from "react-cookie";
import {
    getVesselInventory, isTokenValid, getCountVesselInventory, deleteVesselInventory, getUserAccessById,
    getVesselInventoryById, insertUpdateVesselInventory, findVesselInventoryCategory
} from "../../../../Helpers/ApplicationHelpers";

import { getInventoryUnitType } from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash, BoxArrowUpRight } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import ContainerBox from "../../../../Components/ContainerBox";
import Select from 'react-select';
import { omit } from 'lodash';
import { Trans, useTranslation } from "react-i18next";


export default function VesselInventoryPage() {
    const [listInventoryMaster, setListInventoryMaster] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const location = useLocation();
    const [listInventoryCategory, setListInventoryCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [vesselId, setVesselId] = useState("");
    const [inventoryMasterId, setInventoryMasterId] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [deletedButton, setDeletedButton] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [removeId, setRemoveId] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [listUnitType, setListUnitType] = useState([])
    const [searchQuery, setSearchQuery] = useState("");
    const [inventoryMasterDetailModal, setInventoryMasterDetailModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [itemPerPageSelection, setItemPerPageSelection] = useState([2, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_vessel_inventory_master.order_name1")}`,
        value: "vesselInventory.vessel_inventory_name"
    });
    const orderByList = [{
        label: `${t("index_vessel_inventory_master.order_name1")}`,
        value: "vesselInventory.vessel_inventory_name"
    }];
    const [descending, setDescending] = useState(false);

    const [vesselInventoryMaster, setVesselInventoryMaster] = useState({
        id: 0,
        vessel_inventory_name: "",
        vessel_inventory_brand: "",
        vessel_inventory_model: "",
        vessel_inventory_description: "",
        quantity: null,
        vessel_inventory_category_id: null,
        vessel_id: location.state.vesselId,
        vessel_inventory_unit_id: null,
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadAccess();
                setVesselId(location.state.vesselId);
                loadInventoryCategory();
                loadUnitType();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        // if (isSearched !== 0)
        if (vesselId !== 0 && vesselId !== "")
            loadVesselInventoryMaster();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadVesselInventoryMaster();
    }, [itemPerPage]);

    useEffect(() => {
        if (vesselId !== 0 && vesselId !== "") {
            loadVesselInventoryMaster();
        }
    }, [vesselId])

    useEffect(() => {
        if (isSearched !== 0)
            loadVesselInventoryMaster();
    }, [orderBy]);

    useEffect(() => {
        if (inventoryMasterId !== "")
            initVesselInventoryMaster()
    }, [inventoryMasterId])

    useEffect(() => {
        if (resetSearch !== 0) {
            loadVesselInventoryMaster()
        }
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0)
            loadVesselInventoryMaster()
    }, [descending]);

    useEffect(() => {
        if (removeId !== "")
            removeVesselInventoryMaster();
    }, [removeId]);

    useEffect(() => {
        if (vesselInventoryMaster.vessel_inventory_category_id) {
            let inventoryCategory = listInventoryCategory.find(p => p.id === vesselInventoryMaster.vessel_inventory_category_id);
            if (inventoryCategory) {
                setSelectedCategory(inventoryCategory);
            }

        }

        validationForm();
    }, [vesselInventoryMaster])

    useEffect(() => {
        if (selectedCategory.category_name) {
            setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_category_id: selectedCategory.id });
        } else {
            setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_category_id: null });
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (!inventoryMasterDetailModal)
            setInventoryMasterId("");
    }, [inventoryMasterDetailModal])



    const loadVesselInventoryMaster = async () => {
        try {
            let countryCount = await getCountVesselInventory(cookies.token, searchQuery, false, vesselId);
            let totalPage = countryCount / itemPerPage;
            setTotalPage(totalPage);

            let listInventoryMaster = await getVesselInventory(cookies.token, vesselId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListInventoryMaster(listInventoryMaster)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception)
        }
    }

    const clearVesselInventoryModal = async () => {
        let inventoryTmp = {};
        inventoryTmp.id = 0;
        inventoryTmp.vessel_inventory_name = "";
        inventoryTmp.vessel_inventory_brand = "";
        inventoryTmp.vessel_inventory_model = "";
        inventoryTmp.vessel_inventory_description = "";
        inventoryTmp.quantity = null;
        inventoryTmp.vessel_inventory_unit_id = null;
        inventoryTmp.vessel_inventory_category_id = null;
        inventoryTmp.vessel_id = location.state.vesselId;
        setVesselInventoryMaster(inventoryTmp);
        setSelectedCategory({})
    }


    const removeVesselInventoryMaster = async () => {
        try {
            let response = await deleteVesselInventory(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_vessel_inventory_master.delete_success_alert")}`);
                loadVesselInventoryMaster();
            }
            setRemoveId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
           setOrderBy({
            label: `${t("index_vessel_inventory_master.order_name1")}`,
            value: "vesselInventory.vessel_inventory_name"
           })
        } catch (exception) {
            console.log(exception);
        }
    }

    const initVesselInventoryMaster = async () => {
        try {
            let response = await getVesselInventoryById(cookies.token, inventoryMasterId);
            if (response) {
                setVesselInventoryMaster({
                    id: response.id,
                    vessel_inventory_name: response.vessel_inventory_name,
                    vessel_inventory_brand: response.vessel_inventory_brand,
                    vessel_inventory_model: response.vessel_inventory_model,
                    quantity: response.quantity,
                    vessel_inventory_description: response.vessel_inventory_description,
                    vessel_inventory_category_id: response.vessel_inventory_category_id,
                    vessel_inventory_unit_id: response.vessel_inventory_unit_id,
                    vessel_id: response.vessel_id

                });
            }
            setInventoryMasterDetailModal(true);

        } catch (exception) {
            console.log(exception);
        }
    }


    const loadInventoryCategory = async () => {
        try {
            let response = await findVesselInventoryCategory(cookies.token);
            setListInventoryCategory(response);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const saveData = async () => {
        let response = await insertUpdateVesselInventory(cookies.token, vesselInventoryMaster);
        if (response === 0) {
            alert(`${t("index_vessel_inventory_master.alert_success_save_data")}`);
            setInventoryMasterDetailModal(false);
            clearVesselInventoryModal();
            loadVesselInventoryMaster();
        }
        else {
            alert(`${t("index_vessel_inventory_master.alert_failed_save_data")}`)
        }
    }

    const validationForm = async () => {
        try {

            if (vesselInventoryMaster.vessel_inventory_category_id === "" || vesselInventoryMaster.vessel_inventory_category_id === undefined || vesselInventoryMaster.vessel_inventory_category_id === null) {
                setErrors({
                    ...errors,
                    message: `${t("index_vessel_inventory_master.error_empty_field")}`
                },
                    setDisabledButton(true));
            } else {
                let errorMsg = omit(errors, "message");
                setErrors(errorMsg);
                setDisabledButton(false);
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    const loadUnitType = async () => {
        try {
            let response = await getInventoryUnitType(cookies.token);
            setListUnitType(response);
        } catch (exception) {
            console.log(exception)
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_vessel_inventory_master.header_title")} ${location.state.vesselName}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                clearVesselInventoryModal();
                                setInventoryMasterDetailModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_vessel_inventory_master.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_vessel_inventory_master.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadVesselInventoryMaster();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadVesselInventoryMaster();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setResetSearch(1);
                                            // await loadVesselInventoryMaster();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel_inventory_master.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel_inventory_master.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel_inventory_master.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel_inventory_master.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_vessel_inventory_master.table_name5")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_vessel_inventory_master.table_name6")}</div>
                                </div>
                                {
                                    listInventoryMaster.map((inventory, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{inventory.category_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{inventory.vessel_inventory_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{inventory.vessel_inventory_brand}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{inventory.quantity}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setInventoryMasterId(inventory.id)
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            {/* <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Link to={`/Vessel/InventoryStock?id=${inventory.id}`}  target="_blank">
                                                    <Button variant="primary" onClick={() => {
                                                            // navigate("/Vessel/InventoryStock", { state: { vesselInventoryMasterId: inventory.id } });
                                                        }}><BoxArrowUpRight />
                                                        </Button>
                                                    </Link>
                                                       
                                                </div></div> */}
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("index_vessel_inventory_master.delete_confirmation")} ${inventory.vessel_inventory_name}`)) {
                                                            setRemoveId(inventory.id);
                                                            setLoading(true);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>

            <Modal show={inventoryMasterDetailModal}
                // dialogClassName="modal-size"
                size={"lg"}
                onHide={() => {
                    setInventoryMasterDetailModal(false);
                    clearVesselInventoryModal();
                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>{t("index_vessel_inventory_master.modal_add_inventory")} </h3>
                    </div>

                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        saveData();

                    }} style={{ padding: 10 }}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_vessel_inventory_master.field_inventory_type")}</Form.Label>
                            <Select placeholder={""}
                                getOptionLabel={(item) => {
                                    return item.category_name;
                                }} clearValue={true}
                                getOptionValue={(item) => {
                                    return item.id;
                                }}
                                options={listInventoryCategory} value={selectedCategory} onChange={(e) => {
                                    if (e === null) {
                                        setSelectedCategory({})
                                    } else {
                                        setSelectedCategory(e);
                                    }
                                }}
                                isClearable
                                isDisabled={inventoryMasterId !== ""}
                            />
                            {
                                errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                            }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_vessel_inventory_master.field_inventory_name")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_name: e.target.value })
                            }} type="text" value={vesselInventoryMaster.vessel_inventory_name} required placeholder="" ></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_vessel_inventory_master.field_merk")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_brand: e.target.value })
                            }} type="text" value={vesselInventoryMaster.vessel_inventory_brand} required placeholder=""></Form.Control>
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>Model</Form.Label>
                            <Form.Control onChange={(e) => {
                                setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_model: e.target.value, vessel_inventory_name: vesselInventoryMaster.vessel_inventory_brand + ' ' + e.target.value })
                            }} type="text" value={vesselInventoryMaster.vessel_inventory_model} required placeholder=""></Form.Control>
                        </Form.Group> */}
                       
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            flexWrap: "nowrap"
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 2,
                                paddingRight:10
                            }}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("index_vessel_inventory_master.field_stock")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        if (new RegExp(/^\d*\.?\d*$/).test(e.target.value)) {
                                            setVesselInventoryMaster({ ...vesselInventoryMaster, quantity: e.target.value })
                                        }
                                    }} type="text" value={vesselInventoryMaster.quantity} required placeholder="" ></Form.Control>
                                </Form.Group>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 10,
                                width: "100%",
                                flexDirection:"column"
                                
                            }}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("index_vessel_inventory_master.field_unit")} </Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.unit_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listUnitType} value={listUnitType.filter(function (option) {
                                            return option.id === vesselInventoryMaster.vessel_inventory_unit_id;
                                        })} onChange={(e) => {
                                            if (e === null) {
                                                setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_unit_id: null })
                                            } else {
                                                setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_unit_id: e.id })
                                            }
                                        }}
                                        isClearable

                                    />
                                </Form.Group>
                            </div>

                        </div>



                        <Form.Group className="mb-3">
                            <Form.Label>{t("index_vessel_inventory_master.field_description")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_description: e.target.value })
                            }} as="textarea" rows={3} value={vesselInventoryMaster.vessel_inventory_description} placeholder="" ></Form.Control>

                        </Form.Group>

                        {
                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                <></>
                                :
                                <div className=""
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                    <Button style={{width:100}} className="save-button" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_vessel_inventory_master.save_button")}</Button>
                                    <Button style={{width:100}} className="cancel cancel-button" variant="danger" onClick={() => {
                                        setInventoryMasterDetailModal(false);
                                        clearVesselInventoryModal();
                                    }}>{t("index_vessel_inventory_master.cancel_button")}</Button>


                                </div>

                        }

                    </Form>



                </div>

            </Modal>
        </>
    );

}