import React, { useState, useEffect } from "react";
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { isTokenValid, getContractById, insertUpdateContract, getContractStatus, getCustomer, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import FormSelect from "../../../../Components/FormSelect";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import moment from 'moment';
import Moment from "moment";
import DatePicker from "../../../../Components/DatePicker";
import { ArrowLeft, FileCheckFill, ListTask, ListColumnsReverse, ListCheck, InfoCircleFill } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function ContractDetailPage() {
    const { t, i18n } = useTranslation();

    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [listCustomer, setListCustomer] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [listStatus, setListStatus] = useState([]);
    const [disabledForm, setDisabledForm] = useState(false);
    const [contract, setContract] = useState({
        id: 0,
        contract_name: "",
        contract_date: null,
        contract_external_number: null,
        customer_id: null,
        description: "",
        start_date: null,
        end_date: null,
        contract_reference_number: "",
        status_id: null,
        ext_customer_id: cookies.extCustomerId
    });
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [errors, setErrors] = useState([]);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadStatus();
                loadCustomer();
                loadAccess();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
        if (location.state.contractId === 0) {
            setLoading(false);
        } else {
            initContract();
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [contract]);


    const initContract = async () => {
        try {
            let response = await getContractById(cookies.token, location.state.contractId);
            if (response) {
                setContract({
                    ...contract,
                    id: response.id,
                    contract_name: response.contract_name,
                    contract_date: response.contract_date,
                    contract_external_number: response.contract_external_number,
                    customer_id: response.customer_id,
                    description: response.description,
                    start_date: response.start_date,
                    end_date: response.end_date,
                    contract_reference_number: response.contract_reference_number,
                    status_id: response.status_id
                })

                if (response.contract_status_code === "CLOSED") {
                    setDisabledForm(true)
                } else {
                    setDisabledForm(false)
                }
            }
            setLoading(false);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }


    const loadCustomer = async () => {
        try {
            let response = await getCustomer(cookies.token, cookies.extCustomerId);
            let newList = [];
            for (let i = 0; i < response.length; i++) {
                var obj = {
                    value: response[i].id,
                    label: response[i].customer_name,
                    id: response[i].id

                };
                newList.push(obj);
            }
            setListCustomer(newList);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getContractStatus(cookies.token, cookies.languageId);
            setListStatus(response);
            if (location.state.contractId === 0) {
                let status = response.find(p => p.contract_status_name === 'Open')
                setContract({ ...contract, status_id: status.id })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const addContract = async () => {
        try {
            let contractTmp = contract;
            contractTmp.ext_customer_id = contractTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : contract.ext_customer_id
            let response = await insertUpdateContract(cookies.token, contract)
            if (response.error_code === 0) {
                alert(`${t("detail_contract.alert_success_save_data")}`)
                if (location.state.contractId === 0) {
                    navigate("/Contract");
                } else {
                    initContract();
                }
                setDisabledButton(false);
            } else {
                alert(`${t("detail_contract.alert_failed_save_data")}`);
            }

        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const validationForm = async () => {

        return new Promise(async (resolve, reject) => {
            try {
                if (contract.contract_name === undefined || contract.contract_name === "") {
                    setErrors({
                        ...errors,
                        contractName: `${t("detail_contract.error_empty_field")}`
                    },
                        setDisabledButton(true));
                } else if (contract.customer_id === undefined || contract.customer_id === null) {
                    setErrors({
                        ...errors,
                        customerName: `${t("detail_contract.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "contractName", "customerName");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_contract.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Contract"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            {
                                location.state.contractId !== 0 &&
                                <div>
                                    <Link to={`/Contract/Document?id=${location.state.contractId}`} target="_blank" >
                                        <Button disabled={location.state.contractId === 0 ? true : false} variant="secondary" style={{
                                            marginLeft: 5, marginRight: 5
                                        }} onClick={() => {
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                alignSelf: "center",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    flex: 1,
                                                }}><FileCheckFill size={32} /></div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 8,
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    paddingLeft: 10,
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                }}>{t("detail_contract.document_button")}</div>
                                            </div>
                                        </Button>
                                    </Link>
                                    <Button disabled={location.state.contractId === 0 ? true : false} variant="secondary" style={{
                                        marginLeft: 5, marginRight: 5
                                    }} onClick={() => {
                                        navigate("/Contract/ListProject", { state: { contractId: contract.id, contractName: contract.contract_name } });
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><ListCheck size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>{t("detail_contract.project_button")}</div>
                                        </div>
                                    </Button>
                                    <Button disabled={location.state.contractId === 0 ? true : false} variant="secondary" style={{
                                        marginLeft: 5, marginRight: 5
                                    }} onClick={() => {
                                        navigate("/Contract/ContractTask", { state: { contractId: contract.id, contractTaskId: 0 } })
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><InfoCircleFill size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>{t("detail_contract.job_button")}</div>
                                        </div>
                                    </Button>
                                </div>

                            }


                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_contract.header_contract")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5
                                                }}>
                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        <TextBox value={contract.contract_name} placeholder=""
                                                            caption={`${t("detail_contract.field_contract_name")}`} setValueFunction={(e) => {
                                                                setContract({ ...contract, contract_name: e.target.value });
                                                            }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />
                                                        {
                                                            errors.contractName && <p style={{ color: "red" }}>{errors.contractName}</p>
                                                        }
                                                    </div>

                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5
                                                }}>
                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        <FormSelect caption={`${t("detail_contract.field_customer_name")}`} autoFocusValue={contract.customer_id === undefined} placeholder=""
                                                            options={listCustomer} value={listCustomer.filter(function (option) {
                                                                return option.id === contract.customer_id;
                                                            })} setValueFunction={(e) => {
                                                                setContract({ ...contract, customer_id: e.id });
                                                            }} disabledForm={location.state.contractId === 0 ? false : true}
                                                        />
                                                        {
                                                            errors.customerName && <p style={{ color: "red" }}>{errors.customerName}</p>
                                                        }
                                                    </div>
                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        {/* <TextBox value={contract.contract_date ? moment(contract.contract_date).format("yyyy-MM-DD") : null} controlType="date" placeholder="Tanggal  Kontrak"
                                                            caption="Tanggal  Kontrak" setValueFunction={(e) => {
                                                                setContract({ ...contract, contract_date: e.target.value });
                                                            }} disabledForm={disabledForm} /> */}

                                                        <DatePicker disabled={disabledCustomerForm === true ? true : disabledForm} caption={`${t("detail_contract.field_contract_date")}`} placeholder="" onDateChange={(date) => {
                                                            setContract({ ...contract, contract_date: new Date(date) });
                                                            // setScheduleToBeViewed({ ...scheduleToBeViewed, last_survey_date: new Date(date) });
                                                        }} value={Moment(contract.contract_date).isValid() ? Moment(contract.contract_date).toDate() : ""} />
                                                    </div>




                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5
                                                }}>
                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        {/* <TextBox value={contract.start_date ? moment(contract.start_date).format("yyyy-MM-DD") : null} controlType="date" placeholder="Tanggal Mulai Kontrak"
                                                            caption="Tanggal Mulai Kontrak" setValueFunction={(e) => {
                                                                setContract({ ...contract, start_date: e.target.value });
                                                            }} disabledForm={disabledForm} /> */}
                                                        <DatePicker disabled={disabledCustomerForm === true ? true : disabledForm} caption={`${t("detail_contract.field_contract_start_date")}`} placeholder="" onDateChange={(date) => {
                                                            setContract({ ...contract, start_date: new Date(date) });
                                                            // setScheduleToBeViewed({ ...scheduleToBeViewed, last_survey_date: new Date(date) });
                                                        }} value={Moment(contract.start_date).isValid() ? Moment(contract.start_date).toDate() : ""} />
                                                    </div>
                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        {/* <TextBox value={contract.end_date ? moment(contract.end_date).format("yyyy-MM-DD") : null} controlType="date" placeholder="Tanggal Berakhir Kontrak"
                                                            caption="Tanggal Berakhir Kontrak" setValueFunction={(e) => {
                                                                setContract({ ...contract, end_date: e.target.value });
                                                            }} disabledForm={location.state.contractId == 0 || disabledForm === true ? true : false} /> */}
                                                        <DatePicker disabled={disabledCustomerForm === true ? true : disabledForm} caption={`${t("detail_contract.field_contract_end_date")}`} placeholder="" onDateChange={(date) => {
                                                            setContract({ ...contract, end_date: new Date(date) });
                                                            // setScheduleToBeViewed({ ...scheduleToBeViewed, last_survey_date: new Date(date) });
                                                        }} value={Moment(contract.end_date).isValid() ? Moment(contract.end_date).toDate() : ""} />
                                                    </div>



                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5
                                                }}>
                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        <TextBox value={contract.contract_reference_number} placeholder=""
                                                            caption={`${t("detail_contract.field_contract_number1")}`} setValueFunction={(e) => {
                                                                setContract({ ...contract, contract_reference_number: e.target.value });
                                                            }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />
                                                    </div>

                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        <TextBox value={contract.contract_external_number} placeholder=""
                                                            caption={`${t("detail_contract.field_contract_number2")}`} setValueFunction={(e) => {
                                                                setContract({ ...contract, contract_external_number: e.target.value });
                                                            }} disabledForm={disabledCustomerForm === true ? true : disabledForm} />
                                                    </div>


                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5
                                                }}>
                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        <Form.Group>
                                                            <Form.Label>{t("detail_contract.field_contract_status")}</Form.Label>
                                                            <Form.Select value={contract.status_id} onChange={(e) => {
                                                                if (e.target.value === "") {
                                                                    setContract({ ...contract, status_id: null });
                                                                } else {
                                                                    setContract({ ...contract, status_id: e.target.value });
                                                                }

                                                            }} disabled={disabledCustomerForm === true ? true : disabledForm}>
                                                                <option value=""></option>
                                                                {
                                                                    listStatus.map((status, index) => {
                                                                        return (
                                                                            <option key={index} value={status.id}>{status.contract_status_code}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>


                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5
                                                }}>
                                                    <div style={{ width: "100%", padding: 5 }}>
                                                        <TextBox value={contract.description} placeholder=""
                                                            caption={`${t("detail_contract.field_description")}`} setValueFunction={(e) => {
                                                                setContract({ ...contract, description: e.target.value });
                                                            }} asType="textarea" disabledForm={disabledCustomerForm === true ? true : disabledForm} />
                                                    </div>


                                                </div>
                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>
                            {
                                disabledCustomerForm !== true &&
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                    padding: 10,
                                    width: "100%",
                                    justifyContent: "center",
                                    fontWeight: "bold"
                                }}>

                                    <div style={{
                                        display: "flex",
                                        paddingRight: 5,
                                    }}>

                                        <Button onClick={() => {
                                            setDisabledButton(true);
                                            addContract()
                                        }} style={{width:100}} className="save-button" disabled={disabledForm === false && updatedButton === true ? disabledButton : true} type="submit">{t("detail_contract.save_button")}</Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>
                                        <Button onClick={() => {
                                            navigate('/Contract')
                                        }} style={{width:100}} className="cancel-button" variant="danger">{t("detail_contract.cancel_button")}</Button>
                                    </div>
                                </div>
                            }


                          
                        </div>

                    }>

                </ContainerBox>

            </Container>
        </>
    )
}