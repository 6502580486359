import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselInventoryRequisition, getVesselInventoryRequisitionCount, deleteVesselInventoryRequisition,
        getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function VesselInventoryRequisitionPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [listVesselInventoryRequisition, setListVesselInventoryRequisition] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [page, setPage] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_vessel_inventory_requisition.order_name1")}`,
        value: "staff.staff_name"
    });
    const orderByList = [{
        label:`${t("index_vessel_inventory_requisition.order_name1")}`,
        value: "staff.staff_name"
    },{
        label: `${t("index_vessel_inventory_requisition.order_name2")}`,
        value: "vessel.vessel_name"
    }
    ,{
        label: `${t("index_vessel_inventory_requisition.order_name3")}`,
        value: "vesselInventoryRequisition.request_date"
    },{
        label:`${t("index_vessel_inventory_requisition.order_name4")}`,
        value: "status.status_name"
    }
    ]

    const [descending, setDescending] = useState(false);
    const [removeId, setRemoveId] = useState("");


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadVesselInventoryRequisition();
                // setLoading(false);
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadVesselInventoryRequisition();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadVesselInventoryRequisition();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadVesselInventoryRequisition();
    }, [orderBy]);

    useEffect(() => {
        if(removeId !== "")
        deleteData();
    }, [removeId]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadVesselInventoryRequisition();
    }, [resetSearch]);

    useEffect(() => {
        if(isSearched !== 0)
        loadVesselInventoryRequisition();
    }, [descending]);



    const loadVesselInventoryRequisition = async () => {
        try {
            let count = await getVesselInventoryRequisitionCount(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
            
            let response = await getVesselInventoryRequisition(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListVesselInventoryRequisition(response);
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0)
        } catch (exception) {
            console.log(exception);
            setLoading(false);
        }
    }

    const deleteData = async () => {
        try {
            let response = await deleteVesselInventoryRequisition(cookies.token, removeId);
            if(response === 0){
                loadVesselInventoryRequisition();
                alert(`${t("index_vessel_inventory_requisition.delete_success_alert")}`)
            }else{
                alert(`${t("index_vessel_inventory_requisition.delete_failed_alert")}`)
            }
            setRemoveId("");
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
           setOrderBy({
            label: `${t("index_vessel_inventory_requisition.order_name1")}`,
            value: "staff.staff_name"
           })
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_vessel_inventory_requisition.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/VesselInventoryRequisition/Detail", { state: { vesselInventoryRequisitionId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_vessel_inventory_requisition.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_vessel_inventory_requisition.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                setPage(0);
                                                await loadVesselInventoryRequisition();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadVesselInventoryRequisition();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadVesselInventoryRequisition();
                                            setResetSearch(1)
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel_inventory_requisition.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_vessel_inventory_requisition.table_name2")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel_inventory_requisition.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_vessel_inventory_requisition.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_vessel_inventory_requisition.table_name5")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_vessel_inventory_requisition.table_name6")}</div>
                                </div>
                                {
                                    listVesselInventoryRequisition.map((inventoryRequisition, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 , justifyContent:"center"}} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 , justifyContent:"center" }} className="table-body-content"><p>{inventoryRequisition.vessel_name}</p></div>
                                            <div style={{ flex: 3 , justifyContent:"center"}} className="table-body-content"><p>{inventoryRequisition.staff_name}</p></div>
                                            <div style={{ flex: 2 , justifyContent:"center"}} className="table-body-content"><p>{moment(inventoryRequisition.request_date).format("DD-MMM-yyyy")}</p></div>
                                            <div style={{ flex: 2 , justifyContent:"center"}} className="table-body-content"><p>{inventoryRequisition.status_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button onClick={() => {
                                                        navigate("/VesselInventoryRequisition/Detail", { state: { vesselInventoryRequisitionId: inventoryRequisition.id,  } })
                                                    }}> <PencilFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"? true : !deletedButton} className="cancel" onClick={() => {
                                                        if (window.confirm(`${t("index_vessel_inventory_requisition.delete_confirmation")}?`)) {
                                                            setRemoveId(inventoryRequisition.id);
                                                        }
                                                    }} variant="danger"><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>


        </>
    );

}