import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal, Nav } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, convertBase64, getTroubleshootingPhoto, insertUpdateTroubleshootingFoto, deleteTroubleshootingFoto, findPhotoEngineInspectionDetail,
    getUserAccessById, convertBase64Image, insertUpdatePhotoEngineInspectionDetail, deletePhotoEngineInspectionDetail, findAllProjectDocumentType
} from "../../../../../Helpers/ApplicationHelpers";
import "../../../../../App.css";
import Navbar from "../../../../../Components/NavBar";
import Paginations from "../../../../../Components/Pagination";
import Modals from "../../../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise, XSquareFill
} from "react-bootstrap-icons";
import ContainerBox from "../../../../../Components/ContainerBox";
import Loading from "../../../../../Components/Loading";
import TextBox from "../../../../../Components/Textbox";
import FormSelect from "../../../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import { Trans, useTranslation } from "react-i18next";
import moment from 'moment';
import { triggerBase64Download } from "../../../../../Helpers/Base64Downloader";



export default function EngineHealthInspectionDetailDocumentPage() {
    const { t, i18n } = useTranslation();
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [troubleshootingPhotoId, setTroubleshootingPhotoId] = useState("");
    const [listPhoto, setListPhoto] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [descending, setDescending] = useState(false);
    const [removeId, setRemoveId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(8);
    const [totalPage, setTotalPage] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [fotoModal, setFotoModal] = useState("");
    const [listOfDocumentType, setListOfDocumentType] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState({});
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [documentToBeViewed, setDocumentToBeViewed] = useState();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoomFactor, setZoomFactor] = useState(0.5);
    const [downloadDocumentData, setDownloadDocumentData] = useState({});
    const [documentTypeId, setDocumentTypeId] = useState('53a07d2b-03bc-4260-839c-4010f34825b5');
    const [insertFoto, setInsertFoto] = useState({
        list_photo: []
    });
    // const [insertFoto, setInsertFoto] = useState({
    //     project_document_type_id: "",
    //     base64_data: "",
    //     document_label: "",
    //     created_date: "",
    //     created_by: "",
    //     updated_date: "",
    //     updated_by: "",
    //     is_active: true,
    //     engine_health_inspection_id: ""
    // });
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([8, 12, 16, 20]);
    const [orderBy, setOrderBy] = useState({
        label: "Tanggal Diambil",
        value: "updated_date"
    });
    const orderBySelection = [{
        label: "Tanggal Diambil",
        value: "updated_date"
    }];

    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);

    useEffect(() => {
        loadTroubleshootingPhoto();
    }, [page]);

    useEffect(() => {
        loadTroubleshootingPhoto();
    }, [descending]);

    useEffect(() => {
        if(isSearched !== 0){
            loadTroubleshootingPhoto();
        }
        
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0){
            loadTroubleshootingPhoto();
        }
        
    }, [orderBy]);

    useEffect(() => {
        if (removeId !== "") {
            removeData();
            setLoading(true);
        }
    }, [removeId])

    // useEffect(() => {
    //     if(listOfDocumentType){
    //         let docType = listOfDocumentType.find(p => p.project_document_type === "doc")
    //         setSelectedDocumentType(docType);
    //     }
    // },[listOfDocumentType])

    // useEffect(() => {
    //     if (selectedDocumentType){
    //         loadTroubleshootingPhoto();
    //     }
    // }, [selectedDocumentType])

    useEffect(() => {
        if (documentToBeViewed){
            if (documentToBeViewed.id){
                setShowDocumentDetailModal(true);
            }
        }
    }, [documentToBeViewed])

    useEffect(() => {
        if (downloadDocumentData){
            if (downloadDocumentData.id){
                downloadData(downloadDocumentData.base64_data, downloadDocumentData.document_label);
            }
        }
    }, [downloadDocumentData])


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setTroubleshootingPhotoId(searchParams.get("id"));
                loadAccess();
                if(cookies.extCustomerId !== "null" && cookies.logingAs === "Customer"){
                    setDisabledCustomerForm(true);
                }
                // loadDocumentData();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadTroubleshootingPhoto();
    }, [troubleshootingPhotoId])


    const loadTroubleshootingPhoto = async () => {
        try {
            let count = await findPhotoEngineInspectionDetail(cookies.token, searchParams.get("id"), documentTypeId ,page, itemPerPage, orderBy.value, descending)
            let totalPage = count.length / itemPerPage;
            setTotalPage(totalPage);

            let response = await findPhotoEngineInspectionDetail(cookies.token, searchParams.get("id"), documentTypeId ,page, itemPerPage, orderBy.value, descending);
          
            setListPhoto(response);
            setLoading(false);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }
    }

    const downloadData = async (base64, name) => {
        try {
            // let response = await getVesselDocumentById(cookies.token, downloadDocumentId);
            triggerBase64Download(base64, name);
            setLoading(false);
            setDownloadDocumentData({});
        } catch (exception) {
            console.log(exception);
        }
    }


    const saveData = async () => {
        try {
            console.log('insertFoto', insertFoto)
            let response = await insertUpdatePhotoEngineInspectionDetail(cookies.token, insertFoto);
            if (response.error_code === 0) {
                alert('Berhasil Menyimpan Data')
                setFotoModal(false);
                resetListPhoto();
                loadTroubleshootingPhoto()
            } else {
                alert('Gagal Menyimpan Data');
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
            alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const removeData = async () => {
        try {
            // let text;
            let response = await deletePhotoEngineInspectionDetail(cookies.token, removeId);
            if (response === 0) {
                alert('Berhasil Menghapus Data');
            } else {
                alert('Gagal menghapus Data');
            }
            loadTroubleshootingPhoto();
            setRemoveId("");

        } catch (exception) {
            alert("Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin");
            console.log(exception);
        }
    }



    const resetListPhoto = async () => {
        let newList = {
            project_document_type_id: "",
            base64_data: "",
            document_label: "",
            created_date: "",
            created_by: "",
            updated_date: "",
            updated_by: "",
            is_active: true,
            engine_health_inspection_id: ""
        };
        setInsertFoto({ ...insertFoto, list_photo: [] });
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDocumentType = async()=>{
        try {
            let response = await findAllProjectDocumentType(cookies.token, cookies.languageId);
            if(response){
                setListOfDocumentType(response);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={searchParams.get("inspectionTask") + ' Documents'}
                    useActionContainer={!disabledCustomerForm}
                    actionContainerChild={
                        <div>

                            <Button disabled={false} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                setFotoModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Document</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content"><p>{'#'}</p></div>
                                    <div style={{ flex: 1 }} className="table-header-content"><p>{'Tanggal'}</p></div>
                                    <div style={{ flex: 2 }} className="table-header-content"><p>{'Dokumen'}</p></div>
                                    <div style={{ flex: 1 }} className="table-header-content"><p>{'Lihat'}</p></div>
                                    <div style={{ flex: 1 }} className="table-header-content"><p>{'Download'}</p></div>
                                    <div style={{ flex: 1 }} className="table-header-content"><p>{'Hapus'}</p></div>
                                </div>
                                {
                                        listPhoto.map((photo, index) => {
                                            return (
                                                <div key={index} className="table-body">
                                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                    <div style={{ flex: 1 }} className="table-body-content"><p>{moment(photo.updated_date).local().format('YYYY-MM-DD')}</p></div>
                                                    <div style={{ flex: 2 }} className="table-body-content"><p>{photo.document_label}</p></div>
                                                    <div style={{ flex: 1 }} className="table-body-content">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                            <Button variant="primary" onClick={() => {
                                                                setDocumentToBeViewed(photo);
                                                            }}><EyeFill />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }} className="table-body-content">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                            <Button variant="primary" onClick={() => {
                                                                setDownloadDocumentData(photo);
                                                            }}><Download />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }} className="table-body-content">
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                            <Button variant="danger" onClick={() => {
                                                                setRemoveId(photo.id);
                                                            }}><Trash />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                            </div>
                            <div style={{paddingBottom:10}}></div>

                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderBySelection}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />

                            
                            <Loading
                                loading={loading}
                            />
                        </div>} />
                <Modal size="xl" show={fotoModal} onHide={
                    () => {
                        setFotoModal(false);
                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={"Dokumen Hasil Kerja"} useActionContainer={true}
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>


                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        saveData();
                                        setDisabledButton(true);
                                        setLoading(true);

                                    }}>
                    
                                        {
                                            insertFoto.list_photo && insertFoto.list_photo.map((foto, index) => {
                                                return (
                                                    <div>
                                                        <div key={index} style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            width: "100%",
                                                            flexDirection: "column",
                                                            flexWrap: "nowrap",
                                                            borderWidth: 2,
                                                            borderRadius: 5,
                                                            borderStyle: "solid",
                                                            borderColor: "rgba(3, 30, 103, 1)",
                                                            padding: 10
                                                        }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: "column",
                                                                width: "100%",

                                                            }}>
                                                                {/* <img src={foto.base64_data}
                                                                    style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} /> */}
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Foto</Form.Label>
                                                                    <Form.Control required type="file" onChange={async (e) => {
                                                                        console.log(searchParams.get("engineHealthInspectionId"));
                                                                        let base64Img = await convertBase64(e.target.files[0]);
                                                                        const base64ImgString = base64Img.toString();
                                                                        // setListPhoto({ ...listPhoto, base64_image: base64ImgString })
                                                                        let detailJobs = insertFoto.list_photo;
                                                                        detailJobs[index].base64_data = base64ImgString;
                                                                        detailJobs[index].project_document_type_id = documentTypeId;
                                                                        detailJobs[index].engine_health_inspection_detail_id = searchParams.get("engineHealthInspectionId");
                                                                        detailJobs[index].inspection_detail_id = searchParams.get("id");
                                                                        setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                    }}></Form.Control>
                                                                </Form.Group>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                flex: 1

                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    paddingRight: 5
                                                                }}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Label</Form.Label>
                                                                        <Form.Control required type="text" onChange={(e) => {
                                                                            let detailJobs = insertFoto.list_photo;
                                                                            detailJobs[index].document_label = e.target.value;
                                                                            setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                        }} value={foto.document_label}></Form.Control>
                                                                    </Form.Group>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    paddingLeft: 5

                                                                }}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Tanggal Upload</Form.Label>
                                                                        <Form.Control required type="date" onChange={(e) => {
                                                                            let detailJobs = insertFoto.list_photo;
                                                                            detailJobs[index].updated_date = e.target.value;
                                                                            setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                        }} value={foto.updated_date ? moment(foto.updated_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                                    </Form.Group>
                                                                </div>


                                                            </div>
                                                            {/* <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",

                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Keterangan</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                        let detailJobs = insertFoto.list_photo;
                                                                        detailJobs[index].description = e.target.value;
                                                                        setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                    }} value={foto.description}></Form.Control>
                                                                </Form.Group>
                                                            </div> */}
                                                            <div style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                justifyContent: "center",
                                                                flexDirection:"column",
                                                                flex: 1,
                                                                paddingLeft: 5,
                                                                paddingRight: 5,
                                                            }}>
                                                                <div style={{ paddingTop: 10 }}></div>
                                                                <Button onClick={() => {
                                                                    if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                        let list = [...insertFoto.list_photo];
                                                                        list.splice(index, 1);
                                                                        setInsertFoto({ ...insertFoto, list_photo: list })
                                                                    }

                                                                }}  variant="danger"><Trash size={20} /></Button>

                                                            </div>

                                                        </div>
                                                        <div style={{ paddingBottom: 10 }}></div>


                                                    </div>



                                                )
                                            })
                                        }
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Button variant="primary" onClick={() => {
                                                let newParameter = {
                                                    base64_data: "",
                                                    taken_date: null,
                                                    document_label: "",
                                                    troubleshooting_detail_id: searchParams.get("id")
                                                };

                                                if (!insertFoto.list_photo) {
                                                   
                                                    let list = [];
                                                    list.push(newParameter);
                                                    setInsertFoto({ ...insertFoto, list_photo: list })
                                                } else {
                                                  
                                                    let list = insertFoto.list_photo;
                                                    list.push(newParameter);
                                                    setInsertFoto({ ...insertFoto, list_photo: list });
                                                }

                                            }}>Tambah Dokumen</Button>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5,
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                paddingRight: 5,
                                            }}><Button variant="primary" disabled={disabledButton} type="submit">Simpan</Button></div>


                                            <div style={{
                                                paddingLeft: 5,
                                            }}>
                                                <Button variant="danger" onClick={() => {
                                                    setFotoModal(false);



                                                }}>Batal</Button>

                                            </div>
                                        </div>
                                    </Form>




                                </div>






                            </div>

                        }>
                    </ContainerBox>
                </Modal>

                <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                }}>
                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed && documentToBeViewed.document_label} 
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{
    
                            }} onClick={() => { 
                                setShowDocumentDetailModal(false);
                                }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_document.close_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            <div>
                                <Document
                                    file={documentToBeViewed && documentToBeViewed.base64_data}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={() => {

                                    }}
                                ><div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            overflow: "scroll",
                                        }}>
                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                            {(pageNumber + 1) < numPages &&
                                                <Page scale={zoomFactor} pageNumber={pageNumber + 1} />
                                            }

                                        </div>
                                    </div>
                                </Document>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <p>
                                    {t("vessel_document.page_text")} {pageNumber} {t("vessel_document.from_text")} {numPages}
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor <= 0.2}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 0.75);
                                            }}
                                        >

                                            <ZoomOut size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={false}
                                            onClick={() => {
                                                setZoomFactor(0.5);
                                            }}
                                        >
                                            <AspectRatioFill size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                        flexWrap: "wrap",
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor >= 10}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 1.25);
                                            }}
                                        >
                                            <ZoomIn size={28} />
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >

                                            <ChevronDoubleLeft size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            <ChevronDoubleRight size={28} />
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    } />

                </Modal>
            </Container>

        </>
    );

}