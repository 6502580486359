import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import {
    getAboutUs, deleteAboutUs, getCountAboutUs, isTokenValid, insertUpdateAboutUs, getAboutUsById,
    getUserAccessById, convertBase64Image
} from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import Navbar from "../../Components/NavBar";
import Loading from "../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../Components/Pagination";
import TextBox from "../../Components/Textbox";
import ContainerBox from "../../Components/ContainerBox";
import { Editor } from '@tinymce/tinymce-react';
import { convert } from "html-to-text";


export default function AboutUsPage() {
    const [listAbout, setListAbout] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [aboutId, setAboutId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [aboutModal, setAboutModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Deskripsi",
        value: "description"
    });
    const orderByList = [{
        label: "Deskripsi",
        value: "description"
    }];
    const [descending, setDescending] = useState(false);
    const [aboutUs, setAboutUs] = useState({
        id: "0",
        description: "",
    })
    const options = {
        wordwrap: 0
      }

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAboutUs();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadAboutUs();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadAboutUs();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadAboutUs();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadAboutUs();
    }, [resetSearch]);

    useEffect(() => {
        if (isSearched !== 0)
            loadAboutUs()
    }, [descending]);

    useEffect(() => {
        if (aboutId !== "" && aboutId !== undefined)
            initAboutUs();
    }, [aboutId]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeAboutUs();
    }, [removeId])

    useEffect(() => {
        if (!aboutModal)
            setAboutId("");
    }, [aboutModal])


    const loadAboutUs = async () => {
        try {
            let count = await getCountAboutUs(cookies.token, searchQuery, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);


            let response = await getAboutUs(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListAbout(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }

    }

    const clearAboutUs = async () => {
        let aboutTmp = {};
        aboutTmp.id = 0;
        aboutTmp.description = "";
        setAboutUs(aboutTmp);
    }

    const saveData = async () => {
        try {
            let response = await insertUpdateAboutUs(cookies.token, aboutUs);
            if (response.error_code === 0) {
                alert('Data  Berhasil Disimpan');
                setAboutModal(false);
                clearAboutUs();
                loadAboutUs();
                setDisabledButton(false);
            } else {
                alert('Gagal Menyimpan Data')
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const initAboutUs = async () => {
        try {
            let response = await getAboutUsById(cookies.token, aboutId);
            if (response) {
                setAboutUs({
                    ...aboutUs,
                    id: response.id,
                    description: response.description
                })
            }
            setAboutModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeAboutUs = async () => {
        try {
            let response = await deleteAboutUs(cookies.token, removeId);
            if (response === 0) {
                alert('Data Telah Berhasil Dihapus');
                loadAboutUs();
            } else {
                alert('Data Gagal Dihapus');
            }

            setRemoveId("");
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
          
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Tentang Kami"
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={listAbout.length === 1} variant="secondary" style={{

                            }} onClick={() => {
                                setAboutModal(true);
                                setAboutUs({ ...aboutUs, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Data</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Desckripsi)"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadAboutUs();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadAboutUs();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadAboutUs();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Deskripsi</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listAbout.map((list, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{convert(list.description, options)}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idAbout = list.id;
                                                        setAboutId(idAbout);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="danger" onClick={() => {
                                                        let idAbout = list.id;
                                                        if (window.confirm(`Apakah Anda Yakin Untuk menghapus Data  ini?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idAbout);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />




                <Modal show={aboutModal}
                    // dialogClassName="modal-size"
                    size={"xl"}
                    onHide={() => {
                        setAboutModal(false);
                        clearAboutUs();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Tentang Kami   </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>
                          

                            <Editor
                                apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                onEditorChange={(evt, editor) => {
                                    setAboutUs({ ...aboutUs, description: evt })


                                }}
                                value={aboutUs.description}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                    ],
                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist | table image fullscreen',
                                    image_title: true,
                                    automatic_uploads: true,
                                    /*
                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                      images_upload_url: 'postAcceptor.php',
                                      here we add custom filepicker only to Image dialog
                                    */
                                    file_picker_types: 'image',
                                    /* and here's our custom image picker*/
                                    file_picker_callback: (cb, value, meta) => {
                                        const input = document.createElement('input');
                                        input.setAttribute('type', 'file');
                                        input.setAttribute('accept', 'image/*');

                                        input.addEventListener('change', (e) => {
                                            const file = e.target.files[0];
                                            const reader = new FileReader();
                                            reader.addEventListener('load', () => {
                                                /*
                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                  registry. In the next release this part hopefully won't be
                                                  necessary, as we are looking to handle it internally.
                                                */

                                                // console.log(editorRef.current.getContent())
                                                const id = 'blobid' + (new Date()).getTime();
                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                //   editorRef.current.editorUpload.blobCache

                                                //Editor.activeEditor.editorUpload.blobCache;

                                                const base64 = reader.result.split(',')[1];
                                                const blobInfo = blobCache.create(id, file, base64);
                                                blobCache.add(blobInfo);

                                                /* call the callback and populate the Title field with the file name */
                                                cb(blobInfo.blobUri(), { title: file.name });
                                            });
                                            reader.readAsDataURL(file);
                                        });

                                        input.click();
                                    },
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />


                            <div style={{ paddingBottom: 10 }}></div>

                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                                <Button type="submit" >Simpan</Button>
                                <Button className="cancel" variant="danger" onClick={() => {
                                    clearAboutUs();
                                    setAboutModal(false);
                                }}>Batal</Button>
                            </div>


                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}