import "../../SMSApp.css";
import "./index.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import BarGraph from "../../Components/BarGraph/BarGraph";
import { Anchor, Button, Col, Row, Modal, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill, GearWideConnected } from "react-bootstrap-icons";
import BitLamp from "../../Components/BitLamp/BitLamp";
import {
    isTokenValid, getPLCCoordinatePumpMotor, getPlcValue, getCoordinateRunningHour, getPumpAverageValue, getPLCCoordinatePumpMotorContact,
    getPLCCoordinatePumpMotorThermalOverload, getPLCCoordinatePumpMotorWireBreak, getEquipmentDetail, getEquipmentMaintenanceScheduleByVesselEquipment,
    getCountEquipmentMaintenanceScheduleByVesselEquipment, insertUpdateMaintenanceTime
} from "../../Helpers/ApplicationHelpers";
import Loading from "../../Components/Loading";
import moment from "moment";
import ContainerBox from "../../Components/ContainerBox";
import Paginations from "../../Components/Pagination";

export default function PumpMotor() {
    // const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listPumpMotor, setListPumpMotor] = useState([]);
    const [listContact, setListContact] = useState([]);
    const [listThermalOverload, setListThermalOverload] = useState([]);
    const [listWireBreak, setListWireBreak] = useState([]);
    const [loading, setLoading] = useState(true);
    const [vesselEquipmentId, setVesselEquipmentid] = useState("");
    const [detailEquipment, setDetailEquipment] = useState({});
    const [equipmentModal, setEquipmentModal] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [totalMaintenanceTime, setTotalMaintenanceTime] = useState(0);
    const [maintenanceScheduleId, setMaintenanceScheduleId] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [isDataLoaded,setIsDataLoaded] = useState(false);
    const [isSearched, setIsSearched] = useState(0);
    const [listMaintenanceSchedule, setListMaintenanceSchedule] = useState([]);
    const [totalHour, setTotalHour] = useState(0);
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (listPumpMotor.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [listPumpMotor]);
    // useEffect(() => {
    //     if (listPumpMotor.length > 0) {
    //         const loadData = async () => {
    //             await loadBitlampValue();
    //         };

    //         // Load data immediately
    //         loadData();

    //         // Set interval to load data every 1000 ms
    //         const interval = setInterval(loadData, 1000);

    //         // Cleanup function to clear the interval
    //         return () => clearInterval(interval);
    //     }
    // }, [listPumpMotor]);


    useEffect(() => {
        if (listPumpMotor.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadBitlampValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [listPumpMotor, isDataLoaded]);


    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail();
    }, [page]);

    useEffect(() => {
        if (maintenanceScheduleId !== "") {
            updateMaintenanceTime();
        }
    }, [maintenanceScheduleId])

    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail()
    }, [vesselEquipmentId])

    const loadEquipmentDetail = async () => {
        try {
            let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
            setDetailEquipment(response);

            let count = await getCountEquipmentMaintenanceScheduleByVesselEquipment(cookies.token, cookies.vesselId, vesselEquipmentId, undefined, false);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let list = await getEquipmentMaintenanceScheduleByVesselEquipment(cookies.token, cookies.vesselId, vesselEquipmentId, page, itemPerPage, undefined, undefined, undefined, false);
            for (let index = 0; index < list.length; index++) {
                const listScheduleTmp = list[index];
                if (listScheduleTmp.maintenance_time % Number(totalHour).toFixed(0) === listScheduleTmp.maintenance_time) {
                    let total = 0
                    if (listScheduleTmp.next_maintenance_hour) {
                        total = Number(listScheduleTmp.next_maintenance_hour)
                    } else {
                        total = Number(listScheduleTmp.maintenance_time) + totalHour;
                    }
                    if ((total - totalHour) < 100) {
                        listScheduleTmp.background_color = '#ffff00'
                    } else {
                        listScheduleTmp.background_color = ''
                    }
                } else {
                    let total = 0;
                    if (listScheduleTmp.next_maintenance_hour) {
                        total = Number(listScheduleTmp.next_maintenance_hour) - totalHour;
                    } else {
                        total = Number(listScheduleTmp.maintenance_time) - totalHour;
                    }

                    if (total < 100) {
                        listScheduleTmp.background_color = '#ffff00'
                    } else {
                        listScheduleTmp.background_color = ''
                    }
                }
            }
            setListMaintenanceSchedule(list)

            setIsSearched(0);
            setEquipmentModal(true);
        } catch (exception) {
            // console.log(exception);
        }
    }

    const updateMaintenanceTime = async () => {
        try {
            let response = await insertUpdateMaintenanceTime(cookies.token, totalHour, totalMaintenanceTime, maintenanceScheduleId);
            if (response.error_code === 0) {
                alert('Data Has Been Saved');
                loadEquipmentDetail();
                setMaintenanceScheduleId("");
                setTotalMaintenanceTime(0);
            }
        } catch (exception) {

        }
    }

    const loadBitlampValue = async () => {
        try {
            setIsDataLoaded(true);
            let newListCoordinate = listPumpMotor.concat(listContact, listThermalOverload, listWireBreak);
            if (newListCoordinate.length === 0) {
                setLoading(false);
            }
            let coordinate = newListCoordinate.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = listThermalOverload.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };

                } else {
                    return { ...item, alarm: item.alarm };
                }
            });

            let listRh = await getCoordinateRunningHour(cookies.token, cookies.serialNumber);
            let newList = listContact.map(item => {
                let rh = listRh.find(p => item.plc_coordinate === p.coordinate)
                if (rh) {
                    let totalHour = (rh.running_hour / (1000 * 60 * 60)).toFixed(1);

                    const duration = moment.duration(rh.running_hour);
                    const days = Math.floor(duration.asDays());
                    const hours = duration.hours();
                    const minutes = duration.minutes();
                    let totalDays = days + ' days ' + hours + ' hours: ' + minutes + ' minutes' + ' (' + totalHour + ' Hours )'
                    return { ...item, running_hour: rh.running_hour, total_day: totalDays, total_hour: totalHour, last_data_read: rh.created_date };
                } else {
                    return { ...item, running_hour: item.running_hour, total_day: item.total_day, total_hour: item.totalHour, last_data_read: item.last_data_read };
                }
            })

            let rhStatus = newList.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };

                } else {
                    return { ...item, alarm: item.alarm };
                }
            });

            let listValue = await getPumpAverageValue(cookies.token, cookies.serialNumber);
            let listPumpMotorTmp = listPumpMotor.map(item => {
                let lv = listValue.find(p => item.plc_coordinate === p.field);

                if (lv) {
                    let valueVib = ((Number(lv.value) / 4095) * 20);
                    let finalValue = ((valueVib - 4) / 16) * 100
                    // let valueVibration = ((Number(lv.value))/(4095-0))*100
                    return { ...item, value: finalValue.toFixed(2) };
                } else {
                    return { ...item, value: Number(item.value) };
                }
            })
            setListThermalOverload(updatedList);
            setListContact(rhStatus);
            setListPumpMotor(listPumpMotorTmp)
            setIsDataLoaded(false);
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCoordinate = async () => {
        try {
            let response = await getPLCCoordinatePumpMotor(cookies.token, cookies.vesselId, 0, 6);
            let contact = await getPLCCoordinatePumpMotorContact(cookies.token, cookies.vesselId, 0, 6);
            let thermal = await getPLCCoordinatePumpMotorThermalOverload(cookies.token, cookies.vesselId, 0, 6);
            let wire = await getPLCCoordinatePumpMotorWireBreak(cookies.token, cookies.vesselId, 0, 6);
            setListPumpMotor(response);
            setListContact(contact);
            setListThermalOverload(thermal);
            setListWireBreak(wire);


            if (response.length < 1) {
                setLoading(false);
            }
        } catch (exception) {

        }
    }
    return (
        <div>
            <div>
                <TopNavBar />
            </div>
            <div>
                <TitleAlarmBar text={"PUMP AND MOTOR"} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                padding: 10
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: 10,
                    width: 100
                }}>
                    <Button
                        className="btnAlarms"
                        variant="outline-primary"
                        onClick={() => {
                            navigate('/PumpMotor')
                        }}
                    >
                        Page 1
                    </Button>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 10,
                    width: 100
                }}>

                    <Button
                        className="btnAlarms"
                        variant="outline-primary"
                        onClick={() => {
                            navigate("/PumpMotor2");
                        }}
                    >
                        Page 2
                    </Button>
                </div>


            </div>
            <div className="boxLST">

                {
                    listPumpMotor.length > 0 && listContact.length > 0 && listThermalOverload.length > 0 && listWireBreak.length > 0 &&
                    <div className="contentLST">
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            flexWrap: "nowrap",
                            width: "100%",
                            padding: 20,


                        }}>
                            <Col md={12}>
                                <Row>
                                    {
                                        listPumpMotor.map((pump, index) => {
                                            return (
                                                <Col md={4} key={index} style={{ paddingBottom: 20 }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        width: "100%",
                                                        borderStyle: "groove",
                                                        padding: 5
                                                    }}>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                            }}>
                                                                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                                                    <BitLamp text={pump.coordinate_desc} alarm={listContact[index].alarm}></BitLamp>
                                                                </div>
                                                                <div style={{ fontSize: 15, display: "flex", flex: 1 }}>Last Data Read : {listContact[index].last_data_read ? moment(listContact[index].last_data_read).format("DD-MM-yyyy HH:mm") : ""} </div>

                                                            </div>


                                                            <BarGraph
                                                                text={"821 FW Tank 20 C"}
                                                                valueBG={pump.value}
                                                                wireBreak={true}
                                                                shortError={false}
                                                            />
                                                            <div>Running Hours : {listContact[index].total_day} </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            // marginTop: 25,
                                                            marginLeft: -14
                                                        }}>
                                                            <div style={{ display: "flex", flexDirection: "column", flex: 1, width: "50% !important", alignItems: 'flex-end' }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    // width: "25%",
                                                                    paddingRight: 5
                                                                }}>

                                                                    <a type="button" onClick={() => {
                                                                        // alert(pump.equipment_id);
                                                                        setVesselEquipmentid(pump.equipment_id);
                                                                        setTotalHour(listContact[index].total_hour)
                                                                    }}> <InfoCircle size={25} /></a>
                                                                </div>


                                                            </div>
                                                            <div>
                                                                <BitLamp text={""} alarm={listWireBreak[index].alarm}></BitLamp>
                                                                <div style={{ fontSize: 14, marginLeft: -15 }}>Wire Break</div>
                                                            </div>

                                                            <div>
                                                                <BitLamp text={""} alarm={listThermalOverload[index].alarm}></BitLamp>
                                                                <div style={{ fontSize: 14, marginLeft: -15 }}>Thermal Overload</div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Col>


                                            )
                                        })
                                    }
                                </Row>
                            </Col>
                        </div>




                    </div>
                }



            </div>
            <Loading
                loading={loading}
            />


            <div style={{ paddingBottom: 120 }}></div>

            <Modal show={equipmentModal}
                // dialogClassName="modal-size"
                size={"xl"}
                onHide={() => {
                    setEquipmentModal(false);
                    setDetailEquipment({});
                    setVesselEquipmentid("")
                    // clearInventoryTypeModal()
                }}>

                {/*  */}
                <ContainerBox
                    containerPos="inner"
                    titleCaption={`Equipment Detail`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setEquipmentModal(false);
                                setDetailEquipment({});
                                setVesselEquipmentid("")
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Close</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <Form style={{ padding: 10 }}>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                width: "100%",
                                flexWrap: "nowrap"
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    flex: 1,
                                    flexWrap: "nowrap",
                                    paddingRight: 5
                                }}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Equipment Category</Form.Label>
                                        <Form.Control disabled value={detailEquipment.equipment_name}></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Manufacture Name</Form.Label>
                                        <Form.Control disabled value={detailEquipment.manufacturer_name}></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Model</Form.Label>
                                        <Form.Control disabled value={detailEquipment.model}></Form.Control>
                                    </Form.Group>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    flex: 1,
                                    flexWrap: "nowrap",
                                    paddingLeft: 5
                                }}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Serial Number</Form.Label>
                                        <Form.Control disabled value={detailEquipment.serial_number}></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Part Number</Form.Label>
                                        <Form.Control disabled value={detailEquipment.part_number}></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Position</Form.Label>
                                        <Form.Control disabled value={detailEquipment.position}></Form.Control>
                                    </Form.Group>

                                </div>
                            </div>

                            <div style={{
                                paddingBottom: 20
                            }}></div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Maintenance Name</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Maintenance Time(Hour)</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Next Maintenance Time(Hour)</div>
                                    {/* <div style={{ flex: 3 }} className="table-header-content">Rubrication(Hour)</div>
                                    <div style={{ flex: 3, textAlign: "center" }} className="table-header-content">Baring Replacement(Hour)</div> */}
                                    <div style={{ flex: 3 }} className="table-header-content">Last Maintenance</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Last Maintenance Hour</div>
                                    <div style={{ flex: 2 }} className="table-header-content"> Maintenance</div>

                                </div>
                                {
                                    listMaintenanceSchedule.map((list, index) => {
                                        return (
                                            <div style={{
                                                backgroundColor: `${list.background_color}`
                                            }} key={index} className="table-body">
                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{list.maintenance_name}</p></div>
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{list.maintenance_time.toFixed(0)}</p></div>
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{list.next_maintenance_hour ? list.next_maintenance_hour.toFixed(0) : ""}</p></div>
                                                {/* <div style={{ flex: 3 }} className="table-body-content"><p>{list.rubrication}</p></div>
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{list.baring_replacement}</p></div> */}
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{list.last_maintenance ? moment(list.last_maintenance).format("DD-MM-yyyy") : ""}</p></div>
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{list.last_maintenance_running_hour}</p></div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button variant="primary" onClick={() => {
                                                            if (window.confirm('Are You Sure To Do Maintenance?')) {

                                                                let total = 0
                                                                if (list.next_maintenance_hour) {
                                                                    total = Number(list.next_maintenance_hour) + Number(totalHour)
                                                                } else {
                                                                    total = Number(list.maintenance_time) + Number(totalHour)
                                                                }
                                                                setTotalMaintenanceTime(total);
                                                                setMaintenanceScheduleId(list.id);
                                                            }
                                                        }}><GearWideConnected />
                                                        </Button>
                                                    </div></div>
                                            </div>)
                                    }
                                    )
                                }
                            </div>

                            {
                                listMaintenanceSchedule.length > 0 &&
                                <Paginations
                                    totalPage={totalPage}
                                    page={page}
                                    setPage={setPage}
                                    setIsSearched={setIsSearched}
                                />

                            }


                        </Form>
                    }
                />



            </Modal>
            <div>
                <BottomNavBar />
            </div>
        </div>
    );
}