import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { ArrowLeft } from "react-bootstrap-icons";
import { isTokenValid, convertBase64, getUserAccessById} from "../../../../../Helpers/ApplicationHelpers";
import {
    getVesselClassificationNotationById,
    getVesselClassificationSociety, insertUpdateNotation
} from "../../../../../Helpers/VesselClassification.helpers";
import "../../../../../App.css";
import ContainerBox from "../../../../../Components/ContainerBox";
import Navbar from "../../../../../Components/NavBar";
import Loading from "../../../../../Components/Loading";
import DropDownForm from "../../../../../Components/DropDownForm";
import Clock from 'react-live-clock';
import { Trans, useTranslation } from "react-i18next";


export default function VesselTypeDetailPage() {
    const { t, i18n } = useTranslation();
    const inputFile = useRef(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [updatedButton, setUpdatedButton] = useState("");
    const [listLanguage, setListLanguage] = useState([]);
    const [listSociety, setListSociety] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [loading, setLoading] = useState(true);
    const [notation, setNotation] = useState({
        languageId: "",
        societyId: "",
        notationName: "",
        notationCode: "",
        notationDesc: ""
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
        }
        checkCookies();
        if (cookies.language_code === "id-id") {
            i18n.changeLanguage("id");
          } else if (cookies.language_code === "en-us") {
            i18n.changeLanguage("en");
          }
        if (location.state.notationId === 0) {
            loadSociety();
            loadAccess();
            setNotation({ ...notation, languageId: cookies.languageId });
            setLoading(false);
        } else {
            loadSociety();
            loadAccess();
            initNotation();
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [notation])

    const loadSociety = async () => {
        let listSociety = await getVesselClassificationSociety(cookies.token, cookies.languageId);
        setListSociety(listSociety);
    }

    const initNotation = async () => {
        try {
            let response = await getVesselClassificationNotationById(cookies.token, location.state.languageId, location.state.notationId);
            if (response) {
                setNotation({
                    notationName: response.notation_name,
                    notationCode: response.notation_code,
                    societyId: response.vessel_classification_society_id,
                    notationDesc: response.notation_desc,
                    languageId: response.language_id,
                    societyId: response.vessel_classification_society_id,
                    notationName: response.notation_name,
                    notationCode: response.notation_code,
                    notationDesc: response.notation_desc
                });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (notation.notationName === undefined || notation.notationName === "") {
                    setErrors({
                        ...errors,
                        notationNameError: `${t("detail_class_notation.error_empty_field")}`
                    },
                        setDisabledButton(true));
                } else if (notation.societyId === undefined || notation.societyId === "") {
                    setErrors({
                        ...errors,
                        societyIdError: `${t("detail_class_notation.error_empty_field")}`
                    },
                        setDisabledButton(true));
                } else if (notation.notationCode === undefined || notation.notationCode === "") {
                    setErrors({
                        ...errors,
                        notationCodeError: `${t("detail_class_notation.error_empty_field")}`
                    },
                        setDisabledButton(true));
                }
                else {
                    let errorMsg = omit(errors, "notationNameError", "societyIdError", "notationCodeError");
                    setErrors(errorMsg);
                    setDisabledButton(false);
                }

                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const saveNotation = async () => {
        let response = await insertUpdateNotation(cookies.token, notation, location.state.notationId);
        if (response) {
            alert(`${t("detail_class_notation.alert_success_save_data")}`);
            setLoading(false);
            navigate("../.././VesselClassification/Notation");
        } else {
            alert(`${t("detail_class_notation.alert_failed_save_data")}`);
            setLoading(false);
        }

    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if(response){
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_class_notation.header_title")}`} useActionContainer={false}
                useBackButton={true}
                backButtonChild={
                    <div>
                        <Button variant="secondary"
                            onClick={() => { navigate(-1); }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                alignSelf: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    alignSelf: "center",
                                    flex: 1,
                                }}><ArrowLeft size={32} /></div>
                            </div>
                        </Button></div>
                }
                    childContent={
                        <div className="master-table-inner-container">
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setLoading(true);
                                saveNotation();
                            }} style={{ padding: 10 }}>
                                <Form.Group className="mb-3">
                                    <DropDownForm value={notation.societyId} caption={`${t("detail_class_notation.field_class")}`} placeholder=""
                                        setValueFunction={(e) => {
                                            setNotation({ ...notation, societyId: e.target.value });
                                        }} listOption={listSociety} valueKey="id" labelKey="society_name" />
                                    {
                                        errors.societyIdError && <p style={{ color: "red" }}>{errors.societyIdError}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_class_notation.field_notation_name")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setNotation({ ...notation, notationName: e.target.value })
                                    }} type="text" value={notation.notationName} ></Form.Control>
                                    {
                                        errors.notationNameError && <p style={{ color: "red" }}>{errors.notationNameError}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_class_notation.field_notation_code")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setNotation({ ...notation, notationCode: e.target.value })
                                    }} type="text" value={notation.notationCode} ></Form.Control>
                                    {
                                        errors.notationCodeError && <p style={{ color: "red" }}>{errors.notationCodeError}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_class_notation.field_description")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setNotation({ ...notation, notationDesc: e.target.value })
                                    }} as="textarea" value={notation.notationDesc} ></Form.Control>
                                </Form.Group>
                                <Button style={{width:100}} disabled={!updatedButton} type="submit">{t("detail_class_notation.save_button")}</Button>
                                <Button style={{width:100}} variant="danger" className="cancel" onClick={() => {
                                    navigate("../.././VesselClassification/Notation");
                                }}>{t("detail_class_notation.cancel_button")}</Button>
                            </Form>
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>

                    } />
            </Container>
        </>
    )
}