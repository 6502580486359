import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, useLocation, useAsyncError } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getServiceJob, deleteServiceJob, getServiceJobCount, getServiceReport,
    getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { EyeFill, PencilFill, Trash, Search, ArrowClockwise, FilePlusFill, PrinterFill } from "react-bootstrap-icons";
import Moment from "moment";
import Paginations from "../../../Components/Pagination";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";


export default function ServiceJobPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listServiceJob, setListServiceJob] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [serviceJobCount, setServiceJobCount] = useState(0);
    const [page, setPage] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    // const [disabledPrint, setDisabledPrint] = useState(false);
    const [descending, setDescending] = useState(true);
    const [projectName, setProjectName] = useState("");
    const [serviceDate, setServiceDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("")
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [serviceJobId, setServiceJobId] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_annual_report.order_name1")}`,
        value: "project.project_name"
    });
    const [docxData, setDocxData] = useState("");

    const orderByList = [
        {
            label: `${t("index_annual_report.order_name1")}`,
            value: "project.project_name"
        }
    ]

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadServiceJob();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadServiceJob();
    }, [page]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadServiceJob();
    }, [resetSearch])

    useEffect(() => {
        loadServiceJob();
    }, [itemPerPage]);

    useEffect(() => {
        loadServiceJob();
    }, [orderBy]);

    useEffect(() => {
        if (docxData.size > 0) {
            downloadDocxFile();
        }
    }, [docxData]);

    // useEffect(() => {
    //     if (searchQuery === "") {
    //         loadServiceJob()
    //     }
    // }, [searchQuery]);

    useEffect(() => {
        if (removeId !== "")
            removeData();
    }, [removeId]);

    useEffect(() => {
        if (serviceJobId !== "")
            createDocument();
    }, [serviceJobId]);


    const loadServiceJob = async () => {
        try {
            let count = await getServiceJobCount(cookies.token, cookies.extCustomerId);
            setServiceJobCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
            let listServiceJob = await getServiceJob(cookies.token, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListServiceJob(listServiceJob);
            setLoading(false);
            setResetSearch(0);
        } catch (exception) {

        }

    }

    const downloadDocxFile = async () => {
        const element = document.createElement("a");
        const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        element.href = URL.createObjectURL(file);
        element.download = `${projectName} - ${Moment(serviceDate).format("DD-MM-yyyy")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        URL.revokeObjectURL(file);
        setLoading(false);
        setDisabledButton(false);
    }

    const removeData = async () => {
        try {
            // let text;
            // if (window.confirm("Anda ingin menghapus servis ini?")) {
            let response = await deleteServiceJob(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_annual_report.delete_success_alert")}`);
            } else {
                alert(`${t("index_annual_report.delete_failed_alert")}`);
            }
            // }
            loadServiceJob();

        } catch (exception) {
            // alert("Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin");
            console.log(exception);
        }
    }


    const createDocument = async () => {
        try {
            let docx = await getServiceReport(cookies.token, serviceJobId)
            if (docx.size) {
                setDocxData(docx);
            } else {
                alert(`${t("index_annual_report.error_download")}`);
                setLoading(false);
                setDisabledButton(false);
            }
            setServiceJobId("")
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_annual_report.order_name1")}`,
                value: "project.project_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_annual_report.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/ServiceJob/Detail", { state: { serviceJobId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_annual_report.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_annual_report.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadServiceJob();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadServiceJob();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadServiceJob();
                                            setResetSearch(1)
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 5 }} className="table-header-content">{t("index_annual_report.table_name1")}</div>
                                    <div style={{ flex: 5 }} className="table-header-content">{t("index_annual_report.table_name2")}</div>
                                    <div style={{ flex: 7 }} className="table-header-content">{t("index_annual_report.table_name3")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_annual_report.table_name4")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_annual_report.table_name5")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_annual_report.table_name6")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_annual_report.table_name7")}</div>

                                </div>
                                {
                                    listServiceJob.length === 0 &&
                                    <div>
                                        <div colSpan={7} style={{ textAlign: "center" }}>{t("index_annual_report.text_no_data")}</div>
                                    </div>
                                }
                                {
                                    listServiceJob.length > 0 && listServiceJob.map(
                                        (serviceJob, index) => {
                                            return (<div key={index} className="table-body">
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                <div style={{ flex: 5 }} className="table-body-content"><p>{serviceJob.project_name}</p></div>
                                                <div style={{ flex: 5 }} className="table-body-content"><p>{Moment(serviceJob.service_date).format("DD/MM/yyyy")}</p></div>
                                                <div style={{ flex: 7 }} className="table-body-content"><p>{serviceJob.vessel_name}</p></div>
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{serviceJob.status_name}</p></div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button variant="primary" onClick={() => {
                                                            navigate("/ServiceJob/Detail", { state: { serviceJobId: serviceJob.id } });
                                                        }}><PencilFill />
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                            onClick={(event) => {
                                                                if (window.confirm(`${t("index_annual_report.delete_confirmation")}  ${serviceJob.vessel_name} ${t("index_annual_report.text_date")} ${Moment(serviceJob.service_date).format("DD/MM/yyyy")}?`)) {
                                                                    let idRemove = serviceJob.id
                                                                    setRemoveId(idRemove)
                                                                }
                                                            }}
                                                        ><Trash /></Button>
                                                    </div>
                                                </div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                    <div style={{ padding: 5 }}>
                                                        <Button disabled={disabledButton} variant="primary" onClick={() => {
                                                            setDisabledButton(true);
                                                            setProjectName(serviceJob.project_name);
                                                            setServiceDate(serviceJob.service_date)
                                                            setServiceJobId(serviceJob.id);
                                                            setLoading(true);
                                                        }}><PrinterFill />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        })
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            {/* <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            /> */}
                        </div>
                    } />
            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}   