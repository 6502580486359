import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Modal, Form, } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getProjectStatus, getCompany, getProjectType, getContract, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import {
    getSurveyScheduleByVesselId, getSurveyScheduleByVesselIdCount, deleteSurveySchedule,
    insertUpdateSurveySchedule
} from "../../../../Helpers/SurveyScheduleHelpers";
import { getSurveyType } from "../../../../Helpers/SurveyHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Loading from "../../../../Components/Loading";
import Paginations from "../../../../Components/Pagination";
import {
    BoxArrowUpRight, FileCheckFill, PencilFill, Trash, CameraFill,
    FilePlusFill, Search, ArrowClockwise, GearWideConnected, EyeFill
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import { Moment } from 'react-moment';
import FormSelect from "../../../../Components/FormSelect";
import Select from 'react-select';
import moment from "moment";
import DatePicker from "../../../../Components/DatePicker";
import { Trans, useTranslation } from "react-i18next";


export default function VesselSurveySchedulePage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [listDocument, setListDocument] = useState([]);
    const [updatedButton,setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [listContract, setListContract] = useState([]);
    const [projectStatus, setProjectStatus] = useState("");
    const [validationError, setValidationError] = useState({});
    const [loading, setLoading] = useState(true);
    const [listSurveyType, setListSurveyType] = useState([]);
    const [listSurveyScheduleCount, setListSurveyScheduleCount] = useState([]);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [projectType, setProjectType] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_survey_schedule.order_name1")}`,
        value: "sched.created_date"
    });

    const [descending, setDescending] = useState(false);
    const [deletedSurveyScheduleId, setDeletedSurveyScheduleId] = useState("");
    const [listSurveySchedule, setListSurveySchedule] = useState([]);
    const [showScheduleDetailModal, setShowScheduleDetailModal] = useState(false);
    const [scheduleToBeViewed, setScheduleToBeViewed] = useState({
        id: "",
        vessel_id: "",
        survey_type_id: "",
        survey_schedule_remark: "",
        project_name: "",
        project_number: "",
        customer_id: "",
        contract_id: null,
        project_start_date: null,
        project_closing_date: null,
        project_status_id: null,
        project_location: "",
        vessel_location: "",
        estimated_start_date: null,
        estimated_end_date: null,
        vessel_arrived_date: null,
        project_type_id: null,
        done: false
    });
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const orderByList = [
        {
            label: `${t("index_survey_schedule.order_name3")}`,
            value: "tp.survey_type_name"
        },
        {
            label:`${t("index_survey_schedule.order_name2")}`,
            value: "sched.due_date"
        },
        {
            label: `${t("index_survey_schedule.order_name1")}`,
            value: "sched.created_date"
        }]

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadContract();
                loadProjectType();
                loadStatus();
                loadAccess();
                setVesselId(location.state.vesselId);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        setVesselName(location.state.vesselName);
        loadScheduleData();
        loadSurveyType();
        setLoading(false);
    }, [vesselId]);

    useEffect(() => {
        if (scheduleToBeViewed.done) {
            setLoading(true);
            saveSchedule();
        }
    }, [scheduleToBeViewed]);

    useEffect(() => {
        if (deletedSurveyScheduleId !== "") {
            removeSchedule();
        }
    }, [deletedSurveyScheduleId]);

    useEffect(() => {
        setLoading(true);
        if (vesselId !== "")
            loadScheduleData();
    }, [page]);

    useEffect(() => {
        setLoading(true);
        if (vesselId != "")
            loadScheduleData();
    }, [itemPerPage]);

    useEffect(() => {
        setLoading(true);
        if (vesselId !== "")
            loadScheduleData();
    }, [orderBy]);

    useEffect(() => {
        if (searchQuery === "") {
            loadScheduleData();
        }
    }, [searchQuery])

    useEffect(() => {
        setLoading(true);
        if (vesselId !== "")
            loadScheduleData();
    }, [descending]);

    const styles = {
        valueContainer: (base) => ({
            ...base,
            display: "flex",
            minWidth: "100%",
            flexDirection: "column",
        }),
    }

    const loadSurveyType = async () => {
        try {
            let listSurveyType = await getSurveyType(cookies.token, cookies.languageId, 0, 10000, "survey_type_name", false, undefined);
            setListSurveyType(listSurveyType);
        } catch (exception) {
            console.log(exception);
        }
    }


    const loadScheduleData = async () => {
        try {
            if (vesselId !== "") {
                let count = await getSurveyScheduleByVesselIdCount(cookies.token, vesselId, cookies.languageId, searchQuery);
                setListSurveyScheduleCount(count);
                let totalPage = listSurveyScheduleCount / itemPerPage;
                setTotalPage(totalPage);
                let listSurveySchedule = await getSurveyScheduleByVesselId(cookies.token, vesselId, cookies.languageId, page, itemPerPage, orderBy.value, descending, searchQuery);
                setListSurveySchedule(listSurveySchedule);
                setLoading(false);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeSchedule = async () => {
        let response = await deleteSurveySchedule(cookies.token, deletedSurveyScheduleId);
        console.log(response);
        if (response) {
            setDeletedSurveyScheduleId("");
            loadScheduleData();
        } else {
            alert(`${t("index_survey_schedule.delete_failed_alert")}`);
            setLoading(false);
        }
    }

    const resetDetail = () => {
        let schedule = {
            id: "",
            vessel_id: "",
            survey_type_id: "",
            survey_schedule_remark: "",
            project_name: "",
            project_number: "",
            customer_id: "",
            project_start_date: null,
            contrac_id: null,
            project_type_id: null,
            project_closing_date: null,
            project_status_id: null,
            estimated_start_date: null,
            estimated_end_date: null,
            project_location: "",
            vessel_location: "",
            vessel_arrived_date: null,
            done: false
        };
        setScheduleToBeViewed(schedule);
    }

    const saveSchedule = async () => {
        try {
            let res = await insertUpdateSurveySchedule(cookies.token, scheduleToBeViewed);
            if (!res) {
                alert(`${t("index_survey_schedule.alert_failed_save_data")}`);
            } else {
                alert(`${t("index_survey_schedule.alert_success_save_data")}`);
            }
            resetDetail();
            await loadScheduleData();
            setLoading(false);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadContract = async () => {
        try {
            let response = await getContract(cookies.token, cookies.languageId, cookies.extCustomerId);
            let newResponse = await response.filter(p => p.customer_id === location.state.customerId);
            setListContract(newResponse);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadStatus = async () => {
        try {
            let response = await getProjectStatus(cookies.token, cookies.languageId);
            let newRespons = response.find(p => p.project_status_code === "Open");
            setProjectStatus(newRespons.id);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadProjectType = async () => {
        try {
            let response = await getProjectType(cookies.token, cookies.languageId);
            let newProjectType = response.find(p => p.project_type_code === "SURVEY")
            setProjectType(newProjectType.id);
        } catch (exception) {
            console.log(exception);
            // alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_survey_schedule.order_name1")}`,
                value: "sched.created_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_survey_schedule.header_title")} ${vesselName}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setScheduleToBeViewed({
                                    ...scheduleToBeViewed,
                                    vessel_id: location.state.vesselId,
                                    customer_id: location.state.customerId,
                                    project_type_id: projectType,
                                    project_status_id: projectStatus

                                })
                                setShowScheduleDetailModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_survey_schedule.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_survey_schedule.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadScheduleData();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setPage(0);
                                            await loadScheduleData();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadScheduleData();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 5 }} className="table-header-content">{t("index_survey_schedule.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_survey_schedule.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_survey_schedule.table_name3")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_survey_schedule.table_name4")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_survey_schedule.table_name5")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_survey_schedule.table_name6")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_survey_schedule.table_name7")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_survey_schedule.table_name8")}</div>
                                </div>
                                {
                                    listSurveySchedule.map((schedule, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 5 }} className="table-body-content"><p>{schedule.survey_type_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{schedule.project_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{schedule.project_number}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{schedule.project_start_date ? moment(schedule.project_start_date).format("DD/MM/YYYY") : "-"}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{schedule.project_closing_date ? moment(schedule.project_closing_date).format("DD/MM/YYYY") : "-"}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Link to={`/Project/Detail?id=${schedule.project_id}`} target="_blank">
                                                        <Button variant="primary" onClick={() => {

                                                        }}><EyeFill />
                                                        </Button>
                                                    </Link>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setScheduleToBeViewed({ ...scheduleToBeViewed, ...schedule, done: false });
                                                        setShowScheduleDetailModal(true);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("index_survey_schedule.delete_confirmation")}  ${schedule.survey_type_name}?`)) {
                                                            setDeletedSurveyScheduleId(schedule.id);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}></div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    }
                />
                <Modal size="xl" show={showScheduleDetailModal} onHide={() => {
                    setShowScheduleDetailModal(false);
                    resetDetail()
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("index_survey_schedule.modal_add_survey_schedule")}`}
                        childContent={
                            <div style={{
                                display: "flex",
                                minWidth: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form style={{
                                    width: "100%"
                                }} onSubmit={(e) => {
                                    e.preventDefault();
                                    setScheduleToBeViewed({
                                        ...scheduleToBeViewed,
                                        vessel_id: vesselId,
                                        done: true
                                    });
                                    setShowScheduleDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <FormSelect required caption={`${t("index_survey_schedule.field_survey_type")}`} autoFocusValue={scheduleToBeViewed.id === ""} placeholder=""
                                                options={listSurveyType} value={listSurveyType.filter(p => p.id === scheduleToBeViewed.survey_type_id)} setValueFunction={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, survey_type_id: e.id });
                                                }}
                                                getOptionLabel={(option) => `${option.survey_type_name}`}
                                                getOptionValue={(option) => `${option.id}`}
                                                clearValue={() => setScheduleToBeViewed({ ...scheduleToBeViewed, survey_type_id: "" })}
                                            />
                                            {
                                                validationError.surveyTypeError && <p style={{ color: "red" }}>{validationError.surveyTypeErrorMessage}</p>
                                            }
                                        </Form.Group>
                                    </div>
                                    {/* <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <DatePicker caption="Tanggal Survey Terakhir" placeholder="Pilih Tanggal" onDateChange={(date) => {
                                            setScheduleToBeViewed({ ...scheduleToBeViewed, last_survey_date: moment(date).isValid() ? new Date(date) : null });
                                        }} value={moment(scheduleToBeViewed.last_survey_date).isValid() ? moment(scheduleToBeViewed.last_survey_date).toDate() : ""} />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <DatePicker caption="Tanggal Awal Survey" placeholder="Pilih Tanggal" onDateChange={(date) => {
                                            setScheduleToBeViewed({ ...scheduleToBeViewed, range_start_date: moment(date).isValid() ? new Date(date) : null });
                                        }} value={moment(scheduleToBeViewed.range_start_date).isValid() ? moment(scheduleToBeViewed.range_start_date).toDate() : ""} />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <DatePicker caption="Tanggal Akhir Survey" placeholder="Pilih Tanggal" onDateChange={(date) => {
                                            setScheduleToBeViewed({ ...scheduleToBeViewed, range_end_date: moment(date).isValid() ? new Date(date) : null });
                                        }} value={
                                                moment(scheduleToBeViewed.range_end_date).isValid() ? moment(scheduleToBeViewed.range_end_date).toDate() : null
                                            } />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <DatePicker caption="Tanggal Jatuh Tempo" placeholder="Pilih Tanggal" onDateChange={(date) => {
                                            setScheduleToBeViewed({ ...scheduleToBeViewed, due_date: moment(date).isValid() ? new Date(date) : null });
                                        }} value={moment(scheduleToBeViewed.due_date).isValid() ? moment(scheduleToBeViewed.due_date).toDate() : ""} />
                                    </div> */}
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_project_name")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control required onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, project_name: e.target.value })
                                                }} value={scheduleToBeViewed.project_name} type="text" placeholder=""></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_project_number")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control required onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, project_number: e.target.value })
                                                }} value={scheduleToBeViewed.project_number} type="text" placeholder=""></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <FormSelect caption={`${t("index_survey_schedule.field_contract_name")}`} autoFocusValue={scheduleToBeViewed.id === ""} placeholder=""
                                                options={listContract} value={listContract.filter(p => p.id === scheduleToBeViewed.contract_id)} setValueFunction={(e) => {
                                                    if (e !== null) {
                                                        setScheduleToBeViewed({ ...scheduleToBeViewed, contract_id: e.id });
                                                    } else {
                                                        setScheduleToBeViewed({ ...scheduleToBeViewed, contract_id: null });
                                                    }

                                                }}
                                                getOptionLabel={(option) => `${option.contract_name}`}
                                                getOptionValue={(option) => `${option.id}`}
                                                clearValue={() => setScheduleToBeViewed({ ...scheduleToBeViewed, contract_id: null })}
                                            />

                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_project_start_date")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control required type="date" onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, project_start_date: e.target.value === "" ? null : e.target.value })
                                                }} value={moment(scheduleToBeViewed.project_start_date).format("yyyy-MM-DD")}></Form.Control>

                                            </div>
                                        </Form.Group>

                                        {/* <DatePicker caption="Tanggal Mulai Proyek" placeholder="Pilih Tanggal" onDateChange={(date) => {
                                            setScheduleToBeViewed({ ...scheduleToBeViewed, project_start_date: moment(date).isValid() ? new Date(date) : null });
                                        }} value={moment(scheduleToBeViewed.project_start_date).isValid() ? moment(scheduleToBeViewed.project_start_date).toDate() : ""} /> */}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_project_end_date")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control type="date" onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, project_closing_date: e.target.value === "" ? null : e.target.value })
                                                }} value={moment(scheduleToBeViewed.project_closing_date).format("yyyy-MM-DD")}></Form.Control>
                                            </div>
                                        </Form.Group>
                                        {/* <DatePicker caption="Tanggal Akhir Proyek" placeholder="Pilih Tanggal" onDateChange={(date) => {
                                            setScheduleToBeViewed({ ...scheduleToBeViewed, project_closing_date: moment(date).isValid() ? new Date(date) : null });
                                        }} value={moment(scheduleToBeViewed.project_closing_date).isValid() ? moment(scheduleToBeViewed.project_closing_date).toDate() : ""} /> */}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_estimate_project_start_date")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control required type="date" onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, estimated_start_date: e.target.value === "" ? null : e.target.value })
                                                }} value={moment(scheduleToBeViewed.estimated_start_date).format("yyyy-MM-DD")}></Form.Control>

                                            </div>
                                        </Form.Group>

                                        {/* <DatePicker caption="Tanggal Mulai Proyek" placeholder="Pilih Tanggal" onDateChange={(date) => {
                                            setScheduleToBeViewed({ ...scheduleToBeViewed, project_start_date: moment(date).isValid() ? new Date(date) : null });
                                        }} value={moment(scheduleToBeViewed.project_start_date).isValid() ? moment(scheduleToBeViewed.project_start_date).toDate() : ""} /> */}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_estimate_project_end_date")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control required type="date" onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, estimated_end_date: e.target.value === "" ? null : e.target.value })
                                                }} value={moment(scheduleToBeViewed.estimated_end_date).format("yyyy-MM-DD")}></Form.Control>

                                            </div>
                                        </Form.Group>

                                    </div>
                                    {/* <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}><Form.Group style={{
                                        display: "flex",
                                        minWidth: "100%",
                                        flexDirection: "column",
                                    }} className="mb-3">
                                            <Form.Label>Ditunda?</Form.Label>
                                            <Form.Check onClick={(e) => {
                                                if (e.target.checked) {
                                                    console.log('true');
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, is_postponed: true });
                                                }
                                                else {
                                                    console.log('false');
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, is_postponed: false });
                                                }


                                            }} label={"Ditunda?"} defaultChecked={scheduleToBeViewed.is_postponed} type={"switch"}></Form.Check>
                                        </Form.Group>
                                    </div> */}
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_project_location")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, project_location: e.target.value })
                                                }} value={scheduleToBeViewed.project_location} type="text" placeholder=""></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_vessel_arrived_date")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control type="date" onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, vessel_arrived_date: e.target.value === "" ? null : e.target.value })
                                                }} value={moment(scheduleToBeViewed.vessel_arrived_date).format("yyyy-MM-DD")}></Form.Control>
                                            </div>
                                        </Form.Group>
                                        {/* <DatePicker caption="Waktu Kapal Tiba" placeholder="Pilih Tanggal" onDateChange={(date) => {
                                            setScheduleToBeViewed({ ...scheduleToBeViewed, vessel_arrived_date: moment(date).isValid() ? new Date(date) : null });
                                        }} value={moment(scheduleToBeViewed.vessel_arrived_date).isValid() ? moment(scheduleToBeViewed.vessel_arrived_date).toDate() : ""} /> */}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_vessel_location")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, vessel_location: e.target.value })
                                                }} value={scheduleToBeViewed.vessel_location} type="text" placeholder=""></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>{t("index_survey_schedule.field_note")}</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setScheduleToBeViewed({ ...scheduleToBeViewed, survey_schedule_remark: e.target.value })
                                                }} value={scheduleToBeViewed.survey_schedule_remark} as="textarea" rows={3} placeholder=""></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Button style={{width:100}} disabled={!updatedButton} variant="primary" type="submit">
                                        {t("index_survey_schedule.save_button")}
                                        </Button>
                                        <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {

                                            resetDetail();
                                            setShowScheduleDetailModal(false);
                                        }}>
                                            {t("index_survey_schedule.cancel_button")}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal>
            </Container>
        </>
    );
}