import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, insertUpdateWisdomWord, getWisdomWordById, convertBase64, getUserAccessById, convertBase64Image } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import ContainerBox from "../../../Components/ContainerBox";
import Loading from "../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import { Editor } from '@tinymce/tinymce-react';
import { Trans, useTranslation } from "react-i18next";


export default function WisdomWordDetailPage() {
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [wisdomWord, setWisdomWord] = useState({
        id: 0,
        description: "",
        author: ""
    })
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadAccess();
            }
        }
        checkCookies();
        if (location.state.wordId === 0) {
            setLoading(false);
        } else {
            initWisdomWord();
        }
    }, []);

    useEffect(() => {
        validationForm();
    }, [wisdomWord]);



    const addWisdomWord = async () => {
        try {
            let response = await insertUpdateWisdomWord(cookies.token, wisdomWord);
            if (response === 0) {
                alert(`${t("detail_wisdom_word.alert_success_save_data")}`);
                navigate("/WisdomWord")
            } else {
                alert(`${t("detail_wisdom_word.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
        } catch (exception) {
            // setErrorMessage("Data Gagal Disimpan");
        }
    }

    const initWisdomWord = async () => {
        try {
            let response = await getWisdomWordById(cookies.token, location.state.wordId);
            if (response) {
                setWisdomWord({
                    ...wisdomWord,
                    id: response.id,
                    description: response.description,
                    author: response.author
                })
                setLoading(false);
            } else {
                alert(`${t("detail_wisdom_word.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (wisdomWord.description === "" || wisdomWord.description === undefined) {
                    setErrorMessage(`${t("detail_wisdom_word.error_empty_field")}`)
                    setDisabledButton(true)
                }
                else {
                    setErrorMessage("");
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_wisdom_word.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/WisdomWord"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_wisdom_word.header_word")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    addWisdomWord();
                                                    setDisabledButton(true);
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_wisdom_word.field_author")}</Form.Label>
                                                        <Form.Control required type="text" onChange={(e) => {
                                                            setWisdomWord({ ...wisdomWord, author: e.target.value })
                                                        }} value={wisdomWord.author}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>{t("detail_wisdom_word.field_wisdom_word")}</Form.Label>
                                                        <Editor
                                                            apiKey='ztvd4c2g0hrta3yvvwkwq2gauss5uapibvpdepxx2fk5gqoi'
                                                            onEditorChange={(evt, editor) => {
                                                                setWisdomWord({ ...wisdomWord, description: evt })


                                                            }}
                                                            value={wisdomWord.description}
                                                            init={{
                                                                height: 500,
                                                                menubar: false,
                                                                plugins: [
                                                                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                ],
                                                                toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                                image_title: true,
                                                                automatic_uploads: true,
                                                                /*
                                                                  URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                  images_upload_url: 'postAcceptor.php',
                                                                  here we add custom filepicker only to Image dialog
                                                                */
                                                                file_picker_types: 'image',
                                                                /* and here's our custom image picker*/
                                                                file_picker_callback: (cb, value, meta) => {
                                                                    const input = document.createElement('input');
                                                                    input.setAttribute('type', 'file');
                                                                    input.setAttribute('accept', 'image/*');

                                                                    input.addEventListener('change', (e) => {
                                                                        const file = e.target.files[0];
                                                                        const reader = new FileReader();
                                                                        reader.addEventListener('load', () => {
                                                                            /*
                                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                                              registry. In the next release this part hopefully won't be
                                                                              necessary, as we are looking to handle it internally.
                                                                            */

                                                                            // console.log(editorRef.current.getContent())
                                                                            const id = 'blobid' + (new Date()).getTime();
                                                                            const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                            //   editorRef.current.editorUpload.blobCache

                                                                            //Editor.activeEditor.editorUpload.blobCache;

                                                                            const base64 = reader.result.split(',')[1];
                                                                            const blobInfo = blobCache.create(id, file, base64);
                                                                            blobCache.add(blobInfo);

                                                                            /* call the callback and populate the Title field with the file name */
                                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                                        });
                                                                        reader.readAsDataURL(file);
                                                                    });

                                                                    input.click();
                                                                },
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    {
                                                        errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                    }

                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "row",
                                                        padding: 5,
                                                        justifyContent: "center"
                                                    }}>
                                                        <div style={{
                                                            paddingRight: 5,
                                                        }}><Button style={{ width: 100 }} disabled={disabledButton} type="submit" variant="primary" >{t("detail_wisdom_word.save_button")}</Button></div>


                                                        <div style={{
                                                            paddingLeft: 5,
                                                        }}>
                                                            <Button style={{ width: 100 }} variant="danger" onClick={() => {
                                                                navigate('/WisdomWord');
                                                            }}>{t("detail_wisdom_word.cancel_button")}</Button>

                                                        </div>
                                                    </div>
                                                </Form>

                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>

                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loadingText={`${t("loading_component.text")}`}
                loading={loading}
            />
        </>
    )
}