import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getCustomerById, insertUpdateCustomer, getBank, getCustomerContact,
    getCustomerContactById, getCustomerContactCount, insertUpdateCustomerContact,
    deleteCustomerContact, getCompany, getTermoFPayment, getTitle, getUserAccessById,
    getCustomerType
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import Select from "react-select"
import { ArrowLeft, Trash, PencilFill, ArrowClockwise, Search, FilePlusFill } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function CustomerDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listCompany, setListCompany] = useState([]);
    const { t, i18n } = useTranslation();
    const [listBank, setListBank] = useState([]);
    const [deletedButton, setDeletedButton] = useState("");
    const [listCustomerType, setListCustomerType] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [listCustomerContact, setListCustomerContact] = useState([]);
    const [customerContactId, setCustomerContactId] = useState("");
    const [customerContactModal, setCustomerContactModal] = useState("");
    const [isSearched, setIsSearched] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState({ id: "" });
    const [selectedBank, setSelectedBank] = useState({ id: "" });
    const location = useLocation();
    const [listTermOfPayment, setListTermOfPayment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customerId, setCustomerId] = useState("");
    const [errors, setErrors] = useState({});
    const [page, setPage] = useState(0);
    const [disabledCompany, setDisabledCompany] = useState(true);
    const [removeId, setRemoveId] = useState("");
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [listTitle, setListTitle] = useState([]);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("detail_customer.order_name1")}`,
        value: "contact.contact_person_name"
    });
    const orderByList = [{
        label: `${t("detail_customer.order_name1")}`,
        value: "contact.contact_person_name"
    }];
    const [customerContactCount, setCustomerContactCount] = useState(0);
    const [selectedCustomerType, setSelectedCustomerType] = useState({ id: "" });
    const [descending, setDescending] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [customer, setCustomer] = useState({
        id: 0,
        customer_name: "",
        customer_type: "",
        city: "",
        bank_id: null,
        address_1: "",
        address_2: "",
        zip_code: "",
        bank_account_number: "",
        npwp: "",
        fg_ppn: "",
        nppkp: "",
        company_id: null,
        term_of_payment: null,
        customer_type_id: null,
        customer_type_name: "",
        ext_customer_id: cookies.extCustomerId
    });

    const [customerContact, setCustomerContact] = useState({
        id: "",
        customer_id: null,
        phone_number1: "",
        fax: "",
        email: "",
        contact_person_name: "",
        contact_title: null,
        phone_number2: "",
        note: ""
    })
    useEffect(() => {
        if (customerId !== 0 && customerId !== "")
            loadCustomerContact();
    }, [page]);

    useEffect(() => {
        if (customerId !== 0 && customerId !== "" && isSearched !== 0)
            loadCustomerContact();
    }, [itemPerPage]);

    useEffect(() => {
        if (customerId !== 0 && customerId !== "" && isSearched !== 0)
            loadCustomerContact();
    }, [orderBy]);

    useEffect(() => {
        if (customerId !== 0 && customerId !== "") {
            // if (searchQuery === "")
            loadCustomerContact();
        }

    }, [searchQuery]);

    useEffect(() => {
        if (customerId !== 0 && customerId !== "" && isSearched !== 0)
            loadCustomerContact()
    }, [descending]);

    useEffect(() => {
        if (selectedCompany.company_name) {

            setCustomer({ ...customer, company_id: selectedCompany.id });
            setCompanyAddress(selectedCompany.address)
        } else {
            setCustomer({ ...customer, company_id: null });
            setCompanyAddress("")
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (selectedCustomerType.customer_type_name) {
            setCustomer({ ...customer, customer_type_id: selectedCustomerType.id });
        } else {
            setCustomer({ ...customer, customer_type_id: null });
        }
    }, [selectedCustomerType]);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBank();
                setCustomerId(location.state.customerId);
                setLoading(false);
                loadCompany();
                loadTermOfPayment();
                loadTitle();
                loadAccess();
                loadCustomerType();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (customerId !== 0 && customerId !== "") {
            initCustomer();
            loadCustomerContact();
        }
    }, [customerId])

    useEffect(() => {
        if (listCompany.length > 0) {
            let companyTmp = listCompany.find(p => p.id === customer.company_id);
            if (companyTmp) {
                setSelectedCompany(companyTmp);
            }
        }
    }, [listCompany])


    useEffect(() => {
        if (selectedBank.bank_name) {
            setCustomer({ ...customer, bank_id: selectedBank.id });
        } else {
            setCustomer({ ...customer, bank_id: null });
        }
    }, [selectedBank]);

    useEffect(() => {
        if (location.state.customerId !== 0) {
            if (customer.bank_id) {
                let bank = listBank.find(p => p.id === customer.bank_id);
                if (bank) {
                    setSelectedBank(bank);
                }

            }

            if (customer.customer_type_id) {
                let listType = listCustomerType.find(p => p.id === customer.customer_type_id);
                if (listType) {
                    setSelectedCustomerType(listType);
                }

            }

            if (customer.company_id) {
                let response = listCompany.find(p => p.id === customer.company_id);
                if (response) {
                    setSelectedCompany(response);
                }

            }
        }

    }, [customer]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeCustomerContact();
    }, [removeId]);

    useEffect(() => {
        if (customerContactId !== "" && customerContactId !== undefined) {
            initCustomerContact();
        }

    }, [customerContactId]);

    useEffect(() => {
        if (!customerContactModal) {
            setCustomerContactId("")
        }
    }, [customerContactModal]);




    const clearCustomerContactModal = async () => {
        let customerContactTmp = {};
        customerContactTmp.id = 0;
        customerContactTmp.customer_id = customerId;
        customerContactTmp.phone_number1 = "";
        customerContactTmp.fax = "";
        customerContactTmp.email = "";
        customerContactTmp.contact_person_name = "";
        customerContactTmp.contact_title = null;
        customerContactTmp.phone_number2 = "";
        customerContactTmp.note = "";
        setCustomerContact(customerContactTmp);

    }



    const saveCustomer = async () => {
        try {
            let customerTmp = customer;
            customerTmp.ext_customer_id = customerTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : customer.ext_customer_id
            let response = await insertUpdateCustomer(cookies.token, customerTmp);
            if (response.error_code === 0) {
                alert(`${t("detail_customer.alert_success_save_data")}`);
                navigate("/Customer")
            } else {
                alert(`${t("detail_customer.alert_failed_save_data")}`)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadTitle = async () => {
        try {
            let response = await getTitle(cookies.token);
            setListTitle(response)
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadCustomerType = async () => {
        try {
            let response = await getCustomerType(cookies.token);
            setListCustomerType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const initCustomer = async () => {
        try {
            let response = await getCustomerById(cookies.token, location.state.customerId);
            if (response) {
                setCustomer({
                    ...customer,
                    id: response.id,
                    customer_name: response.customer_name,
                    customer_type: response.customer_type,
                    city: response.city,
                    bank_id: response.bank_id,
                    address_1: response.address_1,
                    address_2: response.address_2,
                    zip_code: response.zip_code,
                    bank_account_number: response.bank_account_number,
                    npwp: response.npwp,
                    fg_ppn: response.fg_ppn,
                    nppkp: response.nppkp,
                    company_id: response.company_id,
                    term_of_payment: response.term_of_payment,
                    customer_type_id: response.customer_type_id
                })

                let customerTypeName = await getCustomerType(cookies.token);
                let typeNameTmp = customerTypeName.find(p => p.id === response.customer_type_id);
                if (typeNameTmp) {
                    if (typeNameTmp.customer_type_name === "Perusahaan") {
                        setDisabledCompany(false);
                    } else {
                        setDisabledCompany(true);

                    }
                } else {
                    setDisabledCompany(true);
                }

                setOrderBy({
                    label: `${t("detail_customer.order_name1")}`,
                    value: "contact.contact_person_name"
                })
                setLoading(false);
            } else {
                alert(`${t("detail_customer.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const loadBank = async () => {
        try {
            let response = await getBank(cookies.token, cookies.languageId);
            setListBank(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCustomerContact = async () => {
        let count = await getCustomerContactCount(cookies.token, customerId, searchQuery, false);
        setCustomerContactCount(count);
        let totalPage = count / itemPerPage;
        setTotalPage(totalPage);


        let response = await getCustomerContact(cookies.token, customerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
        setListCustomerContact(response)
        setLoading(false);
        setIsSearched(0);
    }

    const initCustomerContact = async () => {
        try {
            let response = await getCustomerContactById(cookies.token, customerContactId)
            if (response) {
                setCustomerContact({
                    ...customerContact,
                    id: response.id,
                    customer_id: response.customer_id,
                    phone_number1: response.phone_number1,
                    fax: response.fax,
                    email: response.email,
                    contact_person_name: response.contact_person_name,
                    contact_title: response.contact_title,
                    phone_number2: response.phone_number2,
                    note: response.note
                });

                setCustomerContactModal(true);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const removeCustomerContact = async () => {
        try {
            let response = await deleteCustomerContact(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("detail_customer.delete_success_alert")}`);
                loadCustomerContact();
            } else {
                alert(`${t("detail_customer.delete_failed_alert")}`);
            }
            setRemoveId("");
            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveCustomerContact = async () => {
        try {
            let response = await insertUpdateCustomerContact(cookies.token, customerContact);
            if (response.error_code === 0) {
                alert(`${t("detail_customer.alert_success_save_data")}`);
                setCustomerContactModal(false);
                clearCustomerContactModal();
                loadCustomerContact();
                setDisabledButton(false);
            } else {
                alert(`${t("detail_customer.alert_failed_save_data")}`)
            }
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadCompany = async () => {
        try {
            let response = await getCompany(cookies.token, cookies.languageId, "null" /*cookies.extCustomerId*/);
            setListCompany(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadTermOfPayment = async () => {
        try {
            let response = await getTermoFPayment(cookies.token);
            setListTermOfPayment(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_customer.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Customer"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    borderWidth: 2,
                                    borderColor: "rgba(3, 30, 103, 1)",
                                    borderRadius: 10,
                                    borderStyle: "solid",
                                }}>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        setDisabledButton(true);
                                        setLoading(true);
                                        saveCustomer();

                                    }}>
                                        <Tabs
                                            defaultActiveKey="information"
                                            // transition={false}
                                            id="noanim-tab-example"
                                            className="mb-3"
                                            style={{
                                                backgroundColor: "rgba(3, 30, 103, 1)",
                                                borderRadius: 5,

                                            }}
                                        >
                                            <Tab eventKey="information" title={`${t("detail_customer.tab_1")}`}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    padding: "0px 10px 10px 10px"
                                                }}>
                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_customer.header_customer_information")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                width: "100%"
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "row",
                                                                    flexWrap: "nowrap"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        flexDirection: "column",
                                                                        flexWrap: "nowrap",
                                                                        width: "100%",
                                                                        paddingRight: 10
                                                                    }}>
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_customer_name")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setCustomer({ ...customer, customer_name: e.target.value })
                                                                            }} value={customer.customer_name} placeholder="" required></Form.Control>
                                                                        </Form.Group>

                                                                        {/* <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_customer_type")}</Form.Label>
                                                                            <Form.Select value={customer.customer_type} onChange={(e) => {
                                                                                setCustomer({ ...customer, customer_type: e.target.value })
                                                                            }}>
                                                                                <option value=""></option>
                                                                                <option value="company">Perusahaan</option>
                                                                                <option value="personal">Perorangan</option>
                                                                            </Form.Select>
                                                                        </Form.Group> */}

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_customer_type")}</Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.customer_type_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listCustomerType} value={selectedCustomerType} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedCustomerType({})
                                                                                        setCustomer({ ...customer, customer_type_id: null, customer_type_name: "" })
                                                                                    } else {
                                                                                        setSelectedCustomerType(e);
                                                                                    }
                                                                                }}
                                                                                isClearable

                                                                            />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_customer_city")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setCustomer({ ...customer, city: e.target.value })
                                                                            }} value={customer.city} placeholder="" required></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_bank_name")}</Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.bank_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listBank} value={selectedBank} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedBank({})
                                                                                    } else {
                                                                                        setSelectedBank(e);
                                                                                    }
                                                                                }}
                                                                                isClearable

                                                                            />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_bank_account_number")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                                                } else {
                                                                                    setCustomer({ ...customer, bank_account_number: e.target.value })
                                                                                }


                                                                            }} value={customer.bank_account_number} placeholder="" ></Form.Control>
                                                                        </Form.Group>

                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        flexDirection: "column",
                                                                        flexWrap: "nowrap",
                                                                        width: "100%",
                                                                        paddingLeft: 10
                                                                    }}>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_term_of_payment")}</Form.Label>
                                                                            <Form.Select onChange={(e) => {
                                                                                if (e.target.value === "") {
                                                                                    setCustomer({ ...customer, term_of_payment: null })
                                                                                } else {
                                                                                    setCustomer({ ...customer, term_of_payment: e.target.value })
                                                                                }
                                                                            }} value={customer.term_of_payment}>
                                                                                <option value=""></option>
                                                                                {
                                                                                    listTermOfPayment.map((list, index) => {
                                                                                        return (
                                                                                            <option key={index} value={list.id}>{list.number_of_days}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Form.Select>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_postal_code")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                if (!new RegExp(/^[0-9]{0,6}$/).test(e.target.value)) {

                                                                                } else {
                                                                                    setCustomer({ ...customer, zip_code: e.target.value })
                                                                                }

                                                                            }} value={customer.zip_code} placeholder="" ></Form.Control>
                                                                        </Form.Group>


                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_address1")}</Form.Label>
                                                                            <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                                setCustomer({ ...customer, address_1: e.target.value })
                                                                            }} value={customer.address_1} placeholder="" ></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_address2")}</Form.Label>
                                                                            <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                                setCustomer({ ...customer, address_2: e.target.value })
                                                                            }} value={customer.address_2} placeholder=""></Form.Control>
                                                                        </Form.Group>






                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    }>
                                                    </ContainerBox>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="contact" title={`${t("detail_customer.tab_2")}`} disabled={location.state.customerId === 0 ? true : false}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    padding: "0px 10px 10px 10px"
                                                }}>
                                                    <ContainerBox containerPos="inner" titleCaption={`${t("detail_customer.header_customer_contact")}`}
                                                        useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                                        actionContainerChild={
                                                            <div>
                                                                <Button disabled={!updatedButton} variant="secondary" style={{

                                                                }} onClick={() => {
                                                                    // navigate("/Country/Detail", { state: { countryId: 0 } }) 
                                                                    // setCustomerContact({ ...customerContact, id: 0, customer_id: customerId })
                                                                    setCustomerContactModal(true);
                                                                    clearCustomerContactModal()
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        flexDirection: "row",
                                                                        alignSelf: "center",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            flex: 1,
                                                                        }}><FilePlusFill size={32} /></div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 8,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            alignSelf: "center",
                                                                            paddingLeft: 10,
                                                                            fontWeight: "bold",
                                                                            fontSize: 18,
                                                                        }}>{t("detail_customer.add_button")}</div>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        }
                                                        childContent={
                                                            <div style={{
                                                                padding: 10,
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "column",
                                                                    flexWrap: "nowrap",
                                                                    width: "100%"
                                                                }}>
                                                                    <div className="master-table-inner-container">
                                                                        <div className="master-table-searchbar-container">
                                                                            <div className="master-table-searchbar-textbox">
                                                                                <Form.Control value={searchQuery} type="text" placeholder={`${t("detail_customer.search_box")}`}
                                                                                    onKeyPress={async (e) => {
                                                                                        if (e.charCode === 13) {
                                                                                            setLoading(true);
                                                                                            await loadCustomerContact();
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setPage(0);
                                                                                        setSearchQuery(e.target.value);
                                                                                    }} />
                                                                            </div>
                                                                            <div className="master-table-searchbar-button">
                                                                                <div style={{ paddingRight: 5 }}>
                                                                                    <Button onClick={async () => {
                                                                                        setLoading(true);
                                                                                        await loadCustomerContact();
                                                                                    }}><Search /></Button>
                                                                                </div>
                                                                                <div style={{ paddingLeft: 5 }}>
                                                                                    <Button onClick={async () => {
                                                                                        setLoading(true);
                                                                                        setSearchQuery("");
                                                                                        await loadCustomerContact();
                                                                                    }}><ArrowClockwise /></Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_customer.table_name1")}</div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">{t("detail_customer.table_name2")}</div>
                                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_customer.table_name3")}</div>
                                                                                <div style={{ flex: 1 }} className="table-header-content">{t("detail_customer.table_name4")}</div>
                                                                            </div>
                                                                            {
                                                                                listCustomerContact.map((contact, index) => {
                                                                                    return (<div key={index} className="table-body">
                                                                                        <div style={{ flex: 0.5, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{contact.customer_name}</p></div>
                                                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{contact.contact_person_name}</p></div>
                                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                            <div style={{ padding: 5 }}>
                                                                                                <Button variant="primary" onClick={() => {
                                                                                                    let idContact = contact.id;
                                                                                                    setCustomerContactId(idContact);
                                                                                                }}><PencilFill />
                                                                                                </Button>
                                                                                            </div></div>
                                                                                        <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                                            <div style={{ padding: 5 }}>
                                                                                                <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                                                                    // navigate("/Country/Detail", { state: { countryId: country.id } });
                                                                                                    let idContact = contact.id;
                                                                                                    if (window.confirm(`${t("detail_customer.delete_confirmation")} ${contact.contact_person_name}`))
                                                                                                        setRemoveId(idContact);
                                                                                                }}><Trash />
                                                                                                </Button>
                                                                                            </div></div>


                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div style={{
                                                                            paddingTop: 20,
                                                                        }}>

                                                                        </div>
                                                                        <Paginations
                                                                            itemPerPage={itemPerPage}
                                                                            totalPage={totalPage}
                                                                            page={page}
                                                                            setPage={setPage}
                                                                            setItemPerPage={setItemPerPage}
                                                                            itemPerPageSelection={itemPerPageSelection}
                                                                            orderBy={orderBy}
                                                                            setOrderBy={setOrderBy}
                                                                            orderBySelection={orderByList}
                                                                            isDescActive={descending}
                                                                            setIsDescActive={setDescending}
                                                                            setIsSearched={setIsSearched}
                                                                        />
                                                                        <Loading
                                                                            loading={loading}
                                                                        />
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        }>
                                                    </ContainerBox>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="company" title={`${t("detail_customer.tab_3")}`} disabled={disabledCompany}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                    padding: "0px 10px 10px 10px"
                                                }}>
                                                    <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_customer.header_company_information")}`} childContent={
                                                        <div style={{
                                                            padding: 10,
                                                        }}>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                flexWrap: "nowrap",
                                                                width: "100%"
                                                            }}>

                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    flexDirection: "row",
                                                                    flexWrap: "nowrap"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                        flexDirection: "column",
                                                                        flexWrap: "nowrap",
                                                                        width: "100%",
                                                                        paddingRight: 10
                                                                    }}>


                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_company_name")}</Form.Label>
                                                                            <Select placeholder={""}
                                                                                getOptionLabel={(item) => {
                                                                                    return item.company_name;
                                                                                }} clearValue={true}
                                                                                getOptionValue={(item) => {
                                                                                    return item.id;
                                                                                }}
                                                                                options={listCompany.filter(cookies.extCustomerId === "null" ? p => p.ext_customer_id !== "null" : p => p.ext_customer_id === cookies.extCustomerId)} value={selectedCompany} onChange={(e) => {
                                                                                    if (e === null) {
                                                                                        setSelectedCompany({})
                                                                                    } else {
                                                                                        setSelectedCompany(e);
                                                                                    }
                                                                                }}
                                                                                isClearable
                                                                                required
                                                                            />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_company_address")}</Form.Label>
                                                                            <Form.Control as="textarea" rows={3} onChange={(e) => {

                                                                            }} value={companyAddress} disabled={true} placeholder=""></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_npwp")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                                                } else {
                                                                                    setCustomer({ ...customer, npwp: e.target.value })
                                                                                }

                                                                            }} value={customer.npwp} placeholder="" ></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_fg_ppn")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setCustomer({ ...customer, fg_ppn: e.target.value })
                                                                            }} value={customer.fg_ppn} placeholder="" ></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>{t("detail_customer.field_nppkp")}</Form.Label>
                                                                            <Form.Control type="text" onChange={(e) => {
                                                                                setCustomer({ ...customer, nppkp: e.target.value })
                                                                            }} value={customer.nppkp} placeholder="" ></Form.Control>
                                                                        </Form.Group>


                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    }>
                                                    </ContainerBox>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                        {
                                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                <></>
                                                :
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    padding: 5
                                                }}>

                                                    <Button style={{width:100}} type="submit" disabled={!updatedButton} variant="primary" >{t("detail_customer.save_button")}</Button>

                                                    <Button style={{width:100}} className="cancel" type="reset" variant="danger" onClick={() => {
                                                        navigate('/Customer')
                                                    }}>{t("detail_customer.cancel_button")}</Button>

                                                </div>
                                        }




                                    </Form>

                                </div>




                            </div>


                        </div >

                    }>

                </ContainerBox >

                <Modal size="xl" show={customerContactModal} onHide={
                    () => {
                        setCustomerContactModal(false);
                        clearCustomerContactModal();
                    }
                }>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`${t("detail_customer.modal_add_customer_header")}`}
                        childContent={
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                padding: 10,
                                flex: 1,
                                flexWrap: "nowrap"
                            }}>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setDisabledButton(true);
                                    saveCustomerContact();

                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingRight: 5,
                                                flex: 3,
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_customer.field_title")}</Form.Label>
                                                <Form.Select onChange={(e) => {
                                                    if (e.target.value === "") {
                                                        setCustomerContact({ ...customerContact, contact_title: null })
                                                    } else {
                                                        setCustomerContact({ ...customerContact, contact_title: e.target.value });
                                                    }
                                                }} value={customerContact.contact_title} required>
                                                    <option value=""></option>
                                                    {
                                                        listTitle.map((list, index) => {
                                                            return (
                                                                <option key={index} value={list.id}>{list.title_name}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>

                                            </Form.Group>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5,
                                                flex: 10
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_customer.field_contact_name")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    setCustomerContact({ ...customerContact, contact_person_name: e.target.value });
                                                }} value={customerContact.contact_person_name} placeholder="" required></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingRight: 5
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_customer.field_phone_number1")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                    } else {
                                                        setCustomerContact({ ...customerContact, phone_number1: e.target.value });
                                                    }

                                                }} value={customerContact.phone_number1} placeholder=""></Form.Control>
                                            </Form.Group>

                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5,
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_customer.field_phone_number2")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    if (!new RegExp(/^[\+]?[0-9]{0,15}$/gmi).test(e.target.value)) {

                                                    } else {
                                                        setCustomerContact({ ...customerContact, phone_number2: e.target.value });
                                                    }
                                                }} value={customerContact.phone_number2} placeholder="" ></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingRight: 5,
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_customer.field_fax")}</Form.Label>
                                                <Form.Control type="text" onChange={(e) => {
                                                    if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                    } else {
                                                        setCustomerContact({ ...customerContact, fax: e.target.value });
                                                    }

                                                }} value={customerContact.fax} placeholder=""></Form.Control>
                                            </Form.Group>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_customer.field_email")}</Form.Label>
                                                <Form.Control type="email" onChange={(e) => {
                                                    setCustomerContact({ ...customerContact, email: e.target.value });
                                                }} value={customerContact.email} placeholder=""></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Group style={{
                                                width: "100%",
                                                paddingLeft: 5
                                            }} className="mb-3">
                                                <Form.Label>{t("detail_customer.field_note")}</Form.Label>
                                                <Form.Control as={"textarea"} rows={3} onChange={(e) => {
                                                    setCustomerContact({ ...customerContact, note: e.target.value });
                                                }} value={customerContact.note} placeholder=""></Form.Control>
                                            </Form.Group>
                                        </div>
                                        {
                                            cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                                <></>
                                                :

                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    padding: 5,
                                                    justifyContent: "center"
                                                }}>
                                                    <Button style={{ width: 100 }} disabled={disabledButton === true ? disabledButton : !updatedButton} type="submit" variant="primary">{t("detail_customer.save_button")}</Button>
                                                    <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                                        clearCustomerContactModal();
                                                        setCustomerContactModal(false);
                                                    }}>{t("detail_customer.cancel_button")}</Button>
                                                </div>
                                        }

                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal>

            </Container >
            <Loading
                loading={loading}
            />
        </>
    )
}