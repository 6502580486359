import React, { useState, useEffect } from "react";
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ArrowLeft, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise } from "react-bootstrap-icons";
import { isTokenValid, insertUpdateVesselInventory, getVesselInventoryById, findVesselInventorySubCategory, getUserAccessById, getVesselSubCategory } from "../../../../../Helpers/ApplicationHelpers";
import "../../../../../App.css";
import Navbar from "../../../../../Components/NavBar";
import ContainerBox from "../../../../../Components/ContainerBox";
import Select from 'react-select';
import { omit } from 'lodash';

export default function VesselInventoryDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [disabledButton, setDisabledButton] = useState(false);
    const location = useLocation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [listInventorySubCategory, setListInventorySubCategory] = useState([]);
    const [inventoryId, setInventoryId] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState({});
    const [errors, setErrors] = useState([]);

    const [vesselInventoryMaster, setVesselInventoryMaster] = useState({
        id: 0,
        vessel_inventory_name: "",
        vessel_inventory_brand: "",
        vessel_inventory_model: "",
        vessel_inventory_description: "",
        vessel_inventory_sub_category_id: "",
        vessel_id: location.state.vesselId
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setInventoryId(location.state.vesselInventoryMasterId);
                loadAccess();

            }
        }
        checkCookies();
       
    }, []);

    useEffect(() => {
        loadSubCategory();
        if (inventoryId !== "" && inventoryId !== 0) {
            initVesselInventoryMaster();
        }
    }, [inventoryId])

    useEffect(() => {
        if (vesselInventoryMaster.vessel_inventory_sub_category_id) {
            let inventoryCategory = listInventorySubCategory.find(p => p.id === vesselInventoryMaster.vessel_inventory_sub_category_id);
            if (inventoryCategory) {
                setSelectedSubCategory(inventoryCategory);
            }

        }

        validationForm();
    }, [vesselInventoryMaster])

    useEffect(() => {
        if (selectedSubCategory.sub_category_name) {
            setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_sub_category_id: selectedSubCategory.id });
        } else {
            setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_sub_category_id: null });
        }
    }, [selectedSubCategory]);


    const initVesselInventoryMaster = async () => {
        try {
            let response = await getVesselInventoryById(cookies.token, location.state.vesselInventoryMasterId);
            if (response) {
                setVesselInventoryMaster({
                    id: response.id,
                    vessel_inventory_name: response.vessel_inventory_name,
                    vessel_inventory_brand: response.vessel_inventory_brand,
                    vessel_inventory_model: response.vessel_inventory_model,
                    quantity: response.quantity,
                    vessel_inventory_description: response.vessel_inventory_description,
                    vessel_inventory_sub_category_id: response.vessel_inventory_sub_category_id,
                    vessel_id: response.vessel_id

                });
            }

        } catch (exception) {
            console.log(exception);
        }
    }

 
    const saveData = async () => {
        let response = await insertUpdateVesselInventory(cookies.token, vesselInventoryMaster);
        if (response === 0) {
            alert("Berhasil Menyimpan Data");
            navigate("/Vessel/InventoryMaster");
        }
        else {
            alert("Gagal Menyimpan Data")
        }
    }

    const loadSubCategory = async () => {
        try {
            let response = await getVesselSubCategory(cookies.token);
            setListInventorySubCategory(response);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const validationForm = async () => {
        try {

            if (vesselInventoryMaster.vessel_inventory_sub_category_id === "" || vesselInventoryMaster.vessel_inventory_sub_category_id === undefined || vesselInventoryMaster.vessel_inventory_sub_category_id === null) {
                setErrors({
                    ...errors,
                    message: '*Harus Diisi'
                },
                    setDisabledButton(true));
            } else {
                let errorMsg = omit(errors, "message");
                setErrors(errorMsg);
                setDisabledButton(false);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

   
    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if(response){
                setUpdatedButton(response.updated)

            }
            setUpdatedButton(true)
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container>
                <ContainerBox
                    titleCaption="Detail Inventaris"
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/Vessel/InventoryMaster"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            {
                                location.state.vesselInventoryMasterId === 0 ? <></> :
                                    <Button variant="secondary" onClick={(e) => {
                                        navigate("/InventoryStock", { state: { inventoryMasterId: vesselInventoryMaster.id } });
                                    }}>Stok Inventaris</Button>

                            }
                        </div>
                    }
                    childContent={
                        <div>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                saveData();

                            }} style={{ padding: 10 }}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Jenis Barang</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.sub_category_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listInventorySubCategory} value={selectedSubCategory} onChange={(e) => {
                                            if (e === null) {
                                                setSelectedSubCategory({})
                                            } else {
                                                setSelectedSubCategory(e);
                                            }
                                        }}
                                        isClearable
                                        isDisabled={location.state.vesselInventoryMasterId !== 0 ? true : false}
                                    />
                                    {
                                        errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Merk</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_brand: e.target.value, vessel_inventory_name: e.target.value + ' ' + vesselInventoryMaster.vessel_inventory_model })
                                    }} type="text" value={vesselInventoryMaster.vessel_inventory_brand} required placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_model: e.target.value, vessel_inventory_name: vesselInventoryMaster.vessel_inventory_brand + ' ' + e.target.value })
                                    }} type="text" value={vesselInventoryMaster.vessel_inventory_model} required placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nama Barang Inventaris</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_name: e.target.value })
                                    }} type="text" value={vesselInventoryMaster.vessel_inventory_name} required placeholder="" disabled={true}></Form.Control>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Deskripsi</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setVesselInventoryMaster({ ...vesselInventoryMaster, vessel_inventory_description: e.target.value })
                                    }} as="textarea" rows={3} value={vesselInventoryMaster.vessel_inventory_description}  placeholder="" ></Form.Control>
                                   
                                </Form.Group>
                                
                                {
                                    cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                    <></>
                                    :
                                    <div className=""
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                    <Button className="save-button" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>Simpan</Button>
                                    <Button className="cancel cancel-button" variant="danger" onClick={() => {
                                        navigate("/InventoryMaster");
                                    }}>Batal</Button>
                                    {/* {
                                        location.state.vesselInventoryMasterId === 0 ? <></>
                                            : <Button className="cancel save-button" onClick={() => {
                                                navigate("/InventoryStock", { state: { inventoryMasterId: vesselInventoryMaster.id, departmentCode: vesselInventoryMaster.departementCode } });
                                            }}>Stock</Button>
                                    } */}

                                </div>

                                }
                                
                            </Form>
                        </div>} />
            </Container>

        </>
    )
}