import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import { getWisdomWord, isTokenValid, getCountWisdomWord, deleteWisdomWord, getUserAccessById } from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import Navbar from "../../Components/NavBar";
import Loading from "../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../Components/Pagination";
import ContainerBox from "../../Components/ContainerBox";
import { convert } from 'html-to-text'
import { Trans, useTranslation } from "react-i18next";



export default function WisdomWordPage() {
  const { t, i18n } = useTranslation();
  const [listWord, setListWord] = useState([]);
  const [cookies, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [updatedButton, setUpdatedButton] = useState("");
  const [deletedButton, setDeletedButton] = useState("");
  const [isSearched, setIsSearched] = useState(0);
  const [resetSearch, setResetSearch] = useState(0);
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [removeId, setRemoveId] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
  const [orderBy, setOrderBy] = useState({
    label: `${t("index_wisdom.order_name1")}`,
    value: "created_date"
  });
  const orderByList = [{
    label: `${t("index_wisdom.order_name1")}`,
    value: "created_date"
  }];
  const options = {
    wordwrap: 130
  }
  const [descending, setDescending] = useState(false);


  useEffect(() => {
    async function checkCookies() {
      let isAuthenticated = await isTokenValid(cookies.token);
      if (!isAuthenticated)
        navigate("/");
      else {
        if (cookies.language_code === "id-id") {
          i18n.changeLanguage("id");
        } else if (cookies.language_code === "en-us") {
          i18n.changeLanguage("en");
        }
        loadWisdomWord();
        loadAccess();
      }
    }
    checkCookies();
  }, []);

  useEffect(() => {
    loadWisdomWord();
  }, [page]);

  useEffect(() => {
    if (isSearched !== 0)
      loadWisdomWord();
  }, [itemPerPage]);

  useEffect(() => {
    if (isSearched !== 0)
      loadWisdomWord();
  }, [orderBy]);


  useEffect(() => {
    if (resetSearch !== 0) {
      loadWisdomWord()
    }
  }, [resetSearch])

  useEffect(() => {
    if (isSearched !== 0)
      loadWisdomWord()
  }, [descending]);

  useEffect(() => {
    if (removeId !== "")
      removeWisdomWord();
  }, [removeId])


  const loadWisdomWord = async () => {
    try {
      let count = await getCountWisdomWord(cookies.token, searchQuery, false);
      let totalPage = count / itemPerPage;
      setTotalPage(totalPage);

      let listWord = await getWisdomWord(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
      setListWord(listWord)
      setLoading(false);
      setResetSearch(0);
      setIsSearched(0);
    } catch (exception) {
      console.log(exception)
    }
  }

  const removeWisdomWord = async () => {
    try {
      let response = await deleteWisdomWord(cookies.token, removeId);
      if (response === 0) {
        alert(`${t("index_wisdom.delete_success_alert")}`);
        loadWisdomWord();
      }
      setRemoveId("");
    } catch (exception) {
      console.log(exception)
    }
  }

  const loadAccess = async () => {
    try {
      // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
      let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
      if (response) {
        setDeletedButton(response.deleted);
        setUpdatedButton(response.updated)
      }
      setOrderBy({
        label: `${t("index_wisdom.order_name1")}`,
        value: "created_date"
      })
    } catch (exception) {
      console.log(exception);
    }
  }

  return (
    <>
      <Navbar />
      <Container fluid style={{
        display: "flex",
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
      }}>
        <ContainerBox
          titleCaption={`${t("index_wisdom.header_title")}`}
          useActionContainer={true}
          actionContainerChild={
            <div>
              <Button disabled={!updatedButton} variant="secondary" style={{

              }} onClick={() => { navigate("/WisdomWord/Detail", { state: { wordId: 0 } }) }}>
                <div style={{
                  display: "flex",
                  flex: 1,
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignSelf: "center",
                }}>
                  <div style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    flex: 1,
                  }}><FilePlusFill size={32} /></div>
                  <div style={{
                    display: "flex",
                    flex: 8,
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    paddingLeft: 10,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}>{t("index_wisdom.add_button")}</div>
                </div>
              </Button>
            </div>
          }
          childContent={
            <div className="master-table-inner-container">
              <div className="master-table-searchbar-container">
                <div className="master-table-searchbar-textbox">
                  <Form.Control value={searchQuery} type="text" placeholder={`${t("index_wisdom.search_box")}`}
                    onKeyPress={async (e) => {
                      if (e.charCode === 13) {
                        setPage(0);
                        setLoading(true);
                        await loadWisdomWord();
                      }
                    }}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }} />
                </div>
                <div className="master-table-searchbar-button">
                  <div style={{ paddingRight: 5 }}>
                    <Button onClick={async () => {
                      setLoading(true);
                      setPage(0);
                      await loadWisdomWord();
                    }}><Search /></Button>
                  </div>
                  <div style={{ paddingLeft: 5 }}>
                    <Button onClick={async () => {
                      setLoading(true);
                      setSearchQuery("");
                      setResetSearch(1);
                      // await loadWisdomWord();
                    }}><ArrowClockwise /></Button>
                  </div>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <div style={{ flex: 1 }} className="table-header-content">#</div>
                  <div style={{ flex: 4 }} className="table-header-content">{t("index_wisdom.table_name1")}</div>
                  <div style={{ flex: 1 }} className="table-header-content">{t("index_wisdom.table_name2")}</div>
                  <div style={{ flex: 1 }} className="table-header-content">{t("index_wisdom.table_name3")}</div>
                </div>
                {
                  listWord.map((word, index) => {
                    return (<div className="table-body">
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                      <div style={{ flex: 4 }} className="table-body-content"><p>{convert(word.description, options)}</p></div>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                        <div style={{ padding: 5 }}>
                          <Button variant="primary" onClick={() => {
                            navigate("/WisdomWord/Detail", { state: { wordId: word.id } });
                          }}><PencilFill />
                          </Button>
                        </div></div>
                      <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                        <div style={{ padding: 5 }}>
                          <Button disabled={!deletedButton} variant="danger" onClick={() => {
                            if (window.confirm(`${t("index_wisdom.delete_confirmation")} '${convert(word.description, options)}'?`))
                              setRemoveId(word.id)
                          }}><Trash />
                          </Button>
                        </div></div>


                    </div>)
                  }
                  )
                }
              </div>
              <div style={{
                paddingTop: 20,
              }}>

              </div>
              <Paginations
                itemPerPage={itemPerPage}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
                setItemPerPage={setItemPerPage}
                itemPerPageSelection={itemPerPageSelection}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                orderBySelection={orderByList}
                isDescActive={descending}
                setIsDescActive={setDescending}
                setIsSearched={setIsSearched}
              />
              <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
              />
            </div>
          } />
      </Container>
    </>
  );

}