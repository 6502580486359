import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById, insertUpdateGeneralOverhaulDataTable, getDataTableByGeneralOverhaulId } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../Components/NavBar";
import ContainerBox from "../../../Components/ContainerBox";
import ButtonForm from "../../../Components/ButtonForm";
import Loading from "../../../Components/Loading";
import TextBox from "../../../Components/Textbox";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function GeneralOverhaulTableDataPage() {

    const [errorMessage, setErrorMessage] = useState("");
    const [generalOverhaulId, setGeneralOverhaulId] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [listDetailDimensionCylinderLiner, setListDetailDymensionCylinderLiner] = useState([]);
    const [dimensionCylinderLiner, setDimensionCylinderLiner] = useState({
        id: 0,
        nominal_diameter: "",
        upper_limit: "",
        lower_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Dimensions Of Cylinder Liner"
    });
    const [listClearanceOfPistonRingOld, setListClearanceOfPistonRingOld] = useState([]);
    const [listPistonRingOld, setListPistonRingOld] = useState({});
    const [clearanceOfPistonRingOld, setClearanceOfPistonRingOld] = useState({
        id: 0,
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Clearance OF Piston Ring(old)",
        clearance_a: "",
        clearance_b: "",
        clearance_c: ""
    });
    const [listPinPiston, setListPinPiston] = useState([]);
    const [pinPiston, setPinPiston] = useState({
        id: 0,
        nominal_diameter: "",
        a_boss: "",
        b_bush: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Pin Piston"
    });
    const [listPistonBore, setListPistonBore] = useState([]);
    const [pistonBore, setPistonBore] = useState({
        id: 0,
        nominal_diameter: "",
        upper_limit: "",
        lower_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Pin Piston Bore"
    });
    const [listConnectingRodBushingOld, setListConnectingRodBushingOld] = useState([]);
    const [connectingRodBushingOld, setConnectingRodBushingOld] = useState({
        id: 0,
        nominal_diameter: "",
        upper_limit: "",
        lower_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Connecting Rod Bushing (Old)"
    });
    const [listClearanceOfCrankPinBearingJournals, setListClearanceOfCrankpinBearingJournals] = useState([]);
    const [clearanceOfCrankpinBearingJournals, setClearanceOfCrankpinBearingJournals] = useState({
        id: 0,
        nominal_diameter: "",
        upper_limit: "",
        lower_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Clearance of Crankpin Bearing Journals (Old)"
    });
    const [listDimensionOfCrankPinJournal, setListDimensionOfCrankPinJournal] = useState([]);
    const [dimensionOfCrankPinJournal, setDimensionOfCrankPinJournal] = useState({
        id: 0,
        nominal_diameter: "",
        upper_limit: "",
        lower_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Dimension Of Crank Pin Journal"
    });
    const [listIntakeValveExhaust, setListIntakeValeExhaust] = useState([]);
    const [intakeValveExhaust, setIntakeValveExhaust] = useState({
        id: 0,
        nominal_thickness: "",
        repair_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: " Intake Valve and Exhaust Valve Face Thickness (Old)"
    });
    const [listClearanceOfMainBearingJournalNew, setListClearanceOfMainBearingJournalNew] = useState([]);
    const [clearanceOfMainBearingJournalNew, setClearanceOfMainBearingJournalNew] = useState({
        id: 0,
        nominal_diameter: "",
        upper_limit: "",
        lower_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Clearance of Main Bearings and Journals (New)"
    });
    const [listClearanceOfCrankpinBearingJournalsNew, setListClearanceOfCrankpinBearingJournalsNew] = useState([]);
    const [clearanceOfCrankpinBearingJournalsNew, setClearanceOfCrankpinBearingJournalsNew] = useState({
        id: 0,
        nominal_diameter: "",
        upper_limit: "",
        lower_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Clearance of Crankpin Bearing Journals (New)"
    });
    const [listConnectingRodBushingNew, setListConnectingRodBushingNew] = useState([]);
    const [connectingRodBushingNew, setConnectingRodBushingNew] = useState({
        id: 0,
        nominal_diameter: "",
        upper_limit: "",
        lower_limit: "",
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Connecting Rod Bushing (New)"
    });
    const [listClearanceOfPistonRingNew, setListClearanceOfPistionRingNew] = useState([]);
    const [listPistonRingNew, setListPistonRingNew] = useState({});
    const [clearanceOfPistonRingNew, setClearanceOfPistonRingNew] = useState({
        id: 0,
        comment: "",
        general_overhaul_id: location.state.generalOverhaulId,
        name: "Clearance OF Piston Ring(New)",
        clearance_a: "",
        clearance_b: "",
        clearance_c: ""
    });


    const { t, i18n } = useTranslation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setGeneralOverhaulId(location.state.generalOverhaulId)

            }
        }
        checkCookies();

    }, []);

    useEffect(()=>{
        console.log(listDetailDimensionCylinderLiner);
    },[listDetailDimensionCylinderLiner])

    useEffect(() => {
        console.log(connectingRodBushingNew)
    }, [connectingRodBushingNew])
    useEffect(() => {
        if (generalOverhaulId !== "" && generalOverhaulId !== undefined) {
            initDataTable()
        }
    }, [generalOverhaulId])

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "GeneralOverhaul", cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
            setUpdatedButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }

    const saveData = async () => {
        try {
            let dataTableTmp = {};
            dataTableTmp.dimensionOfCylinderLiners = dimensionCylinderLiner;
            dataTableTmp.detailDimensionOfCylinderLiners = listDetailDimensionCylinderLiner;
            dataTableTmp.clearanceOfPistonRingOlds = clearanceOfPistonRingOld;
            dataTableTmp.detailClearanceOfPistonRingOlds = listClearanceOfPistonRingOld;
            dataTableTmp.pinPistons = pinPiston;
            dataTableTmp.detailPinPistons = listPinPiston;
            dataTableTmp.pistonBores = pistonBore;
            dataTableTmp.detailPistonBores = listPistonBore;
            dataTableTmp.connectingRodBushingOlds = connectingRodBushingOld;
            dataTableTmp.detailConnectingRodBushingOlds = listConnectingRodBushingOld;
            dataTableTmp.clearanceOfCrankPinBearingJournalsOlds = clearanceOfCrankpinBearingJournals
            dataTableTmp.detailClearanceOfCrankPinBearingJournalsOlds = listClearanceOfCrankPinBearingJournals
            dataTableTmp.dimensionOfCrankpinJournals = dimensionOfCrankPinJournal
            dataTableTmp.detailDimensionOfCrankPinJournals = listDimensionOfCrankPinJournal
            dataTableTmp.intakeValves = intakeValveExhaust
            dataTableTmp.detailIntakeValves = listIntakeValveExhaust
            dataTableTmp.clearanceOfMainBearingsAndJournalNews = clearanceOfMainBearingJournalNew;
            dataTableTmp.detailClearanceOfMainBearingsAndJournalNews = listClearanceOfMainBearingJournalNew
            dataTableTmp.clearanceOfCrankPinBearingJournalsNews = clearanceOfCrankpinBearingJournalsNew;
            dataTableTmp.detailClearanceOfCrankPinBearingJournalsNews = listClearanceOfCrankpinBearingJournalsNew;
            dataTableTmp.connectingRodBushingNews = connectingRodBushingNew
            dataTableTmp.detailConnectingRodBushingNews = listConnectingRodBushingNew
            dataTableTmp.clearanceOfPistonRingNews = clearanceOfPistonRingNew
            dataTableTmp.detailClearanceOfPistonRingNews = listClearanceOfPistonRingNew

            let response = await insertUpdateGeneralOverhaulDataTable(cookies.token, dataTableTmp, location.state.generalOverhaulId);
            if (response.error_code === 0) {
                alert('Berhasil Menyimpan Data');
                initDataTable();
            } else {
                alert('Gagal Menyimpan Data');
            }
            
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const initDataTable = async () => {
        try {
            let response = await getDataTableByGeneralOverhaulId(cookies.token, generalOverhaulId);
            console.log(response);
            if (response) {
                if (response.dimensionOfCylinderLiner.id) {
                    setDimensionCylinderLiner(response.dimensionOfCylinderLiner)
                    setListDetailDymensionCylinderLiner(response.listDimensionOfCylinderLiner);
                    setClearanceOfPistonRingOld(response.clearanceOfPistonRingOld);
                    setListClearanceOfPistonRingOld(response.listClearanceOfPistongRingOld);
                    setPinPiston(response.pinPiston);
                    setListPinPiston(response.listPinPiston);
                    setPistonBore(response.pistonBore);
                    setListPistonBore(response.listPistonBore);
                    setConnectingRodBushingOld(response.connectingRodBushingOld);
                    setListConnectingRodBushingOld(response.listConnectingRodBushingOld);
                    setClearanceOfCrankpinBearingJournals(response.clearanceOfCrankpinBearingJournalsOld);
                    setListClearanceOfCrankpinBearingJournals(response.listClearanceOfCrankpinBearingJournalsOld);
                    setDimensionOfCrankPinJournal(response.dimensionOfCrankpinJournal);
                    setListDimensionOfCrankPinJournal(response.listDimensionOfCrankPinJournal);
                    setIntakeValveExhaust(response.intakeValve);
                    setListIntakeValeExhaust(response.listIntakeValve);
                    setClearanceOfMainBearingJournalNew(response.clearanceOfMainBearingAndJournals);
                    setListClearanceOfMainBearingJournalNew(response.listClearanceOfMainBearingAndJournals);
                    setClearanceOfCrankpinBearingJournalsNew(response.clearanceOfCrankPinBearingJournalsNew);
                    setListClearanceOfCrankpinBearingJournalsNew(response.listClearanceOfCrankPinBearingJournalsNew);
                    setConnectingRodBushingNew(response.connectingRodBushingNew);
                    setListConnectingRodBushingNew(response.listConnectingRodBushingNew);
                    setClearanceOfPistonRingNew(response.clearanceOfPistonRingNew);
                    setListClearanceOfPistionRingNew(response.listClearanceOfPistonRingNew)
                } else {
                    setListDetailDymensionCylinderLiner(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        dimension_aa_1: null,
                        dimension_aa_2: null,
                        dimension_aa_3: null,
                        dimension_bb_1: null,
                        dimension_bb_2: null,
                        dimension_bb_3: null
                    })));
                    setListClearanceOfCrankpinBearingJournals(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        crank_pin_aa_1: null,
                        crank_pin_aa_2: null,
                        crank_pin_aa_3: null,
                        crank_pin_bb_1: null,
                        crank_pin_bb_2: null,
                        crank_pin_bb_3: null
                    })));
                    setListClearanceOfCrankpinBearingJournalsNew(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        crank_pin_aa_1: null,
                        crank_pin_aa_2: null,
                        crank_pin_cc_1: null,
                        crank_pin_bb_1: null,
                        crank_pin_bb_2: null,
                        crank_pin_cc_2: null
                    })));
                    setListClearanceOfMainBearingJournalNew(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        bearing_aa_1: null,
                        bearing_aa_2: null,
                        bearing_bb_1: null,
                        bearing_bb_2: null,
                        bearing_cc_1: null,
                        bearing_cc_2: null
                    })));
                    setListClearanceOfPistionRingNew(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        ring_a: null,
                        ring_b: null,
                        ring_c: null,
                    })));
                    setListClearanceOfPistonRingOld(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        ring_a: null,
                        ring_b: null,
                        ring_c: null,
                    })));
                    setListConnectingRodBushingNew(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        rod_bushing_aa_1: null,
                        rod_bushing_aa_2: null,
                        rod_bushing_bb_1: null,
                        rod_bushing_bb_2: null,
                        clearance: null,
                    })));
                    setListConnectingRodBushingOld(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        rod_bushing_aa_1: null,
                        rod_bushing_aa_2: null,
                        rod_bushing_bb_1: null,
                        rod_bushing_bb_2: null,
                        clearance: null,
                    })));
                    setListIntakeValeExhaust(Array.from({ length: location.state.numberOfCylinder }, (_, { }) => ({
                        thickness_c_1: null,
                        thickness_c_2: null,
                        thickness_e_1: null,
                        thickness_e_2: null,
                    })));
                    setListPinPiston(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        pin_aa_1: null,
                        pin_aa_2: null,
                        pin_aa_3: null,
                        pin_bb_1: null,
                        pin_bb_2: null,
                        pin_bb_3: null
                    })));
                    setListPistonBore(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        bore_1a: null,
                        bore_1b: null,
                        bore_2a: null,
                        bore_2b: null,
                    })));
                    setListDimensionOfCrankPinJournal(Array.from({ length: location.state.numberOfCylinder }, (_, index) => ({
                        pin_aa_1: null,
                        pin_aa_2: null,
                        pin_aa_3: null,
                        pin_bb_1: null,
                        pin_bb_2: null,
                        pin_bb_3: null
                    })));
                }
            }
            setLoading(false)
        } catch (exception) {
            console.log(exception)
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`Table Data`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/GeneralOverhaul"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                            width: "100%",
                                            flexWrap: "nowrap"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexWrap: "nowrap",
                                                flexDirection: "column",
                                                borderWidth: 2,
                                                borderColor: "rgba(3, 30, 103, 1)",
                                                borderRadius: 10,
                                                borderStyle: "solid",

                                            }}>
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    saveData();
                                                    setLoading(true);
                                                    setDisabledButton(true);
                                                    setTimeout(function () {
                                                        window.scrollTo(0, 0);
                                                    }, 2);
                                                }}>
                                                    <Tabs
                                                        defaultActiveKey="page1"
                                                        // transition={false}
                                                        id="noanim-tab-example"
                                                        className="mb-3"
                                                        style={{
                                                            backgroundColor: "rgba(3, 30, 103, 1)",
                                                            borderRadius: 5,

                                                        }}
                                                    >

                                                        <Tab eventKey="page1" title={`Page 1`}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Dimensions Of Cylinder Liner`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={dimensionCylinderLiner.nominal_diameter} onChange={(e) => {
                                                                                setDimensionCylinderLiner({ ...dimensionCylinderLiner, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Upper Limit</Form.Label>
                                                                            <Form.Control value={dimensionCylinderLiner.upper_limit} onChange={(e) => {
                                                                                console.log(e.target.value)
                                                                                setDimensionCylinderLiner({ ...dimensionCylinderLiner, upper_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Lower Limit</Form.Label>
                                                                            <Form.Control value={dimensionCylinderLiner.lower_limit} onChange={(e) => {
                                                                                setDimensionCylinderLiner({ ...dimensionCylinderLiner, lower_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={dimensionCylinderLiner.comment} onChange={(e) => {
                                                                                setDimensionCylinderLiner({ ...dimensionCylinderLiner, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={dimensionCylinderLiner.is_show}
                                                                                onClick={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setDimensionCylinderLiner({ ...dimensionCylinderLiner, is_show: true })
                                                                                    } else {
                                                                                        setDimensionCylinderLiner({ ...dimensionCylinderLiner, is_show: false })
                                                                                    }
                                                                                }}
                                                                            >

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Linear Number</p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>AA(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>3</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>BB(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>3</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check checked={dimensionCylinderLiner.same_value}  onClick={(e) => {
                                                                                        let newList
                                                                                        if (e.target.checked) {
                                                                                            setDimensionCylinderLiner({...dimensionCylinderLiner, same_value:true})
                                                                                            newList = listDetailDimensionCylinderLiner.map(obj => ({
                                                                                                ...obj, same_value: true, dimension_aa_3: obj.dimension_aa_1,
                                                                                                dimension_aa_2: obj.dimension_aa_1,
                                                                                                dimension_bb_1: obj.dimension_aa_1,
                                                                                                dimension_bb_2: obj.dimension_aa_1,
                                                                                                dimension_bb_3: obj.dimension_aa_1
                                                                                            }))
                                                                                        } else {
                                                                                            newList = listDetailDimensionCylinderLiner.map(obj => ({ ...obj, same_value: false }))
                                                                                            setDimensionCylinderLiner({...dimensionCylinderLiner, same_value:false})
                                                                                        }
                                                                                        setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                    }}  >

                                                                                    </Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listDetailDimensionCylinderLiner.map((dimensionCylinder, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={dimensionCylinder.dimension_aa_1} onChange={(e) => {
                                                                                                            let newList = listDetailDimensionCylinderLiner;
                                                                                                            if (dimensionCylinder.same_value) {
                                                                                                                newList[index].dimension_aa_1 = e.target.value
                                                                                                                newList[index].dimension_aa_2 = e.target.value
                                                                                                                newList[index].dimension_aa_3 = e.target.value
                                                                                                                newList[index].dimension_bb_1 = e.target.value
                                                                                                                newList[index].dimension_bb_2 = e.target.value
                                                                                                                newList[index].dimension_bb_3 = e.target.value
                                                                                                            } else {
                                                                                                                newList[index].dimension_aa_1 = e.target.value
                                                                                                            }

                                                                                                            setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={dimensionCylinder.dimension_aa_2} onChange={(e) => {
                                                                                                        let newList = listDetailDimensionCylinderLiner;
                                                                                                        newList[index].dimension_aa_2 = e.target.value
                                                                                                        setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={dimensionCylinder.dimension_aa_3} onChange={(e) => {
                                                                                                        let newList = listDetailDimensionCylinderLiner;
                                                                                                        newList[index].dimension_aa_3 = e.target.value
                                                                                                        setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={dimensionCylinder.dimension_bb_1} onChange={(e) => {
                                                                                                        let newList = listDetailDimensionCylinderLiner;
                                                                                                        newList[index].dimension_bb_1 = e.target.value
                                                                                                        setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={dimensionCylinder.dimension_bb_2} onChange={(e) => {
                                                                                                        let newList = listDetailDimensionCylinderLiner;
                                                                                                        newList[index].dimension_bb_2 = e.target.value
                                                                                                        setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={dimensionCylinder.dimension_bb_3} onChange={(e) => {
                                                                                                        let newList = listDetailDimensionCylinderLiner;
                                                                                                        newList[index].dimension_bb_3 = e.target.value
                                                                                                        setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                            </div></div>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>
                                                                                                <Form.Check onClick={(e) => {
                                                                                                    if (e.target.checked) {
                                                                                                        let newList = listDetailDimensionCylinderLiner;
                                                                                                        newList[index].same_value = true
                                                                                                        newList[index].dimension_aa_3 = dimensionCylinder.dimension_aa_1
                                                                                                        newList[index].dimension_aa_2 = dimensionCylinder.dimension_aa_1
                                                                                                        newList[index].dimension_bb_1 = dimensionCylinder.dimension_aa_1
                                                                                                        newList[index].dimension_bb_2 = dimensionCylinder.dimension_aa_1
                                                                                                        newList[index].dimension_bb_3 = dimensionCylinder.dimension_aa_1
                                                                                                        setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                                    } else {
                                                                                                        let newList = listDetailDimensionCylinderLiner;
                                                                                                        newList[index].same_value = false;
                                                                                                        setListDetailDymensionCylinderLiner(newList.slice(0))
                                                                                                    }
                                                                                                }} checked={dimensionCylinder.same_value} ></Form.Check>
                                                                                            </p></div>



                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Clearance Of Piston Ring(Old)`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>



                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={clearanceOfPistonRingOld.comment} onChange={(e) => {
                                                                                setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={clearanceOfPistonRingOld.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, is_show: true })
                                                                                } else {
                                                                                    setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{ flex: 1 }} className="table-header-content"></div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">A</div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">B</div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">C</div>
                                                                                <div style={{ flex: 2 }} className="table-header-content">
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        flexWrap: "nowrap"
                                                                                    }}>
                                                                                        <div style={{ flex: 1 }}>Same Value</div>
                                                                                        <div style={{ flex: 1, justifyContent: "center", display: "flex" }}><Form.Check checked={clearanceOfPistonRingOld.all_checked} onClick={(e) => {
                                                                                            if (e.target.checked) {
                                                                                                setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, all_checked:true, same_value: true, clearance_b: clearanceOfPistonRingOld.clearance_a, clearance_c: clearanceOfPistonRingOld.clearance_a })
                                                                                                let newList = listClearanceOfPistonRingOld.map(obj => ({ ...obj, same_value: true, ring_b: obj.ring_a, ring_c: obj.ring_a }))
                                                                                                setListClearanceOfPistonRingOld(newList.slice(0));
                                                                                            } else {
                                                                                                setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, all_checked:false, same_value: false })
                                                                                                let newList = listClearanceOfPistonRingOld.map(obj => ({ ...obj, same_value: false }))
                                                                                                setListClearanceOfPistonRingOld(newList.slice(0));
                                                                                            }
                                                                                        }}></Form.Check></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="table-body" >
                                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>Clearance</p></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearanceOfPistonRingOld.clearance_a} onChange={(e) => {
                                                                                    if (clearanceOfPistonRingOld.same_value) {
                                                                                        setClearanceOfPistonRingOld({
                                                                                            ...clearanceOfPistonRingOld, clearance_a: e.target.value,
                                                                                            clearance_b: e.target.value,
                                                                                            clearance_c: e.target.value
                                                                                        })
                                                                                    } else {
                                                                                        setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, clearance_a: e.target.value })
                                                                                    }

                                                                                }}></Form.Control></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearanceOfPistonRingOld.clearance_b} onChange={(e) => {
                                                                                    setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, clearance_b: e.target.value })
                                                                                }}></Form.Control></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearanceOfPistonRingOld.clearance_c} onChange={(e) => {
                                                                                    setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, clearance_c: e.target.value })
                                                                                }}></Form.Control></div>
                                                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content" ><p>
                                                                                    <Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, clearance_b: clearanceOfPistonRingOld.clearance_a, clearance_c: clearanceOfPistonRingOld.clearance_a, same_value: true })
                                                                                        } else {
                                                                                            setClearanceOfPistonRingOld({ ...clearanceOfPistonRingOld, same_value: false })
                                                                                        }
                                                                                    }} checked={clearanceOfPistonRingOld.same_value}></Form.Check>
                                                                                </p></div>

                                                                            </div>
                                                                            {
                                                                                listClearanceOfPistonRingOld.map((clearancePistonRing, index) => {
                                                                                    return (
                                                                                        <div className="table-body" key={index}>
                                                                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                            <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearancePistonRing.ring_a} onChange={(e) => {
                                                                                                let newList = listClearanceOfPistonRingOld;
                                                                                                if (clearancePistonRing.same_value) {
                                                                                                    newList[index].ring_a = e.target.value;
                                                                                                    newList[index].ring_b = e.target.value;
                                                                                                    newList[index].ring_c = e.target.value;
                                                                                                } else {
                                                                                                    newList[index].ring_a = e.target.value;
                                                                                                }

                                                                                                setListClearanceOfPistonRingOld(newList.slice(0));
                                                                                            }}></Form.Control></div>
                                                                                            <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearancePistonRing.ring_b} onChange={(e) => {
                                                                                                let newList = listClearanceOfPistonRingOld;
                                                                                                newList[index].ring_b = e.target.value;
                                                                                                setListClearanceOfPistonRingOld(newList.slice(0));
                                                                                            }}></Form.Control></div>
                                                                                            <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearancePistonRing.ring_c} onChange={(e) => {
                                                                                                let newList = listClearanceOfPistonRingOld;
                                                                                                newList[index].ring_c = e.target.value;
                                                                                                setListClearanceOfPistonRingOld(newList.slice(0));
                                                                                            }}></Form.Control></div>
                                                                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                                                <Form.Check checked={clearancePistonRing.same_value} onClick={(e) => {
                                                                                                    if (e.target.checked) {
                                                                                                        let newList = listClearanceOfPistonRingOld;
                                                                                                        newList[index].ring_b = clearancePistonRing.ring_a
                                                                                                        newList[index].same_value = true
                                                                                                        newList[index].ring_c = clearancePistonRing.ring_a
                                                                                                        setListClearanceOfPistonRingOld(newList.slice(0));
                                                                                                    } else {
                                                                                                        let newList = listClearanceOfPistonRingOld;
                                                                                                        newList[index].same_value = false
                                                                                                        setListClearanceOfPistonRingOld(newList.slice(0));
                                                                                                    }
                                                                                                }}></Form.Check>
                                                                                            </div>


                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Pin Piston`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={pinPiston.nominal_diameter} onChange={(e) => {
                                                                                setPinPiston({ ...pinPiston, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>A(Boss)</Form.Label>
                                                                            <Form.Control value={pinPiston.a_boss} onChange={(e) => {
                                                                                setPinPiston({ ...pinPiston, a_boss: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>B(Bush)</Form.Label>
                                                                            <Form.Control value={pinPiston.b_bush} onChange={(e) => {
                                                                                setPinPiston({ ...pinPiston, b_bush: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={pinPiston.comment} onChange={(e) => {
                                                                                setPinPiston({ ...pinPiston, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={pinPiston.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setPinPiston({ ...pinPiston, is_show: true })
                                                                                } else {
                                                                                    setPinPiston({ ...pinPiston, is_show: false })
                                                                                }
                                                                            }} >

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>A</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>3</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>B</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>3</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 2,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Clearance</p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 2,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setPinPiston({...pinPiston, same_value:true})
                                                                                            let newList = listPinPiston.map(obj => ({
                                                                                                ...obj, same_value: true, pin_aa_2: obj.pin_aa_1,
                                                                                                pin_aa_3: obj.pin_aa_1,
                                                                                                pin_bb_1: obj.pin_aa_1,
                                                                                                pin_bb_2: obj.pin_aa_1,
                                                                                                pin_bb_3: obj.pin_aa_1,
                                                                                                pin_clearance_b: obj.pin_aa_1

                                                                                            }))
                                                                                            setListPinPiston(newList.slice(0));
                                                                                        } else {
                                                                                            setPinPiston({...pinPiston, same_value:false})
                                                                                            let newList = listPinPiston.map(obj => ({ ...obj, same_value: false }))
                                                                                            setListPinPiston(newList.slice(0));
                                                                                        }
                                                                                    }} checked={pinPiston.same_value}></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listPinPiston.map((pistons, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={pistons.pin_aa_1} onChange={(e) => {
                                                                                                            let newList = listPinPiston;
                                                                                                            if (pistons.same_value) {
                                                                                                                newList[index].pin_aa_1 = e.target.value
                                                                                                                newList[index].pin_aa_2 = e.target.value
                                                                                                                newList[index].pin_aa_3 = e.target.value
                                                                                                                newList[index].pin_bb_1 = e.target.value
                                                                                                                newList[index].pin_bb_2 = e.target.value
                                                                                                                newList[index].pin_bb_3 = e.target.value
                                                                                                                newList[index].pin_clearance_b = e.target.value
                                                                                                            } else {
                                                                                                                newList[index].pin_aa_1 = e.target.value
                                                                                                            }

                                                                                                            setListPinPiston(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={pistons.pin_aa_2} onChange={(e) => {
                                                                                                        let newList = listPinPiston;
                                                                                                        newList[index].pin_aa_2 = e.target.value
                                                                                                        setListPinPiston(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={pistons.pin_aa_3} onChange={(e) => {
                                                                                                        let newList = listPinPiston;
                                                                                                        newList[index].pin_aa_3 = e.target.value
                                                                                                        setListPinPiston(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={pistons.pin_bb_1} onChange={(e) => {
                                                                                                        let newList = listPinPiston;
                                                                                                        newList[index].pin_bb_1 = e.target.value
                                                                                                        setListPinPiston(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={pistons.pin_bb_2} onChange={(e) => {
                                                                                                        let newList = listPinPiston;
                                                                                                        newList[index].pin_bb_2 = e.target.value
                                                                                                        setListPinPiston(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={pistons.pin_bb_3} onChange={(e) => {
                                                                                                        let newList = listPinPiston;
                                                                                                        newList[index].pin_bb_3 = e.target.value
                                                                                                        setListPinPiston(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 2,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{ padding: 5 }}><Form.Control required type="number" value={pistons.pin_clearance_b} onChange={(e) => {
                                                                                                let newList = listPinPiston;
                                                                                                newList[index].pin_clearance_b = e.target.value
                                                                                                setListPinPiston(newList.slice(0))
                                                                                            }}></Form.Control></div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 2,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: "center",
                                                                                            alignContent: "center",
                                                                                            alignItems: "center"
                                                                                        }} >
                                                                                            {/* <div style={{ padding: 5, justifyContent:"center" }}> */}
                                                                                            <Form.Check checked={pistons.same_value} onClick={(e) => {
                                                                                                let newList = listPinPiston;
                                                                                                if (e.target.checked) {
                                                                                                    newList[index].same_value = true
                                                                                                    newList[index].pin_aa_2 = pistons.pin_aa_1
                                                                                                    newList[index].pin_aa_3 = pistons.pin_aa_1
                                                                                                    newList[index].pin_bb_1 = pistons.pin_aa_1
                                                                                                    newList[index].pin_bb_2 = pistons.pin_aa_1
                                                                                                    newList[index].pin_bb_3 = pistons.pin_aa_1
                                                                                                    newList[index].pin_clearance_b = pistons.pin_aa_1
                                                                                                } else {
                                                                                                    newList[index].same_value = false
                                                                                                }
                                                                                                setListPinPiston(newList.slice(0));
                                                                                            }}></Form.Check>
                                                                                            {/* </div> */}
                                                                                        </div>



                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Piston Bore`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={pistonBore.nominal_diameter} onChange={(e) => {
                                                                                setPistonBore({ ...pistonBore, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Upper Limit</Form.Label>
                                                                            <Form.Control value={pistonBore.upper_limit} onChange={(e) => {
                                                                                setPistonBore({ ...pistonBore, upper_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Lower Limit</Form.Label>
                                                                            <Form.Control value={pistonBore.lower_limit} onChange={(e) => {
                                                                                setPistonBore({ ...pistonBore, lower_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={pistonBore.comment} onChange={(e) => {
                                                                                setPistonBore({ ...pistonBore, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={pistonBore.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setPistonBore({ ...pistonBore, is_show: true })
                                                                                } else {
                                                                                    setPistonBore({ ...pistonBore, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>1(MM)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>A</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>B</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>2(MM)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>A</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>B</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setPistonBore({...pistonBore, same_value:true})
                                                                                            let newList = listPistonBore.map(obj => ({
                                                                                                ...obj, same_value: true,
                                                                                                bore_1b: obj.bore_1a,
                                                                                                bore_2a: obj.bore_1a,
                                                                                                bore_2b: obj.bore_1a
                                                                                            }))
                                                                                            setListPistonBore(newList.slice(0))
                                                                                        } else {
                                                                                            setPistonBore({...pistonBore, same_value:false})
                                                                                            let newList = listPistonBore.map(obj => ({ ...obj, same_value: false }))
                                                                                            setListPistonBore(newList.slice(0));
                                                                                        }
                                                                                    }} checked={pistonBore.same_value}></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listPistonBore.map((pistonsBores, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={pistonsBores.bore_1a} onChange={(e) => {
                                                                                                            let newList = listPistonBore;
                                                                                                            newList[index].bore_1a = e.target.value
                                                                                                            if (pistonsBores.same_value) {
                                                                                                                newList[index].bore_1b = e.target.value;
                                                                                                                newList[index].bore_2a = e.target.value;
                                                                                                                newList[index].bore_2b = e.target.value;
                                                                                                            }

                                                                                                            setListPistonBore(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={pistonsBores.bore_1b} onChange={(e) => {
                                                                                                        let newList = listPistonBore;
                                                                                                        newList[index].bore_1b = e.target.value
                                                                                                        setListPistonBore(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={pistonsBores.bore_2a} onChange={(e) => {
                                                                                                        let newList = listPistonBore;
                                                                                                        newList[index].bore_2a = e.target.value
                                                                                                        setListPistonBore(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={pistonsBores.bore_2b} onChange={(e) => {
                                                                                                        let newList = listPistonBore;
                                                                                                        newList[index].bore_2b = e.target.value
                                                                                                        setListPistonBore(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p><Form.Check checked={pistonsBores.same_value} onClick={(e) => {
                                                                                            let newList = listPistonBore
                                                                                            if (e.target.checked) {
                                                                                                newList[index].same_value = true;
                                                                                                newList[index].bore_1b = pistonsBores.bore_1a;
                                                                                                newList[index].bore_2a = pistonsBores.bore_1a;
                                                                                                newList[index].bore_2b = pistonsBores.bore_1a;
                                                                                            } else {
                                                                                                newList[index].same_value = false
                                                                                            }
                                                                                            setListPistonBore(newList.slice(0))
                                                                                        }}></Form.Check></p></div>

                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>

                                                        </Tab>
                                                        <Tab eventKey="page2" title={`Page 2`}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Connecting Rod Bushing (Old)`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={connectingRodBushingOld.nominal_diameter} onChange={(e) => {
                                                                                setConnectingRodBushingOld({ ...connectingRodBushingOld, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Upper Limit</Form.Label>
                                                                            <Form.Control value={connectingRodBushingOld.upper_limit} onChange={(e) => {
                                                                                setConnectingRodBushingOld({ ...connectingRodBushingOld, upper_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Lower Limit</Form.Label>
                                                                            <Form.Control value={connectingRodBushingOld.lower_limit} onChange={(e) => {
                                                                                setConnectingRodBushingOld({ ...connectingRodBushingOld, lower_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={connectingRodBushingOld.comment} onChange={(e) => {
                                                                                setConnectingRodBushingOld({ ...connectingRodBushingOld, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={connectingRodBushingOld.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setConnectingRodBushingOld({ ...connectingRodBushingOld, is_show: true })
                                                                                } else {
                                                                                    setConnectingRodBushingOld({ ...connectingRodBushingOld, is_show: false })
                                                                                }

                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>AA(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>BB(MM)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 2,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Clearance</p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 2,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setConnectingRodBushingOld({...connectingRodBushingOld, same_value:true})
                                                                                            let newList = listConnectingRodBushingOld.map(obj => ({
                                                                                                ...obj, same_value: true,
                                                                                                rod_bushing_aa_2: obj.rod_bushing_aa_1,
                                                                                                rod_bushing_bb_1: obj.rod_bushing_aa_1,
                                                                                                rod_bushing_bb_2: obj.rod_bushing_aa_1,
                                                                                                clearance: obj.rod_bushing_aa_1
                                                                                            }))
                                                                                            setListConnectingRodBushingOld(newList.slice(0))
                                                                                        } else {
                                                                                            setConnectingRodBushingOld({...connectingRodBushingOld, same_value:false})
                                                                                            let newList = listConnectingRodBushingOld.map(obj => ({ ...obj, same_value: false }))
                                                                                            setListConnectingRodBushingOld(newList.slice(0));
                                                                                        }
                                                                                    }} checked={connectingRodBushingOld.same_value}></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listConnectingRodBushingOld.map((connectingRodBushing, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control type="number" required value={connectingRodBushing.rod_bushing_aa_1} onChange={(e) => {
                                                                                                            let newList = listConnectingRodBushingOld;
                                                                                                            newList[index].rod_bushing_aa_1 = e.target.value
                                                                                                            if (connectingRodBushing.same_value) {
                                                                                                                newList[index].rod_bushing_aa_2 = e.target.value;
                                                                                                                newList[index].rod_bushing_bb_1 = e.target.value;
                                                                                                                newList[index].rod_bushing_bb_2 = e.target.value;
                                                                                                                newList[index].clearance = e.target.value
                                                                                                            }
                                                                                                            setListConnectingRodBushingOld(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={connectingRodBushing.rod_bushing_aa_2} onChange={(e) => {
                                                                                                        let newList = listConnectingRodBushingOld;
                                                                                                        newList[index].rod_bushing_aa_2 = e.target.value
                                                                                                        setListConnectingRodBushingOld(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={connectingRodBushing.rod_bushing_bb_1} onChange={(e) => {
                                                                                                        let newList = listConnectingRodBushingOld;
                                                                                                        newList[index].rod_bushing_bb_1 = e.target.value
                                                                                                        setListConnectingRodBushingOld(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={connectingRodBushing.rod_bushing_bb_2} onChange={(e) => {
                                                                                                        let newList = listConnectingRodBushingOld;
                                                                                                        newList[index].rod_bushing_bb_2 = e.target.value
                                                                                                        setListConnectingRodBushingOld(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 2,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{ padding: 5 }}><Form.Control required type="number" value={connectingRodBushing.clearance} onChange={(e) => {
                                                                                                let newList = listConnectingRodBushingOld;
                                                                                                newList[index].clearance = e.target.value
                                                                                                setListConnectingRodBushingOld(newList.slice(0))
                                                                                            }}></Form.Control></div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 2,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center"
                                                                                        }} >
                                                                                            <Form.Check checked={connectingRodBushing.same_value} onClick={(e) => {
                                                                                                let newList = listConnectingRodBushingOld
                                                                                                if (e.target.checked) {
                                                                                                    newList[index].rod_bushing_aa_2 = connectingRodBushing.rod_bushing_aa_1;
                                                                                                    newList[index].rod_bushing_bb_1 = connectingRodBushing.rod_bushing_aa_1;
                                                                                                    newList[index].rod_bushing_bb_2 = connectingRodBushing.rod_bushing_aa_1;
                                                                                                    newList[index].clearance = connectingRodBushing.rod_bushing_aa_1;
                                                                                                    newList[index].same_value = true
                                                                                                } else {
                                                                                                    newList[index].same_value = false
                                                                                                }
                                                                                                setListConnectingRodBushingOld(newList.slice(0));
                                                                                            }}></Form.Check>
                                                                                        </div>



                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Clearance Of Crankpin Bearing Journal Old`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={clearanceOfCrankpinBearingJournals.nominal_diameter} onChange={(e) => {
                                                                                setClearanceOfCrankpinBearingJournals({ ...clearanceOfCrankpinBearingJournals, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Upper Limit</Form.Label>
                                                                            <Form.Control value={clearanceOfCrankpinBearingJournals.upper_limit} onChange={(e) => {
                                                                                setClearanceOfCrankpinBearingJournals({ ...clearanceOfCrankpinBearingJournals, upper_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Lower Limit</Form.Label>
                                                                            <Form.Control value={clearanceOfCrankpinBearingJournals.lower_limit} onChange={(e) => {
                                                                                setClearanceOfCrankpinBearingJournals({ ...clearanceOfCrankpinBearingJournals, lower_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={clearanceOfCrankpinBearingJournals.comment} onChange={(e) => {
                                                                                setClearanceOfCrankpinBearingJournals({ ...clearanceOfCrankpinBearingJournals, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={clearanceOfCrankpinBearingJournals.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setClearanceOfCrankpinBearingJournals({ ...clearanceOfCrankpinBearingJournals, is_show: true })
                                                                                } else {
                                                                                    setClearanceOfCrankpinBearingJournals({ ...clearanceOfCrankpinBearingJournals, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>AA(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>3</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>BB(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>3</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setClearanceOfCrankpinBearingJournals({...clearanceOfCrankpinBearingJournals, same_value:true});
                                                                                            let newList = listClearanceOfCrankPinBearingJournals.map(obj => ({
                                                                                                ...obj, same_value: true,
                                                                                                crank_pin_aa_2: obj.crank_pin_aa_1,
                                                                                                crank_pin_aa_3: obj.crank_pin_aa_1,
                                                                                                crank_pin_bb_1: obj.crank_pin_aa_1,
                                                                                                crank_pin_bb_2: obj.crank_pin_aa_1,
                                                                                                crank_pin_bb_3: obj.crank_pin_aa_1,
                                                                                            }))
                                                                                            setListClearanceOfCrankpinBearingJournals(newList.slice(0))
                                                                                        } else {
                                                                                            setClearanceOfCrankpinBearingJournals({...clearanceOfCrankpinBearingJournals,  same_value:false});

                                                                                            let newList = listClearanceOfCrankPinBearingJournals.map(obj => ({ ...obj, same_value: false }));
                                                                                            setListClearanceOfCrankpinBearingJournals(newList.slice(0));
                                                                                        }
                                                                                    }} checked={clearanceOfCrankpinBearingJournals.same_value}></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listClearanceOfCrankPinBearingJournals.map((crankpinBearingJournals, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={crankpinBearingJournals.crank_pin_aa_1} onChange={(e) => {
                                                                                                            let newList = listClearanceOfCrankPinBearingJournals;
                                                                                                            newList[index].crank_pin_aa_1 = e.target.value;
                                                                                                            if (crankpinBearingJournals.same_value) {
                                                                                                                newList[index].crank_pin_aa_2 = e.target.value;
                                                                                                                newList[index].crank_pin_aa_3 = e.target.value;
                                                                                                                newList[index].crank_pin_bb_1 = e.target.value;
                                                                                                                newList[index].crank_pin_bb_2 = e.target.value;
                                                                                                                newList[index].crank_pin_bb_3 = e.target.value;
                                                                                                            }
                                                                                                            setListClearanceOfCrankpinBearingJournals(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankpinBearingJournals.crank_pin_aa_2} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankPinBearingJournals;
                                                                                                        newList[index].crank_pin_aa_2 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournals(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankpinBearingJournals.crank_pin_aa_3} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankPinBearingJournals;
                                                                                                        newList[index].crank_pin_aa_3 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournals(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankpinBearingJournals.crank_pin_bb_1} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankPinBearingJournals;
                                                                                                        newList[index].crank_pin_bb_1 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournals(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankpinBearingJournals.crank_pin_bb_2} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankPinBearingJournals;
                                                                                                        newList[index].crank_pin_bb_2 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournals(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankpinBearingJournals.crank_pin_bb_3} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankPinBearingJournals;
                                                                                                        newList[index].crank_pin_bb_3 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournals(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                            </div></div>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p><Form.Check checked={crankpinBearingJournals.same_value} onClick={(e) => {
                                                                                            let newList = listClearanceOfCrankPinBearingJournals
                                                                                            if (e.target.checked) {
                                                                                                newList[index].same_value = true;
                                                                                                newList[index].crank_pin_aa_2 = crankpinBearingJournals.crank_pin_aa_1
                                                                                                newList[index].crank_pin_aa_3 = crankpinBearingJournals.crank_pin_aa_1
                                                                                                newList[index].crank_pin_bb_1 = crankpinBearingJournals.crank_pin_aa_1
                                                                                                newList[index].crank_pin_bb_2 = crankpinBearingJournals.crank_pin_aa_1
                                                                                                newList[index].crank_pin_bb_3 = crankpinBearingJournals.crank_pin_aa_1
                                                                                            } else {
                                                                                                newList[index].same_value = false
                                                                                            }
                                                                                        }}></Form.Check></p></div>


                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Dimension Of Crank Pin Journal`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={dimensionOfCrankPinJournal.nominal_diameter} onChange={(e) => {
                                                                                setDimensionOfCrankPinJournal({ ...dimensionOfCrankPinJournal, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Upper Limit</Form.Label>
                                                                            <Form.Control value={dimensionOfCrankPinJournal.upper_limit} onChange={(e) => {
                                                                                setDimensionOfCrankPinJournal({ ...dimensionOfCrankPinJournal, upper_limit: e.target.value })
                                                                            }} ></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Lower Limit</Form.Label>
                                                                            <Form.Control value={dimensionOfCrankPinJournal.lower_limit} onChange={(e) => {
                                                                                setDimensionOfCrankPinJournal({ ...dimensionOfCrankPinJournal, lower_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={dimensionOfCrankPinJournal.comment} onChange={(e) => {
                                                                                setDimensionOfCrankPinJournal({ ...dimensionOfCrankPinJournal, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={dimensionOfCrankPinJournal.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setDimensionOfCrankPinJournal({ ...dimensionOfCrankPinJournal, is_show: true })
                                                                                } else {
                                                                                    setDimensionOfCrankPinJournal({ ...dimensionOfCrankPinJournal, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>AA(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>3</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>BB(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>3</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setDimensionOfCrankPinJournal({...dimensionOfCrankPinJournal, same_value:true})
                                                                                            let newList = listDimensionOfCrankPinJournal.map(obj => ({
                                                                                                ...obj, same_value: true,
                                                                                                pin_aa_2: obj.pin_aa_1,
                                                                                                pin_aa_3: obj.pin_aa_1,
                                                                                                pin_bb_1: obj.pin_aa_1,
                                                                                                pin_bb_2: obj.pin_aa_1,
                                                                                                pin_bb_3: obj.pin_aa_1,
                                                                                            }))
                                                                                            setListDimensionOfCrankPinJournal(newList.slice(0))
                                                                                        } else {
                                                                                            setDimensionOfCrankPinJournal({...dimensionOfCrankPinJournal, same_value:false})
                                                                                            let newList = listDimensionOfCrankPinJournal.map(obj => ({ ...obj, same_value: false }));
                                                                                            setListDimensionOfCrankPinJournal(newList.slice(0))
                                                                                        }
                                                                                    }} checked={dimensionOfCrankPinJournal.same_value}></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listDimensionOfCrankPinJournal.map((crankPinJournal, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={crankPinJournal.pin_aa_1} onChange={(e) => {
                                                                                                            let newList = listDimensionOfCrankPinJournal;
                                                                                                            newList[index].pin_aa_1 = e.target.value;
                                                                                                            if (crankPinJournal.same_value) {
                                                                                                                newList[index].pin_aa_2 = e.target.value;
                                                                                                                newList[index].pin_aa_3 = e.target.value;
                                                                                                                newList[index].pin_bb_1 = e.target.value;
                                                                                                                newList[index].pin_bb_2 = e.target.value;
                                                                                                                newList[index].pin_bb_3 = e.target.value;
                                                                                                            }
                                                                                                            setListDimensionOfCrankPinJournal(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.pin_aa_2} onChange={(e) => {
                                                                                                        let newList = listDimensionOfCrankPinJournal;
                                                                                                        newList[index].pin_aa_2 = e.target.value
                                                                                                        setListDimensionOfCrankPinJournal(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.pin_aa_3} onChange={(e) => {
                                                                                                        let newList = listDimensionOfCrankPinJournal;
                                                                                                        newList[index].pin_aa_3 = e.target.value
                                                                                                        setListDimensionOfCrankPinJournal(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.pin_bb_1} onChange={(e) => {
                                                                                                        let newList = listDimensionOfCrankPinJournal;
                                                                                                        newList[index].pin_bb_1 = e.target.value
                                                                                                        setListDimensionOfCrankPinJournal(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.pin_bb_2} onChange={(e) => {
                                                                                                        let newList = listDimensionOfCrankPinJournal;
                                                                                                        newList[index].pin_bb_2 = e.target.value
                                                                                                        setListDimensionOfCrankPinJournal(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.pin_bb_3} onChange={(e) => {
                                                                                                        let newList = listDimensionOfCrankPinJournal;
                                                                                                        newList[index].pin_bb_3 = e.target.value
                                                                                                        setListDimensionOfCrankPinJournal(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                            </div></div>

                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p><Form.Check checked={crankPinJournal.same_value} onClick={(e) => {
                                                                                            let newList = listDimensionOfCrankPinJournal
                                                                                            if (e.target.checked) {
                                                                                                newList[index].pin_aa_2 = crankPinJournal.pin_aa_1
                                                                                                newList[index].pin_aa_3 = crankPinJournal.pin_aa_1
                                                                                                newList[index].pin_bb_1 = crankPinJournal.pin_aa_1
                                                                                                newList[index].pin_bb_2 = crankPinJournal.pin_aa_1
                                                                                                newList[index].pin_bb_3 = crankPinJournal.pin_aa_1
                                                                                                newList[index].same_value = true
                                                                                            } else {
                                                                                                newList[index].same_value = false
                                                                                            }
                                                                                        }}></Form.Check></p></div>

                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Intake Valve And Exhaust Valve Face Thickness`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Thickness</Form.Label>
                                                                            <Form.Control value={intakeValveExhaust.nominal_thickness} onChange={(e) => {
                                                                                setIntakeValveExhaust({ ...intakeValveExhaust, nominal_thickness: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Repair Limit</Form.Label>
                                                                            <Form.Control value={intakeValveExhaust.repair_limit} onChange={(e) => {
                                                                                setIntakeValveExhaust({ ...intakeValveExhaust, repair_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={intakeValveExhaust.comment} onChange={(e) => {
                                                                                setIntakeValveExhaust({ ...intakeValveExhaust, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={intakeValveExhaust.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setIntakeValveExhaust({ ...intakeValveExhaust, is_show: true })
                                                                                } else {
                                                                                    setIntakeValveExhaust({ ...intakeValveExhaust, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>C(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>E(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setIntakeValveExhaust({...intakeValveExhaust, same_value:true})
                                                                                            let newList = listIntakeValveExhaust.map(obj => ({
                                                                                                ...obj, same_value: true,
                                                                                                thickness_c_2: obj.thickness_c_1,
                                                                                                thickness_e_1: obj.thickness_c_1,
                                                                                                thickness_e_2: obj.thickness_c_1
                                                                                            }))
                                                                                            setListIntakeValeExhaust(newList.slice(0))
                                                                                        } else {
                                                                                            setIntakeValveExhaust({...intakeValveExhaust, same_value:false})
                                                                                            let newList = listIntakeValveExhaust.map(obj => ({ ...obj, same_value: false }));
                                                                                            setListIntakeValeExhaust(newList.slice(0));
                                                                                        }
                                                                                    }} checked={intakeValveExhaust.same_value}></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listIntakeValveExhaust.map((intakeValve, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={intakeValve.thickness_c_1} onChange={(e) => {
                                                                                                            let newList = listIntakeValveExhaust;
                                                                                                            newList[index].thickness_c_1 = e.target.value;
                                                                                                            if (intakeValve.same_value) {
                                                                                                                newList[index].thickness_c_2 = e.target.value;
                                                                                                                newList[index].thickness_e_1 = e.target.value;
                                                                                                                newList[index].thickness_e_2 = e.target.value;
                                                                                                            }
                                                                                                            setListIntakeValeExhaust(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={intakeValve.thickness_c_2} onChange={(e) => {
                                                                                                        let newList = listIntakeValveExhaust;
                                                                                                        newList[index].thickness_c_2 = e.target.value
                                                                                                        setListIntakeValeExhaust(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={intakeValve.thickness_e_1} onChange={(e) => {
                                                                                                        let newList = listIntakeValveExhaust;
                                                                                                        newList[index].thickness_e_1 = e.target.value
                                                                                                        setListIntakeValeExhaust(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={intakeValve.thickness_e_2} onChange={(e) => {
                                                                                                        let newList = listIntakeValveExhaust;
                                                                                                        newList[index].thickness_e_2 = e.target.value
                                                                                                        setListIntakeValeExhaust(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div></div>

                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p><Form.Check checked={intakeValve.same_value} onClick={(e) => {
                                                                                            let newList = listIntakeValveExhaust;
                                                                                            if (e.target.checked) {
                                                                                                newList[index].thickness_c_2 = intakeValve.thickness_c_1;
                                                                                                newList[index].thickness_e_1 = intakeValve.thickness_c_1;
                                                                                                newList[index].thickness_e_2 = intakeValve.thickness_c_1;
                                                                                                newList[index].same_value = true
                                                                                            } else {
                                                                                                newList[index].same_value = false
                                                                                            }
                                                                                            setListIntakeValeExhaust(newList.slice(0));
                                                                                        }}></Form.Check></p></div>

                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>

                                                        </Tab>
                                                        <Tab eventKey="page3" title={`Page 3`}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Clearance of Main Bearings and Journals (New)`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={clearanceOfMainBearingJournalNew.nominal_diameter} onChange={(e) => {
                                                                                setClearanceOfMainBearingJournalNew({ ...clearanceOfMainBearingJournalNew, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Upper Limit</Form.Label>
                                                                            <Form.Control value={clearanceOfMainBearingJournalNew.upper_limit} onChange={(e) => {
                                                                                setClearanceOfMainBearingJournalNew({ ...clearanceOfMainBearingJournalNew, upper_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>
                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Lower Limit</Form.Label>
                                                                            <Form.Control value={clearanceOfMainBearingJournalNew.lower_limit} onChange={(e) => {
                                                                                setClearanceOfMainBearingJournalNew({ ...clearanceOfMainBearingJournalNew, lower_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={clearanceOfMainBearingJournalNew.comment} onChange={(e) => {
                                                                                setClearanceOfMainBearingJournalNew({ ...clearanceOfMainBearingJournalNew, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={clearanceOfMainBearingJournalNew.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setClearanceOfMainBearingJournalNew({ ...clearanceOfMainBearingJournalNew, is_show: true })
                                                                                } else {
                                                                                    setClearanceOfMainBearingJournalNew({ ...clearanceOfMainBearingJournalNew, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>AA</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>BB</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>CC</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                     flexDirection: "column",
                                                                                     borderStyle: "solid",
                                                                                     width: "100%",
                                                                                     flex: 1,
                                                                                     flexWrap: "nowrap",
                                                                                     borderWidth: 0.5,
                                                                                     justifyContent: 'center',
                                                                                     textAlign: "center",
                                                                                     alignContent: "center"

                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check checked={clearanceOfMainBearingJournalNew.same_value} onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setClearanceOfMainBearingJournalNew({...clearanceOfMainBearingJournalNew, same_value:true})
                                                                                            let newList = listClearanceOfMainBearingJournalNew.map(obj => ({
                                                                                                ...obj, same_value: true,
                                                                                                bearing_aa_2: obj.bearing_aa_1,
                                                                                                bearing_bb_1: obj.bearing_aa_1,
                                                                                                bearing_bb_2: obj.bearing_aa_1,
                                                                                                bearing_cc_1: obj.bearing_aa_1,
                                                                                                bearing_cc_2: obj.bearing_aa_1,
                                                                                            }))
                                                                                            setListClearanceOfMainBearingJournalNew(newList.slice(0))
                                                                                        } else {
                                                                                            setClearanceOfMainBearingJournalNew({...clearanceOfMainBearingJournalNew, same_value:false})
                                                                                            let newList = listClearanceOfCrankpinBearingJournalsNew.map(obj => ({ ...obj, same_value: false }))
                                                                                            setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0));
                                                                                        }
                                                                                    }}></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listClearanceOfMainBearingJournalNew.map((mainBearing, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={mainBearing.bearing_aa_1} onChange={(e) => {
                                                                                                            let newList = listClearanceOfMainBearingJournalNew;
                                                                                                            newList[index].bearing_aa_1 = e.target.value;
                                                                                                            if (mainBearing.same_value) {
                                                                                                                newList[index].bearing_aa_2 = e.target.value;
                                                                                                                newList[index].bearing_bb_1 = e.target.value;
                                                                                                                newList[index].bearing_bb_2 = e.target.value;
                                                                                                                newList[index].bearing_cc_1 = e.target.value;
                                                                                                                newList[index].bearing_cc_2 = e.target.value;
                                                                                                            }
                                                                                                            setListClearanceOfMainBearingJournalNew(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={mainBearing.bearing_aa_2} onChange={(e) => {
                                                                                                        let newList = listClearanceOfMainBearingJournalNew;
                                                                                                        newList[index].bearing_aa_2 = e.target.value
                                                                                                        setListClearanceOfMainBearingJournalNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={mainBearing.bearing_bb_1} onChange={(e) => {
                                                                                                        let newList = listClearanceOfMainBearingJournalNew;
                                                                                                        newList[index].bearing_bb_1 = e.target.value
                                                                                                        setListClearanceOfMainBearingJournalNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={mainBearing.bearing_bb_2} onChange={(e) => {
                                                                                                        let newList = listClearanceOfMainBearingJournalNew;
                                                                                                        newList[index].bearing_bb_2 = e.target.value
                                                                                                        setListClearanceOfMainBearingJournalNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div></div>


                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={mainBearing.bearing_cc_1} onChange={(e) => {
                                                                                                        let newList = listClearanceOfMainBearingJournalNew;
                                                                                                        newList[index].bearing_cc_1 = e.target.value
                                                                                                        setListClearanceOfMainBearingJournalNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={mainBearing.bearing_cc_2} onChange={(e) => {
                                                                                                        let newList = listClearanceOfMainBearingJournalNew;
                                                                                                        newList[index].bearing_cc_2 = e.target.value
                                                                                                        setListClearanceOfMainBearingJournalNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div></div>

                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p><Form.Check checked={mainBearing.same_value} onClick={(e) => {
                                                                                            let newList = listClearanceOfMainBearingJournalNew
                                                                                            if (e.target.checked) {
                                                                                                newList[index].same_value = true;
                                                                                                newList[index].bearing_aa_2 = mainBearing.bearing_aa_1;
                                                                                                newList[index].bearing_bb_1 = mainBearing.bearing_aa_1;
                                                                                                newList[index].bearing_bb_2 = mainBearing.bearing_aa_1;
                                                                                                newList[index].bearing_cc_1 = mainBearing.bearing_aa_1;
                                                                                                newList[index].bearing_cc_2 = mainBearing.bearing_aa_1;
                                                                                            } else {
                                                                                                newList[index].same_value = false
                                                                                            }
                                                                                            setListClearanceOfMainBearingJournalNew(newList.slice(0));
                                                                                        }}></Form.Check></p></div>

                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Clearance of Crankpin Bearing Journals (New)`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={clearanceOfCrankpinBearingJournalsNew.nominal_diameter} onChange={(e) => {
                                                                                setClearanceOfCrankpinBearingJournalsNew({ ...clearanceOfCrankpinBearingJournalsNew, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Upper Limit</Form.Label>
                                                                            <Form.Control value={clearanceOfCrankpinBearingJournalsNew.upper_limit} onChange={(e) => {
                                                                                setClearanceOfCrankpinBearingJournalsNew({ ...clearanceOfCrankpinBearingJournalsNew, upper_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>
                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Lower Limit</Form.Label>
                                                                            <Form.Control value={clearanceOfCrankpinBearingJournalsNew.lower_limit} onChange={(e) => {
                                                                                setClearanceOfCrankpinBearingJournalsNew({ ...clearanceOfCrankpinBearingJournalsNew, lower_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={clearanceOfCrankpinBearingJournalsNew.comment} onChange={(e) => {
                                                                                setClearanceOfCrankpinBearingJournalsNew({ ...clearanceOfCrankpinBearingJournalsNew, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={clearanceOfCrankpinBearingJournalsNew.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setClearanceOfCrankpinBearingJournalsNew({ ...clearanceOfCrankpinBearingJournalsNew, is_show: true })
                                                                                } else {
                                                                                    setClearanceOfCrankpinBearingJournalsNew({ ...clearanceOfCrankpinBearingJournalsNew, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>AA</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>BB</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>CC</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Same Value</p>
                                                                                    <p><Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setClearanceOfCrankpinBearingJournalsNew({...clearanceOfCrankpinBearingJournalsNew, same_value:true})
                                                                                            let newList = listClearanceOfCrankpinBearingJournalsNew.map(obj => ({
                                                                                                ...obj, same_value: true,
                                                                                                crank_pin_aa_2: obj.crank_pin_aa_1,
                                                                                                crank_pin_bb_1: obj.crank_pin_aa_1,
                                                                                                crank_pin_bb_2: obj.crank_pin_aa_1,
                                                                                                crank_pin_cc_1: obj.crank_pin_aa_1,
                                                                                                crank_pin_cc_2: obj.crank_pin_aa_1,
                                                                                            }))
                                                                                            setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0));
                                                                                        } else {
                                                                                            setClearanceOfCrankpinBearingJournalsNew({...clearanceOfCrankpinBearingJournalsNew, same_value:false})
                                                                                            let newList = listClearanceOfCrankpinBearingJournalsNew.map(obj => ({ ...obj, same_value: false }));
                                                                                            setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0));
                                                                                        }
                                                                                    }} checked={clearanceOfCrankpinBearingJournalsNew.same_value}></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listClearanceOfCrankpinBearingJournalsNew.map((crankPinJournal, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={crankPinJournal.crank_pin_aa_1} onChange={(e) => {
                                                                                                            let newList = listClearanceOfCrankpinBearingJournalsNew;
                                                                                                            newList[index].crank_pin_aa_1 = e.target.value
                                                                                                            if (crankPinJournal.same_value) {
                                                                                                                newList[index].crank_pin_aa_2 = e.target.value;
                                                                                                                newList[index].crank_pin_bb_1 = e.target.value;
                                                                                                                newList[index].crank_pin_bb_2 = e.target.value;
                                                                                                                newList[index].crank_pin_cc_1 = e.target.value;
                                                                                                                newList[index].crank_pin_cc_2 = e.target.value;
                                                                                                            }
                                                                                                            setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.crank_pin_aa_2} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankpinBearingJournalsNew;
                                                                                                        newList[index].crank_pin_aa_2 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.crank_pin_bb_1} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankpinBearingJournalsNew;
                                                                                                        newList[index].crank_pin_bb_1 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.crank_pin_bb_2} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankpinBearingJournalsNew;
                                                                                                        newList[index].crank_pin_bb_2 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div></div>


                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.crank_pin_cc_1} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankpinBearingJournalsNew;
                                                                                                        newList[index].crank_pin_cc_1 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={crankPinJournal.crank_pin_cc_2} onChange={(e) => {
                                                                                                        let newList = listClearanceOfCrankpinBearingJournalsNew;
                                                                                                        newList[index].crank_pin_cc_2 = e.target.value
                                                                                                        setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div></div>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p><Form.Check checked={crankPinJournal.same_value} onClick={(e) => {
                                                                                            let newList = listClearanceOfCrankpinBearingJournalsNew
                                                                                            if (e.target.checked) {
                                                                                                newList[index].same_value = true;
                                                                                                newList[index].crank_pin_aa_2 = crankPinJournal.crank_pin_aa_1;
                                                                                                newList[index].crank_pin_bb_1 = crankPinJournal.crank_pin_aa_1;
                                                                                                newList[index].crank_pin_bb_2 = crankPinJournal.crank_pin_aa_1;
                                                                                                newList[index].crank_pin_cc_1 = crankPinJournal.crank_pin_aa_1;
                                                                                                newList[index].crank_pin_cc_2 = crankPinJournal.crank_pin_aa_1;
                                                                                            } else {
                                                                                                newList[index].same_value = false
                                                                                            }
                                                                                            setListClearanceOfCrankpinBearingJournalsNew(newList.slice(0));
                                                                                        }}></Form.Check></p></div>


                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Connecting Rod Bushing (New)`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Nominal Diameter</Form.Label>
                                                                            <Form.Control value={connectingRodBushingNew.nominal_diameter} onChange={(e) => {
                                                                                setConnectingRodBushingNew({ ...connectingRodBushingNew, nominal_diameter: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Upper Limit</Form.Label>
                                                                            <Form.Control value={connectingRodBushingNew.upper_limit} onChange={(e) => {
                                                                                setConnectingRodBushingNew({ ...connectingRodBushingNew, upper_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Lower Limit</Form.Label>
                                                                            <Form.Control value={connectingRodBushingNew.lower_limit} onChange={(e) => {
                                                                                setConnectingRodBushingNew({ ...connectingRodBushingNew, lower_limit: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={connectingRodBushingNew.comment} onChange={(e) => {
                                                                                setConnectingRodBushingNew({ ...connectingRodBushingNew, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={connectingRodBushingNew.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setConnectingRodBushingNew({ ...connectingRodBushingNew, is_show: true })
                                                                                } else {
                                                                                    setConnectingRodBushingNew({ ...connectingRodBushingNew, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,

                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center",

                                                                                    }}>
                                                                                        <p>AA(mm)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 4,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        borderStyle: "solid",
                                                                                        borderWidth: 0.5,
                                                                                        justifyContent: "center",
                                                                                        textAlign: "center"
                                                                                    }}>
                                                                                        <p>BB(MM)</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>1</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            borderWidth: 0.5,
                                                                                            textAlign: "center",
                                                                                            flex: 1
                                                                                        }}>
                                                                                            <p>2</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 2,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p>Clearance</p>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    borderStyle: "solid",
                                                                                    width: "100%",
                                                                                    flex: 1,
                                                                                    flexWrap: "nowrap",
                                                                                    borderWidth: 0.5,
                                                                                    justifyContent: 'center',
                                                                                    textAlign: "center",
                                                                                    // fontWeight:"bold"
                                                                                }}>
                                                                                    <p><Form.Check onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setConnectingRodBushingNew({...connectingRodBushingNew, same_value: true});
                                                                                            let newList = listConnectingRodBushingNew.map(obj => ({
                                                                                                ...obj, same_value: true,
                                                                                                rod_bushing_aa_2: obj.rod_bushing_aa_1,
                                                                                                rod_bushing_bb_1: obj.rod_bushing_aa_1,
                                                                                                rod_bushing_bb_2: obj.rod_bushing_aa_1,
                                                                                                clearance: obj.rod_bushing_aa_1,
                                                                                            }))
                                                                                            setListConnectingRodBushingNew(newList.slice(0))
                                                                                        } else {
                                                                                            setConnectingRodBushingNew({...connectingRodBushingNew, same_value: false});
                                                                                            let newList = listConnectingRodBushingNew.map(obj => ({ ...obj, same_value: false }));
                                                                                            setListConnectingRodBushingNew(newList.slice(0))
                                                                                        }
                                                                                    }} checked={connectingRodBushingNew.same_value} ></Form.Check></p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                listConnectingRodBushingNew.map((connectingRodBushing, index) => {
                                                                                    return (<div className="table-body" key={index}>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p>{index + 1}</p></div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}>
                                                                                                        <Form.Control required type="number" value={connectingRodBushing.rod_bushing_aa_1} onChange={(e) => {
                                                                                                            let newList = listConnectingRodBushingNew;
                                                                                                            newList[index].rod_bushing_aa_1 = e.target.value
                                                                                                            if (connectingRodBushing.same_value) {
                                                                                                                newList[index].rod_bushing_aa_2 = e.target.value;
                                                                                                                newList[index].rod_bushing_bb_1 = e.target.value;
                                                                                                                newList[index].rod_bushing_bb_2 = e.target.value;
                                                                                                                newList[index].clearance = e.target.value;
                                                                                                            }
                                                                                                            setListConnectingRodBushingNew(newList.slice(0))
                                                                                                        }}></Form.Control>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={connectingRodBushing.rod_bushing_aa_2} onChange={(e) => {
                                                                                                        let newList = listConnectingRodBushingNew;
                                                                                                        newList[index].rod_bushing_aa_2 = e.target.value
                                                                                                        setListConnectingRodBushingNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 4,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={connectingRodBushing.rod_bushing_bb_1} onChange={(e) => {
                                                                                                        let newList = listConnectingRodBushingNew;
                                                                                                        newList[index].rod_bushing_bb_1 = e.target.value
                                                                                                        setListConnectingRodBushingNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>
                                                                                                <div style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    borderStyle: "solid",
                                                                                                    width: "100%",
                                                                                                    borderWidth: 0.5,
                                                                                                    textAlign: "center",
                                                                                                    flex: 1
                                                                                                }}>
                                                                                                    <div style={{ padding: 5 }}><Form.Control required type="number" value={connectingRodBushing.rod_bushing_bb_2} onChange={(e) => {
                                                                                                        let newList = listConnectingRodBushingNew;
                                                                                                        newList[index].rod_bushing_bb_2 = e.target.value
                                                                                                        setListConnectingRodBushingNew(newList.slice(0))
                                                                                                    }}></Form.Control></div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 2,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                        }} >
                                                                                            <div style={{ padding: 5 }}><Form.Control required type="number" value={connectingRodBushing.clearance} onChange={(e) => {
                                                                                                let newList = listConnectingRodBushingNew;
                                                                                                newList[index].clearance = e.target.value
                                                                                                setListConnectingRodBushingNew(newList.slice(0))
                                                                                            }}></Form.Control></div>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            flexDirection: "column",
                                                                                            borderStyle: "solid",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                            flexWrap: "nowrap",
                                                                                            borderWidth: 0.5,
                                                                                            justifyContent: 'center',
                                                                                            textAlign: "center",
                                                                                            alignContent: "center"
                                                                                        }} ><p><Form.Check checked={connectingRodBushing.same_value} onClick={(e) => {
                                                                                            let newList = listConnectingRodBushingNew;
                                                                                            if (e.target.checked) {
                                                                                                newList[index].same_value = true;
                                                                                                newList[index].rod_bushing_aa_2 = connectingRodBushing.rod_bushing_aa_1;
                                                                                                newList[index].rod_bushing_bb_1 = connectingRodBushing.rod_bushing_aa_1;
                                                                                                newList[index].rod_bushing_bb_2 = connectingRodBushing.rod_bushing_aa_1;
                                                                                                newList[index].clearance = connectingRodBushing.rod_bushing_aa_1;
                                                                                            } else {
                                                                                                newList[index].same_value = false;
                                                                                            }
                                                                                            setListConnectingRodBushingNew(newList.slice(0))
                                                                                        }}></Form.Check></p></div>



                                                                                    </div>)
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flexWrap: "nowrap",
                                                                padding: "0px 10px 10px 10px"
                                                            }}>
                                                                <ContainerBox containerPos="inner" captionSize="small" titleCaption={`Clearance Of Piston Ring(New)`} childContent={
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        padding: 10,
                                                                    }}>



                                                                        <Form.Group className="mb3">
                                                                            <Form.Label>Comment</Form.Label>
                                                                            <Form.Control as={"textarea"} rows={3} value={clearanceOfPistonRingNew.comment} onChange={(e) => {
                                                                                setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, comment: e.target.value })
                                                                            }}></Form.Control>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3">
                                                                            <Form.Check label="Tampilkan" defaultChecked={clearanceOfPistonRingNew.is_show} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, is_show: true })
                                                                                } else {
                                                                                    setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, is_show: false })
                                                                                }
                                                                            }}>

                                                                            </Form.Check>
                                                                        </Form.Group>

                                                                        <div className="table-container">
                                                                            <div className="table-header">
                                                                                <div style={{ flex: 1 }} className="table-header-content"></div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">A</div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">B</div>
                                                                                <div style={{ flex: 3 }} className="table-header-content">C</div>
                                                                                <div style={{ flex: 1 }} className="table-header-content">
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        flexWrap: "nowrap"
                                                                                    }}>
                                                                                        <div style={{ flex: 1 }}>Same Value</div>
                                                                                        <div style={{ flex: 1, justifyContent: "center", display: "flex" }}><Form.Check checked={clearanceOfPistonRingNew.all_checked} onClick={(e) => {
                                                                                            if (e.target.checked) {
                                                                                                setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, same_value: true,all_checked: true, clearance_b: clearanceOfPistonRingNew.clearance_a, clearance_c: clearanceOfPistonRingNew.clearance_a })
                                                                                                let newList = listClearanceOfPistonRingNew.map(obj => ({ ...obj, same_value: true, ring_b: obj.ring_a, ring_c: obj.ring_a }))
                                                                                                setListClearanceOfPistionRingNew(newList.slice(0));
                                                                                            } else {
                                                                                                setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, same_value: false, all_checked:false })
                                                                                                let newList = listClearanceOfPistonRingNew.map(obj => ({ ...obj, same_value: false }))
                                                                                                setListClearanceOfPistionRingNew(newList.slice(0));
                                                                                            }
                                                                                        }}></Form.Check></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="table-body" >
                                                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>Clearance</p></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearanceOfPistonRingNew.clearance_a} onChange={(e) => {
                                                                                    if(clearanceOfPistonRingNew.same_value){
                                                                                        setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, clearance_a: e.target.value, clearance_b: e.target.value, clearance_c: e.target.value })
                                                                                    }else{
                                                                                        setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, clearance_a: e.target.value })
                                                                                    }

                                                                                }}></Form.Control></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearanceOfPistonRingNew.clearance_b} onChange={(e) => {
                                                                                    setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, clearance_b: e.target.value })
                                                                                }}></Form.Control></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearanceOfPistonRingNew.clearance_c} onChange={(e) => {
                                                                                    setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, clearance_c: e.target.value })
                                                                                }}></Form.Control></div>
                                                                                <div style={{ flex: 1,justifyContent:"center" }} className="table-body-content"><Form.Check checked={clearanceOfPistonRingNew.same_value} onClick={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, same_value: true, clearance_b: clearanceOfPistonRingNew.clearance_a, clearance_c: clearanceOfPistonRingNew.clearance_a })
                                                                                    } else {
                                                                                        setClearanceOfPistonRingNew({ ...clearanceOfPistonRingNew, same_value: false })
                                                                                    }
                                                                                }}></Form.Check></div>


                                                                            </div>
                                                                            {
                                                                                listClearanceOfPistonRingNew.map((clearancePistonRing, index) => {
                                                                                    return (
                                                                                        <div className="table-body" key={index}>
                                                                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{index + 1}</p></div>
                                                                                            <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearancePistonRing.ring_a} onChange={(e) => {
                                                                                                let newList = listClearanceOfPistonRingNew;
                                                                                                newList[index].ring_a = e.target.value;
                                                                                                if(clearancePistonRing.same_value){
                                                                                                    newList[index].ring_b = e.target.value;
                                                                                                    newList[index].ring_c = e.target.value;
                                                                                                }
                                                                                                setListClearanceOfPistionRingNew(newList.slice(0))
                                                                                            }}></Form.Control></div>
                                                                                            <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearancePistonRing.ring_b} onChange={(e) => {
                                                                                                let newList = listClearanceOfPistonRingNew;
                                                                                                newList[index].ring_b = e.target.value;
                                                                                                setListClearanceOfPistionRingNew(newList.slice(0))
                                                                                            }}></Form.Control></div>
                                                                                            <div style={{ flex: 3 }} className="table-body-content"><Form.Control required value={clearancePistonRing.ring_c} onChange={(e) => {
                                                                                                let newList = listClearanceOfPistonRingNew;
                                                                                                newList[index].ring_c = e.target.value;
                                                                                                setListClearanceOfPistionRingNew(newList.slice(0))
                                                                                            }}></Form.Control></div>
                                                                                            <div style={{ flex: 1, justifyContent:"center" }} className="table-body-content">
                                                                                                <Form.Check checked={clearancePistonRing.same_value} onClick={(e)=>{
                                                                                                    let newList = listClearanceOfPistonRingNew
                                                                                                    if(e.target.checked){
                                                                                                        newList[index].same_value = true;
                                                                                                        newList[index].ring_b = clearancePistonRing.ring_a;
                                                                                                        newList[index].ring_c = clearancePistonRing.ring_a;
                                                                                                    }else{
                                                                                                        newList[index].same_value = false
                                                                                                    }
                                                                                                }}></Form.Check>
                                                                                            </div>


                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                }>
                                                                </ContainerBox>

                                                            </div>


                                                        </Tab>
                                                    </Tabs>
                                                    <div style={{ paddingBottom: 10 }}></div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "nowrap",
                                                        justifyContent: "center"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            paddingRight: 5
                                                        }}>
                                                            <Button type="submit" style={{ width: 100, paddingRight: 10 }} disabled={false}>Simpan</Button>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            paddingLeft: 5
                                                        }}>
                                                            <Button onClick={() => {
                                                                navigate('/GeneralOverhaul')
                                                            }} type="reset" variant="danger" style={{ width: 100, paddingLeft: 10 }}>Batal</Button>

                                                        </div>


                                                    </div>
                                                </Form>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}