import React, { useState, useEffect } from "react";
import { Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserRole } from "../../../Helpers/ApplicationHelpers";
import { getUserRoleXMenu, getUserRoleXMenuCount } from "../../../Helpers/SystemHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, CameraFill, ArrowClockwise, FilePlusFill, Search } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";

export default function UserRoleXMenuPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listUserRole, setListUserRole] = useState([]);
    const [listUserRoleXMenu, setListUserRoleXMenu] = useState([]);
    const [userRoleXMenuCount, setUserRoleXMenuCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "Nama Menu",
        value: "userRolexMenu.menu_id"
    });
    const orderByList = [{
        label: "Nama Menu",
        value: "userRolexMenu.menu_id"
    },
    {
        label: "Role Pengguna",
        value: "userRolexMenu.role_id"
    }
    ]

    const [descending, setDescending] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                setLoading(false);
                loadUserRole();
            }
        }
        checkCookies();
    }, []);

    const loadUserRole = async () => {
        try {
            let response = await getUserRole(cookies.token)
            setListUserRole(response);
        } catch (exception) {
            console.log(exception)
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption="Akses Menu"
                    useActionContainer={true}
                    // actionContainerChild={
                    //     <div>
                    //         <Button variant="secondary" style={{

                    //         }} onClick={() => { navigate("/UserRoleXMenu/Detail", { state: { roleId: 0 } }) }}>
                    //             <div style={{
                    //                 display: "flex",
                    //                 flex: 1,
                    //                 alignContent: "center",
                    //                 alignItems: "center",
                    //                 justifyContent: "center",
                    //                 flexDirection: "row",
                    //                 alignSelf: "center",
                    //             }}>
                    //                 <div style={{
                    //                     display: "flex",
                    //                     alignContent: "center",
                    //                     alignItems: "center",
                    //                     justifyContent: "center",
                    //                     alignSelf: "center",
                    //                     flex: 1,
                    //                 }}><FilePlusFill size={32} /></div>
                    //                 <div style={{
                    //                     display: "flex",
                    //                     flex: 8,
                    //                     alignContent: "center",
                    //                     alignItems: "center",
                    //                     justifyContent: "center",
                    //                     alignSelf: "center",
                    //                     paddingLeft: 10,
                    //                     fontWeight: "bold",
                    //                     fontSize: 18,
                    //                 }}>Tambah Akses</div>
                    //             </div>
                    //         </Button>
                    //     </div>
                    // }
                    childContent={
                        <div className="master-table-inner-container">
                            {/* <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder="Cari (Nama Menu,"
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setLoading(true);
                                                await loadUserRoleXMenu();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            await loadUserRoleXMenu();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            await loadUserRoleXMenu();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div> */}
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 2 }} className="table-header-content">Role Pengguna</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                </div>
                                {
                                    listUserRole.map((userRole, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{userRole.role_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button onClick={() => {
                                                        navigate("/UserRoleXMenu/Detail", { state: { roleId: userRole.id } })
                                                    }}> <PencilFill /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            {/* <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            /> */}
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>


        </>
    );

}