import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import {
    isTokenValid, getStaff, getStaffCount, deleteStaff, getStaffDocumentById, getMechanicCount, getMechanic,
    getUserAccessById, getCrewCount, getCrew, getEmployeeStatus,
    getDataPrintStaff, getDataPrintCSV
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise, Play, BorderStyle, Printer, XSquare } from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";
import Select from 'react-select';
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "../../../Components/DatePicker";

export default function StaffPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation()
    const [listStaff, setListStaff] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(0);
    const [listDataPrint, setListDataPrint] = useState([]);
    const [idPrint, setIdPrint] = useState("");
    const [genenrateCSVStatus, setGenerateCSVStatus] = useState(0)
    const [statusLoadCsv, setStatusLoadCsv] = useState(false);
    const [messageGenerateCSV, setMessageGenerateCSV] = useState("");
    const [dateNow, setDateNow] = useState("");
    const [listDataPrintCsv, setListDataPrintCsv] = useState([]);
    const [startJoinDate, setStartJoinDate] = useState("");
    const [endJoinDate, setEndJoinDate] = useState("");
    const [startJoinDatePrint, setStartJoinDatePrint] = useState("");
    const [endJoinDatePrint, setEndJoinDateprint] = useState("");
    const [loading, setLoading] = useState(true);
    const [staffName, setStaffName] = useState("");
    const [modalOptionPrint, setModalOptionPrint] = useState("");
    const [staffCount, setStaffCount] = useState(0);
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const { t, i18n } = useTranslation();
    const [staffIds, setStaffIds] = useState("");
    const [isResign, setIsResign] = useState(0);
    const [statusId, setStatusId] = useState([{
        id: 0,
        employee_status_name: "Semua"
    }]);
    const [statusIdPrint, setStatusIdPrint] = useState([{
        id: 0,
        employee_status_name: "Semua"
    }]);
    const [isResignPrint, setIsResignPrint] = useState(0);
    const [listEmployeeStatus, setListEmployeeStatus] = useState([]);
    const [listEmployeeStatusPrint, setListEmployeeStatusPrint] = useState([])
    const [isResignChecked, setIsResingChecked] = useState(false);
    const [isActiveChecked, setIsActiveChecked] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [page, setPage] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const styles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: 30,
            minWidth: 40,
            overflowY: "auto"
        }),
    }
    const [totalPage, setTotalPage] = useState(0);
    const [tableOption, setTableOption] = useState(
        [
            {
                name: "NIK",
                value: true,
                valueName: "staff.employee_number"
            },
            {
                name: "Nama Lengkap",
                value: true,
                valueName: "staff.staff_name"
            },
            {
                name: "Jabatan",
                value: true,
                valueName: "position.position_name"
            },
            {
                name: "Tanggal Bergabung",
                value: true,
                valueName: "staff.join_date"
            },
            {
                name: "Status Karyawan",
                value: true,
                valueName: "employeeStatus.employee_status_name"
            },
            {
                name: "Awal Kontrak",
                value: true,
                valueName: "Contract"
            },
            {
                name: "Akhir Kontrak",
                value: true,
                valueName: "Contract"
            },
            {
                name: "No KTP",
                value: true,
                valueName: "staff.ktp_number"
            },
            {
                name: "No NPWP",
                value: true,
                valueName: "staff.npwp_number"
            },
            {
                name: "Status Keluarga",
                value: true,
                valueName: "Contract"
            },
            {
                name: "Email",
                value: true,
                valueName: "staff.email"
            },
            {
                name: "No HP",
                value: true,
                valueName: "staff.phone_number_1"
            },
            {
                name: "Nama Bank",
                value: true,
                valueName: "bank.bank_name"
            },
            {
                name: "Nomor Rekening",
                value: true,
                valueName: "staff.bank_account_number"
            },
            {
                name: "Divisi",
                value: true,
                valueName: "division.division_code"
            },
            {
                name: "Departemen",
                value: true,
                valueName: "department.department_code"
            },
            {
                name: "Unit Bisnis",
                value: true,
                valueName: "businessUnit.unit_name"
            }
        ]
    )
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: "",
        value: "staff.staff_name"
    });

    const orderByList = [
        {
            label: `${t("index_staff_page.order_name1")}`,
            value: "staff.staff_name"
        },
        {
            label: `${t("index_staff_page.order_name2")}`,
            value: "staff.employee_number"
        },
        {
            label: `${t("index_staff_page.order_name3")}`,
            value: "staff.join_date"
        },
        {
            label: `${t("index_staff_page.order_name4")}`,
            value: "contractHistory.end_date"
        },

    ]
    const [descending, setDescending] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadStaff();
                loadAccess();
                loadEployeeStatus();
                loadUserPrint();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);


    useEffect(() => {
        // if(isSearched !== 0)
        loadStaff();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadStaff();
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0)
            loadStaff();
    }, [orderBy]);

    useEffect(() => {
        // if (isSearched !== 0)
        loadStaff();
    }, [isResign])

    useEffect(() => {
        if (staffIds !== "")
            removeStaff();
        // loadStaff();
    }, [staffIds]);

    useEffect(() => {
        if (resetSearch === 1)
            loadStaff();
    }, [resetSearch])



    useEffect(() => {
        if (isSearched !== 0)
            loadStaff();
    }, [descending]);

    useEffect(() => {
        if (updateStatus !== 0) {
            loadStaff();
        }
    }, [updateStatus]);

    useEffect(() => {
        if (idPrint !== "")
            loadPrintList();
    }, [idPrint]);

    useEffect(() => {
        if (listDataPrint.length > 0) {

            // handlesum();
            printDataStaff();
            setIdPrint("")
            setLoading(false);
            setListDataPrint([]);
        }
        // else {
        //     setLoading(false);
        // }


    }, [listDataPrint]);;

    // useEffect(()=>{
    //     if(listDataPrintCsv.length>0){
    //         <CSVLink
    //         data={listDataPrintCsv}
    //         filename={"Staff-Report.csv"}
    //         className="btn btn-primary"
    //         target="_blank"
    //     >
    //         Print CSV

    //     </CSVLink>
    //     }
    // },[listDataPrintCsv])

    useEffect(() => {
        if (startJoinDate !== "" && endJoinDate !== "") {
            setPage(0);
            loadStaff();
        }
    }, [startJoinDate, endJoinDate])


    // useEffect(() => {
    //     if (isResignChecked === true && isActiveChecked === true) {
    //         setIsResign(3);
    //     }
    //     else if (isResignChecked === true && isActiveChecked === false) {
    //         setIsResign(1);
    //     } else if (isResignChecked === false && isActiveChecked === true) {
    //         setIsResign(0)
    //     } else {
    //         setListStaff([]);
    //         setTotalPage(0);
    //         setLoading(false);
    //         setIsResign("");
    //     }


    // }, [isResignChecked, isActiveChecked])

    const loadPrintList = async () => {
        try {
            let response = await getDataPrintStaff(cookies.token, isResignPrint, cookies.extCustomerId, statusIdPrint, startJoinDatePrint, endJoinDatePrint);
            setListDataPrint(response);

            if (response.length === 0) {
                setIdPrint("");
                setLoading(false)
            }


        } catch (exception) {

        }
    }

    const loadUserPrint = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, "null");
            let newStaff = response.find(p => p.id === cookies.staffId);
            if (newStaff.staff_name) {
                setStaffName(newStaff.staff_name);
            }
        } catch (exception) {

        }
    }

    const loadStaff = async () => {
        try {
            if (isResign !== "") {
                if (window.location.pathname === "/Staff") {
                    let staffCount = await getStaffCount(cookies.token, cookies.languageId, searchQuery, false, isResign, cookies.extCustomerId, statusId, startJoinDate, endJoinDate);
                    let totalPage = staffCount / itemPerPage;
                    setTotalPage(totalPage);
                } else if (window.location.pathname === "/Crew") {
                    let CrewCount = await getCrewCount(cookies.token, cookies.languageId, searchQuery, false, isResign, cookies.extCustomerId, statusId);
                    let totalPage = CrewCount / itemPerPage;
                    setTotalPage(totalPage);
                } else {
                    let mechanicCount = await getMechanicCount(cookies.token, cookies.languageId, searchQuery, false, isResign, cookies.extCustomerId, statusId);

                    let totalPage = mechanicCount / itemPerPage;
                    setTotalPage(totalPage);
                }


                if (window.location.pathname === "/Mechanic") {
                    let listMechanic = await getMechanic(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false, isResign, statusId);
                    setListStaff(listMechanic)
                } else if (window.location.pathname === "/Crew") {
                    let listCrew = await getCrew(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false, isResign, statusId);
                    setListStaff(listCrew)
                } else {
                    let listStaff = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false, isResign, statusId, startJoinDate, endJoinDate);
                    let listStaffTmp = listStaff.filter(p => p.is_crew === false);
                    setListStaff(listStaffTmp)
                }


                setResetSearch(0);
                setIsSearched(0);
                setUpdateStatus(0);
                setLoading(false);
            }

            let dates = new Date()
            setDateNow(moment(dates).format("DD-MM-yyyy HH:mm"))


        } catch (exception) {

        }
    }

    const removeStaff = async () => {
        try {
            let response = await deleteStaff(cookies.token, staffIds);
            if (response === 0) {
                setLoading(false);
                alert(`${t("index_staff_page.delete_success_alert")}`)
                loadStaff();
            } else {
                alert(`${t("index_staff_page.delete_failed_alert")}`)
                setLoading(false)
            }
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, "/Staff",cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated);
            }
            setOrderBy({
                label: `${t("index_staff_page.order_name1")}`,
                value: "staff.staff_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadEployeeStatus = async () => {
        try {
            const response = await getEmployeeStatus(cookies.token, cookies.languageId);
            let newStatus = [{
                id: 0,
                employee_status_name: "Semua"
            },
            {
                id: 1,
                employee_status_name: "Kecuali Temporary"
            }
            ]

            let newListStatus = response.concat(newStatus);


            setListEmployeeStatus(newListStatus);

            setListEmployeeStatusPrint(newListStatus);

        } catch (exception) {
            console.log(exception);
        }
    }

    const printDataStaff = () => {
        var prtContent = document.getElementById("printStaff");
        prtContent.style.width = "100%";
        prtContent.style.font = "17px Calibri";
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"
        style = style + ".tableWidth  { width:15% !important}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("index_inventory_master.header_title")}`;
        WinPrint.print();
        prtContent.style.width = "";
        prtContent.style.font = "";
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";



    }

    const loadDataPrintCSV = async () => {
        try {
            let response = await getDataPrintCSV(cookies.token, isResignPrint, cookies.extCustomerId, statusIdPrint, startJoinDatePrint, endJoinDatePrint, tableOption);
            setListDataPrintCsv(response);
            if (response.length === 0) {
                // setMessageGenerateCSV("Tidak Ada Data");
                alert('Tidak Ada Data')
            } else {
                setGenerateCSVStatus(1);
            }
            setLoading(false);
            setStatusLoadCsv(false)
        } catch (exception) {

        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={window.location.pathname === "/Staff" ? `${t("index_staff_page.header_title")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.crew_header")}` : `${t("index_technician_page.header_title")}`}
                    useActionContainer={(window.location.pathname === "/Staff" || window.location.pathname === "/Crew") && (cookies.logingAs === "User" || cookies.extCustomerId === "null") ? true : false}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                if (window.location.pathname === "/Crew") {
                                    navigate("/Crew/Detail", { state: { staffId: 0, pathname: window.location.pathname } })
                                } else {
                                    navigate("/Staff/Detail", { state: { staffId: 0, pathname: window.location.pathname } })
                                }

                            }}
                            >
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_staff_page.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">

                            <div className="master-table-searchbar-container">
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                    <div style={{
                                        paddingRight: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%"
                                    }}>
                                        <Form.Control type="date" onChange={(e) => {
                                            if (moment(e.target.value).format('yyyy-MM-DD') > moment(endJoinDate).format('yyyy-MM-DD')) {
                                                startJoinDate("")
                                            } else {
                                                setStartJoinDate(e.target.value)
                                            }

                                        }} value={startJoinDate}></Form.Control>
                                    </div>
                                    <div style={{
                                        paddingRight: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%"
                                    }}>
                                        <Form.Control type="date" onChange={(e) => {
                                            if (moment(e.target.value).format('yyyy-MM-DD') < moment(startJoinDate).format('yyyy-MM-DD')) {
                                                setEndJoinDate("")
                                            } else {
                                                setEndJoinDate(e.target.value)
                                            }

                                        }} value={endJoinDate}></Form.Control>
                                    </div>
                                </div>
                                {/* <div style={{
                                    display: "flex",
                                    padding: 10
                                }}>
                                    <Form.Check label={window.location.pathname === "/Mechanic" ? `${t("index_technician_page.checkbox_active_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.checkbox_active_crew")}` : `${t("index_staff_page.checkbox_active_staff")}`} onClick={(e) => {
                                        setLoading(true);
                                        if (e.target.checked) {
                                            // setIsResign(0)
                                            setIsActiveChecked(true)
                                        } else {
                                            setIsActiveChecked(false)
                                        }
                                        setIsSearched(1)
                                    }} defaultChecked={isActiveChecked}
                                    />
                                </div>
                                <div style={{
                                    display: "flex",
                                    padding: 10
                                }}>
                                    <Form.Check label={window.location.pathname === "/Mechanic" ? `${t("index_technician_page.leave_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.leave_crew")}` : `${t("index_staff_page.leave_staff")}`} onClick={(e) => {
                                        setLoading(true);
                                        if (e.target.checked) {
                                            // setIsResign(1);
                                            setIsResingChecked(true);
                                        } else {
                                            setIsResingChecked(false)
                                        }
                                    }} defaultChecked={isResignChecked}
                                    />
                                </div> */}

                                <div className="master-table-searchbar-textbox">


                                    <div style={{ paddingRight: 10, display: "flex", flexDirection: "column", width: 800 }}>
                                        <Select styles={styles} isMulti={true} placeholder=""
                                            clearValue={true} closeMenuOnSelect={false}
                                            getOptionLabel={(item) => {
                                                return item.employee_status_name;
                                            }}
                                            getOptionValue={(item) => {
                                                return item.id;
                                            }}
                                            options={listEmployeeStatus} value={statusId} onChange={(e) => {
                                                if(e.length === 0){
                                                    setUpdateStatus(0);
                                                 
                                                }else{
                                                    setUpdateStatus(1);
                                                  
                                                }
                                                setStatusId(e);
                                            }} isClearable

                                        />
                                        {/* <Select placeholder={""}
                                            getOptionLabel={(item) => {
                                                return item.employee_status_name;
                                            }} clearValue={true}
                                            getOptionValue={(item) => {
                                                return item.id;
                                            }}
                                            options={listEmployeeStatus} value={listEmployeeStatus.filter(function (option) {
                                                return option.id === statusId;
                                            })} onChange={(e) => {
                                                setPage(0)
                                                setLoading(true);
                                                if (e === null) {
                                                    setUpdateStatus(1);
                                                    setStatusId(0);

                                                } else {
                                                    setUpdateStatus(1);
                                                    setStatusId(e.id)
                                                }
                                            }}
                                            isClearable

                                        /> */}
                                    </div>
                                    <div style={{
                                        paddingRight: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%"
                                    }}>
                                        <Form.Select required onChange={(e) => {
                                            // setDivision(e.target.value);
                                            setPage(0);
                                            setLoading(true);
                                            setIsResign(e.target.value);
                                        }} value={isResign} >
                                            <option value={0}>
                                                {window.location.pathname === "/Mechanic" ? `${t("index_technician_page.checkbox_active_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.checkbox_active_crew")}` : `${t("index_staff_page.checkbox_active_staff")}`}
                                            </option>
                                            <option value={1}>
                                                {window.location.pathname === "/Mechanic" ? `${t("index_technician_page.leave_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.leave_crew")}` : `${t("index_staff_page.leave_staff")}`}
                                            </option>
                                            <option value={3}>{window.location.pathname === "/Mechanic" ? `${t("index_technician_page.all_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.all_crew")}` : `${t("index_staff_page.all_staff")}`}</option>
                                        </Form.Select>
                                    </div>

                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_staff_page.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadStaff();

                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadStaff();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setResetSearch(1);
                                            setStatusId([
                                                {
                                                    id: 0,
                                                    employee_status_name: "Semua"
                                                }]);
                                            setIsResign(0);
                                            setStartJoinDate("");
                                            setEndJoinDate("");

                                            // await loadStaff();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={() => {
                                            // setLoading(true);
                                            // setIdPrint(1);
                                            // printPageStock();
                                            // loadDataPrintCSV();
                                            setModalOptionPrint(true);
                                        }}><Printer /> Print</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{`${t("index_staff_page.table_name1")}`}</div>
                                    <div style={{ flex: 5 }} className="table-header-content">{`${t("index_staff_page.table_name2")}`}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{`${t("index_staff_page.table_name3")}`}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{`${t("index_staff_page.table_name4")}`}</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{`${t("index_staff_page.table_name5")}`}</div>
                                    {window.location.pathname === "/Mechanic" ? <div style={{ flex: 3 }} className="table-header-content">{`${t("index_technician_page.table_name6")}`}</div> : ""}
                                    {window.location.pathname === "/Crew" && <div style={{ flex: 3 }} className="table-header-content">{`${t("index_staff_page.table_name6")}`}</div>}
                                    <div style={{ flex: 1 }} className="table-header-content">{`${t("index_staff_page.table_name7")}`}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{`${t("index_staff_page.table_name8")}`}</div>

                                </div>
                                {
                                    listStaff.map((staff, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p><img src={staff.photo_base64} style={{ maxWidth: 80, maxHeight: 80 }} /></p></div>
                                            <div style={{ flex: 5 }} className="table-body-content"><p>{staff.staff_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{staff.employee_number}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{staff.phone_number_1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{staff.email}</p></div>
                                            {window.location.pathname === "/Mechanic" ? <div style={{ flex: 3 }} className="table-body-content"><p>{staff.position_name}</p></div> : ""}
                                            {window.location.pathname === "/Crew" && <div style={{ flex: 3 }} className="table-body-content"><p>{staff.status_vaksin}</p></div>}
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        if (window.location.pathname === "/Crew") {
                                                            navigate("/Crew/Detail", { state: { staffId: staff.id, staffName: staff.staff_name, pathname: window.location.pathname } });
                                                        } else {
                                                            navigate("/Staff/Detail", { state: { staffId: staff.id, staffName: staff.staff_name, pathname: window.location.pathname } });
                                                        }

                                                    }}><PencilFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} className="cancel" variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("index_staff_page.delete_confirmation")} ${staff.staff_name}?`)) {
                                                            let staffIds = staff.id
                                                            setStaffIds(staffIds);
                                                            setLoading(true)
                                                        }
                                                    }}><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                isSearched={isSearched}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />

                <div id="printStaff" style={{ display: 'none', padding: 10 }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ width: '70%' }}>
                        <img src={require("../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>

                    <div style={{
                        // marginLeft: 300,
                        textAlign: "center",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >Daftar Karyawan</h3>

                    </div>

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%", fontWeight: "bold" }}>

                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{staffName}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{dateNow}</td>
                            </tr>
                        </Table>

                    </div>
                    <div style={{ paddingBottom: 5 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr style={{ fontWeight: "bold" }}>
                            <td>No </td>
                            <td hidden={!tableOption[0].value} className="tableWidth">NIK</td>
                            <td hidden={!tableOption[1].value} >Nama Lengkap</td>
                            <td hidden={!tableOption[2].value} >Jabatan </td>
                            <td hidden={!tableOption[14].value} >Divisi </td>
                            <td hidden={!tableOption[15].value} >Departemen </td>
                            <td hidden={!tableOption[3].value} >Tanggal Bergabung </td>
                            <td hidden={!tableOption[4].value} >Status Karyawan </td>
                            <td hidden={!tableOption[16].value} >Unit Bisnis </td>
                            <td hidden={!tableOption[5].value}>Awal Kontrak </td>
                            <td hidden={!tableOption[6].value}>Akhir Kontrak</td>
                            <td hidden={!tableOption[7].value}>No KTP</td>
                            <td hidden={!tableOption[8].value}>No NPWP</td>
                            <td hidden={!tableOption[9].value}>Status Keluarga</td>
                            <td hidden={!tableOption[10].value} className="tableWidth">Email</td>
                            <td hidden={!tableOption[11].value}>No.HP</td>
                            <td hidden={!tableOption[12].value}>Nama Bank</td>
                            <td hidden={!tableOption[13].value}>Nomor Rekening</td>

                        </tr>

                        {listDataPrint && listDataPrint.map((print, index) => {
                            return (
                                <div>
                                    <tr key={index} className="table-caption-body">
                                        <td>{index + 1}</td>
                                        <td hidden={!tableOption[0].value} className="tableWidth">{print.employee_number}</td>
                                        <td hidden={!tableOption[1].value}>{print.staff_name}</td>
                                        <td hidden={!tableOption[2].value}>{print.position_name}</td>
                                        <td hidden={!tableOption[14].value}>{print.division_code}</td>
                                        <td hidden={!tableOption[15].value}>{print.department_code}</td>
                                        <td hidden={!tableOption[3].value}>{print.join_date ? moment(print.join_date).format("DD/MM/yyyy") : ""}</td>
                                        <td hidden={!tableOption[4].value}>{print.employee_status_name}</td>
                                        <td hidden={!tableOption[16].value}>{print.unit_name}</td>
                                        <td hidden={!tableOption[5].value}>{print.start_date && print.employee_status_name !== "Permanent" ? moment(print.start_date).format("DD/MM/yyyy") : ""}</td>
                                        <td hidden={!tableOption[6].value}>{print.end_date && print.employee_status_name !== "Permanent" ? moment(print.end_date).format("DD/MM/yyyy") : ""}</td>
                                        <td hidden={!tableOption[7].value}>{print.ktp_number}</td>
                                        <td hidden={!tableOption[8].value}>{print.npwp_number}</td>
                                        <td hidden={!tableOption[9].value}>{print.family_status}</td>
                                        <td hidden={!tableOption[10].value} className="tableWidth">{print.email}</td>
                                        <td hidden={!tableOption[11].value}>{print.phone_number_1}</td>
                                        <td hidden={!tableOption[12].value}>{print.bank_name}</td>
                                        <td hidden={!tableOption[13].value}>{print.bank_account_number}</td>


                                    </tr>




                                </div>
                            )
                        })
                        }

                    </table>

                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        borderStyle: "solid",
                        borderWidth: 1
                    }}>


                    </div>


                </div>

                <Modal size="xl" show={modalOptionPrint} onHide={() => {
                    setModalOptionPrint(false);
                    setGenerateCSVStatus(0);
                    setListDataPrintCsv([]);
                    // setStatusIdPrint("")
                }} >
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Print Data Karyawan`}
                        useActionContainer={true}
                        actionContainerChild={
                            <div id="button-color">
                                <Button variant="primary" style={{

                                }} onClick={() => {
                                    setModalOptionPrint(false);
                                    setGenerateCSVStatus(0);
                                    setListDataPrintCsv([]);
                                    // setStatusIdPrint("")
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquare size={32} /></div>

                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{

                                padding: 10,
                            }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                        width: "100%"
                                    }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <div style={{
                                            paddingRight: 10,
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%"
                                        }}>
                                            <Form.Control type="date" onChange={(e) => {
                                                if (moment(e.target.value).format('yyyy-MM-DD') > moment(endJoinDatePrint).format('yyyy-MM-DD')) {
                                                    setStartJoinDatePrint("")
                                                } else {
                                                    setStartJoinDatePrint(e.target.value)
                                                }

                                            }} value={startJoinDatePrint}></Form.Control>
                                        </div>
                                        <div style={{
                                            paddingRight: 10,
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%"
                                        }}>
                                            <Form.Control type="date" onChange={(e) => {
                                                if (moment(e.target.value).format('yyyy-MM-DD') < moment(startJoinDatePrint).format('yyyy-MM-DD')) {
                                                    setEndJoinDateprint("")
                                                } else {
                                                    setEndJoinDateprint(e.target.value)
                                                }

                                            }} value={endJoinDatePrint}></Form.Control>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        flex: 1
                                    }}>
                                         <Select styles={styles} isMulti={true} placeholder=""
                                            clearValue={true} closeMenuOnSelect={false}
                                            getOptionLabel={(item) => {
                                                return item.employee_status_name;
                                            }}
                                            getOptionValue={(item) => {
                                                return item.id;
                                            }}
                                            options={listEmployeeStatusPrint} value={statusIdPrint} onChange={(e) => {
                                                setStatusIdPrint(e);
                                            }} isClearable

                                        />
                                        {/* <Select placeholder={""}
                                            getOptionLabel={(item) => {
                                                return item.employee_status_name;
                                            }} clearValue={true}
                                            getOptionValue={(item) => {
                                                return item.id;
                                            }}
                                            options={listEmployeeStatusPrint} value={listEmployeeStatusPrint.filter(function (option) {
                                                return option.id === statusIdPrint;
                                            })} onChange={(e) => {
                                                setPage(0)
                                                // setLoading(true);
                                                if (e === null) {
                                                    // setUpdateStatus(1);
                                                    setStatusIdPrint(0);

                                                } else {
                                                    // setUpdateStatus(1);
                                                    setStatusIdPrint(e.id)
                                                }
                                            }}
                                            isClearable

                                        /> */}

                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        flex: 1
                                    }}>
                                        <Form.Select required onChange={(e) => {
                                            // setDivision(e.target.value);
                                            setPage(0);
                                            // setLoading(true);
                                            setIsResignPrint(e.target.value);
                                        }} value={isResignPrint} >
                                            <option value={0}>
                                                {window.location.pathname === "/Mechanic" ? `${t("index_technician_page.checkbox_active_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.checkbox_active_crew")}` : `${t("index_staff_page.checkbox_active_staff")}`}
                                            </option>
                                            <option value={1}>
                                                {window.location.pathname === "/Mechanic" ? `${t("index_technician_page.leave_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.leave_crew")}` : `${t("index_staff_page.leave_staff")}`}
                                            </option>
                                            <option value={3}>{window.location.pathname === "/Mechanic" ? `${t("index_technician_page.all_staff")}` : window.location.pathname === "/Crew" ? `${t("index_staff_page.all_crew")}` : `${t("index_staff_page.all_staff")}`}</option>
                                        </Form.Select>
                                    </div>
                                    {/* <div style={{ paddingRight: 10, display: "flex", flexDirection: "column", width: "100%" }}>

                                    </div>
                                    <div style={{
                                        paddingRight: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%"
                                    }}>

                                    </div> */}
                                </div>
                                <div style={{ paddingBottom: 30 }}></div>
                                <div className="table-container">
                                    <div className="table-header">
                                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                        <div style={{ flex: 2 }} className="table-header-content">Nama Tabel</div>
                                        <div style={{ flex: 2 }} className="table-header-content">Tampilkan</div>

                                    </div>
                                    {
                                        tableOption.map((options, index) => {
                                            return (<div key={index} className="table-body">
                                                <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{index + 1}</p></div>
                                                <div style={{ flex: 2 }} className="table-body-content"><p>{options.name}</p></div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content"><p>
                                                    <Form.Check onClick={(e) => {
                                                        if (e.target.checked) {
                                                            let newList = tableOption;
                                                            tableOption[index].value = true
                                                            setTableOption(newList.slice(0))
                                                        }
                                                        else {
                                                            let newList = tableOption;
                                                            tableOption[index].value = false
                                                            setTableOption(newList.slice(0))
                                                        }
                                                    }} defaultChecked={options.value}>

                                                    </Form.Check>
                                                </p></div>

                                            </div>)
                                        }
                                        )
                                    }
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingTop: 30
                                }}>
                                    <Button onClick={() => {
                                        setLoading(true);
                                        setIdPrint(1);
                                        setModalOptionPrint(false);
                                        // setStatusIdPrint("")
                                        // printPageStock();
                                    }}>
                                        Print <Printer />
                                    </Button>
                                    {
                                        genenrateCSVStatus === 0 ?
                                            <Button disabled={statusLoadCsv} onClick={() => {
                                                loadDataPrintCSV();
                                                setLoading(true);
                                                setStatusLoadCsv(true);
                                            }}>
                                                {!statusLoadCsv ? "Generate Csv" : "Mohon Tunggu...."}
                                            </Button>
                                            :
                                            // <Button style={{color:"white"}}>
                                            <div onClick={() => {
                                                setModalOptionPrint(false);
                                                // setLoading(true);
                                                setGenerateCSVStatus(0);
                                                setStatusLoadCsv(false);
                                            }} style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%"
                                            }}>
                                                <CSVLink
                                                    data={listDataPrintCsv}
                                                    filename={"Staff-Report.csv"}
                                                    // role="button"
                                                    className="btn btn-primary"
                                                    target="_blank"
                                                >
                                                    Download CSV<Printer />

                                                </CSVLink>
                                            </div>
                                        // </Button>

                                    }




                                </div>
                            </div>
                        }
                    />


                </Modal>
            </Container>

        </>
    )
}