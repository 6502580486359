import ReloadPict from "../../Assets/Images/reload-pict.png"
import "./NumStatMainEngine.css"

export default function NumStatMainEngine({ text, temperature }) {
    return (
        <div className="NumStatMainEngine">
            <div>
                {text}
            </div>
            <div className="statCel">{temperature} C</div>
            <div><img src={ReloadPict} className="reloadPic" alt={"Reload Pict"} /></div>
        </div>
    )
}

