import React, { useEffect, useState, useRef } from "react";
import { Modal, Col, Button, Row, Form, Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../Config/Config";
import { useCookies } from "react-cookie";
import { isTokenValid } from "../../Helpers/ApplicationHelpers";
import "../../App.css";
import "./Login.css";
import RPILogo from "../../Assets/Images/rpi-logo.svg";
import { Font } from "@react-pdf/renderer";

export default function LoginPage() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(true);
  const [cookies, setCookie] = useCookies(["token", "userId", "userName", "userEmail"]);
  const [errorMessage, setErrorMessage] = useState("");
  const txtUsername = useRef(null);
  const txtPassword = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function checkCookies() {
      if (cookies.token) {
        let isAuthenticated = await isTokenValid(cookies.token);
        if (isAuthenticated)
          navigate("/Dashboard");
      } else {
        navigate("/");
      }
    }
    checkCookies();
    let interval = setInterval(() => {
      if (txtPassword.current) {
        setPassword(txtPassword.current.value);
        clearInterval(interval);
      }
    }, 100);
    let interval2 = setInterval(() => {
      if (txtUsername.current) {
        setUsername(txtUsername.current.value)
        clearInterval(interval2)

      }
    }, 100);

  }, []);

  useEffect(() => {
    checkUsernamePasswordLength();
  }, [username]);

  useEffect(() => {
    checkUsernamePasswordLength();
  }, [password]);

  const login = async () => {
    try {
      let response = await axios.post(`${config.API_ENDPOINT}/api/UserAccount/login`, {
        username: username,
        password: password
      });
      if (response.data.error_code === 0 && response.data.data.customer_id === "fab7b2b6-cb86-4b74-ba8e-036361f24115" || response.data.data.customer_id === null) {
        let loginData = response.data.data;
        loginData.loging_as = 'User';
        setCookie("token", loginData.token, { path: "/" });
        setCookie("userId", loginData.user_account_id, { path: "/" });
        setCookie("userName", loginData.login_username, { path: "/" });
        setCookie("userEmail", loginData.email, { path: "/" });
        setCookie("languageId", loginData.language_id, { path: "/" });
        setCookie("staffId", loginData.staff_id, { path: "/" });
        setCookie("roleId", loginData.role_id, { path: "/" });
        setCookie("level", loginData.user_level, { path: "/" });
        setCookie("division", loginData.division_code, { path: "/" });
        setCookie("extCustomerId", loginData.customer_id, { path: "/" });
        setCookie("logingAs", loginData.loging_as, { path: "/" });
        setCookie("language_code", loginData.language_iso_code, { path: "/" });
        navigate("/Dashboard");
      } else {
        setErrorMessage("Failed to sign in, wrong username and/or password.");
      }

    }
    catch (exception) {
      console.log(exception);
      setErrorMessage("Error has been occurred, please contact system administrator");
    }
  }

  const checkUsernamePasswordLength = () => {
    if (username.length > 0 && password.length > 0)
      setLoginButtonDisabled(false);
    else
      setLoginButtonDisabled(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Container>
        <div className="d-flex align-items-center justify-content-center">
          <div className="rpi-logo-container d-flex align-items-center justify-content-center">
            <img src={RPILogo} height={200} />
          </div>
        </div>
        <div className="rpi-tagline d-flex align-items-center justify-content-center">
          Solution is Our Service
        </div>
        <h2 className="mt-4 p-3 text-center rounded login-title">PLANNED MAINTENANCE SYSTEM</h2>
        <Row className="mt-4">
          <Col lg={4}
            md={6}
            sm={12}
            className="p-3 m-auto shadow-sm rounded-lg">
            <Form onSubmit={(e) => {
              e.preventDefault();
              login();
            }}>
              <p className="text-center login-text" style={{ fontSize: 20 }}>Login To Continue</p>
              <Form.Group controlId="formBasicUsername">
                <Form.Label className="login-label">Username</Form.Label>
                <Form.Control onChange={(e) => {
                  setUsername(e.target.value);
                  checkUsernamePasswordLength();
                }} value={username} ref={txtUsername} type="text" placeholder="Username" />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="login-label">Password</Form.Label>
                <Form.Control onChange={(e) => {
                  setPassword(e.target.value);
                  checkUsernamePasswordLength();
                }} value={password} ref={txtPassword} type="password" placeholder="Password" />
              </Form.Group>
              <div className="d-flex justify-content-between">
                <div className="mb-2 form-check">

                  {
                    errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                  }

                </div>

                {/* <div>
                    <a href="#" className="link">Forgot Password ?</a>
                  </div> */}
              </div>

              <div className="d-grid mt-3">
                <Button disabled={loginButtonDisabled} type="submit" className="btn btn-primary btn-login" >
                  Login
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        {/* <div style={{paddingBottom: 50}}></div> */}
        {/* <h6 className="mt-5 p-5 text-center text-secondary ">Copyright © 2023 PT Quantum Pesona Dunia. All Rights Reserved.</h6> */}
      </Container>
    </div >

  );
}

