import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import { getWarehouse, deleteWarehouse, getWarehouseCount, isTokenValid, insertUpdateWarehouse, getWarehouseById,
        getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";
import { Trans, useTranslation } from "react-i18next";


export default function WarehousePage() {
    const [listWarehouse, setListWarehouse] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [warehouseId, setWarehouseId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [warehouseModal, setWarehouseModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_warehouse.order_name1")}`,
        value: "warehouse_name"
    });
    const orderByList = [{
        label: `${t("index_warehouse.order_name1")}`,
        value: "warehouse_name"
    }, {
        label: `${t("index_warehouse.order_name2")}`,
        value: "warehouse_code"
    }, {
        label: `${t("index_warehouse.order_name3")}`,
        value: "warehouse_address"
    }];
    const [warehouseCount, setWarehouseCount] = useState(0);
    const [descending, setDescending] = useState(false);
    const [warehouse, setWarehouse] = useState({
        id: "0",
        warehouse_name: "",
        warehouse_code: "",
        warehouse_address: "",
        ext_customer_id: cookies.extCustomerId
    })

   
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadWarehouse();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadWarehouse();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadWarehouse();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadWarehouse();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadWarehouse();
    }, [resetSearch]);

    useEffect(() => {
        if(isSearched !== 0)
        loadWarehouse()
    }, [descending]);

    useEffect(() => {
        if (warehouseId !== "" && warehouseId !== undefined)
            initWarehouse();
    }, [warehouseId]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeWarehouse();
    }, [removeId])

    useEffect(() => {
        if (!warehouseModal)
            setWarehouseId("");
    }, [warehouseModal])


    const loadWarehouse = async () => {
        try {
            let count = await getWarehouseCount(cookies.token, cookies.extCustomerId, searchQuery, false);
            setWarehouseCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
    
    
            let response = await getWarehouse(cookies.token,cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListWarehouse(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }
    
    }

    const clearWarehouseModal = async () => {
        let warehouseTmp = {};
        warehouseTmp.id = 0;
        warehouseTmp.warehouse_name = "";
        warehouseTmp.warehouse_code = "";
        warehouseTmp.warehouse_address = "";
        warehouseTmp.ext_customer_id = cookies.extCustomerId;
        setWarehouse(warehouseTmp);
    }

    const saveData = async () => {
        try {
            let warehouseTmp = warehouse;
            warehouseTmp.ext_customer_id = warehouseTmp.ext_customer_id === null ? null : warehouseTmp.ext_customer_id
            let response = await insertUpdateWarehouse(cookies.token, warehouseTmp);
            if (response.error_code === 0) {
                alert(`${t("index_warehouse.alert_success_save_data")}`);
                setWarehouseModal(false);
                clearWarehouseModal();
                loadWarehouse();
                setDisabledButton(false);
            } else {
                alert(`${t("index_warehouse.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const initWarehouse = async () => {
        try {
            let response = await getWarehouseById(cookies.token, warehouseId);
            if (response) {
                setWarehouse({
                    ...warehouse,
                    id: response.id,
                    warehouse_name: response.warehouse_name,
                    warehouse_code: response.warehouse_code,
                    warehouse_address: response.warehouse_address
                })
            }
            setWarehouseModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeWarehouse = async () => {
        try {
            let response = await deleteWarehouse(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_warehouse.delete_success_alert")}`);
                loadWarehouse();
            } else {
                alert(`${t("index_warehouse.delete_failed_alert")}`);
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_warehouse.order_name1")}`,
                value: "warehouse_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_warehouse.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false :true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setWarehouseModal(true);
                                setWarehouse({ ...warehouse, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_warehouse.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_warehouse.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadWarehouse();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadWarehouse();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadWarehouse();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_warehouse.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_warehouse.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_warehouse.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_warehouse.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_warehouse.table_name5")}</div>
                                </div>
                                {
                                    listWarehouse.map((warehouses, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 0.5, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{warehouses.warehouse_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{warehouses.warehouse_code}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{warehouses.warehouse_address}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idWarehouse = warehouses.id;
                                                        setWarehouseId(idWarehouse);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"? true:!deletedButton} variant="danger" onClick={() => {
                                                        let idWarehouse = warehouses.id;
                                                        if (window.confirm(`${t("index_warehouse.delete_confirmation")} ${warehouses.warehouse_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idWarehouse);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />


                {/* <Modal show={warehouseModal} onHide={() => {
                    setWarehouseModal(false);
                    clearWarehouseModal();
                }}>
                    <ContainerBox
                        containerPos="inner"
                        titleCaption={`Data Gudang`}
                        childContent={
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flex: 1,
                                padding: 10,
                            }}>
                                <Form onSubmit={(e) => {
                                    setDisabledButton(true);
                                    e.preventDefault();
                                    saveData()
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flexWrap: "nowrap",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Nama Gudang</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control style={{
                                                    display: "flex",
                                                    minWidth: "100%",
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                }} onChange={(e) => {
                                                    setWarehouse({ ...warehouse, warehouse_name: e.target.value })
                                                }} value={warehouse.warehouse_name} type="text" placeholder="" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Kode Gudang</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setWarehouse({ ...warehouse, warehouse_code: e.target.value })
                                                }} value={warehouse.warehouse_code} type="text" placeholder="" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        flex: 1,
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flexDirection: "column",
                                        }} className="mb-3">
                                            <Form.Label>Alamat Gudang</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                            }}>
                                                <Form.Control onChange={(e) => {
                                                    setWarehouse({ ...warehouse, warehouse_address: e.target.value })
                                                }} value={warehouse.warehouse_address} type="text" placeholder="" required></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div style={{
                                        paddingTop: 20,
                                    }}>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        flex: 1,
                                    }}>
                                        <Button variant="primary" type="submit" disabled={disabledButton}>
                                            Simpan
                                        </Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            clearWarehouseModal();
                                            setWarehouseModal(false);
                                        }}>
                                            Batal
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    />
                </Modal> */}

                <Modal show={warehouseModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setWarehouseModal(false);
                        clearWarehouseModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_warehouse.header_add_modal")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_warehouse.field_warehouse_name")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setWarehouse({ ...warehouse, warehouse_name: e.target.value })
                                }} value={warehouse.warehouse_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_warehouse.field_warehouse_code")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setWarehouse({ ...warehouse, warehouse_code: e.target.value })
                                }} value={warehouse.warehouse_code} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_warehouse.field_warehouse_address")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setWarehouse({ ...warehouse, warehouse_address: e.target.value })
                                }} value={warehouse.warehouse_address} as="textarea" row={3} placeholder="" required></Form.Control>
                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? 
                                <></>
                                :
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "center"
                                }}>
                                    <Button style={{width: 100}} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_warehouse.save_button")}</Button>
                                    <Button style={{width: 100}} className="cancel" variant="danger" onClick={() => {
                                         clearWarehouseModal();
                                        setWarehouseModal(false);
                                    }}>{t("index_warehouse.cancel_button")}</Button>
                                </div>
                            }
                         

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}