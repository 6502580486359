
import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form, Alert,Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById, getStaff, getDivision } from "../../../Helpers/ApplicationHelpers";
import {getStockCountByCategory, getinventoryListToPrint, getInventoryUnitType, getInventoryMaster, findAllStockByCategory, getinventoryListToPrintCategory, deleteInventoryStock } from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import { BoxArrowUpRight, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise, PrinterFill, Printer } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import ContainerBox from "../../../Components/ContainerBox";
import Select from "../../../Components/FormSelect"
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

export default function InventoryGroupPage () {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [dateNow, setDateNow] = useState("");
    const [division, setDivision] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [detailedSearch, setDetailedSearch] = useState(false);
    const [isDetailSearched, setIsDetailSearched] = useState(false);
    const [resetSearch, setResetSearch] = useState(0);
    const [idStock, setIdStock] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [descending, setDescending] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [staffName, setStaffName] = useState("");
    const [totalStock, setTotalStock] = useState("");
    const [listPrint, setListPrint] = useState("");
    const [listInventoryWorkshop, setListInventoryWorkshop] = useState([]);
    const [inventoryWorkshopCount, setInventoryWorkshopCount] = useState();
    const [inventoryCategoryId, setInventoryCategoryId] = useState("78d5334b-fd10-49ee-b9cc-3c917ae7bdba")
    const [listDivision, setListDivision] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState();
    const [listUnitType, setListUnitType] = useState([]);
    const [selectedUnitType, setSelectedUnitType] = useState();
    const [searchQueryInventory, setSearchQueryInventory] = useState("");
    const [findInventoryModal, setFindInventoryModal] = useState(false);
    const [currentDate, setCurrentDate] = useState(moment(new Date()).format("DD-MM-yyyy HH:mm"));
    const [checkOnDetailSearch, setCheckOnDetailSearch] = useState(false);

    const [orderBy, setOrderBy] = useState({
        label: `${t("index_inventory_group.order_name2")}`,
        value: "inventory_master_name"
    });

    const orderByList = [
        {
            label: `${t("index_inventory_group.order_name2")}`,
            value: "inventory_master_name"
        },
        // {
        //     label: `${t("index_inventory_group.order_name3")}`,
        //     value: "type_name"
        // },
        // {
        //     label: "Unit",
        //     value: "unit"
        // },
        // {
        //     label: "Harga Referensi",
        //     value: "reference_price"
        // },
        // {
        //     label: "Merk",
        //     value: "brand"
        // },
        // {
        //     label: "Model",
        //     value: "model"
        // },
        // {
        //     label: "Jumlah Stock",
        //     value: "Quantity"
        // },
        // {
        //     label: "Tanggal Input",
        //     value: "inventory.created_date"
        // }


    ]

    const [workshopCodeSearched, setWorkshopCodeSearched] = useState({
        isSearched: false,
        label: "workshopCategory",
        value: ""
    });
    
    const [workshopNameSearched, setWorkshopNameSearched] = useState({
        isSearched: false,
        label: "workshopName",
        value: ""
    });

    const [workshopTypeSearched, setWorkshopTypeSearched] = useState({
        isSearched: false,
        label: "workshopType",
        value: ""
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadAccess();
                loadStaff();
                loadDivision();
                loadUnitType();
                loadInventoryWorkshop();
                setLoading(false);
            }
        }
        checkCookies();
        console.log(cookies);
    }, []);

    useEffect(() => {
        if (idStock !== "")
            loadPrintList();
    }, [idStock]);

    useEffect(() => {
        console.log('print idstock from useeffect: ', idStock);
        // if (listPrint.length > 0) {
        //     // handlesum();
        //     if (idStock === 0) {
        //         printPageArea();
        //         setIdStock("")
        //     } else if (idStock === 1) {
        //         printPageStock();
        //         setIdStock("")
        //     }
        //     setLoading(false);
        // }

        if (idStock === 0) {
            printPageArea();
            setIdStock("")
        } else if (idStock === 1) {
            printPageStock();
            setIdStock("")
        }
        setLoading(false);
    }, [listPrint])

    useEffect(() => {
        setLoading(true);
        loadInventoryWorkshop();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryWorkshop();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryWorkshop();
        }
    }, [orderBy]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadInventoryWorkshop();
        }
    }, [descending]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadInventoryWorkshop();
    }, [resetSearch]);

    useEffect(() => {
        console.log(selectedDivision);
    }, [selectedDivision]);

    const printPageArea = () => {
        console.log('print idstock printable area: ', idStock);  
        var prtContent = document.getElementById("printableArea");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("index_inventory_group.header_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }

    const printPageStock = () => {
        console.log('print idstock page stock: ', idStock);  
        var prtContent = document.getElementById("printStock");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var logo = document.getElementById('radian-img');
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `${t("index_inventory_group.header_title")}`;
        WinPrint.print();
        // WinPrint.close();
        logo.src = "/static/media/radian-logo-side.effdf434dec4faa325ef.png";
    }
    
    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname, cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_inventory_group.order_name2")}`,
                value: "inventory_master_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaff = async () => {
        try {
            let response = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            let newStaff = response.find(p => p.id === cookies.staffId);
            if (newStaff.staff_name) {
                setStaffName(newStaff.staff_name);
            }
        } catch (exception) {

        }
    }

    const loadDivision = async () => {
        try {
            let list = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId, 0, 100000, "division_name", false, undefined, false);
            setListDivision(list);
            
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadUnitType = async () => {
        try {
            let response = await getInventoryUnitType(cookies.token);
            setListUnitType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadPrintList = async () => {
        try {
            let total = 0;
            let responseList = await getinventoryListToPrintCategory(cookies.token, cookies.languageId, inventoryCategoryId);
            for (let index = 0; index < responseList.length; index++) {
                total += Number(responseList[index].quantity);
            }
            console.log(responseList);
            setTotalStock(total);
            setListPrint(responseList);
            console.log('setelah setlistprint: ')

            if (responseList.length === 0) {
                setIdStock("");
                setLoading(false)
                alert(`${t("index_inventory_group.text_not_data")}`);

            }
            console.log('akhir try dari loadprintlist');
        } catch (exception) {
            console.log(exception)
        }
    }

    // useEffect(() => {
    //     console.log("isDetailedSearch", isDetailSearched)
    // }, [isDetailSearched])

    const loadInventoryWorkshop = async () => {
        detailSearchOnCheck();
        let detailedSearchValue = [];
        detailedSearchValue.push(workshopCodeSearched, workshopNameSearched, workshopTypeSearched)
        let newDetail = detailedSearchValue.filter(p=>p.isSearched === true);

        let  isDetailSearchedTmp = isDetailSearched
        if(newDetail.length>0){
            isDetailSearchedTmp =true
            setIsDetailSearched(true);
        }else{
            isDetailSearchedTmp =false
            setIsDetailSearched(false);
        }

        let count = await getStockCountByCategory(cookies.token, cookies.languageId, cookies.extCustomerId, searchQuery, isDetailSearchedTmp, detailedSearchValue, inventoryCategoryId);
        console.log(count);
        setInventoryWorkshopCount(count);
        let totalPage = count / itemPerPage;
        setTotalPage(totalPage);
        let tmpSearchQuery = undefined;
        if (searchQuery) {
            tmpSearchQuery = searchQuery;
        }
        console.log(detailedSearchValue);
        let response = await findAllStockByCategory(cookies.token, cookies.languageId, cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, isDetailSearchedTmp, detailedSearchValue, inventoryCategoryId);
        console.log('responseWorkshopFromAPI',response);
        setListInventoryWorkshop(response);
        let dates = new Date()
        setDateNow(moment(dates).format("DD-MM-yyyy HH:mm"))
        setIsSearched(0);
        setLoading(false);
    }

    const deleteInventory = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("index_inventory_group.delete_confirmation")}`) == true) {
                let deleteSelectedProject = await deleteInventoryStock(cookies.token, id);
                if (deleteSelectedProject.success) {
                    if (deleteSelectedProject.error_code === 0) {
                        alert(`${t("index_inventory_group.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("index_inventory_group.delete_faield_alert")}`);
                    }
                }
                else {
                    alert(`${t("index_inventory_group.delete_failed_alert")}`);
                }
                loadInventoryWorkshop();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }

    const findInventory = async (id) => {
        try{
            // let listInventoryMaster = await getInventoryMaster(cookies.token, cookies.languageId, cookies.extCustomerId, division, page, itemPerPage, orderBy.value, descending, searchQuery, isDetailSearchedTmp,detailedSearchValue);
            // setListInventoryMaster(listInventoryMaster);
        } catch (exception){
            console.log(exception); 
        }
    }

    const formatRupiah = (bilangan) => {
        var number_string = bilangan.toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah;

    }

    const detailSearchOnCheck = async () => {
        try {
            if (!workshopCodeSearched.isSearched && !workshopNameSearched.isSearched && !workshopTypeSearched.isSearched) {
                console.log("none")
                setCheckOnDetailSearch(false);
            } else{
                console.log("detailed search on")
                setCheckOnDetailSearch(true);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }


    return(
        <>
        <Navbar />
        <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_inventory_group.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button  variant="secondary" style={{

                            }} onClick={() => { 
                                navigate("/InventoryStock", {state: {inventoryMasterId: "", backButton: '/InventoryGroup', categoryTitle: 'Group', inventoryCategoryId: '78d5334b-fd10-49ee-b9cc-3c917ae7bdba'}})
                                }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_inventory_group.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                {/* <div style={{
                                    paddingRight: 10
                                }}>

                                    <Form.Select required onChange={(e) => {
                                        setDivision(e.target.value);
                                    }} value={division} >
                                        <option selected value="">
                                            {t("index_inventory_group.dropdown_division")}
                                        </option>
                                        {listDivision.map((division, index) => (
                                            <option key={index} value={division.id}>{division.division_name} ({division.division_code})</option>
                                        ))}
                                    </Form.Select>

                                </div> */}
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    alignItems: "center",
                                    paddingRight: 10,
                                    pointer: "cursor"
                                }}>

                                    <Form.Check checked={checkOnDetailSearch} onClick={(e) => {
                                        if (!detailedSearch){
                                            setDetailedSearch(true);
                                        } 
                                        // if (e.target.checked) {
                                        //     setDetailedSearch(true);
                                        // } else {
                                        //     setDetailedSearch(false);
                                        // }
                                    }} label={`${t("index_vessel.detail_search")}`}></Form.Check>
                                </div>
                                <div className="master-table-searchbar-textbox">


                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_inventory_group.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0)
                                                setLoading(true);
                                                await loadInventoryWorkshop();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} disabled={checkOnDetailSearch ? true : false}/>
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0)
                                            setLoading(true);
                                            await loadInventoryWorkshop();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            // setLoading(true);
                                            setSearchQuery("");
                                            setDivision("");
                                            setResetSearch(1);
                                            setIsDetailSearched(false);
                                            setWorkshopCodeSearched({...workshopCodeSearched, isSearched:false, value: ""});
                                            setWorkshopNameSearched({...workshopNameSearched, isSearched:false, value: ""});
                                            setWorkshopTypeSearched({...workshopTypeSearched, isSearched:false, value: ""});
                                            loadInventoryWorkshop();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={() => {
                                            setIdStock(1);
                                            setLoading(true);
                                            // printPageStock();
                                        }}><Printer /> {t("index_inventory_group.button_print_stock")}</Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={() => {
                                            setIdStock(0);
                                            setLoading(true);
                                            // printPageStock();
                                            // printPageArea
                                        }}
                                        ><PrinterFill /> {t("index_inventory_group.button_print_detail")}</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container" style={{ marginBottom: '15px'}}>
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_inventory_group.table_name11")}</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_inventory_group.table_name1")}</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_inventory_group.table_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name24")}</div>
                                    {/* <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name25")}</div> */}
                                    {/* <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name26")}</div> */}
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name27")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_inventory_group.table_name28")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_inventory_group.table_name29")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name8")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name10")}</div>
                                </div>
                                {
                                    listInventoryWorkshop.map((inventory, index) => {
                                        return (<div className="table-body">
                                           <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.inventory_code}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.inventory_master_name}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{inventory.inventory_type_name}</p></div>
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.division_code}</p></div>
                                            {/* <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.inventory_status_name}</p></div> */}
                                            {/* <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.inventory_condition_name}</p></div> */}
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{inventory.weight_gram ? inventory.weight_gram + 'g' : inventory.weight_gram}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{moment(inventory.purchase_date).format("DD-MM-yyyy")}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{inventory.category_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/InventoryStock", { state: { inventoryMasterId: inventory.inventory_master_id, backButton: '/InventoryGroup', inventoryCategoryId: '78d5334b-fd10-49ee-b9cc-3c917ae7bdba', categoryTitle: 'Group'} });
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={false} variant="danger" onClick={() => {
                                                        // console.log(inventory.id);
                                                        deleteInventory(inventory.id);
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    })
                                }
                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    }
                />

                <div id="printableArea" style={{ display: 'none', padding: 10 }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ width: '70%' }}>
                        <img src={require("../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>

                    <div style={{
                        textAlign: "center",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >{t("index_inventory_master.header_title")} </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%", fontWeight: "bold" }}>

                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{staffName}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{dateNow}</td>
                            </tr>
                        </Table>

                    </div>
                    <div style={{ paddingBottom: 5 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr style={{ fontWeight: "bold" }}>
                            <td>No </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name1")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name2")} </td>
                            <td>{t("index_inventory_master.table_name3")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name4")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_master.table_name5")} </td>
                            <td>{t("index_inventory_master.table_name7")} </td>
                            <td>{t("index_inventory_master.table_name6")} </td>
                            <td>{t("index_inventory_master.table_name17")} </td>
                            <td>{t("index_inventory_master.table_name18")} </td>
                        </tr>

                        {listPrint && listPrint.map((print, index) => {
                            console.log(print) ;
                            return (
                                <div>
                                    <tr key={index} className="table-caption-body">
                                        <td style={{ fontWeight: "bold" }}>{index + 1}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.inventory_master_name}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.type_name}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.division_code}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.brand}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.model}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.bulk_item ? print.list_stock.length : print.quantity }</td>
                                        <td style={{ fontWeight: "bold" }}>{print.unit_name}</td>
                                        <td style={{ fontWeight: "bold" }}>{print.quantity}</td>
                                        <td style={{ fontWeight: "bold" }}>{!print.bulk_item ? 0 : Number(print.list_stock.length) - Number(print.quantity)}</td>

                                    </tr>

                                    {
                                        print.list_stock.length > 0 ?
                                            <tr >
                                                <td className="tableClass"  ></td>
                                                {/* <td></td> */}
                                                <td colSpan={5} className="tableClass" >
                                                    <Table className="tableClass">
                                                        <thead className="tableClass" style={{ fontWeight: "bold" }}>
                                                            <tr className="tableClass">
                                                                {/* <td colSpan={3}>No </td> */}
                                                                <th className="tableClass">{t("index_inventory_master.table_name11")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name12")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name13")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name14")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name15")} </th>
                                                                <th className="tableClass">{t("index_inventory_master.table_name16")} </th>
                                                            </tr>

                                                        </thead>
                                                        {print.list_stock && print.list_stock.map((stock, index) => {
                                                            return (
                                                                <div>
                                                                    <tbody key={index} className="tableClass">
                                                                        {/* <td colSpan={3}>{index + 1}</td> */}
                                                                        <td className="tableClass">{stock.inventory_code}</td>
                                                                        <td className="tableClass">{stock.inventory_status_name}</td>
                                                                        <td className="tableClass">{stock.inventory_condition_name}</td>
                                                                        <td className="tableClass">{moment(stock.created_date).format("DD-MM-yyyy")}</td>
                                                                        <td className="tableClass">{moment(stock.purchase_date).format("DD-MM-yyyy")}</td>
                                                                        <td className="tableClass">{stock.staff_name}</td>

                                                                    </tbody>

                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </Table>

                                                </td>
                                                <td className="tableClass"  ></td>
                                                <td className="tableClass"  ></td>
                                            </tr>
                                            :
                                            <tr>
                                                <td className="tableClass" colSpan={10} style={{ textAlign: "center" }} >{t("index_inventory_master.text_no_stock")}</td>
                                            </tr>
                                    }



                                </div>
                            )
                        })
                        }
                        <tr style={{ fontWeight: "bold" }} >
                            <td style={{ textAlign: "right" }} colSpan={7}>{t("index_inventory_master.text_total_stock")} :</td>
                            <td colSpan={7}>{totalStock}</td>
                        </tr>

                    </table>

                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        borderStyle: "solid",
                        borderWidth: 1
                    }}>
                        {/* <div style={{
                            display: "flex",
                            flexDirection:"row",
                            flex:7,
                            justifyContent:"flex-end",
                            fontWeight:"bold"
                        }}>
                            <div>Total Stok :</div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection:"row",
                            flex:1,
                            justifyContent:"center",
                            fontWeight:"bold"
                        }}>
                            <div>{totalStock}</div>
                        </div> */}

                        {/* <tr className="tableClass">
                            <td className="tableClass">Total Stok</td>
                            <td className="tableClass">:</td>
                            <td className="tableClass">{totalStock}</td>
                        </tr> */}

                    </div>
                    {/* <div style={{ float: 'right', width: '65%', marginBottom: '20px' }}>
                        <Table className="tableClass" border={1}>

                            <tr className="tableClass">
                                <td className="tableClass">Total Stok</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{totalStock}</td>
                            </tr>

                        </Table>

                    </div> */}

                </div>

                <div id="printStock" style={{ display: 'none', padding: 10 }}>
                    {/* <div id="printableArea"> */}
                    <div style={{ width: '70%' }}>
                        <img src={require("../../../Assets/Images/radian-logo-side.png")} id="radian-img" alt="foto radian" />
                    </div>

                    <div style={{
                        // marginLeft: 300,
                        textAlign: "center",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        width: '100%'
                    }}>
                        <h3 >{t("index_inventory_master.header_title")} </h3>

                    </div>

                    <div style={{ float: 'left', width: '65%' }}>
                        <Table className="tableClass" style={{ width: "70%", fontWeight: "bold" }}>

                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_by")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{staffName}</td>
                            </tr>
                            <tr className="tableClass">
                                <td className="tableClass">{t("index_inventory_master.text_print_date")}</td>
                                <td className="tableClass">:</td>
                                <td className="tableClass">{dateNow}</td>
                            </tr>
                        </Table>

                    </div>
                    <div style={{ paddingBottom: 5 }}></div>

                    {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                    <table >
                        <tr style={{ fontWeight: "bold" }}>
                            <td style={{ width: '5%' }}>No </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_group.table_print1")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_group.table_print2")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print3")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print4")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print5")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print6")} </td>
                            <td style={{ width: '5%' }}>{t("index_inventory_group.table_print7")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_group.table_print8")} </td>
                            <td style={{ width: '15%' }}>{t("index_inventory_group.table_print9")} </td>

                        </tr>

                        {listPrint && listPrint.map((print, index) => {
                            return (
                                <div>
                                    <tr key={index} className="table-caption-body">
                                        <td>{index + 1}</td>
                                        <td>{print.inventory_code}</td>
                                        <td>{print.inventory_master_name}</td>
                                        <td>{print.category_name}</td>
                                        <td>{print.inventory_status_name}</td>
                                        <td>{print.inventory_condition_name}</td>
                                        <td>{print.division_code !== "NONE" ? print.division_code : "-"}</td>
                                        <td>{print.weight_gram}</td>
                                        <td>{print.purchase_date}</td>
                                        <td>{"Rp" + formatRupiah(print.purchase_price)}</td>


                                    </tr>
                                </div>
                            )
                        })
                        }
                        <tr style={{ fontWeight: "bold" }} >
                            <td style={{ textAlign: "right" }} colSpan={9}>{t("index_inventory_workshop.text_total_item")} :</td>
                            <td colSpan={7}>{listPrint.length}</td>
                        </tr>

                    </table>

                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        borderStyle: "solid",
                        borderWidth: 1
                    }}>


                    </div>


                </div>

                <Modal show={detailedSearch}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setDetailedSearch(false);
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Pencarian</h3>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            padding: 10
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1
                                }}>
                                    <Form.Check checked={workshopCodeSearched.isSearched} onClick={(e) => {
                                        if (e.target.checked) {
                                            setWorkshopCodeSearched({ ...workshopCodeSearched, isSearched: true })
                                        } else {
                                            setWorkshopCodeSearched({ ...workshopCodeSearched, isSearched: false, value: "" })
                                        }
                                    }}></Form.Check>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 4,
                                    paddingRight: 5
                                }}>
                                    <Form.Control disabled value={`${t("index_inventory_workshop.table_name1")}`}></Form.Control>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 7,
                                    paddingLeft: 5
                                }}>
                                    <Form.Control disabled={!workshopCodeSearched.isSearched} value={workshopCodeSearched.value} onChange={(e) => {
                                        setWorkshopCodeSearched({ ...workshopCodeSearched, value: e.target.value })
                                    }}></Form.Control>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1
                                }}>
                                    <Form.Check checked={workshopNameSearched.isSearched} onClick={(e) => {
                                        if (e.target.checked) {
                                            setWorkshopNameSearched({ ...workshopNameSearched, isSearched: true })
                                        } else {
                                            setWorkshopNameSearched({ ...workshopNameSearched, isSearched: false, value: "" })
                                        }
                                    }}></Form.Check>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 4,
                                    paddingRight: 5
                                }}>
                                    <Form.Control disabled value={`${t("index_inventory_workshop.order_name2")}`}></Form.Control>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 7,
                                    paddingLeft: 5
                                }}>
                                    <Form.Control disabled={!workshopNameSearched.isSearched} value={workshopNameSearched.value} onChange={(e) => {
                                        setWorkshopNameSearched({ ...workshopNameSearched, value: e.target.value })
                                    }}></Form.Control>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1
                                }}>
                                    <Form.Check checked={workshopTypeSearched.isSearched} onClick={(e) => {
                                        if (e.target.checked) {
                                            setWorkshopTypeSearched({ ...workshopTypeSearched, isSearched: true })
                                        } else {
                                            setWorkshopTypeSearched({ ...workshopTypeSearched, isSearched: false, value: "" })
                                        }
                                    }}></Form.Check>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 4,
                                    paddingRight: 5
                                }}>
                                    <Form.Control disabled value={`${t("index_inventory_workshop.order_name3")}`}></Form.Control>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 7,
                                    paddingLeft: 5
                                }}>
                                    <Form.Control disabled={!workshopTypeSearched.isSearched} value={workshopTypeSearched.value} onChange={(e) => {
                                        setWorkshopTypeSearched({ ...workshopTypeSearched, value: e.target.value })
                                    }}></Form.Control>
                                </div>
                            </div>
                            
                            <div style={{
                                display: 'flex',
                                flexDirection: "column",
                                padding: 10,
                                width: "100%"
                            }}>
                                <Button onClick={() => {
                                    setDetailedSearch(false);
                                    loadInventoryWorkshop();
                                    setPage(0);
                                }}><Search size={30} /></Button>
                            </div>

                        </div>


                    </div>

                </Modal>

                <Modal show={findInventoryModal}

                    size={"xl"}
                    onHide={() => {
                        // handleResets();
                        setFindInventoryModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{"Find Inventory List"} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            console.log("Find Inventory")
                            findInventory();


                        }} style={{ padding: 10 }}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("Jenis Barang")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_type_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listUnitType} value={selectedUnitType} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedUnitType({ id: "" });
                                        } else {

                                            setSelectedUnitType(e);
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("Division")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.division_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listDivision} value={selectedDivision} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedDivision({ id: "" });
                                        } else {
                                            setSelectedDivision(e);
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_inventory_material")}</Form.Label>
                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.inventory_material_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryMaterial} value={selectedMaterial} onChange={(e) => {
                                        if (e === null) {
                                            setSelectedMaterial({ id: "" });
                                        } else {
                                            setSelectedMaterial(e);
                                            setInventoryMaster({ ...inventoryMaster, inventory_material_id: e.id, inventory_material_name: e.inventory_material_name });
                                        }
                                    }}
                                    isClearable
                                    isDisabled={false}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("detail_inventory_master.field_brand")}</Form.Label>

                                <Select placeholder={""}
                                    getOptionLabel={(item) => {
                                        return item.brand_name;
                                    }} clearValue={true}
                                    getOptionValue={(item) => {
                                        return item.id;
                                    }}
                                    options={listInventoryBrand} value={selectedBrand} onChange={(e) => {
                                        if (e === null) {
                                            console.log(e);
                                            setSelectedBrand({})
                                        } else {
                                            setSelectedBrand(e);
                                        }

                                    }}
                                    isClearable
                                    required
                                />

                            </Form.Group> */}

                            <Form.Group className="mb-3">
                                <Form.Label>{t("sparepart_catalogue.search_button_by_name")}</Form.Label>
                                <Form.Control style={{height: '45px'}} value={searchQueryInventory} type="text"
                                onChange={(e) => {
                                    setSearchQueryInventory(e.target.value);
                                }} />
                            </Form.Group>
                            
                            <div className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <Button style={{ width: 100 }} className="save-button" type="submit" disabled={false}>{t("sparepart_catalogue.search_button")}</Button>
                                <Button style={{ width: 100 }} className="cancel cancel-button" variant="danger" onClick={() => {
                                    // handleResets();
                                    setFindInventoryModal(false);
                                }}>{t("sparepart_catalogue.cancel_button")}</Button>
                            </div>
                        </Form>
                        <div className="master-table-inner-container">
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_inventory_group.table_name1")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name2")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_inventory_group.table_name3")}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
            </Container>
        </>
    )
}