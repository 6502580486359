import axios from "axios";
import Config from "../Config/Config";

export const getVesselClassificationSociety = async (token, languageId, offset = 0, limit = 50, orderBy = "society_name", descending = false, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/findAllSociety`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselClassificationSocietyById = async (token, languageId, societyId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/findSocietyById`, {
            params: {
                language_id: languageId,
                id: societyId,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselClassificationSocietyCount = async (token, languageId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/countSociety`, {
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const insertUpdateSociety = async (token, society, societyId) => {
    try {
        const payload = {
            society_name: society.societyName,
            country_id: society.countryId,
            society_name: society.societyName,
            society_desc: society.societyDesc === undefined || society.societyDesc === null ? "" : society.societyDesc,
            society_code: society.societyCode,
            language_id: society.languageId,
            id: societyId
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselClassification/insertUpdateSociety`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSociety = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselClassification/deleteSociety`, {
            id: id
        },
            {
                headers: {
                    token: token
                }
            }
        );
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getVesselClassificationApplication = async (token, languageId, offset = 0, limit = 50, orderBy = "application_name", descending = false, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/findAllApplication`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselClassificationApplicationById = async (token, languageId, societyId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/findApplicationById`, {
            params: {
                language_id: languageId,
                id: societyId,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselClassificationApplicationCount = async (token, languageId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/countApplication`, {
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0].count);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const insertUpdateApplication = async (token, application, applicationId) => {
    try {
        const payload = {
            application_name: application.applicationName,
            application_desc: application.applicationDesc === undefined || application.applicationDesc === null ? "" : application.applicationDesc,
            language_id: application.languageId,
            id: applicationId
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselClassification/insertUpdateApplication`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteApplication = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselClassification/deleteApplication`, {
            id: id
        },
            {
                headers: {
                    token: token
                }
            }
        );
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getVesselClassificationCharacter = async (token, languageId, offset = 0, limit = 50, orderBy = "society.society_name", descending = false, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/findAllCharacter`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselClassificationCharacterById = async (token, languageId, characterId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/findCharacterById`, {
            params: {
                language_id: languageId,
                id: characterId,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselClassificationCharacterCount = async (token, languageId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/countCharacter`, {
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0].count);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const insertUpdateCharacter = async (token, character, characterId) => {
    try {
        const payload = {
            vessel_classification_society_id: character.societyId,
            vessel_classification_application_id: character.applicationId,
            character_symbol_image: character.characterSymbolImage,
            character_desc: character.characterDesc === undefined || character.characterDesc === null ? "" : character.characterDesc,
            language_id: character.languageId,
            id: characterId
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselClassification/insertUpdateCharacter`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCharacter = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselClassification/deleteCharacter`, {
            id: id
        },
            {
                headers: {
                    token: token
                }
            }
        );
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}


export const getVesselClassificationNotation = async (token, languageId,societyId= "", offset = 0, limit = 100000, orderBy = "notation.notation_name", descending = false, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/findAllNotation`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                society_id: societyId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselClassificationNotationById = async (token, languageId, notationId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/findNotationById`, {
            params: {
                language_id: languageId,
                id: notationId,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselClassificationNotationCount = async (token, languageId, searchQuery, detailedSearch, societyId = "") => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselClassification/countNotation`, {
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                society_id: societyId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0].count);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const insertUpdateNotation = async (token, notation, notationId) => {
    try {
        const payload = {
            vessel_classification_society_id: notation.societyId,
            notation_code: notation.notationCode,
            notation_name: notation.notationName,
            notation_desc: notation.notationDesc === undefined || notation.notationDesc === null ? "" : notation.notationDesc,
            language_id: notation.languageId,
            id: notationId
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselClassification/insertUpdateNotation`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteNotation = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselClassification/deleteNotation`, {
            id: id
        },
            {
                headers: {
                    token: token
                }
            }
        );
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}