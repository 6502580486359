import React, { useState, useEffect } from "react";
import { Button, Form, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { isTokenValid, getCompanyCategoryById, insertUpdateCompanyCategory, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import DropDownForm from "../../../../Components/DropDownForm";
import ButtonForm from "../../../../Components/ButtonForm";
import FormSelect from "../../../../Components/FormSelect";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function CompanyCategoryDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const { t, i18n } = useTranslation();
    const [companyCategory, setCompanyCategory] = useState({
        id: 0,
        company_category: "",
        description: "",
        language_id: cookies.languageId,
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }

            }
        }
        checkCookies();
        if (location.state.companyCategoryId === "") {

        } else {
            initCompanyCategory();
        }
    }, []);


    const initCompanyCategory = async () => {
        try {
            let response = await getCompanyCategoryById(cookies.token, location.state.companyCategoryId);
            if (response) {
                setCompanyCategory({
                    ...companyCategory,
                    id: response.id,
                    company_category: response.company_category,
                    description: response.description
                })
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const addCompanyCategory = async () => {
        try {
            let response = await insertUpdateCompanyCategory(cookies.token, companyCategory);
            if (response === 0) {
                alert(`${t("detail_company_category.alert_success_save_data")}`);
                navigate("/CompanyCategory");

            } else {
                alert(`${t("detail_company_category.failed_save_data")}`);
            }
            setLoading(false);
            setDisabledButton(false);

        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_company_category.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/CompanyCategory"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                                paddingTop: 10
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_company_category.container_detail_category")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>

                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{t("detail_company_category.field_name_company_category")}</Form.Label>
                                                            <Form.Control onChange={(e) => {
                                                                setCompanyCategory({ ...companyCategory, company_category: e.target.value })
                                                            }} type="text" value={companyCategory.company_category} required ></Form.Control>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label> {t("detail_company_category.field_description")}</Form.Label>
                                                            <Form.Control onChange={(e) => {
                                                                setCompanyCategory({ ...companyCategory, description: e.target.value })
                                                            }} as="textarea" rows={3} value={companyCategory.description} ></Form.Control>
                                                        </Form.Group>


                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>

                            <ButtonForm addData={() => {
                                addCompanyCategory();
                                setDisabledButton(true);
                            }}
                                saveButton={`${t("detail_company_category.save_button")}`}
                                cancelButton={`${t("detail_company_category.cancel_button")}`}
                                button={disabledButton === true ? disabledButton : !updatedButton} />
                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}