import "../../SMSApp.css";
import "./index.css";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BitLamp from "../../Components/BitLamp/BitLamp";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { isTokenValid, getPLCCoordinate, getPlcValue, getPLCCoordinateAuxEngine, getEquipmentDetail } from "../../Helpers/ApplicationHelpers";
import Speedometer, { Arc, Needle, Progress, Marks, Indicator, DangerPath } from 'react-speedometer';
import Loading from "../../Components/Loading";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import { Row, Form, Modal, Button } from "react-bootstrap";
import ContainerBox from "../../Components/ContainerBox";

export default function AuxEngine() {

    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listAuxEngine, setListAuxEngine] = useState([]);
    const [listEngine1, setListEngine1] = useState([]);
    const [listEngine2, setListEngine2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("");
    const [equipmentModal, setEquipmentModal] = useState("");
    const [detailEquipment, setDetailEquipment] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);
    

    useEffect(() => {
        if (listEngine1.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadCoordinateValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [listEngine1, isDataLoaded]);

    // useEffect(() => {
    //     if (listEngine1.length > 0) {

    //         const interval = setInterval(async () => {
    //             loadCoordinateValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [listEngine1]);

    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail();
    }, [vesselEquipmentId]);

    const loadEquipmentDetail = async () => {
        try {
            let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
            console.log(response);
            setDetailEquipment(response);
            setEquipmentModal(true);
        } catch (exception) {

        }
    }



    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinateAuxEngine(cookies.token, "aux_engine", cookies.vesselId);
            setListAuxEngine(response);
            let engine1 = response.filter(p => p.coordinate_group === "aux_engine1" || p.coordinate_group === 'ae1_dummy');
            setListEngine1(engine1);
            let engine2 = response.filter(p => p.coordinate_group === "aux_engine2");
            setListEngine2(engine2);

            if (response.length < 1) {
                setLoading(false);
            }
            setLoading(false);
        } catch (exception) {

        }
    }

    const loadCoordinateValue = async () => {
        try {
            setIsDataLoaded(true);
            let coordinate = listAuxEngine.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            console.log(listEngine1)
            let updatedList = listEngine1.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    let valueEngine = 0
                    if(item.plc_coordinate === 'AI11'){
                      let valueMa = (Number(alarmValue.value)/4095)*20
                      valueEngine = ((valueMa - 4) / 16) * (10 - 1.01325) + 1.01325
                    }else if(item.plc_coordinate === 'AI10'){
                        let valueMa = ((Number(alarmValue.value)-815)/4095)*200
                        valueEngine = valueMa * 1
                    }else{
                        valueEngine = alarmValue.value
                    }
                    
                    return { ...item, value: valueEngine };
                } else {
                    return { ...item, value: Number(item.value) };
                }
            });
            setListEngine1(updatedList);

            let updatedList2 = listEngine2.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.plc_coordinate);
                if (alarmValue) {
                    return { ...item, value: alarmValue.value };
                } else {
                    return { ...item, value: Number(item.value) };
                }
            });
            setListEngine2(updatedList2);

            setLoading(false);
            setIsDataLoaded(false);
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"AUXILARY ENGINE"} /></div>
            <div className="contentAuxEngine">
                <div className="leftContent">
                    {
                        listEngine1.length > 0 && listEngine1.map((aux, index) => {
                            return (
                                <>
                                    {
                                        aux.component_type === "gauge" ?
                                        <div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "100%",
                                                flexWrap: "nowrap"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 1
                                                }}>
                                                    <div className="titleContent">{aux.coordinate_desc}</div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    flex: 1,
                                                    paddingRight: 10,
                                                    paddingTop: 5
                                                }}>
                                                    <a type="button" onClick={() => {
                                                        // alert(pump.equipment_id);
                                                        setVesselEquipmentId(aux.equipment_id)
                                                    }}> <InfoCircle size={25} /></a>
                                                </div>
                                            </div>

                                            <div className="gauge">
                                                <Speedometer
                                                    width={400}
                                                    height={400}
                                                    rotation={-150}
                                                    min={0}
                                                    max={4000}
                                                    value={0}
                                                    angle={250}
                                                    fontFamily='squada-one'>
                                                    <Arc color="#D9D9D9" />
                                                    <Needle color="#D9D9D9" />
                                                    <DangerPath angle={62} />
                                                    <Progress color="#D9D9D9" />
                                                    <Marks step={500} />
                                                    <Indicator>

                                                    </Indicator>

                                                </Speedometer>
                                            </div>
                                            
                                        </div>
                                        :
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            flexWrap: "nowrap",
                                            width: "100%",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            // borderStyle:"solid",
                                            fontSize: 30,
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "100%",
                                                marginLeft: 340
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 20
                                                }}>{aux.coordinate_desc}</div>
                                              
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 5
                                                }}>{aux.value.toFixed(2 )}</div>
                                                 <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 25
                                                }}>{aux.plc_coordinate === 'AI11' ? "BAR" : <div>&deg;C</div>}</div>
                                            </div>
                                           


                                        </div>



                                    }


                                </>
                            )
                        })
                    }
                </div>
                <div className="rightContent">
                    {
                        listEngine2.length > 0 && listEngine2.map((aux, index) => {
                            return (
                                <div key={index}>
                                    {
                                        aux.component_type === "gauge" &&
                                        <div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "100%",
                                                flexWrap: "nowrap"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 1
                                                }}>
                                                    <div className="titleContent">{aux.coordinate_desc}</div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    flex: 1,
                                                    paddingRight: 10,
                                                    paddingTop: 5
                                                }}>
                                                    <a type="button" onClick={() => {
                                                        // alert(pump.equipment_id);
                                                        setVesselEquipmentId(aux.equipment_id)
                                                    }}> <InfoCircle size={25} /></a>
                                                </div>
                                            </div>

                                            <div className="gauge">
                                                <Speedometer
                                                    width={400}
                                                    height={400}
                                                    rotation={-150}
                                                    min={0}
                                                    max={4000}
                                                    value={0}
                                                    angle={250}
                                                    fontFamily='squada-one'>
                                                    {/* <Background
                                            angle={250}
                                        /> */}
                                                    <Arc color="#D9D9D9" />
                                                    <Needle color="#D9D9D9" />
                                                    <DangerPath angle={62} />
                                                    <Progress color="#D9D9D9" />
                                                    <Marks step={500} />
                                                    <Indicator>

                                                    </Indicator>

                                                </Speedometer>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 1,
                                                flexWrap: "nowrap",
                                                width: "100%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                // borderStyle:"solid",
                                                fontSize: 30,
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                                alignSelf: "center",
                                            }}>
                                                
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "100%",
                                                   paddingLeft:200
                                                    // marginLeft: 340
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 20
                                                    }}>Coolant Temperature</div>
                                                   
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 5
                                                    }}>0</div>
                                                      <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 25
                                                }}><div>&deg;C</div></div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "100%",
                                                    // marginLeft: 340
                                                    paddingLeft:200
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 20
                                                    }}>Coolant Pressure</div>
                                                   
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 5
                                                    }}>0</div>
                                                      <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 25
                                                }}>BAR</div>
                                                </div>


                                            </div>
                                        </div>



                                    }


                                </div>
                            )
                        })
                    }
                </div>
                {/* <div className="topRow">
                        <div className="titleContent">Auxilary Engine #1</div>

                    </div>
                    <div className="topRow">
                        <div className="titleContent">Auxilary Engine #2</div>


                    </div> */}


            </div>
            <Loading
                loading={loading}
            />
            <div style={{ paddingBottom: 120 }}></div>
            <Modal show={equipmentModal}
                // dialogClassName="modal-size"
                size={"xl"}
                onHide={() => {
                    setEquipmentModal(false);
                    setDetailEquipment({});
                    setVesselEquipmentId("")
                    // clearInventoryTypeModal()
                }}>

                {/*  */}
                <ContainerBox
                    containerPos="inner"
                    titleCaption={`Equipment Detail`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setEquipmentModal(false);
                                setDetailEquipment({});
                                setVesselEquipmentId("")
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Close</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <Form style={{ padding: 10 }}>


                            <Form.Group className="mb-3">
                                <Form.Label>Equipment Category</Form.Label>
                                <Form.Control disabled value={detailEquipment.equipment_name}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Manufacture Name</Form.Label>
                                <Form.Control disabled value={detailEquipment.manufacturer_name}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control disabled value={detailEquipment.model}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Serial Number</Form.Label>
                                <Form.Control disabled value={detailEquipment.serial_number}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control disabled value={detailEquipment.part_number}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Position</Form.Label>
                                <Form.Control disabled value={detailEquipment.position}></Form.Control>
                            </Form.Group>


                        </Form>
                    }
                />



            </Modal>
            <div><BottomNavBar /></div>
        </>
    );
}