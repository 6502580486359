import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../Config/Config";
import { useCookies } from "react-cookie";
import { getBusinessUnit, deleteBusinessUnit, getCountBusinessUnit, isTokenValid, insertUpdateBusinessUnit, getBusinessUnitById,
        getUserAccessById
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import { EyeFill, PencilFill, FilePlusFill, ArrowClockwise, Search, Trash } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import ContainerBox from "../../../Components/ContainerBox";
import TextBox from "../../../Components/Textbox";
import { Trans, useTranslation } from "react-i18next";


export default function BusinessUnitPage() {
    const [listBusinessUnit, setListBusinessUnit] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [isSearched, setIsSearched] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [unitId, setUnitId] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [businessUnitModal, setBusinessUnitModal] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `Nama Unit Bisnis`,
        value: "unit_name"
    });
    const orderByList = [{
        label: `Nama Unit Bisnis`,
        value: "unit_name"
    }, {
        label: `Kode Unit Bisnis`,
        value: "unit_code"
    }];
    const [warehouseCount, setWarehouseCount] = useState(0);
    const [descending, setDescending] = useState(false);
    const [businessUnit, setBusinessUnit] = useState({
        id: "0",
        unit_name: "",
        unit_code: "",
        language_id: cookies.languageId,
        ext_customer_id: cookies.extCustomerId
    })

   
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBusinessUnit();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadBusinessUnit();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadBusinessUnit();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadBusinessUnit();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadBusinessUnit();
    }, [resetSearch]);

    useEffect(() => {
        if(isSearched !== 0)
        loadBusinessUnit()
    }, [descending]);

    useEffect(() => {
        if (unitId !== "" && unitId !== undefined)
            initBusinessUnit();
    }, [unitId]);

    useEffect(() => {
        if (removeId !== "" && removeId !== undefined)
            removeBusinessUnit();
    }, [removeId])

    useEffect(() => {
        if (!businessUnitModal)
            setUnitId("");
    }, [businessUnitModal])


    const loadBusinessUnit = async () => {
        try {
            let count = await getCountBusinessUnit(cookies.token, searchQuery, false);
            setWarehouseCount(count);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);
    
    
            let response = await getBusinessUnit(cookies.token, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListBusinessUnit(response)
            setLoading(false);
            setResetSearch(0);
            setIsSearched(0);
        } catch (exception) {
            console.log(exception);
        }
    
    }

    const clearBusinessUnitModal = async () => {
        let businessUnitTmp = {};
        businessUnitTmp.id = 0;
        businessUnitTmp.unit_name = "";
        businessUnitTmp.unit_code = "";
        businessUnitTmp.language_id = cookies.languageId;
        businessUnitTmp.ext_customer_id = cookies.extCustomerId;
        setBusinessUnit(businessUnitTmp);
    }

    const saveData = async () => {
        try {
            let businessUnitTmp = businessUnit;
            businessUnitTmp.ext_customer_id = businessUnitTmp.ext_customer_id === null ? null : businessUnitTmp.ext_customer_id
            let response = await insertUpdateBusinessUnit(cookies.token, businessUnitTmp);
            if (response.error_code === 0) {
                alert(`${t("index_warehouse.alert_success_save_data")}`);
                setBusinessUnitModal(false);
                clearBusinessUnitModal();
                loadBusinessUnit();
                setDisabledButton(false);
            } else {
                alert(`${t("index_warehouse.alert_failed_save_data")}`)
            }

        } catch (exception) {
            console.log(exception)
        }
    }

    const initBusinessUnit = async () => {
        try {
            let response = await getBusinessUnitById(cookies.token, unitId);
            if (response) {
                setBusinessUnit({
                    ...businessUnit,
                    id: response.id,
                    unit_name: response.unit_name,
                    unit_code: response.unit_code,
                    language_id: response.language_id
                })
            }
            setBusinessUnitModal(true);


        } catch (exception) {
            console.log(exception)
        }
    }

    const removeBusinessUnit = async () => {
        try {
            let response = await deleteBusinessUnit(cookies.token, removeId);
            if (response === 0) {
                alert(`${t("index_warehouse.delete_success_alert")}`);
                loadBusinessUnit();
            } else {
                alert(`${t("index_warehouse.delete_failed_alert")}`);
            }

            setLoading(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setDeletedButton(true);
            setUpdatedButton(true);
            // setOrderBy({
            //     label: `${t("index_warehouse.order_name1")}`,
            //     value: "unit_name"
            // })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`Daftar Unit Bisnis`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false :true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => {
                                setBusinessUnitModal(true);
                                setBusinessUnit({ ...businessUnit, id: 0 })
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Tambah Data</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`Kata Kunci (Nama Unit Bisnis)`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadBusinessUnit();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadBusinessUnit();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadBusinessUnit();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Nama Unit Bisnis</div>
                                    <div style={{ flex: 3 }} className="table-header-content">Kode Unit Bisnis</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                </div>
                                {
                                    listBusinessUnit.map((units, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{units.unit_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{units.unit_code}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        let idUnit = units.id;
                                                        setUnitId(idUnit);
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null"? true:!deletedButton} variant="danger" onClick={() => {
                                                        let idUnit = units.id;
                                                        if (window.confirm(`${t("index_warehouse.delete_confirmation")} ${units.unit_name}?`)) {
                                                            setLoading(true);
                                                            setRemoveId(idUnit);
                                                        }

                                                    }}><Trash />
                                                    </Button>
                                                </div></div>


                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />


                

                <Modal show={businessUnitModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setBusinessUnitModal(false);
                        clearBusinessUnitModal();
                        // clearInventoryTypeModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Detail Unit Bisnis </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            setDisabledButton(true);
                            e.preventDefault();
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>Nama Unit Bisnis</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setBusinessUnit({ ...businessUnit, unit_name: e.target.value })
                                }} value={businessUnit.unit_name} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Kode Unit Bisnis</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setBusinessUnit({ ...businessUnit, unit_code: e.target.value })
                                }} value={businessUnit.unit_code} type="text" placeholder="" required></Form.Control>
                            </Form.Group>

                          


                            <div style={{ paddingBottom: 10 }}></div>
                            {/* {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? 
                                <></>
                                : */}
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "center"
                                }}>
                                    <Button style={{width: 100}} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("index_warehouse.save_button")}</Button>
                                    <Button style={{width: 100}} className="cancel" variant="danger" onClick={() => {
                                         clearBusinessUnitModal();
                                        setBusinessUnitModal(false);
                                    }}>{t("index_warehouse.cancel_button")}</Button>
                                </div>
                            {/* } */}
                         

                        </Form>



                    </div>

                </Modal>
            </Container >
        </>
    );

}