import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import { insertUpdateInventoryUnitType, getInventoryUnitTypeById } from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function InventoryUnitTypeDetailPage() {
    const { t, i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [unitType, setUnitType] = useState({
        id: 0,
        unit_name: "",
        unit_code: "",
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
        if (location.state.unitTypeId === 0) {
            setLoading(false);
        } else {
            initInventoryUnitType();
        }
    }, []);




    const saveData = async () => {
        try {
            let response = await insertUpdateInventoryUnitType(cookies.token, unitType);
            if (response.error_code === 0) {
                alert(`${t("detail_inventory_unit_type.alert_success_save_data")}`);
                navigate("/InventoryUnitType")
            } else {
                alert(`${t("detail_inventory_unit_type.alert_failed_save_data")}`)
            }
            setDisabledButton(false);
        } catch (exception) {
            // setErrorMessage("Data Gagal Disimpan");
        }
    }

    const initInventoryUnitType = async () => {
        try {
            let response = await getInventoryUnitTypeById(cookies.token, location.state.unitTypeId);
            if (response) {
                setUnitType({
                    ...unitType,
                    id: response.id,
                    unit_name: response.unit_name,
                    unit_code: response.unit_code,
                })
                setLoading(false);
            } else {
                alert(`${t("detail_inventory_unit_type.alert_failed_load_data")}`);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            
            // setDeletedButton(true);
            // setUpdatedButton(true);
        } catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={`${t("detail_inventory_unit_type.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/InventoryUnitType"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_inventory_unit_type.header_unit")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    saveData();
                                                    setDisabledButton(true);
                                                }}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_inventory_unit_type.field_unit_name")}</Form.Label>
                                                        <Form.Control required value={unitType.unit_name} onChange={(e) => {
                                                            setUnitType({ ...unitType, unit_name: e.target.value })
                                                        }}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("detail_inventory_unit_type.field_unit_code")}</Form.Label>
                                                        <Form.Control required value={unitType.unit_code} onChange={(e) => {
                                                            setUnitType({ ...unitType, unit_code: e.target.value })
                                                        }}></Form.Control>
                                                    </Form.Group>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        flexWrap: "nowrap",
                                                        flex: 1
                                                    }}>
                                                        <div >
                                                            <Button disabled={disabledButton === true ? disabledButton : !updatedButton} style={{ width: 100, paddingRight: 5 }} type="submit" variant="primary">{t("detail_inventory_unit_type.save_button")}</Button>
                                                        </div>
                                                        <div className="cancel">
                                                            <Button  style={{ width: 100, paddingLeft: 5 }} variant="danger" onClick={() => {
                                                                navigate("/InventoryUnitType")
                                                            }}>{t("detail_inventory_unit_type.cancel_button")}</Button>
                                                        </div>

                                                    </div>
                                                </Form>



                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>
                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}