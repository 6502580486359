import "../../SMSApp.css";
import "./index.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import BottomNavBar from "../../Components/BottomNavBar/BottomNavBar";
import TitleAlarmBar from "../../Components/TitleAlarmBar/TitleAlarmBar";
import { Col, Row, Form, Modal } from "react-bootstrap";
import BarGraph from "../../Components/BarGraph/BarGraph";
import { Anchor, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import BitLamp from "../../Components/BitLamp/BitLamp";
import { isTokenValid, getPlcValue, getTankLevelCoordinate, getCountCoordinateTankLevel, getWireBreakTankLevel, getEquipmentDetail, calculateTankSounding } from "../../Helpers/ApplicationHelpers";
import TankLevel from "../../Components/TankLevel/TankLevel";
import Paginations from "../../Components/Pagination";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";

export default function TankLevels() {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(true)
    const [itemPerPage, setItemPerPage] = useState(9);
    const [cookies, setCookie] = useCookies(["token"]);
    const [listTankLevel, setListTankLevel] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [listWireBreak, setListWireBreak] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentid] = useState("");
    const [equipmentModal, setEquipmentModal] = useState("");
    const [detailEquipment, setDetailEquipment] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadCoordinate();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (vesselEquipmentId !== "")
            loadEquipmentDetail();
    }, [vesselEquipmentId])

    useEffect(() => {
        // if (isSearched !== 0)
        loadCoordinate();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0)
            loadCoordinate();
    }, [itemPerPage]);


    // useEffect(() => {

    //     if (listTankLevel.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }


    // }, [listTankLevel]);
    // useEffect(() => {
    //     if (listTankLevel.length > 0 && isDataLoaded === false) {
    //         const loadData = async () => {
    //             await loadBitlampValue();
    //         };
    
    //         // Load data immediately
    //         loadData();
    
    //         // Set interval to load data every 1000 ms
    //         const interval = setInterval(loadData, 1000);
    
    //         // Cleanup function to clear the interval
    //         return () => clearInterval(interval);
    //     }
    // }, [listTankLevel]);

    useEffect(() => {
        if (listTankLevel.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadBitlampValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [listTankLevel, isDataLoaded]);

    const loadEquipmentDetail = async () => {
        try {
            let response = await getEquipmentDetail(cookies.token, vesselEquipmentId);
            console.log(response);
            setDetailEquipment(response);
            setEquipmentModal(true);
        } catch (exception) {

        }
    }

    const loadBitlampValue = async () => {
        try {
            setIsDataLoaded(true);
            let coordinate = listTankLevel.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            // let valueTank = await calculateTankSounding(cookies.token, cookies.vesselId, page , itemPerPage);
           
            let updatedList = listTankLevel.map(item=>{
                // let tank = valueTank.find(p=>item.plc_coordinate === p.plc_coordinate);
                // if(tank){
                //     if(item.plc_coordinate === 'AI0'){
                //         return{...item,volume: tank.volume, tank_level: tank.tank_level}
                //     }else{
                        return{...item,volume: 0, tank_level:0}
                    // }
                   
                // }else{
                //     return{...item,volume: item.volume, tank_level: item.tank_level}
                // }
            })
            



            let updatedListWireBreak = listWireBreak.map(item => {
                let wireBreak = response.find(p => item.plc_coordinate === p.plc_coordinate);
                if (wireBreak) {
                    return { ...item, alarm: wireBreak.value === 1 };

                } else {
                    return { ...item, alarm: item.alarm };

                }
            });
            if (isSearched === 0) {
                setListTankLevel(updatedList);
                setListWireBreak(updatedListWireBreak);
            }


            setLoading(false);
            setIsDataLoaded(false);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCoordinate = async () => {
        try {
            // setIsSearched(1);
            // let count = await getCountCoordinateTankLevel(cookies.token);
            // let totalPage = count / itemPerPage;
            // setTotalPage(totalPage);

            let response = await getTankLevelCoordinate(cookies.token, page, itemPerPage, cookies.vesselId);


            let wireBreaks = await getWireBreakTankLevel(cookies.token, page, itemPerPage, cookies.vesselId);
            setListWireBreak(wireBreaks)
            setListTankLevel(response);
            setIsSearched(0);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }
    return (
        <div>
            <div>
                <TopNavBar />
            </div>
            <div>
                <TitleAlarmBar text={"Tank Level"} />
            </div>
            <div className="boxLST">
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    padding: 10
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingRight: 10,
                        width: 100
                    }}>
                        <Button
                            className="btnAlarms"
                            variant="outline-primary"
                            onClick={() => {
                                // navigate('/BilgesTanks')
                                navigate("/BilgesTanks2");
                            }}
                        >
                            Page 1
                        </Button>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 10,
                        width: 100
                    }}>

                        <Button
                            className="btnAlarms"
                            variant="outline-primary"
                            onClick={() => {
                                // navigate("/BilgesTanks2");
                                navigate('/BilgesTanks')
                            }}
                        >
                            Page 2
                        </Button>
                    </div>


                </div>

                <div className="contentLST">

                    {
                        listWireBreak.length > 0 && listTankLevel.length > 0 &&
                        <Col md={12}>
                            <Row>
                                {
                                    listTankLevel.map((tanks, index) => {
                                        return (
                                            <Col md={4} key={index} style={{ paddingBottom: 20 }}>
                                                <div key={index} style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexWrap: "nowrap",
                                                    flexDirection: "column",
                                                    borderStyle: "groove",

                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        flexWrap: "nowrap"
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            flex: 1
                                                        }}>
                                                            <p>{tanks.coordinate_desc}</p>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "flex-end",
                                                            flex: 1,
                                                            paddingRight: 5
                                                        }}>
                                                            <a type="button" onClick={() => {
                                                                // alert(pump.equipment_id);
                                                                setVesselEquipmentid(tanks.equipment_id)
                                                            }}> <InfoCircle size={25} /></a>
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "row",
                                                        paddingBottom: 20,
                                                        flexWrap: "nowrap",
                                                        justifyContent: "center",

                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            // marginTop:20,
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}>
                                                            <p>Liquid Level</p>
                                                            <BitLamp text={""} alarm={listWireBreak[index].alarm} ></BitLamp>
                                                            <p>Wire Break</p>
                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",

                                                        }}>
                                                            <TankLevel
                                                                text={""}
                                                                valueBG={tanks.tank_level}
                                                            />
                                                            <div>Volume(m3) : {tanks.volume}</div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </Col>


                                        )
                                    })
                                }
                            </Row>
                        </Col>

                    }


                </div>
                <Loading
                    loading={loading}
                />
            </div>
            <div style={{ paddingBottom: 120 }}></div>

            <Modal show={equipmentModal}
                // dialogClassName="modal-size"
                size={"xl"}
                onHide={() => {
                    setEquipmentModal(false);
                    setDetailEquipment({});
                    setVesselEquipmentid("")
                    // clearInventoryTypeModal()
                }}>

                {/*  */}
                <ContainerBox
                    containerPos="inner"
                    titleCaption={`Equipment Detail`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{

                            }} onClick={() => {
                                setEquipmentModal(false);
                                setDetailEquipment({});
                                setVesselEquipmentid("")
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Close</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <Form style={{ padding: 10 }}>


                            <Form.Group className="mb-3">
                                <Form.Label>Equipment Category</Form.Label>
                                <Form.Control disabled value={detailEquipment.equipment_name}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Manufacture Name</Form.Label>
                                <Form.Control disabled value={detailEquipment.manufacturer_name}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control disabled value={detailEquipment.model}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Serial Number</Form.Label>
                                <Form.Control disabled value={detailEquipment.serial_number}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control disabled value={detailEquipment.part_number}></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Position</Form.Label>
                                <Form.Control disabled value={detailEquipment.position}></Form.Control>
                            </Form.Group>

                        </Form>
                    }
                />



            </Modal>
            <div>
                <BottomNavBar />
            </div>
        </div>
    );
}