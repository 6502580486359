import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Clock from "react-live-clock";
import "./TitleAlarmBar.css"

export default function TitleAlarmBar({ text }) {
    return (
        <div className="titleAlarmBar">
            <h4>{text}</h4>
            <div className="alarmBar">
                <div className="contentAlarmBar">
                    <FontAwesomeIcon icon={"fa-bell"} fontSize={20} />
                    <Clock format={"DD/MM/YYYY"} ticking={true} timezone={"Asia/Jakarta"} className="date1" />
                    <Clock format={"hh:mm:ss"} ticking={true} timezone={"Asia/Jakarta"} className="date2" />
                    <div>Event 0</div>
                </div>
            </div>
        </div>
    )
}