import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate,useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getUserAccessById, getVesselInventoryById, insertUpdateVesselInventoryMasterStock, getVesselInventoryMasterStock,
    getVesselInventoryMasterStockById, getCountVesselInventoryMasterStock, deleteVesselInventoryMasterStock
} from "../../../../Helpers/ApplicationHelpers";
import {
    getInventoryPeriod, getInventoryCondition, getInventoryStatus,
} from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import { ArrowLeft, FilePlusFill, Search, ArrowClockwise, PencilFill, Trash } from "react-bootstrap-icons";
import Paginations from "../../../../Components/Pagination";
import moment from "moment";

export default function VesselInventoryStockPage() {

    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [listInventoryStock, setListInventoryStock] = useState([]);
    const [vesselInventoryMasterId, setVesselInventoryMasterId] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);
    const [listInventoryPeriod, setlistInventoryPeriod] = useState([]);
    const [listInventoryStatus, setlistInventoryStatus] = useState([]);
    const [disabledButtonModal, setDisabledButtonModal] = useState(false);
    const [isSearched, setIsSearched] = useState(0);
    const [showtStockModal, setShowStockModal] = useState("");
    const [page, setPage] = useState(0);
    const [resetSearch, setResetSearch] = useState(0);
    const [inventoryStockId, setInventoryStockId] = useState("");
    const [removeId, setRemoveId] = useState("");
    const [itemPerPage, setItemPerPage] = useState(10);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [inventoryMaster, setInventoryMaster] = useState({});

    const [inventoryStock, setInventoryStock] = useState({
        id: 0,
        stock_internal_code: "",
        stock_external_code: "",
        vessel_inventory_id: vesselInventoryMasterId,
        purchase_date: null,
        vessel_inventory_stock_period_id: null,
        period_number: null,
        purchase_price: null,
        vessel_inventory_stock_status_id: null,
        vessel_inventory_stock_condition_id: null,
        vessel_inventory_stock_description: null,
    })

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [orderBy, setOrderBy] = useState({
        label: "Kode Barang",
        value: "inventoryStock.stock_internal_code"
    });
    const orderByList = [{
        label: "Kode Barang",
        value: "inventoryStock.stock_internal_code"
    }];
    const [descending, setDescending] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAccess();
                setVesselInventoryMasterId(searchParams.get("id"));
                loadInventoryCondition();
                loadInventoryPeriod();
                loadInventoryStatus();
            }
        }
        checkCookies();
        // if (vesselInventoryMasterId === 0) {
        //     setLoading(false);
        // } else {
        //     initinventoryMaster();
        //     loadInventoryStock()
        // }
    }, []);

    useEffect(() => {
        if(vesselInventoryMasterId !== 0 && vesselInventoryMasterId !== "")
        loadInventoryStock();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0 && vesselInventoryMasterId !== 0 && vesselInventoryMasterId !== "")
            loadInventoryStock();
    }, [itemPerPage]);

    useEffect(() => {
        if (vesselInventoryMasterId !== 0 && vesselInventoryMasterId !== "") {
            initinventoryMaster();
            loadInventoryStock()
        }
    }, [vesselInventoryMasterId])

    useEffect(() => {
        if (isSearched !== 0 && vesselInventoryMasterId !== 0 && vesselInventoryMasterId !== "")
            loadInventoryStock();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0 && vesselInventoryMasterId !== 0 && vesselInventoryMasterId !== "")
            loadInventoryStock()
    }, [resetSearch])

    useEffect(() => {
        if (isSearched !== 0 && vesselInventoryMasterId !== 0 && vesselInventoryMasterId !== "")
            loadInventoryStock()
    }, [descending]);

    useEffect(() => {
        if (inventoryStockId !== "")
            initInventoryStock()
    }, [inventoryStockId])

    useEffect(() => {
        if (removeId !== "")
            deleteData();
    }, [removeId]);


    const saveData = async () => {
        try {
            let inventoryStockTmp = inventoryStock;
            inventoryStockTmp.purchase_price = inventoryStock.purchase_price.toString().match(/\d{1,3}/gi) ? inventoryStock.purchase_price.toString().replace(/[^,\d]/g, '') : null
            // inventoryTypeDetailTmp.ext_customer_id = inventoryTypeDetailTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : inventoryStock.ext_customer_id
            let response = await insertUpdateVesselInventoryMasterStock(cookies.token, inventoryStockTmp);
            if (response === 0) {
                alert('Berhasil Menyimpan Data');
                setShowStockModal(false);
                clearInventoryStockModal();
                loadInventoryStock();
                initinventoryMaster();
            } else {
                alert('Gagal Menyimpan Data')
            }
            setDisabledButtonModal(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const clearInventoryStockModal = async () => {
        let inventoryStockTmp = [];
        inventoryStockTmp.stock_internal_code = "";
        inventoryStockTmp.stock_external_code = "";
        inventoryStockTmp.vessel_inventory_id = vesselInventoryMasterId;
        inventoryStockTmp.purchase_date = null;
        inventoryStockTmp.vessel_inventory_stock_period_id = null;
        inventoryStockTmp.period_number = null;
        inventoryStockTmp.purchase_price = null;
        inventoryStockTmp.vessel_inventory_stock_status_id = null;
        inventoryStockTmp.vessel_inventory_stock_condition_id = null;
        inventoryStockTmp.vessel_inventory_stock_description = null;

        setInventoryStock(inventoryStockTmp);
    }

    const initinventoryMaster = async () => {
        try {
            let response = await getVesselInventoryById(cookies.token, vesselInventoryMasterId);
            if (response) {
                setInventoryMaster({
                    ...inventoryMaster,
                    id: response.id,
                    vessel_inventory_brand: response.vessel_inventory_brand,
                    vessel_inventory_model: response.vessel_inventory_model,
                    vessel_inventory_name: response.vessel_inventory_name,
                    vessel_inventory_description: response.vessel_inventory_description,
                    quantity: response.quantity,
                    category_name: response.category_name

                })
                setLoading(false);
            } else {
                alert('Terjadi Kesalahan Pada Sistem');
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const loadInventoryStock = async () => {
        try {
            let count = await getCountVesselInventoryMasterStock(cookies.token, searchQuery, false, vesselInventoryMasterId);
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            let response = await getVesselInventoryMasterStock(cookies.token, vesselInventoryMasterId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListInventoryStock(response);
            setLoading(false);
            setIsSearched(0);
            setResetSearch(0)

        } catch (exception) {
            console.log(exception);
        }
    }

    const initInventoryStock = async () => {
        try {
            let response = await getVesselInventoryMasterStockById(cookies.token, inventoryStockId);
            if (response) {
                setInventoryStock({
                    ...inventoryStock,
                    id: response.id,
                    vessel_inventory_id: response.vessel_inventory_id,
                    stock_internal_code: response.stock_internal_code,
                    stock_external_code: response.stock_external_code,
                    purchase_date: response.purchase_date,
                    vessel_inventory_stock_period_id: response.vessel_inventory_stock_period_id,
                    period_number: response.period_number,
                    purchase_price: response.purchase_price,
                    vessel_inventory_stock_status_id: response.vessel_inventory_stock_status_id,
                    vessel_inventory_stock_condition_id: response.vessel_inventory_stock_condition_id,
                    vessel_inventory_stock_description: response.vessel_inventory_stock_description,
                })
                setShowStockModal(true);
                setInventoryStockId("");
                setLoading(false);
            } else {
                alert('Terjadi Kesalahan Pada Sistem');
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const deleteData = async () => {
        try {
            let response = await deleteVesselInventoryMasterStock(cookies.token, removeId);
            if (response === 0) {
                loadInventoryStock();
                setRemoveId("");
                initinventoryMaster();
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated);
            }
            setDeletedButton(true);
            setUpdatedButton(true);

        } catch (exception) {
            console.log(exception);
        }
    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const loadInventoryPeriod = async () => {
        try {
            let listInventoryPeriod = await getInventoryPeriod(cookies.token, cookies.languageId);
            setlistInventoryPeriod(listInventoryPeriod);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryStatus = async () => {
        try {
            let listInventoryStatus = await getInventoryStatus(cookies.token, cookies.languageId);
            setlistInventoryStatus(listInventoryStatus);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }




    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={"Daftar Stok Inventaris"}
                    useBackButton={false}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                            // onClick={() => 
                            //     { navigate("/Vessel/InventoryCategory"); }
                            // }
                            >
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 10,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" titleCaption={"Detail Invtaris"} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                        padding: 5
                                                    }}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Jenis Peralatan</Form.Label>
                                                            <Form.Control value={inventoryMaster.category_name} onChange={(e) => {

                                                            }} disabled={true} placeholder=""></Form.Control>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Merek</Form.Label>
                                                            <Form.Control type="text" value={inventoryMaster.vessel_inventory_brand} onChange={(e) => {

                                                            }} disabled={true} placeholder=""></Form.Control>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Model</Form.Label>
                                                            <Form.Control type="text" value={inventoryMaster.vessel_inventory_model} onChange={(e) => {

                                                            }} disabled={true} placeholder=""></Form.Control>
                                                        </Form.Group>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                        padding: 5
                                                    }}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Nama Barang</Form.Label>
                                                            <Form.Control value={inventoryMaster.vessel_inventory_name} onChange={(e) => {

                                                            }} disabled={true}></Form.Control>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Jumlah Stok</Form.Label>
                                                            <Form.Control type="text" value={inventoryMaster.quantity} onChange={(e) => {

                                                            }} disabled={true}></Form.Control>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Keterangan</Form.Label>
                                                            <Form.Control as={"textarea"} rows={3} value={inventoryMaster.vessel_inventory_description} onChange={(e) => {

                                                            }} disabled={true}></Form.Control>
                                                        </Form.Group>

                                                    </div>

                                                </div>





                                            </div>
                                        }>
                                        </ContainerBox>


                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    padding: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>

                                        <ContainerBox containerPos="inner" titleCaption={"Daftar Barang "}
                                            useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                                            actionContainerChild={
                                                <div>
                                                    <Button disabled={!updatedButton} variant="secondary" style={{

                                                    }} onClick={() => {
                                                        setInventoryStock({
                                                            ...inventoryStock,
                                                            id: 0,
                                                            stock_internal_code: "",
                                                            stock_external_code: "",
                                                            vessel_inventory_id: vesselInventoryMasterId,
                                                            purchase_date: null,
                                                            vessel_inventory_stock_period_id: null,
                                                            period_number: null,
                                                            purchase_price: null,
                                                            vessel_inventory_stock_status_id: null,
                                                            vessel_inventory_stock_condition_id: null,
                                                            vessel_inventory_stock_description: null,
                                                        })
                                                        setShowStockModal(true);
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "row",
                                                            alignSelf: "center",
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignSelf: "center",
                                                                flex: 1,
                                                            }}><FilePlusFill size={32} /></div>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 8,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignSelf: "center",
                                                                paddingLeft: 10,
                                                                fontWeight: "bold",
                                                                fontSize: 18,
                                                            }}>Tambah Data</div>
                                                        </div>
                                                    </Button>
                                                </div>
                                            }
                                            childContent={
                                                <div className="master-table-inner-container">
                                                    <div className="master-table-searchbar-container">
                                                        <div className="master-table-searchbar-textbox">
                                                            <Form.Control value={searchQuery} type="text" placeholder="Cari (Nama Inventaris)"
                                                                onKeyPress={async (e) => {
                                                                    if (e.charCode === 13) {
                                                                        setPage(0);
                                                                        setLoading(true);
                                                                        await loadInventoryStock();
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    setSearchQuery(e.target.value);
                                                                }} />
                                                        </div>
                                                        <div className="master-table-searchbar-button">
                                                            <div style={{ paddingRight: 5 }}>
                                                                <Button onClick={async () => {
                                                                    setLoading(true);
                                                                    setPage(0);
                                                                    await loadInventoryStock();
                                                                }}><Search /></Button>
                                                            </div>
                                                            <div style={{ paddingLeft: 5 }}>
                                                                <Button onClick={async () => {
                                                                    setLoading(true);
                                                                    setSearchQuery("");
                                                                    setResetSearch(1);
                                                                    // await loadInventoryStock();
                                                                }}><ArrowClockwise /></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-container">
                                                        <div className="table-header">
                                                            <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                            <div style={{ flex: 3 }} className="table-header-content">Kode Barang</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">Status Barang</div>
                                                            <div style={{ flex: 2 }} className="table-header-content">Kondisi Barang</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">Edit</div>
                                                            <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                                        </div>
                                                        {
                                                            listInventoryStock.map((inventoryStock, index) => {
                                                                return (<div className="table-body">
                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                                                    <div style={{ flex: 3 }} className="table-body-content"><p>{inventoryStock.stock_internal_code}</p></div>
                                                                    <div style={{ flex: 2 }} className="table-body-content"><p>{inventoryStock.stock_external_code}</p></div>
                                                                    <div style={{ flex: 2 }} className="table-body-content"><p>{inventoryStock.stock_external_code}</p></div>
                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                        <div style={{ padding: 5 }}>
                                                                            <Button disabled={!updatedButton} variant="primary" onClick={() => {
                                                                                setInventoryStockId(inventoryStock.id);
                                                                            }}><PencilFill /></Button>
                                                                        </div></div>

                                                                    <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                                        <div style={{ padding: 5 }}>
                                                                            <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger"
                                                                                onClick={() => {
                                                                                    if (window.confirm(`Apakah Anda Ingin Menghapus Data Inventaris ${inventoryStock.stock_internal_code}?`))
                                                                                        setRemoveId(inventoryStock.id);
                                                                                    setLoading(true);
                                                                                }}
                                                                            ><Trash /></Button>
                                                                        </div></div>
                                                                </div>)
                                                            }
                                                            )
                                                        }
                                                    </div>
                                                    <div style={{
                                                        paddingTop: 20,
                                                    }}>

                                                    </div>
                                                    <Paginations
                                                        itemPerPage={itemPerPage}
                                                        totalPage={totalPage}
                                                        page={page}
                                                        setPage={setPage}
                                                        setItemPerPage={setItemPerPage}
                                                        itemPerPageSelection={itemPerPageSelection}
                                                        orderBy={orderBy}
                                                        setOrderBy={setOrderBy}
                                                        orderBySelection={orderByList}
                                                        isDescActive={descending}
                                                        setIsDescActive={setDescending}
                                                        setIsSearched={setIsSearched}
                                                    />
                                                    <Loading
                                                        loading={loading}
                                                    />
                                                </div>
                                            } />



                                    </div>
                                </div>


                            </div>


                        </div>

                    }>

                </ContainerBox >

                <Modal show={showtStockModal}
                    // dialogClassName="modal-size"
                    size={"lg"}
                    onHide={() => {
                        setShowStockModal(false);
                        clearInventoryStockModal()
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>Tambah Barang </h3>
                        </div>

                        {/* <Form onSubmit={(e) => {
                            e.preventDefault();
                            setDisabledButtonModal(true)
                            saveData()
                        }} style={{ padding: 10 }}>

                            <Form.Group className="mb-3">
                                <Form.Label>Kode Barang Internal</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setInventoryStock({ ...inventoryStock, stock_internal_code: e.target.value })
                                }} type="text" value={inventoryStock.stock_internal_code} required placeholder=""></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Kode Barang External</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setInventoryStock({ ...inventoryStock, stock_external_code: e.target.value })
                                }} as="textarea" rows={3} value={inventoryStock.stock_external_code} placeholder=""></Form.Control>

                            </Form.Group>


                            <div style={{ paddingBottom: 10 }}></div>
                            {
                                cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                    <></>
                                    :
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "center"
                                    }}>
                                        <Button type="submit" disabled={disabledButtonModal === true ? disabledButtonModal : !updatedButton}>Simpan</Button>
                                        <Button className="cancel" variant="danger" onClick={() => {
                                            clearInventoryStockModal();
                                            setShowStockModal(false);
                                        }}>Batal</Button>
                                    </div>
                            }


                        </Form> */}
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setDisabledButtonModal(true)
                            saveData()

                        }} style={{ padding: 10 }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingRight: 10,
                                    }}>
                                         <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Kode Barang Internal</Form.Label>
                                                <Form.Control   onChange={(e) => {
                                                    setInventoryStock({ ...inventoryStock, stock_internal_code: e.target.value })
                                                }} type="text" value={inventoryStock.stock_internal_code} required placeholder="" />
                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Kode Barang External</Form.Label>
                                                <Form.Control autoComplete="off" onChange={(e) => {
                                                    setInventoryStock({ ...inventoryStock, stock_external_code: e.target.value })
                                                }} type="text" value={inventoryStock.stock_external_code} placeholder=""></Form.Control>

                                            </Form.Group>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tanggal Pembelian</Form.Label>
                                                <Form.Control onChange={(e) => {
                                                    setInventoryStock({ ...inventoryStock, purchase_date: e.target.value })
                                                }} type="date" value={inventoryStock.purchase_date ? moment(inventoryStock.purchase_date).format("yyyy-MM-DD") : ""} required placeholder="Masukan Tanggal Pembelian"></Form.Control>
                                            </Form.Group>
                                        </div>
                                        {/* <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tanggal Kalibrasi</Form.Label>
                                                <Form.Control onChange={(e) => {
                                                    if (e.target.value === "") {
                                                        setInventoryStock({ ...inventoryStock, calibration_date: null })
                                                    } else {
                                                        setInventoryStock({ ...inventoryStock, calibration_date: e.target.value })
                                                    }

                                                }} type="date" value={inventoryStock.calibration_date ? moment(inventoryStock.calibration_date).format("yyyy-MM-DD") : ""} placeholder=""></Form.Control>
                                            </Form.Group>
                                        </div> */}

                                       

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Pengecekan Barang</Form.Label>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        padding: 5
                                                    }}>
                                                        <Form.Control type="text" onChange={(e) => {
                                                            if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                            } else {
                                                                setInventoryStock({ ...inventoryStock, period_number: e.target.value })
                                                            }
                                                        }} value={inventoryStock.period_number} required>

                                                        </Form.Control>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        padding: 5
                                                    }}>
                                                        <Form.Select required onChange={(e) => {
                                                            setInventoryStock({ ...inventoryStock, vessel_inventory_stock_period_id: e.target.value })
                                                        }} value={inventoryStock.vessel_inventory_stock_period_id} >
                                                            <option selected value="">
                                                                Pilih Periode...
                                                            </option>
                                                            {listInventoryPeriod.map((period, index) => (
                                                                <option key={index} value={period.id}>{period.inventory_period_name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                                {/* <Col md={12}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <Form.Control
                                                                onChange={(e) => {
                                                                    if (!new RegExp(/^[0-9]*$/).test(e.target.value)) {

                                                                    } else {
                                                                        setInventoryStock({ ...inventoryStock, period_number: e.target.value })
                                                                    }

                                                                }} type="text" value={inventoryStock.period_number} required placeholder="Masukan Jumlah Periode"></Form.Control>
                                                        </Col>
                                                        <Col md={4}>
                                                          
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                            </Form.Group>
                                        </div>



                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingLeft: 10,
                                    }}>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Harga Pembelian</Form.Label>
                                                <Form.Control
                                                    onKeyUp={(e) => {

                                                        setInventoryStock({ ...inventoryStock, purchase_price: formatCurrency(e.target.value) })
                                                    }}

                                                    onChange={(e) => {
                                                        setInventoryStock({ ...inventoryStock, purchase_price: e.target.value })
                                                    }} type="text" value={inventoryStock.purchase_price} placeholder=""></Form.Control>
                                            </Form.Group>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label> Status Barang</Form.Label>
                                                <Form.Select required onChange={(e) => {
                                                    setInventoryStock({ ...inventoryStock, vessel_inventory_stock_status_id: e.target.value })
                                                }} value={inventoryStock.vessel_inventory_stock_status_id} >
                                                    <option selected value="">
                                                        Pilih Status Barang...
                                                    </option>
                                                    {listInventoryStatus.map((status, index) => (
                                                        <option key={index} value={status.id}>{status.inventory_status_name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                   


                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Kondisi Barang</Form.Label>
                                                <Form.Select required onChange={(e) => {
                                                    setInventoryStock({ ...inventoryStock, vessel_inventory_stock_condition_id: e.target.value })
                                                }} value={inventoryStock.vessel_inventory_stock_condition_id} >
                                                    <option selected value="">
                                                        Pilih Kondisi Barang...
                                                    </option>
                                                    {listInventoryCondition.map((condition, index) => (
                                                        <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                        }}>
                                            {/* <Form.Group className="mb-3">
                                                <Form.Label>Keterangan</Form.Label>
                                                <Form.Control as="textarea" rows={3} value={inventoryStock.vessel_inventory_stock_description} onChange={(e)=>{
                                                     setInventoryStock({ ...inventoryStock, vessel_inventory_stock_description: e.target.value })
                                                }}></Form.Control>
                                              
                                            </Form.Group> */}
                                        </div>
                                    </div>
                                </div>
                                {
                                    cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                        <></>
                                        :
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            flex: 1,
                                            padding: 10,
                                        }}>
                                            <Button style={{
                                                width: "100%"
                                            }} type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>Simpan</Button>
                                        </div>
                                }

                                <div style={{
                                    display: "flex",
                                    minWidth: "100%",
                                    flex: 1,
                                    padding: 10,
                                }}>
                                    <Button style={{
                                        width: "100%"
                                    }} variant="danger" onClick={() => {
                                        setShowStockModal(false);
                                        // navigate("/InventoryStock", { state: { inventoryMasterId: location.state.inventoryMasterId,departmentCode: location.state.departmentCode} });
                                    }}>Batal</Button>
                                </div>
                            </div>
                        </Form>



                    </div>

                </Modal>


            </Container >
            <Loading
                loading={loading}
            />
        </>
    )
}