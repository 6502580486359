import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Row, Form, Container, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, insertUpdateLeaveType, getLeaveTypeById, getUnitOfTime, getUserAccessById, convertBase64Image } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import { omit } from 'lodash';
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import Select from "react-select";

export default function LeaveTypeDetailPage() {
    const [errorMessage, setErrorMessage] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [errors, setErrors] = useState({});
    const [disabledButton, setDisabledButton] = useState(false);
    const [selectedUnitTime, setSelectedUnitTime] = useState({});
    const [listUnitTime, setListUnitTime] = useState([]);
    const [leaveType, setLeaveType] = useState({
        id: 0,
        unit_of_time: null,
        leave_type: "",
        description: "",
        leave_allowance: 0
    })
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAccess();
                loadUnitOfTime();
            }
        }
        checkCookies();
        if (location.state.typeId === 0) {
            setLoading(false);
        } else {
            initLeaveType();
        }
    }, []);


    useEffect(() => {
        if (selectedUnitTime.unit_time) {
            setLeaveType({ ...leaveType, unit_of_time: selectedUnitTime.id });
        } else {
            setLeaveType({ ...leaveType, unit_of_time: null });
        }
    }, [selectedUnitTime]);

    useEffect(() => {
        validationForm();
        if (leaveType.unit_of_time) {
            let response = listUnitTime.find(p => p.id === leaveType.unit_of_time);
            if (response) {
                setSelectedUnitTime(response);
            }

        }
    }, [leaveType])

    useEffect(() => {
        if (listUnitTime.length > 0) {
            if (leaveType.unit_of_time) {
                let response = listUnitTime.find(p => p.id === leaveType.unit_of_time);
                if (response) {
                    setSelectedUnitTime(response);
                }

            }
        }
    }, [listUnitTime])


    const saveData = async () => {
        try {
            let response = await insertUpdateLeaveType(cookies.token, leaveType);
            if (response === 0) {
                alert("Berhasil Menyimpan Data");
                navigate("/LeaveType")
            } else {
                alert("Gagal Menyimpan Data")
            }
            setDisabledButton(false);
        } catch (exception) {
            // setErrorMessage("Data Gagal Disimpan");
        }
    }

    const loadUnitOfTime = async () => {
        try {
            let response = await getUnitOfTime(cookies.token, cookies.languageId, "null");
            setListUnitTime(response);
        } catch (exception) {

        }
    }

    const initLeaveType = async () => {
        try {
            let response = await getLeaveTypeById(cookies.token, location.state.typeId);
            console.log(response);
            if (response) {
                setLeaveType({
                    ...leaveType,
                    unit_of_time: response.unit_of_time,
                    leave_type: response.leave_type,
                    description: response.description,
                    leave_allowance: response.leave_allowance
                })
                setLoading(false);
            } else {
                alert('Terjadi Kesalahan Pada Sistem');
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const validationForm = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (leaveType.unit_of_time === undefined || leaveType.unit_of_time === null) {
                    setErrorMessage("*Harus Diisi");
                    setDisabledButton(true)
                }
                else {
                    setErrorMessage("");
                    setDisabledButton(false);
                }
                resolve(true);

            } catch (exception) {
                // console.log(exception);
                resolve(false);

            }
        })
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox titleCaption={"Detail Jenis Cuti"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/LeaveType"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={"Jenis Cuti"} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>

                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    saveData();
                                                    setDisabledButton(true);
                                                }}>
                                                    <Form.Group>
                                                        <Form.Label>Jenis Cuti</Form.Label>
                                                        <Form.Control required type="text" onChange={(e) => {
                                                                setLeaveType({ ...leaveType, leave_type: e.target.value })
                                                           
                                                        }} value={leaveType.leave_type}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Jatah Cuti </Form.Label>
                                                        <Form.Control required type="number" onChange={(e) => {
                                                            if (e.target.value >= 0) {
                                                                setLeaveType({ ...leaveType, leave_allowance: e.target.value })
                                                            }

                                                        }} value={leaveType.leave_allowance}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Satuan Waktu</Form.Label>


                                                        <Select placeholder={""}
                                                            getOptionLabel={(item) => {
                                                                return item.unit_time;
                                                            }} clearValue={true}
                                                            getOptionValue={(item) => {
                                                                return item.id;
                                                            }}
                                                            options={listUnitTime} value={selectedUnitTime} onChange={(e) => {
                                                                if (e === null) {
                                                                    setSelectedUnitTime({})
                                                                } else {
                                                                    setSelectedUnitTime(e);
                                                                }
                                                            }}
                                                            isClearable
                                                            required
                                                        />
                                                        {
                                                            errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>
                                                        }
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Keterangan</Form.Label>
                                                        <Form.Control as="textarea" onChange={(e) => {
                                                               setLeaveType({ ...leaveType, description: e.target.value })
                                                         
                                                        }} value={leaveType.description} rows={3}></Form.Control>
                                                    </Form.Group>
                                                    <div style={{ paddingBottom: 20 }}></div>
                                                    <div style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        flexDirection: "row",
                                                        padding: 5,
                                                        justifyContent: "center"
                                                    }}>
                                                        <div style={{
                                                            paddingRight: 5,
                                                        }}><Button disabled={disabledButton} type="submit" variant="primary" >Simpan</Button></div>


                                                        <div style={{
                                                            paddingLeft: 5,
                                                        }}>
                                                            <Button variant="danger" onClick={() => {
                                                                navigate('/LeaveType');
                                                            }}>Batal</Button>

                                                        </div>
                                                    </div>
                                                </Form>

                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>


                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}