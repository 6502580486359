import React, { useState, useEffect } from "react";
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ArrowLeft, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise } from "react-bootstrap-icons";
import { isTokenValid, getDivision, getUserAccessById, getInventoryBrand } from "../../../../Helpers/ApplicationHelpers";
import {
    getInventoryType, insertUpdateInventoryMaster, getInventoryMasterById, getInventoryTypeSubItem, getInventoryUnitType, getInventoryPeriod,
    getInventoryCondition, getInventoryStatus, checkBarcodeNumber
} from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import Select from 'react-select';
import { omit } from 'lodash';
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

export default function InventoryMasterDetailPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listInventoryBrand, setListInventoryBrand] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState({});
    const navigate = useNavigate();
    const [disabledButton, setDisabledButton] = useState(true);
    const location = useLocation();
    const [listInventoryStatus, setlistInventoryStatus] = useState([]);
    const [listInventoryCondition, setlistInventoryCondition] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [listDivision, setListDivision] = useState([]);
    const [listInventoryType, setlistInventoryType] = useState([]);
    const [listUnitType, setListUnitType] = useState([])
    const [inventoryId, setInventoryId] = useState("");
    const [selectedDetail, setSelectedDetail] = useState({ id: "" });
    const [errors, setErrors] = useState([]);
    const [listInventoryPeriod, setlistInventoryPeriod] = useState([]);


    const [inventoryMaster, setInventoryMaster] = useState({
        id: 0,
        inventory_master_name: "",
        division_id: "",
        division_code: "",
        unit: "",
        // reference_price: "",
        brand: "",
        model: "",
        quantity: 0,
        inventory_unit_id: null,
        inventory_master_description: "",
        inventory_master_note: "",
        inventory_type_id: "",
        language_id: cookies.languageId,
        ext_customer_id: cookies.extCustomerId,
        lead_time: 0,
        buffer_stock: 0,
        brand_id: null,
        weight_gram: 0
    })


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadUnitType();
                setInventoryId(location.state.inventoryMasterId);
                loadAccess();
                loadInventoryPeriod();
                loadInventoryStatus();
                loadInventoryCondition();
                loadInventoryBrand();

            }
        }
        checkCookies();
        console.log('COOKIES DATA', cookies);
        // if (location.state.inventoryMasterId === 0) {

        // } else {
        //     initInventoryMaster();
        // }
    }, []);

    useEffect(() => {
        loadDivision();
        loadInventoryType();
        if (inventoryId !== "" && inventoryId !== 0) {
            initInventoryMaster();
        }
    }, [inventoryId])

    useEffect(() => {
        if (inventoryMaster.inventory_type_id) {
            let inventoryType = listInventoryType.find(p => p.id === inventoryMaster.inventory_type_id);
            if (inventoryType) {
                setSelectedDetail(inventoryType);
            }

        }

        if (inventoryMaster.brand_id) {
            let brands = listInventoryBrand.find(p => p.id === inventoryMaster.brand_id);
            if (brands) {
                setSelectedBrand(brands);
            }

        }

        validationForm();
        console.log(inventoryMaster)
    }, [inventoryMaster])

    useEffect(() => {
        if (selectedDetail.inventory_type_name) {
            setInventoryMaster({ ...inventoryMaster, inventory_type_id: selectedDetail.id });
        } else {
            setInventoryMaster({ ...inventoryMaster, inventory_type_id: null });
        }
    }, [selectedDetail]);


    useEffect(() => {
        if (selectedBrand.brand_name) {
            setInventoryMaster({ ...inventoryMaster, brand_id: selectedBrand.id, brand_name: selectedBrand.brand_name, inventory_master_name: selectedBrand.brand_name + ' ' + inventoryMaster.model });
        } else {
            setInventoryMaster({ ...inventoryMaster, brand_id: null, brand_name: null, inventory_master_name: '' + ' ' + inventoryMaster.model });
        }
    }, [selectedBrand]);



    const initInventoryMaster = async () => {
        try {
            setDisabledButton(false);
            let response = await getInventoryMasterById(cookies.token, location.state.inventoryMasterId, cookies.languageId);
            console.log(response);
            if (response) {
                setInventoryMaster({
                    id: response.id,
                    inventory_master_name: response.inventory_master_name,
                    division_id: response.division_id,
                    unit: response.unit,
                    brand: response.brand,
                    model: response.model,
                    quantity: response.quantity,
                    inventory_master_description: response.inventory_master_description,
                    inventory_master_note: response.inventory_master_note,
                    inventory_type_id: response.inventory_type_id,
                    division_code: response.division_code,
                    language_id: response.language_id,
                    inventory_unit_id: response.inventory_unit_id,
                    bulk_item: response.bulk_item,
                    purchase_date: response.purchase_date,
                    period_number: response.period_number,
                    inventory_period_id: response.inventory_period_id,
                    purchase_price: response.purchase_price,
                    inventory_status_id: response.inventory_status_id,
                    inventory_condition_id: response.inventory_condition_id,
                    stock: response.quantity,
                    lead_time: response.lead_time,
                    buffer_stock: response.buffer_stock,
                    brand_id: response.inventory_brand_id,
                    is_consumables: response.is_consumables,
                    is_product_material: response.is_product_material,
                    weight_gram: response.weight_gram,

                });
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const saveInventoryMaster = async () => {


        let invTmp = inventoryMaster;
        let purchase_price
        if (inventoryMaster.id === 0 && inventoryMaster.bulk_item) {
            let barcodeNumber = await generateCode();
            let today = new Date();
            let yyyy = today.getFullYear();
            let invCode = "RPI/" + inventoryMaster.division_code + "/EQP/" + yyyy + "/" + barcodeNumber;
            invTmp.inventory_code = invCode;
            invTmp.barcode_number = barcodeNumber;
            if (inventoryMaster.purchase_price.toString().match(/\d{1,3}/gi)) purchase_price = inventoryMaster.purchase_price.toString().replace(/[^,\d]/g, '');
        }


        invTmp.id = location.state.inventoryMasterId;
        invTmp.language_id = cookies.languageId;
        invTmp.ext_customer_id = invTmp.ext_customer_id === "null" ? "fab7b2b6-cb86-4b74-ba8e-036361f24115" : inventoryMaster.ext_customer_id
        invTmp.purchase_price = purchase_price;
        invTmp.division_id = inventoryMaster.division_id ? inventoryMaster.division_id : 'f3104281-499f-4eb7-a92c-312132be7a64'
        console.log(invTmp)
        let response = await insertUpdateInventoryMaster(cookies.token, invTmp);
        if (response.error_code === 0) {
            alert(`${t("detail_inventory_master.alert_success_save_data")}`);
            navigate("/InventoryMaster");
        }
        else {
            alert(`${t("detail_inventory_master.alert_failed_save_data")}`)
        }
    }


    const loadDivision = async () => {
        try {
            let listDivision = await getDivision(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListDivision(listDivision);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryPeriod = async () => {
        try {
            let listInventoryPeriod = await getInventoryPeriod(cookies.token, cookies.languageId);
            setlistInventoryPeriod(listInventoryPeriod);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryType = async () => {
        try {
            let response = await getInventoryTypeSubItem(cookies.token, cookies.languageId, cookies.extCustomerId);
            setlistInventoryType(response);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const validationForm = async () => {
        try {

            // if (inventoryMaster.inventory_type_id === "" || inventoryMaster.inventory_type_id === undefined || inventoryMaster.inventory_type_id === null) {
            //     setErrors({
            //         ...errors,
            //         message: `${t("detail_inventory_master.error_empty_field")}`
            //     },
            //         setDisabledButton(true));
            // // } else if (inventoryMaster.division_id === "" || inventoryMaster.division_id === undefined || inventoryMaster.division_id === null) {
            //     setErrors({
            //         ...errors,
            //         divisionError: `${t("detail_inventory_master.error_empty_field")}`
            //     },
            //         setDisabledButton(true));
            // } else {
            //     let errorMsg = omit(errors, "message", "divisionError");
            //     setErrors(errorMsg);
            //     setDisabledButton(false);
            // }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadUnitType = async () => {
        try {
            let response = await getInventoryUnitType(cookies.token);
            setListUnitType(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")), cookies.languageId);
            // console.log(response);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    function formatCurrency(angka, prefix) {
        var number_string = angka.replace(/[^\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    const loadInventoryStatus = async () => {
        try {
            let listInventoryStatus = await getInventoryStatus(cookies.token, cookies.languageId);
            setlistInventoryStatus(listInventoryStatus);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryCondition = async () => {
        try {
            let listInventoryCondition = await getInventoryCondition(cookies.token, cookies.languageId);
            setlistInventoryCondition(listInventoryCondition);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const generateCode = async () => {
        try {
            let randomNumber = Math.floor(Math.random() * 100000000);
            let checkCode = await checkBarcodeNumber(cookies.token, randomNumber);
            if (checkCode != 0) {
                return (generateCode());
            } else {
                return (randomNumber);
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadInventoryBrand = async () => {
        try {
            let response = await getInventoryBrand(cookies.token);
            setListInventoryBrand(response);
        } catch (exception) {

        }
    }


    return (
        <>
            <Navbar />
            <Container>
                <ContainerBox
                    titleCaption={`${t("detail_inventory_master.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/InventoryMaster"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            {
                                location.state.inventoryMasterId === 0 ? <></> :
                                    <Button variant="secondary" onClick={(e) => {
                                        navigate("/InventoryStock", { state: { inventoryMasterId: inventoryMaster.id, divisionCode: inventoryMaster.divisionCode } });
                                    }}>{t("detail_inventory_master.button_stock")}</Button>

                            }
                        </div>
                    }
                    childContent={
                        <div>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                saveInventoryMaster();

                            }} style={{ padding: 10 }}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_inventory_type")}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.inventory_type_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listInventoryType} value={selectedDetail} onChange={(e) => {
                                            if (e === null) {
                                                setSelectedDetail({})
                                                setDisabledButton(true);
                                            } else {
                                                setSelectedDetail(e);
                                                setDisabledButton(false);
                                            }
                                        }}
                                        
                                        isClearable
                                        isDisabled={false}
                                    />
                                    {/* {
                                        selectedDetail.id !== null ? <p>ok</p> : <p style={{ color: "red" }}>EROOR</p> 
                                    } */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_merk")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        if (/^[a-zA-Z0-9_ .-]*$/.test(e.target.value) || e.target.value === "") {
                                            setInventoryMaster({ ...inventoryMaster, brand: e.target.value, inventory_master_name: e.target.value + ' ' + inventoryMaster.model })
                                        } else {

                                            setInventoryMaster({ ...inventoryMaster, brand: "" });
                                        }
                                        // setInventoryMaster({ ...inventoryMaster, brand: e.target.value, inventory_master_name: e.target.value + ' ' + inventoryMaster.model })
                                    }} type="text" value={inventoryMaster.brand} required  placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>New Brand</Form.Label>


                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.brand_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listInventoryBrand} value={selectedBrand} onChange={(e) => {
                                            if (e === null) {
                                                setSelectedBrand({})
                                            } else {
                                                setSelectedBrand(e);
                                            }
                                        }}
                                        isClearable
                                        // required
                                    />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_model")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        if (/^[a-zA-Z0-9_ .\-\/"%]*$/.test(e.target.value) || e.target.value === "") {
                                            setInventoryMaster({ ...inventoryMaster, model: e.target.value, inventory_master_name: inventoryMaster.brand + ' ' + e.target.value })
                                        } else {

                                            setInventoryMaster({ ...inventoryMaster, model: "" });
                                        }
                                        // setInventoryMaster({ ...inventoryMaster, model: e.target.value, inventory_master_name: inventoryMaster.brand + ' ' + e.target.value })
                                    }} type="text" value={inventoryMaster.model} required placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_inventory_name")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setInventoryMaster({ ...inventoryMaster, inventory_master_name: e.target.value })
                                    }} type="text" value={inventoryMaster.inventory_master_name} required placeholder="" disabled={true}></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_weight")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        const value = e.target.value;
                                        console.log(e);
                                        setInventoryMaster({ ...inventoryMaster, weight_gram: value })
                                        // if (value) {
                                            
                                        // } else {
                                        //     setInventoryMaster({ ...inventoryMaster, weight_gram: "" });
                                        // }
                                        if (/^[0-9.]*$/.test(value) || value === "") {
                                            setInventoryMaster({ ...inventoryMaster, weight_gram: value })
                                        } else {

                                            setInventoryMaster({ ...inventoryMaster, weight_gram: "" });
                                        }


                                    }} type="text" value={inventoryMaster.weight_gram}  placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_lead_time")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) || value === "") {
                                            setInventoryMaster({ ...inventoryMaster, lead_time: value })
                                        } else {

                                            setInventoryMaster({ ...inventoryMaster, lead_time: "" });
                                        }


                                    }} type="text" value={inventoryMaster.lead_time} required placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_buffer_stock")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) || value === "") {
                                            setInventoryMaster({ ...inventoryMaster, buffer_stock: value })
                                        } else {

                                            setInventoryMaster({ ...inventoryMaster, buffer_stock: "" });
                                        }

                                    }} type="text" value={inventoryMaster.buffer_stock} required placeholder="" ></Form.Control>
                                </Form.Group>
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_division")}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.division_code;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listDivision} value={listDivision.filter(function (option) {
                                            return option.id === inventoryMaster.division_id;
                                        })} onChange={(e) => {
                                            if (e === null) {
                                                setInventoryMaster({ ...inventoryMaster, division_id: null })
                                            } else {
                                                setInventoryMaster({ ...inventoryMaster, division_id: e.id, division_code: e.division_code })
                                            }
                                        }}
                                    // isClearable

                                    />
                                    { <Form.Select required onChange={(e) => {
                                        setInventoryMaster({ ...inventoryMaster, division_id: e.target.value })
                                    }} value={inventoryMaster.division_id} >
                                        <option selected value="">
                                            {t("detail_inventory_master.choose_division")}
                                        </option>
                                        {listDivision.map((division, index) => (
                                            <option key={index} value={division.id}>{division.division_name} ({division.division_code})</option>
                                        ))}
                                    </Form.Select> }
                                </Form.Group> */}
                                {
                                    // errors.divisionError && <p style={{ color: "red" }}>{errors.divisionError}</p>
                                }

                                {/* <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_unit_type")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setInventoryMaster({ ...inventoryMaster, unit: e.target.value })
                                    }} type="text" value={inventoryMaster.unit} required placeholder=""></Form.Control>
                                </Form.Group> */}
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_unit_type")}</Form.Label>
                                    <Select placeholder={""}
                                        getOptionLabel={(item) => {
                                            return item.unit_name;
                                        }} clearValue={true}
                                        getOptionValue={(item) => {
                                            return item.id;
                                        }}
                                        options={listUnitType} value={listUnitType.filter(function (option) {
                                            return option.id === inventoryMaster.inventory_unit_id;
                                        })} onChange={(e) => {
                                            if (e === null) {
                                                setInventoryMaster({ ...inventoryMaster, inventory_unit_id: null })
                                            } else {
                                                setInventoryMaster({ ...inventoryMaster, inventory_unit_id: e.id })
                                            }
                                        }}
                                        isClearable

                                    />
                                </Form.Group>
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>Harga Referensi</Form.Label>
                                    <Form.Control
                                        onKeyUp={(e) => {
                                            setInventoryMaster({ ...inventoryMaster, reference_price: formatCurrency(e.target.value) })
                                        }}
                                        onChange={(e) => {
                                            setInventoryMaster({ ...inventoryMaster, reference_price: e.target.value })
                                        }} type="text" value={inventoryMaster.reference_price} placeholder="" required></Form.Control>
                                </Form.Group> */}


                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_description")}</Form.Label>
                                    <textarea
                                        className="form-control"
                                        value={inventoryMaster.inventory_master_description}
                                        placeholder=""
                                        onChange={(e) => {
                                            setInventoryMaster({ ...inventoryMaster, inventory_master_description: e.target.value })
                                        }}
                                        rows="4"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_inventory_master.field_note")}</Form.Label>
                                    <textarea
                                        className="form-control"
                                        value={inventoryMaster.inventory_master_note}
                                        placeholder=""
                                        onChange={(e) => {
                                            setInventoryMaster({ ...inventoryMaster, inventory_master_note: e.target.value })
                                        }}
                                        rows="4"
                                    />
                                </Form.Group>
                                <Form.Group>
                                    {/* <Form.Label>Consumable Item</Form.Label> */}
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Form.Check disabled={location.state.inventoryMasterId !== 0} defaultChecked={inventoryMaster.bulk_item} label={'Bulk Item'} onClick={(e) => {
                                            if (e.target.checked) {
                                                setInventoryMaster({ ...inventoryMaster, bulk_item: true })
                                            } else {
                                                setInventoryMaster({ ...inventoryMaster, bulk_item: false, purchase_date: null, purchase_price: null, period_number: null, inventory_period_id: null, inventory_condition_id: null, inventory_status_id: null })
                                            }
                                        }}>
                                        </Form.Check>
                                        <div style={{paddingRight:10}}></div>
                                        <Form.Check  defaultChecked={inventoryMaster.is_consumables} label={'Consumable Item'} onClick={(e) => {
                                            if (e.target.checked) {
                                                setInventoryMaster({ ...inventoryMaster, is_consumables: true })
                                            } else {
                                                setInventoryMaster({ ...inventoryMaster, is_consumables: false })
                                            }
                                        }}>
                                        </Form.Check>
                                        <div style={{paddingRight:10}}></div>
                                        <Form.Check  defaultChecked={inventoryMaster.is_product_material} label={'Product Material'} onClick={(e) => {
                                            if (e.target.checked) {
                                                setInventoryMaster({ ...inventoryMaster, is_product_material: true })
                                            } else {
                                                setInventoryMaster({ ...inventoryMaster, is_product_material: false })
                                            }
                                        }}>
                                        </Form.Check>
                                    </div>

                                </Form.Group>
                                {
                                    inventoryMaster.bulk_item &&
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        flexWrap: "nowrap"
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            paddingRight: 10
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group>
                                                    <Form.Label>{t("inventory_stock.field_purchase_date")}</Form.Label>
                                                    <Form.Control onChange={(e) => {
                                                        if (e.target.value === "") {
                                                            setInventoryMaster({ ...inventoryMaster, purchase_date: null })
                                                        } else {
                                                            setInventoryMaster({ ...inventoryMaster, purchase_date: e.target.value })
                                                        }
                                                    }} type="date" value={inventoryMaster.purchase_date ? moment(inventoryMaster.purchase_date).format("yyyy-MM-DD") : ""} required></Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("inventory_stock.field_checking_item")}</Form.Label>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            paddingRight: 10
                                                        }}>
                                                            <Form.Control onChange={(e) => {
                                                                if (e.target.value >= 0) {
                                                                    setInventoryMaster({ ...inventoryMaster, period_number: e.target.value })
                                                                }

                                                            }} type="number" value={inventoryMaster.period_number} required placeholder=""></Form.Control>
                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            paddingLeft: 10
                                                        }}>
                                                            <Form.Select required onChange={(e) => {
                                                                setInventoryMaster({ ...inventoryMaster, inventory_period_id: e.target.value })
                                                            }} value={inventoryMaster.inventory_period_id} >
                                                                <option selected value="">

                                                                </option>
                                                                {listInventoryPeriod.map((period, index) => (
                                                                    <option key={index} value={period.id}>{period.inventory_period_name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </div>
                                                    </div>




                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group>
                                                    <Form.Label>{t("inventory_stock.field_purchase_price")}</Form.Label>
                                                    <Form.Control
                                                        onKeyUp={(e) => {

                                                            setInventoryMaster({ ...inventoryMaster, purchase_price: formatCurrency(e.target.value) })
                                                        }}

                                                        onChange={(e) => {
                                                            setInventoryMaster({ ...inventoryMaster, purchase_price: e.target.value })
                                                        }} type="text" value={inventoryMaster.purchase_price} required placeholder=""></Form.Control>
                                                </Form.Group>
                                            </div>



                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            paddingLeft: 10
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Total Item</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => {
                                                        if (e.target.value >= 0) {
                                                            setInventoryMaster({ ...inventoryMaster, stock: e.target.value })
                                                        }
                                                    }} value={inventoryMaster.stock} required></Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label> {t("inventory_stock.field_item_status")}</Form.Label>
                                                    <Form.Select required onChange={(e) => {
                                                        setInventoryMaster({ ...inventoryMaster, inventory_status_id: e.target.value })
                                                    }} value={inventoryMaster.inventory_status_id} >
                                                        <option selected value="">

                                                        </option>
                                                        {listInventoryStatus.map((status, index) => (
                                                            <option key={index} value={status.id}>{status.inventory_status_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>

                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                flex: 1
                                            }}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("inventory_stock.field_item_condition")}</Form.Label>
                                                    <Form.Select required onChange={(e) => {
                                                        setInventoryMaster({ ...inventoryMaster, inventory_condition_id: e.target.value })
                                                    }} value={inventoryMaster.inventory_condition_id} >
                                                        <option selected value="">

                                                        </option>
                                                        {listInventoryCondition.map((condition, index) => (
                                                            <option key={index} value={condition.id}>{condition.inventory_condition_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>




                                        </div>


                                    </div>

                                }
                                <div style={{ paddingBottom: 20 }}></div>
                                {
                                    cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ?
                                        <></>
                                        :
                                        <div className=""
                                            style={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                            <Button style={{ width: 100 }} className="save-button" type="submit" disabled={disabledButton === true ? disabledButton : !updatedButton}>{t("detail_inventory_master.save_button")}</Button>
                                            <Button style={{ width: 100 }} className="cancel cancel-button" variant="danger" onClick={() => {
                                                navigate("/InventoryMaster");
                                            }}>{t("detail_inventory_master.cancel_button")}</Button>
                                            {/* {
                                        location.state.inventoryMasterId === 0 ? <></>
                                            : <Button className="cancel save-button" onClick={() => {
                                                navigate("/InventoryStock", { state: { inventoryMasterId: inventoryMaster.id, departmentCode: inventoryMaster.departementCode } });
                                            }}>Stock</Button>
                                    } */}

                                        </div>

                                }

                            </Form>
                        </div>} />
            </Container>

        </>
    )
}