import React, { useState, useEffect } from "react";
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import {
    isTokenValid, getStaff, getLanguage, getUserRole, getUserById, insertUpdateUser, updatePassword,
    checkUsername, getUserAccessById, convertBase64Image
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import { ArrowLeft } from "react-bootstrap-icons";
import Select from 'react-select';
import { Trans, useTranslation } from "react-i18next";


export default function UserDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [listLanguage, setListLanguage] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [listUserRole, setListUserRole] = useState([]);
    const [listStaff, setListStaff] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState({})
    const [userNameDupplicate, setUserNameDupplicate] = useState("");
    const [message, setMessage] = useState("");
    const [showChangePasswordModal, setShowChangePasswordModal] = useState("");
    const [changePasswordButtonDisabled, setChangePasswordButtonDisabled] = useState(true);
    const [disabledEdit, setDisableEdit] = useState(false);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [user, setUser] = useState({
        id: 0,
        login_username: "",
        login_password: "",
        confirPass: "",
        email: "",
        role_id: "",
        role_name: "",
        language_id: "",
        staff_id: "",
        staff_name: "",
        pass: "",
        user_photo: ""
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadLanguge();
                loadUserRole();
                loadAccess();
                loadStaff();
                checkPasswordLength();

                if (location.state.page) {
                    setDisableEdit(true);
                } else {
                    setDisableEdit(false)
                }

                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
        if (location.state.userId === 0) {

        } else {
            initUser();
        }
    }, []);

    useEffect(() => {
        checkPasswordModalLength();
    }, [password, password2]);

    useEffect(() => {
        if (user.login_username !== "") {
            checkUsernames()
        }

    }, [user])

    useEffect(() => {
        if (listStaff.length > 0 && location.state.userId !== 0) {
            if (user.staff_id) {
                let staffTmp = listStaff.find(p => p.id === user.staff_id);
                if (staffTmp) {
                    setSelectedStaff(staffTmp);
                }

            }
        }
    }, [listStaff])

    useEffect(() => {
        if (selectedStaff) {
            setUser({ ...user, staff_id: selectedStaff.id });
        } else {
            setUser({ ...user, staff_id: null });
        }
    }, [selectedStaff])

    const initUser = async () => {
        try {
            let response = await getUserById(cookies.token, location.state.userId);
            if (response) {
                setUser({
                    id: response.id,
                    language_id: response.language_id,
                    login_username: response.login_username,
                    login_password: response.login_password,
                    email: response.email,
                    role_id: response.role_id,
                    role_name: response.role_name,
                    staff_id: response.staff_id,
                    staff_name: response.staff_name,
                    user_photo: response.photo_base64
                })
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    const saveData = async () => {

        try {
            if (user.login_password == user.confirPass) {
                setMessage("");
                let response = await insertUpdateUser(cookies.token, user);
                if (response === 0) {
                    alert(`${t("detail_user.alert_success_save_data")}`);
                    navigate("/User");
                }
            }
            else {
                setMessage(`${t("detail_user.error_confirmation_password")}`);
            }
        } catch (exception) {

        }
    }

    const loadLanguge = async () => {
        try {
            let listLanguage = await getLanguage(cookies.token);
            // console.log(listLanguage);
            setListLanguage(listLanguage);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadStaff = async () => {
        try {
            let listStaff = await getStaff(cookies.token, cookies.languageId, cookies.extCustomerId);
            setListStaff(listStaff);
        } catch (exception) {
            console.log(exception);
        }


    }
    const loadUserRole = async () => {
        try {
            let listUserRole = await getUserRole(cookies.token);
            let listUserRoleTmp = listUserRole.filter(p => p.role_name !== "Super Administrator");
            setListUserRole(listUserRoleTmp);


        } catch (exception) {
            console.log(exception);
        }
    }

    const changePassword = async () => {
        try {
            let res = await updatePassword(cookies.token, user.id, password);
            if (res.success) {
                if (res.error_code === 0) {
                    alert(`${t("detail_user.alert_success_save_password")}`);
                    setShowChangePasswordModal(false);
                }
                else {
                    alert(res.error_message);
                    setShowChangePasswordModal(false);
                }
            } else {
                alert(res.error_message);
                setShowChangePasswordModal(false);
            }
        }
        catch (exception) {
            // alert("Terjadi Kesalahan Pada Sistem");
            setShowChangePasswordModal(false);
        }
    }

    const checkPasswordModalLength = () => {
        if (password.length > 0 && (password === password2))
            setChangePasswordButtonDisabled(false);
        else
            setChangePasswordButtonDisabled(true);
    }


    const checkPasswordLength = () => {

        if (location.state.userId != 0) {
            setSaveButtonDisabled(false);
        } else {
            if (user.login_password != undefined && user.login_password.length > 0 && user.confirPass != undefined && user.confirPass.length > 0)
                setSaveButtonDisabled(false);
            else
                setSaveButtonDisabled(true);

        }

    }

    const checkUsernames = async () => {
        let response = await checkUsername(cookies.token, user.login_username);
        if (response) {
            if (location.state.userId === 0) {
                if (response.login_username === user.login_username) {
                    setUserNameDupplicate(`${t("detail_user.error_username")}`)
                    setSaveButtonDisabled(true);
                }
            }


        } else {
            setUserNameDupplicate("")
            // setSaveButtonDisabled(false);
        }

    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_user.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/User"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={false}
                    childContent={
                        <div className="master-table-inner-container">
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                saveData();
                            }}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_user.field_username")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setUser({ ...user, login_username: e.target.value })
                                    }} type="text" value={user.login_username} required placeholder=""></Form.Control>
                                </Form.Group>
                                {
                                    userNameDupplicate && <p style={{ color: "red" }}>{userNameDupplicate}</p>
                                }
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_user.field_email")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setUser({ ...user, email: e.target.value })
                                    }} type="email" value={user.email} required placeholder=""></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_user.field_language")}</Form.Label>
                                    <Form.Select disabled={disabledEdit} required onChange={(e) => {
                                        setUser({ ...user, language_id: e.target.value });
                                    }} value={user.language_id} >
                                        <option selected disabled value="">
                                            {t("detail_user.choose_language")}...
                                        </option>
                                        {
                                            listLanguage.map((language, index) => (
                                                <option key={index} value={language.id}>{language.language_name}</option>
                                            )
                                            )
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_user.field_user_role")}</Form.Label>
                                    <Form.Select disabled={disabledEdit} required onChange={(e) => {
                                        setUser({ ...user, role_id: e.target.value });
                                    }} value={user.role_id} >
                                        <option selected disabled value="">
                                            {t("detail_user.choose_user_role")}...
                                        </option>
                                        {
                                            listUserRole.map((userRole, index) => (
                                                <option key={index} value={userRole.id}>{userRole.role_name}</option>
                                            )
                                            )
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("detail_user.field_staff_name")}</Form.Label>
                                    <Select placeholder={""}
                                        options={listStaff} value={selectedStaff} onChange={(e) => {
                                            if (e === null) {
                                                setSelectedStaff(null)
                                            } else {
                                                setSelectedStaff(e)
                                            }

                                        }}
                                        getOptionLabel={
                                            (item) => {
                                                return item.staff_name
                                            }
                                        } getOptionValue={
                                            (item) => {
                                                return item.id;
                                            }
                                        }
                                        isDisabled={disabledEdit}
                                    />
                                </Form.Group>
                                {
                                    user.staff_id !== null &&
                                    <div>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("detail_user.field_photo")}</Form.Label>
                                            <Form.Control onChange={async (e) => {
                                                let base64Img = await convertBase64Image(e.target.files[0]);
                                                const base64ImgString = base64Img.toString();
                                                setUser({ ...user, user_photo: base64ImgString });
                                            }} type="file"></Form.Control>
                                        </Form.Group>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexWrap: "nowrap",
                                            justifyContent: "center"
                                        }}>
                                            <img src={user.user_photo} width={"20%"} />
                                        </div>
                                    </div>

                                }


                                {location.state.userId === 0 ?
                                    <div>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("detail_user.field_password")}</Form.Label>
                                            <Form.Control type="password" onChange={(e) => {
                                                setUser({ ...user, login_password: e.target.value });
                                                checkPasswordLength();
                                            }} value={user.login_password} placeholder="" ></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("detail_user.field_password_confirmation")}</Form.Label>
                                            <Form.Control type="password" onChange={(e) => {
                                                setUser({ ...user, confirPass: e.target.value });
                                                checkPasswordLength();
                                            }} value={user.confirPass} placeholder=""></Form.Control>
                                        </Form.Group><p className="text-danger">{message}</p></div> : ''
                                }

                                <Button style={{ width: 100 }} type="submit" disabled={saveButtonDisabled === true ? saveButtonDisabled : !updatedButton} >{t("detail_user.save_button")}</Button>
                                {location.state.userId != 0 ?
                                    <Button disabled={!updatedButton} className="cancel" onClick={() => {
                                        setShowChangePasswordModal(true);
                                    }}>{t("detail_user.button_change_password")}</Button>
                                    : ''
                                }

                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                    navigate("/User");
                                }}>{t("detail_user.cancel_button")}</Button>
                            </Form>

                        </div>}
                />
                {/* modal change password detail user */}
                <Modal size="lg" show={showChangePasswordModal} onHide={() => {
                    setShowChangePasswordModal(false);
                }}>

                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("detail_user.change_password_title")} <b>{user.login_username}</b>  </h3>
                        </div>
                        <div style={{
                            padding: 10,
                        }}>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                changePassword();

                            }}>
                              
                                <Form.Group controlId="formBasicUsername">
                                    <Form.Label>{t("detail_user.field_new_password")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setPassword(e.target.value);
                                        checkPasswordModalLength();
                                    }} value={password} type="password" placeholder="" />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>{t("detail_user.field_new_password_confirmation")}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setPassword2(e.target.value);
                                        checkPasswordModalLength();
                                    }} value={password2} type="password" placeholder="" />
                                </Form.Group>
                                <p className="text-danger">{message}</p>

                                <div className="d-flex justify-content-between">
                                    <div className="mb-3 form-check">

                                    </div>

                                    {/* <div>
                    <a href="#" className="link">Forgot Password ?</a>
                  </div> */}
                                </div>

                                <div>
                                    <Button style={{ width: 100 }} disabled={changePasswordButtonDisabled} type="submit" >{t("detail_user.save_button")}</Button>

                                    <Button className="cancel" variant="danger" style={{ width: 100 }} type="button" onClick={() => {

                                        setShowChangePasswordModal(false);

                                    }}>{t("detail_user.cancel_button")}</Button>
                                </div>
                            </Form>
                        </div>

                    </div>

                </Modal>
            </Container>

        </>
    )
}