import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container, Table, Modal, InputGroup, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import { isTokenValid, insertUpdateInspectionDetail, findInspectionDetailById, findEquipmentForInspectionDetail, convertBase64Image, insertUpdatePhotoEngineInspectionDetail } from "../Helpers/ApplicationHelpers";
import ContainerBox from "../Components/ContainerBox";
import { Trash, PencilFill, CameraFill, Image, FileCheckFill, TerminalSplit, FilePlusFill, Newspaper } from "react-bootstrap-icons";
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import Accordion from 'react-bootstrap/Accordion';

export default function EngineHealthInspection({ vesselId, vesselEquipmentId, inspectionDetail, updateDetail, index, engineHealthInspectionId }) {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [isEditInspectionDetail, setIsEditInspectionDetail] = useState(false);
    const [tmpDetail, setTmpDetail] = useState({
        data: {},
        shouldUpdateToParent: false
    }
    );
    const [isFindEquipmentModalOpen, setFindEquipmentModalOpen] = useState(false);
    const [shouldLoadEngineFromAPI, setShouldLoadEngineFromAPI] = useState(false);
    const [listEngineForInspectionDetail, setListEngineForInspectionDetail] = useState([]);
    const [engineInsepctionId, setEngineInspectionId] = useState("");
    const [disabledRemoveButton, setDisabledRemoveButton] = useState(false);
    const [insertFoto, setInsertFoto] = useState({
        list_photo: []
    });
    const [fotoModal, setFotoModal] = useState(false);
    const [selectedInspectionDetailId, setSelectedInspectionDetailId] = useState("");

    const [searchEquipmentParameters, setSearchEquipmentParameters] = useState({
        equipmentTitleSearched: true,
        vesselNameSearched: false,
        equipmentModelSearched: false,
        equipmentPartNumberSearched: false,
        equipmentTitle: "",
        vesselName: "",
        equipmentModel: "",
        equipmentPartNumber: "",

    });

    useEffect(() => {
        setTmpDetail(inspectionDetail);
        console.log('engineHealthInspectionId', engineHealthInspectionId);
        // console.log('vesselEquipmentId', vesselEquipmentId)
    }, []);


    /* useEffect(() => {
        if (engineHealthInspectionId !== "") {
            console.log('engineHealthInspectionId', engineHealthInspectionId);
            setEngineInspectionId(engineHealthInspectionId);
            let data = {
                engine_health_inspection_id: engineHealthInspectionId
            };
            setTmpDetail({...tmpDetail, data: data, shouldUpdateToParent: true});
        }
    }, [engineHealthInspectionId]);
 */
    useEffect(() => {
        if (inspectionDetail) {
            setTmpDetail({...tmpDetail, data: inspectionDetail, shouldUpdateToParent: false});
        }
    }, [inspectionDetail])


    useEffect(() => {
        if (tmpDetail && tmpDetail.engine_health_inspection_id === "") {
            setTmpDetail({ ...tmpDetail, engine_health_inspection_id: engineHealthInspectionId });
        }
    }, [engineInsepctionId])

    useEffect(() => {
        if (selectedInspectionDetailId !== "") {
            // console.log(selectedInspectionDetailId)
            setFotoModal(true);
        }
    }, [selectedInspectionDetailId])

    useEffect(() => {
        if (fotoModal === false) {
            setSelectedInspectionDetailId("")
        }
    }, [fotoModal])


    useEffect(() => {
        if (tmpDetail) {
            console.log(tmpDetail.data);
            if (tmpDetail.shouldUpdateToParent) {
                updateDetail(index, tmpDetail.data);
                setTmpDetail({...tmpDetail, shouldUpdateToParent: false});
            }
        }
    }, [tmpDetail]);

    useEffect(() => {
        if (shouldLoadEngineFromAPI === true) {
            loadEngineForInspectionDetail();
        }
    }, [shouldLoadEngineFromAPI])

    // useEffect(() => {
    //     console.log(insertFoto.list_photo);
    //     setTmpDetail({...tmpDetail, photos: insertFoto.list_photo});
    // }, [insertFoto])


    useEffect(() => {
        if (shouldLoadEngineFromAPI === true && listEngineForInspectionDetail) {
            console.log(listEngineForInspectionDetail);
            setShouldLoadEngineFromAPI(false);
        }

    }, [listEngineForInspectionDetail])


    const loadEngineForInspectionDetail = async () => {
        try {
            const result = await findEquipmentForInspectionDetail(cookies.token, vesselId, vesselEquipmentId, searchEquipmentParameters);
            console.log(result);
            setListEngineForInspectionDetail(result);
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const resetListPhoto = async () => {
        let newList = {
            project_document_type_id: "",
            base64_data: "",
            document_label: "",
            created_date: "",
            created_by: "",
            updated_date: "",
            updated_by: "",
            is_active: true,
            engine_health_inspection_id: ""
        };
        setInsertFoto({ ...insertFoto, list_photo: [] });
    }

    const saveData = async () => {
        try {
            console.log('insertFoto', insertFoto)
            let response = await insertUpdatePhotoEngineInspectionDetail(cookies.token, insertFoto);
            if (response.error_code === 0) {
                alert('Berhasil Menyimpan Data')
                setFotoModal(false);
                resetListPhoto();
                // loadTroubleshootingPhoto()
            } else {
                alert('Gagal Menyimpan Data');
            }
            // setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
            alert('Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin');
        }
    }


    return (
        <>
            <div style={{ width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px black solid' }}>
                <div style={{ flex: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: "center", margin: '0px 3px' }}>
                    <Form.Check type="checkbox" checked={tmpDetail ? tmpDetail.data.inspection_done : false} label={'Done'} onClick={(e) => {
                        let data = tmpDetail.data;
                        if (e.target.checked) {
                            data.inspection_done = true;
                        } else {
                            data.inspection_done = false;
                        }
                        setTmpDetail({ ...tmpDetail, data:data, shouldUpdateToParent: true });

                    }}></Form.Check>
                </div>
                <div style={{ flex: 11, width: '100%', display: 'flex', justifyContent: 'center', alignItems: "center", border: tmpDetail ? tmpDetail.data.inspection_done ? '1px #cbffc4 solid': '1px #fffcc4 solid': '1px #fffcc4 solid', borderRadius: '5px', margin: '0px 3px', backgroundColor: tmpDetail ? tmpDetail.data.inspection_done ? '#cbffc4': '#fffcc4': '#fffcc4' }}>
                    <Accordion style={{ width: '100%', padding: '10px' }}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
                                    <div>
                                        <Form.Label>{tmpDetail ? tmpDetail.data.inspection_task : ""}</Form.Label>
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", backgroundColor: tmpDetail ? tmpDetail.data.inspection_done ? '#cbffc4': '#fffcc4': '#fffcc4' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", backgroundColor: tmpDetail ? tmpDetail.data.inspection_done ? '#cbffc4': '#fffcc4': '#fffcc4', border: '1px rgba(3, 30, 103, 1) solid', borderRadius: '5px', padding: '5px', }}>

                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: "center", padding: '5px', backgroundColor: tmpDetail ? tmpDetail.data.inspection_done ? '#cbffc4': '#fffcc4': '#fffcc4' }}>
                                        <Form.Group style={{ flex: 10, width: '100%', padding: '3px', height: '100px'}}>
                                            <Form.Label>Nama Pekerjaan</Form.Label>
                                            <Form.Control disabled={!isEditInspectionDetail} required type="text" onChange={(e) => {
                                                let data = tmpDetail.data;
                                                data.inspection_task = e.target.value
                                                setTmpDetail({ ...tmpDetail, data: data, shouldUpdateToParent: true });
                                            }}
                                                value={tmpDetail ? tmpDetail.data.inspection_task : ""}></Form.Control>
                                            {
                                                tmpDetail ? (tmpDetail.data.inspection_task ? (<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}></p>
                                                </Form.Floating>):(<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}>*require</p>
                                                </Form.Floating>)):(<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}></p>
                                                </Form.Floating>)
                                            }
                                        </Form.Group>
                                        <Form.Group style={{ flex: 8, width: '100%', padding: '3px', height: '100px' }}>
                                            <Form.Label>Equipment</Form.Label>
                                            <Form.Control disabled={!isEditInspectionDetail} required type="text" onChange={(e) => {

                                            }}
                                                value={tmpDetail ? tmpDetail.data.vessel_equipment_name : ""}></Form.Control>
                                            {
                                                tmpDetail ? (tmpDetail.data.vessel_equipment_name ? (<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}></p>
                                                </Form.Floating>):(<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}>*require</p>
                                                </Form.Floating>)):(<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}></p>
                                                </Form.Floating>)
                                            }
                                        </Form.Group>
                                        <Form.Group style={{ flex: 1, width: '100%', padding: '3px', marginTop: '10px' , height: '100px'}}>
                                            <Form.Label>{''}</Form.Label><br />
                                            <Button variant="primary" disabled={false} onClick={() => {
                                                setFindEquipmentModalOpen(true);
                                            }}><FilePlusFill />
                                            </Button>
                                        </Form.Group>
                                        <Form.Group style={{ flex: 2, width: '100%', padding: '3px' , height: '100px'}}>
                                            <Form.Label>Tanggal Mulai</Form.Label>
                                            <Form.Control disabled={!isEditInspectionDetail} required type="date" onChange={(e) => {
                                                let data = tmpDetail.data;
                                                data.start_date = moment(e.target.value).local().format("YYYY-MM-DD")
                                                setTmpDetail({ ...tmpDetail, data: data, shouldUpdateToParent: true });
                                            }}
                                                value={tmpDetail ? moment(tmpDetail.data.start_date).local().format("YYYY-MM-DD") : ""}></Form.Control>
                                            {
                                                tmpDetail ? (tmpDetail.data.start_date ? (<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}></p>
                                                </Form.Floating>):(<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}>*require</p>
                                                </Form.Floating>)):(<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}></p>
                                                </Form.Floating>)
                                            }
                                        </Form.Group>
                                        <Form.Group style={{ flex: 2, width: '100%', padding: '3px' , height: '100px'}}>
                                            <Form.Label>Tanggal Selesai</Form.Label>
                                            <Form.Control disabled={!isEditInspectionDetail} required type="date" onChange={(e) => {
                                                let data = tmpDetail.data;
                                                data.end_date = moment(e.target.value).local().format("YYYY-MM-DD")
                                                setTmpDetail({ ...tmpDetail, data: data, shouldUpdateToParent: true });
                                            }}
                                                value={tmpDetail ? moment(tmpDetail.data.end_date).local().format("YYYY-MM-DD") : ""}></Form.Control>
                                            {
                                                tmpDetail ? (tmpDetail.data.end_date ? (<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}></p>
                                                </Form.Floating>):(<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}>*require</p>
                                                </Form.Floating>)):(<Form.Floating>
                                                    <p style={{fontSize: 'smaller', color: 'red'}}></p>
                                                </Form.Floating>)
                                            }
                                        </Form.Group>
                                        <Form.Group style={{ flex: 1, width: '100%', padding: '3px', marginTop: '10px', height: '100px' }}>
                                            <Form.Label>{''}</Form.Label><br />
                                            {
                                                isEditInspectionDetail === false ? (
                                                    <Button variant="primary" disabled={false} onClick={() => {
                                                        setIsEditInspectionDetail(true);
                                                    }}><PencilFill />
                                                    </Button>
                                                ) : (
                                                    <Button variant="primary" disabled={false} onClick={() => {
                                                        setIsEditInspectionDetail(false);

                                                    }}><FileCheckFill />
                                                    </Button>
                                                )
                                            }
                                        </Form.Group>
                                        <Form.Group style={{ flex: 1, width: '100%', padding: '3px', marginTop: '10px' , height: '100px'}}>
                                            <Form.Label>{''}</Form.Label><br />
                                            {/* <Link exact to={`/EngineHealthInspection/Detail/Photo?id=${tmpDetail ? tmpDetail.id : ''}&engineHealthInspectionId=${tmpDetail ? tmpDetail.engine_health_inspection_id : ''}&inspectionTask=${tmpDetail ? tmpDetail.inspection_task : ""}`} activeClassName="activeClicked" target="_blank" style={{ textDecoration: 'none' }}> */}
                                            <Button variant="primary" disabled={tmpDetail ? (tmpDetail.data.id === "" ? true : false) : true} onClick={() => {
                                                setSelectedInspectionDetailId(tmpDetail ? tmpDetail.data.id : "");
                                            }}><CameraFill />
                                            </Button>
                                            {/* </Link> */}
                                        </Form.Group>
                                        <Form.Group style={{ flex: 1, width: '100%', padding: '3px', marginTop: '10px' , height: '100px'}}>
                                            <Form.Label>{''}</Form.Label><br />
                                            <Link exact to={`/EngineHealthInspection/Detail/Photo?id=${tmpDetail ? tmpDetail.data.id : ''}&engineHealthInspectionId=${tmpDetail ? tmpDetail.data.engine_health_inspection_id : ''}&inspectionTask=${tmpDetail ? tmpDetail.data.inspection_task : ""}`} activeClassName="activeClicked" target="_blank" style={{ textDecoration: 'none' }}>
                                                <Button variant="primary" disabled={tmpDetail ? (tmpDetail.data.id === "" ? true : false) : true} onClick={() => {
                                                }}><Image />
                                                </Button>
                                            </Link>
                                        </Form.Group>
                                        <Form.Group style={{ flex: 1, width: '100%', padding: '3px', marginTop: '10px' , height: '100px'}}>
                                            <Form.Label>{''}</Form.Label><br />
                                            <Link exact to={`/EngineHealthInspection/Detail/Document?id=${tmpDetail ? tmpDetail.data.id : ''}&engineHealthInspectionId=${tmpDetail ? tmpDetail.data.engine_health_inspection_id : ''}&inspectionTask=${tmpDetail ? tmpDetail.data.inspection_task : ""}`} activeClassName="activeClicked" target="_blank" style={{ textDecoration: 'none' }}>
                                                <Button variant="primary" disabled={tmpDetail ? (tmpDetail.data.id === "" ? true : false) : true} onClick={() => {
                                                }}><Newspaper />
                                                </Button>
                                            </Link>
                                        </Form.Group>
                                        <Form.Group style={{ flex: 1, width: '100%', padding: '3px', marginTop: '10px' , height: '100px'}}>
                                            <Form.Label>{''}</Form.Label><br />
                                            <Button variant="danger" disabled={disabledRemoveButton} onClick={() => {
                                                if (window.confirm("Apakah Anda ingin menghapus data inspeksi?")) {
                                                    let data = tmpDetail.data;
                                                    data.is_active = false;
                                                    setTmpDetail({ ...tmpDetail, data: data, shouldUpdateToParent: true , height: '100px'});
                                                }
                                            }}><Trash />
                                            </Button>
                                        </Form.Group>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: "center", padding: '5px', backgroundColor: tmpDetail ? tmpDetail.data.inspection_done ? '#cbffc4': '#fffcc4': '#fffcc4' }}>
                                        <Form.Group style={{ flex: 2, width: '100%', padding: '3px' }}>
                                            <Form.Label>Hasil Inspeksi</Form.Label>
                                            <Editor
                                                apiKey='rxnc1oy2exywbxkggp1tifq3iax959rxiwme9crki1rxr9t1'
                                                disabled={!isEditInspectionDetail}
                                                onEditorChange={(evt, editor) => {
                                                    let data = tmpDetail.data;
                                                    data.inspection_result = evt
                                                    setTmpDetail({ ...tmpDetail, data: data, shouldUpdateToParent: true });
                                                    // setTmpDetail({ ...tmpDetail, inspection_result: evt });
                                                }}
                                                value={tmpDetail ? tmpDetail.data.inspection_result : ""}
                                                init={{
                                                    height: 250,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                    ],
                                                    toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                    toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    /*
                                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                      images_upload_url: 'postAcceptor.php',
                                                      here we add custom filepicker only to Image dialog
                                                    */
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: (cb, value, meta) => {
                                                        const input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        input.addEventListener('change', (e) => {
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', () => {
                                                                /*
                                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                                  registry. In the next release this part hopefully won't be
                                                                  necessary, as we are looking to handle it internally.
                                                                */

                                                                // console.log(editorRef.current.getContent())
                                                                const id = 'blobid' + (new Date()).getTime();
                                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                //   editorRef.current.editorUpload.blobCache

                                                                //Editor.activeEditor.editorUpload.blobCache;

                                                                const base64 = reader.result.split(',')[1];
                                                                const blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), { title: file.name });
                                                            });
                                                            reader.readAsDataURL(file);
                                                        });

                                                        input.click();
                                                    },
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: "center", padding: '5px', backgroundColor: tmpDetail ? tmpDetail.data.inspection_done ? '#cbffc4': '#fffcc4': '#fffcc4' }}>
                                        <Form.Group style={{ flex: 2, width: '100%', padding: '3px' }}>
                                            <Form.Label>Rekomendasi</Form.Label>
                                            <textarea
                                                className="form-control"
                                                value={tmpDetail ? tmpDetail.data.recommendation : ""}
                                                placeholder=""
                                                disabled={!isEditInspectionDetail}
                                                onChange={(e) => {
                                                    let data = tmpDetail.data;
                                                    data.recommendation = e.target.value;
                                                    setTmpDetail({ ...tmpDetail, data: data, shouldUpdateToParent: true });
                                                    // setTmpDetail({ ...tmpDetail, recommendation: e.target.value });

                                                }}
                                                rows="2"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <Modal size="xl" show={isFindEquipmentModalOpen} onHide={
                () => {
                    setFindEquipmentModalOpen(false)
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={"Pilih Equipment"} useActionContainer={true}
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flex: 1,
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5
                                    }} className="mb-3">
                                        <Form.Label>Nama Equipment</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Check type="switch" checked={searchEquipmentParameters.equipmentTitleSearched} onClick={(e) => {
                                                if (e.target.checked) {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentTitleSearched: true })
                                                } else {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentTitleSearched: false, equipmentTitle: "" })
                                                }
                                            }}></Form.Check>
                                            <div style={{ paddingLeft: 20 }}></div>
                                            <Form.Control disabled={!searchEquipmentParameters.equipmentTitleSearched} type="text"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        setShouldLoadEngineFromAPI(true);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentTitle: e.target.value })
                                                }} value={searchEquipmentParameters.equipmentTitle}></Form.Control>
                                        </div>
                                    </Form.Group>
                                    <Form.Group style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5
                                    }} className="mb-3">
                                        <Form.Label>Nama Vessel</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Check type="switch" checked={searchEquipmentParameters.vesselNameSearched} onClick={(e) => {
                                                if (e.target.checked) {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, vesselNameSearched: true })
                                                } else {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, vesselNameSearched: false, vesselName: "" })
                                                }
                                            }}></Form.Check>
                                            <div style={{ paddingLeft: 20 }}></div>
                                            <Form.Control disabled={!searchEquipmentParameters.vesselNameSearched} type="text"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        setShouldLoadEngineFromAPI(true);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, vesselName: e.target.value })
                                                }} value={searchEquipmentParameters.vesselName}></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flex: 1,
                                    flexDirection: "row",
                                    padding: 5
                                }}>
                                    <Form.Group style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5
                                    }} className="mb-3">
                                        <Form.Label>Model Equipment</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Check type="switch" checked={searchEquipmentParameters.equipmentModelSearched} onClick={(e) => {
                                                if (e.target.checked) {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentModelSearched: true })
                                                } else {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentModelSearched: false, equipmentModel: "" })
                                                }
                                            }}></Form.Check>
                                            <div style={{ paddingLeft: 20 }}></div>
                                            <Form.Control disabled={!searchEquipmentParameters.equipmentModelSearched} required type="text"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        setShouldLoadEngineFromAPI(true);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentModel: e.target.value })
                                                }} value={searchEquipmentParameters.equipmentModel}></Form.Control>
                                        </div>
                                    </Form.Group>
                                    <Form.Group style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5
                                    }} className="mb-3">
                                        <Form.Label>Part Number</Form.Label>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "row",
                                            padding: 5
                                        }}>
                                            <Form.Check type="switch" checked={searchEquipmentParameters.equipmentPartNumberSearched} onClick={(e) => {
                                                if (e.target.checked) {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentPartNumberSearched: true })
                                                } else {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentPartNumberSearched: false, equipmentPartNumber: "" })
                                                }
                                            }}></Form.Check>
                                            <div style={{ paddingLeft: 20 }}></div>
                                            <Form.Control disabled={!searchEquipmentParameters.equipmentPartNumberSearched} required type="text"
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        setShouldLoadEngineFromAPI(true);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setSearchEquipmentParameters({ ...searchEquipmentParameters, equipmentPartNumber: e.target.value })
                                                }} value={searchEquipmentParameters.equipmentPartNumber}></Form.Control>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                    <Button style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }} variant="primary" onClick={() => {
                                        setShouldLoadEngineFromAPI(true);
                                    }}>Cari</Button>
                                    <div style={{ paddingRight: 10, paddingLeft: 10 }}></div>
                                    <Button style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }} variant="primary" onClick={() => {
                                        setListEngineForInspectionDetail([]);
                                    }}>Reset</Button>
                                </div>
                                <div>
                                {listEngineForInspectionDetail && listEngineForInspectionDetail.map((engine, idx) => {
                                        return (
                                            <div key={idx} style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                borderStyle: "solid",
                                                borderWidth: 1,
                                                margin: 5,
                                                borderRadius: 10,
                                                padding: 5
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 8
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}><h5>{engine.title}</h5></div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}>Equipment Name: {engine.equipment_name}</div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}>Serial Number: {engine.serial_number}</div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}>Description: {engine.description}</div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    flex: 1,
                                                    alignContent: "center",
                                                    justifyContent: "center",
                                                }}>
                                                    <Button style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }} variant="primary" onClick={() => {
                                                        let data = tmpDetail.data;
                                                        data.vessel_equipment_name = engine.equipment_name;
                                                        data.vessel_equipment_id = engine.vessel_equipment_id;
                                                        setTmpDetail({ ...tmpDetail, data: data, shouldUpdateToParent: true });
                                                        setFindEquipmentModalOpen(false);
                                                    }}>Pilih Equipment</Button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {!listEngineForInspectionDetail && listEngineForInspectionDetail.length === 0 &&
                                        <div style={{
                                            flex: 2,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginTop: 80,
                                        }}>
                                            <div>Data Mesin Tidak Ditemukan</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    } />
            </Modal>

            <Modal size="xl" show={fotoModal} onHide={
                () => {
                    setFotoModal(false);
                }
            }>
                <ContainerBox containerPos="inner" titleCaption={"Foto Hasil Kerja"} useActionContainer={true}
                    childContent={
                        <div style={{
                            padding: 10,
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                padding: 5
                            }}>


                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    setFotoModal(false);
                                    saveData();
                                    // setDisabledButton(true);
                                    // setLoading(true);

                                }}>

                                    {
                                        insertFoto.list_photo && insertFoto.list_photo.map((foto, index) => {
                                            return (
                                                <div>
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        width: "100%",
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        borderWidth: 2,
                                                        borderRadius: 5,
                                                        borderStyle: "solid",
                                                        borderColor: "rgba(3, 30, 103, 1)",
                                                        padding: 10
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            width: "100%",

                                                        }}>
                                                            <img src={foto.base64_data}
                                                                style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Foto</Form.Label>
                                                                <Form.Control required type="file" onChange={async (e) => {
                                                                    // console.log(searchParams.get("engineHealthInspectionId"));
                                                                    let base64Img = await convertBase64Image(e.target.files[0]);
                                                                    const base64ImgString = base64Img.toString();
                                                                    // setListPhoto({ ...listPhoto, base64_image: base64ImgString })
                                                                    let detailJobs = insertFoto.list_photo;
                                                                    detailJobs[index].base64_data = base64ImgString;
                                                                    detailJobs[index].project_document_type_id = 'c9a981ff-7661-47a9-81ca-1bafe1be090b';
                                                                    detailJobs[index].engine_health_inspection_detail_id = engineHealthInspectionId;
                                                                    detailJobs[index].inspection_detail_id = selectedInspectionDetailId;
                                                                    // setTmpDetail({...tmpDetail, photos: detailJobs});
                                                                    setInsertFoto({ ...insertFoto, list_photo: detailJobs });
                                                                    // setTmpDetail({...tmpDetail, photos: insertFoto.list_photo});
                                                                }}></Form.Control>
                                                            </Form.Group>

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            flex: 1

                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                paddingRight: 5
                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Label</Form.Label>
                                                                    <Form.Control required type="text" onChange={(e) => {
                                                                        let detailJobs = insertFoto.list_photo;
                                                                        detailJobs[index].document_label = e.target.value;
                                                                        // setTmpDetail({...tmpDetail, photos: detailJobs});
                                                                        setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                    }} value={foto.document_label}></Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                paddingLeft: 5

                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Tanggal Foto Diambil</Form.Label>
                                                                    <Form.Control required type="date" onChange={(e) => {
                                                                        let detailJobs = insertFoto.list_photo;
                                                                        detailJobs[index].updated_date = e.target.value;
                                                                        // setTmpDetail({...tmpDetail, photos: detailJobs});
                                                                        setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                    }} value={foto.updated_date ? moment(foto.updated_date).format("yyyy-MM-DD") : ""}></Form.Control>
                                                                </Form.Group>
                                                            </div>


                                                        </div>
                                                        {/* <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",

                                                            }}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Keterangan</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} onChange={(e) => {
                                                                        let detailJobs = insertFoto.list_photo;
                                                                        detailJobs[index].description = e.target.value;
                                                                        setInsertFoto({ ...insertFoto, list_photo: detailJobs })
                                                                    }} value={foto.description}></Form.Control>
                                                                </Form.Group>
                                                            </div> */}
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            justifyContent: "center",
                                                            flexDirection: "column",
                                                            flex: 1,
                                                            paddingLeft: 5,
                                                            paddingRight: 5,
                                                        }}>
                                                            <div style={{ paddingTop: 10 }}></div>
                                                            <Button onClick={() => {
                                                                if (window.confirm('Apakah Anda Yakin Menghapus Data ini?')) {
                                                                    let list = [...insertFoto.list_photo];
                                                                    list.splice(index, 1);
                                                                    setInsertFoto({ ...insertFoto, list_photo: list })
                                                                }

                                                            }} variant="danger"><Trash size={20} /></Button>

                                                        </div>

                                                    </div>
                                                    <div style={{ paddingBottom: 10 }}></div>


                                                </div>



                                            )
                                        })
                                    }
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        padding: 5,
                                    }}>
                                        <Button variant="primary" onClick={() => {
                                            let newParameter = {
                                                base64_data: "",
                                                taken_date: null,
                                                document_label: "",
                                                inspection_detail_id: selectedInspectionDetailId
                                            };

                                            if (!insertFoto.list_photo) {

                                                let list = [];
                                                list.push(newParameter);
                                                setInsertFoto({ ...insertFoto, list_photo: list })
                                            } else {

                                                let list = insertFoto.list_photo;
                                                list.push(newParameter);
                                                setInsertFoto({ ...insertFoto, list_photo: list });
                                            }

                                        }}>Tambah Foto</Button>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "row",
                                        padding: 5,
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            paddingRight: 5,
                                        }}><Button variant="primary" disabled={false} type="submit">Simpan</Button></div>


                                        <div style={{
                                            paddingLeft: 5,
                                        }}>
                                            <Button variant="danger" onClick={() => {
                                                setFotoModal(false);



                                            }}>Batal</Button>

                                        </div>
                                    </div>
                                </Form>




                            </div>






                        </div>

                    }>
                </ContainerBox>
            </Modal>
        </>
    )
}