import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUser, deleteUserAccount, getUserAccessById, getUserCount } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { EyeFill, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise } from "react-bootstrap-icons";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import ContainerBox from "../../../Components/ContainerBox";
import Paginations from "../../../Components/Pagination";
import { Trans, useTranslation } from "react-i18next";

export default function UserPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listUser, setListUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deletedButton, setDeletedButton] = useState("");
    const [updatedButton, setUpdatedButton] = useState("");
    const [selectedUser, setUser] = useState({
        id: "",
    })
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [isSearched, setIsSearched] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [resetSearch, setResetSearch] = useState(0);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_user.order_name1")}`,
        value: "userAccount.login_username"
    });
    const orderByList = [{
        label: `${t("index_user.order_name1")}`,
        value: "userAccount.login_username"
    }, {
        label: `${t("index_user.order_name2")}`,
        value: "userRole.role_name"
    }, {
        label: `${t("index_user.order_name3")}`,
        value: "staff.staff_name"
    }
    ]

    const [descending, setDescending] = useState(false);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadUser();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadUser();
    }, [page]);

    useEffect(() => {
        if(isSearched !== 0)
        loadUser();
    }, [itemPerPage]);

    useEffect(() => {
        if(isSearched !== 0)
        loadUser();
    }, [orderBy]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadUser();
    }, [resetSearch]);

    useEffect(() => {
        if(isSearched !== 0)
        loadUser()
    }, [descending]);

    const loadUser = async () => {
        try{
        let count = await getUserCount(cookies.token, cookies.extCustomerId, searchQuery, false);
        let totalPage = count / itemPerPage;
        setTotalPage(totalPage);

        let listUser = await getUser(cookies.token, undefined,cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
        setListUser(listUser);
        setLoading(false);
        setResetSearch(0);
        setIsSearched(0)
        }catch(exception){
            console.log(exception);
        }
    }

    const deleteUser = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("index_user.delete_confirmation")}`) == true) {
                let deleteSelectedUser = await deleteUserAccount(cookies.token, id);
                if (deleteSelectedUser.success) {
                    if (deleteSelectedUser.error_code === 0) {
                        alert(`${t("index_user.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("index_user.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(deleteSelectedUser.error_message);
                }
                loadUser();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }
    
    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_user.order_name1")}`,
                value: "userAccount.login_username"
            })
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_user.header_title")}`}
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/User/Detail", { state: { userId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_user.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_user.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadUser();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadUser();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadUser();
                                            setResetSearch(1)
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>

                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_user.table_name1")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_user.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_user.table_name3")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_user.table_name4")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_user.table_name5")}</div>
                                </div>
                                {
                                    listUser.map((user, index) => {
                                        return (<div className="table-body" key={index}>
                                            <div style={{ flex: 0.5 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{user.login_username}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{user.role_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{user.staff_name}</p></div>
                                            {/* <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button className="m-1" variant="primary" onClick={() => {
                                                        navigate("/User/Detail", { state: { userId: user.id } });
                                                    }}><PencilFill /></Button>
                                                </div></div> */}
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary"
                                                        onClick={() => {
                                                            navigate("/User/Detail", { state: { userId: user.id } });
                                                        }}
                                                    ><PencilFill /></Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={!deletedButton} variant="danger"
                                                        onClick={event => {
                                                            // setUser({ ...selectedUser, id : user.id })
                                                            deleteUser(user.id);
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>

                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                            <Loading
                                loading={loading}
                            />
                        </div>
                    } />
            </Container>

        </>
    )
}