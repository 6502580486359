import React, { useState, useEffect } from "react";
import { Button, Form, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import { isTokenValid, getVesselTypeById, insertUpdateVesselType, getLanguage, getUserAccessById } from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ButtonForm from "../../../../Components/ButtonForm";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import { ArrowLeft } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";

export default function VesselTypeDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [updatedButton, setUpdatedButton] = useState("");
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [disabledButton, setDisabledButton] = useState(false);
    const [listLanguage, setListLanguage] = useState([]);
    const [vesselType, setVesselType] = useState({
        languageId: "",
        vesselTypeName: "",
        vesselTypeDesc: "",
        vesselTypeCode: ""
    })

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadLanguge();
                loadAccess();
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
            }
        }
        checkCookies();
        if (location.state.staffId === "") {

        } else {
            initVesselType();
        }
    }, []);


    const initVesselType = async () => {
        try {
            let response = await getVesselTypeById(cookies.token, location.state.vesselTypeId);
            if (response) {
                setVesselType({
                    ...vesselType,
                    languageId: response.language_id,
                    vesselTypeName: response.vessel_type_name,
                    vesselTypeDesc: response.vessel_type_desc,
                    vesselTypeCode: response.vessel_type_code
                })
            }
        } catch (exception) {
            // console.log(exception);
        }
    }

    const addVesselType = async () => {
        try {
            let response = await insertUpdateVesselType(cookies.token, vesselType, location.state.vesselTypeId, cookies.languageId);
            if (response === 0) {
                alert(`${t("detail_vessel_type.alert_success_save_data")}`);
                navigate(".././VesselType");
            } else {
                alert(`${t("detail_vessel_type.alert_success_save_data")}`);
            }
            setLoading(false);
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadLanguge = async () => {
        try {
            let listLanguage = await getLanguage(cookies.token);
            setListLanguage(listLanguage);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")),cookies.languageId);
            if (response) {
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={`${t("detail_vessel_type.header_title")}`}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate('/VesselType'); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={true}
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <ContainerBox containerPos="inner" captionSize="small" titleCaption={`${t("detail_vessel_type.header_vessel_type")}`} childContent={
                                            <div style={{
                                                padding: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    flexWrap: "nowrap",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        flexWrap: "nowrap",
                                                        paddingRight: 10
                                                    }}>

                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{t("detail_vessel_type.field_vessel_type")}</Form.Label>
                                                            <Form.Control onChange={(e) => {
                                                                setVesselType({ ...vesselType, vesselTypeName: e.target.value });
                                                            }} type="text" value={vesselType.vesselTypeName} required></Form.Control>
                                                        </Form.Group>
                                                        
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{t("detail_vessel_type.field_vessel_type_code")}</Form.Label>
                                                            <Form.Control onChange={(e) => {
                                                                setVesselType({ ...vesselType, vesselTypeCode: e.target.value })
                                                            }} type="text" value={vesselType.vesselTypeCode} required ></Form.Control>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{t("detail_vessel_type.field_description")}</Form.Label>
                                                            <Form.Control as={"textarea"} rows={3} onChange={(e) => {
                                                                setVesselType({ ...vesselType, vesselTypeDesc: e.target.value });
                                                            }} type="text" value={vesselType.vesselTypeDesc} required ></Form.Control>
                                                        </Form.Group>


                                                    </div>
                                                </div>


                                            </div>
                                        }>
                                        </ContainerBox>
                                    </div>
                                </div>


                            </div>

                            <ButtonForm addData={() => {
                                addVesselType();
                                setDisabledButton(true);
                            }}
                                saveButton={`${t("button_component.save_button")}`}
                                cancelButton={`${t("button_component.cancel_button")}`}
                                button={disabledButton === true ? disabledButton : !updatedButton} />
                        </div>

                    }>


                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}