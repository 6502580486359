import "./index.css";
import "../../../SMSApp.css";
import TopNavBar from "../../../Components/TopNavBar/TopNavBar.js";
import TitleAlarmBar from "../../../Components/TitleAlarmBar/TitleAlarmBar.js";
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavBar.js";
import WidthButton from "../../../Components/WidthButton/WidthButton";
import PictWheelHouse from "../../../Assets/Images/wheelHouse.png";
import BitLamp from "../../../Components/BitLamp/BitLamp";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { getPLCCoordinate, isTokenValid, getPlcValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading.js";


export default function WheelHouse() {
    const navigate = useNavigate();
    const navigateMainDeck = () => { navigate("/FireAlarm/MainDeck") };
    const navigateBelowMainDeck = () => { navigate("/FireAlarm/BelowMainDeck") };
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // const wheelhouseBitlamp = [{
    //     text: "Bridge 1",
    //     alarm: true
    // },
    // {
    //     text: "Bridge 2",
    //     alarm: false
    // },
    // {
    //     text: "Manual Call Point Wheel House",
    //     alarm: true
    // }]
    const [cookies, setCookie] = useCookies(["token"]);
    const location = useLocation();
    const [wheelhouseBitlamp, setWheelHouseBitlamp] = useState([])
    // const [wheelhouseBitlamp, setWheelHouseBitlamp] = useState([
    //     { text: "Bridge1", alarm: false, coordinate: "M32" },
    //     { text: "Bridge2", alarm: true, coordinate: "M34" },
    //     { text: "Manual Call Point Wheel House", alarm: false, coordinate: "M52" },
    // ])

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadBilampCoordinate();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (wheelhouseBitlamp.length > 0) {
    //         const interval = setInterval(async () => {
    //             loadBitlampValue()
    //         }, 1000)


    //         return () => clearInterval(interval)
    //     }

    // }, [wheelhouseBitlamp]);

    useEffect(() => {
        if (wheelhouseBitlamp.length > 0 && isDataLoaded === false) {
            const loadData = async () => {
                await loadBitlampValue();
            };

            loadData();
            const interval = setInterval(loadData, 1000);
            return () => clearInterval(interval);
        }
    }, [wheelhouseBitlamp, isDataLoaded]);
    // const loadBitlampValue = async()=>{
    //     try {
    //         // let wheelhouseBitlampTes = wheelhouseBitlamp;
    //         // wheelhouseBitlamp[0].alarm = !wheelhouseBitlamp[0].alarm
    //         // setWheelHouseBitlamp(wheelhouseBitlampTes.slice(0))
    //     } catch (exception) {
    //         console.log(exception);
    //     }
    // }

    const loadBitlampValue = async () => {
        try {
            setIsDataLoaded(true);
            let coordinate = wheelhouseBitlamp.map(p => p.plc_coordinate);
            let response = await getPlcValue(cookies.token, coordinate, cookies.serialNumber);
            let updatedList = wheelhouseBitlamp.map(item => {
                let alarmValue = response.find(p => item.plc_coordinate === p.field);
                if (alarmValue) {
                    return { ...item, alarm: alarmValue.value === 1 };
                } else {
                    return { ...item, alarm: item.alarm };
                }
            });
            setWheelHouseBitlamp(updatedList);

            setLoading(false);
            setIsDataLoaded(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadBilampCoordinate = async () => {
        try {
            let response = await getPLCCoordinate(cookies.token, "fire_alarm", cookies.vesselId);
            let newLisst = response.filter(p => p.sub_coordinate_group === "wheel_house")
            setWheelHouseBitlamp(newLisst);
            if(response.length < 1){
                setLoading(false);
            }
        } catch (exception) {

        }
    }

    return (
        <>
            <div><TopNavBar /></div>
            <div><TitleAlarmBar text={"FIRE ALARM > WHEEL HOUSE"} /></div>
            <div className="contentWheelHouse">
                <div className="topBtn">
                    <WidthButton textBtn={"GO TO MAIN DECK"} handleOnClick={navigateMainDeck} />
                    <WidthButton textBtn={"GO TO BELOW MAIN DECK"} handleOnClick={navigateBelowMainDeck} />
                </div>
                <div className="containerImgWH">
                    <img src={PictWheelHouse} alt="" />
                </div>
                <div className="containerBitLampsWH">
                    <div className="boxBitLampWH">
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "center",
                            gap: 120
                        }}>
                            {
                                wheelhouseBitlamp.length > 0 && wheelhouseBitlamp.map((wheelhouse, index) => {
                                    return (
                                        <div key={index}>
                                            <BitLamp text={wheelhouse.coordinate_desc} alarm={wheelhouse.alarm} />
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                   
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
              />
            <div style={{ paddingBottom: 120 }}></div>
            <div><BottomNavBar /></div>
        </>
    );
}