import LampNavButton from "../LampNavButton/LampNavButton";
import NavButton from "../NavButton/NavButton";
import "./BottomNavBar.css";
import { useNavigate } from "react-router-dom";

export default function BottomNavBar() {
    const navigate = useNavigate();
    const navigateMainEngine = () => { navigate("/MainEngine") }
    const navigateSpeedNTemp = () => { navigate("/MainEngine/SpeedTemp") }
    const navigatePortCalibration = () => { navigate("/MainEngine/PortCalibration") }
    const navigatePortCyl1 = () => { navigate("/MainEngine/PortCyl1") }
    const navigateLOPressLastBearing = () => { navigate("/MainEngine/LOPressLastBearing") }
    const navigateAuxEngine = () => { navigate("/AuxEngine") }
    const navigateGearBox = () => { navigate("/GearBox") }
    const navigateBilgesTanks = () => { navigate("/BilgesTanks2") }
    const navigateLevelSoundingTanks = () => { navigate("/LevelSoundingTanks") }
    const navigateMiscellaneous = () => { navigate("/Miscellaneous") }
    const navigateAlarm = () => { navigate("/Alarm/Logs") }
    const navigateWatertightDoor = () => {navigate("/WatertightDoor")}
    const navigateMSB = () =>{navigate("/MSB")}
    const navigatePumpMotor = () =>{navigate("/PumpMotor")}

    return (
        <>
            <div className="bottomNavBar">
                <div className="contentNavBar">
                    <LampNavButton text1={"MAIN"} text2={"ENGINE"} lamp={false} handleOnClick={navigateMainEngine} />
                    {/* <NavButton text1={"ME SPEED"} text2={"& TEMPERATURE"} handleOnClick={navigateSpeedNTemp} /> */}
                    {/* <NavButton text1={"ME PORT"} text2={"CALIBRATION"} handleOnClick={navigatePortCalibration} /> */}
                    {/* <NavButton text1={"ME "} text2={"PORT CYL 1"} handleOnClick={navigatePortCyl1} /> */}
                    {/* <NavButton text1={"ME "} text2={"LO PRESS"} handleOnClick={navigateLOPressLastBearing} /> */}
                    <LampNavButton text1={"AUX"} text2={"ENGINE"} lamp={false} handleOnClick={navigateAuxEngine} />
                    <LampNavButton text1={"PUMP AND"} text2={"MOTOR"} lamp={false} handleOnClick={navigatePumpMotor} />
                    {/* <LampNavButton text1={"GEAR"} text2={"BOX"} lamp={false} handleOnClick={navigateGearBox} /> */}
                    <LampNavButton text1={"TANK"} text2={"LEVEL"} lamp={false} handleOnClick={navigateBilgesTanks} />
                    {/* <LampNavButton text1={"LEVEL SOUNDING"} text2={"TANKS"} lamp={true} handleOnClick={navigateLevelSoundingTanks} /> */}
                    <LampNavButton text1={"WATER TIGHT"} text2={"DOOR"} lamp={false} handleOnClick={navigateWatertightDoor} />
                    <LampNavButton text1={"MAIN SWITCH"} text2={"BOARD"} lamp={false} handleOnClick={navigateMSB} />
                    <NavButton text1={"ALARM"} handleOnClick={navigateAlarm} />
                    {/* <NavButton text1={"CALIBRATE"} handleOnClick={navigateMainEngine} /> */}
                </div>
                <div style={{paddingBottom:120}}></div>
            </div>
        </>
    )
}