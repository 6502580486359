import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getUserAccessById } from "../../../Helpers/ApplicationHelpers";
import { getHandover, getHandoverCount, deleteHandover } from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import { EyeFill, PencilFill, Trash, FilePlusFill, Search, ArrowClockwise } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/Loading";
import Navbar from "../../../Components/NavBar";
import ContainerBox from "../../../Components/ContainerBox";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";


export default function HandoverPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listHandover, setlistHandover] = useState([]);
    const [loading, setLoading] = useState(true);
    const [HandoverCount, setHandoverCount] = useState(0);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([50, 100, 150, 200, 250]);
    const [descending, setDescending] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);

    const [orderBy, setOrderBy] = useState({
        label: `${t("index_handover_inventory.order_name1")}`,
        value: "form_number"
    });

    const orderByList = [
        {
            label: `${t("index_handover_inventory.order_name2")}`,
            value: "form_number"
        },
        {
            label: `${t("index_handover_inventory.order_name2")}`,
            value: "handover_date"
        },
        

    ]



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadHandover();
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadHandover();
    }, [page]);

    useEffect(() => {
        loadHandover();
    }, [itemPerPage]);

    useEffect(() => {
        loadHandover();
    }, [orderBy]);

    useEffect(() => {
        loadHandover();
    }, [descending]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadHandover();
    }, [resetSearch])



    const loadHandover = async () => {
        try {
            let HandoverCount = await getHandoverCount(cookies.token, cookies.extCustomerId);
            setHandoverCount(HandoverCount);
            let totalPage = HandoverCount / itemPerPage;
            setTotalPage(totalPage);
            let tmpSearchQuery = undefined;
            if (searchQuery) {
                tmpSearchQuery = searchQuery;
            }
            let listHandover = await getHandover(cookies.token,cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setlistHandover(listHandover);
            setLoading(false);
            setResetSearch(0);
        } catch (exception) {
            console.log(exception);
        }


    }



    const formatDate = (timestamps) => {
        var dateFormat = new Date(timestamps.toString());
        var tanggal = dateFormat.getDate();
        var bulan = dateFormat.getMonth();
        var tahun = dateFormat.getFullYear();
        var fixDate = tanggal + "/" + bulan + "/" + tahun;
        return fixDate
    }

    const deletethisHandover = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("index_handover_inventory.delete_confirmation")}`) == true) {
                let deleteSelectedHandover = await deleteHandover(cookies.token, id);
                if (deleteSelectedHandover.success) {
                    if (deleteSelectedHandover.error_code === 0) {
                        alert(`${t("index_handover_inventory.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("index_handover_inventory.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("index_handover_inventory.delete_failed_alert")}`);
                }
                loadHandover();
            }

        } catch (exception) {
            // alert("Something went wrong, please contact administrator");
            console.log(exception);
        }
    }


    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_handover_inventory.order_name1")}`,
                value: "form_number"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_handover_inventory.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/Handover/Detail", { state: { handoverId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_handover_inventory.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_handover_inventory.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadHandover();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadHandover();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            // await loadHandover();
                                            setResetSearch(1);
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_handover_inventory.table_name1")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_handover_inventory.table_name2")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_handover_inventory.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_handover_inventory.table_name4")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_handover_inventory.table_name5")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_handover_inventory.table_name6")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_handover_inventory.table_name7")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_handover_inventory.table_name8")}</div>
                                </div>
                                {
                                    listHandover.map((handover, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{handover.document_number}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p> {handover.handover_date?moment(handover.handover_date).format('DD-MMMM-YYYY'):""}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{handover.division_to_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{handover.division_from_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{handover.staff_givenbyname}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{handover.staff_receivedbyname}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/Handover/Detail", { state: { handoverId: handover.id } });
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                        deletethisHandover(handover.id);
                                                    }}><Trash />
                                                    </Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            {listHandover.length == 0 ?
                                <center>{t("index_handover_inventory.text_no_data")} </center> : <div></div>

                            }

                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>

        </>
    );
}   