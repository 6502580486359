import React, { useState, useEffect } from "react";
import { Spinner, Button, Container, Table, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getProject, deleteProject, getProjectCount, getUserAccessById } from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import { EyeFill, ArrowLeft, PencilFill, Trash, Search, ArrowClockwise, FilePlusFill } from "react-bootstrap-icons";
import Paginations from "../../../Components/Pagination";
import Navbar from "../../../Components/NavBar";
import Loading from "../../../Components/Loading";
import ContainerBox from "../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";

export default function ProjectPage() {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [listProject, setListProject] = useState([]);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [projectCount, setProjectCount] = useState(0);
    const [page, setPage] = useState(0);
    const [descending, setDescending] = useState(true);
    const [resetSearch, setResetSearch] = useState(0);
    const [searchQuery, setSearchQuery] = useState("")
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [disabledCustomerForm, setDisabledCustomerForm] = useState(false);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_project.order_name1")}`,
        value: "created_date"
    });

    const orderByList = [
        {
            label: `${t("index_project.order_name1")}`,
            value: "created_date"
        }
    ]
    const [isDescActive, setIsDescActive] = useState(true);



    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                loadProject();
                loadAccess();
                if (cookies.extCustomerId !== "null" && cookies.logingAs === "Customer") {
                    setDisabledCustomerForm(true);
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        loadProject();
    }, [page]);

    useEffect(() => {
        loadProject();
    }, [itemPerPage]);

    useEffect(() => {
        loadProject();
    }, [orderBy]);

    useEffect(() => {
        loadProject();
    }, [descending]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadProject();
    }, [resetSearch])

    // useEffect(() => {
    //     if (searchQuery === "") {
    //         loadProject()
    //     }
    // }, [searchQuery]);


    const loadProject = async () => {
        try {
            let projectCount = await getProjectCount(cookies.token, cookies.extCustomerId);
            setProjectCount(projectCount);
            let totalPage = projectCount / itemPerPage;
            setTotalPage(totalPage);
       
            let listProject = await getProject(cookies.token, cookies.languageId,cookies.extCustomerId, page, itemPerPage, orderBy.value, descending, searchQuery, false);
            setListProject(listProject);
            setLoading(false);
            setResetSearch(0);
        } catch (exception) {

        }

    }

    const deleteProjectData = async (id) => {
        try {
            // let text;
            if (window.confirm(`${t("index_project.delete_confirmation")}`) == true) {
                let deleteSelectedProject = await deleteProject(cookies.token, id);
                if (deleteSelectedProject.success) {
                    if (deleteSelectedProject.error_code === 0) {
                        alert(`${t("index_project.delete_success_alert")}`);
                    }
                    else {
                        alert(`${t("index_project.delete_failed_alert")}`);
                    }
                }
                else {
                    alert(`${t("index_project.delete_confirmation")}`);
                }
                loadProject();
            }

        } catch (exception) {
            // alert("Terjadi Kesalahan Pada Sistem Silahkan Hubungi Admin");
            console.log(exception);
        }
    }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname,cookies.languageId);
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("index_project.order_name1")}`,
                value: "created_date"
            })
        } catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            }}>
                <ContainerBox
                    titleCaption={`${t("index_project.header_title")}`}

                    useActionContainer={!disabledCustomerForm}
                    actionContainerChild={
                        <div>
                            <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { navigate("/Project/Detail", { state: { projectId: 0 } }) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_project.add_button")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("index_project.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);
                                                await loadProject();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);
                                            await loadProject();
                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");
                                            setResetSearch(1)
                                            // await loadProject();
                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 0.5 }} className="table-header-content">#</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_project.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_project.table_name2")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_project.table_name3")}</div>
                                    {/* <div style={{ flex: 2 }} className="table-header-content">Perusahaan</div> */}
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_project.table_name4")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_project.table_name5")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_project.table_name6")}</div>
                                    <div style={{ flex: 1 }} className="table-header-content">{t("index_project.table_name7")}</div>
                                </div>
                                {
                                    listProject.map((project, index) => {
                                        return (<div className="table-body">
                                            <div style={{ flex: 0.5 }} className="table-body-content"><p>{(page * (itemPerPage)) + (index + 1)}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{project.project_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{project.project_number}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{project.project_type_name}</p></div>
                                            {/* <div style={{ flex: 2 }} className="table-body-content"><p>{project.company_name}</p></div> */}
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{project.customer_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content"><p>{project.vessel_name}</p></div>
                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        navigate("/Project/Detail", { state: { projectId: project.id } });
                                                    }}><PencilFill />
                                                    </Button>
                                                </div></div>

                                            <div style={{ flex: 1, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={disabledCustomerForm === true ? true : !deletedButton} variant="danger"
                                                        onClick={event => {
                                                            deleteProjectData(project.id);
                                                        }}
                                                    ><Trash /></Button>
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                            />
                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
            </Container>
            <Loading
                loading={loading}
            />
        </>
    )
}   