import { config } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import { json } from "react-router-dom";
import Config from "../Config/Config";
import qs from "qs";
import Resizer from "react-image-file-resizer";

export const isTokenValid = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/check-token`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (true);
        } else {
            return (false);
        }
    }
    catch (exception) {
        console.log(exception);
        return (false);
    }
}

export const getCountry = async (token, offset = 0, limit = 100000, orderBy = "country_name", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Country/All`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const createCountry = async (token, country, countryId) => {
    const payload = {
        country_name: country.country_name,
        country_code: country.country_code,
        country_flag_url: country.country_flag_url,
        id: countryId
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Country/create`, payload, {
            headers: {
                token: token
            },
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const uploadFile = async (token, country) => {
    const payload = {
        base64: country,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SMS/uploadFile`, payload, {
            headers: {
                token: token
            },
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffPosition = async (token, languageId, offset = 0, limit = 100000, orderBy = "position_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffPosition/All`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCompany = async (token, languageId, extCustomerId, offset = 0, limit = 100000, orderBy = "company_name", descending = true, searchQuery, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Company/all`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getCompanyCount = async (token, languageId, searchQuery, detailedSearch, customerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Company/count`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                customer_id: customerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getLanguage = async (token, offset = 0, limit = 100000, orderBy = "language.language_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/language/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getUserRole = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserRole/all`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSpecializaton = async (token, languageId, offset = 0, limit = 100000, orderBy = "specialization_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffSpecialization/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselType = async (token, languageId, offset = 0, limit = 100000, orderBy = "vessel_type_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselType/all`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getStaff = async (token, languageId, customerId, offset = 0, limit = 100000, orderBy = "staff.staff_name", descending = false, searchQuery, detailedSearch, isResign = 0, statusId = [{id:0, employee_status_name:"Semua"}], startJoinDate = "",endJoinDate = "") => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/all`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                is_resign: isResign,
                customer_id: customerId,
                status_id: statusId,
                start_join_date: startJoinDate,
                end_join_date: endJoinDate
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffCount = async (token, languageId, searchQuery, detailedSearch, isResign, customerId, statusId = [{id:0, employee_status_name:"Semua"}], startJoinDate = "", endJoinDate = "") => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/count`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                is_resign: isResign,
                customer_id: customerId,
                status_id: statusId,
                start_join_date: startJoinDate,
                end_join_date: endJoinDate
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getMechanicCount = async (token, languageId, searchQuery, detailedSearch, isResign = 0, customerId, statusId= [{id:0, employee_status_name:"Semua"}]) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getMechanicCount`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                is_resign: isResign,
                customer_id: customerId,
                status_id: statusId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getStaffByid = async (token, languageId, staffId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findById`, {
            params: {
                language_id: languageId,
                id: staffId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);

        }

        fileReader.onerror = (error) => {
            reject(error);
        }

    })
}

export const convertBase64Image = (file) => {
    return new Promise((resolve, reject) => {
        try {
            Resizer.imageFileResizer(
                file,
                1200,
                1200,
                "JPEG",
                60,
                0,
                uri => {
                    resolve(uri)
                },
                "base64"
            );
        } catch (exception) {
            console.log(exception);
            resolve("");
        }
    })
}



export const createStaff = async (token, staff, staffId, formData, isFieldStaff, allowLogin, listContractHistory) => {
    let payload = {
        id: staffId,
        staff_name: staff.staff_name,
        employee_number: staff.employee_number,
        date_of_birth: staff.date_of_birth,
        place_of_birth: staff.place_of_birth,
        address: staff.address,
        phone_number_1: staff.phone_number_1,
        phone_number_2: staff.phone_number_2,
        email: staff.email,
        gender: staff.gender,
        photo_base64: staff.photo_base64,
        position_id: staff.position_id,
        specialization: formData,
        is_field_staff: isFieldStaff,
        allow_login: allowLogin,
        division_id: staff.division_id,
        department_id: staff.department_id,
        staff_position_id: staff.staff_position_id,
        employee_status: staff.employee_status,
        join_date: staff.join_date,
        resign_date: staff.resign_date,
        resign_reason: staff.resign_reason,
        npwp_number: staff.npwp_number,
        npwp_address: staff.npwp_address,
        kpp: staff.kpp,
        grade: staff.grade,
        poin_of_hire: staff.poin_of_hire,
        workarea: staff.workarea,
        worklegth: staff.worklegth,
        bpjs_tk_number: staff.bpjs_tk_number,
        bpjs_ks_number: staff.bpjs_ks_number,
        bpjs_pensiun: staff.bpjs_pensiun,
        ptkp: staff.ptkp,
        ptkp_next_year: staff.ptkp_next_year,
        tax: staff.tax,
        marital_status_id: staff.marital_status_id,
        kk_number: staff.kk_number,
        ktp_number: staff.ktp_number,
        ktp_address: staff.ktp_address,
        mothers_name: staff.mothers_name,
        overtime: staff.overtime,
        bank_branch: staff.bank_branch,
        bank_city: staff.bank_city,
        bank_account_name: staff.bank_account_name,
        bank_account_number: staff.bank_account_number,
        note_1: staff.note_1,
        note_2: staff.note_2,
        clothes_size: staff.clothes_size,
        religion_id: staff.religion_id,
        bank_id: staff.bank_id,
        blood_type_id: staff.blood_type_id,
        rate: staff.rate ? staff.rate.toString().replace(/[^,\d]/g, '') : null,
        ext_customer_id: staff.ext_customer_id,
        is_crew: staff.is_crew,
        crew_type_id: staff.crew_type_id,
        sailor_registration_number: staff.sailor_registration_number,
        language_id: staff.language_id,
        pay_privately: staff.pay_privately,
        number_of_children: staff.number_of_children,
        contract_history: listContractHistory,
        business_unit_id: staff.business_unit_id,
        bpjs_government: staff.bpjs_government
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/create`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }



    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateCompany = async (token, company, companyId) => {
    const payload = {
        company_name: company.company_name,
        company_category_id: company.company_category_id,
        address: company.address,
        phone_number_1: company.phone_number1,
        phone_number_2: company.phone_number2,
        fax: company.fax,
        email: company.email,
        website: company.website,
        logo_image: company.logo_image,
        id: companyId,
        ext_customer_id: company.ext_customer_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/company/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getGender = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/gender/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getCompanyCategory = async (token, languageId, offset = 0, limit = 100000, orderBy = "company_category", descending = true, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CompanyCategory/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getCompanyCategoryCount = async (token, languageId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CompanyCategory/count`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getCompanyByid = async (token, languageId, companyId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Company/get`, {
            params: {
                language_id: languageId,
                id: companyId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountryById = async (token, countryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Country/get`, {
            params: {
                id: countryId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getUser = async (token, staffId, extCustomerId, offset = 0, limit = 100000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/all`, {
            headers: {
                token: token
            },
            params: {
                staff_id: staffId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}
export const getProject = async (token, language_id, extCustomerId, offset = 0, limit = 100000, orderBy = "project_number", descending, searchQuery, detailedSearch) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}


export const getProjectCount = async (token, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/count`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getProjectById = async (token, projectId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/findById`, {
            params: {
                id: projectId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteProject = async (token, id) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/deleteProject`, {
            params: {
                id: id
            },
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}



export const insertUpdateProject = async (token, project, isProjectChecked) => {
    const payload = {
        id: project.id,
        project_name: project.project_name,
        project_number: project.project_number,
        customer_id: project.customer_id,
        vessel_id: project.vessel_id,
        project_start_date: project.project_start_date,
        project_closing_date: project.project_closing_date,
        project_status_id: project.project_status_id,
        project_pic_staff_id: project.project_pic_staff_id,
        customer_pic_name: project.customer_pic_name,
        project_location: project.project_location,
        latitude: project.latitude,
        longitude: project.longitude,
        project_type_id: project.project_type_id,
        customer_pic_title: project.customer_pic_title,
        contract_id: project.contract_id,
        survey_id: project.survey_id,
        survey_project_name: project.survey_project_name,
        is_project: isProjectChecked,
        estimation_date: project.estimation_date,
        spmk_number: project.spmk_number,
        customer_pic_phone_number: project.customer_pic_phone_number,
        billing_status_id: project.billing_status_id,
        vessel_location: project.vessel_location,
        vessel_arrived_date: project.vessel_arrived_date,
        estimated_start_date: project.estimated_start_date,
        estimated_end_date: project.estimated_end_date,
        ext_customer_id: project.ext_customer_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Project/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}





export const getReturnInventory = async (token, offset = 0, limit = 100000, orderBy = "return_id", descending = true, searchQuery, detailedSearch = false, returnId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ReturnInventory/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                return_id: returnId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}


export const insertUpdateReturnInventory = async (token, returnInventory, returnId, divisionId) => {
    const payload = {
        id: returnInventory.id,
        return_id: returnId,
        inventory_stock_id: returnInventory.inventory_stock_id,
        inventory_condition_id: returnInventory.inventory_condition_id,
        return_inventory_description: returnInventory.return_inventory_description,
        division_id: divisionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ReturnInventory/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getReturnInventoryCount = async (token, returnId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ReturnInventory/count`, {
            headers: {
                token: token
            },
            params: {
                return_id: returnId

            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getReturnInventoryById = async (token, returnId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ReturnInventory/findById`, {
            params: {
                id: returnId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteReturnInventory = async (token, id, inventoryStockId, staffId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ReturnInventory/deleteReturn`, {
            params: {
                id: id,
                inventory_stock_id: inventoryStockId,
                staff_id: staffId
            },
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getDivision = async (token, languageId, extCustomerId, offset = 0, limit = 100000, orderBy = "division_name", descending = false, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Division/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}


export const getDivisionById = async (token, divisionId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Division/findById`, {
            params: {
                id: divisionId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const insertUpdateDivision = async (token, division, languageId, divisionId, listDepartment) => {
    const payload = {
        id: division.id,
        division_name: division.division_name,
        division_code: division.division_code,
        division_description: division.division_description,
        language_id: languageId,
        ext_customer_id: division.ext_customer_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Division/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteDivision = async (token, divisionId) => {
    const payload = {
        id: divisionId,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Division/deleteDivision`, payload, {
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getCategory = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryCategory/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getCurrency = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Currency/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getContractTask = async (token, languageId, offset = 0, limit = 100000, orderBy = "contractTask.contract_id", descending, searchQuery, detailedSearch, contractId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ContractTask/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                contract_id: contractId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);

    }

};

export const getContract = async (token, language_id, extCustomerId, offset = 0, limit = 100000, orderBy = "contract.contract_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}

export const getContractByProject = async (token, language_id, projectId, offset = 0, limit = 100000, orderBy = "contract.contract_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/contractByProject`, {
            headers: {
                token: token
            },
            params: {
                project_id: projectId,
                language_id: language_id,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}

export const getContractCount = async (token, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/count`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getContractCountByProject = async (token, projectId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/countByProject`, {
            headers: {
                token: token
            },
            params: {
                project_id: projectId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

// export const getContractTaskDetail = async (token, language_id) => {
//     try {
//         let response = await axios.get(`${Config.API_ENDPOINT}/api/ContractTaskDetail/all`, {
//             headers: {
//                 token: token
//             },
//             params: {
//                 language_id: language_id
//             }
//         });
//         if (response.data.error_code === 0) {
//             return (response.data.data);
//         } else {
//             return ([])
//         }
//     } catch (exception) {
//         return ([]);

//     }

// };



export const getUserById = async (token, userId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/findById`, {
            params: {
                id: userId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteUserAccount = async (token, id) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/delete-account`, {
            params: {
                id: id
            },
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateUser = async (token, user) => {
    const payload = {
        login_username: user.login_username,
        login_password: user.login_password,
        email: user.email,
        role_id: user.role_id,
        id: user.id,
        language_id: user.language_id,
        staff_id: user.staff_id,
        user_photo: user.user_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/UserAccount/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const updatePassword = async (token, id, login_password) => {
    const payload = {
        id: id,
        login_password: login_password
    }
    console.log(payload);
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/UserAccount/update-password`, payload, {
            headers: {
                token: token,
            }
        });
        if (response) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getVessel = async (token, languageId, extCustomerId, offset = 0, limit = 100000, orderBy = "vessel_name", descending = "false", searchQuery, detailedSearch, company_id = undefined, detailedSearchValue=[]) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                company_id: company_id,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId,
                detailed_searched_value: detailedSearchValue
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getVesselByCompany = async (token, languageId, companyId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findByCompany`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                company_id: companyId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}


export const getAllVesselByCompany = async (token, languageId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getAllVesselByCompany`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getVesselDocumentCount = async (token, vesselId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getVesselDocumentCount`, {
            headers: {
                token: token
            },
            params: {
                vessel_id: vesselId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0)
        }
    } catch (exception) {
        return (0);
    }

}

export const getVesselDocument = async (token, vesselId, offset = 0, limit = 100000, orderBy, descending, searchQuery) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getVesselDocument`, {
            headers: {
                token: token
            },
            params: {
                vessel_id: vesselId,
                offset: offset,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery
            }
        });
        if (response.data.error_code === 0) {
            console.log(response);
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const insertUpdateVesselDocument = async (token, document) => {
    try {
        let payload = {
            id: document.id,
            vessel_id: document.vessel_id,
            base64_document: document.base64_document,
            reference_number: document.reference_number,
            document_name: document.document_name,
            document_desc: document.document_desc
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/insertUpdateVesselDocument`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}


export const deleteVesselDocument = async (token, documentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselDocument`, { id: documentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getVesselCount = async (token, languageId, extCustomerId, searchQuery, detailedSearch, detailedSearchValue=[]) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/count`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId,
                detailed_searched_value: detailedSearchValue
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0)
        }
    } catch (exception) {
        return (0);
    }

}

export const getVesselById = async (token, vesselId, languageId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findById`, {
                params: {
                    id: vesselId,
                    language_id: languageId
                },
                headers: {
                    token: token
                }
            });
            if (response.data.error_code === 0) {
                resolve(response.data.data[0]);
            } else {
                resolve([]);
            }
        }
        catch (exception) {
            console.log(exception);
            resolve([]);
        }
    })

}

export const insertUpdateVessel = async (token, vessel, vesselId) => {
    const payload = {
        vessel_name: vessel.vessel_name,
        customer_id: vessel.customer_id,
        vessel_type_id: vessel.vessel_type_id,
        imo_number: vessel.imo_number,
        serial_number: vessel.serial_number,
        built_date: vessel.built_date,
        built_shipyard: vessel.built_shipyard,
        vessel_code: vessel.vessel_code,
        gross_register_tonnage: vessel.gross_register_tonnage,
        deadweight_tonnage: vessel.deadweight_tonnage,
        lbp: vessel.lbp,
        bmld: vessel.bmld,
        hmld: vessel.hmld,
        callsign: vessel.callsign,
        flag: vessel.flag,
        class_register_number: vessel.class_register_number,
        id: vesselId,
        character: vessel.character,
        notation: vessel.notation,
        ext_customer_id: vessel.ext_customer_id,
        shift_session_id: vessel.shift_session_id,
        hull_number: vessel.hull_number
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/vessel/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getLanguagebyId = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Language/findById`, {
            params: {
                id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateLanguage = async (token, language, languageId) => {
    const payload = {
        country_id: language.country,
        language_name: language.language_name,
        language_iso_code: language.language_iso_code,
        id: languageId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/language/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffSpecializationById = async (token, specializationId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffSpecialization/findById`, {
            params: {
                id: specializationId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffPositionById = async (token, specializationId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffPosition/findById`, {
            params: {
                id: specializationId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateStaffPosition = async (token, staffPosition, staffPositionId, languageId) => {
    const payload = {
        position_name: staffPosition.positionName,
        position_desc: staffPosition.positionDesc,
        language_id: languageId,
        id: staffPositionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/StaffPosition/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateStaffSpecialization = async (token, staffSpecialization, languageId) => {
    const payload = {
        specialization_name: staffSpecialization.specializationName,
        specialization_desc: staffSpecialization.specializationDesc,
        language_id: languageId,
        id: staffSpecialization.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/StaffSpecialization/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateVesselType = async (token, vesselType, vesselTypeId, languageId) => {
    const payload = {
        language_id: languageId,
        vessel_type_name: vesselType.vesselTypeName,
        vessel_type_desc: vesselType.vesselTypeDesc,
        vessel_type_code: vesselType.vesselTypeCode,
        id: vesselTypeId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselType/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselTypeById = async (token, vesselTypeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselType/findById`, {
            params: {
                id: vesselTypeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipmentById = async (token, equipmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EquipmentCategory/findById`, {
            params: {
                id: equipmentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateEquipment = async (token, equipment, equipmentId, languageId, parameter) => {
    const payload = {
        equipment_name: equipment.equipment_name,
        equipment_description: equipment.equipment_description,
        id: equipment.id,
        language_id: languageId,
        parent_id: equipment.parent_id,
        parameter: parameter,
        equipment_code: equipment.equipment_code
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/EquipmentCategory/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipment = async (token, offset = 0, limit = 100000, orderBy = "equipment_name", descending, searchQuery, detailedSearch) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EquipmentCategory/All`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipmentCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EquipmentCategory/count`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getVesselPhoto = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselPhoto/All`, {
            headers: {
                token: token
            }
            // params:{
            //     page:0,
            //     pageSize:1
            // }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselPhotoById = async (token, vesselId, offset = 0, limit = 100000, orderBy = "Title", descending = true,) => {
    if (orderBy === 'Title') {
        orderBy = 'name';
    } else if (orderBy === 'Taken Date') {
        orderBy = 'taken_date';
    }
    if (orderBy[0] === 'Title') {
        orderBy = 'name';
    }
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselPhoto/findById`, {
            params: {
                id: vesselId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselPhotoByCount = async (token, vesselPhotoId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselPhoto/count`, {
            headers: {
                token: token
            },
            params: {
                id: vesselPhotoId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateVesselPhoto = async (token, vesselPhoto, vesselPhotoId) => {
    const payload = {
        vessel_id: vesselPhotoId,
        base64_img: vesselPhoto.base64Img,
        description: vesselPhoto.description,
        id: 0,
        taken_date: vesselPhoto.takenDate,
        name: vesselPhoto.name
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselPhoto/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffxSpecializationById = async (token, StaffxSpecializationId, staffSpecializationId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffxSpecialization/findById`, {
            headers: {
                token: token
            },
            params: {
                id: StaffxSpecializationId,
                staff_specialization_id: staffSpecializationId
            }
        })
        if (response.data.error_code === 0) {
            return (response.data.data)
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
    }
}

export const deleteStaff = async (token, staffId) => {
    const payload = {
        id: staffId
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/delete`, payload, {
            headers: {
                token: token
            }
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
    }
}

export const deleteCompany = async (token, companyId) => {
    const payload = {
        id: companyId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Company/delete`, payload, {
            headers: {
                token: token,
            },
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteVessel = async (token, vesselId) => {
    const payload = {
        id: vesselId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/delete`, payload, {
            headers: {
                token: token,
            },
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteVesselPhoto = async (token, vesselPhotoId) => {
    const payload = {
        id: vesselPhotoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselPhoto/delete`, payload, {
            headers: {
                token: token,
            },
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountryCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Country/count`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getVesselxEquipment = async (token, offset = 0, limit = 100000, orderBy = "vessel_id", descending = true, searchQuery, detailedSearch) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/all`, {
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getVesselxEquipmentById = async (token, vesselEquipmentId, vesselId, equipmentId, offset = 0, limit = 100000, orderBy = "vessel_id", descending, searchQuery, detailedSearch, languageId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/findById`, {
            params: {
                id: vesselEquipmentId,
                vessel_id: vesselId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                equipment_id: equipmentId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getParameter = async (token, vesselEquipmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/getParameter`, {
            params: {
                id: vesselEquipmentId,
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipmentCountByType = async (token, languageId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/countEquipmentByType`, {
            params: {
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }
    }
    catch (exception) {
        return ([]);
    }
}

export const getDocument = async (token, vesselEquipmentId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/getDocument`, {
            params: {
                id: vesselEquipmentId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getVesselxEquipmentCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/count`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateVesselxEquipment = async (token, vesselEquipment, languageId) => {
    const payload = {
        vessel_id: vesselEquipment.vessel_id,
        equipment_id: vesselEquipment.equipment_id,
        id: vesselEquipment.id,
        parameter: vesselEquipment.parameters,
        title: vesselEquipment.title,
        description: vesselEquipment.description,
        language_id: languageId,
        model: vesselEquipment.model,
        parent_id: vesselEquipment.parent_id,
        part_number: vesselEquipment.part_number,
        manufacture_name: vesselEquipment.manufacture_name,
        sparepart_catalog_id: vesselEquipment.sparepart_catalog_id,
        serial_number: vesselEquipment.serial_number,
        number_of_cylinder: vesselEquipment.number_of_cylinder,
        equipment_position_id: vesselEquipment.equipment_position_id,
        is_megger: vesselEquipment.is_megger
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselEquipment/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteVesselxEquipment = async (token, vesselEquipmentId) => {
    const payload = {
        id: vesselEquipmentId
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselEquipment/delete`, payload, {
            headers: {
                token: token
            }
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
    }
}

export const getVesselxEquipmentPhotoById = async (token, vesselEquipmentPhotoId, offset = 0, limit = 100000, orderBy = "taken_date", descending = true) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/findById`, {
            params: {
                id: vesselEquipmentPhotoId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateVesselxEquipmentPhoto = async (token, vesselEquipmentPhoto, vesselEquipmentId) => {
    const payload = {
        vessel_x_equipment_id: vesselEquipmentPhoto.vessel_x_equipment_id,
        vessel_id: vesselEquipmentPhoto.vessel_id,
        equipment_id: vesselEquipmentPhoto.equipment_id,
        base64_img: vesselEquipmentPhoto.base64_img,
        title: vesselEquipmentPhoto.title,
        description: vesselEquipmentPhoto.description,
        taken_date: vesselEquipmentPhoto.taken_date,
        id: 0
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselXEquipmentPhotoCount = async (token, vesselXEquipemntPhotoId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/count`, {
            headers: {
                token: token
            },
            params: {
                id: vesselXEquipemntPhotoId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getCompanyCategoryById = async (token, companyCategoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CompanyCategory/findById`, {
            params: {
                id: companyCategoryId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateCompanyCategory = async (token, companyCategory,) => {
    if (companyCategory.id === undefined) {
        companyCategory.id = 0
    }
    const payload = {
        company_category: companyCategory.company_category,
        description: companyCategory.description,
        language_id: companyCategory.language_id,
        id: companyCategory.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/CompanyCategory/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const checkUsername = async (token, login_username) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/checkUsername`, {
            params: {
                login_username: login_username
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const checkIsCover = async (token, vesselEquipmentPhotoId, vesselEquipmentId) => {
    const payload = {
        id: vesselEquipmentPhotoId,
        vessel_x_equipment_id: vesselEquipmentId
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/checkIsCover`, payload, {
            headers: {
                token: token
            }
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
    }
}

export const checkPhotoIsCover = async (token, vesselId, vesselPhotoId) => {
    const payload = {
        id: vesselPhotoId,
        vesselId: vesselId
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselPhoto/checkIsCover`, payload, {
            headers: {
                token: token
            }
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
    }
}

export const deleteVesselEquipmentPhoto = async (token, id) => {
    const payload = {
        id: id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountDetailEquipment = async (token, vesselId, vesselEquipmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/countDetailEquipment`, {
            headers: {
                token: token
            },
            params: {
                vessel_id: vesselId,
                id: vesselEquipmentId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const getParameterEquipment = async (token, equipmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EquipmentCategory/getParameter`, {
            params: {
                id: equipmentId,
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getLanguageCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Language/count`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getStaffPositionCount = async (token, languageId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffPosition/count`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getSpecializationCount = async (token, languageId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffSpecialization/count`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getVesselTypeCount = async (token, languageId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselType/count`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0].count);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getProjectxProjectType = async (token, projectId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/getProjectXProjectType`, {
            headers: {
                token: token
            },
            params: {
                projectId: projectId,
            }
        })
        if (response.data.error_code === 0) {
            return (response.data.data)
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
    }
}

export const getContractById = async (token, contractId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/findById`, {
            params: {
                id: contractId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateContract = async (token, contract) => {
    const payload = {
        id: contract.id,
        customer_id: contract.customer_id,
        contract_name: contract.contract_name,
        contract_date: contract.contract_date,
        contract_external_number: contract.contract_external_number,
        description: contract.description,
        start_date: contract.start_date,
        end_date: contract.end_date,
        contract_reference_number: contract.contract_reference_number,
        status_id: contract.status_id,
        ext_customer_id: contract.ext_customer_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Contract/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getContractTaskById = async (token, contractTaskId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ContractTask/findById`, {
            params: {
                id: contractTaskId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateContractTask = async (token, contractTask, contractTaskId) => {
    const payload = {
        id: contractTask.id,
        contract_id: contractTask.contract_id,
        vessel_type_id: contractTask.vessel_type_id,
        equipment_id: contractTask.equipment_id,
        task_detail: contractTask.detail_job,
        quality: contractTask.quality,
        title: contractTask.title,
        order_number: contractTask.order_number
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ContractTask/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getContractTaskCount = async (token, contractId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ContractTask/count`, {
            headers: {
                token: token
            },
            params: {
                contract_id: contractId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getContractTaskDetail = async (token, contractTaskId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ContractTask/ContractTaskDetail`, {
            params: {
                id: contractTaskId,
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateFullContract = async (token, contract, contractTaskDetail) => {
    const payload = {
        id: contract.id,
        project_id: contract.project_id,
        contract_name: contract.contract_name,
        contract_date: contract.contract_date,
        contract_external_number: contract.contract_external_number,
        description: contract.description,
        vessel_type_id: contract.vessel_type_id,
        equipment_id: contract.equipment_id,
        task_detail: contractTaskDetail,
        contractTaskId: contract.contractTaskId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Contract/insertUpdateFullContract`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getFullContractById = async (token, contractId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/fullContractFindById`, {
            params: {
                id: contractId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectTaskParameter = async (token, contractId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectTask/getProjectTask`, {
            params: {
                contract_id: contractId,
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateProjectTask = async (token, projectTask, projectTaskName) => {
    const payload = {
        id: projectTask.id,
        project_id: projectTask.project_id,
        projectTask: projectTaskName
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ProjectTask/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectTaskById = async (token, projectId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectTask/findById`, {
            params: {
                project_id: projectId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectTaskParameterByProjectId = async (token, projectId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectTask/getProjectTaskByProjectId`, {
            params: {
                project_id: projectId,
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectTask = async (token, language_id, offset = 0, limit = 100000, orderBy = "project_number", descending, searchQuery, detailedSearch) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectTask/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}


export const getVesselProject = async (token, languageId, customerId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/findVessel`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                customer_id: customerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getProjectContract = async (token, language_id) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/getContract`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getPreventiveMaintenance = async (token, offset = 0, limit = 100000, orderBy = "periode_maintenance", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/PreventiveMaintenance/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getPreventiveMaintenanceById = async (token, maintenanceId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/PreventiveMaintenance/findById`, {
            params: {
                id: maintenanceId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPreventiveMaintenanceCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/PreventiveMaintenance/count`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdatePreventiveMaintenance = async (token, maintenance) => {
    const payload = {
        id: maintenance.id,
        maintenance_name: maintenance.maintenance_name,
        vessel_equipment_id: maintenance.vessel_equipment_id,
        periode_maintenance: maintenance.periode_maintenance,
        last_maintenance: maintenance.last_maintenance,
        service_job_id: maintenance.service_job_id,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/PreventiveMaintenance/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartCatalog = async (token, extCustomerId, offset = 0, limit = 100000, orderBy = "sparepartCatalog.model", descending, searchQuery, detailedSearch, totalStock = 0, searchByParameter, parameterName, parameterValue) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                total_stock: totalStock,
                search_parameter: searchByParameter,
                parameter_name: parameterName,
                parameter_value: parameterValue,
                ext_customer_id: extCustomerId

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSparepartCatalogById = async (token, sparepartCatalogId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/findById`, {
            params: {
                id: sparepartCatalogId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartCatalogCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/count`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateSparepartCatalog = async (token, sparepartCatalog) => {
    const payload = {
        id: sparepartCatalog.id,
        equipment_id: sparepartCatalog.equipment_id,
        title: sparepartCatalog.title,
        description: sparepartCatalog.description,
        model: sparepartCatalog.model,
        manufacture_name: sparepartCatalog.manufacture_name,
        parameter: sparepartCatalog.parameters,
        equipment_position_id: sparepartCatalog.equipment_position_id,
        parent_id: sparepartCatalog.parent_id,
        part_number: sparepartCatalog.part_number,
        supplier_id: sparepartCatalog.supplier_id,
        ext_customer_id: sparepartCatalog.ext_customer_id

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartCatalog/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSparepartCatalog = async (token, sparepartCatalogId) => {
    const payload = {
        id: sparepartCatalogId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartCatalog/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartRequisition = async (token, extCustomerId, offset = 0, limit = 100000, orderBy = "sparepartRequisition.created_date", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSparepartRequisitionById = async (token, SparepartRequisitionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/findById`, {
            params: {
                id: SparepartRequisitionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartRequisitionCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/count`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateSparepartRequisition = async (token, sparepartRequisition) => {
    const payload = {
        id: sparepartRequisition.id,
        project_id: sparepartRequisition.project_id,
        sparepart_catalog_id: sparepartRequisition.sparepart_catalog_id,
        requesting_staff_id: sparepartRequisition.requesting_staff_id,
        request_date: sparepartRequisition.request_date,
        status_id: sparepartRequisition.status_id,
        approved_date: sparepartRequisition.approved_date,
        arrived_date: sparepartRequisition.arrived_date,
        quantity: sparepartRequisition.quantity,
        price: sparepartRequisition.price,
        note: sparepartRequisition.note,
        list_requisition: sparepartRequisition.list_requisition,
        is_project: sparepartRequisition.is_project,
        approved_by: sparepartRequisition.approved_by,
        ext_customer_id: sparepartRequisition.ext_customer_id
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartRequisition/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const deleteSparepartRequisition = async (token, sparepartRequisitionId) => {
    const payload = {
        id: sparepartRequisitionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartRequisition/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getRequisitionStatus = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/status`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getProjectSurvey = async (token, language_id, offset = 0, limit = 100000, orderBy = "project_number", descending, searchQuery, detailedSearch, vesselName, customerName) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/listProjectSurvey`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_name: vesselName,
                customer_name: customerName
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getServiceJob = async (token, extCustomerId, offset = 0, limit = 100000, orderBy = "project.project_name", descending, searchQuery, detailedSearch, contractId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);

    }

};


export const getServiceJobById = async (token, serviceJobId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findById`, {
            params: {
                id: serviceJobId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateServiceJob = async (token, serviceJob, listDocumentServiceJob) => {
    const payload = {
        id: serviceJob.id,
        project_id: serviceJob.project_id,
        service_date: serviceJob.service_date,
        staff_service_pic: serviceJob.staff_service_pic,
        customer_service_pic: serviceJob.customer_service_pic,
        service_reason: serviceJob.service_reason,
        list_crew: serviceJob.list_crew,
        deleted_crew: serviceJob.deleted_crew,
        detail_job: serviceJob.detail_job,
        group_mechanic_id: serviceJob.group_mechanic_id,
        status_id: serviceJob.status_id,
        deleted_detail: serviceJob.deleted_detail,
        deleted_job: serviceJob.deleted_job,
        ext_customer_id: serviceJob.ext_customer_id,
        listDocument: listDocumentServiceJob,
        deleted_document: serviceJob.deleted_document
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteServiceJob = async (token, serviceJobId) => {
    const payload = {
        id: serviceJobId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getServiceJobCount = async (token, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/count`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getServiceJobDetail = async (token, serviceJobId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findServiceJobDetail`, {
            params: {
                id: serviceJobId,
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getContractTaskDetailByVesselType = async (token, projectId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ContractTask/ContractTaskDetailByVesselType`, {
            params: {
                project_id: projectId,
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartParameter = async (token, sparepartCatalogId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/parameter`, {
            params: {
                id: sparepartCatalogId,
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getListReturnInventory = async (token, offset = 0, limit = 100000, orderBy = "Inventory Master", descending = true, inventoryMasterId, staffId, handedOver) => {
    if (orderBy === 'Inventory Master') {
        orderBy = 'inventory_master_name';
    }
    else if (orderBy === 'Inventory Code') {
        orderBy = 'inventory_code'
    }
    else if (orderBy === 'Purchase Date') {
        orderBy = 'purchase_date'
    }
    else if (orderBy === 'Received Date') {
        orderBy = 'received_date'
    }
    else if (orderBy === 'Period Name') {
        orderBy = 'inventory_period_name'
    }
    else if (orderBy === 'Remove Date') {
        orderBy = 'remove_date'
    }
    else if (orderBy === 'Depreciation Rate') {
        orderBy = 'depreciation_rate'
    }
    else if (orderBy === 'Person in Charge') {
        orderBy = 'staff_name'
    }
    else if (orderBy === 'Status') {
        orderBy = 'inventory_status_name'
    }
    else if (orderBy === 'Period') {
        orderBy = 'inventory_period_name'
    }
    else if (orderBy === 'Condition') {
        orderBy = 'inventory_condition_name'
    }


    if (orderBy[0] === 'Inventory Code') {
        orderBy = 'inventory_code';
    }

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryDetail/listReturnInventory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                inventory_master_id: inventoryMasterId,
                staff_id: staffId,
                handed_over: handedOver
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}


export const insertUpdateVesselEquipmentDocument = async (token, document) => {
    try {
        const payload = {
            id: document.id,
            document_base64: document.document_base64,
            document_name: document.document_name,
            description: document.description,
            vessel_x_equipment_id: document.vessel_x_equipment_id,
            reference_number: document.reference_number
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselEquipment/insertUpdateVesselEquipmentDocument`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const insertUpdateSparepartCatalogDocument = async (token, document) => {
    try {
        const payload = {
            id: document.id,
            document_base64: document.document_base64,
            document_name: document.document_name,
            description: document.description,
            sparepart_catalog_id: document.sparepart_catalog_id,
            reference_number: document.reference_number
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartCatalog/insertUpdateSparepartCatalogDocument`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getSparepartCatalogDocument = async (token, sparepartCatalogId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getSparepartCatalogDocument`, {
            params: {
                id: sparepartCatalogId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteVesselEquipmentDocument = async (token, documentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselEquipment/deleteVesselEquipmentDocument`, { id: documentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const deleteSparepartCatalogDocument = async (token, documentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartCatalog/deleteSparepartCatalogDocument`, { id: documentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getEquipmentPosition = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/getEquipmentPosition`, {
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMechanicGroup = async (token, extCustomerId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllMechanicGroup`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMechanicGroupById = async (token, mechanicGroupId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findByIdMechanicGroup`, {
            params: {
                id: mechanicGroupId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateMechanicGroup = async (token, mechanicGroup) => {
    const payload = {
        group_name: mechanicGroup.group_name,
        id: mechanicGroup.id,
        leader_id: mechanicGroup.leader_id,
        ext_customer_id: mechanicGroup.ext_customer_id
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateMechanicGroup`, payload, {
            headers: {
                token: token
            },
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMechanicGroupCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/staff/getCountMechanicGroup`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteMechanicGroup = async (token, mechanicGroupId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteMechanicGroup`, { id: mechanicGroupId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getStaffMechanicGroup = async (token, mechanicGroupId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/selectStaffMechanicGroup`, {
            params: {
                id: mechanicGroupId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteContract = async (token, contractId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Contract/deleteContract`, { id: contractId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const deleteContractTask = async (token, contractTaskId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ContractTask/deleteContractTask`, { id: contractTaskId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getEquipmentCategoryStruct = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EquipmentCategory/getAllEquipmentCategoryStruct`, {
            params: {
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    } catch (exception) {
        return (false);
    }
}

export const getVesselEquipmentByCategory = async (token, vesselId, categoryId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/getVesselEquipmentByCategory`, {
            params: {
                language_id: languageId,
                vessel_id: vesselId,
                category_id: categoryId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMechanic = async (token, languageId, customerId, offset = 0, limit = 100000, orderBy = "employee_number", descending = true, searchQuery, detailedSearch, isResign = 0, statusId = [{id:0, employee_status_name:"Semua"}]) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllMechanic`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                is_resign: isResign,
                customer_id: customerId,
                status_id: statusId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    } catch (exception) {
        return (false);
    }

}

export const deleteEquipmentCategory = async (token, equipmentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/EquipmentCategory/deleteEquipmentCategory`, { id: equipmentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getSparepartByCategory = async (token, categoryId, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getSparepartByCategory`, {
            params: {
                category_id: categoryId,
                ext_customer_id: extCustomerId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    } catch (exception) {
        return (false);
    }
}

export const getAllSparepartCatalog = async (token, languageId, equipmentId, parameters, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/findAllSparepart`, {
            headers: {
                token: token
            },
            params: {
                equipment_id: equipmentId,
                parameter: parameters,
                ext_customer_id: extCustomerId
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return (false);
    }
}

export const getAllVesselEquipment = async (token, languageId, equipmentId, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/findAllVesselEquipment`, {
            headers: {
                token: token
            },
            params: {
                equipment_id: equipmentId,
                vessel_id: vesselId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return (false);
    }
}

export const insertUpdateContractDocument = async (token, document) => {
    try {
        const payload = {
            id: document.id,
            document_base64: document.document_base64,
            document_name: document.document_name,
            description: document.description,
            contract_id: document.contract_id,
            reference_number: document.reference_number
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Contract/insertUpdateContractDocument`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getContractDocument = async (token, sparepartCatalogId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/getContractDocument`, {
            params: {
                id: sparepartCatalogId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteContractDocument = async (token, documentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Contract/deleteContractDocument`, { id: documentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getReligion = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllReligion`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPosition = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllPosition`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMaritalStatus = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllMaritalStatus`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEmployeeStatus = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllEmployeeStatus`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getBloodType = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllBloodType`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getBank = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Bank/findAllBank`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffDocument = async (token, staffId, offset = 0, limit = 100000, orderBy = "document.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getStaffDocument`, {
            params: {
                id: staffId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateSparepartCatalogStock = async (token, stock) => {
    const payload = {
        id: stock.id,
        sparepart_catalog_id: stock.sparepart_catalog_id,
        sparepart_catalog_code: stock.sparepart_catalog_code,
        purchase_date: stock.purchase_date,
        received_date: stock.received_date,
        periode_id: stock.periode_id,
        periode_number: stock.periode_number,
        purchase_price: stock.purchase_price,
        currency_id: stock.currency_id,
        currency_value_idr: stock.currency_value_idr,
        status_id: stock.status_id,
        removed_date: stock.removed_date,
        condition_id: stock.condition_id,
        depreciation_rate: stock.depreciation_rate,
        description: stock.description,
        note: stock.note,
        last_checked_date: stock.last_checked_date,
        barcode_number: stock.barcode_number,
        language_id: stock.language_id,
        condition_status_id: stock.condition_status,
        supplier_id: stock.supplier_id,
        depreciation_group_id: stock.depreciation_group_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartCatalog/insertUpdateStock`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartCatalogStock = async (token, languageId, sparepartCatalogId, offset = 0, limit = 100000, orderBy = "created_date", descending = true) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/findAllStock`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                sparepart_catalog_id: sparepartCatalogId,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSparepartStockCount = async (token, sparepartCatalogId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getSparepatStockCount`, {
            headers: {
                token: token
            },
            params: {
                sparepart_catalog_id: sparepartCatalogId,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getSparepartCatalogStockById = async (token, sparepartCatalogId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/findStockById`, {
            params: {
                id: sparepartCatalogId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSparepartStock = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartCatalog/deleteStock`,
            {
                id: id
            },
            {
                headers: {
                    token: token
                }
            });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const insertUpdateStaffDocument = async (token, document) => {
    try {
        const payload = {
            id: document.id,
            document_base64: document.document_base64,
            document_name: document.document_name,
            description: document.description,
            staff_id: document.staff_id,
            reference_number: document.reference_number,
            effective_date: document.effective_date,
            expired_date: document.expired_date,
            document_category_id: document.document_category_id,
            is_vaccine_covid: document.is_vaccine_covid,
            vaccine_covid_description: document.vaccine_covid_description,
            note: document.note,
            technician_document_category_id: document.technician_document_category_id
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateStaffDocument`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const deleteStaffDocument = async (token, documentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteStaffDocument`, { id: documentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getStaffInventory = async (token, staffId, offset = 0, limit = 100000, orderBy = "handoverInventory.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getStaffInventory`, {
            params: {
                staff_id: staffId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCheckCondition = async (token, offset = 0, limit = 100000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getCheckConditionStatus`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getWarehouse = async (token, extCustomerId, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/findAllWarehouse`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getWarehouseById = async (token, warehouseId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/findWarehouseById`, {
            params: {
                id: warehouseId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getWarehouseCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/getWarehouseCount`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateWarehouse = async (token, warehouse) => {
    const payload = {
        id: warehouse.id,
        warehouse_name: warehouse.warehouse_name,
        warehouse_code: warehouse.warehouse_code,
        warehouse_address: warehouse.warehouse_address,
        ext_customer_id: warehouse.ext_customer_id

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryPosition/insertUpdateWarehouse`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteWarehouse = async (token, warehouseId) => {
    const payload = {
        id: warehouseId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryPosition/deleteWarehouse`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getRack = async (token, extCustomerId, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/findAllRack`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getRackById = async (token, rackId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/findRackById`, {
            params: {
                id: rackId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getRackCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/getRackCount`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateRack = async (token, rack) => {
    const payload = {
        id: rack.id,
        rack_name: rack.rack_name,
        rack_code: rack.rack_code,
        ext_customer_id: rack.ext_customer_id

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryPosition/insertUpdateRack`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteRack = async (token, rackId) => {
    const payload = {
        id: rackId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryPosition/deleteRack`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getRackCoordinate = async (token, extCustomerId, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/findAllRackCoordinate`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getRackCoordinateById = async (token, rackCoodinateId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/findRackCoordinateById`, {
            params: {
                id: rackCoodinateId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getRackCoordinateCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPosition/getRackCoordinateCount`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateRackCoordinate = async (token, rackCoordinate) => {
    const payload = {
        id: rackCoordinate.id,
        warehouse_id: rackCoordinate.warehouse_id,
        rack_id: rackCoordinate.rack_id,
        coordinate_name: rackCoordinate.coordinate_name,
        ext_customer_id: rackCoordinate.ext_customer_id

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryPosition/insertUpdateRackCoordinate`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteRackCoordinate = async (token, rackCoordinateId) => {
    const payload = {
        id: rackCoordinateId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryPosition/deleteRackCoordinate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getManufacturer = async (token, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/findAllManufacturer`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getManufacturerById = async (token, manufacturerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/findManufacturerById`, {
            params: {
                id: manufacturerId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getManfacturerCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getManufacturerCount`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateManufacturer = async (token, manufacturer) => {
    const payload = {
        id: manufacturer.id,
        manufacturer_name: manufacturer.manufacturer_name,
        description: manufacturer.description,

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartCatalog/insertUpdateManufacturer`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteManufacturer = async (token, manufacturerId) => {
    const payload = {
        id: manufacturerId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartCatalog/deleteManufacturer`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSupplier = async (token, extCustomerId, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findAllSupplier`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSupplierById = async (token, supplierId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findSupplierById`, {
            params: {
                id: supplierId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSupplierCount = async (token, customerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/getSupplierCount`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: customerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateSupplier = async (token, supplier) => {
    const payload = {
        id: supplier.id,
        supplier_code: supplier.supplier_code,
        supplier_name: supplier.supplier_name,
        supplier_type_id: supplier.supplier_type_id,
        supplier_class_id: supplier.supplier_class_id,
        city: supplier.city,
        bank_id: supplier.bank_id,
        address_1: supplier.address_1,
        address_2: supplier.address_2,
        zip_code: supplier.zip_code,
        bank_account_number: supplier.bank_account_number,
        npwp: supplier.npwp,
        fg_ppn: supplier.fg_ppn,
        nppkp: supplier.nppkp,
        company_id: supplier.company_id,
        term_of_payment: supplier.term_of_payment,
        ext_customer_id: supplier.ext_customer_id

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/insertUpdateSupplier`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSupplier = async (token, supplierId) => {
    const payload = {
        id: supplierId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/deleteSupplier`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSupplierClass = async (token, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findAllSupplierClass`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSupplierClassById = async (token, supplierClassId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findSupplierClassById`, {
            params: {
                id: supplierClassId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSupplierClassCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/getSupplierClassCount`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateSupplierClass = async (token, supplierClass) => {
    const payload = {
        id: supplierClass.id,
        supplier_class_code: supplierClass.supplier_class_code,
        supplier_class_name: supplierClass.supplier_class_name,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/insertUpdateSupplierClass`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSupplierClass = async (token, supplierClassId) => {
    const payload = {
        id: supplierClassId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/deleteSupplierClass`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSupplierType = async (token, extCustomerId, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findAllSupplierType`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSupplierTypeById = async (token, supplierTypeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findSupplierTypeById`, {
            params: {
                id: supplierTypeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSupplierTypeCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/getSupplierTypeCount`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateSupplierType = async (token, supplierType) => {
    const payload = {
        id: supplierType.id,
        supplier_type_code: supplierType.supplier_type_code,
        supplier_type_name: supplierType.supplier_type_name,
        ext_customer_id: supplierType.ext_customer_id
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/insertUpdateSupplierType`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSupplierType = async (token, supplierTypeId) => {
    const payload = {
        id: supplierTypeId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/deleteSupplierType`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSupplierContact = async (token, supplierId, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findAllSupplierContact`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                supplier_id: supplierId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSupplierContactById = async (token, supplierContactId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findSupplierContactById`, {
            params: {
                id: supplierContactId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSupplierContactCount = async (token, supplierId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/getSupplierContactCount`, {
            headers: {
                token: token
            },
            params: {
                supplier_id: supplierId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateSupplierContact = async (token, supplierContact) => {
    const payload = {
        id: supplierContact.id,
        supplier_id: supplierContact.supplier_id,
        phone: supplierContact.phone,
        fax: supplierContact.fax,
        email: supplierContact.email,
        contact_person: supplierContact.contact_person,
        contact_title: supplierContact.contact_title,
        contact_mobile_phone: supplierContact.contact_mobile_phone,
        note: supplierContact.note

    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/insertUpdateSupplierContact`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSupplierContact = async (token, supplierContactId) => {
    const payload = {
        id: supplierContactId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/deleteSupplierContact`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartReturnRequisition = async (token, extCustomerId, offset = 0, limit = 100000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/findAllReturnRequisition`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSparepartReturnRequisitionById = async (token, sparepartReturnRequisitionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/findReturnRequisitionById`, {
            params: {
                id: sparepartReturnRequisitionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartReturnRequisitionCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/getCountReturnRequisition`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateSparepartReturnRequisition = async (token, sparepartReturnRequisition) => {
    const payload = {
        id: sparepartReturnRequisition.id,
        project_id: sparepartReturnRequisition.project_id,
        sparepart_catalog_id: sparepartReturnRequisition.sparepart_catalog_id,
        staff_id: sparepartReturnRequisition.staff_id,
        request_date: sparepartReturnRequisition.request_date,
        status_id: sparepartReturnRequisition.status_id,
        approved_date: sparepartReturnRequisition.approved_date,
        arrived_date: sparepartReturnRequisition.arrived_date,
        quantity: sparepartReturnRequisition.quantity,
        price: sparepartReturnRequisition.price,
        note: sparepartReturnRequisition.note,
        list_return: sparepartReturnRequisition.list_return,
        sparepart_handover_id: sparepartReturnRequisition.sparepart_handover_id,
        ext_customer_id: sparepartReturnRequisition.ext_customer_id
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartRequisition/insertUpdateReturnRequisition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartRequisitionDetail = async (token, sparepartHandoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/findAllRequisitionDetail`, {
            params: {
                id: sparepartHandoverId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSparepartReturnRequisition = async (token, sparepartReturnRequisitionId) => {
    const payload = {
        id: sparepartReturnRequisitionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartRequisition/deleteSparepartReturnRequisition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartCatalogDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getSparepartCatalogDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getStaffDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartHandover = async (token, extCustomerId, offset = 0, limit = 100000, orderBy = "sparepartHandover.created_date", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/findAllSparepartHandover`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getSparepartHandoverById = async (token, sparepartHandoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/findSparepartHandoverById`, {
            params: {
                id: sparepartHandoverId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartHandoverCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/getCountSparepartHandover`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateSparepartHandover = async (token, sparepartHandover) => {
    const payload = {
        id: sparepartHandover.id,
        project_id: sparepartHandover.project_id,
        requesting_staff_id: sparepartHandover.requesting_staff_id,
        request_date: sparepartHandover.request_date,
        approved_date: sparepartHandover.approved_date,
        arrived_date: sparepartHandover.arrived_date,
        approved_by: sparepartHandover.approved_by,
        sparepart_requisition_id: sparepartHandover.sparepart_requisition_id,
        giving_staff_id: sparepartHandover.giving_staff_id,
        list_handover: sparepartHandover.list_handover
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartRequisition/insertUpdateSparepartHandover`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const deleteSparepartHandover = async (token, sparepartRequisitionId) => {
    const payload = {
        id: sparepartRequisitionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SparepartRequisition/deleteSparepartHandover`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSparepartHandoverList = async (token, sparepartRequisitionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartRequisition/findSparepartHandoverList`, {
            params: {
                id: sparepartRequisitionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getListStaffGroupMechanic = async (token, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getStaffMechanicGroup`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselEquipmentDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/getVesselEquipmentDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMechanicGroupStaff = async (token, languageId, mechachicGroupId, offset = 0, limit = 1000, orderBy = "groupStaff.created_date", descending = true, searchQuery, detailedSearch) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllMechanicGroupStaff`, {
            params: {
                id: mechachicGroupId,
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMechanicGroupStaffById = async (token, mechaniGroupStaffId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findMechanicGroupStaffById`, {
            params: {
                id: mechaniGroupStaffId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteMechanicGroupStaff = async (token, mechanicGroupStaffId) => {
    const payload = {
        id: mechanicGroupStaffId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteMechaniGroupStaff`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateMechanicGroupStaff = async (token, mechanicGroupStaff) => {
    const payload = {
        id: mechanicGroupStaff.id,
        mechanic_group_id: mechanicGroupStaff.mechanic_group_id,
        staff_id: mechanicGroupStaff.staff_id,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateMechanicGroupStaff`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCountry = async (token, countryId) => {
    const payload = {
        id: countryId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Country/delete`, payload, {
            headers: {
                token: token,
            },
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCompanyCategory = async (token, categoryId) => {
    const payload = {
        id: categoryId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/CompanyCategory/delete`, payload, {
            headers: {
                token: token,
            },
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDepartment = async (token, divisionId, offset = 0, limit = 1000, orderBy = "department.department_name", descending = false, searchQuery, detailedSearch) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Division/findAllDepartment`, {
            params: {
                id: divisionId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDepartmentById = async (token, departmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Division/findDepartmentById`, {
            params: {
                id: departmentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteDepartment = async (token, departmentId) => {
    const payload = {
        id: departmentId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Division/deleteDepartment`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateDepartment = async (token, department) => {
    const payload = {
        id: department.id,
        division_id: department.division_id,
        department_name: department.department_name,
        department_code: department.department_code,
        department_description: department.department_description,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Division/insertUpdateDepartment`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteStaffPosition = async (token, positionId) => {
    const payload = {
        id: positionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/StaffPosition/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteStaffSpecialization = async (token, specializationId) => {
    const payload = {
        id: specializationId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/StaffSpecialization/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCompanyContact = async (token, companyId, offset = 0, limit = 1000, orderBy = "companyContact.created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Company/findAllCompanyContact`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                company_id: companyId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCompanyContactById = async (token, companyContactId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Company/findCompanyContactById`, {
            params: {
                id: companyContactId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCompanyContactCount = async (token, companyId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Company/getCompanyContactCount`, {
            headers: {
                token: token
            },
            params: {
                company_id: companyId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateCompanyContact = async (token, companyContact) => {
    const payload = {
        id: companyContact.id,
        company_id: companyContact.company_id,
        phone_number1: companyContact.phone_number1,
        phone_number2: companyContact.phone_number2,
        contact_name: companyContact.contact_name,
        email: companyContact.email,
        title: companyContact.title,
        description: companyContact.description
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Company/insertUpdateCompanyContact`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCompanyContact = async (token, companyContactId) => {
    const payload = {
        id: companyContactId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Company/deleteCompanyContact`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTitle = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Company/findAllTitle`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getAllStaffDocument = async (token, offset = 0, limit = 100000, orderBy = "document.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getAllStaffDocument`, {
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getAllCrewDocument = async (token, offset = 0, limit = 100000, orderBy = "document.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getAllCrewDocument`, {
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getServiceJobCrewHistory = async (token, serviceJobId, offset = 0, limit = 100000, orderBy = "crewHistory.order_number", descending, searchQuery, detailedSearch, contractId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findAllServiceJobHistory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                service_job_id: serviceJobId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);

    }

};

export const getServiceJobCrewHistoryCount = async (token, serviceJobId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/getCountHistory`, {
            headers: {
                token: token
            },
            params: {
                service_job_id: serviceJobId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}




export const getCustomer = async (token, extCustomerId, offset = 0, limit = 10000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/findAllCustomer`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCustomerById = async (token, customerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/findCustomerById`, {
            params: {
                id: customerId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCustomerCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/getCustomerCount`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateCustomer = async (token, customer) => {
    const payload = {
        id: customer.id,
        customer_name: customer.customer_name,
        customer_type: customer.customer_type,
        city: customer.city,
        bank_id: customer.bank_id,
        address_1: customer.address_1,
        address_2: customer.address_2,
        zip_code: customer.zip_code,
        bank_account_number: customer.bank_account_number,
        npwp: customer.npwp,
        fg_ppn: customer.fg_ppn,
        nppkp: customer.nppkp,
        company_id: customer.company_id,
        term_of_payment: customer.term_of_payment,
        customer_type_id: customer.customer_type_id,
        ext_customer_id: customer.ext_customer_id

    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Customer/insertUpdateCustomer`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCustomer = async (token, customerId) => {
    const payload = {
        id: customerId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Customer/deleteCustomer`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCustomerContact = async (token, customerId, offset = 0, limit = 1000, orderBy = "contact.created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/findAllCustomerContact`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                customer_id: customerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCustomerContactById = async (token, customerContactId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/findCustomerContactById`, {
            params: {
                id: customerContactId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCustomerContactCount = async (token, customerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/getCustomerContactCount`, {
            headers: {
                token: token
            },
            params: {
                customer_id: customerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateCustomerContact = async (token, customerContact) => {
    const payload = {
        id: customerContact.id,
        customer_id: customerContact.customer_id,
        phone_number1: customerContact.phone_number1,
        fax: customerContact.fax,
        email: customerContact.email,
        contact_person_name: customerContact.contact_person_name,
        contact_title: customerContact.contact_title,
        phone_number2: customerContact.phone_number2,
        note: customerContact.note

    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Customer/insertUpdateCustomerContact`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCustomerContact = async (token, customerContactId) => {
    const payload = {
        id: customerContactId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Customer/deleteCustomerContact`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselEquipmentByVessel = async (token, vesselId, searchQuery, detailedSearch) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/findVesselEquipmentByVessel`, {
            params: {
                vessel_id: vesselId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDetailEquipmentService = async (token, contractId, vesselTypeId, vesselId, projectId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findDetailEquipmentService`, {
            params: {
                contract_id: contractId,
                vessel_type_id: vesselTypeId,
                vessel_id: vesselId,
                project_id: projectId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDetailEquipmentServicePhoto = async (token, equipmentServiceJobDetailId, offset = 0, limit = 10000, orderBy = "taken_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findPhotoDetailJob`, {
            params: {
                equipment_service_job_detail_id: equipmentServiceJobDetailId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateServiceJobFoto = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/insertUpdateServiceJobFoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteServiceJobFoto = async (token, photoId) => {
    const payload = {
        id: photoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/deleteServiceJobFoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getServiceReport = async (token, serviceJobId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/createDocument`, {
            params: {
                id: serviceJobId
            },
            headers: {
                token: token
            },
            responseType: 'blob'
        });
        console.log(response);
        return (response.data);
        // if (response.data.error_code === 0) {
        //     return (response.data.data);
        // } else {
        //     return ([]);
        // }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStatusBilling = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/projectBillingStatus`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateProjectDocument = async (token, document) => {
    try {
        const payload = {
            id: document.id,
            document_base64: document.document_base64,
            document_name: document.document_name,
            description: document.description,
            project_id: document.project_id,
            reference_number: document.reference_number,
            document_type: document.document_type,
            document_date: document.document_date
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Project/insertUpdateProjectDocument`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getProjectDocument = async (token, projectId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/getProjectDocument`, {
            params: {
                id: projectId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/getProjectDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteProjectDocument = async (token, documentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Project/deleteProjectDocument`, { id: documentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getTroubleshooting = async (token, extCustomerId, offset = 0, limit = 100000, orderBy = "project.project_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);

    }

};


export const getTroubleshootingById = async (token, troubleshootingId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/findById`, {
            params: {
                id: troubleshootingId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateTroubleshooting = async (token, troubleshooting) => {
    const payload = {
        id: troubleshooting.id,
        project_id: troubleshooting.project_id,
        service_date: troubleshooting.service_date,
        staff_pic_id: troubleshooting.staff_pic_id,
        mechanic_group_id: troubleshooting.mechanic_group_id,
        status_id: troubleshooting.status_id,
        service_location: troubleshooting.service_location,
        list_job: troubleshooting.list_job,
        list_crew: troubleshooting.list_technician,
        deleted_crew: troubleshooting.deleted_technician,
        detail_job: troubleshooting.detail_job,
        deleted_detail: troubleshooting.deleted_detail,
        list_finding: troubleshooting.list_finding,
        deleted_finding: troubleshooting.deleted_finding,
        ext_customer_id: troubleshooting.ext_customer_id,
        deleted_job: troubleshooting.deleted_job
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Troubleshooting/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteTroubleshooting = async (token, troubleshootingId) => {
    const payload = {
        id: troubleshootingId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Troubleshooting/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTroubleshootingCount = async (token, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/count`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}



export const getTroubleshootingPhoto = async (token, troubleshootingDetailId, offset = 0, limit = 10000, orderBy = "taken_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/findPhotoDetailJob`, {
            params: {
                troubleshooting_detail_id: troubleshootingDetailId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const findPhotoEngineInspectionDetail = async (token, inspectionDetailId, documentTypeId, offset = 0, limit = 10000, orderBy = "taken_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findPhotoEngineInspectionDetail`, {
            params: {
                id: inspectionDetailId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                document_type_id: documentTypeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const findPhotoEngineInspection = async (token, inspectionDetailId, documentTypeId, offset = 0, limit = 10000, orderBy = "taken_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findPhotoEngineInspection`, {
            params: {
                id: inspectionDetailId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                document_type_id: documentTypeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateTroubleshootingFoto = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Troubleshooting/insertUpdateTroubleshootingFoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdatePhotoEngineInspectionDetail = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/EngineHealthInspection/insertUpdatePhotoEngineInspectionDetail`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            console.log(response);
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdatePhotoEngineInspection = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/EngineHealthInspection/insertUpdatePhotoEngineInspection`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            console.log(response);
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const deleteTroubleshootingFoto = async (token, photoId) => {
    const payload = {
        id: photoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Troubleshooting/deleteTroubleshootingFoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const findAllProjectDocumentType = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findAllProjectDocumentType`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return (false);
    }
}

export const deletePhotoEngineInspectionDetail = async (token, photoId) => {
    const payload = {
        id: photoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/EngineHealthInspection/deletePhotoEngineInspectionDetail`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deletePhotoEngineInspection = async (token, photoId) => {
    const payload = {
        id: photoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/EngineHealthInspection/deletePhotoEngineInspection`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTroubleshootingReport = async (token, troubleshootingId, projectId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/createDocument`, {
            params: {
                id: troubleshootingId,
                project_id: projectId
            },
            headers: {
                token: token
            },
            responseType: 'blob'
        });
        return (response.data);
        // if (response.data.error_code === 0) {
        //     return (response.data.data);
        // } else {
        //     return ([]);
        // }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTroubleshootingCrewHistory = async (token, troubleshootingId, offset = 0, limit = 100000, orderBy = "crewHistory.departure_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/findAllTroubleshootingHistory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                troubleshooting_id: troubleshootingId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);

    }

};

export const getTroubleshootingCrewHistoryCount = async (token, troubleshootingId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/getCountHistory`, {
            headers: {
                token: token
            },
            params: {
                troubleshooting_id: troubleshootingId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const deleteVesselType = async (token, vesselTypeId) => {
    const payload = {
        id: vesselTypeId
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselType/delete`, payload, {
            headers: {
                token: token
            }
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
    }
}

export const insertUpdateTechnicianDocument = async (token, document) => {
    try {
        const payload = {
            id: document.id,
            document_base64: document.document_base64,
            document_name: document.document_name,
            description: document.description,
            reference_number: document.reference_number,
            document_category_id: document.document_category_id
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateTechnicianDocument`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getTechnicianDocument = async (token, offset = 0, limit = 100000, orderBy = "document.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getTechnicianDocument`, {
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}
export const deleteTechnicianDocument = async (token, documentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteTechnicianDocument`, { id: documentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}

export const getTechnicianDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getTechnicianDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSubStaffSpecialization = async (token, specializationId, offset = 0, limit = 1000, orderBy = "sub_specialization_name", descending = true, searchQuery, detailedSearch) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffSpecialization/findAllSubStaffSpecialization`, {
            params: {
                id: specializationId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSubStaffSpecializationById = async (token, subStaffSpecializationId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffSpecialization/findSubStaffSpecializationById`, {
            params: {
                id: subStaffSpecializationId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteSubStaffSpecialization = async (token, subStaffSpecializationId) => {
    const payload = {
        id: subStaffSpecializationId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/StaffSpecialization/deleteSubStaffSpecialization`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateSubStaffSpecialization = async (token, subStaffSpecialization) => {
    const payload = {
        id: subStaffSpecialization.id,
        specialization_id: subStaffSpecialization.specialization_id,
        sub_specialization_name: subStaffSpecialization.sub_specialization_name,
        description: subStaffSpecialization.description
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/StaffSpecialization/insertUpdateSubStaffSpecialization`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentSparepartById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentTechById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getDocumentTechById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentStaffById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentCategoryTechnician = async (token, offset = 0, limit = 100000, orderBy = "category_name", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllDocumentTechnicianCategory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const insertUpdateDocumentCategoryTechnician = async (token, documentCategory) => {
    const payload = {
        category_name: documentCategory.category_name,
        description: documentCategory.description,
        id: documentCategory.id,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateDocumentTechnicianCategory`, payload, {
            headers: {
                token: token
            },
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentCategoryTechnicianById = async (token, unitTypeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findByIdDocumentTechnicianCategory`, {
            params: {
                id: unitTypeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentCategoryTechnicianCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCountDocumentTechnicianCategory`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteDocumentCategoryTechnician = async (token, unitTypeId) => {
    const payload = {
        id: unitTypeId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteDocumentTechnicianCategory`, payload, {
            headers: {
                token: token,
            },
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSubSpecialization = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffSpecialization/findAllSubSpecialization`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getUserAccessById = async (token, menuPath, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SystemMenu/findByIdUserAccess`, {
            params: {
                menu_path: menuPath,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselEquipmentByEquipmentCategory = async (token, equipmentId, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/findVesselEquipmentByEquipmentCategory`, {
            headers: {
                token: token
            },
            params: {
                id: equipmentId,
                vessel_id: vesselId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return (false);
    }
}

export const insertUpdateVesselCopy = async (token, vessel) => {
    const payload = {
        id: 0,
        vessel_name: vessel.vessel_name,
        customer_id: vessel.customer_id,
        vessel_type_id: vessel.vessel_type_id,
        source_vessel_id: vessel.source_vessel_id,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/vessel/insertUpdateCopyData`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPositionsById = async (token, positionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findPositionById`, {
            params: {
                id: positionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPositionCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getPositionCount`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdatePositions = async (token, position) => {
    const payload = {
        id: position.id,
        position_name: position.position_name,
        position_description: position.position_description,
        language_id: position.language_id,

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdatePosition`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deletePositions = async (token, positionId) => {
    const payload = {
        id: positionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deletePosition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEmployeeStatusById = async (token, statusId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findEmployeeStatusById`, {
            params: {
                id: statusId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEmployeeStatusCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getEmployeeStatusCount`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateEmployeeStatus = async (token, status) => {
    const payload = {
        id: status.id,
        employee_status_name: status.employee_status_name,
        status_description: status.status_description,
        language_id: status.language_id,

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateEmployeeStatus`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteEmployeeStatus = async (token, statusId) => {
    const payload = {
        id: statusId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteEmployeeStatus`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getBankById = async (token, bankId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Bank/findById`, {
            params: {
                id: bankId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getBankCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Bank/getBankCount`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateBank = async (token, bank) => {
    const payload = {
        id: bank.id,
        bank_name: bank.bank_name,
        bank_code: bank.bank_code,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Bank/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteBank = async (token, bankId) => {
    const payload = {
        id: bankId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Bank/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTermoFPayment = async (token, offset = 0, limit = 100000, orderBy = "number_of_days", descending = false, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findTermOfPayment`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTermoFPaymentById = async (token, topId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/findTermOfPaymentById`, {
            params: {
                id: topId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTermoFPaymentCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Supplier/getTermOfPaymentCount`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateTermoFPayment = async (token, top) => {
    const payload = {
        id: top.id,
        number_of_days: top.number_of_days,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/insertUpdateTermOfPayment`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteTermoFPayment = async (token, bankId) => {
    const payload = {
        id: bankId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Supplier/deleteTermOfPayment`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectType = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectType/findAll`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectTypeById = async (token, typeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectType/findProjectTypeById`, {
            params: {
                id: typeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectTypeCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectType/getProjectTypeCount`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateProjectType = async (token, projectType) => {
    const payload = {
        id: projectType.id,
        project_type_name: projectType.project_type_name,
        project_type_code: projectType.project_type_code,
        project_type_desc: projectType.project_type_desc,
        language_id: projectType.language_id
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ProjectType/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteProjectType = async (token, typeId) => {
    const payload = {
        id: typeId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ProjectType/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentProjectTypeById = async (token, typeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/findDocumentProjectTypeById`, {
            params: {
                id: typeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentProjectTypeCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/getDocumentProjectTypeCount`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateDocumentProjectType = async (token, documentType) => {
    const payload = {
        id: documentType.id,
        type_name: documentType.type_name,
        type_code: documentType.type_code,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Project/insertUpdateDocumentProjectType`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteDocumentProjectType = async (token, typeId) => {
    const payload = {
        id: typeId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Project/deleteDocumentProjectType`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentProjectType = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/findDocumenProjectType`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getProjectStatus = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectStatus/findAll`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectStatusById = async (token, statusId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectStatus/findById`, {
            params: {
                id: statusId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectStatusCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ProjectStatus/getCount`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateProjectStatus = async (token, projectStatus) => {
    const payload = {
        id: projectStatus.id,
        project_status_name: projectStatus.project_status_name,
        project_status_code: projectStatus.project_status_code,
        language_id: projectStatus.language_id,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ProjectStatus/insertUpdate`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteProjectStatus = async (token, statusId) => {
    const payload = {
        id: statusId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ProjectStatus/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getContractStatus = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/getContractStatus`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getContractStatusById = async (token, statusId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/findContractStatusById`, {
            params: {
                id: statusId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getContractStatusCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Contract/getContractStatusCount`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateContractStatus = async (token, contractStatus) => {
    const payload = {
        id: contractStatus.id,
        contract_status_name: contractStatus.contract_status_name,
        contract_status_code: contractStatus.contract_status_code,
        language_id: contractStatus.language_id,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Contract/insertUpdateContractStatus`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteContractStatus = async (token, statusId) => {
    const payload = {
        id: statusId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Contract/deleteContractStatus`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getServiceJobStatus = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/getServiceJobStatus`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getServiceJobStatusById = async (token, statusId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findServiceJobStatusById`, {
            params: {
                id: statusId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getServiceJobStatusCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/getServiceJobStatusCount`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateServiceJobStatus = async (token, serviceJobStatus) => {
    const payload = {
        id: serviceJobStatus.id,
        status_name: serviceJobStatus.status_name,
        status_code: serviceJobStatus.status_code,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/insertUpdateServiceJobStatus`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteServiceJobStatus = async (token, statusId) => {
    const payload = {
        id: statusId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/deleteServiceJobStatus`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}



export const getTroubeleshootingStatus = async (token, languageId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/getTroubleshootingStatus`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTroubleshootingStatusById = async (token, statusId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/findTroubleshootingStatusById`, {
            params: {
                id: statusId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTroubleshootingStatusCount = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Troubleshooting/getTroubleshootingStatusCount`, {
            headers: {
                token: token
            },
            params: {

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateTroubleshootingStatus = async (token, troubleshootingStatus) => {
    const payload = {
        id: troubleshootingStatus.id,
        status_name: troubleshootingStatus.status_name,
        status_code: troubleshootingStatus.status_code,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Troubleshooting/insertUpdateTroubleshootingStatus`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteTroubleshootingStatus = async (token, statusId) => {
    const payload = {
        id: statusId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Troubleshooting/deleteTroubleshootingStatus`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getCustomerType = async (token, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/getCustomerType`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCustomerTypeById = async (token, customerTypeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/findCustomerTypeById`, {
            params: {
                id: customerTypeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCustomerTypeCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/getCustomerTypeCount`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateCustomerType = async (token, customerType) => {
    const payload = {
        id: customerType.id,
        customer_type_name: customerType.customer_type_name,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Customer/insertUpdateCustomerType`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCustomerType = async (token, customerTypeId) => {
    const payload = {
        id: customerTypeId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Customer/deleteCustomerType`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDashboarAccessByUserId = async (token, userId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SurveySchedule/findByIdDashboardAccess`, {
            headers: {
                token: token
            },
            params: {
                user_id: userId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateDashboarAccess = async (token, dasboardAccess) => {
    let payload = {
        user_id: dasboardAccess.user_id,
        calender: dasboardAccess.calender,
        alert: dasboardAccess.alert,
        vessel: dasboardAccess.vessel,
        equipment: dasboardAccess.equipment,
        due_date_alert: dasboardAccess.due_date_alert,
        document_alert: dasboardAccess.document_alert,
        service_plan_alert: dasboardAccess.service_plan_alert,
        inventory_due_date: dasboardAccess.inventory_due_date,
        staff_contract_alert:  dasboardAccess.staff_contract_alert
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SurveySchedule/insertUpdateDashboardAccess`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }



    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCompanyLogo = async (token, customerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Customer/findCompanyLogo`, {
            params: {
                ext_customer_id: customerId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMechanicGroupStaffCount = async (token, mechachicGroupId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCountMechanicGroupStaff`, {
            headers: {
                token: token
            },
            params: {
                id: mechachicGroupId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getCountSubStaffSpecialization = async (token, staffSpecializationId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/StaffSpecialization/getCountSubStaffSpecialization`, {
            headers: {
                token: token
            },
            params: {
                id: staffSpecializationId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getDivisionCount = async (token, languageId, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Division/getCount`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getDepartmentCount = async (token, divisionId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Division/getDepartmentCount`, {
            headers: {
                token: token
            },
            params: {
                id: divisionId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getUserCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/getCount`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getCountSparepartCatalogDocument = async (token, sparepartCatalogId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getCountSparepartCatalogDocument`, {
            headers: {
                token: token
            },
            params: {
                id: sparepartCatalogId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getCountSparepartCatalogStock = async (token, extCustomerId, searchQuery, detailedSearch, totalStock = 0, searchByParameter, parameterName, parameterValue) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SparepartCatalog/getCountSparepartCatalog`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                total_stock: totalStock,
                search_parameter: searchByParameter,
                parameter_name: parameterName,
                parameter_value: parameterValue,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getCountInventoryType = async (token, languageId, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getCountInventoryType`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId,
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getCrewPositionsById = async (token, positionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findByIdCrewPositions`, {
            params: {
                id: positionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateCrewPosition = async (token, crewPositions) => {
    const payload = {
        position_name: crewPositions.position_name,
        position_desc: crewPositions.position_desc,
        department_id: crewPositions.department_id,
        id: crewPositions.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateCrewPositions`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewPositions = async (token, offset = 0, limit = 100000, orderBy = "position.position_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllCrewPositions`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountCrewPositions = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCountCrewPositions`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteCrewPositions = async (token, positionId) => {
    const payload = {
        id: positionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteCrewPositions`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrew = async (token, languageId, customerId, offset = 0, limit = 100000, orderBy = "staff_name", descending = true, searchQuery, detailedSearch, isResign = 0) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllCrew`, {
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                is_resign: isResign,
                customer_id: customerId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewCount = async (token, languageId, searchQuery, detailedSearch, isResign, customerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCrewCount`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                is_resign: isResign,
                customer_id: customerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const getCrewScheduleById = async (token, crewScheduleId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findByIdCrewSchedule`, {
            params: {
                id: crewScheduleId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateCrewSchedule = async (token, crewSchedule) => {
    const payload = {
        vessel_id: crewSchedule.vessel_id,
        crew_id: crewSchedule.crew_id,
        start_date: crewSchedule.start_date,
        end_date: crewSchedule.end_date,
        id: crewSchedule.id,
        crew_positions_id: crewSchedule.crew_positions_id,
        ext_customer_id: crewSchedule.ext_customer_id,
        department_id: crewSchedule.department_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateCrewSchedule`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewSchedule = async (token, crewId, offset = 0, limit = 100000, orderBy = "start_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllCrewSchedule`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                crew_id: crewId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountCrewSchedule = async (token, crewId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCountCrewSchedule`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                crew_id: crewId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteCrewSchedule = async (token, crewScheduleId) => {
    const payload = {
        id: crewScheduleId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteCrewSchedule`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getCrewRankById = async (token, rankId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findByIdCrewRank`, {
            params: {
                id: rankId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateCrewRank = async (token, crewRank) => {
    const payload = {
        rank_name: crewRank.rank_name,
        rank_desc: crewRank.rank_desc,
        crew_type_id: crewRank.crew_type_id,
        id: crewRank.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateCrewRank`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewRank = async (token, offset = 0, limit = 100000, orderBy = "rank_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllCrewRank`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountCrewRank = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCountCrewRank`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteCrewRank = async (token, rankId) => {
    const payload = {
        id: rankId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteCrewRank`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getVesselDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getIncomingInvoiceDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Invoice/findIncomingInvoceDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getIncomingInvoiceById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Invoice/findIncomingInvoiceById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const insertUpdateIncomingInvoice = async (token, incomingInvoice) => {
    const payload = {
        base64_document: incomingInvoice.base64_document,
        invoice_date: incomingInvoice.invoice_date,
        description: incomingInvoice.description,
        document_name: incomingInvoice.document_name,
        id: incomingInvoice.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Invoice/InsertUpdateIncomingInvoice`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getIncomingInvoice = async (token, offset = 0, limit = 100000, orderBy = "document_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Invoice/findAllIncomingInvoice`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountIncomingInvoice = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Invoice/getCountIncomingInvoice`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteIncomingInvoice = async (token, invoiceId) => {
    const payload = {
        id: invoiceId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Invoice/deleteIncomingInvoice`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInvoiceOutDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Invoice/findInvoiceOutDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInvoiceOutById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Invoice/findInvoiceOutById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateInvoiceOut = async (token, invoiceOut) => {
    const payload = {
        base64_document: invoiceOut.base64_document,
        invoice_date: invoiceOut.invoice_date,
        description: invoiceOut.description,
        document_name: invoiceOut.document_name,
        id: invoiceOut.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Invoice/InsertUpdateInvoiceOut`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInvoiceOut = async (token, offset = 0, limit = 100000, orderBy = "document_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Invoice/findAllInvoiceOut`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountInvoiceOut = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Invoice/getCountInvoiceOut`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteInvoiceOut = async (token, invoiceId) => {
    const payload = {
        id: invoiceId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Invoice/deleteInvoiceOut`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventoryCategoryById = async (token, categoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselInventoryCategoryById`, {
            params: {
                id: categoryId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateVesselInventoryCategory = async (token, vesselInventoryCategory) => {
    const payload = {
        category_name: vesselInventoryCategory.category_name,
        category_description: vesselInventoryCategory.category_description,
        id: vesselInventoryCategory.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/InsertUpdateVesselInventoryCategory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const findVesselInventoryCategory = async (token, offset = 0, limit = 100000, orderBy = "category_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselInventoryCategory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountVesselInventoryCategory = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselInventoryCategory`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteVesselInventoryCategory = async (token, categoryId) => {
    const payload = {
        id: categoryId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselInventoryCategory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventorySubCategoryById = async (token, subCategoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselInventorySubCategoryById`, {
            params: {
                id: subCategoryId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSubCategory = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselSubCategory`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateVesselInventorySubCategory = async (token, vesselInventorySubCategory) => {
    const payload = {
        sub_category_name: vesselInventorySubCategory.sub_category_name,
        sub_category_description: vesselInventorySubCategory.sub_category_description,
        category_id: vesselInventorySubCategory.category_id,
        id: vesselInventorySubCategory.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/InsertUpdateVesselInventorySubCategory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const findVesselInventorySubCategory = async (token, categoryId, offset = 0, limit = 100000, orderBy = "sub_category_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselInventorySubCategory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                category_id: categoryId,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountVesselInventorySubCategory = async (token, searchQuery, detailedSearch, categoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselInventorySubCategory`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                category_id: categoryId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteVesselInventorySubCategory = async (token, categoryId) => {
    const payload = {
        id: categoryId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselInventorySubCategory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventoryById = async (token, inventoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselInventoryById`, {
            params: {
                id: inventoryId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateVesselInventory = async (token, vesselInventoryMaster) => {
    const payload = {
        vessel_inventory_category_id: vesselInventoryMaster.vessel_inventory_category_id,
        vessel_inventory_brand: vesselInventoryMaster.vessel_inventory_brand,
        vessel_inventory_model: vesselInventoryMaster.vessel_inventory_model,
        vessel_inventory_name: vesselInventoryMaster.vessel_inventory_name,
        vessel_inventory_description: vesselInventoryMaster.vessel_inventory_description,
        quantity: vesselInventoryMaster.quantity,
        vessel_id: vesselInventoryMaster.vessel_id,
        id: vesselInventoryMaster.id,
        vessel_inventory_unit_id: vesselInventoryMaster.vessel_inventory_unit_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/InsertUpdateVesselInventory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventory = async (token, vesselId, offset = 0, limit = 100000, orderBy = "vesselInventory.vessel_inventory_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselInventory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                vessel_id: vesselId,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountVesselInventory = async (token, searchQuery, detailedSearch, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselInventory`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_id: vesselId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteVesselInventory = async (token, vesselId) => {
    const payload = {
        id: vesselId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselInventory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventoryMasterStockById = async (token, inventoryStockId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselInventoryMasterStockById`, {
            params: {
                id: inventoryStockId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateVesselInventoryMasterStock = async (token, vesselInventoryMasterStock) => {
    const payload = {
        vessel_inventory_id: vesselInventoryMasterStock.vessel_inventory_id,
        stock_internal_code: vesselInventoryMasterStock.stock_internal_code,
        stock_external_code: vesselInventoryMasterStock.stock_external_code,
        purchase_date: vesselInventoryMasterStock.purchase_date,
        vessel_inventory_stock_period_id: vesselInventoryMasterStock.vessel_inventory_stock_period_id,
        period_number: vesselInventoryMasterStock.period_number,
        purchase_price: vesselInventoryMasterStock.purchase_price,
        staff_id: vesselInventoryMasterStock.staff_id,
        vessel_inventory_stock_status_id: vesselInventoryMasterStock.vessel_inventory_stock_status_id,
        vessel_inventory_stock_condition_id: vesselInventoryMasterStock.vessel_inventory_stock_condition_id,
        vessel_inventory_stock_description: vesselInventoryMasterStock.vessel_inventory_stock_description,
        id: vesselInventoryMasterStock.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/InsertUpdateVesselInventoryMasterStock`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventoryMasterStock = async (token, vesselInventoryMasterId, offset = 0, limit = 100000, orderBy = "inventoryStock.stock_internal_code", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselInventoryMasterStock`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                vessel_inventory_id: vesselInventoryMasterId,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountVesselInventoryMasterStock = async (token, searchQuery, detailedSearch, inventoryMasterId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselInventoryMasterStock`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_inventory_id: inventoryMasterId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteVesselInventoryMasterStock = async (token, inventoryStockId) => {
    const payload = {
        id: inventoryStockId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselInventoryMasterStock`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getVesselInventoryRequisition = async (token, offset = 0, limit = 100000, orderBy = "vessel.vessel_name", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselInventoryRequisition`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getVesselInventoryRequisitionById = async (token, vesselInventoryRequisitionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findByIdVesselInventoryRequisition`, {
            params: {
                id: vesselInventoryRequisitionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventoryRequisitionCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselInventoryRequisition`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateVesselInventoryRequisition = async (token, vesselInventoryRequisition) => {
    const payload = {
        id: vesselInventoryRequisition.id,
        vessel_id: vesselInventoryRequisition.vessel_id,
        staff_id: vesselInventoryRequisition.staff_id,
        request_date: vesselInventoryRequisition.request_date,
        status_id: vesselInventoryRequisition.status_id,
        approved_date: vesselInventoryRequisition.approved_date,
        approved_by: vesselInventoryRequisition.approved_by,
        list_requisition: vesselInventoryRequisition.list_requisition,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/insertUpdateVesselInventoryRequisition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const deleteVesselInventoryRequisition = async (token, vesselInventoryRequisitionId) => {
    const payload = {
        id: vesselInventoryRequisitionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselInventoryRequisition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewOnVesselToday = async (token, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findCrewOnVesselToday`, {
            params: {
                vessel_id: vesselId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventoryHandover = async (token, offset = 0, limit = 100000, orderBy = "vessel.vessel_name", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselInventoryHandover`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getVesselInventoryHandoverById = async (token, vesselHandoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselInventoryHandoverById`, {
            params: {
                id: vesselHandoverId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselHandoverCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselInventoryHandover`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateVesselHandover = async (token, vesselHandover) => {
    const payload = {
        id: vesselHandover.id,
        vessel_id: vesselHandover.vessel_id,
        requesting_staff_id: vesselHandover.requesting_staff_id,
        request_date: vesselHandover.request_date,
        approved_date: vesselHandover.approved_date,
        approved_by: vesselHandover.approved_by,
        vessel_inventory_requisition_id: vesselHandover.vessel_inventory_requisition_id,
        giving_staff_id: vesselHandover.giving_staff_id,
        list_handover: vesselHandover.list_handover
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/insertUpdateVesselInventoryHandover`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const deleteVesselHandover = async (token, vesselHandoverId) => {
    const payload = {
        id: vesselHandoverId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselInventoryHandover`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselHandoverList = async (token, vesselHandoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselInventoryHandoverList`, {
            params: {
                id: vesselHandoverId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventoryStock = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselInventoryStock`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewOnVessel = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findCrewOnVessel`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselReturn = async (token, offset = 0, limit = 100000, orderBy = "vessel.vessel_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselReturn`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getVesselReturnById = async (token, returnId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselReturnById`, {
            params: {
                id: returnId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselReturnCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselReturn`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateVesselReturn = async (token, vesselReturn) => {
    const payload = {
        id: vesselReturn.id,
        vessel_id: vesselReturn.vessel_id,
        staff_id: vesselReturn.staff_id,
        inventory_handover_id: vesselReturn.inventory_handover_id,
        list_return: vesselReturn.list_return,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/insertUpdateVesselReturn`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getAllHandoverDetail = async (token, handoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllHandoverDetail`, {
            params: {
                id: handoverId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteVesselReturn = async (token, returnId) => {
    const payload = {
        id: returnId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselReturn`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewRankByType = async (token, typeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findCrewRankByCrewType`, {
            params: {
                crew_type_id: typeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewType = async (token,) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findCrewType`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getCrewDepartment = async (token, offset = 0, limit = 1000, orderBy = "department_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllCrewDepartment`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCrewDepartmentById = async (token, departmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findCrewDepartmentById`, {
            params: {
                id: departmentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewDepartmentCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCountCrewDepartment`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateCrewDepartment = async (token, crewDepartment) => {
    const payload = {
        id: crewDepartment.id,
        department_name: crewDepartment.department_name,
        description: crewDepartment.description,

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateCrewDepartment`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCrewDepartment = async (token, departmentId) => {
    const payload = {
        id: departmentId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteCrewDepartment`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewPositionByDepartment = async (token, departmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findCrewPositionByDepartment`, {
            params: {
                id: departmentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewDocumentCategory = async (token, offset = 0, limit = 1000, orderBy = "category_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllCrewDocumentCategory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCrewDocumentCategoryById = async (token, categoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findCrewCategoryDocumentById`, {
            params: {
                id: categoryId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewDocumentCategoryCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCountCrewDocumentCategory`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateCrewDocumentCategory = async (token, documentCategory) => {
    const payload = {
        id: documentCategory.id,
        category_name: documentCategory.category_name,
        description: documentCategory.description,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateCrewDocumentCategory`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteCrewDocumentCategory = async (token, categoryId) => {
    const payload = {
        id: categoryId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteCrewDocumentCategory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getVesselShiftSession = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselShiftSession`, {
            params: {
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getVesselSparepartRequisition = async (token, offset = 0, limit = 100000, orderBy = "vessel.vessel_name", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllSparepartRequisition`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getVesselSparepartRequisitionById = async (token, vesselSparepartRequisitionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findByIdVesselSparepartRequisition`, {
            params: {
                id: vesselSparepartRequisitionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSparepartRequisitionCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselSparepartRequisition`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateVesselSparepartRequisition = async (token, vesselSparepartRequisition) => {
    const payload = {
        id: vesselSparepartRequisition.id,
        vessel_id: vesselSparepartRequisition.vessel_id,
        staff_id: vesselSparepartRequisition.staff_id,
        request_date: vesselSparepartRequisition.request_date,
        status_id: vesselSparepartRequisition.status_id,
        approved_date: vesselSparepartRequisition.approved_date,
        approved_by: vesselSparepartRequisition.approved_by,
        list_requisition: vesselSparepartRequisition.list_requisition,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/insertUpdateVesselSparepartRequisition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const deleteVesselSparepartRequisition = async (token, vesselSparepartRequisitionId) => {
    const payload = {
        id: vesselSparepartRequisitionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselSparepartRequisition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSparepartHandover = async (token, offset = 0, limit = 100000, orderBy = "vessel.vessel_name", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselSparepartHandover`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getVesselSparepartHandoverById = async (token, vesselHandoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselSparepartHandoverById`, {
            params: {
                id: vesselHandoverId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSparepartHandoverCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselSparepartHandover`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateVesselSparepartHandover = async (token, vesselHandover) => {
    const payload = {
        id: vesselHandover.id,
        vessel_id: vesselHandover.vessel_id,
        requesting_staff_id: vesselHandover.requesting_staff_id,
        request_date: vesselHandover.request_date,
        approved_date: vesselHandover.approved_date,
        approved_by: vesselHandover.approved_by,
        vessel_sparepart_requisition_id: vesselHandover.vessel_sparepart_requisition_id,
        giving_staff_id: vesselHandover.giving_staff_id,
        list_handover: vesselHandover.list_handover
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/insertUpdateVesselSparepartHandover`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const deleteVesselSparepartHandover = async (token, vesselHandoverId) => {
    const payload = {
        id: vesselHandoverId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselSparepartHandover`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSparepartHandoverList = async (token, vesselHandoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselSparepartHandoverList`, {
            params: {
                id: vesselHandoverId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSparepartStock = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselSparepartStock`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSparepartReturn = async (token, offset = 0, limit = 100000, orderBy = "vessel.vessel_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselSparepartReturn`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getVesselSparepartReturnById = async (token, returnId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselSparepartReturnById`, {
            params: {
                id: returnId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSparepartReturnCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselSparepartReturn`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateVesselSparepartReturn = async (token, vesselReturn) => {
    const payload = {
        id: vesselReturn.id,
        vessel_id: vesselReturn.vessel_id,
        staff_id: vesselReturn.staff_id,
        sparepart_handover_id: vesselReturn.sparepart_handover_id,
        list_return: vesselReturn.list_return,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/insertUpdateVesselSparepartReturn`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getAllSparepartHandoverDetail = async (token, handoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllSparepartHandoverDetail`, {
            params: {
                id: handoverId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteVesselSparepartReturn = async (token, returnId) => {
    const payload = {
        id: returnId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselSparepartReturn`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getVesselScheduleById = async (token, vesselScheduleId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findByIdVesselSchedule`, {
            params: {
                id: vesselScheduleId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateVesselSchedule = async (token, vesselSchedule) => {
    const payload = {
        vessel_id: vesselSchedule.vessel_id,
        start_date: vesselSchedule.start_date,
        end_date: vesselSchedule.end_date,
        departing_from: vesselSchedule.departing_from,
        departure_destination: vesselSchedule.departure_destination,
        note: vesselSchedule.note,
        list_crew: vesselSchedule.list_crew,
        id: vesselSchedule.id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/InsertUpdateVesselSchedule`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselSchedule = async (token, vesselId, offset = 0, limit = 100000, orderBy = "vessel.vessel_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselSchedule`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_id: vesselId

            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountVesselSchedule = async (token, vesselId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselSchedule`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_id: vesselId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteVesselSchedule = async (token, vesselScheduleId) => {
    const payload = {
        id: vesselScheduleId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselSchedule`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCrewXVesselByVesselScheduleId = async (token, vesselScheduleId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findCrewXVesselByVesselScheduleId`, {
            params: {
                id: vesselScheduleId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getAllCrewXVessel = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllCrewXVessel`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselScheduleForCrew = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselScheduleForCrew`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselScheduleForCalender = async (token, vesselId, languageId, startDate, endDate, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findScheduleForCalender`, {
            params: {
                vessel_id: vesselId,
                language_id: languageId,
                start_date: startDate,
                end_date: endDate,
                ext_customer_id: extCustomerId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false);
        }
    }
    catch (exception) {
        return (false);
    }
}

export const getVesselScheduleForCrewByVesselId = async (token, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselScheduleForCrewByVesselId`, {
            params: {
                vessel_id: vesselId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentAlert = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findDocumentAlert`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getServiceByProject = async (token, projectId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/getServiceByProject`, {
            params: {
                project_id: projectId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getVesselInventoryMasterById = async (token, inventoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findVesselInventoryMasterById`, {
            params: {
                id: inventoryId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateVesselInventoryMaster = async (token, vesselInventoryMaster) => {
    const payload = {
        vessel_inventory_category_id: vesselInventoryMaster.vessel_inventory_category_id,
        vessel_inventory_brand: vesselInventoryMaster.vessel_inventory_brand,
        vessel_inventory_name: vesselInventoryMaster.vessel_inventory_name,
        vessel_inventory_description: vesselInventoryMaster.vessel_inventory_description,
        quantity: vesselInventoryMaster.quantity,
        id: vesselInventoryMaster.id,
        vessel_inventory_unit_id: vesselInventoryMaster.vessel_inventory_unit_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/InsertUpdateVesselInventoryMaster`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getVesselInventoryMaster = async (token, offset = 0, limit = 100000, orderBy = "vesselInventoryMaster.vessel_inventory_name", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/findAllVesselInventoryMaster`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountVesselInventoryMaster = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselInventoryMaster`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteVesselInventoryMaster = async (token, vesselId) => {
    const payload = {
        id: vesselId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselInventoryMaster`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getNotificationVesselInventoryStock = async (token, offset = 0, limit = 100000, orderBy = "vessel.vessel_inventory_name", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getNotificationStockVessel`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCountNotificationVesselInventoryStock = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountNotificationStockVessel`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getNotificationVesselInventoryRequisition = async (token, offset = 0, limit = 100000, orderBy = "vessel.vessel_name", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getNotificationInventoryRequisition`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCountNotificationVesselInventoryRequisition = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountNotificationInventoryRequisition`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getCountVesselInventoryHandoverDocument = async (token, sparepartCatalogId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getCountVesselInventoryHandoverDocument`, {
            headers: {
                token: token
            },
            params: {
                id: sparepartCatalogId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const insertUpdateVesselInventoryHandoverDocument = async (token, document) => {
    try {
        const payload = {
            id: document.id,
            document_base64: document.document_base64,
            document_name: document.document_name,
            note: document.note,
            vessel_inventory_handover_detail_id: document.vessel_inventory_handover_detail_id,
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/insertUpdateVesselInventoryHandoverDocument`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getVesselInventoryHandoverDocument = async (token, vesselHandoverId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getVesselInventoryHandoverDocument`, {
            params: {
                id: vesselHandoverId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteVesselInventoryHandoverDocument = async (token, documentId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Vessel/deleteVesselInventoryHandoverDocument`, { id: documentId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}


export const getVesselInventoryHandoverDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Vessel/getVesselInventoryHandoverDocumentById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getLoadCell = async (token, offset = 0, limit = 100000, orderBy = "device_name", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadcell/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCountLoadCell = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadCell/count`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}



export const getLoadCellById = async (token, deviceId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadCell/findById`, {
            params: {
                id: deviceId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getAllLoadCellData = async (token, deviceId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadCell/findAllLoadCellData`, {

            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getLoadCellDeviceLocation = async (token, offset = 0, limit = 100000, orderBy = "device.device_name", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadcell/findAllDeviceLocation`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getCountLoadCellDeviceLocation = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadCell/getCountDeviceLocation`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const getAllLoadCellDataLocation = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadCell/findAllLoadCellDataLocation`, {

            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const findLastLoadCellReading = async (token, deviceId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadCell/findLastLoadCellReading`, {
            params: {
                device_id: deviceId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const findAllLoadCellReading = async (token, deviceId, startTime, endTime) => {
    try {
        // console.log(startTime);
        // console.log(endTime);
        let response = await axios.get(`${Config.API_ENDPOINT}/api/loadCell/findAllLoadCellReading`, {
            params: {
                device_id: deviceId,
                start_time: startTime,
                end_time: endTime
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffBirthDateForCalender = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/getBirthDateForCalender`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getPortalContentById = async (token, contentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findByIdPortalContent`, {
            params: {
                id: contentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdatePortalContent = async (token, contentPortal, isPublishChecked) => {
    const payload = {
        id: contentPortal.id,
        content_title: contentPortal.content_title,
        start_publish_date: contentPortal.start_publish_date,
        end_publish_date: contentPortal.end_publish_date,
        content_description: contentPortal.content_description,
        content_date: contentPortal.content_date,
        publish_status: isPublishChecked,
        content_photo: contentPortal.content_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/insertUpdatePortalContent`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPortalContent = async (token, offset = 0, limit = 100000, orderBy = "content_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findAllPortalContent`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountPortalContent = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/getCountPortalContent`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deletePortalContent = async (token, contentId) => {
    const payload = {
        id: contentId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/deletePortalContent`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getWisdomWordById = async (token, wordId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findByIdWisdomWord`, {
            params: {
                id: wordId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateWisdomWord = async (token, wisdomWord) => {
    const payload = {
        id: wisdomWord.id,
        description: wisdomWord.description,
        author: wisdomWord.author
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/insertUpdateWisdomWord`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getWisdomWord = async (token, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findAllWisdomWord`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountWisdomWord = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/getCountWisdomWord`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteWisdomWord = async (token, wordId) => {
    const payload = {
        id: wordId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/deleteWisdomWord`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPublishContent = async (token, offset = 0, limit = 100000, orderBy = "publish_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findAllPublishContent`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountPublishContent = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/getCountPublishContent`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const publishContent = async (token, contentId) => {
    const payload = {
        id: contentId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/publishPortalContent`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const unpublishContent = async (token, contentId) => {
    const payload = {
        id: contentId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/unpublishPortalContent`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPublishWisdomWord = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/getPublishWisdomWord`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getLeaveRequestById = async (token, requestId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/findByIdLeaveRequest`, {
            params: {
                id: requestId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateLeaveRequest = async (token, leaveRequest) => {
    const payload = {
        id: leaveRequest.id,
        staff_id: leaveRequest.staff_id,
        status_id: leaveRequest.status_id,
        start_date: leaveRequest.start_date,
        end_date: leaveRequest.end_date,
        description: leaveRequest.description,
        approved_by: leaveRequest.approved_by,
        rejected_by: leaveRequest.rejected_by,
        rejected_reason: leaveRequest.rejected_reason,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/LeaveRequest/insertUpdateLeaveRequest`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getLeaveRequest = async (token, level, staffId, offset = 0, limit = 100000, orderBy = "leaveRequest.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/findAllLeaveRequest`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                user_level: level,
                limit: limit,
                order_by: orderBy,
                staff_id: staffId,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountLeaveRequst = async (token, searchQuery, detailedSearch, level, staffId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/getCountLeaveRequest`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                user_level: level,
                staff_id: staffId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteLeaveRequest = async (token, requestId) => {
    const payload = {
        id: requestId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/LeaveRequest/deleteLeaveRequest`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getLeaveAllowanceById = async (token, allowanceId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/findByIdLeaveAllowance`, {
            params: {
                id: allowanceId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateLeaveAllowance = async (token, leaveAllowance) => {
    const payload = {
        id: leaveAllowance.id,
        staff_id: leaveAllowance.staff_id,
        annual_leave_allowance: leaveAllowance.annual_leave_allowance,
        sick_leave_allowance: leaveAllowance.sick_leave_allowance,
        maternity_leave_allowance: leaveAllowance.maternity_leave_allowance,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/LeaveRequest/insertUpdateLeaveAllowance`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getLeaveAllowance = async (token, offset = 0, limit = 100000, orderBy = "leaveAllowance.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/findAllLeaveAllowance`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountLeaveAllowance = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/getCountLeaveAllowance`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteLeaveAllowance = async (token, requestId) => {
    const payload = {
        id: requestId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/LeaveRequest/deleteLeaveAllowance`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getLeaveTypeById = async (token, typeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/findByIdLeaveType`, {
            params: {
                id: typeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateLeaveType = async (token, leaveType) => {
    const payload = {
        id: leaveType.id,
        leave_type: leaveType.leave_type,
        description: leaveType.description,
        leave_allowance: leaveType.leave_allowance,
        unit_of_time: leaveType.unit_of_time,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/LeaveRequest/insertUpdateLeaveType`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getLeaveType = async (token, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/findAllLeaveType`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountLeaveType = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/getCountLeaveType`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteLeaveType = async (token, typeId) => {
    const payload = {
        id: typeId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/LeaveRequest/deleteLeaveType`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getUnitOfTime = async (token,) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/LeaveRequest/findAllUnitOfTime`, {
            params: {

            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getDailyReportById = async (token, reportId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/findByIdDailyReport`, {
            params: {
                id: reportId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateDailyReport = async (token, dailyReport, dailyReportFinding, listPhotoFinding) => {
    const payload = {
        id: dailyReport.id,
        staff_id: dailyReport.staff_id,
        vessel_id: dailyReport.vessel_id,
        report_date: dailyReport.report_date,
        description: dailyReport.description,
        location: dailyReport.location,
        time_periode: dailyReport.time_periode,
        deleted_detail: dailyReport.deleted_detail,
        deleted_job: dailyReport.deleted_job,
        deleted_crew: dailyReport.deleted_crew,
        tomorrow_plan: dailyReport.tomorrow_plan,
        list_crew: dailyReport.list_crew,
        mechanic_group_id: dailyReport.mechanic_group_id,
        finding_description: dailyReportFinding.description,
        finding_photo: listPhotoFinding

    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/insertUpdateDailyReport`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDailyReport = async (token, offset = 0, limit = 100000, orderBy = "dailyReport.report_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/findAllDailyReport`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountDailyReport = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/getCountDailyReport`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteDailyReport = async (token, reportId) => {
    const payload = {
        id: reportId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/deleteDailyReport`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getReportDailyReport = async (token, reportId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/createDocument`, {
            params: {
                id: reportId,
            },
            headers: {
                token: token
            },
            responseType: 'blob'
        });
        return (response.data);
        // if (response.data.error_code === 0) {
        //     return (response.data.data);
        // } else {
        //     return ([]);
        // }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDailReportJobPhoto = async (token, dailyReportJobPhotoId, offset = 0, limit = 10000, orderBy = "taken_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/findDailyReportJobPhoto`, {
            params: {
                daily_report_job_id: dailyReportJobPhotoId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateDailyReportJobPhoto = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/insertUpdateDailyReportJobPhoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteDailyReportJobPhoto = async (token, photoId) => {
    const payload = {
        id: photoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/deleteDailyReportJobPhoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDailyReportCrewById = async (token, crewId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/getDailyReportCrewById`, {
            params: {
                id: crewId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const insertUpdateDailyReportCrew = async (token, crew) => {
    try {
        const payload = {
            id: crew.id,
            staff_id: crew.staff_id,
            image_base64: crew.image_base64,
            daily_report_id: crew.daily_report_id,
            daily_report_job_id: crew.daily_report_job_id,
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/insertUpdateDailyReportCrew`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getDailyReportCrew = async (token, dailyReportJobId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/getDailyReportCrew`, {
            params: {
                id: dailyReportJobId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteDailyReportCrew = async (token, crewId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/deleteDailyReportCrew`, { id: crewId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}


export const getCountDailyReportCrew = async (token, dailyReportJobId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/getCountDailyReportCrew`, {
            headers: {
                token: token
            },
            params: {
                id: dailyReportJobId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getPhotoCrew = async (token, crewId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/findPhotoByCrewId`, {
            params: {
                id: crewId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getNewTroubleshootingById = async (token, troubleshootingId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/NewTroubleshooting/findByIdTroubleshooting`, {
            params: {
                id: troubleshootingId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateNewTroubleshooting = async (token, newTroubleshooting) => {
    const payload = {
        id: newTroubleshooting.id,
        location: newTroubleshooting.location,
        description: newTroubleshooting.description,
        report_date: newTroubleshooting.report_date,
        project_id: newTroubleshooting.project_id,
        mechanic_group_id: newTroubleshooting.mechanic_group_id,
        time_periode: newTroubleshooting.time_periode,
        deleted_detail: newTroubleshooting.deleted_detail,
        deleted_job: newTroubleshooting.deleted_job,
        deleted_crew: newTroubleshooting.deleted_crew,
        list_crew: newTroubleshooting.list_crew
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/NewTroubleshooting/insertUpdateNewTroubleshooting`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getNewTroubleshooting = async (token, offset = 0, limit = 100000, orderBy = "troubleshooting.report_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/NewTroubleshooting/findAllNewTroubleshooting`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountNewTroubleshooting = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/NewTroubleshooting/getCountNewTroubleshooting`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteNewTroubleshooting = async (token, troubleshootingId) => {
    const payload = {
        id: troubleshootingId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/NewTroubleshooting/deleteNewTroubleshooting`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentNewTroubleshooting = async (token, troubleshootingId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/NewTroubleshooting/createDocument`, {
            params: {
                id: troubleshootingId,
            },
            headers: {
                token: token
            },
            responseType: 'blob'
        });
        return (response.data);
        // if (response.data.error_code === 0) {
        //     return (response.data.data);
        // } else {
        //     return ([]);
        // }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentEngineHealthInspection = async (token, engineHealthInspectionId, mechanicGroupId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/createDocument`, {
            params: {
                id: engineHealthInspectionId,
                mechanic_group_id: mechanicGroupId
            },
            headers: {
                token: token
            },
            responseType: 'blob'
        });
        return (response.data);
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTroubleshootingJobPhoto = async (token, troubleshootingJobPhotoId, offset = 0, limit = 10000, orderBy = "taken_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/NewTroubleshooting/findNewTroubleshootingJobPhoto`, {
            params: {
                troubleshooting_job_id: troubleshootingJobPhotoId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateTroubleshootingJobPhoto = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/NewTroubleshooting/insertUpdateNewTroubleshootingJobPhoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteNewTroubleshootingJobPhoto = async (token, photoId) => {
    const payload = {
        id: photoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/NewTroubleshooting/deleteNewTroubleshootingJobPhoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTroubleshootingPhotoCrew = async (token, crewId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/NewTroubleshooting/findPhotoByCrewId`, {
            params: {
                id: crewId
            },
            headers: {
                token: token
            }
        });
        console.log(response.data.data);
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getReportDailyReportV2 = async (token, reportId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/createDocumentV2`, {
            params: {
                id: reportId,
            },
            headers: {
                token: token
            },
            responseType: 'blob'
        });
        return (response.data);
        // if (response.data.error_code === 0) {
        //     return (response.data.data);
        // } else {
        //     return ([]);
        // }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}




export const getDashboarCarouselImageById = async (token, imageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findByIdDashboardCarouselImage`, {
            params: {
                id: imageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateDashboardCarouselImage = async (token, dashboardCarousel) => {
    const payload = {
        id: dashboardCarousel.id,
        base64_image: dashboardCarousel.base64_image,
        image_title: dashboardCarousel.image_title,
        description: dashboardCarousel.description,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/insertUpdateDashboardCarouselImage`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDashboardCarouselImage = async (token, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findAllDashboardCarouselImage`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountDashboardCarousel = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/getCountDashboardCarouselImage`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteDashboarCarouselImage = async (token, imageId) => {
    const payload = {
        id: imageId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/deleteDashboardCarouselImage`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}



export const getCarouselForDashboard = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/getCarouselForDashboard`, {
            params: {
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getAboutUsById = async (token, aboutId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findByIdAboutUs`, {
            params: {
                id: aboutId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateAboutUs = async (token, aboutUs) => {
    const payload = {
        id: aboutUs.id,
        description: aboutUs.description,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/insertUpdateAboutUs`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getAboutUs = async (token, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/findAllAboutUs`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountAboutUs = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Blog/getCountAboutUs`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteAboutUs = async (token, aboutId) => {
    const payload = {
        id: aboutId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Blog/deleteAboutUs`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPLCCoordinate = async (token, coordinateGroup, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getPLCCoordinate`, {
            params: {
                coordinate_group: coordinateGroup,
                vessel_id: vesselId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPlcValue = async (token, coordinate, serialNumber) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getPLCValue`, {
            params: {
                plc_coordinate: coordinate,
                sms_serial_number: serialNumber
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getAlarmEventHistory = async (token, serialNumber, offset = 0, limit = 100000, orderBy = "eventHistory.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/findAllAlarmEventHistory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                sms_serial_number: serialNumber,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountalarmEventHistory = async (token, searchQuery, detailedSearch, serialNumber) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getCountAlarmEventHistory`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                sms_serial_number: serialNumber
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getPLCCoordinateAuxEngine = async (token, coordinateGroup, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getPLCCoordinateAuxEngine`, {
            params: {
                coordinate_group: coordinateGroup,
                vessel_id: vesselId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPLCCoordinatePumpMotor = async (token, vesselId, offset, limit) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getPLCCoordinatePumpMotorBar`, {
            params: {
                vessel_id: vesselId,
                offset: offset * limit,
                limit: limit,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getTankLevelCoordinate = async (token, offset = 0, limit = 100000, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/findCoordinateTankLevel`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                vessel_id: vesselId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountCoordinateTankLevel = async (token, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getCountCooridinateTankLevel`, {
            headers: {
                token: token
            },
            params: {
                vessel_id: vesselId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getWireBreakTankLevel = async (token, offset = 0, limit = 100000, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/findWireBreakTankLevel`, {
            params: {
                offset: offset * limit,
                limit: limit,
                vessel_id: vesselId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getSMSDeviceLocation = async (token, serialNumber) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getSMSDeviceLocation`, {
            params: {
                sms_serial_number: serialNumber
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCoordinateRunningHour = async (token, serialNumber) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getCoordinateRunningHour`, {
            params: {
                sms_serial_number: serialNumber
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPumpAverageValue = async (token, serialNumber) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getPumpValue`, {
            params: {
                sms_serial_number: serialNumber
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPLCCoordinatePumpMotorContact = async (token, vesselId, offset, limit) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getPLCCoordinatePumpMotorContact`, {
            params: {
                vessel_id: vesselId,
                offset: offset * limit,
                limit: limit,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPLCCoordinatePumpMotorThermalOverload = async (token, vesselId, offset, limit) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getPLCCoordinatePumpMotorThermalOverload`, {
            params: {
                vessel_id: vesselId,
                offset: offset * limit,
                limit: limit,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getPLCCoordinatePumpMotorWireBreak = async (token, vesselId, offset, limit) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getPLCCoordinatePumpMotorWireBreak`, {
            params: {
                vessel_id: vesselId,
                offset: offset * limit,
                limit: limit,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipmentDetail = async (token, equipmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getEquipmentDetail`, {
            params: {
                equipment_id: equipmentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const calculateTankSounding = async (token, vesselId, offset, limit) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/CalculateSoundingLevel`, {
            headers: {
                token: token
            },
            params: {
                vessel_id: vesselId,
                offset: offset * limit,
                limit: limit,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getECUValue = async (token, serialNumber) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getECUValue`, {
            headers: {
                token: token
            },
            params: {
                sms_serial_number: serialNumber
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipmentMaintenanceScheduleById = async (token, scheduleId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/finByIdEquipmentMaintenanceSchedule`, {
            params: {
                id: scheduleId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateEquipmentMaintenanceSchedule = async (token, EquipmentMaintenanceSchedule) => {
    const payload = {
        id: EquipmentMaintenanceSchedule.id,
        vessel_equipment_id: EquipmentMaintenanceSchedule.vessel_equipment_id,
        maintenance_time: EquipmentMaintenanceSchedule.maintenance_time,
        rubrication: EquipmentMaintenanceSchedule.rubrication,
        baring_replacement: EquipmentMaintenanceSchedule.baring_replacement,
        maintenance_name: EquipmentMaintenanceSchedule.maintenance_name,
        maintenance_description: EquipmentMaintenanceSchedule.maintenance_description,
        last_maintenance: EquipmentMaintenanceSchedule.last_maintenance,
        vessel_id: EquipmentMaintenanceSchedule.vessel_id,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselEquipment/InsertUpdateEquipmentMaintenanceSchedule`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipmentMaintenanceSchedule = async (token, vesselId, offset = 0, limit = 100000, orderBy = "maintenanceSchedule.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/findAllEquipmentMaintenanceSchedule`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_id: vesselId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountEquipmentMaintenanceSchedule = async (token, vesselId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselEquipment/getCountEquipmentMaintenanceSchedule`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_id: vesselId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const DeleteEquipmentMaintenanceSchedule = async (token, scheduleId) => {
    const payload = {
        id: scheduleId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/VesselEquipment/DeleteEquipmentMaintenanceSchedule`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipmentMaintenanceScheduleByVesselEquipment = async (token, vesselId, vesselEquipmentId, offset = 0, limit = 100000, orderBy = "maintenanceSchedule.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/findEquipmentMaintenanceScheduleByVesselEquipment`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_id: vesselId,
                vessel_equipment_id: vesselEquipmentId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountEquipmentMaintenanceScheduleByVesselEquipment = async (token, vesselId, vesselEquipmentId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/SMS/getCountEquipmentMaintenanceScheduleByVesselEquipment`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_id: vesselId,
                vessel_equipment_id: vesselEquipmentId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateMaintenanceTime = async (token, lastRunnginHour, totalMaintenanceTime, scheduleId) => {
    const payload = {
        id: scheduleId,
        maintenance_time: totalMaintenanceTime,
        last_maintenance_running_hour: lastRunnginHour,
    }
    console.log(payload);
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/SMS/UpdateMaintenanceTime`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getProjectAssignment = async (token, projectId, offset = 0, limit = 1000, orderBy = "job_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/findAllProjectAssignment`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                project_id: projectId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getProjectAssignmentById = async (token, projectAssignmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/findByIdProjectAssignment`, {
            params: {
                id: projectAssignmentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getProjectAssignmentCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/getCountProjectAssignment`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateProjectAssignment = async (token, projectAssignment, listTechnician) => {
    const payload = {
        id: projectAssignment.id,
        project_id: projectAssignment.project_id,
        job_name: projectAssignment.job_name,
        assignment_number: projectAssignment.assignment_number,
        language_id: projectAssignment.language_id,
        list_technician: listTechnician

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Project/insertUpdateProjectAssignment`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteProjectAssignment = async (token, projectAssignmentId) => {
    const payload = {
        id: projectAssignmentId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Project/deleteProjectAssignment`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDocumentProjectAssignment = async (token, projectAssignmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Project/createDocument`, {
            params: {
                id: projectAssignmentId,
            },
            headers: {
                token: token
            },
            responseType: 'blob'
        });
        return (response.data);
        // if (response.data.error_code === 0) {
        //     return (response.data.data);
        // } else {
        //     return ([]);
        // }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getGeneralOverhaul = async (token, offset = 0, limit = 1000, orderBy = "overhaul.created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/GeneralOverhaul/findAll`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}
export const getGeneralOverhaulById = async (token, overhaulId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/GeneralOverhaul/findGeneralOverhaulById`, {
            params: {
                id: overhaulId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getGeneralOverhaulCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/GeneralOverhaul/getCountGeneralOverhaul`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateGeneralOverhaul = async (token, generalOverhaul, listRecommendation, listJob) => {
    const payload = {
        id: generalOverhaul.id,
        project_id: generalOverhaul.project_id,
        location: generalOverhaul.location,
        equipment_category_id: generalOverhaul.equipment_category_id,
        vessel_equipment_id: generalOverhaul.vessel_equipment_id,
        language_id: generalOverhaul.language_id,
        start_date: generalOverhaul.start_date,
        project_overview: generalOverhaul.project_overview,
        list_recommendation: listRecommendation,
        list_job: listJob,
        deleted_detail: generalOverhaul.deleted_detail

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/GeneralOverhaul/insertUpdateGeneralOverhaul`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteGeneralOverhaul = async (token, overhaulId) => {
    const payload = {
        id: overhaulId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/GeneralOverhaul/deleteGeneralOverhaul`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getGeneralOverhaulReport = async (token, generalOverhaulId, projectId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/GeneralOverhaul/createDocument`, {
            params: {
                id: generalOverhaulId,
                project_id: projectId
            },
            headers: {
                token: token
            },
            responseType: 'blob'
        });
        return (response.data);
        // if (response.data.error_code === 0) {
        //     return (response.data.data);
        // } else {
        //     return ([]);
        // }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDailyReportFindingPhoto = async (token, dailyReportId, offset = 0, limit = 10000, orderBy = "taken_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/findDailyReportFindingPhoto`, {
            params: {
                daily_report_id: dailyReportId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateDailyReportFindingPhoto = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/insertUpdateDailyReportFindingPhoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteDailyReportfindingPhoto = async (token, photoId) => {
    const payload = {
        id: photoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/deleteDailyReportFindingPhoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const insertUpdateInventoryImage = async (token, inventoryImage) => {
    try {
        const payload = {
            id: inventoryImage.id,
            base64_image: inventoryImage.base64_image,
            label: inventoryImage.label,
            inventory_id: inventoryImage.inventory_id,
        }
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateInventoryImage`, payload, {
            headers: {
                token: token

            }
        }
        );
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}

export const getInventoryImage = async (token, inventoryId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryImage`, {
            params: {
                inventory_id: inventoryId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryImage = async (token, imageId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteInventoryImage`, { id: imageId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}


export const getInventoryImageById = async (token, imageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryImageById`, {
            params: {
                id: imageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountInventoryImage = async (token, inventoryId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getInventoryImageCount`, {
            headers: {
                token: token
            },
            params: {
                inventory_id: inventoryId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getInventoryBrand = async (token, offset = 0, limit = 1000, orderBy = "brand_name", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllInventoryBrand`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getInventoryBrandById = async (token, brandId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findByIdInventoryBrand`, {
            params: {
                id: brandId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryBrandCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getCountInventoryBrand`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateInventoryBrand = async (token, brand) => {
    const payload = {
        id: brand.id,
        brand_name: brand.brand_name,
        description: brand.description,
        language_id: brand.language_id,
        brand_code: brand.brand_code

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/InsertUpdateInventoryBrand`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryBrand = async (token, brandId) => {
    const payload = {
        id: brandId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteInventoryBrand`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryStockImage = async (token, inventoryStockId, offset = 0, limit = 100000, orderBy = "taken_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryImageStock`, {
            params: {
                inventory_stock_id: inventoryStockId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryStockImage = async (token, imageId) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteInventoryStockImage`, { id: imageId }, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return (false)
        }
    } catch (exception) {
        return (false);
    }

}


export const getInventoryStockImageById = async (token, imageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryImageStockById`, {
            params: {
                id: imageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountInventoryStockImage = async (token, inventoryStockId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getInventoryImageStockCount`, {
            headers: {
                token: token
            },
            params: {
                inventory_stock_id: inventoryStockId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const getContractStaffHistory = async (token, staffId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getContractStaffHistory`, {
            params: {
                id: staffId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data)
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDataPrintStaff = async (token, isResign, customerId, statusId, startJoinDatePrint= "", endJoinDatePrint= "") => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getDataPrintStaff`, {
            params: {
                is_resign: isResign,
                customer_id: customerId,
                status_id: statusId,
                start_join_date: startJoinDatePrint,
                end_join_date: endJoinDatePrint
            },
            headers: {
                token: token
            }
        });
        console.log(response)
        if (response.data.error_code === 0) {
            return (response.data.data)
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getDataPrintCSV = async (token, isResign, customerId, statusId, startJoinDatePrint= "", endJoinDatePrint="", tableOption) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getDataPrintCsv`, {
            params: {
                is_resign: isResign,
                customer_id: customerId,
                status_id: statusId,
                start_join_date: startJoinDatePrint,
                end_join_date: endJoinDatePrint,
                table_option: tableOption
            },
            headers: {
                token: token
            }
        });
         if (response.data.error_code === 0) {
            return (response.data.data)
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}



export const getBusinessUnit = async (token, offset = 0, limit = 1000, orderBy = "created_date", descending, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findAllBusinessUnit`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getBusinessUnitById = async (token, unitId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findByIdBusinessUnit`, {
            params: {
                id: unitId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountBusinessUnit = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/getCountBusinessUnit`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateBusinessUnit = async (token, unitBusiness) => {
    const payload = {
        id: unitBusiness.id,
        unit_name: unitBusiness.unit_name,
        unit_code: unitBusiness.unit_code,
        language_id: unitBusiness.language_id,

    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/insertUpdateBusinessUnit`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteBusinessUnit = async (token, unitId) => {
    const payload = {
        id: unitId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Staff/deleteBusinessUnit`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStaffEndContract = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Staff/findStaffEndContract`, {
          
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryBatchNumber = async (token, inventoryMasterId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryBatch`, {
            params: {
                id: inventoryMasterId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getListBatchBarcode = async (token, orderNumber, inventoryMasterId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findListBatchBarcode`, {
            params: {
                order_number: orderNumber,
                inventory_master_id: inventoryMasterId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateGeneralOverhaulDataTable = async (token,dataTable, generalOverhaulId ) => {
    const payload = {
        // id: generalOverhaul.id,
        general_overhaul_id: generalOverhaulId,
        dimensionOfCylinderLiner : dataTable.dimensionOfCylinderLiners,
        detailDimensionOfCylinderLiner : dataTable.detailDimensionOfCylinderLiners,
        clearanceOfPistonRingOld : dataTable.clearanceOfPistonRingOlds,
        detailClearanceOfPistonRingOld : dataTable.detailClearanceOfPistonRingOlds,
        pinPiston : dataTable.pinPistons,
        detailPinPiston : dataTable.detailPinPistons,
        pistonBore : dataTable.pistonBores,
        detailPistonBore : dataTable.detailPistonBores,
        connectingRodBushingOld : dataTable.connectingRodBushingOlds,
        detailConnectingRodBushingOld: dataTable.detailConnectingRodBushingOlds,
        clearanceOfCrankPinBearingJournalsOld : dataTable.clearanceOfCrankPinBearingJournalsOlds,
        detailClearanceOfCrankPinBearingJournalsOld : dataTable.detailClearanceOfCrankPinBearingJournalsOlds,
        dimensionOfCrankpinJournal : dataTable.dimensionOfCrankpinJournals,
        detailDimensionOfCrankPinJournal : dataTable.detailDimensionOfCrankPinJournals,
        intakeValve : dataTable.intakeValves,
        detailIntakeValve : dataTable.detailIntakeValves,
        clearanceOfMainBearingsAndJournalNew : dataTable.clearanceOfMainBearingsAndJournalNews,
        detailClearanceOfMainBearingsAndJournalNew : dataTable.detailClearanceOfMainBearingsAndJournalNews,
        clearanceOfCrankPinBearingJournalsNew : dataTable.clearanceOfCrankPinBearingJournalsNews,
        detailClearanceOfCrankPinBearingJournalsNew : dataTable.detailClearanceOfCrankPinBearingJournalsNews,
        connectingRodBushingNew : dataTable.connectingRodBushingNews,
        detailConnectingRodBushingNew : dataTable.detailConnectingRodBushingNews,
        clearanceOfPistonRingNew : dataTable.clearanceOfPistonRingNews,
        detailClearanceOfPistonRingNew : dataTable.detailClearanceOfPistonRingNews,
    }
    console.log(payload);

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/GeneralOverhaul/insertUpdateDataTable`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getDataTableByGeneralOverhaulId = async (token, generalOverhaulId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/GeneralOverhaul/findDataTableByGeneralOverhaulId`, {
            params: {
               id: generalOverhaulId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getListJobFromDailyReport = async (token, projectId, vesselEquipmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/GeneralOverhaul/findListJobFromDailyReport`, {
            params: {
               project_id: projectId,
               vessel_equipment_id: vesselEquipmentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getGOHJobPhoto = async (token, jobId, offset = 0, limit = 10000, orderBy = "created_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/GeneralOverhaul/findJobPhoto`, {
            params: {
                job_id: jobId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateGOHPhoto = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/GeneralOverhaul/insertUpdateJobPhoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteGOHJobPhoto = async (token, photoId) => {
    const payload = {
        id: photoId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/GeneralOverhaul/deleteJobPhoto`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const insertUpdateInventoryStockImage = async (token, photo) => {
    const payload = {
        list_photo: photo.list_photo
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateInventoryStockImage`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}



export const getMeggerResult = async (token, vesselEquipmentID, offset = 0, limit = 10000, orderBy = "equipmentMeggerResult.created_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findAllEquipmentMeggerResult`, {
            params: {
                vessel_equipment_id: vesselEquipmentID,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateMeggerResult = async (token, meggerResult) => {
    const payload = {
        id: meggerResult.id,
        vessel_equipment_id: meggerResult.vessel_equipment_id,
        service_id: meggerResult.service_id,
        u1_n: meggerResult.u1_n,
        w1_n: meggerResult.w1_n,
        v1_n: meggerResult.v1_n,
        description: meggerResult.description,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/insertUpdateEquipmentMeggerResult`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteMeggerResult = async (token, meggerResultId) => {
    const payload = {
        id: meggerResultId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/deleteEquipmentMeggerResult`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getMeggerResultById = async (token, meggerResultId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findByIdEquipmentMeggerResult`, {
            params: {
                id: meggerResultId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountMeggerResult = async (token,vesselEquipmentId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/getCountEquipmentMeggerResult`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_equipment_id: vesselEquipmentId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getVesselByVesselEquipment = async (token, vesselEquipmentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findVesselByVesselEquipment`, {
            params: {
                id: vesselEquipmentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const findServiceByVessel = async (token, vesselId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findServiceByVessel`, {
            params: {
                vessel_id: vesselId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}



export const getEquipmentFinding = async (token, vesselEquipmentID, offset = 0, limit = 10000, orderBy = "equipmentFinding.created_date", descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findAllEquipmentFinding`, {
            params: {
                vessel_equipment_id: vesselEquipmentID,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateEquipmentFinding = async (token, equipmentFinding) => {
    const payload = {
        id: equipmentFinding.id,
        vessel_equipment_id: equipmentFinding.vessel_equipment_id,
        service_id: equipmentFinding.service_id,
        finding: equipmentFinding.finding,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/insertUpdateEquipmentFinding`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteEquipmentFinding = async (token, equipmentFindingId) => {
    const payload = {
        id: equipmentFindingId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/ServiceJob/deleteEquipmentFinding`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getEquipmentFindingById = async (token, equipmentFindingId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findByIdEquipmentFinding`, {
            params: {
                id: equipmentFindingId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getCountEquipmentFinding = async (token,vesselEquipmentId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/getCountEquipmentFinding`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch,
                vessel_equipment_id: vesselEquipmentId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const getServiceJobDucmentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/ServiceJob/findByIdServiceJobDocument`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const insertUpdateDailyReportDataTable = async (token,dataTable, dailyReportJobId, dailyReportId ) => {
    const payload = {
        // id: generalOverhaul.id,
        daily_report_id:dailyReportId,
        daily_report_job_id: dailyReportJobId,
        dimensionOfCylinderLiner : dataTable.dimensionOfCylinderLiners,
        detailDimensionOfCylinderLiner : dataTable.detailDimensionOfCylinderLiners,
        clearanceOfPistonRingOld : dataTable.clearanceOfPistonRingOlds,
        detailClearanceOfPistonRingOld : dataTable.detailClearanceOfPistonRingOlds,
        pinPiston : dataTable.pinPistons,
        detailPinPiston : dataTable.detailPinPistons,
        pistonBore : dataTable.pistonBores,
        detailPistonBore : dataTable.detailPistonBores,
        connectingRodBushingOld : dataTable.connectingRodBushingOlds,
        detailConnectingRodBushingOld: dataTable.detailConnectingRodBushingOlds,
        clearanceOfCrankPinBearingJournalsOld : dataTable.clearanceOfCrankPinBearingJournalsOlds,
        detailClearanceOfCrankPinBearingJournalsOld : dataTable.detailClearanceOfCrankPinBearingJournalsOlds,
        dimensionOfCrankpinJournal : dataTable.dimensionOfCrankpinJournals,
        detailDimensionOfCrankPinJournal : dataTable.detailDimensionOfCrankPinJournals,
        intakeValve : dataTable.intakeValves,
        detailIntakeValve : dataTable.detailIntakeValves,
        clearanceOfMainBearingsAndJournalNew : dataTable.clearanceOfMainBearingsAndJournalNews,
        detailClearanceOfMainBearingsAndJournalNew : dataTable.detailClearanceOfMainBearingsAndJournalNews,
        clearanceOfCrankPinBearingJournalsNew : dataTable.clearanceOfCrankPinBearingJournalsNews,
        detailClearanceOfCrankPinBearingJournalsNew : dataTable.detailClearanceOfCrankPinBearingJournalsNews,
        connectingRodBushingNew : dataTable.connectingRodBushingNews,
        detailConnectingRodBushingNew : dataTable.detailConnectingRodBushingNews,
        clearanceOfPistonRingNew : dataTable.clearanceOfPistonRingNews,
        detailClearanceOfPistonRingNew : dataTable.detailClearanceOfPistonRingNews,
    }
    console.log(payload);

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/DailyReport/insertUpdateDataTableDailyReport`, payload, {
            headers: {
                token: token
            }
        });

        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getDataTableByDailyReportId = async (token, dailyReportId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/DailyReport/findDataTableByDailyReportId`, {
            params: {
               id: dailyReportId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const findAllEngineHealthInspection = async (token, language_id, extCustomerId, searchQuery, orderBy="created_date", limit=50, offset=0, sortDescending=true) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findAll`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id,
                ext_customer_id: extCustomerId,
                order_by: orderBy,
                limit: limit,
                offset: offset,
                descending: sortDescending,
                search_query: searchQuery
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findEngineHealthInspectionById = async (token, engineHealthInspectionId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findById`, {
            headers: {
                token: token
            },
            params: {
                id: engineHealthInspectionId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findAllInspectionDetail = async (token, engineHealthInspectionId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findAllInspectionDetail`, {
            headers: {
                token: token
            },
            params: {
                engine_health_inspection_id: engineHealthInspectionId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const deleteEngineHealthInspectionById = async (token, engineHealthInspectionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/deleteById`, {
            headers: {
                token: token
            },
            params: {
                id: engineHealthInspectionId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findProjectForEngineHealthInspection = async (token, language_id, extCustomerId, searchParams) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findProject`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id,
                ext_customer_id: extCustomerId,
                project_name: searchParams.projectName,
                vessel_name: searchParams.vesselName,
                customer_name: searchParams.customerName,
                project_number: searchParams.projectNumber
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}


export const findStaffForEngineHealthInspection = async (token, language_id, searchParams) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findStaff`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id,
                staff_name: searchParams.staffName,
                employee_number: searchParams.employeeNumber,
                division_name: searchParams.divisionName,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findStaffByFieldStaff = async (token, language_id, searchParams) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findStaffByFieldStaff`, {
            headers: {
                token: token
            },
            params: {
                language_id: language_id,
                staff_name: searchParams.staffName,
                employee_number: searchParams.employeeNumber,
                division_name: searchParams.divisionName,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findEngineByVesselId = async (token, vessel_id) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findEngineByVesselId`, {
            headers: {
                token: token
            },
            params: {
                vessel_id: vessel_id
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findEquipmentForInspectionDetail = async (token, vesselId, vesselEquipmentId, searchParams) => {
    
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findEquipmentForInspectionDetail`, {
            headers: {
                token: token
            },
            params: {
                vessel_id: vesselId,
                vessel_equipment_id: vesselEquipmentId,
                equipment_title: searchParams.equipmentTitle,
                vessel_name: searchParams.vesselName,
                equipment_model: searchParams.equipmentModel,
                equipment_part_number: searchParams.equipmentPartNumber
            }
        });
        if (response.data.error_code === 0) {
            console.log(response);
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const insertUpdateEngineHealthInspectionDetail = async (token, engineHealthInspection) => {

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/EngineHealthInspection/insertUpdate`, engineHealthInspection, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const insertUpdateInspectionDetail = async (token, engineInspectionDetail) => {

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/EngineHealthInspection/insertUpdateInspectionDetail`, engineInspectionDetail, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findInspectionDetailById = async (token, engineHealthInspectionId, inspectionDetailId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findInspectionDetailById`, {
            headers: {
                token: token
            },
            params: {
                engine_health_inspection_id: engineHealthInspectionId,
                id: inspectionDetailId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findProjectForEngineHealthInspectionById = async (token, projectId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findProjectById`, {
            headers: {
                token: token
            },
            params: {
                project_id: projectId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findStaffForEngineHealthInspectionById = async (token, languageId, staffId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findStaffById`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                staff_id: staffId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findEngineForEngineHealthInspectionById = async (token, vesselEquipmentId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findEngineById`, {
            headers: {
                token: token
            },
            params: {
                vessel_equipment_id: vesselEquipmentId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findAllMechanicGroupForEngineHealthInspection = async (token, searchListMechanicParameters) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findAllMechanicGroup`, {
            headers: {
                token: token
            },
            params: {
                group_name: searchListMechanicParameters.groupName
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findMechanicGroupForEngineHealthInspectionById = async (token, mechanicGroupId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/EngineHealthInspection/findMechanicGroupById`, {
            headers: {
                token: token
            },
            params: {
                id: mechanicGroupId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}


